import React, {useEffect, useState} from 'react';

import IconSVG from 'components/icons/IconSVG';

export default (props) => {
  props = {...props.children, ...props, children: null};

  return (
    <div className="profile">
      <h2><IconSVG icon="chat" className="h2" />Messages</h2>
      <p><IconSVG icon="warning" className="p" />Messages are under development</p>

    </div>
  )
}
