import React, { useRef, useEffect, useState } from 'react';

import IconSVG from 'components/icons/IconSVG';

export default (props) => {
  const radio = useRef();
  const [groupClass, setGroupClass] = useState(null);
  
  // HOOKS
  useEffect(() => {
    setGroupClass(props.group.replace(/\.|\[|\]\.|\]/g, '-'));
  }, [props.group]);

  // FUNCTIONS
  function handleClick(e) {
    let target = e.target;
    while(!target.classList.contains('radio-btn') && target.tagName.toLowerCase() !== 'body') {
      target = target.parentNode;
    }
    
    if(target.classList.contains('radio-btn') && !target.classList.contains('active')) {
      const radios = document.querySelectorAll(`.${groupClass}`);
  
      radios.forEach(thisRdio => {
        if(thisRdio != radio.current && thisRdio.classList.contains('active')) {
            thisRdio.classList.remove('active');
        }
      })
  
      if(radio.current.classList.contains('active')) {
        radio.current.classList.remove('active');
        props.selectAction && props.selectAction(null, props.group);
      }else{
        radio.current.classList.add('active');
        props.selectAction && props.selectAction(props.value, props.group);
      }
    }else{
      e.preventDefault();
    }
  }
  
  return (
    <div className={`radio-btn${props.className ? ` ${props.className}` : ''}${groupClass ? ` ${groupClass}` : ''}${props.selected ? ' active' : ''}${props.disabled ? ' disabled' : ''}`}
          ref={radio}
          rel={props.group}>
      <div className="radio-btn-boundary" onClick={!props.disabled ? handleClick : null} >
        <div className="radio-btn-selector">
          <IconSVG icon="check" />
        </div>
      </div>
      <div className="radio-btn-info">
        <div className="radio-btn-label" onClick={handleClick} >{props.label}</div>
        {props.labelDescription &&
          Array.isArray(props.labelDescription) ?
            props.labelDescription.map((desc, index) => {
              return <div className={`radio-btn-label-sub ${props.labelTruncate ? 'truncate' : ''}`} key={`rdiolbls${index}`} dangerouslySetInnerHTML={{__html: desc}}></div>
            })
            :
            <div className="radio-btn-label-sub">{props.labelDescription}</div>
        }
      </div>
    </div>
  )
}
