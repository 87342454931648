import React, {useEffect, useState} from 'react';

import ModalConfirmation from 'components/molecules/ModalConfirmation';
import OrganizationTaskGroup from 'components/profiles/orgs/OrganizationTaskGroup';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [taskList, setTaskList] = useState(null);
  const [taskGroups, setTaskGroups] = useState(null);
  const [groupTasks, setGroupTasks] = useState(null);
  const [staffGroups, setStaffGroups] = useState(null);
  const [orgGroups, setOrgGroups] = useState(null);
  const [staffGroupOptions, setStaffGroupOptions] = useState(null);
  const [orgGroupOptions, setOrgGroupOptions] = useState(null);
  
  useEffect(() => {
    props.userID &&
    props.userID !== userID &&
    setUserID(props.userID);
  }, [props.userID]);

  useEffect(() => {
    props.token &&
    props.token !== token &&
    setToken(props.token);
  }, [props.token]);

  useEffect(() => {
    props.languageCode && 
    !languageCode &&
    setLanguageCode(props.languageCode);
  }, [props.languageCode]);
  
  useEffect(() => {
    if(props.organizationID && 
        props.organizationID !== organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    props.userAccess && 
    !userAccess &&
    setUserAccess(props.userAccess);
  }, [props.userAccess]);
  
  useEffect(() => {
    if(props.taskList && props.taskList !== taskList) {
      setTaskList(props.taskList);
    }
  }, [props.taskList]);
  
  useEffect(() => {
    if(props.taskGroups && props.taskGroups !== taskGroups) {
      setTaskGroups(props.taskGroups);
    }
  }, [props.taskGroups]);
  
  useEffect(() => {
    if(languageCode && Array.isArray(taskList)) {
      let taskNames = [];
      taskList.map(task => {
        const taskName = props.camelCase(task[languageCode].task_name);
        if(!taskNames.find(gTask => gTask.task_name === task[languageCode].task_name &&
                                    gTask.task_type === task[languageCode].task_type)){
          taskNames.push({
            task_name: task[languageCode].task_name, 
            link_type: taskName,
            task_type: task.type_name,
            task_parent_name: task.task_parent_name,
            task_ignored: 
              props.objGetValue(task, `task_properties.task_ignored.value`) === 1 ?
                true : props.objGetValue(task, `task_properties.task_ignored.value`) === 0 ?
                  false : task.task_ignored === 1 ?
                    true : false,
            task_published: 
            props.objGetValue(task, `task_properties.task_published.value`) === 1 ?
              true : props.objGetValue(task, `task_properties.task_published.value`) === 0 ?
                false : task.task_published === 1 ?
                  true : false
          });
        }
      })
      if(taskNames !== groupTasks) {
        setGroupTasks(taskNames);
      }
    }
  }, [taskList, languageCode]);
  
  useEffect(() => {
    if(props.staffGroups && props.staffGroups !== staffGroups) {
      setStaffGroups(props.staffGroups);
    }  
  }, [props.staffGroups]);
  
  useEffect(() => {
    if(props.orgGroups && props.orgGroups !== orgGroups) {
      setOrgGroups(props.orgGroups);
    }  
  }, [props.orgGroups]);
  
  useEffect(() => {
    if(Array.isArray(staffGroups)) {
      let sgOptions = [];
      staffGroups.map(sgroup => {
        sgOptions.push(
          {option_name: sgroup.group_name,
            option_value: sgroup.group_id
          }
        )
      })
      console.log("GOT STAFF GROUP OPTIONS", sgOptions);
      setStaffGroupOptions(sgOptions);
    }
  }, [staffGroups]);
  

  // FUNCTIONS
  
  return (
    <div>
      {Array.isArray(groupTasks) &&
        groupTasks.map((task, index) => {
          return (
            <OrganizationTaskGroup {...props}
              key={`tgrp${index}`}
              userID={userID}
              token={token}
              languageCode={languageCode}
              organizationID={organizationID}
              task={task}
              group={Array.isArray(taskGroups) ?
                      taskGroups.find(tgroup => tgroup.group_task === task.link_type) :
                      null}
              optionsStaffGroups={staffGroupOptions}
              optionsOrgGroups={orgGroupOptions} />
          )
        })
      }
      

    </div>
  )
}
