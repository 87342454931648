import React, {useEffect, useState, Fragment} from 'react';

import FormDynamic from 'components/forms/FormDynamic';
import IconSVG from 'components/icons/IconSVG';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [task, setTask] = useState(null);
	const [taskName, setTaskName] = useState(null);
	const [fieldsets, setFieldsets] = useState(null);
	const [form, setForm] = useState(null);
	
	useEffect(() => {
		if(props.userID && props.userID != userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token != token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.task && props.task != task) {
			setTask(props.task);
		}
	}, [props.task]);
	
	useEffect(() => {
		if(props.languageCode && 
			props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}else if (!props.languageCode && 
					!languageCode &&
					props.objExists(props.site, 'site_language')) {
			setLanguageCode(props.site.site_language);
		}else if (!languageCode) {
			setLanguageCode('en');
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(task) {
			// SET TASK NAME FOR MODAL
			let tName;
			if(props.objExists(task, 'item_name')) {
				setTaskName(`this ${props.capitalizeString(task.item_name, true)}`);
			}else if(props.objExists(task, `order_id`) &&
					props.objExists(task, `project_id`) &&
					props.objExists(task, `organization_code`)) {
				setTaskName(props.orderNumDisplay(task.project_id, task.order_id, task.organization_code));
			}else if(props.objExists(task, `project_id`) &&
					props.objExists(task, `organization_code`)) {
				setTaskName(props.projectNumDisplay(task.project_id, task.organization_code));
			}else{
				setTaskName(`Task ${task.task_id}`);
			}
			
			// SET FORM FIELDS
			let formFieldsets = [{
				fieldset_name: `Decline ${task.item_id ? 'Item' : task.order_id ? 'Shipment' : task.project_id ? 'Order' : 'Task'}`,
				fields: [
					{field_type: 'textarea',
					field_name: 'message_text',
					field_label: 'comments',
					field_placeholder: 'Provide reason(s) for declining and/or revisions required.',
					field_value: null,
					field_required: true,
					field_validate: true,
					field_order: 1
					},
				]
			}];
			
			if(task.item_id) {
				formFieldsets[0].fields.push(
					{field_type: 'file',
					field_name: 'item_file',
					field_label: 'Upload Revisions',
					field_value: null,
					file_required: false,
					file_validate: false
					}
				);
			}
			
			formFieldsets[0].fields.push(
				{field_type: 'hidden',
				field_name: 'process',
				field_value: 'advance_task'
				},
				{field_type: 'hidden',
				field_name: 'user_id',
				field_value: userID
				},
				{field_type: 'hidden',
				field_name: 'token',
				field_value: token
				},
				{field_type: 'hidden',
				field_name: 'task_id',
				field_value: task.task_id
				},
				{field_type: 'hidden',
				field_name: 'task_status',
				field_value: 'declined'
				},
				{field_type: 'hidden',
				field_name: 'task_owner_type',
				field_value: props.taskOwnerType
				},
				{field_type: 'hidden',
				field_name: 'task_owner_id',
				field_value: props.taskOwnerID
				},
				{field_type: 'hidden',
				field_name: 'task_root_type',
				field_value: props.taskRootType
				},
				{field_type: 'hidden',
				field_name: 'task_root_id',
				field_value: props.taskRootID
				}
			)
			
			setFieldsets(formFieldsets);
		}
	}, [task]);
	
	useEffect(() => {
		if(fieldsets) {
			setForm(
				<FormDynamic {...props}
				  content={fieldsets}
				  name="decline-task"
				  returnObject={true}
				  fieldOnChange={null}
				  formActions={{formCancel: handleCancel, formSubmit: handleDecline}}
				  formCompleteLabel="Confirm" />
			)
		}
	}, [fieldsets, props.files]);
	
	// FUNCTIONS 
	function handleCancel() {
		if(props.actionCancel) {
			props.actionCancel(true);
		}else{
			props.setModal(null);
		}
	}
	
	function handleDecline(params) {
		if(props.actionSubmit) {
			props.actionSubmit(params);
		}else{
			props.setModal(null);
		}
	}
	
	
	
	console.log("FILES FORM DECLINE TASK", props.files);
	return (
		<Fragment>
			<div className="modal-header text-center">
				<IconSVG icon="decline" className="icon-header error" />
				<h3>{`Decline ${taskName ? taskName : 'this task'}?`}</h3>
			</div>
			{form}
		</Fragment>
	)
}