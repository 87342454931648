import React, {useEffect, useState} from 'react';

// import Link from 'components/navigation/Link';
import OrganizationList from 'components/profiles/orgs/OrganizationList';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [token, setToken] = useState(null);
  const [siteLanguage, setSiteLanguage] = useState(null);

  useEffect(() => {
    props.userID &&
    setUserID(props.userID);
  }, [props.userID]);

  useEffect(() => {
    props.token &&
    setToken(props.token);
  }, [props.token])

  useEffect(() => {
    props.organizationID &&
    setOrganizationID(props.organizationID);
  }, [props.organizationID]);

  useEffect(() => {
    props.organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);

  useEffect(() => {
    props.siteLanguage &&
    setSiteLanguage(props.siteLanguage);
  }, [props.siteLanguage]);

  console.log("What's in the box?", props.admin);
  return (
    <div>
      {props.objExists(props.admin, 'organizations') &&
      Array.isArray(props.admin.organizations) &&
      <OrganizationList {...props}
                        organizations={props.admin.organizations}
                        isAdmin={true}
                        location="admin"
                        />
      }
    </div>
  )
}
