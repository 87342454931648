import React, {useEffect, useState} from 'react';
import SVGInline from 'react-svg-inline';

// Also requires raw-loader.
// Add the following to node_modules/react-scripts/config/webpack-config.js
// After test: [/\.bmp$/, /\.gif$/, /\.jpe?g$/, /\.png$/],
//
// {
//   test: /\.svg$/i,
//   use: 'raw-loader',
// },

import IconSVG from 'components/icons/IconSVG';

import Ai from 'svg/filetypes/fileAI.svg';
import Archive from 'svg/filetypes/fileArchive.svg';
import Code from 'svg/filetypes/fileCode.svg';
import Csv from 'svg/filetypes/fileCSV.svg';
import Doc from 'svg/filetypes/fileDoc.svg';
import File from 'svg/filetypes/fileFile.svg';
import Gif from 'svg/filetypes/fileGIF.svg';
import Img from 'svg/filetypes/fileImg.svg';
import Jpg from 'svg/filetypes/fileJPG.svg';
import Mp3 from 'svg/filetypes/fileMP3.svg';
import Mp4 from 'svg/filetypes/fileMP4.svg';
import Music from 'svg/filetypes/fileMusic.svg';
import Pdf from 'svg/filetypes/filePDF.svg';
import Png from 'svg/filetypes/filePNG.svg';
import Ppt from 'svg/filetypes/filePPT.svg';
import Psd from 'svg/filetypes/filePSD.svg';
import Spreadsheet from 'svg/filetypes/fileSpreadsheet.svg';
import Text from 'svg/filetypes/fileText.svg';
import Video from 'svg/filetypes/fileVideo.svg';
import Xls from 'svg/filetypes/fileXLS.svg';
import Zip from 'svg/filetypes/fileZip.svg';

export default (props) => {
  let Icon = null;

  switch (props.icon) {
    case 'ai':
      Icon = Ai;
      break;
    case 'archive':
      Icon = Archive;
      break;
    case 'code':
      Icon = Code;
      break;
    case 'csv':
      Icon = Csv;
      break;
    case 'doc':
    case 'docx':
      Icon = Doc;
      break;
    case 'file':
      Icon = File;
      break;
    case 'gif':
      Icon = Gif;
      break;
    case 'img':
      Icon = Img;
      break;
    case 'jpeg':
    case 'jpg':
      Icon = Jpg;
      break;
    case 'mp3':
      Icon = Mp3;
      break;
    case 'mp4':
      Icon = Mp4;
      break;
    case 'music':
      Icon = Music;
      break;
    case 'pdf':
      Icon = Pdf;
      break;
    case 'png':
      Icon = Png;
      break;
    case 'ppt':
      Icon = Ppt;
      break;
    case 'psd':
      Icon = Psd;
      break;
    case 'spreadsheet':
      Icon = Spreadsheet;
      break;
    case 'text':
      Icon = Text;
      break;
    case 'video':
      Icon = Video;
      break;
    case 'xls':
    case 'xlsx':
      Icon = Xls;
      break;
    case 'zip':
      Icon = Zip;
      break;
    default:
      Icon = File;
      break;
  }
  
  const [approvalBadge, setApprovalBadge] = useState(null);
  
  useEffect(() => {
    switch(props.approval) {
      case 'approved':
        setApprovalBadge(
          <IconSVG
            className="success icon-badge"
            icon="thumbs-up" />
        );
        break;
      case 'declined':
        setApprovalBadge(
          <IconSVG
            className="error icon-badge"
            icon="thumbs-down" />
        );
        break;
      default:
        break;
    }
  }, [props.approval]);

  function handleClick() {
    props.action &&
    props.action(props.index);
  }

  return (
    <div
      className={`icon-file${props.className ? ` ${props.className}` : ''}`} 
      style={{position: 'relative'}}>
      {approvalBadge}
      <SVGInline
        svg={Icon}
        
        onClick={props.action ? handleClick : null}
      />
    </div>
  )
};
