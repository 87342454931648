import React, {useEffect, useState, Fragment} from 'react';

import * as formData from 'methods/forms';

import CardContent from 'components/molecules/CardContent';
import CardContentContact from 'components/cards/CardContentContact';
import FormDynamic from 'components/forms/FormDynamic';
import RadioBtn from 'components/forms/elements/RadioBtn';
import IconToken from 'components/icons/IconToken';
import Button from 'components/forms/elements/Button';

export default (props) => {
	const [fieldsEmail, setFieldsEmail] = useState(null);
	const [fieldsAddress, setFieldsAddress] = useState(null);
	const [delivery, setDelivery] = useState(null);
	
	useEffect(() => {
		if(props.objExists(props.user, 'user_id')) {
			setFieldsEmail(
				[{fieldset_name: 'Invoice Email',
				  fields: [
					{field_type: 'email',
					  field_name: 'order_invoice_email',
					  field_label: 'Invoice Email',
					  field_placeholder: 'Send invoice to this address',
					  field_value: props.objExists(props.cart, 'project.billing.order_invoice_email') ?
									props.cart.project.billing.order_invoice_email :
									props.objExists(props.cart, 'project.billing.contact.contact_email') ?
									  props.cart.project.billing.contact.contact_email :
									  null}
				  ]
				}]
			);
		}
		
		setFieldsAddress(
			[{fieldset_name: 'Billing Address', fields: formData.fieldsLocation}]
		);
		
		// [props.user && props.user.isLogin === true && (!props.isUserLocation(props.cart.project.billing.location, props.user) &&{field_type: 'switch',
		//   field_name: 'save_location',
		//   field_label: 'Add location to your account?',
		//   field_value: props.cart.project.billing.location.save_location ? true : false,
		//   field_required: false,
		//   field_validate: false}),
		// {field_type: 'switch',
		//   field_name: 'save_default',
		//   field_label: 'Save as default?',
		//   field_value:
		//   props.objExists(props.cart, 'project.billing.location.save_default') && props.cart.project.billing.location.save_default ? true : false,
		//   field_required: false,
		//   field_validate: false},
		// ]
	}, [props.user]);
	
	useEffect(() => {
		if(props.objGetValue(props.cart, `${props.location}.billing.order_invoice_send`) !== delivery) {
			props.cartSetInfo(delivery, `${props.location}.billing.order_invoice_send`);
		}
	}, [delivery]);
	
	// FUNCTIONS
	function loadAddEditInfo(type) {
		if(type === 'billing_email' && fieldsEmail) {
			props.setModal(
				<FormDynamic
					name={`add_update_billing_email`}
					content={fieldsEmail}
					returnObject={true}
					formActions={{formCancel: ()=>{props.setModal(null);},
								formSubmit: addEditInfo}}
				/>
			);
		}
	}
	
	function addEditInfo(params) {
		props.setModal(null);
		console.log("PARMAMAMAMAMAMAMAS", params);
		// TODO - COMPLETE THIS FORM
	}
	
	return (
		<Fragment>
		<CardContent
			editable={true}
		    actionEdit={()=>{loadAddEditInfo('billing_email')}} >
			<RadioBtn
				label="Send by Email"
	            value="email"
	            selectAction={setDelivery}
	            selected={props.objExists(props.cart, 'project.billing') && 
							props.cart.project.billing.order_invoice_send === 'email' ? true : false}
	            group="project.billing.order_invoice_send"
	            labelDescription={props.objExists(props.cart, 'project.billing.order_invoice_email') ?
	                              [props.cart.project.billing.order_invoice_email]
	                              :
	                                props.objExists(props.cart, 'project.billing.contact.contact_email') ?
	                                  props.cart.project.billing.contact.contact_email
	                                :
	
		                                <div className="card-info-warning">
		                                    <div className="card-info-warning-content">
		                                      <IconToken icon="warning" />
		                                      More Infomation Required
		                                    </div>
		                                    <Button icon="add"
		                                      label="Add Invoice Email"
		                                      btnClass="success-bg"
		                                      btnAction={()=>{props.infoEdit('project.billing',
		                                                                        props.cart.project.billing,
		                                                                        [{fieldset_name: 'Invoice Email',
		                                                                          fields: [
		                                                                            {field_type: 'email',
		                                                                              field_name: 'order_invoice_email',
		                                                                              field_label: 'Invoice Email',
		                                                                              field_placeholder: 'Send invoice to this address',
		                                                                              field_value: props.objExists(props.cart, 'project.billing.order_invoice_email') ?
		                                                                                            props.cart.project.billing.order_invoice_email :
		                                                                                            props.objExists(props.cart, 'project.billing.contact.contact_email') ?
		                                                                                              props.cart.project.billing.contact.contact_email :
		                                                                                              null}
		                                                                          ]
		                                                                        }],
                                                                                    [props.user && props.user.isLogin === true && (!props.isUserLocation(props.cart.project.billing.location, props.user) &&{field_type: 'switch',
		                                                                                          field_name: 'save_invoice_email',
		                                                                                          field_label: 'Add email to your account?',
		                                                                                          field_value: props.cart.project.billing.save_location ? true : false,
		                                                                                          field_required: false,
		                                                                                          field_validate: false}),
		                                                                                        {field_type: 'switch',
		                                                                                          field_name: 'save_default',
		                                                                                          field_label: 'Save as default?',
		                                                                                          field_value:
		                                                                                          props.objExists(props.cart, 'project.billing.location.save_default') && props.cart.project.billing.save_default_invoice_email ? true : false,
		                                                                                          field_required: false,
		                                                                                          field_validate: false},
		                                                                                        ]
		                                                                                      )}} />
		                                                </div>
		                                                } />
		                </CardContent>
		  
		                <CardContent editable={true}
		                              actionEdit={()=>{props.infoEdit('project.billing.location',
		                                                                props.cart.project.billing.location,
		                                                                [{fieldset_name: 'Billing Address', fields: formData.fieldsLocation}],
		                                                                [props.user && props.user.isLogin === true && (!props.isUserLocation(props.cart.project.billing.location, props.user) &&{field_type: 'switch',
		                                                                  field_name: 'save_location',
		                                                                  field_label: 'Add location to your account?',
		                                                                  field_value: props.cart.project.billing.location.save_location ? true : false,
		                                                                  field_required: false,
		                                                                  field_validate: false}),
		                                                                {field_type: 'switch',
		                                                                  field_name: 'save_default',
		                                                                  field_label: 'Save as default?',
		                                                                  field_value:
		                                                                  props.objExists(props.cart, 'project.billing.location.save_default') && props.cart.project.billing.location.save_default ? true : false,
		                                                                  field_required: false,
		                                                                  field_validate: false},
		                                                                ]
		                                                              )}} >
		  
		                  <RadioBtn label="Send by Snail Mail"
		                            value="mail"
		                            selectAction={setDelivery}
		                            selected={props.objExists(props.cart, 'project.billing') && props.cart.project.billing.order_invoice_send === 'mail' ? true : false}
		                            group="project.billing.order_invoice_send"
		                            labelDescription={props.objExists(props.cart, 'project.billing.location') ?
		                                              [`${props.cart.project.billing.location.location_address_1}${props.cart.project.billing.location.location_address_2 ? ` ${props.cart.project.billing.location.location_address_2}` : ''}`,
		                                                `${props.cart.project.billing.location.location_city}, ${props.cart.project.billing.location.location_province}  ${props.cart.project.billing.location.location_postal_code}`] :
		                                                <div className="card-info-warning">
		                                                    <div className="card-info-warning-content">
		                                                      <IconToken icon="warning" />
		                                                      More Infomation Required
		                                                    </div>
		                                                    <Button icon="add"
		                                                            label="Add Billing Address"
		                                                            btnClass="success-bg"
		                                                            btnAction={()=>{props.infoEdit('project.billing.location',
		                                                                                              props.cart.project.billing.location,
		                                                                                              [{fieldset_name: 'Billing Address', fields: formData.fieldsLocation}],
		                                                                                              [props.user && props.user.isLogin === true && (!props.isUserLocation(props.cart.project.billing.location, props.user) &&{field_type: 'switch',
		                                                                                                field_name: 'save_location',
		                                                                                                field_label: 'Add location to your account?',
		                                                                                                field_value: props.cart.project.billing.location.save_location ? true : false,
		                                                                                                field_required: false,
		                                                                                                field_validate: false}),
		                                                                                              {field_type: 'switch',
		                                                                                                field_name: 'save_default',
		                                                                                                field_label: 'Save as default?',
		                                                                                                field_value:
		                                                                                                props.objExists(props.cart, 'project.billing.location.save_default') && props.cart.project.billing.location.save_default ? true : false,
		                                                                                                field_required: false,
		                                                                                                field_validate: false},
		                                                                                              ]
		                                                                                            )}} />
		                                                </div>
		                                              } />
		                </CardContent>
	</Fragment>
	)
}