import React, {Fragment} from 'react';

import Control from 'components/atoms/Control';
import List from 'components/molecules/List';

export default (props) => {

  function addEditHost(host_id) {
    if(props.actionAddEdit) {
      props.actionAddEdit(host_id);
    }
  }

  function removeHost(host_id) {
    if(props.actionRemove) {
      props.actionRemove(host_id, 'hostToOrganization')
    }
  }

  return (
    <div className="list-set">
      {props.hosts && Array.isArray(props.hosts) &&
        <List {...props}
              list={props.hosts.map((host, index) => {
                      return {name: host.host_name, id: host.host_id, index: index}
                    })}
              listName="Hosts"
              listIcon="elipse"
              actionEditItem={addEditHost}
              actionRemoveItem={removeHost}
              editable={true}
              listBox={true} />
      }
      <div className="list-set-ctl">
        <Control icon="add" label="Add a host" showLabel={true} action={addEditHost} />
      </div>
    </div>
  )
}
