import React from 'react';
import PagePublic from 'components/templates/PagePublic';

import CustomRequest from 'components/organisms/CustomRequest';

export default () => {
  return (
    <PagePublic >
      <CustomRequest  />
    </PagePublic>
  )
}
