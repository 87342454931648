import React, {useEffect, useState, Fragment} from 'react';

import RadioBtn from 'components/forms/elements/RadioBtn';
import IconToken from 'components/icons/IconToken';
import Control from 'components/atoms/Control';
import MessageList from 'components/profiles/messages/MessageList';
import MediaFile from 'components/profiles/media/MediaFile';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [task, setTask] = useState(null);
	const [taskStatus, setTaskStatus] = useState(null);
	const [taskName, setTaskName] = useState(null);
	const [comments, setComments] = useState(null);
	const [commentsActive, setCommentsActive] = useState(null);
	const [files, setFiles] = useState(null);
	const [filesActive, setFilesActive] = useState(null);
	
	useEffect(() => {
		if(props.userID &&
			props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token &&
			props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.languageCode &&
			props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.task &&
			props.task !== task) {
			setTask(props.task);
		}
	}, [props.task]);
	
	useEffect(() => {
		if(task) {
			let parentName = '';
			if(props.taskParent) {
				if(props.taskParent.item_name) {
					parentName = `${props.capitalizeString(props.taskParent[languageCode].task_name)} `;
				}
			}
			setTaskName(`${parentName}${props.capitalizeString(task[languageCode].task_name)}`);
		}
		if(task &&
			Array.isArray(task.messages) && 
			task.messages !== comments) {
			setComments(task.messages);
		}
		
		if(task && Array.isArray(task.files) && 
			task.files.length > 0 &&
			task.files !== files) {
				setFiles(task.files);
			}
		
	}, [task, props.taskParent]);
	
	// FUNCTIONS 
	function completeToDo(status) {
		setTaskStatus(status);
		const completeParams = [
			{name: 'process', value: 'update_task_status'},
			{name: 'user_id', value: props.userID},
			{name: 'token', value: props.token},
			{name: 'language_code', value: props.languageCode},
			{name: 'task_id', value: task.task_id},
			{name: 'task_owner_type', value: props.taskOwnerType},
			{name: 'task_owner_id', value: props.taskOwnerID},
			{name: 'task_status', value: status},
			{name: 'task_root_type', value: props.taskRootType},
			{name: 'task_root_id', value: props.taskRootID}	
		];
		
		if(props.actionUpdate) {
			props.actionUpdate(task.task_id, status);
		}
		props[`${props.location ? props.location : 'dashboard'}LoadState`](completeParams, 'tasks', `reload.${props.stateLocation ? `${props.stateLocation}` : 'task'}`);
	}
	
	return (
		<div className={`flex list-item no-wrap${task && task.task_completed ? ' completed' : ''}`}>
			{task &&
			<Fragment>
				<div style={{flex: '0 0 auto'}}>
					<RadioBtn {...props}
						selectAction={task.task_completed ? null : ()=>{completeToDo('complete')}}
						disabled={task.task_completed ? true : false}
						group={`complete_todo${task.task_id}`}
						value="complete"
						selected={task.task_completed ? true : false}
						tooltip={'Complete To-to'}
						/>
				</div>
				<div className="flex column" style={{flex: '1 1 auto'}}>
					<div><b>{taskName}</b><wbr /> <span className="subtext">{`${props.dateDisplay(task.task_created)} - ${props.timeDisplay(task.task_created, false)}`}</span></div>
					<div className="ctl row ">
						{comments &&
						<Control icon="messages-view"
								iconClass="no-btn"
								label={`${commentsActive ? 'Hide' : 'View'} ${comments.length} ${comments.length === 1 ? 'Comment' : 'Comments'}`}
								showLabel={true}
								action={()=>{setCommentsActive(commentsActive ? false : true)}} />
						}
						{files &&
						<Control icon="file"
								iconClass="no-btn"
								label={`${filesActive ? 'Hide' : 'View'} ${files.length} ${files.length === 1 ? 'File' : 'Files'}`}
								showLabel={true}
								action={()=>{setFilesActive(filesActive ? false : true)}} />	
						}
					</div>
					{Array.isArray(comments) &&
					<div className={`expandable message-list${commentsActive ? ' active' : ''}`}>
						<MessageList {...props}
							userID={userID}
							token={token}
							languageCode={languageCode}
							messages={comments}
							title="comments"
							location={props.location}
							stateLocation={props.stateLocation} />
					</div>
					}
					{Array.isArray(files) &&
					<div className={`expandable file-list${filesActive ? ' active' : ''}`}>
						{files.map((file, index) => {
							return(
								<MediaFile {...props}
									userID={userID}
									token={token}
									inline={true}
									media={file}
									location={props.stateLocation ? props.stateLocation : "dashboard"} />
							)
						})}
					</div>
					}
				</div>
			</Fragment>}
		</div>
	)
}