import React, {useEffect, useState} from 'react';

import FormDynamic from 'components/forms/FormDynamic';
import FileUpload from 'components/forms/elements/FileUpload';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [orgCode, setOrgCode] = useState(null);
  const [orgFields, setOrgFields] = useState(null);
  const [orgForm, setOrgForm] = useState(null);
  
  useEffect(() => {
    props.userID &&
    setUserID(props.userID);
  }, [props.userID]);

  useEffect(() => {
    if(props.userID) {
      setUserID(props.userID)
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token) {
      setToken(props.token)
    }
  }, [props.token]);

  useEffect(() => {
    if(props.organizationID && organizationID !== props.organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    props.organizationName &&
    props.organizationName !== organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);
  
  useEffect(() => {
    if(props.languageCode && languageCode !== props.languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    if(props.userAccess && props.userAccess !== userAccess) {
      setUserAccess(props.userAccess);
    } 
  }, [props.userAccess]);

  // useEffect(() => {
  //   if(orgCode) {
  //     (!props.objExists(props.admin, 'organization.organization_code') ||
  //     props.admin.organization.organization_code !== orgCode) &&
  //     props.adminSetVal('organization.organization_code', orgCode);
  //   }else{
  //     props.objExists(props.admin, 'organization.organization_code') &&
  //     props.adminDeleteVal('organization.organization_code');
  //   }
  // }, [orgCode]);

  useEffect(() => {
    props.objExists(props.files, 'uploads') &&
    Array.isArray(props.files.uploads) &&
    props.files.uploads.length > 0 &&
    props.adminSetVal(`organization.site_identity`, props.files.uploads[0]);
    props.adminSetVal(`organization.site_identity.media_type`, `site_identity`);
    // props.testForSave && props.testForSave();
  },[props.files.uploads]);

  console.log("FIles:", props.files);
  return (
    <div>
      <p>Add Organization Logo</p>
      <FileUpload {...props}
                  userID={userID}
                  organizationID={organizationID}
                  token={token}
                  organizationName={organizationName} />
    </div>
  )
}
