import React, {useEffect, useState, Fragment} from 'react';

import FormFieldListItem from 'components/forms/form_display/FormFieldListItem';
import DropZone from 'components/forms/elements/DropZone';
import Control from 'components/atoms/Control';
import Loader from 'components/atoms/Loader';
import LiveFieldInput from 'components/forms/elements/LiveFieldInput';

export default (props) => {
  const [fieldOrder, setFieldOrder] = useState(null);

  // FUNCTIONS
  function handleAddField(fieldset_id = null, option_id = null, field_count = null) {
    if(!option_id && !parseInt(fieldset_id)) {
      fieldset_id = props.fieldset_id;
      field_count = props.fields.length;
    }
    if(props.actionAdd) {
      props.actionAdd(fieldset_id, option_id, field_count);
    }
  }

  function handleSetFieldOrder(newIndex, params) {
    if(props.actionSetFieldOrder && newIndex && params) {
      props.actionSetFieldOrder(newIndex, params.fieldset_id);
    }
  }

  function handleSetUpdateField(field_id, field_order, fieldset_id) {
    if(field_id && field_order && fieldset_id &&
      props.actionSetUpdateField) {
      props.actionSetUpdateField({field_id: field_id,
                      field_order: field_order,
                      fieldset_id: fieldset_id});
    }
  }

  function handleEditField(field, isSet, isConditional, fieldset_id = null, fieldset_type = null) {
    if(props.actionEdit) {
      if(isConditional) {
        props.actionEdit(field, isSet, isConditional, fieldset_id ? fieldset_id : props.fieldset_id, isConditional ? 'Option' : null);
      }else{
        props.actionEdit(fieldset_id ? fieldset_id : props.fieldset_id, null, null, field,);
      }
    }
  }

  function handleRemoveField(params) {
    if(props.actionRemove) {
      props.actionRemove(params);
    } 
  }

  function handleFieldsetName(name, value) {
    if(props.actionFieldsetName && props.fieldset_id) {
      props.actionFieldsetName(name, value, props.fieldset_id);
    }
  }

  function handleOption(params) {
    if(props.actionOption) {
      props.actionOption(params);
    }
  }

  return(
    <div className="form-field-list">
      <h3>{
        <LiveFieldInput {...props}
                        type="text"
                        name="fieldset_name"
                        value={props.title ? props.capitalizeString(props.title, true) : null}
                        placeholder="Add Fieldset Name?"
                        disabled={props.fieldset_id ? false : true}
                        action={handleFieldsetName} />

      }</h3>
      {
        !props.isConditional &&
        <div className="form-field-list-item header">
          <div className="form-field-list-item-row">
            <div className="form-field-list-item-col field-order center" style={{flex: `0 0 2em`}}>
              #
            </div>
            <div className="form-field-list-item-col field-type" style={{flex: '0 0 5em'}}>
              Type
            </div>
            <div className="form-field-list-item-col field-label" style={{flex: '1 0 3em'}}>
              Label (Placeholder) <sub>*required</sub>
            </div>
            <div className="form-field-list-item-col field-req" style={{flex: '0 0 8em'}}>

            </div>
          </div>
        </div>
      }
      <DropZone key={`ffDZ_X_`}
                index={1}
                params={{fieldset_id: props.fieldset_id}}
                actionDrop={handleSetFieldOrder} />
      {props.fields && Array.isArray(props.fields) &&
        props.fields.map((field, index) => {
          return <Fragment key={`ffFrag${index}`}>
                  <FormFieldListItem {...props}
                                      isConditional={props.isConditional ? props.isConditional : false }
                                      field={field}
                                      fieldset_id={props.fieldset_id}
                                      index={index+1}
                                      order={fieldOrder && fieldOrder[field.field_id] ? fieldOrder[field.field_id] : null}
                                      key={`ffLstItm${index}`}
                                      actionSetUpdateField={handleSetUpdateField}
                                      actionAdd={handleAddField}
                                      actionEdit={handleEditField}
                                      actionRemove={handleRemoveField}
                                      actionOption={handleOption} />
                  <DropZone key={`ffDZ${index}`}
                            index={index+2}
                            params={{fieldset_id: props.fieldset_id}}
                            actionDrop={handleSetFieldOrder} />
                </Fragment>
        })
      }
      <Control action={handleAddField}
                icon="add"
                showLabel={true}
                label={`Add a field to this fieldset (group)?`} />

    </div>
  )
}
