import React, {useEffect, useState, Fragment} from 'react';

import FormDynamic from 'components/forms/FormDynamic';
import IconSVG from 'components/icons/IconSVG';
import {moduleIcons} from 'methods/forms';

export default (props) => {
  const [fieldsets, setFieldsets] = useState(null);
  const [form, setForm] = useState(null);

  useEffect(() => {
    let parentOptions = [];
    props.modules && Array.isArray(props.modules) &&
    props.modules.map(module => {
      if(!props.module || (props.module && module.module_id !== props.module.module_id)) {
        parentOptions.push({option_name: module[props.languageCode].module_name, option_value: module.module_id});
      }
    });
    
    const iconOptions = [];
    
    Array.isArray(moduleIcons) &&
    moduleIcons.map((icon) => {
        iconOptions.push(
            {option_name: <Fragment>
                             <IconSVG icon={icon} />
                             {props.capitalizeString(icon, true)}
                          </Fragment>, 
            option_value: icon});
    });
    
    console.log("Module Parent", String(props.module_parent));
    setFieldsets([
      {fieldset_name: `${props.objExists(props, 'host.host_id') ? 'Edit' : 'Add'} Module`,
        fields: [
          {field_type: 'text',
            field_name: 'module_name',
            field_label: `Module Name`,
            field_value: props.objExists(props, `module.${props.languageCode}.module_name`) ? props.module[props.languageCode].module_name : null,
            field_placeholder: 'Name that shows in navigation',
            field_order: 1,
            field_required: true,
            field_validate: true,},
          {field_type: 'select',
            field_name: 'module_icon',
            field_label: 'Icon',
            field_placeholder: 'Select icon',
            field_value: props.objExists(props, `module.module_icon`) ? props.module.module_icon : null,
            field_order: 2,
            field_required: false,
            field_validate: false,
            option_groups: [
              {options: iconOptions}
            ]
          },
          {field_type: 'text',
            field_name: 'module_link',
            field_label: `Module Link`,
            field_value: props.objExists(props, `module.${props.languageCode}.module_link`) ? props.module[props.languageCode].module_link : null,
            field_placeholder: 'The link to the module',
            field_order: 2,
            field_required: false,
            field_validate: true,},
          {field_type: 'text',
            field_name: 'module_component',
            field_label: `Module Component`,
            field_value: props.objExists(props, 'module.module_component') ? props.module.module_component : null,
            field_placeholder: 'The app component for the module',
            field_order: 3,
            field_required: false,
            field_validate: true},
          parentOptions &&
          Array.isArray(parentOptions) &&
          parentOptions.length > 0 &&
          {field_type: 'select',
            field_name: 'module_parent',
            field_label: 'Module Parent',
            field_value: props.objExists(props, `module.module_parent`) ? props.module.module_parent :
                          props.module_parent && parseInt(props.module_parent) > 0 ? props.module_parent : null,
            field_placeholder: 'Select Parent...',
            field_order: 4,
            field_required: false,
            field_validate: true,
            option_groups: [{options: parentOptions}]
          },
          {field_type: 'select',
            field_name: 'module_target',
            field_label: 'Open external links in',
            field_value: props.objExists(props, `module.module_target`) ? props.module.module_target :null,
            field_placeholder: 'Select target',
            field_order: 4,
            field_required: false,
            field_validate: true,
            option_groups: [{options: [
              {option_name: 'Current Page', option_value: '_current'},
              {option_name: 'New Page', option_value: '_blank'}
            ]}]
          },
          {field_type: 'hidden',
            field_name: 'process',
            field_value: props.objExists(props, 'module.module_id') ? 'update_site_module' : 'add_site_module'},
          props.objExists(props, 'module.module_id') &&
          {field_type: 'hidden',
            field_name: 'module_id',
            field_value: props.module.module_id},
          !props.objExists(props, 'module.module_id') &&
          {field_type: 'hidden',
            field_name: 'module_parent',
            field_value: props.module_parent},
          !props.objExists(props, 'module.module_id') &&
          {field_type: 'hidden',
            field_name: 'module_order',
            field_value: props.module_order},
        ]
      }
    ]);
  }, [props.module, props.modules]);

  useEffect(() => {
    fieldsets &&
    setForm(
      <FormDynamic
          content={fieldsets}
          name="add-edit-category"
          returnObject={true}
          fieldOnChange={null}
          fieldOnLoad={null}
          formActions={{formCancel: handleCancel, formSubmit: handleSubmit}} />
    )
  }, [fieldsets]);

  function handleCancel() {
    props.setModal(null);
  }

  function handleSubmit(params) {
    if(props.action) {
      props.action(params);
    }
  }

  return (fieldsets && form)
}
