import React from 'react';
import PagePublic from 'components/templates/PagePublic';

import UserTask from 'components/account/UserTask';

export default () => {
  return (
    <PagePublic>
      <UserTask />
    </PagePublic>
  )
}
