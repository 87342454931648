import React, {useEffect, useState} from 'react';

import FormDynamic from 'components/forms/FormDynamic';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [parent, setParent] = useState(null);
	const [shell, setShell] = useState(null);
	const [page, setPage] = useState(null);
	const [fieldsets, setFieldsets] = useState(null);
	const [form, setForm] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.languageCode && props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.parent && props.parent !== parent) {
			setParent(props.parent);
		}
	}, [props.parent]);
	
	useEffect(() => {
		if(props.page && props.page !== page) {
			setPage(props.page);
		}
	}, [props.page]);
	
	useEffect(() => {
		if(languageCode && userID && token && page) {
			setFieldsets([{
				fieldset_name: `Create ${parent ? `Subgroup` : `Field Group`}`,
				fields: [
					{
						field_type: 'text',
						field_name: 'group_name',
						field_label: parent ? 'Subgroup Name' : 'Group Name',
						field_required: true,
						field_validate: true,
						field_order: 1
					},
					{
						field_type: 'hidden',
						field_name: 'process',
						field_value: 'add_shell_group'
					},
					{
						field_type: 'hidden',
						field_name: 'user_id',
						field_value: userID
					},
					{
						field_type: 'hidden',
						field_name: 'token',
						field_value: token
					},
					{
						field_type: 'hidden',
						field_name: 'shell_id',
						field_value: shell.shell_id
					},
					{
						field_type: 'hidden',
						field_name: 'page_id',
						field_value: page.page_id
					},
					{
						field_type: 'hidden',
						field_name: 'group_publish',
						field_value: 1
					},
					{
						field_type: 'hidden',
						field_name: 'language_code',
						field_value: languageCode
					},
					{
						field_type: 'hidden',
						field_name: 'group_parent',
						field_value: props.objExists(parent, `group_id`) ?
							parent.group_id : null
					},
					{
						field_type: 'hidden',
						field_name: 'return_bool',
						field_value: 'true'
					}
				]
			}]);
		}
	}, [languageCode, userID, token, page, shell]);
	
	useEffect(() => {
		if(fieldsets) {
			setForm(
				<FormDynamic {...props}
					content={fieldsets}
					returnObject={true}
					formActions={{
						formSubmit: props.actionSubmit,
						formCancel: props.actionCancel
					}} />
			)
		}
	}, [fieldsets]);
	
	
	useEffect(() => {
		if(props.shell && props.shell !== shell) {
			setShell(props.shell);
		}
	}, [props.shell]);
	
	return (
		<div className="border-block">
			{parent &&
				<div className="form-row">
					Adding a subgroup to&nbsp;<b>{parent.group_name}</b>
				</div>
			}
			{form}
		</div>
	)
}