import React, {useEffect, useState, Fragment} from 'react';

import IconFile from 'components/icons/IconFile';
import IconToken from 'components/icons/IconToken';
import Loader from 'components/atoms/Loader';
import ModalConfirmation from 'components/molecules/ModalConfirmation';

export default (props) => {
	const [file, setFile] = useState(null);
	const [preview, setPreview] = useState(null);
	const [updating, setUpdating] = useState(null);
	
	useEffect(() => {
		if(props.media && props.media !== file) {
			setFile(props.media);
		}else if(props.mediaID && props.objExists(props, `${props.location}.media.${props.mediaID}[0]`) &&
			props[props.location].media[props.mediaID][0] !== file) {
			setFile(props[props.location].media[props.mediaID][0]);
		}else if(props.mediaID && props.objExists(props, `${props.location}.media.${props.mediaID}`) &&
			props[props.location].media[props.mediaID] !== file) {
			setFile(props[props.location].media[props.mediaID]);
		}
	}, [props.mediaID,
		props.media,
		props.location && 
		props.objExists(props, `${props.location}.media`) && 
		props[props.location].media]);
	
	useEffect(() => {
		if(file && file.media_file_tag &&
			['jpg', 'svg', 'gif', 'png', 'tif'].includes(file.media_file_tag)) {
			setPreview(
				<div className="modal-image-preview">
					<img src={`https://aecore.app/assets/media/${file.media_file_name}`} />
				</div>
			)
		}
		
	}, [file]);
	
	useEffect(() => {
		if(file && updating && updating !== file.media_id) {
			setUpdating(false);
		}
	}, [file]);
	
	// FUNCTIONS
	function loadRemoveFile() {
		if(props.actionRemove) {
			props.setModal(
				<ModalConfirmation {...props}
					message="Are you sure you want to remove this file?"
					actionAccept={removeFile}
					/>
			);
		}
	}
	 
	function removeFile() {
		props.setModal(null);
		if(props.actionRemove) {
			setUpdating(file.media_id);
			
			// refMedia.current.classList.add('remove');
			props.actionRemove(file.media_id, file.media_type);
		}
	}
 	
	return (
		<span className={`media-file ${props.inline ? ' inline' : ''}${props.showSpecs ? ' show-specs' : ''}${props.inline ? ' inline' : ''}${props.block ? ' block' : ''}${props.className ? ` ${props.className}` : ''}`} >
			{file ?
				<Fragment>
					{!props.hideIcon &&
						<IconFile icon={file.media_file_tag} className="p" approval={props.objGetValue(file, `approvals[0].en.status_name`)}/>
					}
					<div className="media-file-info">
						{props.objExists(file, `approvals[0].en.status_name`) &&
							<div className={`row${file.approvals[0].en.status_name === 'approved' ? ` success` : file.approvals[0].en.status_name === 'declined' ? ` error` : ''} bold`}>
								{props.capitalizeString(file.approvals[0].en.status_name, true)}
							</div> 
						}
						<div className="row">
							<span className="truncate">{file.media_file_upload_name}</span>
							{!props.hideCtl && preview && 
								<IconToken icon="preview" tooltip="preview" className="p" action={()=>{props.setModal(preview, true)}} />
							}
							{!props.hideCtl &&
								<Fragment>
									<a href={`https://api.aecore.app/files/download.php?file=${file.media_id}`} target="_blank" >
										<IconToken icon="download" tooltip="download" className="p" />
									</a>
									{props.actionRemove &&
										<IconToken icon="trash" tooltip="Remove" className="p" action={loadRemoveFile} />
									}
								</Fragment>
							}
						</div>
						{props.showSpecs &&
						<div className="row subtext">
							{file.media_file_size &&
								<span className="no-mobile">{`(${props.displayFileSize(file.media_file_size)})`}</span>
							}
							{file.media_created &&
								<span>
									{`${props.dateDisplay(file.media_created)} ${props.timeDisplay(file.media_created, false)}`}
								</span>
							}
						</div>
						}
					</div>
				</Fragment>
			:
				<span>
					{props.mediaID ?
						`File ${props.mediaID}` :
						`Unknown File`	}
				</span>
			}
			{updating && <Loader label="Removing file" overlayContents={true} />}
		</span>
	)
}