import React, {useEffect, useState, Fragment} from 'react';
import ReactDOM from 'react-dom';

import Button from 'components/forms/elements/Button';
import IconToken from 'components/icons/IconToken';

export default (props) => {
  const [modalContent, setModalContent] = useState(null);
  const [modalWrapper, setModalWrapper] = useState(null);
  const [modal, setModal] = useState(null);
  const [closeBtn, setCloseBtn] = useState(null);
  
  // HOOKS
  // useEffect(() => {
  //   if(props.modal.unmountOnClick === true) {
  //     setUnmountOnClick(true);
  //   }
  // }, [props.modal);
  
  useEffect(() => {
    if(props.objExists(props, 'modal.content')) {
      setModalContent(props.modal.content);
    }else{
      setModalContent(null);
    }
  }, [props.modal, props.files]);

  useEffect(() => {
    if(modalContent) {
      !document.body.classList.contains('modal-active') &&
      document.body.classList.add('modal-active');
    }else{
      document.body.classList.contains('modal-active') &&
      document.body.classList.remove('modal-active');
    }
  }, [modalContent, modalWrapper, closeBtn, modal, props] );

  // FUNCTIONS
  function handleClose() {
    props.setModal(null);
      
    if(props.modal.onClose) {
      props.modal.onClose();
    }
  }
  
  return (
    <Fragment>
    {modalContent &&
      <div id="modal" 
            className={`modal-wrapper`}
            onClick={props.modal.unmountOnClick === true ? handleClose : null} >
        <div className={`modal${props.modal.closable ? ' closable' : ''}`}>
          {typeof(modalContent) === 'string' ?
            <div className="modal-message">
              {modalContent}
              <div className="form-row">
                <Button icon="close" label="Close" action={handleClose} />
              </div>
            </div>
            :
            modalContent
          }
          {props.modal.closable &&
            <IconToken icon='close' className="modal-close" action={()=>{props.setModal(null)}} />
          }
        </div>
      </div>
    }
   </Fragment> 
  )
}
