import React, {Fragment} from 'react';

import Control from 'components/atoms/Control';
import List from 'components/molecules/List';

export default (props) => {

  function addEditPort(port_id) {
    if(props.actionAddEdit) {
      props.actionAddEdit(port_id);
    }
  }

  function removePort(port_id) {
    if(props.actionRemove) {
      props.actionRemove(port_id, 'portToOrganization')
    }
  }

  return (
    <div className="list-set">
      {props.ports && Array.isArray(props.ports) &&
        <List {...props}
              list={props.ports.map((port, index) => {
                      return {name: port.port_num, id: port.port_id, index: index}
                    })}
              listName="Ports"
              listIcon="elipse"
              actionEditItem={addEditPort}
              actionRemoveItem={removePort}
              editable={true}
              listBox={true} />
      }
      <div className="list-set-ctl">
        <Control icon="add" label="Add a port" showLabel={true} action={addEditPort} />
      </div>
    </div>
  )
}
