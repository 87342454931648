import React, { useRef, useEffect, useLayoutEffect, useState, useCallback, Fragment } from 'react';
import ReactDOM from 'react-dom';

import * as utils from 'methods/site';
import * as modal from 'actions/modal';

import Form from 'components/forms/Form';
import Input from 'components/forms/elements/Input';
import Select from 'components/forms/elements/Select';
import TextArea from 'components/forms/elements/TextArea';
import Switch from 'components/forms/elements/Switch';
import RadioSet from 'components/forms/elements/RadioSet';
import InputDate from 'components/forms/elements/InputDate';
import InputTime from 'components/forms/elements/InputTime';
import InputFile from 'components/forms/elements/InputFile';
import InputMulti from 'components/forms/elements/InputMulti';
import CheckList from 'components/forms/elements/CheckList';
import QtyList from 'components/forms/elements/QtyList';
import FormSetCtl from 'components/forms/FormSetCtl';
import FormCompleteCtl from 'components/forms/FormCompleteCtl';
import Loader from 'components/atoms/Loader';

export default (props) => {
  props = {...props, ...utils}
  const [stepHeight, setStepHeight] = useState(0);
  const [formComplete, setComplete] = useState(false);
  const [curStep, setCurStep] = useState(null);
  const [lastComplete, setLastComplete] = useState(null);
  const [stageValidation, setStageValidation] = useState([]);
  const conditionalFields = {};
  const [formContent, setFormContent] = useState(null);
  const [submit, setSubmit] = useState(false);
  const [alertMsg, setAlertMsg] = useState(null);
  const [data, setData] = useState(null);
  const [fileUploads, setFileUploads] = useState(null);
  const [filesUploading, setFilesUploading] = useState(null);
  const [missingValues, setMissingValues] = useState(null);
  const [submitReady, setSubmitReady] = useState(null);
  
  // const [minDate, setMinDate] = useState(null);
  // let formContent = setContent(props.content);
  const stepSet = useRef();
  const formRef = useRef();
  const btnSubmit = useRef();


  // CONSTRUCTION INITIAL FIELDSETS WITH REFS;
  const fsRefs = props.content.map((set, index) => {
    return useRef();
  })
  let fieldsets = [];
  let formStages = [];
  
  useEffect(() => {
    setFormContent(null);
  }, [])
  
  useEffect(() => {
    if(props.alertMsg && props.alertMsg !== alertMsg) {
      setAlertMsg(props.alertMsg);
    }else if (!props.alertMsg && alertMsg) {
      setAlertMsg(null);
    }
  }, [props.alertMsg]);
  
  useEffect(() => {
    if(missingValues) {
      const activeModals = document.getElementsByClassName('modal');
      if(activeModals) {
        activeModals[0].scrollTo(0, 0);
      }
        
    }
  }, [missingValues]);
  
  useEffect(() => {
    props.content.map((set, index) => {
      fieldsets.push(
              <fieldset disabled={(props.content.length === 0 || index === 0)? false : true}
                        ref={fsRefs && fsRefs[index] ?
                                fsRefs[index] :
                                null}
                        id={`fset${index}`}
                        key={`set${index}`}>
                  <legend>{set.fieldset_name}</legend>
                      {makeContent(set.fields)}
              </fieldset>);
      formStages.push({name: set.name,
                        ref: fsRefs && fsRefs[index] ?
                                fsRefs[index] :
                                null});
    })
    setFormContent({content: fieldsets, refs: fsRefs, stages: formStages});
  }, [props.content, props.files, missingValues]);
  
  useEffect(() => {
    if(Array.isArray(filesUploading) && filesUploading.length > 0) {
      let ulComplete = true;
      if(Array.isArray(fileUploads)) {
        filesUploading.map(fFieldName => {
          if(!fileUploads.find(ulFile => ulFile.name === fFieldName)) {
            ulComplete = false;
          }
        }) 
      }else{
        ulComplete = false;
      }
      if(ulComplete) {
        setFilesUploading(null);
      }
    }
  }, [fileUploads, filesUploading, props.files]);

  function getCndtlName(field) {
    const inputs = field.querySelectorAll('input[type=hidden]');
    if(inputs.length === 1) {
      return inputs[0].name;
    }else{
      return null; // This may be an issues in cases of multiple fields)(?)
    }
  }

  function clearConditionalFields(target, field_name) {
    const existingConditionalFields = target.querySelectorAll(`.form-fields-conditional.cndtl${utils.codifyPlainText(field_name.replace(/\./g, '_').replace(/[\[\]']+/g,''))}`);
    if(existingConditionalFields.length > 0) {
      existingConditionalFields.forEach( cFields => {
        const cndtlChildName = getCndtlName(cFields);
        if(cndtlChildName) {
          clearConditionalFields(target, cndtlChildName);
        }
        cFields.remove();
      })
    }
  }

  function showConditionalField(target, field_name, selectValue, location) {
    if(target && conditionalFields[`${field_name}${selectValue}`] &&
        target.querySelectorAll(`.cndtl${utils.codifyPlainText(field_name.replace(/\./g, '_').replace(/[\[\]']+/g,''))}`).length <= 0) {
      const testEl = document.createElement('div');
      testEl.classList.add('form-fields-conditional');
      testEl.classList.add(`cndtl${utils.codifyPlainText(field_name.replace(/\./g, '_').replace(/[\[\]']+/g,''))}`);
      ReactDOM.render(conditionalFields[`${field_name}${selectValue}`], testEl);
      location.append(testEl);
    }
  }

  function setConditionalFields(e) {
    let target = e;
    let eTarget = e;
    if(e.target) {
      eTarget = e.target;
      target = e.target;
    }

    while(target && target.tagName.toLowerCase() !== 'fieldset' && target.tagName.toLowerCase() !== 'body') {
      target = target.parentNode;
    }
    target &&
    clearConditionalFields(target, eTarget.lastChild.name);

    if(eTarget.lastChild.value) {

      let location = eTarget.lastChild;
      while(location && !location.classList.contains('form-row') && location.tagName.toLowerCase() !== 'body') {
        location = location.parentNode;
      }
      showConditionalField(target, eTarget.lastChild.name, utils.codifyPlainText(eTarget.lastChild.value), location);
    }
  }

  function onloadShowConditional(target, field_name, selectValue) {
    if(target) {
      let location = target;
      while(location && !location.classList.contains('form-row') && location.tagName.toLowerCase() !== 'body') {
        location = location.parentNode;
      }
      while(target && target.tagName.toLowerCase() !== 'fieldset' && target.tagName.toLowerCase() !== 'body') {
        target = target.parentNode;
      }
      target && showConditionalField(target, field_name, selectValue, location);
    }
  }

  function isConditional(fieldOptionGroups) {
    let isConditional = false;

    if(Array.isArray(fieldOptionGroups) && fieldOptionGroups.length > 0) {
      fieldOptionGroups.map(group => {
        if(Array.isArray(group.options) && group.options.length > 0) {
          group.options.map(option => {
            if(option.fieldsets) {
              isConditional = true;
            }
          })
        }
      })
    }
    return isConditional;
  }

  function conditionalActive(field) {
    let conditionalActive = false;
    if(field.option_groups && Array.isArray(field.option_groups)) {
      field.option_groups.map(option_group => {
        if(Array.isArray(option_group.options)) {
          if(field.field_default) {
            option_group.options.map(option => {
              if(option.fieldsets && option.option_value === field.field_default) {
                  conditionalActive = true;
              }
            })
          }
        }
      })
    }
    return conditionalActive;
  }

  function updateForm() {
    let validation = true;
    let fieldsComplete = [];
    formContent && formContent.refs &&
    formContent.refs.map((ref, index) => {
      let setFieldsInvalid = [];
      const inputs = ref.current.querySelectorAll('input, select, textarea');
      // const inputs = document.getElementById(`fset${index}`).querySelectorAll('input, select');

      for(var i = 0; i < inputs.length; i++) {

        if((inputs[i].required === true && (!inputs[i].value || inputs[i].value === '')) ||
            (inputs[i].type === 'email' && inputs[i].value && !utils.isEmail(inputs[i].value)) ||
            ((inputs[i].type === 'tel' || inputs[i].type === 'phone') && inputs[i].value && !utils.isPhone(inputs[i].value)) ) {
          validation = false;
          setFieldsInvalid.push({name: inputs[i].name, validation: false});
          if(inputs[i].value) {
            inputs[i].parentNode && inputs[i].parentNode.classList.contains('valid') && inputs[i].parentNode.classList.remove('valid');
            inputs[i].parentNode && !inputs[i].parentNode.classList.contains('invalid') && inputs[i].parentNode.classList.add('invalid');
          }else{
            inputs[i].parentNode && inputs[i].parentNode.classList.contains('invalid') &&
            inputs[i].parentNode.classList.remove('invalid');

            inputs[i].parentNode && inputs[i].parentNode.classList.contains('valid') &&
            inputs[i].parentNode.classList.remove('valid');
          }
        }else if (inputs[i].required){
          inputs[i].parentNode && inputs[i].parentNode.classList.contains('invalid') &&
          inputs[i].parentNode.classList.remove('invalid');

          inputs[i].parentNode && !inputs[i].parentNode.classList.contains('valid') &&
          inputs[i].parentNode.classList.add('valid');
        }
      }
      fieldsComplete.push({ref: ref, setFieldsInvalid: setFieldsInvalid});
      return null;
    })
    setStageValidation(fieldsComplete);

    if(validation) {
      setComplete(true);
    }else{
      setComplete(false);
    }
  }

  function handleChange(e) {
    let target = e;
    while(target.tagName.toLowerCase() !== 'fieldset' && target.tagName.toLowerCase() !== 'body') {
      if(target.parentNode) {
        target = target.parentNode;
      }else{
        // I'm not sure where this getting messed up but it might be the nested conditional fields
        break;
      }

    }
    clearConditionalFields(target, e.parentNode.lastChild.name);
    props.fieldOnChange &&
    props.fieldOnChange({name: e.name, value: e.value});
  }

  function setField(field, index, tabIndex) {
    
    let missingVal = null;
    const fieldName = field.field_id ? String(field.field_id) : field.field_name;
    if(Array.isArray(missingValues) &&
        missingValues.includes(fieldName)){
        missingVal = true;
    }
    
    const fieldValue = field.field_default;
    let thisField;
    switch (field.field_type) {
          case 'text':
          case 'email':
          case 'password':
          case 'tel':
          case 'phone':
          case 'number':
          case 'float':
          case 'price':
          case 'cc-number':
          case 'cc-expiry':
          case 'cc-cvv':
            thisField = <div className="form-row" key={`field${index}`}>
                          <Input type={field.field_type}
                            {...props}
                            key={`ifield${index}`}
                            defaultValue={props.formValues && props.formValues[field.field_id ? field.field_id : field.field_name] ?
                              props.formValues[field.field_id ? field.field_id : field.field_name] :
                              (field.field_value || field.field_value === 0 || field.field_value === '0') ? field.field_value : field.field_default}
                            value=''
                            isDecimal={field.field_type === 'float' ? true: false}
                            name={field.field_id ? field.field_id : field.field_name}
                            label={field.field_label}
                            placeholder={field.field_placeholder ? field.field_placeholder : null}
                            required={field.field_required}
                            isMissing={missingVal}
                            updateAction={isConditional(field.option_groups) ? [setConditionalFields, updateForm] : [updateForm]}
                            onChange={field.fieldOnChange ? field.fieldOnChange : props.fieldOnChange ? props.fieldOnChange : null}
                            onBlur={field.fieldOnBlur ? field.fieldOnBlur : props.fieldOnBlur ? props.fieldOnBlur : null}
                            onLoad={props.fieldOnLoad ? props.fieldOnLoad : null}
                            tabIndex={field.field_order ? field.field_order : index+1}
                            disabled={field.field_disabled === true ? true : false}
                            focus={false}
                            reveal={field.field_reveal ? field.field_reveal : null}
                            />
                        </div>
            break;
          case 'select':
            thisField = <div className="form-row" key={`field${index}`}>
                          <Select {...props}
                                  key={`sfield${index}`}
                                  option_groups={[...field.option_groups]}
                                  name={field.field_id ? field.field_id : field.field_name}
                                  index={field.field_index ? field.field_index : null}
                                  defaultValue={props.formValues && props.formValues[field.field_id ? field.field_id : field.field_name] ?
                                                      props.formValues[field.field_id ? field.field_id : field.field_name] :
                                                      (field.field_value || field.field_value === 0 || field.field_value === '0') ? field.field_value : field.field_default}
                                  conditionalOnLoad={conditionalActive(field) ? onloadShowConditional : null}
                                  label={field.field_label}
                                  required={field.field_required ? true : false}
                                  isMissing={missingVal}
                                  updateAction={isConditional(field.option_groups) ? [setConditionalFields, updateForm] : [updateForm]}
                                  onChange={field.fieldOnChange ? field.fieldOnChange : props.fieldOnChange ? props.fieldOnChange : handleChange}
                                  clearConditional={clearConditionalFields}
                                  onLoad={props.fieldOnLoad ? props.fieldOnLoad : null}
                                  placeholder={field.field_placeholder}
                                  tabIndex={field.field_order ? field.field_order : index+1}
                                  returnObject={field.field_returnObject ? true : false} />
                        </div>
            break;
          case 'checklist':
            thisField = <div className="form-row" key={`field${index}`}>
                          <CheckList {...props}
                                  key={`clfield${index}`}
                                  checklist={field.option_groups}
                                  name={field.field_id ? field.field_id : field.field_name}
                                  index={field.field_index ? field.field_index : null}
                                  defaultValue={props.formValues && props.formValues[field.field_id ? field.field_id : field.field_name] ?
                                                      props.formValues[field.field_id ? field.field_id : field.field_name] :
                                                      (field.field_value || field.field_value === 0 || field.field_value === '0') ? field.field_value : field.field_default}
                                  conditionalOnLoad={conditionalActive(field) ? onloadShowConditional : null}
                                  label={field.field_label}
                                  required={field.field_required ? true : false}
                                  isMissing={missingVal}
                                  updateAction={isConditional(field.option_groups) ? [setConditionalFields, updateForm] : [updateForm]}
                                  onChange={field.fieldOnChange ? field.fieldOnChange : props.fieldOnChange ? props.fieldOnChange : handleChange}
                                  clearConditional={clearConditionalFields}
                                  onLoad={props.fieldOnLoad ? props.fieldOnLoad : null}
                                  placeholder={field.field_placeholder}
                                  tabIndex={field.field_order ? field.field_order : index+1}
                                  returnObject={field.field_returnObject ? true : false} />
                        </div>
            break;
          case 'qtylist':
            thisField = <div className="form-row" key={`field${index}`}>
                          <QtyList {...props}
                                  key={`qlfield${index}`}
                                  options={field.option_groups}
                                  name={field.field_id ? field.field_id : field.field_name}
                                  index={field.field_index ? field.field_index : null}
                                  value={props.formValues && props.formValues[field.field_id ? field.field_id : field.field_name] ?
                                                      props.formValues[field.field_id ? field.field_id : field.field_name] :
                                                      (field.field_value || field.field_value === 0 || field.field_value === '0') ? field.field_value : field.field_default}
                                  conditionalOnLoad={conditionalActive(field) ? onloadShowConditional : null}
                                  label={field.field_label}
                                  required={field.field_required ? true : false}
                                  isMissing={missingVal}
                                  updateAction={isConditional(field.option_groups) ? [setConditionalFields, updateForm] : [updateForm]}
                                  onChange={field.fieldOnChange ? field.fieldOnChange : props.fieldOnChange ? props.fieldOnChange : handleChange}
                                  clearConditional={clearConditionalFields}
                                  onLoad={props.fieldOnLoad ? props.fieldOnLoad : null}
                                  placeholder={field.field_placeholder}
                                  tabIndex={field.field_order ? field.field_order : index+1}
                                  returnObject={field.field_returnObject ? true : false} />
                        </div>
            break;
          case 'switch':
            let fieldValue = false;
            if (props.formValues && props.formValues[field.field_id ? field.field_id : field.field_name]) {
              if (props.formValues[field.field_id ? field.field_id : field.field_name] === true || props.formValues[field.field_id ? field.field_id : field.field_name] === 'true') {
                fieldValue = true;
              }
            } else if (field.field_value){
              if (field.field_value === true || field.field_value === 'true') {
                fieldValue = true;
              }
            } else if (field.field_default){
              if (field.field_default === true || field.field_default === 'true') {
                fieldValue = true;
              }
            }
            thisField = <div className="form-row" key={`field${index}`}>
                          <Switch {...props}
                                  key={`swfield${index}`}
                                  name={field.field_id ? field.field_id : field.field_name}
                                  value={fieldValue}
                                  label={field.field_label}
                                  onChange={field.fieldOnChange ? field.fieldOnChange : props.fieldOnChange ? props.fieldOnChange : null}
                                  onLoad={props.fieldOnLoad ? props.fieldOnLoad : null}
                                  tabIndex={field.field_order ? field.field_order : index+1}
                                   />
                        </div>
            break;
          case 'textarea':
            thisField = <div className="form-row" key={`field${index}`}>
                          <TextArea
                            {...props}
                            key={`tafield${index}`}
                            name={field.field_id ? field.field_id : field.field_name}
                            label={field.field_label}
                            placeholder={field.field_placeholder}
                            onChange={field.fieldOnChange ? field.fieldOnChange : props.fieldOnChange ? props.fieldOnChange : null}
                            onLoad={props.fieldOnLoad ? props.fieldOnLoad : null}
                            value={props.formValues && props.formValues[field.field_id ? field.field_id : field.field_name] ?
                                                props.formValues[field.field_id ? field.field_id : field.field_name] :
                                                field.field_value ? field.field_value : field.field_default}
                            tabIndex={field.field_order ? field.field_order : index+1}
                            required={field.field_required}
                            isMissing={missingVal}
                            updateAction={isConditional(field.option_groups) ? [setConditionalFields, updateForm] : [updateForm]}
                            richText={props.field_format_text === true ? true : false}
                            codeText={props.field_allow_code === true ? true : false}
                            showCtl={props.field_show_ctl === true ? true : false} />
                        </div>
            break;
            case 'radio':
              thisField = <div className="form-row" key={`field${index}`}>
                            <RadioSet
                              {...props}
                              key={`rsfield${index}`}
                              name={field.field_id ? field.field_id : field.field_name}
                              label={field.field_label}
                              onChange={field.fieldOnChange ? field.fieldOnChange : props.fieldOnChange ? props.fieldOnChange : null}
                              onLoad={props.fieldOnLoad ? props.fieldOnLoad : null}
                              value={props.formValues && props.formValues[field.field_id ? field.field_id : field.field_name] ?
                                                  props.formValues[field.field_id ? field.field_id : field.field_name] :
                                                  field.field_value ? field.field_value : field.field_default}
                              tabIndex={field.field_order ? field.field_order : index+1}
                              required={field.field_required}
                              isMissing={missingVal}
                              option_groups = {field.option_groups}
                              />
                          </div>
              break;
            case 'date':
              const curDate = new Date();
              let dateFactor;
              if(field.field_min) {
                switch(field.field_min.toLowerCase()) {
                  case 'days':
                  case 'day':
                    if(parseInt(field.field_min_value) > 0) {
                      dateFactor = parseInt(field.field_min_value);
                    }else{
                      dateFactor = 5;
                    }
                    break;
                  case 'weeks':
                  case 'week':
                    if(parseInt(field.field_min_value) > 0) {
                      dateFactor = parseInt(field.field_min_value)*7;
                    }else{
                      dateFactor = 7;
                    }
                    break;
                  case 'months':
                  case 'month':
                    if(parseInt(field.field_min_value) > 0) {
                      dateFactor = parseInt(field.field_min_value)*30;
                    }else{
                      dateFactor = 30;
                    }
                    break;
                  default:
                    dateFactor = 5
                    break;
                }  
              }
              
              curDate.setDate(curDate.getDate()+parseInt(dateFactor));
              const minDate = `${curDate.getFullYear()}-${curDate.getMonth()+1}-${curDate.getDate()}`;
              thisField =  <InputDate {...props}
                                      key={`idfield${index}`}
                                      name={field.field_id ? field.field_id : field.field_name}
                                      label={field.field_label}
                                      placeholder={field.field_placeholder ? field.field_placeholder : null}
                                      onChange={field.fieldOnChange ? field.fieldOnChange : props.fieldOnChange ? props.fieldOnChange : null}
                                      onLoad={props.fieldOnLoad ? props.fieldOnLoad : null}
                                      value={props.formValues && props.formValues[field.field_id ? field.field_id : field.field_name] ?
                                              props.formValues[field.field_id ? field.field_id : field.field_name] :
                                              field.field_value ? field.field_value : field.field_default}
                                      required={field.field_required}
                                      tabIndex={field.field_order ? field.field_order : index+1}
                                      dateFormat='text'
                                      language_code={'en'}
                                      minDate={minDate}
                                      maxDate={null} />
              break;
            case 'multi':
              thisField = <InputMulti {...props}
                                      key={`imfield${index}`}
                                      name={field.field_id ? field.field_id : field.field_name}
                                      label={field.field_label}
                                      placeholder={field.field_placeholder ? field.field_placeholder : null}
                                      option_groups={field.option_groups ? field.option_groups : null}
                                      onChange={field.fieldOnChange ? field.fieldOnChange : props.fieldOnChange ? props.fieldOnChange : null}
                                      onLoad={props.fieldOnLoad ? props.fieldOnLoad : null}
                                      value={props.formValues && props.formValues[field.field_id ? field.field_id : field.field_name] ?
                                              props.formValues[field.field_id ? field.field_id : field.field_name] :
                                              field.field_value ? field.field_value : field.field_default}
                                      required={field.field_required}
                                      tabIndex={field.field_order ? field.field_order : index+1} />
              break;
            // case 'date':
            // thisField =  <InputDate {...props}
            //                         name={field.field_id ? field.field_id : field.field_name}
            //                         label={field.field_label}
            //                         placeholder={'YYYY-MM-DD'}
            //                         value={props.formValues && props.formValues[field.field_id ? field.field_id : field.field_name] ?
            //                                 props.formValues[field.field_id ? field.field_id : field.field_name] :
            //                                 field.field_value ? field.field_value : field.field_default}
            //                         required={field.field_required}
            //                         dateFormat='text'
            //                         minDate={null}
            //                         maxDate={null} />
            // break;
            case 'file':
              thisField = <InputFile {...props}
                                      key={`iffield${index}`}
                                      name={field.field_id ? field.field_id : field.field_name}
                                      label={field.field_label}
                                      placeholder={'YYYY-MM-DD'}
                                      value={props.formValues && props.formValues[field.field_id ? field.field_id : field.field_name] ?
                                              props.formValues[field.field_id ? field.field_id : field.field_name] :
                                              field.field_value ? field.field_value : field.field_default}
                                      required={field.field_required}
                                      onChange={updateFileField}
                                      actionCancel={null}
                                       />
              break;
          case 'hidden':
            thisField = <input key={`hfield${index}`}
                                type="hidden"
                                name={field.field_id ? field.field_id : field.field_name}
                                value={field.field_value ? field.field_value :
                                        field.field_default ? field.field_default : null} />
            break;
          default:
            break;
        }
      return thisField;
  }

  function getSetFields (set, index) {
    return (
      set.fields && set.fields.map((field, findex) => {
        return setField(field, findex);
      })

    )
  }

  function makeContent (fields, conditionalIndex = false) {
    let content = [];
    fields.map( (field, index) => {
      if(props.user && props.user.user_properties) {
        props.user.user_properties.map(property => {
          if (property.property_name.toLowerCase() === field.field_label.toLowerCase()) {
            field.field_default = property.property_value;
          }
        })
      }
      let tabIndex = index+1;
      if(conditionalIndex) {
        tabIndex += conditionalIndex+1;
      }
      content.push(setField(field, index, tabIndex));
      processConditional(field, index);
    })
    return content;
  }

  function processConditional (field, index = 0) {
    let cndtlFields = null;
    if(field.option_groups) {
      field.option_groups.map(option_group => {
        option_group.options.map(option => {
          if(option.fieldsets) {
            option.fieldsets.map(fieldset => {
              if(fieldset.fields) {
                // **** THIS CHANGED TO INCLUDE FIELD NAME ****
                conditionalFields[`${field.field_name}${utils.codifyPlainText(option.option_value)}`]=
                  <Fragment>
                    {makeContent(fieldset.fields, index)}
                  </Fragment>
              }
            })
          }
        })
      })
    }
    return cndtlFields;
  }

  // function setContent(content) {
  //   let thisContent = [];
  //   let theseRefs = [];
  //   let theseStages = [];
  //
  //     content.map((set, index) => {
  //       const theseFields = getSetFields(set, index);
  //       const setRef = useRef();
  //       thisContent.push(<fieldset disabled={(content.length === 0 || index === 0)? false : true} ref={setRef ? setRef : null} key={`set${index}`}>
  //                   <legend>{set.fieldset_name}</legend>
  //                       {makeContent(set.fields)}
  //                   </fieldset>);
  //
  //               theseRefs.push(setRef);
  //               theseStages.push({name: set.name, ref: setRef});
  //       return null;
  //     })
  //     return {content: thisContent, refs: theseRefs, stages: theseStages};
  //
  // }



  // function mapContent(set, index) {
  //   const theseFields = getSetFields(set, index);
  //   const setRef = useRef();
  //   const content = <fieldset disabled={(content.length === 0 || index === 0)? false : true} ref={setRef ? setRef : null} key={`set${index}`}>
  //                       <legend>{set.fieldset_name}</legend>
  //                         {makeContent(set.fields)}
  //                   </fieldset>
  //
  //   return {ref: setRef, content: content, stage: {name: set.name, ref: setRef }}
  // }

  function setAuxFields(fields) {
    let auxFields = [];

    fields.map((field) => {
      return auxFields.push(setField(field));
    })

    return auxFields;
  }

  const updateLastCompleteStage = useCallback((stage) => {
    if(stage !== lastComplete) {
      setLastComplete(stage);
    }
    return null;
  }, [lastComplete])

  function removeCompleteStage(stage) {
    setLastComplete(null);
  }

  useEffect(() => {
    props.content &&
    updateForm();
  }, [props.content]);

  useEffect(() => {
    if(formContent && formContent.refs && props.multiStep !== false) {
      formContent.refs.map(ref => {
        ref.current.offsetHeight > stepHeight && setStepHeight(ref.current.offsetHeight);
        return null;
      })
      // stepSet.current.style.height = (stepHeight+10)+'px';
    }

    if(!curStep && formContent && formContent.refs) {
      formContent.refs.map( (ref, index) => {
        if(index === 0 || ref.current.classList.contains('active')) {
            return setCurStep(ref.current);
        }else{
            return null;
        }
      })
    }

    let lastCompleteStage = null;
    let lastIncompleteStage = null;
    stageValidation.map(stage => {
      if(stage.setFieldsInvalid.length === 0 && !lastIncompleteStage) {
        return lastCompleteStage = stage.ref.current;
      }else if (stage.setFieldsInvalid.length > 0){
        return lastIncompleteStage = stage.ref.current;
      }else{
        return null;
      }
    })

    if(lastCompleteStage) {
        updateLastCompleteStage(lastCompleteStage);
    }else{
        removeCompleteStage();
    }

  }, [formContent, stepHeight, stepSet, curStep, stageValidation, lastComplete, updateLastCompleteStage]);
  
  useEffect(() => {
    updateForm();
  }, [formContent]);

  function disableForm(formData) {
    return false;
  }

  function submitForm(formData, target) {
    // console.log("Submitting Form", formData);
    if(props.disableForm) {
        disableForm();
        return false;
    }else{
      let uploadingFiles = [];
      let holdSubmit = false;
      const fileUploads = target.getElementsByClassName('file-upload-zone');
      for(var i = 0; i < fileUploads.length; i++) {
        const pendingValue = fileUploads[i].querySelectorAll('input[type=file]');
        if(pendingValue.length > 0 && pendingValue[0].value) {
          const uploadBtn = fileUploads[i].querySelectorAll('.btn-upload');
          uploadingFiles.push(pendingValue[0].name);
          
          if(uploadBtn[0]) {
            uploadBtn[0].click();
            holdSubmit = true;
          }
        }
      }
      
      if(!holdSubmit && uploadingFiles.length <= 0) {
        props.formActions &&
        props.formActions.formSubmit &&
        props.formActions.formSubmit(formData);
        
        if(props.clearOnSubmit) {
          clearFormValues(target);
        }  
      }else{
        setFilesUploading(uploadingFiles);
        setData(formData);
      }
      
    }
  }
  
  function updateFileField(params) {
    if(params.value) {
      let fUploads = [];
      if(fileUploads) {
        fUploads.push(...fileUploads);
      }
      fUploads.push(params);
      
      if(fUploads !== fileUploads) {
        setFileUploads(fUploads);
      }
    }
  }
  
  useEffect(() => {
    if(Array.isArray(filesUploading) && Array.isArray(fileUploads) && data) {
      let submitReady = true;
      filesUploading.map(fileName => {
        if(!fileUploads.find(fUp => String(fUp.name) === String(fileName))) {
          submitReady = false;
        }
      });
      
      if(submitReady) {
        const formData = [...data, ...fileUploads];
        props.formActions &&
        props.formActions.formSubmit &&
        props.formActions.formSubmit(formData);
        setData(null);
        
        if(props.clearOnSubmit) {
          clearFormValues(formRef.current);
        }
      }
    }
  }, [filesUploading, fileUploads, data, props.files]);

  function cancelForm(e) {
    // e.preventDefault();
    if(props.formActions &&
        props.formActions.formCancel) {
      props.formActions.formCancel(e);
    }
  }

  function clearFormValues(target) {
    const inputNodes = target.querySelectorAll('input');
    inputNodes.forEach(node => {
      node.value = '';
    })
    target.reset();
  };

  return (
    <Fragment>
      <Form {...props}
            name={props.name}
            formAction={submitForm}
            formSubmit={submit}
            onComplete={()=>{setSubmit(false)}}
            returnObject={props.returnObject ? true : false}
            clearOnSubmit={props.clearOnSubmit ? true : false}
            formClass={props.formClass}
            loadAction={updateForm}
            onUpdate={updateForm}
            formType={props.formType}
            formRef={formRef}
            submitRef={btnSubmit}
            alertMsg={alertMsg ? alertMsg : null}
            actionMissingValues={setMissingValues} >
        <div className="form-multi-step-veiwport">
          <div className={props.multiStep !== false ? 'form-multi-step-container' : 'form-auto-height'} ref={stepSet}>
          {formContent && formContent.content}
          </div>
        </div>
        {formContent && props.additionalFields && props.additionalFields.length > 0 && setAuxFields(props.additionalFields) &&
          <FormSetCtl stages={formContent.stages} curStep={curStep} setAction={setCurStep} lastComplete={lastComplete} progressive={true} />
        }
        {formContent && formContent.stages && formContent.stages.length > 1 &&
          <FormSetCtl stages={formContent.stages} curStep={curStep} setAction={setCurStep} lastComplete={lastComplete} progressive={true} />
        }
        {props.includeControls !== false &&
          <FormCompleteCtl cancelable={props.cancelable !== false ? true : false}
                            formID={props.name}
                            formComplete={formComplete && !props.disabled}
                            actionCancel={cancelForm}
                            actionSubmit={()=>{setSubmit(true)}}
                            formCompleteLabel={props.formCompleteLabel}
                            formCancelLabel={props.formCancelLabel}
                            submitRef={btnSubmit} />
        }
  
      </Form>
      {filesUploading &&
        <Loader label="Uploading files..." overlayContents={true} />
      }
    </Fragment>
  )
}
