import React, {useEffect, useState} from 'react';

import ProjectListItem from 'components/profiles/projects/ProjectListItem';
import Loader from 'components/atoms/Loader';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [token, setToken] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);

  useEffect(() => {
    props.userID &&
    setUserID(props.userID);
  }, [props.userID]);

  useEffect(() => {
    props.token &&
    setToken(props.token);
  }, [props.token])

  useEffect(() => {
    props.organizationID &&
    setOrganizationID(props.organizationID);
  }, [props.organizationID]);

  useEffect(() => {
    props.organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);

  useEffect(() => {
    props.languageCode &&
    setLanguageCode(props.languageCode);
  }, [props.languageCode]);




  return (
    <div className="info-list projects">
      {props.objExists(props, 'orders') &&
      Array.isArray(props.orders)  ?
        props.orders.map((project, index) => {
          return (
            <ProjectListItem {...props}
                                key={`prjtlst${index}`}
                                project={project}
                                isAdmin={props.isAdmin}
                                location={props.location}
                                canEdit={props.canEdit}
                                isSuper={props.isSuper}
                                viewAction={null}
                                userID={userID}
                                token={token}
                                organizationID={organizationID}
                                organizationName={organizationName}
                                languageCode={languageCode} />
          )
        }) :
        <Loader />
      }
    </div>
  )
}
