import React from 'react';

import IconToken from 'components/icons/IconToken';
import FormDynamic from 'components/forms/FormDynamic';
import ModalConfirmation from 'components/molecules/ModalConfirmation';

export default (props) => {

  let specFields = [];
  if(typeof props.name !== "string" && Object.keys(props.name).length > 0) {
    specFields.push({fieldset_name: `Update Set`, fields: []});
    Object.keys(props.name).map(key => {
      specFields[0].fields.push(

        { field_type: props.getPropertyType(key).type,
            field_name: props.name[key].property_id ? `property_id:${props.name[key].property_id}` : `${props.location}[${props.index}].property_value`,
            field_label: props.getPropertyType(props.name[key].name).label ? props.getPropertyType(props.name[key].name).label : props.name[key].name,
            field_value: props.name[key].value,
            field_required: true,
        }
      )
    })
  }else{
    specFields.push(
      {fieldset_name: `Update ${props.name}`,
        fields: [
          { field_type: props.getPropertyType(props.name).type,
            field_name: props.property_id ? `property_id:${props.property_id}` : `${props.location}[${props.index}].property_value`,
            field_label: props.getPropertyType(props.name).label ? props.getPropertyType(props.name).label : props.name,
            field_value: props.value,
            field_required: true,
          }
        ]
      })
  }
  function buildEditForm() {
    return <FormDynamic
          content={specFields}
          name="editSpecs"
          formActions={{formSubmit: processForm, formCancel: cancelForm}}
          closable={false}
          unmountOnClick={true}
          cancelable={true}
          includeControls={true}
          formCompleteLabel="update"
          multiStep={false}
          disableForm={false}
          clearOnSubmit={true}
          returnObject={true}
          formClass=""
          valActions={{isEmail: props.isEmail}} />
  }

  function processForm(formData) {
    console.log("editing the form", formData);
    let params = [];

    Array.isArray(formData) &&
    formData.filter(value => value.name !== 'form-type').map(updateVal => {
      console.log("update value :", updateVal);
      props.updateObj !== false &&
      props.adminSetVal(updateVal.name, updateVal.value);
      params.push({name: updateVal.name, value: updateVal.value});

    })

    if(props.ctlActions &&
      props.ctlActions.edit) {
      props.ctlActions.edit(params);
    }

    props.setModal(null);
  }

  function cancelForm(e) {
    props.setModal(null);
  }

  function propEdit() {
    props.setModal(buildEditForm());


  }

  function propRemove(e) {
    let propsDelete = [];
    if(typeof props.name === 'object') {
      Object.entries(props.name).map(prop => {
        // THIS NEEDS A BETTER SOLUTION - SEEMS TO LEAVE 1 PAIR IF MULTIPLE
        // `${prop[1].location}[${prop[1].index
        propsDelete.push({property_name: prop[0], property_id: prop[1].property_id, index:props.index});
      })
    }else if(typeof props.name === 'string') {
      propsDelete.push({property_name: props.name, property_id: props.property_id, index: props.index});
    }
    propsDelete.map(prop => {
      if(props.editObject !== false) {
        props[`${props.location ? props.location : 'admin'}DeleteVal`](`${props.objectPath}[${prop.index}]`);
      }
      if(props.ctlActions.delete) {
        props.ctlActions.delete(prop);
      }
    })



    props.setModal(null);
  }

  function handleRemove() {
    const confirmation = <ModalConfirmation
                          message={`are you sure you want to delete ${typeof props.name !== 'string' && Object.entries(props.name).length > 0 ?
                                    Object.entries(props.name).map((entry, index) =>{
                                      return `${index !== 0 ? 'and ' : ''}<b>${props.capitalizeString(entry[0])}</b>`;
                                    }).join(" ")  :
                                    `<b>${props.capitalizeString(props.name)}</b>`} from the specs?`}
                          icon="trash"
                          actionAccept={propRemove}
                          valueAccept={true}
                          valueCancel={false}

                          {...props}
                        />
    props.setModal(confirmation);
  }

  return (
    <div className={`property-pair${props.className ? ` ${props.className}` : ''}`}>
      <div className="property-pair-name">
        {props.groupName ? props.groupName : props.name}
      </div>
      <div className="property-pair-value">
        {props.value}
      </div>
      {props.editable &&
        <div className="property-pair-ctl">
          <IconToken icon="edit" action={props.location ? propEdit : null} />
          <IconToken icon="trash" action={props.location ? handleRemove : null} />
        </div>
      }
    </div>
  )
}
