
export default function forms(state = {}, action) {
  switch (action.type) {
    case 'FORM_VALUE_ERRORS':
      return {...state, valueErrors: action.payload};
    case 'SAVE_FORM_INFO':
      return {...state, tmpFormData: {[action.formType]: action.payload}, };
    case 'CLEAR_FORM_INFO':
      return {...state, tmpFormData: {[action.formName]: null}};
    case 'FORM_SET_VAL':
      return {...state, tmpFormData: {[action.name]: action.value}};
    case 'FORM_REMOVE_VAL':
      let newState = state;
      const locationNodes = action.payload.split('.');
      locationNodes.map((node, index) => {
        if(node.includes('[')) {
          const aNodes = node.split('[');
          aNodes.map((aNode, aIndex) => {
            if(newState && newState[aNode.replace(']', '')]) {
              if(index+1 < locationNodes.length && aIndex+1 < aNodes.length) {
                newState = newState[aNode.replace(']', '')]
              }else{
                delete newState[aNode.replace(']', '')];
              }
            }else{
              newState = null;
            }
            return true;
          })
        }else{
          if(newState && newState[node]) {
            if(index+1 < locationNodes.length) {
              newState = newState[node];
            }else{
              delete newState[node];
            }
          }else{
            newState = null;
          }
        }
        return true;
      })
      return {...newState};
    default:
      return state;
  }
}
