import React, {useEffect, useState, useRef, Fragment} from 'react';

import ReactHtmlParser from 'react-html-parser';

import Button from 'components/forms/elements/Button';
import IconSVG from 'components/icons/IconSVG';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [file, setFile] = useState(null);
	const [results, setResults] = useState(null);
	const formRef = useRef();
	
	// HOOKS
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.objExists(props.admin, `reload.fonts.pdf`)) {
			setFile(null);
			if(props.admin.reload.fonts.pdf === true) {
				setResults("Font was uploaded");
			}else{
				if(props.admin.reload.fonts.pdf.includes('Font file compressed')) {
					// setResults("Font was uploaded");
					props.adminSetVal('reload.fonts.pdf', true);
				}else{
					setResults(props.admin.reload.fonts.pdf);
				}
			}
		}
	}, [props.admin.reload]);
	
	// FUNCTIONS
	function handleFile(e) {
		console.log("Files ", e.target.files.length);
		if(e.target.files.length > 0) {
			console.log("FILE IS", e.target.files[0]);
			setFile(e.target.files[0]);
		}
	}
	
	function handleSubmit(btn, data) {
		console.log("got the form?", formRef);
		const paramsFont = [
			{name: 'process', value: 'pdf_make_font'},
			{name: 'user_id', value: userID},
			{name: 'token', value: token},
			{name: 'font', value: file}
		];
		
		props.adminLoadState(paramsFont,'pdf', 'reload.fonts.pdf');
	}
	
	function stopForm(e) {
		e.preventDefault();
	}
	
	return(
		<Fragment>
			<div className="form-row">
				<div className="input"
					style={{width: '15em'}}>
					<label for="input-font-file">Font File</label>
					<input 
						id="input-font-file"
						type="file" 
						name="font-file" 
						value="" 
						placeholder="Select font file"
						onChange={handleFile} />
				</div>
			</div>
			{file && 
				<Fragment>
					<div className="form-row" style={{alignItems: 'center'}}>
						<IconSVG icon="file" className="" />
						<span style={{marginLeft: '.5em'}}>
							{`${file.name} (${props.displayFileSize(file.size)})`}
						</span>
					</div>
					<div className="form-row">
						<Button icon="upload" 
							label="Make Font"
							btnClass="success-bg" 
							btnAction={handleSubmit} />
					</div>
				</Fragment>
			}
			{results && 
				<div className="form-row">
					{ReactHtmlParser(results)}
				</div>
			}
		</Fragment>
	)
}