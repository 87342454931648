import * as methods from 'methods/reducers';

export default function users(state = {}, action) {
  switch (action.type) {
    case 'USER_LOGIN':
      return {...state, ...action.payload, isLogin: action.isLogin, alert_error: action.alert_error ? action.alert_error : null};
    case 'USER_CHECK_EMAIL':
      if(action.payload > 0 ) {
        return {...state, error: {...state.error, duplicateUser: true}}
      }else{
        return {...state}
      }
    case 'USER_REMOVE_ERROR':
      const newState = delete state.error[action.payload];
      return {...newState}
    case 'SET_NO_USER':
      return {...state, isLogin: 'noUser', errror: 'no_user'};
    case 'USER_LOGOUT':
      return {...action.payload, userLoggedOut: true};
    case 'USER_LOGOUT_CLEARED':
      let userState = state;
      delete userState.userLoggedOut;
      return {...state, }
    case 'ACCOUNT_NOMATCH':
      return {...state, user: action.payload, isLogin: false, accountNoMatch: true}
    case 'URL_REQUEST':
      return {...state, reqUrl: action.payload};
    case 'USER_LOAD_STATE':
      if(action.location === null) {
        return {...action.payload};
      }else if(action.location.indexOf('.') > 0) {
        const locationNodes = action.location.split('.');
        let stateVal = state;
        locationNodes.map((node, index) => {
          if(stateVal[node]) {
            if((index + 1) === locationNodes.length) {
              stateVal[node] = action.payload;
            }else{
              if(stateVal[node]) {
                stateVal = stateVal[node]
              }else{
                stateVal[node] = null;
                stateVal = stateVal[node];
              }
            }
          }else{
            if(index + 1 === locationNodes.length) {
              stateVal[node] = action.payload;
            }else{
              stateVal[node] = {};
              stateVal = stateVal[node];
            }
          }
          return true;
        })
        if(action.location === 'pw.update') {
          console.log("IN THE REDUCER UPDATE THE PASSWORD", state);
        }
        return {...state};
      }else{
        const extState = state;
        extState[action.location] = action.payload;
        return {...state};
      }
    case 'USER_GET_ORDERS':
      return {...state, orders: action.payload};
    case 'USER_DELETE_VAL':
      let removeValState;
      if(Array.isArray(action.payload)) {
        action.payload.map(location => {
          removeValState = methods.removeStateValue(state, location);
          return true;
        })
      }else{
        removeValState = methods.removeStateValue(state, action.payload);
      }
      if(removeValState) {
        return {...removeValState};
      }else{
        return {...state};
      }
    default:
      return state;
  }
}
