import React, {Fragment} from 'react';

import MediaHero from 'components/profiles/media/MediaHero';
import Link from 'components/navigation/Link';

// There may be an issue with loading external images here

export default (props) => {
  return(
    props.feature && props.objExists(props.feature, 'feature_id') &&
    props.objExists(props, `feature.details.${props.languageCode}`) &&
    <div className={`feature${props.feature.details[props.languageCode].feature_url ? ' link' : ''}`}>
      {props.blockLinks !== true &&
        props.objExists(props.feature, `details.${props.languageCode}.feature_url`) ?
          <Link to={props.feature.details[props.languageCode].feature_url}>
            <h4>{props.feature.details[props.languageCode].feature_title}</h4>
            {props.objExists(props.feature, `details.${props.languageCode}.media[0].media_file_name`) &&
              <MediaHero mediaPath={props.localizeURL(props.feature.details[props.languageCode].media[0].media_file_path)}
              mediaFileName={props.feature.details[props.languageCode].media[0].media_file_name} />
            }
            
            <div className="feature-text">
              {props.feature.details[props.languageCode].feature_subtitle && <h5>{props.capitalizeString(props.feature.details[props.languageCode].feature_subtitle, true)}</h5>}
              {props.feature.details[props.languageCode].feature_text && props.includeText !== false && <p>{props.capitalizeString(props.feature.details[props.languageCode].feature_text)}</p>}
            </div>
          </Link>  
      :
        <Fragment>
          <h4>{props.feature.details[props.languageCode].feature_title}</h4>
          {props.objExists(props.feature, `details.${props.languageCode}.media[0].media_file_name`) &&
            <MediaHero mediaPath={props.localizeURL(props.feature.details[props.languageCode].media[0].media_file_path)}
                      mediaFileName={props.feature.details[props.languageCode].media[0].media_file_name} />
          }
          <div className="feature-text">
            {props.feature.details[props.languageCode].feature_subtitle && <h5>{props.capitalizeString(props.feature.details[props.languageCode].feature_subtitle, true)}</h5>}
            {props.feature.details[props.languageCode].feature_text && props.includeText !== false && <p>{props.capitalizeString(props.feature.details[props.languageCode].feature_text)}</p>}
          </div>
        </Fragment>
      }
    </div>
  )
}
