import React from 'react';

import PagePublic from 'components/templates/PagePublic';
import UserLogin from 'components/account/UserLogin';

export default (props) => {
  return(
    <PagePublic>
      <UserLogin />
    </PagePublic>
  )
}
