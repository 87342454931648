import React, {useEffect, useState} from 'react';

import FormDynamic from 'components/forms/FormDynamic';

export default (props) => {
  const [fieldsets, setFieldsets] = useState(null);
  const [form, setForm] = useState(null);

  useEffect(() => {
    setFieldsets([
      {fieldset_name: `${props.objExists(props, 'host.host_id') ? 'Change' : 'Add'} Host`,
        fields: [
          {field_type: 'text',
            field_name: 'port_num',
            field_label: `port`,
            field_value: props.objExists(props, 'port.port_num') ? props.port.port_num : null,
            field_placeholder: 'Server port',
            field_order: 1,
            field_required: true,
            field_validate: true,},
          {field_type: 'switch',
            field_name: 'port_published',
            field_label: 'Publish',
            field_value: props.objGetValue(props, 'port.port_published') === 0 ? false : true,
            field_order: 2,
            field_required: false,
            field_validate: false},
          {field_type: 'hidden',
            field_name: 'process',
            field_value: props.objExists(props, 'port.port_id') ? 'update_site_port' : 'add_site_port'},
          props.objExists(props, 'port.port_id') &&
          {field_type: 'hidden',
            field_name: 'port_id',
            field_value: props.port.port_id},
        ]
      }
    ]);
  }, [props.host]);

  useEffect(() => {
    fieldsets &&
    setForm(
      <FormDynamic
          content={fieldsets}
          name="add-edit-category"
          returnObject={true}
          fieldOnChange={null}
          fieldOnLoad={null}
          formActions={{formCancel: handleCancel, formSubmit: handleSubmit}} />
    )
  }, [fieldsets]);

  function handleCancel() {
    props.setModal(null);
  }

  function handleSubmit(params) {
    if(props.action) {
      props.action(params);
    }
  }

  return (fieldsets && form)
}
