import React, {useEffect, useState} from 'react';

import FilterOption from 'components/forms/elements/FilterOption';

export default (props) => {
	const [options, setOptions] = useState(null);
	
	// HOOKS 
	useEffect(() => {
		if(props.options && props.options !== options) {
			setOptions(props.options);
		}
	}, [props.options]);
	
	// FUNCTIONS 
	function selectOption(param) {
		if(props.actionUpdateFilters) {
			props.actionUpdateFilters(param);
		}
	}
	
	return (
		options && 
		Object.entries(options).length > 0 &&
		<div className="filter-options">
			{Object.entries(options).map((optSet, index) => {
				return(
					<div className={`option-set`}>
						<div className="filter-label">
							{props.capitalizeString(optSet[0].replaceAll('_', ' '), true)}
						</div>
						<div className="filter-values">
							{optSet[1] && Array.isArray(optSet[1]) &&
								optSet[1].map((optVal, index) => {
									return (
										<FilterOption 
											key={`fOpt${index}`}
											{...props} 
											filter={optSet[0]}
											option={optVal}
											action={selectOption}
										/>	
									)
								})
							}
						</div>
					</div>
				)	
			})}
		</div>
	)
}