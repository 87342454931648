import React, {useEffect, useState, Fragment} from 'react';

import MediaFile from 'components/profiles/media/MediaFile';

export default (props) => {
	const [fields, setFields] = useState(null);
	const [files, setFiles] = useState(null);
	
	useEffect(() => {
		if(props.fields && props.fields !== fields) {
			setFields(props.fields);
		}
	}, [props.fields]);
	
	useEffect(() => {
		if(props.formFiles && props.formFiles !== files) {
			setFiles(props.formFiles);
		}else if(!props.formFiles && files) {
			setFiles(null);
		}
	}, [props.formFiles]);
	
	// FUNCTIONS 
	function displayValue(field) {
		switch(field.field_type) {
			case 'file':
				return <MediaFile {...props}
							media={files ? files[field.field_id][0] : null}
							hideCtl={true} />;
			default:
				return field.field_value;
		}
	}
	
	return (
		fields &&
		<div className="form-field-values grid col-2">
			<div className="grid-header">
				{props.title ? props.capitalizeString(props.title) : 'Form Values'}
			</div>
			{Array.isArray(fields) &&
			fields.map((field, index) => {
				return (
					<Fragment key={`fldDta${index}`}>
						<div className="name left">{`${props.capitalizeString(field.field_label, true)}:`}</div>
						<div className="value">{displayValue(field)}</div>
					</Fragment>
				)
				})
			}
		</div>
	)
}