import React, {useEffect, useState} from 'react';

export default (props) => {
	const [values, setValues] = useState(null);
	const [value, setValue] = useState(null);
	const [label, setLabel] = useState(null);
	const [options, setOptions] = useState(null);
	const [textValues, setTextValues] = useState(null);
	
	useEffect(() => {
		if(props.values) {
			if(Array.isArray(props.values) &&
				values !== props.values) {
				setValues(props.values);	
			}else if(parseInt(props.values) > 0 ) {
				let vals = [];
				for(var i = 1; i <= parseInt(props.values); i++) {
					vals.push(i);
				};
				if(vals.length > 0) {
					setValues(vals);
				}
			}
		}
	}, [props.values]);
	
	useEffect(() => {
		if(Array.isArray(values)) {
			let textCheck = false;
			values.map(val => {
				if(isNaN(parseInt(val) && val.length > 1)) {
					textCheck = true;
				}
			});
			setTextValues(textCheck);
		}
	}, [values]);
	
	useEffect(() => {
		if(props.label &&
			props.label !== label) {
			setLabel(props.label);
		}
	}, [props.label]);
	
	useEffect(() => {
		if(props.value && !value) {
			setValue(props.value);
		}
	}, [props.value]);
	
	useEffect(() => {
		if(values) {
			let valOptions = [];
			values.map((val, index) => {
				valOptions.push(
					<div key={`vOpt${index}`}
						className={`option${value === val ? ' selected' : ''}`}
						onClick={()=>{handleSelect(val)}}>
						{val}
					</div>
				);
			});
			if(valOptions.length > 0) {
				setOptions(valOptions);
			}
		}
		
		
 		
	}, [values, value]);
	
	// FUNCTIONS
	function handleSelect(val) {
		setValue(val);
		if(val && props.actionSelect) {
			props.actionSelect(val);
		}
	}
	
	return (
		options &&
		<div className={`value-selector${textValues ? ' text': ''}${props.centered ? ' centered' : ''}`}>
			{!props.hideLabel &&
				<label>{label || 'Values'}</label>	
			}
			<div className="options">
				{options}
			</div>
		</div>
	)
}