import React, {useEffect, useState, Fragment} from 'react';
import {useParams, useLocation} from 'react-router';

import Organization from 'components/profiles/orgs/Organization';
import Loader from 'components/atoms/Loader';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [coreOrg, setCoreOrg] = useState(null);
  const [persona, setPersona] = useState(null);
  const [users, setUsers] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [logoAdd, setLogoAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(null);
  const [isSuper, setIsSuper] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [taskGroups, setTaskGroups] = useState(null);
  const [taskSettings, setTaskSettings] = useState(null);
  const [staffGroups, setStaffGroups] = useState(null);
  const [orgGroups, setOrgGroups] = useState(null);
  
  const pgParams = useParams();

  // HOOKS
  useEffect(() => {
    return() => {
      props.adminDeleteVal('organization');
      props.adminDeleteVal('persona');
      props.adminDeleteVal('current');
    }
  }, []);
  
  useEffect(() => {
    if(props.userID && props.userID !== userID) {
      setUserID(props.userID);
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token && props.token !== token) {
      setToken(props.token)
    }
  }, [props.token]);
  
  useEffect(() => {
    if(props.languageCode && languageCode !== props.languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode]);

  useEffect(() => {
    if(parseInt(pgParams.component) > 0 &&
       parseInt(pgParams.component) > 0 !== organizationID ) {
      setOrganizationID(parseInt(pgParams.component));
    }
  }, [pgParams.component]);
  
  useEffect(() => {
    if(props.objExists(props.admin, 'organization') &&
      props.admin.organization !== organization) {
        setOrganization(props.admin.organization);
      }
  }, [props.admin.organization]);
  
  useEffect(() => {
    if(props.objExists(props.admin, `users`) &&
      props.admin.users[0].organization_id === organizationID &&
      props.admin.users !== users) {
      setUsers(props.admin.users);  
    }
  }, [props.admin.users, organizationID]);
  
  useEffect(() => {
    if(organizationID && props.objExists(props.admin, 'persona') &&
      props.admin.persona.organization_id === organizationID &&
      props.admin.persona !== persona) {
        setPersona(props.admin.persona);
    } 
  }, [props.admin.persona, organizationID]);
  
  useEffect(() => {
    if(props.userAccess && props.userAccess !== userAccess) {
      setUserAccess(props.userAccess);
    } 
  }, [props.userAccess]);
  
  useEffect(() => {
    if(props.canEdit &&
      props.canEdit !== canEdit) {
      setCanEdit(props.canEdit);
    }
  }, [props.canEdit]);
  
  useEffect(() => {
    if(props.isSuper &&
      props.isSuper !== isSuper) {
      setIsSuper(props.isSuper);
    }
  }, [props.isSuper]);

  useEffect(() => {
    if(userID && token && organizationID) {
      let params =  [
        {name: 'process', value: 'get_organizations'},
        {name: 'organization_id', value: organizationID},
      ];
      
      if(props.objGetValue(props.admin, 'organization.organization_id') !== organizationID ||
          props.objGetValue(props.admin, 'reload.organization') === true) {
          
          props.adminLoadState(params, 'organizations', 'organization');
          if(props.objGetValue(props.admin, 'reload.organization') === true) {
            props.adminDeleteVal('reload.organization');
          }
      }
    }
  }, [userID, token, organizationID, props.admin.organization, props.admin.reload]);
  
  useEffect(() => {
    if(userID && token && organizationID) {
      const userParams = [
        {name: 'process', value: 'get_users'},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
        {name: 'organization_id', value: organizationID}
      ];
      if(!props.objExists(props.admin, 'users') ||
          props.objGetValue(props.admin, 'users[0].organization_id') !== organizationID ||
          props.objGetValue(props.admin, 'reload.users')) {
        props.adminLoadState(userParams, 'users', 'users');
        if(props.objGetValue(props.admin, 'reload.users') === true) {
          props.adminDeleteVal('reload.users');
        }
      }
    }
  }, [userID, token, props.pgParams.component, props.admin.users, props.admin.reload]);
  
  useEffect(() => {
    if(userID && token && props.pgParams.component) {
      const params = [
        {name: 'process', value: 'get_site_persona_db'},
        {name: 'organization_id', value: parseInt(props.pgParams.component)},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
      ]
  
      if(props.objGetValue(props.admin, 'persona') !== 'no persona' &&
          (props.objGetValue(props.admin, 'persona.organization_id') !== parseInt(props.pgParams.component) ||
          props.objGetValue(props.admin, 'reload.persona') === true)) {
          
          props.adminLoadState(params, 'sites', 'persona');
          if(props.objGetValue(props.admin, 'reload.persona') === true) {
            props.adminDeleteVal('reload.persona');
          }  
      }
    }
  }, [userID, token, props.pgParams.component, props.admin.persona, props.admin.reload]);
  
  useEffect(() => {
    if(userID && token && props.pgParams.component &&
        props.objGetValue(props.admin, 'persona.organization_id') === parseInt(props.pgParams.component)) {
      const params = [
        {name: 'process', value: 'get_site_prefs'},
        {name: 'organization_id', value: parseInt(props.pgParams.component)},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
      ]
  
      if(props.objGetValue(props.admin, 'persona.prefs') !== 'no prefs' &&
          (!props.objExists(props.admin, 'persona.prefs') || props.objGetValue(props.admin, 'reload.prefs') === true)) {
          
          props.adminLoadState(params, 'sites', 'persona.prefs');
          if(props.objGetValue(props.admin, 'reload.prefs') === true) {
            props.adminDeleteVal('reload.prefs');
          }  
      }
    }
  }, [userID, token, props.pgParams.component, props.admin.persona, props.admin.reload]);
  
  useEffect(() => {
    
    if(userID && token && organizationID) {
      // getDefaultProjectTasks($user_id = 0, $token = null, $organization_id = 0)
      
      const taskParams = [
        {name: 'process', value: 'get_default_project_tasks'},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
        {name: 'organization_id', value: organizationID}
      ];
      
      if(!props.objExists(props.admin, `current.default_tasks`) || 
          props.objGetValue(props.admin, `reload.current.default_tasks`) === true) {
          
          if(props.objGetValue(props.admin, `reload.current.default_tasks`) === true) {
            props.adminDeleteVal(`reload.current.default_tasks`);
          }
          props.adminLoadState(taskParams, 'tasks', 'current.default_tasks');
      }
      
      const groupParams = [
        {name: 'process', value: 'get_org_task_groups'},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
        {name: 'organization_id', value: organizationID},
        {name: 'language_code', value: languageCode}
      ];
      
      if(!props.objExists(props.admin, `current.task_groups`) || 
          props.objGetValue(props.admin, `reload.current.task_groups`) === true) {
          
          if(props.objGetValue(props.admin, `reload.current.task_groups`) === true) {
            props.adminDeleteVal(`reload.current.task_groups`);
          }
          props.adminLoadState(groupParams, 'organizations', 'current.task_groups');
      }
      // getPropertiesByLink
      // getPropertiesByLink($_POST["user_id"], $_POST["token"], $_POST["link_to"], $_POST["link_type"], $_POST["assc_to"], $_POST["assc_type"], $_POST['language_code'] ? $_POST['language_code'] : "en", $_POST["internal"])
      const tSettingsParams = [
        {name: 'process', value: 'get_properties_by_link'},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
        {name: 'link_to', value: organizationID},
        {name: 'link_type', value: 'taskSettingToOrg'},
        {name: 'language_code', value: languageCode}
      ];
      
      if(!props.objExists(props.admin, `current.task_settings`) || 
          props.objGetValue(props.admin, `reload.current.task_settings`) === true) {
          
          if(props.objGetValue(props.admin, `reload.current.task_settings`) === true) {
            props.adminDeleteVal(`reload.current.task_settings`);
          }
          props.adminLoadState(tSettingsParams, 'properties', 'current.task_settings');
      }
    }
    
  }, [userID, token, organizationID, props.admin.reload]);
  
  useEffect(() => {
    if(props.objExists(props.admin, `current.default_tasks`) &&
      props.admin.current.default_tasks !== tasks) {
      setTasks(props.admin.current.default_tasks);
    }
    
    if(Array.isArray(props.objGetValue(props.admin, `current.task_groups`)) &&
      props.admin.current.task_groups !== taskGroups) {
        setTaskGroups(props.admin.current.task_groups);
    }
    
    if(Array.isArray(props.objGetValue(props.admin, `current.task_settings`)) &&
      props.admin.current.task_settings !== taskSettings) {
        setTaskSettings(props.admin.current.task_settings);
    }
  }, [props.admin.current]);
  
  useEffect(() => {
    if(Array.isArray(props.objGetValue(props.admin, `staff.groups`)) &&
      props.admin.staff.groups !== staffGroups) {
        setStaffGroups(props.admin.staff.groups);
      }
  }, [props.admin.staff]);
  
  useEffect(() => {
    if(Array.isArray(props.objGetValue(props.admin, `groups.user`)) &&
      props.admin.groups.user.find(group => group.organization_id === organizationID) &&
      props.admin.groups.user !== orgGroups) {
        setOrgGroups(props.admin.groups.user);
    }else if(!Array.isArray(props.objGetValue(props.admin, `groups.user`)) ||
            !props.admin.groups.user.find(group => group.organization_id === organizationID)) {
      props.adminSetVal('reload.groups.user', true);
    }
  }, [props.admin.groups, organizationID]);

  console.log("ADMIN FROM ORG", props.admin);
  return(
    <Fragment>
      {organization ?
        <Organization {...props}
                      organization={organization}
                      tasks={tasks}
                      taskGroups={taskGroups}
                      taskSettings={taskSettings}
                      staffGroups={staffGroups}
                      orgGroups={orgGroups}
                      persona={persona}
                      users={users}
                      isAdmin={true}
                      canEdit={canEdit}
                      isSuper={isSuper}
                      location="admin"
                      userID={userID}
                      token={token}
                      organizationID={organization.orgainzation_id}
                      coreOrg={props.coreOrg}
                      actionSelectOrg={props.actionUpdateCoreOrg} />
      :
        <Loader />
      }
    </Fragment>
  )
}
