import React, {useEffect, useState} from 'react';

import FormDynamic from 'components/forms/FormDynamic';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [group, setGroup] = useState(null);
	const [fieldsets, setFieldsets] = useState(null);
	const [form, setForm] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.languageCode && props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.group && props.group !== group) {
			setGroup(props.group);
		}
	}, [props.group]);
	
	useEffect(() => {
		if(group) {
			let gFieldsets = [{
				fieldset_name: 'Edit Group',
				fields: [
					{
						field_type: 'hidden',
						field_name: 'process',
						field_value: 'update_shell_group'
					},
					{
						field_type: 'hidden',
						field_name: 'user_id',
						field_value: userID
					},
					{
						field_type: 'hidden',
						field_name: 'token',
						field_value: token
					},
					{
						field_type: 'hidden',
						field_name: 'group_id',
						field_value: group ? group.group_id : null
					},
					{
						field_type: 'text',
						field_name: 'group_name',
						field_label: 'Name',
						field_placeholder: 'Group Name',
						field_value: props.objExists(group, `group_name`) ? group.group_name : null,
						field_required: true,
						field_validate: true,
						field_order: 1,
					},
				]
			}];
			
			if(gFieldsets !== fieldsets) {
				setFieldsets(gFieldsets);
			}
			
		}
	}, [group]);
	
	useEffect(() => {
		if(fieldsets) {
			setForm(
				<FormDynamic {...props}
					content={fieldsets}
					returnObject={true}
					formActions={{
						formSubmit: props.actionSubmit,
						formCancel: props.actionCancel
					}} />
			)
		}
	}, [fieldsets]);
	
	return (
		<div className="border-block">
			{form}
		</div>
	)
}