import axios from 'axios';
import * as apis from 'methods/api.js';

import * as utils from 'methods/site';
import * as methods from 'methods/reducers';

const urlToObject = (url) => {
  const params = url.substring(url.indexOf('?') + 1).split('&');
  let urlObj = {};

  params.forEach((param) => {
    const pair = param.split('=');
    urlObj[pair[0]] = pair[1];
  })
  return urlObj;
}

export const userLogin = (params, menuState = true) => dispatch => {
  let formData = null;
  let storeToken = false;
  if(Array.isArray(params)) {
    formData = new FormData();
    params.map(param => {
      if(param.name && param.value && param.name !== 'form-type' && param.name !== 'persist') {
        formData.append(param.name, param.value);
     }else if(param.name === 'persist' && param.value === 'true') {
       storeToken = true;
     }
    })
  }else{
    formData = params;
    const urlParams = urlToObject(formData);
    if(urlParams.persist === 'true') {
      storeToken = true;
    }
  }

  axios.post(apis.api_url_users, formData).then(res => {
    if(Object.entries(res.data).length > 0 && res.data.user_id && res.data.user_id > 0) {
      dispatch({type: 'USER_LOGIN', payload: res.data, isLogin: true});

      let tokenData = new FormData();
      tokenData.append('process', 'user_set_token');
      tokenData.append('user_id', res.data.user_id);
      tokenData.append('contact_email', res.data.contact.contact_email);

      axios.post(apis.api_url_users, tokenData).then(res => {
        dispatch({type: 'USER_LOAD_STATE', location: 'token', payload: res.data});
        sessionStorage.setItem('token', res.data);
        if(storeToken === true) {
          localStorage.setItem('token', res.data);
        }
      }).catch(error => {
        console.log(error);
      })
      dispatch({type: 'ALERT_ERROR', payload: null});
      dispatch({type: 'SET_MODAL', payload: null});
      menuState &&
      dispatch({type: 'SET_MENU_STATE', payload: {active: true, tab: 'Account'}})
    }else{
      dispatch({type: 'USER_LOGIN', payload: null, isLogin: 'noUser', alert_error: 'No User Found'});
      dispatch({type: 'ALERT_ERROR', errorType: 'userError', payload: {icon: 'user',
                                                                        title: 'No User Found',
                                                                        message: 'Please check your login info and try again.'}});
    }
  }).catch(error=> {
    console.log(error);
  })
}

export const userLoginOld = (formData) => dispatch => {
  axios.post(apis.api_url_users, formData).then(res => {
    if(Object.entries(res.data).length > 0 && res.data.user_id && res.data.user_id > 0) {
      dispatch({type: 'USER_LOGIN', payload: res.data, isLogin: true});
      const urlParams = urlToObject(formData);
      const tokenData = `process=user_set_token&user_id=${res.data.user_id}&contact_email=${res.data.contact.contact_email}`;
      axios.post(apis.api_url_users, tokenData).then(res => {
        sessionStorage.setItem('token', res.data);
        if(urlParams.isRemembered === 'true') {
          localStorage.setItem('token', res.data);
        }
      }).catch(error => {
        console.log(error);
      })
      dispatch({type: 'ALERT_ERROR', payload: null});
      dispatch({type: 'SET_MODAL', payload: null});
      dispatch({type: 'SET_MENU_STATE', payload: {active: true, tab: 'Account'}})
    }else{
      dispatch({type: 'USER_LOGIN', payload: null, isLogin: false});
      dispatch({type: 'ALERT_ERROR', errorType: 'userError', payload: res.data});
    }
  }).catch(error=> {
    console.log(error);
  })
}

export const findUserLogin = () => dispatch => {
  if(sessionStorage.getItem('token') !== null && sessionStorage.getItem('token') !== '') {
    return getUserFromToken(sessionStorage.getItem('token'));
  }else if (localStorage.getItem('token') !== null && localStorage.getItem('token') !== ''){
    return getUserFromToken(localStorage.getItem('token'));
  }
}

export const getUserFromToken = (token, organization_id) => dispatch => {
  const formData = `process=get_user_from_token&token=${token}&organization_id=${organization_id}`;
  axios.post(apis.api_url_users, formData).then(res => {
    if(typeof(res.data) === 'object') {
      dispatch({type: 'USER_LOGIN', payload: res.data, isLogin: true});
      dispatch({type: 'ALERT_ERROR', payload: null});
      return true;
    }else{
      dispatch({type: 'USER_LOGIN', payload: null, isLogin: "noUser"});
      dispatch({type: 'ALERT_ERROR', payload: res.data});
      return false;
    }
  }).catch(error => {
    console.log("DISPATCH ERROR - USER FROM TOKEN:", error);
  })
};

export const userCheckEmail = (contact_email) => dispatch => {
  const formData = `process=user_check_email&contact_email=${contact_email}`;

  axios.post(apis.api_url_users, formData).then(res => {
    dispatch({type: 'USER_CHECK_EMAIL', payload: res.data});
  }).catch(error => {
    console.log("DISPATCH ERROR - CHECK EMAIL", error);
  })
}

export const userRemoveError = (error) => dispatch => {
  dispatch({type: 'USER_REMOVE_ERROR', payload: error});
}

export const setNoUser = () => dispatch => {
  dispatch({type: 'SET_NO_USER'});
}

export const logout = () => dispatch => {
    localStorage.removeItem('ses');
    sessionStorage.removeItem('ses');
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    dispatch({type: 'USER_LOGOUT', payload: null});
    dispatch({type: 'NOTIFY_USER', payload: "You have been logged out"});
}

export const userLogoutCleared = () => dispatch => {
  dispatch({type: 'USER_LOGOUT_CLEARED'});
}

export const setUrlReq = (reqUrl) => dispatch => {
  dispatch({
    type: 'URL_REQUEST',
    payload: reqUrl
  })
};

export const unsetUrlReq = (reqUrl) => dispatch => {
  dispatch({
    type: 'URL_REQUEST',
    payload: null
  })
};

export const userGetOrders = (user_id, organization_id) => dispatch =>{
  const formData = `process=get_orders&user_id=${user_id}&organization_id=${organization_id}`;
  axios.post(apis.api_url_orders, formData).then(res => {
    dispatch({type: 'USER_GET_ORDERS', payload: res.data});
  }).catch(error => {
    console.log("DISPATCH ERROR - USER GET ORDERS", error);
  })
};

export const userLoadState = (params, api, state_location, callback = null) => dispatch => {
    dispatch({type: 'SITE_SET_VALUE', name: 'userUpdate', value: true});
   var formData = new FormData();
   let reqUrl = null;
   Array.isArray(params) &&
   params.map (param => {
     if(param.name && param.value) {
      formData.append(param.name, param.value);
     }
   })

  if(apis.getReqUrl(api)) {
    axios.post(apis.getReqUrl(api), formData).then(res => {
      
        if(callback && Array.isArray(callback) && callback.length > 0) {
          var callbackData = new FormData();
          callback.map (param => {
            if(param.name && param.value) {
              if(param.value === 'result') {
                callbackData.append(param.name, res.data);
              }else{
                callbackData.append(param.name, param.value);
              }
            }
          })
          axios.post(reqUrl, callbackData).then(cbRes => {
            dispatch({type: 'USER_LOAD_STATE', location: state_location, payload: cbRes.data});
            dispatch({type: 'SITE_REMOVE_VALUE', name: 'userUpdate'});
          }).catch(error => {
            console.log("DISPATCH ERROR LOAD CALLBACK", error);
            dispatch({type: 'SITE_REMOVE_VALUE', name: 'userUpdate'});
          })
        }else{
          if(state_location === 'pw.update') {
            console.log("SENDING TO REDUCER", res.data);
          }
          dispatch({type: 'USER_LOAD_STATE', location: state_location, payload: res.data});
          dispatch({type: 'SITE_REMOVE_VALUE', name: 'userUpdate'});
        }
      }).catch(error => {
        console.log("DISPATCH ERROR - LOAD STATE", reqUrl, error);
        dispatch({type: 'SITE_REMOVE_VALUE', name: 'userUpdate'});
      })
    }else{
      console.log("There's no reqUrl", api, reqUrl);
      dispatch({type: 'SITE_REMOVE_VALUE', name: 'userUpdate'});
    }
 }

export const userSetVal = (name, value) => (dispatch) => {
  dispatch({type: 'USER_SET_VAL', name: name, value: value});
}

export const userDeleteVal = (name) => (dispatch) => {
  dispatch({type: 'USER_DELETE_VAL', payload: name});
}

export const userRemoveVal = (name) => (dispatch) => {
  dispatch({type: 'USER_DELETE_VAL', payload: name});
}
