import React from 'react';
import PagePublic from 'components/templates/PagePublic';

import Categories from 'components/categories/Categories';

import * as actions from 'actions/';

export default (props) => {
  return (
    <PagePublic >
      <Categories />
    </PagePublic>
  )
}
