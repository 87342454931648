import React, {useEffect, useState} from 'react';

import Module from 'components/profiles/modules/Module';

export default (props) => {
	const [languageCode, setLanguageCode] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(props.languageCode &&
		  props.languageCode !== languageCode) {
		  setLanguageCode(props.languageCode);
		}else if(!languageCode) {
		  setLanguageCode('en');	
		}
	}, [props.languageCode]);
	
	return (
		<div className="modules">
			{props.modules && 
			Array.isArray(props.modules) && 
			languageCode &&
			props.modules.map((module, index) => {
				return <Module {...props}
								key={`msm-${module.module_id}-${index}`}
								module={module}
								languageCode={languageCode}
								actionActivate={props.actionActivate} />
			})
			}
		</div>
	)
}