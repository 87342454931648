import React, {useEffect, useState} from 'react';

import Link from 'components/navigation/Link';
import IconSVG from 'components/icons/IconSVG';

export default (props) => {
  const [orderCount, setOrderCount] = useState(null);
  const [itemCount, setItemCount] = useState(0);
  const [rushCount, setRushCount] = useState(0);
  const [redirect, setRedirect] = useState(null);
  const [userID, setUserID] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [token, setToken] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [projectStatus, setProjectStatus] = useState(null);
  
  // HOOKS
  useEffect(() => {
    props.userID &&
    setUserID(props.userID);
  }, [props.userID]);

  useEffect(() => {
    props.token &&
    setToken(props.token);
  }, [props.token])

  useEffect(() => {
    props.organizationID &&
    setOrganizationID(props.organizationID);
  }, [props.organizationID]);

  useEffect(() => {
    props.organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);

  useEffect(() => {
    props.languageCode &&
    setLanguageCode(props.languageCode);
  }, [props.languageCode]);

  useEffect(() => {
    props.order.project_order_count &&
    setOrderCount(props.order.project_order_count);

    props.order.project_item_count &&
    setItemCount(props.order.project_item_count);
    
    if(languageCode && 
        props.objExists(props.order, 'current_task.task_name')) {
      // setProjectStatus(props.order.current_task.task_name);
    }
    
    if(languageCode &&
        props.objExists(props.order, `status[0].${languageCode}.status_name`) &&
        props.order.status[0][languageCode].status_name !== projectStatus) {
      setProjectStatus(
        props.order.status[0][languageCode].status_name !== 'new' ?
          props.order.status[0][languageCode].status_name : 'Pending'
      );
    }
    
  }, [props.order, languageCode]);

  return (
    <li className="list-item flex" onClick={props.viewAction ? props.viewAction : null}>
      <IconSVG icon="cart" className="li-icon" />
      <div className="li-content">
        {organizationID === 1 && 
          props.location !== 'user' &&
          <div className="li-content-row">
            {props.order.organization_name }
          </div>
        }
        {props.location !== 'user' &&
          <div className="li-content-row">
            {`${props.order.contact.contact_name_first} ${props.order.contact.contact_name_last}`}
          </div>
        }
        <div className="li-content-row">
          <div>
            <Link to={props.location ? `/${props.location}/orders/order/${props.order.project_id}` : null } >
              <b>{props.projectNumDisplay(props.order.project_id, props.order.organization_code)}</b>
            </Link>
          </div>
          <div>
            {props.capitalizeString(projectStatus, true)}
          </div>
        </div>
        <div className="li-content-row"style={{fontSize: '.75em'}}>
          <div>
            {`${props.dateDisplay(props.order.project_created)} ${props.timeDisplay(props.order.project_created, false)}`}
          </div>
        </div>
        <div className="li-content-row">
          <div>
              {`${itemCount} Item${itemCount > 1 ? 's' : ''} | ${orderCount > 1 ? 'Multiple Shipments' : 'Single Shipment'}${rushCount ? ` | RUSH` : ''}`}
          </div>
        </div>
      </div>
    </li>
  )
}
