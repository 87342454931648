import React, {useEffect, useState} from 'react';

export default (props) => {
	const [image, setImage] = useState(null);
	const [imagePath, setImagePath] = useState(null);
	const [imageSource, setImageSource] = useState(null);
	const [imageAlt, setImageAlt] = useState(null);
	
	useEffect(() => {
		if(props.image && 
			props.image !== image) {
			setImage(props.image);
		}
	}, [props.image]);
	
	useEffect(() => {
		if(props.imagePath && 
			props.imagePath !== imagePath) {
			setImagePath(props.imagePath);
		}else if(!props.imagePath) {
			setImagePath(`https://aecore.app/assets/media/`);
		}
	}, [props.imagePath]);
	
	useEffect(() => {
		if(props.imageSource &&
			props.imageSource !== imageSource) {
			setImageSource(props.imageSource);
		}else if(image && imagePath) {
			setImageSource(`${imagePath}${image.media_file_name}`);
		}
	}, [props.imageSource, image, imagePath]);
	
	useEffect(() => {
		if(props.imageAlt && 
			props.imageAlt !== imageAlt) {
			setImageAlt(props.imageAlt);
		}
	}, [props.imageAlt]);
	
	return(
		<img src={imageSource}
			alt={imageAlt}
			style={props.style}
			className={props.className} />
	)
}