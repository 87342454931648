import React from 'react';

import Button from 'components/forms/elements/Button';


export default (props) => {
  
  function handleSubmit() {
    if(props.actionSubmit) {
      props.actionSubmit();
    }
  }
  
  return (
    <div className="form-complete-ctl">
      <div className="form-row form-row-buttons">
        {props.cancelable &&
          <Button type="button" 
                  name="cancel" 
                  label={props.formCancelLabel ? props.formCancelLabel : 'cancel'} 
                  icon="close" 
                  btnAction={props.actionCancel} 
                  btnClass="error-bg sml mini" />
        }
        <Button type="submit" 
                name='submit'
                form={props.formID ? props.formID : null} 
                label={props.formCompleteLabel ? props.formCompleteLabel : "save"}
                btnAction={handleSubmit}
                icon="check" 
                btnClass={`success-bg ${props.cancelable ? ' sml' : ' med'} mini`} 
                disabled={props.formComplete ? false : true} 
                btnRef={props.submitRef} />
      </div>


    </div>
  )
}
