import React, {useEffect, useState} from 'react';

import Control from 'components/atoms/Control';
import IconToken from 'components/icons/IconToken';
import SearchField from 'components/forms/elements/SearchField';
import FilterSet from 'components/forms/elements/FilterSet';

export default (props) => {
  const [active, setActive] = useState(false);


  return(
    <div className={`list-header-ctl${active ? ' active' : ''}`}>
      <div className="list-header-ctl-inactive">
        <Control icon="filter"
                  label={`Filter${props.listName ? ` ${props.listName}` : ' List'}`}
                  showLabel={true}
                  action={()=> {setActive(true)}} />

      </div>
      <div className="list-header-ctl-active">
        <IconToken icon="add"
                    className="btn rotate45 btn-ctl-right"
                    action={()=> {setActive(false)}} />
        <div className="list-header-ctl-block">
          <SearchField searchOptions={props.searchOptions}
                        actionOptionSelect={props.actionOptionSelect}
                        actionSearch={props.actionSearch}
                        liveSearch={props.liveSearch} />
        </div>
        {props.filterSets &&
          <div className="list-header-ctl-block section">
              <label>Filter by</label>
              {Array.isArray(props.filterSets) &&
                props.filterSets.map((filter, index) => {
                  return <FilterSet key={`fltrst${index}`}
                                    filter={filter}
                                    activeFilters={props.activeFilters &&
                                                    props.activeFilters[filter.filterName] ?
                                                      props.activeFilters[filter.filterName] :
                                                      null}
                                    actionShowAll={props.actionShowAll}
                                    actionSelectFilter={props.actionSelectFilter} />
                })
              }
          </div>
        }

      </div>

    </div>
  )
}
