import React, {useEffect, useState} from 'react';

import FormDynamic from 'components/forms/FormDynamic';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

export default (props) => {
  const [value, setValue] = useState(null);
  const [type, setType] = useState(null);
  const [fieldsets, setFieldsets] = useState(null);
  const [form, setForm] = useState(null);
  
// HOOKS
useEffect(() => {
  if(props.value && props.value !== value) {
    setValue(props.value);
  }
}, [props.value]);

useEffect(() => {
  if(props.type && props.type !== type) {
    setType(props.type);
  }
}, [props.type]);

useEffect(() => {
  if(type) {
    setFieldsets([
       {fieldset_name: `${value ? 'update' : 'add'} ${type}`,
         fields: [
          {field_type: 'text',
            field_name: `field_${type}`,
            field_label: `${props.capitalizeString(type)} Contents`,
            field_placeholder: `Add ${props.capitalizeString(type)} Content`,
            field_value: value,
            field_required: true,
            field_verify: true,
            field_order: 1,
          }
       ]}
     ]);
  }
}, [type, value]);

useEffect(() => {
  if(fieldsets) {
    setForm(
      <FormDynamic
          content={fieldsets}
          name="add-edit-field_appendage"
          returnObject={true}
          fieldOnChange={null}
          fieldOnLoad={null}
          formActions={{formCancel: handleCancel, formSubmit: handleUpdate}}
          formCompleteLabel={value ? 'Update' : 'Add'} />
    )
  }
}, [fieldsets]);

// FUNCTIONS
  function handleCancel(e) {
    if(props.actionCancel) {
      props.actionCancel(e);
    }
  }

  function handleUpdate(params) {
    if(props.actionUpdate) {
      props.actionUpdate(params);
    }
  }

  return (form)
}
