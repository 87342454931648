import React from 'react';

export default (props) => {
  console.log("USER", props.user);
  return (
    <div>
      <h4>What are organizations?</h4>
      <p>Orgainzations are the parent group for users. These are typically the company that a site or partner is attached to.</p>
      <h4>Do I need to create an organization?</h4>
      <p>You will need an organization for each individual site, store or portal. Also to grant a partner (non-staff/non store client) access to administer a site, store or portal on behalf of a client.</p>
    </div>
  )
}
