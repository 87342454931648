import React, {useEffect, useState} from 'react';

import AdminUsersMain from 'components/admin/users/AdminUsersMain';
import AdminOrdersList from 'components/admin/orders/AdminOrdersList';
import AdminOrderQuery from 'components/admin/orders/AdminOrderQuery';
import AdminUsersAdd from 'components/admin/users/AdminUsersAdd';
import AdminOrder from 'components/admin/orders/AdminOrder';
import AdminOrderTasks from 'components/admin/orders/AdminOrderTasks';
export default (props) => {
  const [orderComponent, setOrderComponent] = useState(null);
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [canEdit, setCanEdit] = useState(null);
  const [isSuper, setIsSuper] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [orders, setOrders] = useState(null);
  const [noPermissionOrders, setNoPermissionOrders] = useState(null);
  const [listArgs, setListArgs] = useState(null);
  
  let ivlOrders;
  
  // HOOKS
  useEffect(() => {
    return() => {
      ivlOrders &&
      clearInterval(ivlOrders);
    }
  }, []);
  
  useEffect(() => {
    props.userID &&
    props.userID !== userID &&
    setUserID(props.userID);
  }, [props.userID]);

  useEffect(() => {
    props.token &&
    props.token !== token &&
    setToken(props.token);
  }, [props.token])

  useEffect(() => {
    props.organizationID &&
    props.organizationID !== organizationID &&
    setOrganizationID(props.organizationID);
  }, [props.organizationID]);

  useEffect(() => {
    props.organizationName &&
    props.organizationName !== organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);

  useEffect(() => {
    props.languageCode	&&
    props.languageCode	!== languageCode &&
    setLanguageCode(props.languageCode);
  }, [props.languageCode	]);
  
  useEffect(() => {
    if(props.canEdit &&
      props.canEdit !== canEdit) {
      setCanEdit(props.canEdit);
    }
  }, [props.canEdit]);
  
  useEffect(() => {
    if(props.isSuper &&
        props.isSuper !== isSuper) {
        setIsSuper(props.isSuper);
      }
  }, [props.isSuper]);
  
  useEffect(() => {
    if(props.userAccess && props.userAccess !== userAccess) {
      setUserAccess(props.userAccess);
    } 
  }, [props.userAccess]);
  
  useEffect(() => {
    if(userID && organizationID && token) {
      let params = [
         {name: 'process', value: 'get_projects'},
         {name: 'user_id', value: userID},
         {name: 'token', value: token}
      ]
      if(![1, 2].includes(organizationID)) {
        params.push({name: 'organization_id', value: organizationID});
      }
      if(Array.isArray(listArgs)) {
        params.push(...listArgs);
      }
      
      if(!props.objExists(props.admin, 'orders') || 
          props.objGetValue(props.admin, 'reload.orders') === true) {
        props.objGetValue(props.admin, 'reload.orders') === true &&
        props.adminDeleteVal('reload.orders');
        props.adminLoadState(params, 'orders', 'orders');
        
        if(ivlOrders) {
          clearInterval(ivlOrders);
        }
        
        ivlOrders = setInterval(() => {
          if(document.visibilityState === 'visible') {
            props.adminLoadState(params, 'orders', 'orders');
          }
        }, props.minToMS(10));
      }
    }
  }, [userID, token, organizationID, props.admin.orders, props.admin.reload, listArgs]);

  useEffect(() => {
    if(props.objExists(props.admin, 'orders') && 
      Array.isArray(props.admin.orders)) {
        
        let ordersAll = [];
        props.admin.orders.map(org => {
          if(props.objExists(org, 'project_id')) {
            ordersAll.push(org);
          }else if(([1, 2].includes(organizationID) ||
              org.organization_id === organizationID) &&
              org.projects && Array.isArray(org.projects)) {
                org.projects.map(project => {
                  ordersAll.push(project);
                })
          }
        });
        // Order
        ordersAll.sort((a, b)=>{return a.project_created > b.project_created ? -1 : 1});
        if(ordersAll !== orders){
          setOrders(ordersAll);  
        }
        
        if(noPermissionOrders) {
          setNoPermissionOrders(false);
        }
    }else if(props.objGetValue(props.admin, 'orders') === 'no permission') {
      setNoPermissionOrders(true);
    }else if(props.objGetValue(props.admin, 'orders') === 'no results') {
      setOrders('no results');
    }
  }, [props.admin.orders]);


  useEffect(() => {
    if(userID && token && organizationID && (props.pgParams || props.objExists(props.admin, 'params'))) {
      const activity = props.pgParams.activity ? props.pgParams.activity : 
                        props.objExists(props.admin, 'params.activity') ? 
                          props.admin.params.activity : null;
      switch(activity) {
        case 'order':
          setOrderComponent(<AdminOrder {...props}
                                            userID={userID}
                                            organizationID={organizationID}
                                            token={token}
                                            organizationName={organizationName}
                                            pgParams={props.pgParams}
                                            languageCode={languageCode}
                                            canEdit={canEdit}
                                            isSuper={isSuper}
                                            userAccess={userAccess} />);
          return;
        case 'order-tasks':
        setOrderComponent(<AdminOrderTasks {...props}
                                          userID={userID}
                                          organizationID={organizationID}
                                          token={token}
                                          organizationName={organizationName}
                                          pgParams={props.pgParams}
                                          languageCode={languageCode}
                                          canEdit={canEdit}
                                          isSuper={isSuper}
                                          userAccess={userAccess} />);
        return;
        case 'order-query':
          setOrderComponent(<AdminOrderQuery {...props}
                                            userID={userID}
                                            token={token}
                                            organizationID={organizationID}
                                            organizationName={organizationName}
                                            languageCode={languageCode}
                                            pgParams={props.pgParams}
                                            canEdit={canEdit}
                                            isSuper={isSuper}
                                            userAccess={userAccess}
                                            orders={orders}
                                            noPermission={noPermissionOrders}
                                            actionListArgs={updateArgs}
                                            listArgs={listArgs}
                                            actionSelectOrg={props.actionUpdateCoreOrg} />);
          return;
        case 'orders-list':
        default:
          setOrderComponent(<AdminOrdersList {...props}
                                            userID={userID}
                                            token={token}
                                            organizationID={organizationID}
                                            organizationName={organizationName}
                                            languageCode={languageCode}
                                            pgParams={props.pgParams}
                                            canEdit={canEdit}
                                            isSuper={isSuper}
                                            userAccess={userAccess}
                                            orders={orders}
                                            noPermission={noPermissionOrders}
                                            actionListArgs={updateArgs}
                                            listArgs={listArgs} />);
          return;
      }
    }
  }, [props.pgParams, userID, token, organizationID, organizationName, languageCode, userAccess, props.admin, orders]);
  
  function updateArgs(args) {
    setListArgs(args);
    props.adminSetVal('reload.orders', true);
  }

  return (
    <div>
      {orderComponent}
    </div>
  )
}
