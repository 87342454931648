import React, {useEffect, useState, Fragment} from 'react';

import Control from 'components/atoms/Control';
import List from 'components/molecules/List';
import CheckList from 'components/forms/elements/CheckList';
import FormContactAddEdit from 'components/forms/common_forms/FormContactAddEdit';
import FormLocationAddEdit from 'components/forms/common_forms/FormLocationAddEdit';
import IconSVG from 'components/icons/IconSVG';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [allOptions, setAllOptions] = useState(null);
  const [optionList, setOptionList] = useState(null);
  const [activeOptions, setActiveOptions] = useState(null);
  const [restrictedOptions, setRestrictedOptions] = useState(null);
  const [billingContact, setBillingContact] = useState(null);
  const [billingLocation, setBillingLocation] = useState(null);

  // HOOKS
  useEffect(() => {
    if(props.userID && props.userID !== userID) {
      setUserID(props.userID);
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token && props.token !== token) {
      setToken(props.token);
    }
  }, [props.token]);
  
  useEffect(() => {
    if(props.organizationID && props.organizationID !== organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    const params = [
      {name: 'process', value: 'get_payment_options'},
      {name: 'user_id', value: props.userID},
      {name: 'token', value: props.token}
    ];
    props[`${props.location ? props.location : 'admin'}LoadState`](params, 'site', 'payment_options');

    return ()=> {
      props[`${props.location ? props.location : 'admin'}DeleteVal`]('payment_options');
    }
  }, [props.userID, props.token]);

  useEffect(() => {
    if(props.payOptions) {
      let payOptions = [];
      if(props.payOptions.public && Array.isArray(props.payOptions.public)) {
        props.payOptions.public.map(pop => {
          payOptions.push(pop);
        })
      };
      if(props.payOptions.private && Array.isArray(props.payOptions.private)) {
        props.payOptions.private.map(pop => {
          payOptions.push(pop);
        })
      };

      if(payOptions.length > 0) {
        setActiveOptions(payOptions);
      }
    }

  }, [props.payOptions]);
  
  useEffect(() => {
    if(props.billingContact) {
      if(props.billingContact.contact && props.billingContact.contact !== billingContact) {
        setBillingContact(props.billingContact.contact);
      }
      if(props.billingContact.location && props.billingContact.location !== billingLocation) {
        setBillingLocation(props.billingContact.location);
      }
    }
  }, [props.billingContact]);

  useEffect(() => {
    if(props.objExists(props.admin, 'payment_options') &&
        Array.isArray(props.admin.payment_options)) {
      setOptionList(
        props.admin.payment_options.map((option, index) => {
          return {option_name: option.option_name,
                  option_value: option.option_id,
                  option_active: activeOptions &&
                                  Array.isArray(activeOptions) &&
                                  activeOptions.find(popt => popt.option_id === option.option_id) ? true : false}
        })
      )
    }
  }, [props.admin.payment_options, activeOptions]);

  // FUNCTIONS
  function handleClick(option_id, value) {
    if(props.loadOptionChange) {
      Array.isArray(optionList) &&
      optionList.find(pop => pop.option_value === option_id) &&
      props.loadOptionChange(optionList.find(pop => pop.option_value === option_id), value);
    }
  }
  
  // LOCATION
  function loadLocationAddEdit(location) {
    props.setModal(
      <FormLocationAddEdit {...props}
          userID={userID}
          token={token}
          organizationID={organizationID}
          location={location ? location : null}
          locations={props.locations ? props.locations : null}
          locationType={'billingLocationToOrganization'}
          locationLink={organizationID}
          actionCancel={() => {props.setModal(null)}}
          actionAddEdit={locationAddEdit}
          returnBool={true} />
    )
  }
  
  function loadContactAddEdit(contact = null) {
    props.setModal(
      <FormContactAddEdit {...props}
          userID={userID}
          token={token}
          organizationID={organizationID}
          contact={contact ? contact : null}
          contacts={props.users ? props.users : null}
          contactType={'billingContactToOrganization'}
          contactLink={organizationID}
          actionCancel={() => {props.setModal(null)}}
          actionAddEdit={contactAddEdit}
          returnBool={true} />
          
    )
  }
  
  function contactAddEdit(params) {
    props.setModal(null);
    console.log("Checking Params", params);
    props.adminLoadState(params, 'contacts', 'reload.persona');
  }
  
  function locationAddEdit(params) {
    props.setModal(null);
    console.log("Checking Params", params);
    props.adminLoadState(params, 'locations', 'reload.persona');
  }

  return (
    <div className="list-set">
      <h4><IconSVG icon="payment" /> Payment Options</h4>
      {optionList &&
        <CheckList {...props} checklist={optionList} onClick={handleClick} />
      }
      
      <h4>Organization Billing Contact</h4>
      {billingContact &&
        <div>
        <div><b>Bill To:</b>{billingContact.contact_name_first} {billingContact.contact_name_last}</div>
        <div>{billingContact.contact_email}</div>
        <div>{billingContact.contact_phone}</div>
        </div>
      }
      {billingLocation && 
        <div>{`${billingLocation.location_address_1}${billingLocation.location_address_2 ? ` - ${billingLocation.location_address_1}` : ''}
              ${billingLocation.location_city}, ${billingLocation.location_province}
              ${billingLocation.location_country}  ${billingLocation.location_postal_code}`}
        </div>
      }
      <div className="list-set-ctl">
        <Control icon="pin" 
          label={`${billingLocation ? 'Change' : 'Add'} billing address`} 
          showLabel={true}
          action={loadLocationAddEdit} />
        
        {billingLocation && billingLocation.location_id &&
          <Control icon="user" 
            label={`${billingContact ? 'Change' : 'Add'} billing contact`} 
            showLabel={true}
            action={loadContactAddEdit} />  
        }
      </div>
      
    </div>
  )
}
