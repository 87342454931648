import React, {useEffect, useState, Fragment} from 'react';

import Loader from 'components/atoms/Loader';
import Control from 'components/atoms/Control';
import FileUpload from 'components/forms/elements/FileUpload';
import Switch from 'components/forms/elements/Switch';
import LiveFieldInput from 'components/forms/elements/LiveFieldInput';
import MediaHero from 'components/profiles/media/MediaHero';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [category, setCategory] = useState(null);
	const [parent, setParent] = useState(null);
	
	// HOOKS
	useEffect(() => {
		return() => {
			props.adminDeleteVal('category');
		}
	}, []);
	
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.organizationID && props.organizationID !== organizationID) {
			setOrganizationID(props.organizationID);
		}
	}, [props.organizationID]);
	
	useEffect(() => {
		if(props.languageCode && props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(userID && token && languageCode &&
			props.pgParams.cpntactivity) {
			const catParams = [
				{name: 'process', value: 'get_category'},
				{name: 'user_id', value: userID},
				{name: 'token', value: token},
				{name: 'language_code', value: languageCode},
				{name: 'category_id', value: props.pgParams.cpntactivity},
				{name: 'category_published', value: 'false'}
			];
			
			if(!props.objExists(props.admin, 'category') || 
				props.objGetValue(props.admin, 'reload.category') === true) {
				props.adminLoadState(catParams, 'categories', 'category');
				props.objGetValue(props.admin, 'reload.category') === true &&
				props.adminDeleteVal('reload.category');	
			}else{
				setTimeout(() => {
					props.adminLoadState(catParams, 'categories', 'category');
				}, 600000);
			}
		}
	}, [props.pgParams, userID, token, languageCode, props.admin.reload]);
	
	useEffect(() => {
		if(languageCode && 
			props.objExists(props.admin, `category.details.${languageCode}`) &&
			props.admin.category.details[languageCode] !== category) {
			setCategory({...props.admin.category.details[languageCode], 
						parent: props.objGetValue(props.admin, `category.details.${languageCode}.parent.details.${languageCode}`)});
		}
	}, [languageCode, props.admin.category]);
	
	useEffect(() => {
		if(languageCode && category && 
			category.category_parent > 0 && 
			(!category.parent ||
			props.objGetValue(props.admin, `category.details.${languageCode}.parent.details.${languageCode}.category_id`) !== 
			category.category_parent)) {
			const parentParams = [
				{name: 'process', value: 'get_category'},
				{name: 'user_id', value: userID},
				{name: 'token', value: token},
				{name: 'language_code', value: languageCode},
				{name: 'category_id', value: category.category_parent}
			];
			
			if(!props.objExists(props.admin, 'category.parent') || 
				props.objGetValue(props.admin, 'reload.category') === true) {
				props.adminLoadState(parentParams, 'categories', `category.details.${languageCode}.parent`);	
			}else{
				setTimeout(() => {
					props.adminLoadState(parentParams, 'categories', `category.details.${languageCode}.parent`);
				}, 600000);
			}
		}
	}, [category, props.admin.reload, languageCode]);
	
	// FUNCTIONS
	function loadAddImage() {
		props.setModal(
			<FileUpload {...props}
			  userID={userID}
			  token={token}
			  organizationID={organizationID}
			  language_code={languageCode}
			  media_type="image"
			  media_owner_type="category"
			  media_owner_id={category.category_id}
			  media_published={true}
			  return_boolean={true}
			  actionCancel={()=>{props.setModal(null);}}
			  actionUpload={{action: ()=>{props.setModal(null)}}}
			  cueReload={{location: props.location ? props.location : 'admin', type: 'category'}}
			  />
		);
	}
	
	function updateValue(field_name, field_value) {
		const params = [
		  {name: 'process', value: 'update_value'},
		  {name: 'field_id', value: props.product.details[languageCode].text_id},
		  {name: 'field_name', value: field_name},
		  {name: 'field_value', value: field_value},
		  {name: 'field_parent', value: props.product.product_id},
		]
		props[`${props.location}LoadState`](params, 'common_tasks', 'reload.product');
		props[`${props.location}SetVal`]('reload.products', true);
	  }
	
	  function updateLink(linkName, linkValue, linkParams = []) {
		if(userID && token) {
		  console.log("Checking link stuff:", linkName, linkValue, linkParams);
		  const params = [
			...linkParams,
			{name: 'process', value: 'update_links'},
			{name: 'user_id', value: userID},
			{name: 'token', value: token},
			{name: 'link_field', value: linkName},
			{name: 'link_value', value: linkValue},
		  ];
		  console.log("Update Link Params", params);
		  // props[`${props.location}LoadState`](params, 'links', 'reload.product');
		  // props[`${props.location}SetVal`]('reload.products', true);
		}
	  }
	
	function publishCategory(value) {
		const params = [
			{name: 'process', value: 'update_links'},
			{name: 'user_id', value: userID},
			{name: 'token', value: token},
			{name: 'link_type', value: 'categoryTextToLanguage'},
			{name: 'link_from', value: category.text_id},
			{name: 'link_to', value: languageCode === 'fr' ? 2 : 1},
			{name: 'link_field', value: 'link_published'},
			{name: 'link_value', value: value ? 'true' : 'false'},
		  ];
		  console.log("publish cat params", params);
		  props[`${props.location ? props.location : 'admin'}LoadState`](params, 'links', 'reload.category');
	}
	
	function privateCategory(value) {
		const params = [
			{name: 'process', value: 'update_links'},
			{name: 'user_id', value: userID},
			{name: 'token', value: token},
			{name: 'link_type', value: 'categoryToOrganization'},
			{name: 'link_from', value: category.category_id},
			{name: 'link_to', value: category.organization_id},
			{name: 'link_field', value: 'link_published'},
			{name: 'link_value', value: value ? 'false' : 'true'},
		];
		console.log("private cat params", params);
		props[`${props.location ? props.location : 'admin'}LoadState`](params, 'links', 'reload.category');
	}
	
	console.log("CATEGORY", category, props.admin.category);
	return(
		<div className="profile full">
			{category ?
				<Fragment>
					<Switch {...props} 
							label="Publish" 
							value={category.category_published === 1 ? true : false}
							action={publishCategory} />
					
					<Switch {...props} 
						label="Hidden" 
						value={category.category_private === 1 ? true : false}
						action={privateCategory} />
					
					<article className="section">
						<h2>{props.capitalizeString(category.category_name, true)}</h2>
						<div className="flex-stack">
							{props.objExists(category, 'media[0].media_file_name') &&
							<MediaHero {...props} 
										media={category.media[0]}
										className="flex-row" />
							}
							<div className="flex-row">
								<Control {...props}
											icon="image" 
											label="Upload Image" 
											showLabel={true}
											action={loadAddImage} />
							</div>
						</div>
						<div className="flex-row">
						<div className="flex-stretch-content flex-column">
							{category.parent && 
								<div className="flex-column-row">Parent: <b>{props.capitalizeString(category.parent.category_name, true)}</b></div>
								
							}
							{category.products && 
								<div className="flex-column-row">
									Product Count: <b>{category.products && Array.isArray(category.products) ? 
														category.products.length === 1 ? '1 Product' :
														`${category.length} Products` : 
														'No Products'}</b>
								</div>
							}
							{category.category_created &&
								<div className="flex-column-row">Created: <b>{props.dateDisplay(category.category_created)}</b></div>
							}
							{category.category_modified &&
								category.category_created !== category.category_modified &&
								<div className="flex-column-row">Updated: <b>{props.dateDisplay(category.category_modified)}</b></div>
							}
						</div>
						</div>
					</article>
					
				</Fragment>
			:
				<Loader label="Loading category..." />
			}
		</div>
	)
}