import React from 'react';

import ProductPricingDiscount from 'components/profiles/products/ProductPricingDiscount';
import FormPricingDiscountAddEdit from 'components/forms/common_forms/FormPricingDiscountAddEdit';
import Control from 'components/atoms/Control';

export default (props) => {

  function loadAdd() {
    props.setModal(
      <FormPricingDiscountAddEdit
        {...props}
        discount={null}
        actionAddEdit={handleAddEdit}
        actionCancel={()=>{props.setModal(null)}} />
    )

    if(props.actionEdit){
      props.actionEdit(props.discount.discount_id);
    }
  }

  function handleAddEdit(params) {
    if(props.actionAddEdit) {
      props.actionAddEdit(params);
    }
  }

  function handleRemove(params) {
    if(props.actionRemove) {
      props.actionRemove(params);
    }
  }

  return (
    <div className="product-pricing-discounts margin-top-1em">
      <h4>Discounts</h4>
      {props.discounts && Array.isArray(props.discounts) && props.discounts.length &&
        props.discounts.map((discount, index) => {
          return <ProductPricingDiscount {...props}
                                        key={`pDis${index}`}
                                        discount={discount}
                                        pricing_id={props.pricing_id}
                                        actionAddEdit={handleAddEdit}
                                        actionRemove={handleRemove} />
        })
      }
      <Control action={null}
        icon={"add"}
        action={loadAdd}
        showLabel={true}
        label={`Add Discount`} />
    </div>
  )
}
