import React, {useEffect, useState} from 'react';

import IconToken from 'components/icons/IconToken';
import ModalConfirmation from 'components/molecules/ModalConfirmation';
import FormLocationAddEdit from 'components/forms/common_forms/FormLocationAddEdit';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [userAccess, setUserAccess] = useState(null);
	const [location, setLocation] = useState(null);
	const [active, setActive] = useState(false);
	
	// HOOKS
	useEffect(() => {
		props.userID &&
		props.userID !== userID &&
		setUserID(props.userID);
	}, [props.userID]);
	
	useEffect(() => {
		props.token &&
		props.token !== token &&
		setToken(props.token);
	}, [props.token])
	
	useEffect(() => {
		props.organizationID &&
		props.organizationID !== organizationID &&
		setOrganizationID(props.organizationID);
	}, [props.organizationID]);
	
	 useEffect(() => {
		props.languageCode &&
		props.languageCode !== languageCode &&
		setLanguageCode(props.languageCode);
	}, [props.languageCode]);
	  
	useEffect(() => {
		props.userAccess &&
		props.userAccess !== userAccess &&
		setUserAccess(props.userAccess);
	}, [props.userAccess]);
	
	useEffect(() => {
		props.location &&
		props.location !== location &&
		setLocation(props.location);
	}, [props.location]);
	
	// FUNCTIONS 
	function loadMakeDefault() {
		props.setModal(
			<ModalConfirmation {...props}
								icon='pin'
								message={`Make <b>${location.location_address_1}, ${location.location_city}, ${location.location_province}</b> your default location?`}
								actionAccept={makeDefault} />
		)
	}
	
	function makeDefault() {
		props.setModal(null);
		if(props.locationType && props.locationTypeID) {
			const paramsDefault = [
				{name: 'process', value: 'set_default_location'},
				{name: 'user_id', value: userID},
				{name: 'token', value: token},
				{name: 'location_id', value: location.location_id},
				{name: 'location_type', value: props.locationType},
				{name: 'location_type_id', value: props.locationTypeID}
			];
			console.log("PARAMS DEFAULT", `${props.stateLocation ? props.stateLocation : 'admin'}LoadState`, paramsDefault);
			props[`${props.stateLocation ? props.stateLocation : 'admin'}LoadState`](paramsDefault, 'locations', 'reload.user');
		}else{
			props.notifyUser({message: '<b>location type</b> or <b>location type ID</b> missing.'})
		}
	}
	
	function loadEditLocation() {
		props.setModal(
			<FormLocationAddEdit {...props}
				userID={userID}
				token={token}
				location={props.location}
				coreUser={props.coreUser}
				organizationID={props.organizationID}
				returnBool={true}
				actionCancel={()=>{props.setModal(null)}}
				actionAddEdit={locationEdit} />
		)
	}
	
	function locationEdit(params) {
		props.setModal(null);
		props[`${props.stateLocation ? props.stateLocation : 'admin'}LoadState`](params, 'locations', `reload.${props.listLocation ? props.listLocation : 'locations'}`);
		console.log('LOCATION EDIT', `reload.${props.listLocation ? props.listLocation : 'locations'}`, params);
	}
	
	function loadRemoveLocation() {
		props.setModal(
			<ModalConfirmation {...props}
				message={`Remove <b>${location.location_address_1}, ${location.location_city}, ${location.location_province}</b> from your addresses?`}
				actionCancel={()=>{props.setModal(null)}}
				actionAccept={removeLocation} />
		)
	}
	
	function removeLocation() {
		props.setModal(false);
		const paramsRemove = [
			{name: 'process', value: 'update_link_no_id'},
			{name: 'user_id', value: userID},
			{name: 'token', value: token},
			{name: 'link_from', value: location.location_id},
			{name: 'link_to', value: props.locationTypeID},
			{name: 'link_type', value: props.locationType},
			{name: 'link_field', value: 'link_removed'},
			{name: 'link_value', value: true},
			{name: 'return_bool', value: true}
		];
		props[`${props.stateLocation ? props.stateLocation : 'admin'}LoadState`](paramsRemove, 'links', `reload.${props.listLocation ? props.listLocation : 'locations'}`);
	}	
	return (
		location &&
		<div className={`list-item align-top no-wrap${active ? ' active' : ''}`}>
			<IconToken icon="pin" className="no-mobile" />
			<div className="content">
				<div className="preview truncate">
				<IconToken icon="expand" 
							tooltip="Expand" 
							className="ctl-view" 
							action={!active ? ()=>{setActive(true)}: null} />
				{`${props.objExists(location, 'location_address_1') ? location.location_address_1 : ''}${
						props.objExists(location, 'location_address_2') ?` - ${location.location_address_2}` : ''}${
							props.objExists(location, 'location_city') ? `, ${location.location_city}` : ''}${
							props.objExists(location, 'location_province') ? `, ${location.location_province}` : ''}`
				}
				</div>
				<div className="expanded">
					<IconToken icon="collapse" 
						tooltip="Expand" 
						className="ctl-view" 
						action={active ? ()=>{setActive(false)}: null} />
					<div className="expanded-block">
						{props.objExists(location, 'location_name') &&
							<p>
								<b>{location.location_name}</b>
							</p>
						}
						{props.objExists(location, 'location_address_1') &&
							<p>{`${location.location_address_1}${
									props.objExists(location, 'location_address_2') ?
										` - ${location.location_address_2}` : ''}`}
							</p>
						}
						{(props.objExists(location, 'location_city') ||
							props.objExists(location, 'location_province')) &&
							<p>{`${props.objExists(location, 'location_city') ? 
									location.location_city : ''}${
									props.objExists(location, 'location_province') ?
										`, ${location.location_province}` : ''}`}
							</p>
						}
						{(props.objExists(location, 'location_country') ||
							props.objExists(location, 'location_postal_code')) &&
							<p>{`${props.objExists(location, 'location_country') ? 
									`${location.location_country}  ` : ''}${
									props.objExists(location, 'location_postal_code') ?
										location.location_postal_code : ''}`}
							</p>
						}
					</div>
					
				</div>
				<div className="ctl">
					{!location.location_default &&
						<IconToken icon="star" className="btn" tooltip='Make Default' action={loadMakeDefault}/>
					}
					<IconToken icon="edit" className="btn" tooltip='Edit' action={loadEditLocation} />
					<IconToken icon="trash" className="btn" tooltip='Remove' action={loadRemoveLocation} />
				</div>
			</div>
		</div>
	)
} 