import React from 'react';

import ProductPricingOption from 'components/profiles/products/ProductPricingOption';


export default (props) => {
  function addEditPricing(params) {
    if(props.actionAddEdit) {
      props.actionAddEdit(params);
    }
  }

  function remove(params) {
    if(props.actionRemove) {
      props.actionRemove(params);
    }
  }

  function addEditDiscount(params) {
    if(props.actionDiscountAddEdit) {
      props.actionDiscountAddEdit(params);
    }
  }

  return (
    <div className="product-pricing">
      {props.pricing && Array.isArray(props.pricing) && props.pricing.length &&
        props.pricing.map((priceOption, index) => {
          return <ProductPricingOption {...props}
                    key={`pOpt${index}`}
                    pagePricing={false}
                    priceOption={priceOption}
                    product_id={props.product_id}
                    actionAddEdit={addEditPricing}
                    actionRemove={remove}
                    actionDiscountAddEdit={addEditDiscount} />
        })
      }
      {props.page_pricing && 
        <ProductPricingOption {...props}
                    pagePricing={true}
                    priceOption={props.page_pricing}
                    product_id={props.product_id}
                    actionAddEdit={addEditPricing}
                    actionRemove={remove}
                    actionDiscountAddEdit={addEditDiscount} />
      }
    </div>
  )
}
