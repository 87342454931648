import React from 'react';
import PagePublic from 'components/templates/PagePublic';

import UserOrder from 'components/account/UserOrder';

export default () => {
  return (
    <PagePublic>
      <UserOrder />
    </PagePublic>
  )
}
