import React, {useEffect, useState, Fragment} from 'react';

import FormDynamic from 'components/forms/FormDynamic';
import IconSVG from 'components/icons/IconSVG';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [task, setTask] = useState(null);
	const [taskName, setTaskName] = useState(null);
	const [fieldsets, setFieldsets] = useState(null);
	const [form, setForm] = useState(null);
	const [fileName, setFileName] = useState(null);
	const [fileVersion, setFileVersion] = useState(null);
	const [uploadName, setUploadName] = useState(null);
	const [fileTag, setFileTag] = useState(null);
	
	useEffect(() => {
		if(props.userID && props.userID != userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token != token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.task && props.task != task) {
			setTask(props.task);
		}
	}, [props.task]);
	
	useEffect(() => {
		if(props.languageCode && 
			props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}else if (!props.languageCode && 
					!languageCode &&
					props.objExists(props.site, 'site_language')) {
			setLanguageCode(props.site.site_language);
		}else if (!languageCode) {
			setLanguageCode('en');
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(task) {
			// SET TASK NAME FOR MODAL
			let tName;
			if(props.objExists(task, 'item_name')) {
				setTaskName(`this ${props.capitalizeString(task.item_name, true)}`);
			}else if(props.objExists(task, `order_id`) &&
					props.objExists(task, `project_id`) &&
					props.objExists(task, `organization_code`)) {
				setTaskName(props.orderNumDisplay(task.project_id, task.order_id, task.organization_code));
			}else if(props.objExists(task, `project_id`) &&
					props.objExists(task, `organization_code`)) {
				setTaskName(props.projectNumDisplay(task.project_id, task.organization_code));
			}else{
				setTaskName(`Task ${task.task_id}`);
			}
			
			// SET FORM FIELDS
			let formFieldsets = [{
				fieldset_name: `Complete ${task.item_id ? 'Item' : task.order_id ? 'Shipment' : task.project_id ? 'Order' : 'Task'} Creative`,
				fields: [
					{field_type: 'text',
					field_name: 'media_name',
					field_label: 'File Name',
					field_value: props.fileName,
					fieldOnChange: updateFileName,
					field_order: 1,
					field_required: true,
					field_validate: true
					},
					{field_type: 'number',
					field_name: 'fileVersion',
					field_label: 'File Version',
					field_value: props.fileVersion,
					fieldOnChange: updateFileVersion,
					field_order: 2,
					field_required: false,
					field_validate: false
					},
					{field_type: 'file',
					field_name: 'item_file',
					field_label: 'Upload New Proof',
					field_value: null,
					field_order: 3,
					file_required: false,
					file_validate: false
					},
					{field_type: 'textarea',
					field_name: 'message_text',
					field_label: 'comments',
					field_placeholder: 'Provide any additional comments.',
					field_value: null,
					field_required: false,
					field_validate: false,
					field_order: 4
					},
					{field_type: 'hidden',
					field_name: 'process',
					field_value: 'advance_task'
					},
					{field_type: 'hidden',
					field_name: 'user_id',
					field_value: userID
					},
					{field_type: 'hidden',
					field_name: 'token',
					field_value: token
					},
					{field_type: 'hidden',
					field_name: 'task_id',
					field_value: task.task_id
					},
					{field_type: 'hidden',
					field_name: 'task_status',
					field_value: 'complete'
					},
					{field_type: 'hidden',
					field_name: 'task_owner_type',
					field_value: props.taskOwnerType
					},
					{field_type: 'hidden',
					field_name: 'task_owner_id',
					field_value: props.taskOwnerID
					},
					{field_type: 'hidden',
					field_name: 'task_root_type',
					field_value: props.taskRootType
					},
					{field_type: 'hidden',
					field_name: 'task_root_id',
					field_value: props.taskRootID
					}
				]
			}];
			
			setFieldsets(formFieldsets);
		}
	}, [task]);
	
	useEffect(() => {
		if(fieldsets) {
			setForm(
				<FormDynamic {...props}
				  content={fieldsets}
				  name="complete-task"
				  returnObject={true}
				  fieldOnChange={null}
				  formActions={{formCancel: handleCancel, formSubmit: handleComplete}}
				  formCompleteLabel="Complete" />
			)
		}
	}, [fieldsets, props.files]);
	
	useEffect(() => {
		if(fileName) {
			let ulName = fileName.replaceAll(' ', '_');
			
			if(props.media_type === 'proof') {
				ulName += '_proof';
			}
			if(fileVersion) {
				ulName += `_rev${fileVersion}`;
			}
			if(ulName !== uploadName) {
				setUploadName(ulName);
			}
			
		}else if(uploadName) {
			setUploadName(null);
		}
	}, [fileName, fileVersion]);
	
	useEffect(() => {
		if(fileTag && uploadName) {
			if(!uploadName.includes(`.${fileTag}`)) {
				if(uploadName.search('.') < 0) {
					setUploadName(`${uploadName}.fileTag`);
				}else{
					const nameNodes = uploadName.split('.');
					setUploadName(`${nameNodes[0]}.${fileTag}`);
				}
			}
		}
	}, [uploadName, fileTag]);
	
	// FUNCTIONS
	function updateFileName(param) {
		if(param.value !== fileName) {
			setFileName(param.value);
		}
	}
	
	function updateFileVersion(param) {
		if(parseInt(param.value) !== fileVersion) {
			setFileVersion(parseInt(param.value));
		}
	}
	
	function handleCancel() {
		if(props.actionCancel) {
			props.actionCancel(true);
		}else{
			props.setModal(null);
		}
	}
	
	function handleComplete(params) {
		if(Array.isArray(params)) {
			const mediaID = params.find(param => param.name === 'proof_file' && parseInt(param.value) > 0);
			params.push({name: 'media_file_upload_name', value: uploadName});
			if(props.actionSubmit) {
				props.actionSubmit(params);
			}else{
				props.setModal(null);
			}	
		}
		
	}
	
	return (
		<Fragment>
			<div className="modal-header text-center">
				<IconSVG icon="check" className="icon-header success" />
				<h3>{`Complete ${taskName ? taskName : 'this task'}?`}</h3>
				<p>Upload the proof and any comments before clicking 'COMPLETE'</p>
			</div>
			<p>File Upload Name: {uploadName}</p>
			{form}
		</Fragment>
	)
}