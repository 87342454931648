import React from 'react';

export default (props) => {
  return (
    <div>
      <h4>Who are users?</h4>
      <p>Users are staff, suppliers, partners, clients and customers.</p>
      <h4>When do I create a user?</h4>
      <p>New staff members should have a user account create for them to get them into the system.</p>
      <p>If you want to give a client access to their portal or other features in the dashboard you will need to create a user account for them.</p>
      <p>Also suppliers and partners (non-staff collaborators on behalf of clients - eg: an agency responsible for supplying creative for a client's portal) that need access should also have user accounts</p>
      <h4>What is a user capable of?</h4>
      <p>Users can be given 'Roles' which give them various levels of access to the dashboard system. custom access to individual components of the system can also be given to a specific user</p>
    </div>
  )
}
