import React, {useEffect, useState, Fragment} from 'react';

import FormDynamic from 'components/forms/FormDynamic';
import PDFViewer from 'components/pdf/PDFViewer';
import FormFontUpload from 'components/forms/common_forms/FormFontUpload';
import Loader from 'components/atoms/Loader';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [fonts, setFonts] = useState(null);
	const [fontsLoading, setFontsLoading] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.languageCode && props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(userID && token) {
			const paramsFontList = [
				{name: 'process', value: 'get_fonts'},
				{name: 'user_id', value: userID},
				{name: 'token', value: token},
				{name: 'type', value: 'pdf'}
			];
			if(!props.objExists(props.admin, `fonts.pdf`) ||
				props.objGetValue(props.admin, `reload.fonts.pdf`) === true) {
				if(props.objGetValue(props.admin, `reload.fonts.pdf`) === true) {
					props.adminDeleteVal('reload.fonts.pdf');
					setFontsLoading(true);
				}
				props.adminLoadState(paramsFontList, 'files', 'fonts.pdf');
			}
			
		}
	}, [userID, token, props.admin]);
	
	useEffect(() => {
		if(Array.isArray(props.objGetValue(props.admin, `fonts.pdf`)) &&
			props.admin.fonts.pdf !== fonts) {
				fontsLoading &&
				setFontsLoading(null);
				setFonts(props.admin.fonts.pdf);
		}
	}, [props.admin.fonts]);
	
	// <PDFViewer
	// file="https://api.aecore.app/files/create_pdf.php?query=P3Byb2Nlc3M9b3V0cHV0X3NoZWxsX3BkZiZ1c2VyX2lkPTEmdG9rZW49Wm5VM1ZIVXlRUzk2WjJsdWQzbEllRFoxY201alpVY3pWWFpLTVdselpESXlWVzVRT0haQ1dYUlRhbEJzYlVGUFVFbFhLMG9yY0RBeU0xSnFjMHBaVURvNmxYQWpqJTJGbHE2JTJCa3d5MExPWXdJTmNBJTNEJTNEJml0ZW1faWQ9OTI5"
	// stealthCtl={true} />
	
	return (
		<div className="border-block">
			<div className="info-block">
				<h4>Install Fonts</h4>
				<p>Upload TTF, Type 1 or OTF fonts for PDF output.<br />
				<b>NOTE:</b>Type 1 OTF fonts must be converted to TTF</p>
				<p>Fonts can be converted online at&nbsp;
					<a href="https://convertio.co/otf-ttf/" target="_blank">
						Covertio
					</a>
				</p>
			</div>
			<FormFontUpload {...props}
				userID={userID}
				token={token} />
			
			{Array.isArray(fonts) &&
				fonts.length > 0 &&
				<Fragment>
					<h3>Available Fonts</h3>
					<div className="scrollable" style={{maxHeight: '10em', marginTop: '0em'}}>
						{fonts.map((font, index) => {
							return <div style={{padding: ' .25em .5em'}}>{font.font_name}</div>
						})}
						{fontsLoading && 
							<Loader label="loading fonts..." overlayContents={true} />
						}
					</div>
				</Fragment>
			}
		</div>
	)
}