import React, {useEffect, useState} from 'react';

import Loader from 'components/atoms/Loader';
import FormDynamic from 'components/forms/FormDynamic';
import Article from 'components/molecules/Article';
import FileUpload from 'components/forms/elements/FileUpload';

export default (props) => {
	const [form, setForm] = useState(null);
	const [fieldsets, setFieldsets] = useState(null);
	const [newValue, setNewValue] = useState(null);
	const [article, setArticle] = useState(null);
	
	useEffect(() => {
		setFieldsets([{fieldset_name: `${props.objExists(props, 'article.article_id') ? 'Edit' : 'Add'} Article`,
			fields: [
				{field_type: 'switch',
				field_name: 'article_published',
				field_label: 'Publish',
				field_value: props.objGetValue(props, 'article.article_org_published') === 1 ? true : false,
				field_required: false,
				field_validate: false,
				field_order: 1,
				},
				{field_type: 'text',
				field_name: 'article_title',
				field_label: 'Title',
				field_placeholder: 'Article Title',
				field_value: props.objExists(props, `article.details.${props.languageCode}.article_title`) ?
								props.article.details[props.languageCode].article_title : null,
				field_required: false,
				field_validate: false,
				fieldOnChange: updateValue,
				field_order: 2,
				},
				{field_type: 'textarea',
				field_name: 'article_content',
				field_label: 'Article text',
				field_placeholder: 'Article body content',
				field_value: props.objExists(props, `article.details.${props.languageCode}.article_content`) ?
								props.article.details[props.languageCode].article_content : null,
				field_required: false,
				field_validate: false,
				fieldOnChange: updateValue,
				field_order: 3,
				},
				{field_type: 'text',
				field_name: 'article_url',
				field_label: 'Article URL',
				field_placeholder: 'Article link URL',
				field_value: props.objExists(props, `article.details.${props.languageCode}.article_url`) ?
								props.article.details[props.languageCode].article_url : null,
				field_required: false,
				field_validate: false,
				field_order: 4,
				},
				{field_type: 'select',
				field_name: 'article_style',
				field_label: 'Article Style',
				field_value: props.objExists(props, 'article.article_style') ? 
								props.article.article_style : 
								'picture-bottom',
				field_required: false,
				field_validate: false,
				fieldOnChange: updateValue,
				field_order: 5,
				option_groups: [
					{options: [
						{option_name: 'Picture Top', option_value: 'picture-top'},
						{option_name: 'Picture Centre', option_value: 'picture-centre'},
						{option_name: 'Picture Bottom', option_value: 'picture-bottom'},
						{option_name: 'Dark Overlay', option_value: 'overlay dark'},
						{option_name: 'Light Overlay', option_value: 'overlay light'},
					]}
				],
				},
				{field_type: 'hidden',
				field_name: 'process',
				field_value: props.objExists(props, 'article.article_id') ?
								'update_site_article' :
								'add_site_article'
				},
				props.objExists(props, 'article.article_id') && 
				{field_type: 'hidden',
				field_name: 'article_id',
				field_value: props.article.article_id
				},
				props.objExists(props, `article.details.${props.languageCode}.text_id`) && 
				{field_type: 'hidden',
				field_name: 'text_id',
				field_value: props.article.details[props.languageCode].text_id
				},
				{field_type: 'hidden',
				field_name: 'user_id',
				field_value: props.userID
				},
				{field_type: 'hidden',
				field_name: 'token',
				field_value: props.token
				},
				!props.objExists(props, 'feature.feature_id') &&
				props.organizationID &&
				{field_type: 'hidden',
				field_name: 'organization_id',
				field_value: props.organizationID
				},
				!props.objExists(props, 'feature.feature_id') &&
				{field_type: 'hidden',
				field_name: 'language_code',
				field_value: props.language_code ? props.language_code : 'en'
				},
				{field_type: 'hidden',
				field_name: 'return_bool',
				field_value: true,
				},
			]
		}]);
		
		if(props.article.article_id) {
			setArticle(props.article);	
		}else{
			const newArticle = {details: {}};
			newArticle['details'][props.languageCode] = {article_id: null};
			setArticle(newArticle);
		}
	}, [props.article]);
	
	useEffect(() => {
		if(fieldsets) {
			setForm(
				<FormDynamic
				  content={fieldsets}
				  name="add-edit-article"
				  returnObject={true}
				  fieldOnChange={handleChange}
				  fieldOnLoad={null}
				  formActions={{formCancel: handleCancel, formSubmit: handleAddEdit}} />
			)
		}
	}, [fieldsets]);
	
	useEffect(() => {
		if(newValue) {
			const originalArticle = article;
			switch(newValue.name) {
				case 'article_title':
				case 'article_content':
				case 'article_url':
					originalArticle['details'][props.languageCode][newValue.name] = newValue.value;
					break;
				case 'article_style':
					originalArticle[newValue.name] = newValue.value;
					break;
				default:
					console.log("did nothing: ", newValue.name, newValue.value);
					break;
			}
			setArticle(originalArticle);
			setNewValue(null);
		}
	}, [newValue]);
	
	useEffect(() => {
		console.log("Here's the article", article);
	}, [article]);
	
	// FUNCTIONS
	function handleChange(e) {
		console.log("Article Form is Changing");
	}
	
	function handleCancel(e) {
		console.log("Cancel Form");
		props.objExists(props, 'actionCancel') &&
		props.actionCancel();
	}
	
	function handleAddEdit(params) {
		props.objExists(props, 'actionAddEdit') &&
		props.actionAddEdit(params);
	}
	
	function updateValue(param) {
		setNewValue(param);
	}
	
	console.log("*** In aricle form testing: ", article);
	return (
		<div className="form-matrix">
			{form ? form : <Loader />}
			<div className="form-matrix-display">
				<div className="profile-component-article">
					{article && 
						<Article {...props}
								article={article}
								languageCode={props.languageCode} />	
					}
					{props.objExists(props, 'article.article_id') ?
						<FileUpload {...props}
							retainModal={true}
							cueReload={true}
							stateLocation={'articles'}
							returnInfo={true}
							media_type='image'
							media_owner_id={props.objExists(props, 'article.article_id') ? 
											props.article.article_id : null}
							media_owner_type ='Article'
							media_published ={true}
							user_id={props.userID}
							token={props.token}
							organization_id={props.organizationID}
							language_code={props.languageCode ? props.languageCode : 'en'} />
					:
						<p>Image can be loaded after feature is created (choose edit)</p>
					}
				</div>
			</div>
		</div>
	)
}