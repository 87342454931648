import React, {useEffect, useState} from 'react';

import Loader from 'components/atoms/Loader';
import FormDynamic from 'components/forms/FormDynamic';

export default (props) => {
	const [form, setForm] = useState(null);
	const [fieldsets, setFieldsets] = useState(null);
	const [aspectRatio, setAspectRatio] = useState(null);
	
	useEffect(() => {
		setFieldsets([{fieldset_name: `${props.objExists(props, 'slideshow.slideshow_id') ? 'Edit' : 'Add'} Slideshow`,
			fields: [
				{field_type: 'text',
				field_name: 'slideshow_name',
				field_label: 'Slideshow Name',
				field_placeholder: 'Name this new slideshow',
				field_value: props.objExists(props, 'slideshow.slideshow_name') ? props.slideshow.slideshow_name : null,
				field_required: false,
				field_validate: false,
				field_order: 1
				},
				{field_type: 'text',
				field_name: 'slideshow_aspect_ratio',
				field_label: 'Aspect Ratio',
				field_placeholder: 'Aspect Ratio (#:#)',
				field_value: aspectRatio,
				field_required: false,
				field_validate: false,
				fieldOnChange: updateValue,
				field_order: 2
				},
				{field_type: 'switch',
				field_name: 'slideshow_published',
				field_label: 'Publish',
				field_value: props.objGetValue(props, 'slideshow.slideshow_org_published') === 1 ? true : false,
				field_required: false,
				field_validate: false,
				field_order: 3,
				},
				{field_type: 'hidden',
				field_name: 'process',
				field_value: props.objExists(props, 'slide.slide_id') ?
								'update_site_slideshow' :
								'add_site_slideshow'
				},
				props.objExists(props, 'slideshow.slideshow_id') && 
				{field_type: 'hidden',
				field_name: 'slideshow_id',
				field_value: props.slideshow.slideshow_id
				},
				{field_type: 'hidden',
				field_name: 'user_id',
				field_value: props.userID
				},
				{field_type: 'hidden',
				field_name: 'token',
				field_value: props.token
				},
				!props.objExists(props, 'slideshow.slideshow_id') &&
				props.organizationID &&
				{field_type: 'hidden',
				field_name: 'organization_id',
				field_value: props.organizationID
				},
				!props.objExists(props, 'slideshow.slideshow_id') &&
				{field_type: 'hidden',
				field_name: 'language_code',
				field_value: props.language_code ? props.language_code : 'en'
				},
				{field_type: 'hidden',
				field_name: 'return_bool',
				field_value: true,
				},
			]
		}])
	}, [props.slide, aspectRatio]);
	
	useEffect(() => {
		if(!aspectRatio && props.objExists(props, 'slideshow.slideshow_aspect_ratio')) {
			setAspectRatio(props.slideshow.slideshow_aspect_ratio);
		}else{
			setAspectRatio('16:9');
		}
	}, [props.slideshow]);
	
	useEffect(() => {
		if(aspectRatio) {
			const ratios = adjustAspect(aspectRatio);
			document.getElementById('aspect-ratio').style.height = `${document.getElementById('aspect-ratio').offsetWidth*ratios.height}px`;
		}
	}, [aspectRatio]);
	
	useEffect(() => {
		if(fieldsets) {
			setForm(
				<FormDynamic
				  content={fieldsets}
				  name="add-edit-slideshow"
				  returnObject={true}
				  fieldOnChange={handleChange}
				  fieldOnLoad={null}
				  formActions={{formCancel: handleCancel, formSubmit: handleAddEdit}} />
			)
		}
	}, [fieldsets]);
	
	
	
	// FUNCTIONS
	function handleChange(e) {
		console.log("Slide Form is Changing");
	}
	
	function handleCancel(e) {
		console.log("Cancel Form");
		props.objExists(props, 'actionCancel') &&
		props.actionCancel();
	}
	
	function handleAddEdit(params) {
		props.objExists(props, 'actionAddEdit') &&
		props.actionAddEdit(params);
	}
	
	function adjustAspect(ratio) {
		const ratioValues = ratio.includes(':') ?
		  ratio.split(':') :
		  ratio.split('x');
		const ratioFactors = {width: parseFloat(ratioValues[0])/parseFloat(ratioValues[1]),
				  height: parseFloat(ratioValues[1])/parseFloat(ratioValues[0])};
		return ratioFactors;
	}
	
	function updateValue(param) {
		switch(param.name) {
			case 'slideshow_aspect_ratio':
				setAspectRatio(param.value);
			default:
				break;
		}
	}
	
	// console.log("FILES", props.files, props.slidefiles);
	// console.log("ADMIN", props.admin);
	// console.log("ASPECT Ratio", aspectRatio);
	return (
		<div className="form-matrix">
			{form ? form : <Loader />}
			<div className="form-matrix-display">
				<div id="aspect-ratio">
					{aspectRatio}
				</div>
			</div>
		</div>
	)
}