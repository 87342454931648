import React, {useEffect, useState} from 'react';

import UserList from 'components/profiles/users/UserList';
import Loader from 'components/atoms/Loader';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [users, setUsers] = useState(null);
  const [usersResponse, setUsersResponse] = useState(null);

  // HOOKS
  useEffect(() => {
    if(props.userID && props.userID !== userID) {
      setUserID(props.userID)
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token && props.token !== token) {
      setToken(props.token)
    }
  }, [props.token]);

  useEffect(() => {
    if(props.organizationID && organizationID !== props.organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    props.organizationName &&
    props.organizationName !== organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);
  
  useEffect(() => {
    if(props.languageCode && languageCode !== props.languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    if(props.userAccess && props.userAccess !== userAccess) {
      setUserAccess(props.userAccess);
    } 
  }, [props.userAccess]);

  useEffect(() => {
    console.log("TYPE USERS", typeof props.admin.users);
    if(Array.isArray(props.objGetValue(props.admin, 'users'))) {
      
      if(props.admin.users.length > 0 &&
        props.admin.users !== users) {
          setUsers(props.admin.users);
      }else if(props.admin.users.length === 0) {
        setUsersResponse("no users found");
      }
    }else if(props.objExists(props.admin, 'users') &&
            typeof props.admin.users === 'string') {
      setUsersResponse(props.admin.users);
    }
  }, [props.admin.users]);

  return (
    <div>
      {userID && organizationID && token && users &&
      Array.isArray(users) ?
        <UserList {...props} users={users}
                              title={'Users'}
                              location='admin'
                              organizationID={organizationID}
                              organizationName={organizationName}
                              userID={userID}
                              languageCode={languageCode}
                              token={token}
                              showFiler={true}
                              showAll={props.showAll} />
      :
        usersResponse ?
          <div>{props.capitalizeString(usersResponse)}</div>
        :
          <Loader />
      }
    </div>
  )
}
