import React, {useState, useEffect} from 'react';

import MediaToken from 'components/profiles/media/MediaToken';
import IconFile from 'components/icons/IconFile';
import Card from 'components/molecules/Card';

export default (props) => {
  const [media, setMedia] = useState(null);
  
  useEffect(() => {
    if(Array.isArray(props.media) && 
        props.media.length > 0 && 
        props.media !== media) {
      setMedia(props.media);
    }else if((!Array.isArray(props.media) || 
              props.media.length <= 0) && 
              media) {
      setMedia(null);
    }
  }, [props.media, props.mediaHero]);

  return (
      media &&
      <div className={`media-collection${props.altClass ?` ${props.altClass}` : ''}`} >
        <div className="media-collection-label">
          {props.setLabel ? props.setLabel : props.setType === 'files' ? 'More files' : 'More images'}
        </div>
        <div className="media-collection-scroll">
          <div className="media-collection-content">
            {media.map((token, index) => {
              if(props.mediaHero >= 0 &&
                  props.mediaHero !== index) {
                return( 
                  props.setType === 'images' || 
                  props.setType === 'image' ||
                  token.media_type === 'image' ?
                    <MediaToken 
                      key={`mdtk${index}`} 
                      media={token} 
                      index={index} 
                      action={props.action}/>
                  :
                    <IconFile 
                      key={`mdtk${index}`} 
                      icon={token.media_file_tag} 
                      {...token} 
                      index={index} 
                      action={props.action} /> 
                )
              }
            })
            }
          </div>
        </div>
      </div>
  )

}
