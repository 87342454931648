import React, {useEffect, useState} from 'react';

import IconToken from 'components/icons/IconToken';

export default (props) => {
	const [active, setActive] = useState(null);
	
	useEffect(() => {
		if(props.label && props.activeSection) {
			if(props.activeSection === props.label.toLowerCase().replace(' ', '-') &&
				!active) {
			setActive(true);
		}
		}
	}, [props.label, props.activeSection]);
	
	// FUNCTIONS
	function handleClick() {
		setActive(active ? false : true);
		if(props.onExpand) {
			props.onExpand(props.label, active ? false : true);
		}
	}
	
	return (
		<div className="profile-section">
			<label>
				<span>{props.label}</span>
				<IconToken icon={active ? `collapse` : `expand`} 
							action={handleClick}
							tooltip={active ? 'Hide' : 'Show'} />
			</label>
			<div className={`expandable${active ? ' active' : ''}`}>
				{props.children}
			</div>
		
		</div>
	)
}