import React, {useEffect, useState} from 'react';

import FileUpload from 'components/forms/elements/FileUpload';
import IconFile from 'components/icons/IconFile';
import IconSVG from 'components/icons/IconSVG';
import Control from 'components/atoms/Control';

export default (props) => {
	const [files, setFiles] = useState(null);
	const [uploadActive, setUploadActive] = useState(null);
	const [value, setValue] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(props.objExists(props.files, `uploads.${props.name}`) && 
			Array.isArray(props.files.uploads[props.name]) &&
			props.files.uploads[props.name].length > 0) {
			
			let fileArray = [];
			props.files.uploads[props.name].map(file => {
				fileArray.push(file);
			})
			if(fileArray.length > 0 && files != fileArray) {
				setFiles(fileArray);
			}
			setUploadActive(false);
		}else{
			setUploadActive(true);
		}
		
	}, [props.files, props.name]);
	
	useEffect(() => {
		if(files && Array.isArray(files) && files.length > 0 ) {
			setValue(files[0].media_id);
			if(props.onChange) {
				props.onChange({name: props.name, value: files[0].media_id});
			}
		}
	}, [files]);
	
	return(
		<div class="form-row">
			{files && Array.isArray(files) &&
				<div className="form-field file">
					<label><IconSVG icon="file" />{props.label}</label>
					{files.map(file => {
						return (
							<div className="field-file">
								<IconFile icon={file.media_file_tag} />
								<div className="field-file-name">
										{file.media_file_upload_name}
								</div>
								<input type="hidden" name={props.name} value={value} />
							</div>	
						)
					})}		
					<Control icon="file" label="Change File" showLabel={true} action={()=>{setUploadActive(true)}} />
				</div>
			}
			{uploadActive  && 
				<FileUpload {...props}
					label={props.label}
					retainModal={true}
					stateLocation={props.name}
					returnInfo={true}
					multiple={props.multiple ? props.multiple : false}
					media_type={props.objExists(props, 'media.media_type') ?
									props.media.media_type : props.media_type ?
										props.media_type : 'file'}
					media_owner_id={props.objExists(props, 'media.media_id') ?
									props.media.media_type : props.media_id ?
										props.media_id : props.userID ? props.userID : 0}
					media_owner_type ={props.objExists(props, 'media.media_owner_type') ?
										props.media.media_owner_type : props.media_owner_type ?
											props.media_owner_type : 'User'}
					media_published ={props.objExists(props, 'media.media_published') ?
										props.media.media_published : props.media_published ?
											props.media_published : true}
					user_id={props.userID ? props.userID :
								props.objExists(props, `user.user_id`) ? props.objExists(props, `user.user_id`) : 0}
					token={props.token ? props.token : 
							localStorage.getItem('token') ? localStorage.getItem('token') : 
								sessionStorage.getItem('token') ? sessionStorage.getItem('token') : null}
					organization_id={props.organization_id ? props.organization_id : 
										props.objExists(props.site, 'persona.organization_id') ? props.site.persona.organization_id : 0 }
					language_code={props.languageCode ? props.languageCode : 'en'} />	
			}
		</div>
	)
}