import React, {useEffect, useState} from 'react';

import Form from 'components/forms/Form';
import InputHook from 'components/forms/elements/Input';
import Switch from 'components/forms/elements/Switch';
import Button from 'components/forms/elements/Button';

export default (props) => {
  const fieldsLogin = [
    {fieldset_name: 'User Login',
    fields: [
        {field_type: 'hidden',
        field_name: 'process',
        field_label: 'Login',
        field_value: 'user_login'},
        {field_type: 'email',
        field_name: 'contact_email',
        field_label: 'Email',
        field_placeholder: 'Your email address',
        field_required: true},
        {field_type: 'password',
        field_name: 'user_key',
        field_label: 'Password',
        field_placeholder: 'Your password',
        field_required: true},
        {field_type: 'switch',
        field_name: 'persist',
        field_label: 'Remember Me',
        field_default: false}
      ]
    }
  ];

  return (
      <Form
        formAction={props.formAction}
        name="userLogin"
        id="userLogin"
        hidden={{process: 'user_login'}}
        validate={true}
        submitted={null}
        alertMsg={null} >
        <div className="form-row">
          <InputHook name="contact_email" type="text"  valuetype='email' label="User ID" required={true} fielderror={props.error} defaultValue='' />
        </div>
        <div className="form-row">
          <InputHook name="user_key" type="password" label="Password" required={true} defaultValue='password' />
        </div>
        <div className="form-row">
          <Switch name="persist" value={false} label="Remember Me" />
        </div>
        <div className="form-row">
          <Button name="login" label="login" valuetype='button' btnClass="full"  />
        </div>
      </Form>
    )
}
