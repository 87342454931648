import React, {useEffect, useState, Fragment} from 'react';

import Link from 'components/navigation/Link';
import Control from 'components/atoms/Control';
import IconSVG from 'components/icons/IconSVG';
import Loader from 'components/atoms/Loader';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [token, setToken] = useState(null);
  const [siteLanguage, setSiteLanguage] = useState(null);

  useEffect(() => {
    props.userID &&
    setUserID(props.userID);
  }, [props.userID]);

  useEffect(() => {
    props.token &&
    setToken(props.token);
  }, [props.token])

  useEffect(() => {
    props.organizationID &&
    setOrganizationID(props.organizationID);
  }, [props.organizationID]);

  useEffect(() => {
    props.organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);

  useEffect(() => {
    props.siteLanguage &&
    setSiteLanguage(props.siteLanguage);
  }, [props.siteLanguage]);

  return (
    <div className="list-item organization" >
      {props.organization &&
        <Fragment>
          <div className="list-item-entry organization-name">
          <b>
            <Link to={`/${props.location}/organizations/organization/${props.organization.organization_id}`}>
              {`${props.organization.organization_name}`}
            </Link>
          </b>
          </div>
          <div className="list-item-entry ctl">
            {props.objExists(props.user, 'user_roles.1.role_name') &&
              ['Super User'].includes(props.user.user_roles['1'].role_name) &&
              <Control icon="user"
                        action={null}
                        label={'Add User'}
                        showLabel={true} />
            }
            {'bb' === 'sending' && <Loader className="mini" />}
          </div>
        </Fragment>
      }

    </div>
  )
}
