import React, {useEffect, useState} from 'react';

import MessageListItem from 'components/profiles/messages/MessageListItem';
import MessageConsole from 'components/profiles/messages/MessageConsole';
import IconSVG from 'components/icons/IconSVG';
import Control from 'components/atoms/Control';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [messages, setMessages] = useState(null);
	
	useEffect(() => {
		setMessages(null);
	}, [])
	
	useEffect(() => {
		if(props.userID &&
			props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token &&
			props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.languageCode &&
			props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(Array.isArray(props.messages)) {
			setMessages(props.messages);
		}
	}, [props.messages]);
	
	// FUNCTIONS
	function loadCreateMessage() {
		props.setModal(
			<MessageConsole {...props}
				userID={userID}
				token={token}
				/>
		);
	}
	
	// console.log("USER STATE", props.user);
	return (
		Array.isArray(messages) &&
		<div className="dashboard-widget message-list list">
			<h4><IconSVG icon="chat" />{`${messages.length} ${props.title ? props.capitalizeString(props.title, true) : `Message${messages.length === 1 ? '' : 's'}`}`}</h4>
			<div className="scrollbox" >
			{messages.map((message, index) => {
				return(
					<MessageListItem {...props}
						key={`msgLst${index}`}
						userID={userID}
						token={token}
						languageCode={languageCode}
						message={message}
						location={props.location}
						stateLocation={props.stateLocation}
						noReply={props.noReply} />
				)	
			})}
			</div>
			<div className="ctl message-list-ctl">
				<Control icon="message" 
						label="Send Message" 
						showLabel={true}
						action={loadCreateMessage} />
			</div>
		</div>
	)
}