import React, {useEffect, useState} from 'react';
import { useParams } from "react-router";

import * as utils from 'methods/site';

import Project from 'components/profiles/projects/Project';
import Loader from 'components/atoms/Loader';

export default (props) => {
  props = {...props.children, ...utils};

  const [userID, setUserID] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [token, setToken] = useState(null);
  const [siteLanguage, setSiteLanguage] = useState(null);
  const [tasksSet, setTasksSet] = useState(null);
  const pgParams = useParams();

  // HOOKS
  useEffect(() => {
    return () => {
      props.userDeleteVal('order');
    }
  }, []);
  
  useEffect(() => {
    if(props.objExists(props.user, 'user_id') &&
        props.user.user_id !== userID) {
          setUserID(props.user.user_id);
    }
    
    if(sessionStorage.getItem('token')) {
      setToken(sessionStorage.getItem('token'))
    }else if(localStorage.getItem('token')) {
      setToken(localStorage.getItem('token'))
    }
  }, [props.user]);

  useEffect(() => {
    if(props.objExists(props.site, 'persona.organization_id')) {
      setOrganizationID(props.site.persona.organization_id);
      setOrganizationName(props.site.persona.organization_name);
    }
    if(props.objExists(props.site, 'persona.language_code')) {
      setSiteLanguage(props.site.persona.language_code);
    }else{
      setSiteLanguage('en');
    }

  }, [props.site.persona]);

  useEffect(() => {
    if(parseInt(pgParams.project_id) > 0 && userID && token) {
      const params = [
        {name: 'process', value: 'get_projects'},
        {name: 'user_id', value: userID},
        {name: 'core_user', value: userID},
        {name: 'token', value: token},
        {name: 'project_id', value: pgParams.project_id}
      ]
      props.userLoadState(params, 'orders', 'order');
    }
  }, [pgParams, userID, token]);
  
  useEffect(() => {
    if(!tasksSet && props.objExists(props.user, 'order.project_id')) {
      if(!props.objExists(props.user, 'order.tasks') || 
          !Array.isArray(props.user.order.tasks) || 
          props.user.order.tasks.length <= 0) {
            const taskParams = [
              {name: 'process', value: 'set_project_tasks'},
              {name: 'project_id', value: props.user.order.project_id},
              {name: 'organization_id', value: props.user.order.organization_id},
              {name: 'user_id', value: userID},
              {name: 'return_tasks', value: true}
            ];
            props.userLoadState(taskParams, 'tasks', 'order.tasks');
            setTasksSet(true);
            
      }else{
        setTasksSet(true);
      }
      
    }
  }, [props.user.order, userID]);
  
  useEffect(() => {
    if(props.objExists(props.cart, 'project')) {
      sessionStorage.removeItem('corePrjct');
      props.filesDeleteVal('uploads');
      props.filesDeleteVal('uploadComplete');
      props.cartEmpty();
    }
  }, [props.cart]);

  return (
    <div className="profile">
      <h3>Thank you! Your order has been received.</h3>
      {props.objExists(props.user, 'order') ?
        <Project {...props}
                {...utils}
                isAdmin={false}
                userID={userID}
                token={token}
                organizationID={organizationID}
                organizationName={organizationName}
                languageCode={siteLanguage}
                project={props.user.order} admin={false} />
      :
        <Loader label="Creating your order"/>
      }
    </div>

  )
}
