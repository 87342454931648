import React from 'react';
import { Router, Route, Switch} from 'react-router-dom';
import { createBrowserHistory } from "history";

import * as actions from 'actions/';
import * as utils from 'methods/site';

import PageHome from 'components/pages_public/PageHome';
import PageLogin from 'components/pages_public/PageLogin';
import PageProduct from 'components/pages_public/PageProduct';
import PageProductSearch from 'components/pages_public/PageProductSearch';
import PageCustomRequest from 'components/pages_public/PageCustomRequest';
import PageCustomRequestComplete from 'components/pages_public/PageCustomRequestComplete';
import PageCategory from  'components/pages_public/PageCategory';
import PageCategories from  'components/pages_public/PageCategories';
import PageCart from 'components/pages_public/PageCart';
import PageCheckout from 'components/pages_public/PageCheckout';
import PageCheckoutComplete from 'components/pages_public/PageCheckoutComplete';
import PageUser from 'components/pages_public/PageUser';
import PageUserOrders from 'components/pages_public/PageUserOrders';
import PageUserMessages from 'components/pages_public/PageUserMessages';
import PageUserTask from 'components/pages_public/PageUserTask';
import PageUserSettings from 'components/pages_public/PageUserSettings';
import PageOrder from 'components/pages_public/PageOrder';
import PageAdmin from 'components/pages_internal/PageAdmin';
import PageDashboard from 'components/pages_internal/PageDashboard';
import PageDashboardTask from 'components/pages_internal/PageDashboardTask';

function App() {
  const history = createBrowserHistory();
  return (
    <Router history={history}>
      <Switch>
          <Route exact path="/" component={PageHome} />
          <Route path="/login/:task?/:code?" component={PageLogin} />
          <Route exact path="/categories" component={PageCategories} />
          <Route exact path="/user" component={PageUser} />
          <Route exact path="/user/orders" component={PageUserOrders} />
          <Route exact path="/user/orders/order/:project_id" component={PageOrder} />
          <Route exact path="/user/settings" component={PageUserSettings} />
          <Route exact path="/user/messages" component={PageUserMessages} />
          <Route exact path="/user/task/:task_id" component={PageUserTask} />
          <Route path="/category/:category_id" component={PageCategory} />
          <Route path="/product/search/:search_params?" component={PageProductSearch} />
          <Route path="/product/:product_id?" component={PageProduct} />
          <Route path="/product/search/:search_params" component={PageProductSearch} />
          <Route exact path="/custom-request" component={PageCustomRequest} />
          <Route exact path="/custom-request/project/:request_id" component={PageCustomRequestComplete} />
          <Route exact path="/cart" component={PageCart} />
          <Route path="/checkout/:checkout_step?" component={PageCheckout} />
          <Route exact path="/complete/:project_id?" component={PageCheckoutComplete} />
          <Route exact path="/admin/:section?/:activity?/:component?/:cpntactivity?/:cpntcomponent?/" component={PageAdmin} />
          <Route exact path="/dashboard" component={PageDashboard} />
          <Route exact path="/dashboard/task/:task_id" component={PageDashboardTask} />
          <Route path="/*" component={PageHome} />
      </Switch>
    </Router>
  );
}

export default App;
