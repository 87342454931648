import React, {useState, useEffect, Fragment} from 'react';

import IconToken from 'components/icons/IconToken';
import ShellFieldListItem from 'components/profiles/shells/ShellFieldListItem';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [field, setField] = useState(null);
	const [group, setGroup] = useState(null);
	const [fields, setFields] = useState(null);
	const [subgroups, setSubgroups] = useState(null);
	const [activeField, setActiveField] = useState(null);
	const [activeGroup, setActiveGroup] = useState(null);
	const [expandGroup, setExpandGroup] = useState(null);
	
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.field && props.field !== field) {
			setField(props.field);
		}else if(!props.field) {
			setField(null);
		}
	}, [props.field]);
	
	useEffect(() => {
		if(props.group && props.group !== group) {
			setGroup(props.group);
		}else if(!props.group) {
			setGroup(null);
		}
	}, [props.group]);
	
	useEffect(() => {
		if(props.activeField && 
			props.activeField !== activeField) {
			setActiveField(props.activeField);
		}
	}, [props.activeField])
	
	useEffect(() => {
		if(props.activeGroup && 
			props.activeGroup !== activeGroup) {
			setActiveGroup(props.activeGroup);
		}
	}, [props.activeGroup])
	
	useEffect(() => {
		if(group && Array.isArray(group.fields) &&
			group.fields !== fields) {
			setFields(group.fields);
		}else if(!props.objExists(group, `fields`)){
			setFields(null);
		}
		
		if(group && Array.isArray(group.subgroups) &&
			group.subgroups !== subgroups) {
			setSubgroups(group.subgroups);	
		}else if(!props.objExists(group, 'subgroups')) {
			setSubgroups(null);
		}
		
	}, [group]);
	
	// FUNCTIONS
	
	function handleActivate() {
		if(props.actionSelectField && field) {
			if(field.field_id === props.objGetValue(props.activeField, 'field_id')) {
				props.actionSelectField(null);
			}else{
				props.actionSelectField(field.field_id, props.groupID ? props.groupID : null, props.parentID ? props.parentID : null);
			}
		}
		if(props.actionSelectGroup && group) {
			//console.log("GOING TO SELECT A GROUP");
			if(group.group_id === props.objGetValue(props.activeGroup, `group_id`)) {
				//console.log("SHOULD BE DESELECTING");
				props.actionSelectGroup(null);
			}else{
				//console.log("SELECTING GROUP", group.group_id);
				props.actionSelectGroup(group);
			}
		}
	}
	
	function handleAssignGroupField() {
		if(props.actionAssign) {
			props.actionAssign(group);
		}
	}
	
	function handleAddGroup() {
		if(props.actionAddGroup) {
			props.actionAddGroup(props.objExists(group, 'group_id') ? group : null);
		}
	}
	
	function handleEditGroup() {
		if(props.actionEdit) {
			props.actionEdit(group);
		}
	}
	
	function handleRemove() {
		let type;
		let name;
		let id;
		//console.log("Handle Remove", field, group);
		if(field) {
			type = 'field';
			name = field.field_label;
			id = field.field_id;
			//console.log("Remove Field", field, type, name, id);
		}else if(group) {
			type = 'field group';
			name = group.group_name;
			id = group.group_id;
			//console.log("Remove Group", group, type, name, id);
		}
		// confirmRemove('field', pField.field_name, pField.field_id)
		if(type && name && id && props.actionRemove) {
			
			props.actionRemove(type, name, id, type === 'field' && props.groupID ? props.groupID : null);
		}
	}
	
	return(
		(group || field) &&
			<div className={`block-row hover-row clickable ${
						group && props.objGetValue(props.activeGroup, 'group_id') === group.group_id ?
							' list-group-active' : ''}${
						field && props.objGetValue(props.activeField, `field_id`) === field.field_id ?
							' list-field-active' : ''}`}
						style={{flexWrap: 'wrap'}} >
				<div style={{flex: '1 0 auto'}} >
					<span className="hover-underline"
						onClick={handleActivate}>
					{props.objExists(group, `group_order`) && `${group.group_order} : `}
					{props.objExists(field, `field_order`) && `${field.field_order} : `}
					{props.capitalizeString(group ? group.group_name : field.field_label, true)}
					</span>
					{group && Array.isArray(fields) &&
						<Fragment>
							<br />
							<IconToken 
								icon={expandGroup ? 'collapse' : 'expand'}
								action={()=>{setExpandGroup(expandGroup ? false : true)}}
								style={{height: '.75em', width: '.75em'}} />
							<span onClick={()=>{setExpandGroup(expandGroup ? false : true)}}>
								{` ${expandGroup ? 'Hide' : 'Show'} ${fields.length} Field${fields.length === 1 ? '' : 's'}`}
							</span>
						</Fragment>
					}
					{props.objExists(field, `field_id`) && ` ${field.field_id}`}
					{group && Array.isArray(subgroups) &&
						<Fragment>
							<br />
							<IconToken 
								icon={expandGroup ? 'collapse' : 'expand'}
								action={()=>{setExpandGroup(expandGroup ? false : true)}}
								style={{height: '.75em', width: '.75em'}} />
							<span onClick={()=>{setExpandGroup(expandGroup ? false : true)}}>
								{` Show ${subgroups.length} Subgroup${subgroups.length === 1 ? '' : 's'}`}
							</span>
						</Fragment>
					}
					{props.objExists(subgroups, `group_id`) && ` ${subgroups.group_id}`}
				</div>
				<div className="ctl-row">
					{!field &&
					group &&
					!props.groupID &&
					!Array.isArray(group.fields) &&
						<IconToken 
						icon="field-group" 
						tooltip="Assign Group"
						action={handleAddGroup} />
					}
					{!field && 
					group && 
					!Array.isArray(group.subgroups) &&
						<IconToken 
						icon="field" 
						tooltip="Assign Field"
						action={handleAssignGroupField} />
					}
					<IconToken 
						icon="edit" 
						tooltip={`Edit ${group ? 'Group' : 'Field'}`}
						action={handleEditGroup} />
					<IconToken 
						icon="trash" 
						tooltip={`Remove ${group ? 'Group' : 'Field'}`}
						action={handleRemove} />
				</div>
				{Array.isArray(fields) && 
				<div className={`block expandable${expandGroup ? ' active' : ''}`} 
					style={{paddingLeft: '.5em',
							flex: '0 0 100%'}} >
					{fields.map((gField, index) => {
						return (
							<ShellFieldListItem {...props}
								key={`sfliGrpFld${props.curPage}${index}`}
								userID={userID}
								token={token}
								field={gField}
								group={null}
								curPage={props.curPage}
								groupID={group ? group.group_id : 
											props.groupID ? props.groupID : 
												null}
								parentID={props.parentID}
								activeField={activeField}
								actionEdit={props.actionEdit}
								actionRemove={props.actionRemove}
								actionSelectField={props.actionSelectField} />
						)
					})}
				</div>
				}
				{Array.isArray(subgroups) && 
				<div className={`block expandable${expandGroup ? ' active' : ''}`} 
					style={{paddingLeft: '.5em',
							flex: '0 0 100%'}} >
					{subgroups.map((sGroup, index) => {
						return (
							<ShellFieldListItem {...props}
								key={`sfliSubGrp${props.curPage}${index}`}
								userID={userID}
								token={token}
								field={null}
								group={sGroup}
								curPage={props.curPage}
								groupID={sGroup.group_id}
								parentID={group.group_id}
								activeGroup={activeGroup}
								actionEdit={props.actionEdit}
								actionRemove={props.actionRemove}
								actionSelectField={props.actionSelectField} />
						)
					})}
				</div>
				}
			</div>
	)
}