import React, {useEffect, useState} from 'react';
import { useParams, useLocation } from "react-router";

import CategoryProductBlock from 'components/categories/CategoryProductBlock';
import Loader from 'components/atoms/Loader';

import * as utils from 'methods/site';

const ProductList = (props) => {
	props = {...props.children, ...utils};
	
	const [languageCode, setLanguageCode] = useState('en');
	const [products, setProducts] = useState(null);
	const [search, setSearch] = useState(null);
	const [searchResults, setSearchResults] = useState(null);
	const [searchInProgress, setSearchInProgress] = useState(true);
	
	let { search_params } = useParams();
	
	useEffect(() => {
		if(props.languageCode && 
			props.langaugeCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(Array.isArray(props.objGetValue(props.store, 'products')) &&
			props.store.products.length > 0 &&
			JSON.stringify(props.store.products) !== JSON.stringify(products)) {
			setProducts(props.store.products);
		}
	}, [props.store.products]);
	
	useEffect(() => {
		if(search_params) {
			setSearch(search_params.toLowerCase().split('+'));	
		}
		// else if(!search_params && props.store.search &&
		// 	props.store.serach !== search) {
		// 	console.log('S PRODUCTS CHECKING STATE SEARCH', props.store.search);
		// 	setSearch(props.store.search.split(' '));	
		// } 
	}, [props.store.search, search_params]);
	
	useEffect(() => {
		
		if(products && 
			languageCode && 
			Array.isArray(search)) {
			let sProducts = products.filter( prd => filterProducts(prd, search));
			setSearchInProgress(false);
			if(Array.isArray(sProducts) && 
				sProducts.length > 0 &&
				JSON.stringify(sProducts) !== JSON.stringify(searchResults)) {
				setSearchResults(sProducts);	
			}
		}
	}, [products, search, languageCode]);
	
	function filterProducts(product, params, orderBy = null) {
		let result = false;
		if(product) {
			if(Array.isArray(params)) {
				params.map(param => {
					if(product[languageCode].product_name.toLowerCase().split(' ').includes(param) ||
						(props.objExists(product, `${languageCode}.product_description`) &&
						product[languageCode].product_description.toLowerCase().split(' ').includes(param)) ||
						(props.objExists(product, `${languageCode}.product_type`) &&
						product[languageCode].product_type.toLowerCase().split(' ').includes(param)) ||
						(props.objExists(product, `${languageCode}.product_keywords`) &&
						product[languageCode].product_keywords.toLowerCase().split(' ').includes(param))
						) {
						result = true;
					}
				})
			}
		}
		return result;
	}
	
	return (
		<div className="set-container">
			<div className="product-list-header">
				<span className="product-list-header-label">Searching Products : </span> 
				<span className="product-list-header-search">
					{Array.isArray(search) ? search.map(sParam => {return `${sParam} `}) : ' none'}
					{searchResults && 
						<span>({`${searchResults.length}`})</span>
					}
				</span>
			</div>
			<div className="grid blocks">
				{searchResults ?
					searchResults.map((product, index) => {
						return (
							<CategoryProductBlock {...props} 
								key={`prdBlk_${index}`} 
								product={product}
								languageCode={languageCode}
								link={`/product/${product.product_id}`} />
						)
					})
				: searchInProgress ? <Loader label="Searching" /> : <p>No results</p>
				}
			</div>
		</div>
	)
	
}

export default ProductList;