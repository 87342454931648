import React, {useEffect, useState, Fragment} from 'react';

import ModuleSet from 'components/profiles/modules/ModuleSet';

export default (props) => {
	const [languageCode, setLanguageCode] = useState(null);
	const [active, setActive] = useState(false);
	
	// HOOKS
	useEffect(() => {
		if(props.languageCode &&
		  props.languageCode !== languageCode) {
		  setLanguageCode(props.languageCode);
		}else if(!languageCode) {
		  setLanguageCode('en');	
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.module.module_active === true) {
			setActive(true);
		}
	}, [props.module]);
	
	// FUNCTIONS
	function handleClick(value) {
		setActive(value);
		if(props.actionActivate) {
			props.actionActivate(props.module, value);
		}
	}
	
	return (
		<div className="module-container">
			{props.module && languageCode &&
				<Fragment>
					<div class={`module${active ? ' active' : ''}`}
							onClick={()=>{handleClick(active ? false : true)}}>
						{props.module[languageCode].module_name}
					</div>
					{props.module.modules && Array.isArray(props.module.modules) &&
						<ModuleSet {...props}
									modules={props.module.modules}
									languageCode={languageCode} />
					}
				</Fragment>
			}
		</div>
	)
}