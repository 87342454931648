import React, {useEffect, useState} from 'react';

import UserList from 'components/profiles/users/UserList';
import Loader from 'components/atoms/Loader';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [users, setUsers] = useState(null);

  // HOOKS
  useEffect(() => {
    if(props.userID && props.userID !== userID) {
      setUserID(props.userID)
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token && props.token !== token) {
      setToken(props.token)
    }
  }, [props.token]);

  useEffect(() => {
    if(props.organizationID && organizationID !== props.organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    props.organizationName &&
    props.organizationName !== organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);
  
  useEffect(() => {
    if(props.languageCode && languageCode !== props.languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    if(props.userAccess && props.userAccess !== userAccess) {
      setUserAccess(props.userAccess);
    } 
  }, [props.userAccess]);

  useEffect(() => {
    props.objExists(props.admin, 'staff.users') &&
    props.admin.staff.user !== users &&
    setUsers(props.admin.staff.users);
  }, [props.admin.staff]);

  return (
    <div>
      {userID && organizationID && token && users &&
      Array.isArray(users) ?
        <UserList {...props} users={users}
                              location='admin'
                              title="Staff Users"
                              organizationID={organizationID}
                              organizationName={organizationName}
                              userID={userID}
                              languageCode={languageCode}
                              token={token}
                              showFiler={true}
                              showAll={props.showAll} />
      :
        <Loader label={`Loading staff`} />
      }
    </div>
  )
}
