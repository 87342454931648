import React, { useState, useEffect, Fragment } from "react";
import ReactDOM from 'react-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import IconToken from 'components/icons/IconToken';

export default (props) => {
	
	const [icon, setIcon] = useState('info');
	const [tooltip, setTooltip] = useState(null);
	const [info, setInfo] = useState(null);
	const [active, setActive] = useState(false);
	
	useEffect(() =>  {
		if (props.icon && props.icon !== icon) {
			setIcon(props.icon);
		}
	}, [props.icon]);
	
	useEffect(() =>  {
		if (props.tooltip && props.tooltip !== tooltip) {
			setTooltip(props.tooltip);
		}
	}, [props.tooltip]);

	useEffect(() =>  {
		if (props.info && props.info !== info) {
			setInfo(props.info);
		}
	}, [props.info]);
	
	return (
		icon && info &&
		<Fragment>
			<IconToken 
				icon={icon}
				className={props.className ? props.className : 'info'}
				style={props.style ? props.style : {fontSize: '0.5em', marginLeft: '0.75em'}}
				tooltip={tooltip}
				action={()=>{setActive(true)}}
			/>
			{active &&
				ReactDOM.createPortal(
					<div className="pop-up-wrapper">
						<div className="pop-up closable">
							<div className="pop-up-content">
								{ReactHtmlParser(info)}
							</div>
							<div className="pop-up-ctl">
								<IconToken icon="close" className="btn" action={()=>{setActive(false)}} />
							</div>
						</div>
					</div>,	document.body
				)
			}
		</Fragment>
	)
	
}