import React, {useEffect, useState} from 'react';

import List from 'components/molecules/List';
import Control from 'components/atoms/Control';

export default (props) => {
	
	function addEditCharge(charge, type) {
		if(props.actionAddEdit) {
		  props.actionAddEdit(charge, type);
		}
	  }
	
	function removeCharge(charge) {
		if(props.actionRemove) {
		  props.actionRemove(charge["charge_id"], 'chargeToOrganization')
		}
	  }
	
	return (
		<div>
			<h4>Additional Charges</h4>
			<div className="list-set">
				{props.charges && Array.isArray(props.charges["project"]) &&
					<List {...props}
						list={props.charges["project"].map((charge, index) => {
						return {name: charge.charge_display ? charge.charge_display : charge.charge_name + ': ' + props.priceDisplay(charge.charge_value), id: charge, index: index, type: 'project'}
						})}
						listName="Project"
						listIcon="elipse"
						actionEditItem={addEditCharge}
						actionRemoveItem={removeCharge}
						editable={true}
						listBox={true} />
				}
				<div className="list-set-ctl">
					<Control icon="add" label="Add project charge" showLabel={true} action={()=>addEditCharge(null, 'project')} />
				</div>
			</div>
			<div className="list-set">
				{props.charges && Array.isArray(props.charges["order"]) &&
					<List {...props}
						list={props.charges["order"].map((charge, index) => {
							return {name: charge.charge_display ? charge.charge_display : charge.charge_name + ': ' + props.priceDisplay(charge.charge_value), id: charge, index: index, type: 'order'}
						})}
						listName="Order"
						listIcon="elipse"
						actionEditItem={addEditCharge}
						actionRemoveItem={removeCharge}
						editable={true}
						listBox={true} />
				}
				<div className="list-set-ctl">
					<Control icon="add" label="Add order charge" showLabel={true} action={()=>addEditCharge(null, 'order')} />
				</div>
			</div>
			<div className="list-set">
				{props.charges && Array.isArray(props.charges["product"]) &&
					<List {...props}
						list={props.charges["product"].map((charge, index) => {
							return {name: charge.charge_display ? charge.charge_display : charge.charge_name + ': ' + props.priceDisplay(charge.charge_value), id: charge, index: index, type: 'product'}
						})}
						listName="Product"
						listIcon="elipse"
						actionEditItem={addEditCharge}
						actionRemoveItem={removeCharge}
						editable={true}
						listBox={true} />
				}
				{!props.objExists(props.charges, 'product') &&
					<div className="list-set-ctl">
						<Control icon="add" label="Add product charge" showLabel={true} action={()=>addEditCharge(null, 'product')} />
					</div>
				}
			</div>
		</div>
	)
	
}