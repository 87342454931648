import React, {useEffect, useState} from 'react';

import FormDynamic from 'components/forms/FormDynamic';

export default (props) => {
  const [optionsOrgs, setOptionsOrgs] = useState(null);
  const [fieldsets, setFieldsets] = useState(null);
  const [form, setForm] = useState(null);

  useEffect(() => {
    console.log("Did we get orgs?", props.organizations);
    if(props.organizations && 
        Array.isArray(props.organizations) &&
        props.organizations.length > 0) {
          let orgOpts = [];
          props.organizations.map(org => {
            orgOpts.push(
              {option_name: org.organization_name,
                option_value: org.organization_id
              }
            );
          })
          console.log("Otions Orgs", orgOpts);
          if(orgOpts !== optionsOrgs) {
            setOptionsOrgs(orgOpts);
          }
    }
  }, [props.organizations]);

  useEffect(() => {
    let fields = [
      {field_type: 'text',
      field_name: 'host_name',
      field_label: `Host`,
      field_value: props.objExists(props, 'host.host_name') ? props.host.host_name : null,
      field_placeholder: 'Host IP or URL',
      field_order: 1,
      field_required: true,
      field_validate: true,},
    ];
    
    if(optionsOrgs) {
      fields.push(
        {field_type: 'select',
        field_name: 'organization_id',
        field_lable: 'Host Organization',
        field_value: props.objExists(props, 'host.organization_id') ? props.host.organization_id : null,
        option_groups: [{options: optionsOrgs}],
        field_placeholder: "Select organization",
        field_order: 2,
        field_required: false,
        field_validate: false,
        },
        {field_type: 'hidden',
        field_name: 'prev_organization_id',
        field_value: props.objExists(props, 'host.organization_id') ? props.host.organization_id : null
        }
      )
    }
    
    fields.push(
      {field_type: 'switch',
      field_name: 'host_published',
      field_label: 'Publish',
      field_value: props.objGetValue(props, 'host.host_published') === 0 ? false : true,
      field_order: 2,
      field_required: false,
      field_validate: false},
    {field_type: 'hidden',
      field_name: 'process',
      field_value: props.objExists(props, 'host.host_id') ? 'update_site_host' : 'add_site_host'},
    props.objExists(props, 'host.host_id') &&
    {field_type: 'hidden',
      field_name: 'host_id',
      field_value: props.host.host_id},
    {field_type: 'hidden',
      field_name: 'user_id',
      field_value: props.userID,
    },
    {field_type: 'hidden',
      field_name: 'token',
      field_value: props.token,
    },
    )
    
    setFieldsets([
      {fieldset_name: `${props.objExists(props, 'host.host_id') ? 'Change' : 'Add'} Host`,
        fields: fields
      }
    ]);
  }, [props.host, optionsOrgs]);

  useEffect(() => {
    fieldsets &&
    setForm(
      <FormDynamic {...props}
          content={fieldsets}
          name="add-edit-category"
          returnObject={true}
          fieldOnChange={null}
          fieldOnLoad={null}
          formActions={{formCancel: handleCancel, formSubmit: handleSubmit}} />
    )
  }, [fieldsets, optionsOrgs]);

  function handleCancel() {
    props.setModal(null);
    props.actionCancel &&
    props.actionCancel();
  }

  function handleSubmit(params) {
    if(props.action) {
      props.action(params);
    }
  }

  return (fieldsets && form)
}
