import React, {useEffect, useState, Fragment} from 'react';
import {useParams, useLocation, useHistory} from 'react-router-dom';

import Loader from 'components/atoms/Loader';
import ProductList from 'components/profiles/products/ProductList';
import OrganizationSelector from 'components/profiles/orgs/OrganizationSelector';
import IconSVG from 'components/icons/IconSVG';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [organizations, setOrganizations] = useState(null);
  const [coreOrg, setCoreOrg] = useState(null);
  const [products, setProducts] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [filterOrg, setFilterOrg] = useState(null);
  const [filterLanguage, setFilterLanguage] = useState(null);
  const [filterCat, setFilterCat] = useState(null);
  const [testValue, setTestValue] = useState(false);
  const [searchParams, setSearchParams] = useState(null);
  
  const pgLocation = useLocation();
  const pgHistory = useHistory();
  
  // HOOKS
  useEffect(() => {
    if(sessionStorage.getItem(`admProductSearch`)) {
      const searchString = props.paramsToString(JSON.parse(sessionStorage.getItem(`admProductSearch`)));
      
      if(!pgLocation.search) {
        pgHistory.push(`${pgLocation.pathname}${searchString}`);
      }
    }
  }, []);
  
  useEffect(() => {
    if(pgLocation.search && 
        props.getURLSearchParams(pgLocation.search) !== searchParams) {
      setSearchParams(props.getURLSearchParams(pgLocation.search));
    }
  }, [pgLocation]);
  
  useEffect(() => {
    if(searchParams && 
      JSON.stringify(searchParams) !== sessionStorage.getItem('admProductSearch')) {
      sessionStorage.setItem('admProductSearch', JSON.stringify(searchParams));  
    }else if((!searchParams || Object.entries(searchParams).length <= 0) && 
              sessionStorage.getItem(`admProductSearch`)){
      sessionStorage.removeItem(`admProductSearch`);  
    }
  }, [searchParams, pgHistory, pgLocation]);
  
  useEffect(() => {
    if(props.userID && props.userID !== userID) {
      setUserID(props.userID)
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token && props.token !== token) {
      setToken(props.token)
    }
  }, [props.token]);

  useEffect(() => {
    if(props.organizationID && organizationID !== props.organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    props.organizationName &&
    props.organizationName !== organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);
  
  useEffect(() => {
    if(props.languageCode && languageCode !== props.languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    if(props.coreOrg && props.coreOrg !== coreOrg) {
      setCoreOrg(props.coreOrg);
    }else if(!props.coreOrg && coreOrg) {
      setCoreOrg(null);
    }
  }, [props.coreOrg])
  
  useEffect(()=> {
    if(props.organizations && 
      props.organizations !== organizations) {
        setOrganizations(props.organizations);
      }
  }, [props.organizations])
  
  useEffect(() => {
    if(coreOrg && Array.isArray(organizations)) {
      const curOrg = organizations.find(org => org.organization_id === coreOrg);
      if(curOrg && curOrg.organization_name !== organizationName) {
        setOrganizationName(curOrg.organization_name);
      }
    }
  }, [coreOrg, organizations]);
  
  useEffect(() => {
    if(props.userAccess && props.userAccess !== userAccess) {
      setUserAccess(props.userAccess);
    } 
  }, [props.userAccess]);
  
  useEffect(() => {
    if(Array.isArray(props.objGetValue(props.admin, `products`)) &&
      props.admin.products !== products) {
        setProducts(props.admin.products);
      }
  }, [props.admin.products, languageCode]);
  
  // FUNCTIONS 
  function updateSearch(params) {
    if(params && params !== searchParams) {
      if(searchParams && Object.entries(searchParams).length > 0) {
        setSearchParams({...searchParams, ...params});
      }else{
        setSearchParams(params);
      }    
    }
  }
  
  return (
    <div className="full-row">
      <h3>{`${organizationName &&  !['AE Core', 'Accell Graphics'].includes(organizationName) ? `${organizationName} ` : 'AE Core ' }Products`}</h3>
      {organizations &&
        <div className="form-row">
          <h4>Filter by organization</h4>
          <OrganizationSelector {...props}
            organizations={organizations}
            action={props.actionSelectOrg}
            userAccess={userAccess}
            orgID={coreOrg}
            skipCore={true}  />
        </div>
      }
      {languageCode && products ?
        <Fragment>
          <div className="form-row">
            <a className="link-btn" href={`https://api.aecore.app/files/xlsx.php?query=${btoa(`?type=products&user_id=${userID}&token=${token}&organization_id=${coreOrg}&project_published=false`)}`}>
              <IconSVG icon="download" />
              Download Products Report
            </a>
          </div>
          <ProductList {...props}
                        userID={userID}
                        token={token}
                        organizationID={organizationID}
                        languageCode={languageCode}
                        userAccess={userAccess}
                        coreOrg={coreOrg}
                        products={products}
                        location='admin'
                        isAdmin={true}
                        searchParams={searchParams}
                        actionUpdateSearch={updateSearch}
                         /> 
        </Fragment>
      :
        coreOrg ?
          <Loader />
        : <div className="form-row">
            To view products, select an organization
          </div>
      }
    </div>
  )
}
