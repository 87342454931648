import React from 'react';

import IconSVG from 'components/icons/IconSVG';

export default (props) => {
  return (
    <div className='form-alert'>
      <IconSVG icon={props.alertMsg.icon ? props.alertMsg.icon : 'alert'} className="alert-icon"/>
      <div className="form-alert-msg">
      {props.alertMsg.title && <h3>{props.alertMsg.title}</h3>}
      {Object.entries(props.alertMsg).length > 0 &&
          props.alertMsg.message ? props.alertMsg.message :
          props.alertMsg}
      </div>
    </div>
  )
}
