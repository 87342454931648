import React, {useEffect, useState, Fragment} from 'react';
import {Redirect} from 'react-router-dom';

import FormDynamic from 'components/forms/FormDynamic';
import FileUpload from 'components/forms/elements/FileUpload';
import Button from 'components/forms/elements/Button';
import MediaImage from 'components/profiles/media/MediaImage';
import Loader from 'components/atoms/Loader';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [product, setProduct] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [fieldsets, setFieldsets] = useState(null);
	const [form, setForm] = useState(null);
	const [shellFile, setShellFile] = useState(null);
	const [previewImages, setPreviewImages] = useState(null);
	const [createActive, setCreateActive] = useState(null);
	const [createInProgress, setCreateInProgress] = useState(null);
	const [redirect, setRedirect] = useState(null);
	
	useEffect(() => {
		if(props.userID && 
			props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && 
			props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.product && 
			props.product !== product) {
			setProduct(props.product);
		}
	}, [props.product]);
	
	useEffect(() => {
		if(props.languageCode && 
			props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}else if(!props.languageCode &&
			props.objExists(product, `details`) &&
			Object.entries(product.details).length === 1 &&
			Object.keys(product.details)[0] !== languageCode) {
			setLanguageCode(product.details[0]);
		}
	}, [props.languageCode, product]);
	
	useEffect(() => {
		if(Array.isArray(props.objGetValue(props.files, `uploads.shell_file`)) &&
			props.files.uploads.shell_file.length > 0) {
				
			const shellTpl = props.files.uploads.shell_file.find(sFile => sFile.media_type === 'shellTpl');
			const pvImgs = props.files.uploads.shell_file.filter(pFile => pFile.media_type !== 'shellTpl');
			if(shellTpl && shellTpl !== shellFile) {
				setShellFile(shellTpl);
			}
			
			if(pvImgs && pvImgs !== previewImages) {
				setPreviewImages(pvImgs);
			}
		}
	}, [props.files]);
	
	useEffect(() => {
		console.log("SHELL FILE!", shellFile);
		if(createActive && !createInProgress &&
			props.objExists(shellFile, `media_id`)) {
			const paramsShell = [
				{name: 'process', value: 'create_product_shell'},
				{name: 'user_id', value: userID},
				{name: 'token', value: token},
				{name: 'product_id', value: product.product_id},
				{name: 'language_code', value: languageCode},
				{name: 'media_id', value: shellFile.media_id},
				{name: 'media_file_name', value: shellFile.media_file_name},
				{name: 'shell_units', value: 'in'},
				{name: 'return_bool', value: 1}
			];
			console.log("Params Shell Add", paramsShell);
			props.adminLoadState(paramsShell, 'products', 'reload.product');
			setCreateInProgress(true);
			props.filesDeleteVal('uploads.shell_file');
			props.filesDeleteVal('uploadComplete');
		}
	}, [userID, token, product, languageCode, shellFile, createActive]);
	
	useEffect(() => {
		if(createActive && product &&
			props.objExists(product, `details.${languageCode}.shell.shell_id`)) {
			setCreateActive(false);
			setCreateInProgress(false);
			props.setModal(null);
			setRedirect(`/admin/products/shell/${product.product_id}`);
		}
	}, [createActive, product, languageCode]);
	
	// FUNCTIONS
	function handleUpload(params) {
		console.log("FILE HAS BEEN UPLOADED", params);
	}
	
	function cancelShell() {
		props.setModal(null);
	}
	
	console.log("CREATE SHELL FILES", props.files);
	console.log("Product Shell", props.admin);
	return (
		<div>
			{redirect && <Redirect to={redirect} />}
			{product && languageCode && !shellFile &&
				<Fragment>
					<h3>{props.product.details[props.languageCode].product_name}</h3>
					<FileUpload {...props}
						userID={userID}
						token={token}
						name="shell_file"
						label="Product Shell"
						retainModal={true}
						returnInfo={true}
						media_type={'shellTpl'}
						media_owner_id={product.product_id}
						media_owner_type ={'product'}
						media_published={true}
						create_images={true}
						organization_id={product.organization_id}
						language_code={languageCode}
						onChange={handleUpload} 
						/>	
				</Fragment>
			}
			
			{shellFile && !createActive &&
				<Fragment>
					<h3>Product Shell</h3>
					{Array.isArray(previewImages) && 
					<Fragment>
					<h4>{`${previewImages.length} Page${previewImages.length === 1 ? '' : 's'}`}</h4> 		
					<div style={{display: 'flex',
								flexWrap: 'wrap',
								justifyContent: 'center',
								alignItems: 'flex-start',
								padding: '1em',
								marginBottom: '1em'
				   				 }}>
						{previewImages.map((img, index) => {
							return (
								<MediaImage {...props}
									key={`shlPvImg${index}`}
									image={img}
									style={{
										borderRadius: '0em',
										border: '.0325em solid #333333',
										flex: '1 1 auto',
										maxWidth: '6em',
										maxHeight: '6em',
									}} />
							);
						})}
					</div>
					</Fragment>
					}
					<div className="form-row">
						<Button icon="check" 
								btnClass="success-bg"
								label="Create Shell"
								btnAction={()=>{setCreateActive(true)}} />
					</div>
				</Fragment>
			}
			{createActive &&
				<div>
					<Loader label="Creating Shell..." overlayContents={true} />
				</div>	
			}
			<div className="form-row">
				<Button icon="close"
					label="Cancel"
					btnAction={cancelShell}
					disabled={createActive} />
			</div>
		
		</div>
	)
}
