import React, {useEffect, useState} from 'react';

import AdminNavOption from 'components/admin/AdminNavOption';

export default (props) => {
  const [languageCode, setLanguageCode] = useState(null);
  const [currentOptions, setCurrentOptions] = useState(null);
  const [prevNodeLink, setPrevNodeLink] = useState(null);
  const [nodePath, setNodePath] = useState(null);
  const [navModules, setNavModules] = useState(null);
  const [curModules, setCurModules] = useState(null);
  const [parent, setParent] = useState(null);
  const [moduleNodes, setModuleNodes] = useState(null);
  const [modulePath, setModulePath] = useState(null);
  const [parentPath, setParentPath] = useState(null);
  
  useEffect(() => {
    if(props.languageCode && languageCode !== languageCode) {
      setLanguageCode(props.languageCode);
    }else if(!languageCode){
      setLanguageCode('en');
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    if(props.modules) {
      setNavModules(props.modules);
    }
  }, [props.modules]);
  
  useEffect(() => {
    if(props.pgParams) {
      let modNodes = [];
      // let parentNodes = [];
      if(props.pgParams.section) {
          modNodes.push(props.pgParams.section);
          if(props.pgParams.activity) {
            modNodes.push(props.pgParams.activity);
            if(props.pgParams.component){
              modNodes.push(props.pgParams.component);
              if(props.pgParams.cpntactivity) {
                modNodes.push(props.pgParams.cpntactivity);
                if(props.pgParams.cpntcomponent){
                  modNodes.push(props.pgParams.cpntcomponent);
                }
              }
            }
          }
      }
      if(modNodes.length > 0){
        setModuleNodes(modNodes);
      }
    }
  }, [props.pgParams, curModules]);
  
  useEffect(() => {
    if(languageCode && navModules && moduleNodes) {
      let modPath = '';
      let modParent = null;
      let modSet = navModules;
      Array.isArray(moduleNodes) &&
      moduleNodes.map(node => {
        if(modSet.modules && Array.isArray(modSet.modules)) {
          modParent = modSet;
          const thisNode = modSet.modules.find(mod => mod[languageCode].module_link === node);
          if(thisNode) {
            modPath += `/${node}`;
            modSet = thisNode;
          }  
        }
        return;
      });
      
      // setModulePath(modPath);
      if(modSet.modules && Array.isArray(modSet.modules)) {
        setParent(modParent);
        setCurModules(modSet);
      }else if (modParent.modules && Array.isArray(modParent.modules)){
        setCurModules(modParent);
      }else{
        setCurModules(navModules);
      }
    }else if(navModules){
      // setModulePath('');
      setCurModules(navModules);
    }
    
  //   if(moduleNodes){
  //     let pPath = '';
  //     for(var i = 0; i < moduleNodes.length-1; i++) {
  //       pPath += `/${moduleNodes[i]}`;
  //     }
  //     setParentPath(pPath);
  //   }
  }, [navModules, moduleNodes, languageCode]);
  
  useEffect(() => {
    if(curModules && languageCode && navModules) {
      let pMod = curModules;
      let pPath = '';
      let mPath = `/${curModules[languageCode].module_link}`;
      while(pMod && pMod.module_parent > 0) {
        pMod = findModule(navModules, pMod.module_parent);
        if(pMod) {
          pPath = `/${pMod[languageCode].module_link}${pPath}`;
          mPath = `/${pMod[languageCode].module_link}${mPath}`;
        }
      }
      setParentPath(pPath);
      setModulePath(mPath);
    }
  }, [curModules, languageCode, navModules]);

  function selectParent(module) {
    
    if(module.module_parent > 0) {
      const parMod = findModule(navModules, module.module_parent);
      if(parMod) {
          setCurModules(parMod);
      }
    }
  }
  
  function findModule(modules, module_id) {
    if(modules.module_id && modules.module_id === module_id) {
      return modules;
    }else if(modules.modules && Array.isArray(modules.modules)) {
      let modReturn = null;
      modules.modules.map(mods => {
        if(!modReturn) {
          if(mods.module_id === module_id) {
            modReturn = mods;
          }else{
            modReturn = findModule(mods, module_id);  
          }
        }
        return;
      });
      return modReturn; 
    }else{
      return null;
    }
  }
  
  
  return (
    <div className="admin-nav">
      {languageCode && curModules &&
        <AdminNavOption
          {...props}
          languageCode={languageCode}
          module={curModules}
          modulePath={parentPath}
          parent={true}
          actionSelect={selectParent} />
      }
      
      {languageCode && curModules && curModules.modules &&
        Array.isArray(curModules.modules) &&
        curModules.modules.map((module, index) => {
          return <AdminNavOption
                    {...props}
                    key={`ano${index}`}
                    languageCode={languageCode}
                    module={module}
                    modulePath={modulePath}
                    children={null}
                    actionSelect={setCurModules} />
        })
      }
    </div>
  )
}
