import React, {useEffect, useState} from 'react';

import IconSVG from 'components/icons/IconSVG';

export default (props) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    if(props.checked) {
      setActive(true);
    }else{
      setActive(false);
    }
  }, [props.checked])

  function handleClick() {
    if(active) {
      setActive(false);
      if(props.onClick) {
        props.onClick(props.option_value, false);
      }
    }else{
      setActive(true);
      if(props.onClick) {
        props.onClick(props.option_value, true);
      }
    }
  }
  return (
    <div className={`checkbox${active ? ` active` : ''}`}>
      <div className="checkbox-selector" onClick={props.disabled === true ? null : handleClick} >
          <IconSVG icon="check" className="successBG" />
      </div>
      <div className="checkbox-label">
        {props.option_name}
      </div>
      <input type="hidden" name={props.option_value} value={active} />
    </div>
  )
}
