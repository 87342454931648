import React, {useEffect, useState} from 'react';

import IconToken from 'components/icons/IconToken';
import Button from 'components/forms/elements/Button';

export default (props) => {
  const [missingInfo, setMissingInfo] = useState(null);
  let infoSet = [];

  function auditInfo(infoSet) {
    let missingField = false;
    infoSet && Array.isArray(infoSet) && infoSet.length > 0 &&
    infoSet.map(info => {
      if(Array.isArray(info[1]) && info[1].length > 0) {
        info[1].map(infoField => {
          if(info[3] === true && (!props.info[infoField] ||
              props.info[infoField] === undefined ||
              props.info[infoField] === '')) {
            missingField = true;
          }
        })
      }else{
        if(info[3] && (!props.info[info[1]] || props.info[info[1]] === undefined || props.info[info[1]] === '')) {
          missingField = true;
        }
      }
    })
    missingField !== missingInfo &&
    setMissingInfo(missingField);
  }

  // INFO SET ARRAY [fieldLabel, fieldValue[s], fieldValuesSeperator, required]
  switch(props.type) {
    case 'location':
      infoSet = [[null, 'location_address_1', null, true],
                  [null, 'location_address_2', null, false],
                  [null, ['location_city', 'location_province'], ', ', true],
                  [null, 'location_postal_code', null, true],
                  [null, 'location_country', null, true],
                ];
      break;
    case 'contact':
    let source = `${props.source ? props.source : 'contact'}`;

    infoSet = [[null, [`${source}_name_first`, `${source}_name_last`], ' ', true],
                ['Phone', `${source}_phone`, true],
                ['Email', `${source}_email`, true],
              ];
      break;
    default:
      break;
  }


  useEffect(() => {
    if(props.info && infoSet) {
      auditInfo(infoSet);
    }else{
      setMissingInfo(true);
    }
  }, [props.info], infoSet);

  // console.log("CARD INFO", props.info, infoSet);
  return (
    <div className="card-info">
      {missingInfo === true &&
        <div className="card-info-warning">
          <IconToken icon="warning" />
          More Infomation Required
        </div>
      }
      {missingInfo === true &&
        <Button icon="add" label="Add Info" btnClass="success-bg" btnAction={props.actionEdit} />
      }
      {props.info && infoSet && infoSet.map((info, index) => {
        return (
          <div className="card-info-row" key={`cinfo${index}`} >
          {info[0] && <span className="card-info-label" >{info[0]}{props.fieldsRequired.includes(info[1]) ? '*' : ''}</span>}
          {info[1] && Array.isArray(info[1]) ?
              info[1].map((infoField, index) => {
                return props.info[infoField] !== undefined ?
                        props.info[infoField]+((index+1) < info[1].length ? (info[2] !== undefined ? info[2] : ' ') : '')
                        :
                        '';

              })
            :
              props.info[info[1]] !== undefined ? props.info[info[1]] : ''
          }
        </div>
        )
      })}
    </div>
  )
}
