import React, {useEffect, useState} from 'react';

import IconToken from 'components/icons/IconToken';
import IconToggle from 'components/icons/IconToggle';
import IconSVG from 'components/icons/IconSVG';
import Link from 'components/navigation/Link';
import CategoryList from 'components/profiles/categories/CategoryList';

export default (props) => {
	const [active, setActive] = useState(false);
	const [category, setCategory] = useState(false);
	const [categories, setCategories] = useState(null);
	const [products, setProducts] = useState(null);
	
	useEffect(() => {
		if(props.category && props.category !== category) {
			setCategory(props.category);
		}
	}, [props.category]);
	
	useEffect(() => {
		if(Array.isArray(props.objGetValue(category, `sub_categories`)) &&
			category.sub_categories.length > 0 &&
			category.sub_categories !== categories) {
			setCategories(category.sub_categories);
		}
	}, [category]);
	
	useEffect(() => {
		if(category) {
			let prods = [];
			
			if(Array.isArray(category.products) &&
				category.products.length > 0) {
				prods.push(...category.products);	
			}
			
			if(Array.isArray(category.sub_categories) && 
				category.sub_categories.length > 0) {
				category.sub_categories.map(cat => {
					prods.push(...addSubProducts(cat));	
				})
			}
			
			if(prods.length > 0 && prods.length !== products) {
				setProducts(prods);
			}	
		}
	}, [category]);
	
	// FUNCTIONS
	function addSubProducts(sCat) {
		let sProds = [];
		if(Array.isArray(sCat.products) &&
			sCat.products.length > 0) {
			sProds.push(...sCat.products);	
		}
		
		if(sCat.sub_categories) {
			sCat.sub_categories.map(cat => {
				if(Array.isArray(cat.products) &&
					cat.products.length > 0) {
					sProds.push(...cat.products);	
				}
				if(Array.isArray(cat.sub_categories) &&
					cat.sub_categories.length > 0) {
						sProds.push(addSubProducts(cat));
					}
			})
		}
		return sProds;
	}
	
	function handleEdit() {
		if(category && props.actionAddEdit) {
			props.actionAddEdit(category, category.category_parent);
		}
	}
	
	function handleAdd() {
		if(category && props.actionAddEdit) {
			props.actionAddEdit(null, category.category_id);
		}
	}
	
	function handleRemove() {
		if(category && props.actionRemove) {
			props.actionRemove(category);
		}
	}
	
	return (
		category &&
		<li className={`li${active ? ' active' : ''}${category.category_private === 1 ? ' info' : ''}`}>
			<div className={`list-item`}  style={{padding: '.5em 0em'}}>
				{props.isSub &&
					<IconSVG icon="arrow" className="li-icon rotate90" />
				}
				<div className={`list-item-content${category.category_published ? '' : ' unpublished'}`}>
					<Link to={`${props.location ? props.location : ''}/categories/category/${category.category_id}`} >
						<div className="list-item-content-block">
							<b>{`${!props.coreOrg && props.objExists(category, `organization_name`) ? `${props.capitalizeString(category.organization_name, true)} : ` : ''}${props.capitalizeString(category.category_name, true)}`}</b>
							{!category.category_published &&
								<span style={{marginLeft: '.5em'}}>
									<IconSVG icon="hide" />
								</span>
							}
							{category.category_private === 1 &&
								<span style={{marginLeft: '.5em'}}>
									<IconSVG icon="lock" />
								</span>
							}
						</div>
					</Link>
					<div className="list-item-content-block">
						{products && 
						products.length === 1 ? 
							'1 Product' : `${products ? products.length : 'No'} Products`
						}
					</div>
					{category.sub_categories &&
						Array.isArray(category.sub_categories) &&
						category.sub_categories.length > 0 &&
						<div className="list-item-content-block">
							{category.sub_categories.length === 1 ? '1 Subgroup' : `${category.sub_categories.length} Subgroups`}
							<IconToggle tooltip="show subgroups"
										showTooltip={true}
										action={()=>{setActive(active ? false : true)}} />
						</div>
					}
				</div>
				{props.isAdmin &&
					<div className="li-ctl">
						<IconToken icon="edit" 
								className="btn"
								tooltip="Edit" 
								showTooltip={true}
								action={handleEdit} />
						<IconToken icon="add" 
								className="btn"
								tooltip="Add Subcategory" 
								showTooltip={true}
								action={handleAdd} />
						<IconToken icon="trash"
								className="btn"
								tooltip="Remove" 
								showTooltip={true}
								action={handleRemove} />
					</div>
				}
			</div>
			{categories &&
				Array.isArray(categories) &&
				categories.length > 0 &&
				<div className="list-item-expanded">
					<h4>{`${props.capitalizeString(category.category_name, true)} Subcategories`}</h4>
					<CategoryList {...props}
								languageCode={props.languageCode}
								categoryType={props.categoryType}
								categories={categories}
								isSub={true}
								isAdmin={true} />
				</div>
			}
		</li>
	)
}