// import axios from 'axios';


export const setModal = (modal, unmountOnClick = false, closable = false, onClose = null) => dispatch => {
  dispatch({type: 'SET_MODAL', unmountOnClick: unmountOnClick, closable: closable, payload: modal, onClose: onClose});
}

export const removeModal = (e) => dispatch => {
  e.preventDefault();
  dispatch({type: 'SET_MODAL', payload: null});
}
