import React, {useEffect, useState, Fragment} from 'react';

import RadioBtn from 'components/forms/elements/RadioBtn';

export default (props) => {
  const [value, setValue] = useState(null);
  
  function handleInput(e) {
    if(props.onChange) {
      props.onChange({name: props.name, value: e});
    }
    
    setValue(e);
    
  }

  return (
    <div className="radio-btn-set">
      <div className="radio-btn-set-header">{props.label}</div>
      {props.option_groups &&
        Array.isArray(props.option_groups) &&
        props.option_groups.map((option_group, index) => {
          return <Fragment key={`rbsog${index}`}>
                    {option_group.option_group_name &&
                    <label>{option_group.option_group_name}</label>}
                    <div className="radio-btn-set-group">
                      {option_group.options &&
                      Array.isArray(option_group.options) &&
                      option_group.options.map((option, index) => {
                        return  <RadioBtn key={`rdioBtn${props.name.split('.')[props.name.split('.').length=1]}${index}`}
                                          group={props.name}
                                          label={option.option_name}
                                          value={option.option_value}
                                          selected={props.value === option.option_value ? true : false}
                                          selectAction={handleInput} />
                      })}
                    <input type="hidden" 
                            required={props.required ? true : false} 
                            name={props.name} 
                            value={value ? value : ''} />
                    </div>
                  </Fragment>
        })
      }
      </div>
  )
}
