import React, {useEffect, useState, useRef} from 'react';

import DatePicker from 'components/forms/elements/DatePicker';
import IconToken from 'components/icons/IconToken';

export default (props) => {
  const [value, setValue] = useState(null);
  const [placeholder, setPlaceholder] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [datePicker, setDatePicker] = useState(null);
  const [cancel, setCancel] = useState(false);

  const inputRef = useRef();
  const dpRef = useRef();
  const containerRef = useRef();

  // HOOKS
  useEffect(() => {
    if(cancel && isActive) {
      setIsActive(false);
      setCancel(false);
      setValue('');
    }
  }, [cancel])


  useEffect(() => {
    if(props.value && props.isDate(props.value) &&
        (!props.minDate || !props.isDate(props.minDate) ||
          (props.minDate && props.isDate(props.minDate) &&
            props.setDate(props.minDate).getTime() <= props.setDate(props.value).getTime())) &&
          (!props.maxDate || !props.isDate(props.maxDate) ||
            (props.maxDate && props.isDate(props.maxDate) &&
              props.setDate(props.maxDate).getTime() >= props.setDate(props.value).getTime()))) {
      props.value !== value &&
      setValue(props.value);
    }else if(props.defaultValue && props.isDate(props.defaultValue) &&
        (!props.minDate || !props.isDate(props.minDate) ||
          (props.minDate && props.isDate(props.minDate) &&
            props.setDate(props.minDate).getTime() <= props.setDate(props.defaultValue).getTime())) &&
          (!props.maxDate || !props.isDate(props.maxDate) ||
            (props.maxDate && props.isDate(props.maxDate) &&
              props.setDate(props.maxDate).getTime() >= props.setDate(props.defaultValue).getTime()))) {
      props.defaultValue !== value &&
      setValue(props.defaultValue);
    }
  }, [props.value, props.defaultValue]);

  useEffect(() => {
    if(props.placeholder) {
      setPlaceholder(props.placeholder);
    }else if(props.label) {
      setPlaceholder(props.label)
    }else{
      setPlaceholder('YYYY-MM-DD');
    }
  }, [props.placeholder, props.name]);

  useEffect(() => {
    setDatePicker(
      <div id="date-picker" ref={dpRef}>
        <DatePicker {...props}
                    value={value ? value : null}
                    minDate={props.minDate ? props.minDate : null}
                    maxDate={props.maxDate ? props.maxDate : null}
                    allowPast={props.allowPast ? true : false}
                    dateFormat={props.dateFormat ? props.dateFormat : null}
                    language_code={props.language_code ? props.language_code : 'en'}
                    actionSelect={handleDateSelect}
                    actionCancel={handleCancel} />
      </div>
    )
  }, [value, props.minDate, props.maxDate, props.allowPast, props.dateFormat, props.language_code, isActive]);

  useEffect(() => {
    setIsActive(false);

    if(props.onChange) {
      props.onChange({name: props.id ? props.id : props.name ? props.name : null, value: value});
    }
  }, [value]);

  useEffect(() => {
    if(isActive) {
      function blurDatepicker(e) {

        let target = e.target;
        while(target !== containerRef.current && target.tagName.toLowerCase() !== 'body') {
          target = target.parentNode;
        }
        if(target !== containerRef.current) {
          setIsActive(false);
        }
      }


      window.addEventListener('click', blurDatepicker);

      return(() => {
        window.removeEventListener('click', blurDatepicker);
      })
    }
  }, [isActive]);


  // FUNCTIONS
  function focusInput() {
    setIsActive(true);
  }

  function handleDateSelect(value) {
    setValue(value);
  }

  function handleCancel() {
    setCancel(true);
  }

  return (
    <div className="form-row">
      <div className={`input${props.isMissing || props.errormsg ? ' form-input-missing' : ''}
                        ${props.className ? ` ${props.className}` : ''}`}
          onClick={!isActive ? focusInput : null} ref={containerRef}>
      {props.label &&
        <label htmlFor={props.id? props.id : null} className="input-label" onClick={!isActive ? focusInput : null} >
          {value || value === 0 || props.errormsg ? props.label : ` `}
          {(value || value === 0 || props.errormsg) && props.required ? <span className="flag-required">&#10035;</span> : ''}
          {props.errormsg && <div className="form-input-error-msg">! {props.errormsg}</div>}
        </label>
      }
      <input
        name={props.name? props.name : null}
        id={props.id? props.id : null}
        className="input-field"
        type='text'
        defaultValue={value && props.isDate && props.dateDisplay(value, props.language_code ? props.language_code : 'en', props.dateFormat ? props.dateFormat : 'numeric')}
        placeholder={placeholder}
        required={props.required === true ? true : false}
        onFocus={!isActive ? focusInput : null}
        tabIndex={props.tabIndex}
        ref={props.inputRef ? props.inputRef : inputRef}
        autoFocus={props.focus ? true : false}
        disabled={props.disabled === true ? true : false}
        />
        {props.valid && <IconToken icon="check" className="success field-indicator" />}
        <IconToken icon="date" className="input-field-icon" action={!isActive ? focusInput : null} />
        {isActive && datePicker}
      </div>
    </div>
  )
}
