import React, {useEffect, useState} from 'react';

import AdminOrgMain from 'components/admin/orgs/AdminOrgMain';
import AdminOrgList from 'components/admin/orgs/AdminOrgList';
import AdminOrganization from 'components/admin/orgs/AdminOrganization';
import AdminOrgAdd from 'components/admin/orgs/AdminOrgAdd';
import AdminOrgGroups from 'components/admin/orgs/AdminOrgGroups';

export default (props) => {
  const [orgComponent, setOrgComponent] = useState(null);
  const [userID, setUserID] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [coreOrg, setCoreOrg] = useState(null);
  const [token, setToken] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [canEdit, setCanEdit] = useState(null);
  const [isSuper, setIsSuper] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  
  // HOOKS
  useEffect(() => {
    if(props.userID && userID !== props.userID) {
      setUserID(props.userID);
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token && token !== props.token) {
      setToken(props.token);
    }
  }, [props.token]);
  
  useEffect(() => {
    props.canEdit &&
    setCanEdit(props.canEdit);
  }, [props.canEdit]);
  
  useEffect(() => {
    props.isSuper &&
    setIsSuper(props.isSuper);
  }, [props.isSuper]);

  useEffect(() => {
    if(props.userAccess && props.userAccess !== userAccess) {
      setUserAccess(props.userAccess);
    } 
  }, [props.userAccess]);

  useEffect(() => {
    if(props.organizationID && organizationID !== props.organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    if(props.coreOrg && props.coreOrg !== coreOrg) {
      setCoreOrg(props.coreOrg);
    }
  }, [props.coreOrg]);
  
  useEffect(() => {
    if(props.languageCode && languageCode !== props.languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode]);

  useEffect(() => {
     if(userID && token && organizationID === 1) {
       const orgParams = [
         {name: 'process', value: 'get_organizations'},
       ]
       props.adminLoadState(orgParams, 'organizations', 'organizations');
     }
     
     return() => {
       props.adminDeleteVal('organizations');
     }
  }, [userID, token, organizationID]);

  useEffect(() => {
    if(userID && token && organizationID && organizationName && languageCode &&
      props.objExists(props.admin, 'params.activity') || props.pgParams.activity) {
      const activity = props.pgParams.activity ? props.pgParams.activity : props.admin.params.activity
      switch(activity) {
        case 'organization-add':
          setOrgComponent(<AdminOrgAdd {...props}
                                        userID={userID}
                                        organizationID={organizationID}
                                        token={token}
                                        organizationName={organizationName}
                                        languageCode={languageCode}
                                        coreOrg={coreOrg}
                                        canEdit={canEdit}
                                        isSuper={isSuper}
                                        pgParams={props.pgParams} />);
          return;
        case 'organization':
          setOrgComponent(<AdminOrganization {...props}
                                        userID={userID}
                                        organizationID={organizationID}
                                        token={token}
                                        organizationName={organizationName}
                                        languageCode={languageCode}
                                        coreOrg={coreOrg}
                                        canEdit={canEdit}
                                        isSuper={isSuper}
                                        pgParams={props.pgParams}
                                        actionSelectOrg={props.actionUpdateCoreOrg} />);
          return;
        case 'groups':
          setOrgComponent(<AdminOrgGroups {...props}
                                        userID={userID}
                                        organizationID={organizationID}
                                        token={token}
                                        organizationName={organizationName}
                                        languageCode={languageCode}
                                        coreOrg={coreOrg}
                                        canEdit={canEdit}
                                        isSuper={isSuper}
                                        pgParams={props.pgParams}
                                        actionSelectOrg={props.actionUpdateCoreOrg} />);
          return;
        default:
          setOrgComponent(<AdminOrgList {...props}
                                        userID={userID}
                                        organizationID={organizationID}
                                        token={token}
                                        organizationName={organizationName}
                                        languageCode={languageCode}
                                        coreOrg={coreOrg}
                                        canEdit={canEdit}
                                        isSuper={isSuper}
                                        pgParams={props.pgParams} />);
          return;
      }
    }else{
      setOrgComponent(<AdminOrgMain {...props}
                                    userID={userID}
                                    organizationID={organizationID}
                                    token={token}
                                    organizationName={organizationName}
                                    languageCode={languageCode}
                                    coreOrg={coreOrg}
                                    canEdit={canEdit}
                                    isSuper={isSuper}
                                    pgParams={props.pgParams} />);
    }
  }, [props.pgParams, props.admin, props.files, userID, token, coreOrg, organizationID, languageCode, canEdit, isSuper, userAccess]);

  return (
    <div>
      <h3>Organizations</h3>
      {orgComponent}
    </div>
  )
}
