import React, {useEffect, useState} from 'react';

import IconSVG from 'components/icons/IconSVG';

export default (props) => {
	const [active, setActive] = useState(false);
	const [updateMessage, setUpdateMessage] = useState(false);

	useEffect(() => {
		setTimeout(()=> {
			setActive(true);
			setTimeout(() => {
				setUpdateMessage(true);
			}, 15000);
		}, 1000);
	}, []);
	
	return (
		<div className="loader-file-upload" >
			<label>
				<IconSVG icon={updateMessage ? 'gear' : `file`} />
				{`${updateMessage ? 'Preparing' : 'Uploading'} File`}</label>
			<div className="loader-meter">
				<div className={`loader-meter-bar`}
					style={active ? {width: '100%'} : null}></div>
			</div>
		
		</div>
	)	
}