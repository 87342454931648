import React from 'react';

import IconToken from 'components/icons/IconToken';

export default (props) => {
  return (
    <div className="card-ctl">
      {props.actionEdit && <IconToken icon="edit" action={props.actionEdit} /> }
      {props.actionAdd && <IconToken icon="add" action={props.actionAdd} /> }
    </div>
  )
}
