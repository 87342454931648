import React, {useEffect, useState, Fragment} from 'react';
import { useParams, Redirect } from "react-router";

import CardSet from 'components/organisms/CardSet';
import Link from 'components/navigation/Link';
import IconSVG from 'components/icons/IconSVG';
import CategoryBlock from 'components/categories/CategoryBlock';
import CategoryProductBlock from 'components/categories/CategoryProductBlock';
import Breadcrumb from 'components/navigation/Breadcrumb';
import Loader from 'components/atoms/Loader';

import * as utils from 'methods/site';

export default (props) => {
  props = {...props.children, ...utils};
  
  const [organizationID, setOrganizationID] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [categories, setCategories] = useState(null);
  const [category, setCategory] = useState(null);
  const [subcategories, setSubcategories] = useState(null);
  const [productsLoading, setProductsLoading] = useState(null);
  const [products, setProducts] = useState(null);
  const [noProducts, setNoProducts] = useState(false);
  const [noCategories, setNoCategories] = useState(false);
  const [categoryName, setCategoryName] = useState(null);
  const [redirect, setRedirect] = useState(null);
  
  let { category_id } = useParams();

  useEffect(() => {
    return () => {
      setCategory(null);
      setCategories(null);
      setCategoryName(null);
      setNoProducts(null);
      setProducts(null);
      setSubcategories(null);
    }
  }, []);

  useEffect(() => {
    if(props.objExists(props.site, 'persona.organization_id')) {
      setOrganizationID(props.site.persona.organization_id);
    }
    if(props.objExists(props.site, 'site_language')) {
      setLanguageCode(props.site.site_language);
    }else{
      setLanguageCode('en');  
    }
  }, [props.site]);
  
  useEffect(() => {
    if(props.objExists(props.store, 'categories') &&
      props.store.categories !== categories) {
        setCategories(props.store.categories);
      }
  }, [props.store.categories]);
  
  useEffect(() => {
    if(Array.isArray(categories) && category_id) {
      const thisCat = props.getCategory(category_id, categories);
      if(thisCat && thisCat !== category) {
        setCategory(thisCat);
       
      }else if(category && !thisCat){
        setCategory(null);
        setSubcategories(null);
        setNoCategories(true);
      }
    }
  }, [categories, category_id]);
  
  useEffect(() => {
    if(category && languageCode &&
        props.objExists(category, `${languageCode}.category_name`) &&
        category[languageCode].category_name !== categoryName) {
      
      setCategoryName(`${category[languageCode].category_parent_name ?
                          `${category[languageCode].category_parent_name} : ` :
                          ''}${category[languageCode].category_name}`);
                          
      if(category.subcategories &&
        Array.isArray(category.subcategories) && 
        category.subcategories.length > 0 &&
        category.subcategories !== subcategories) {
        setSubcategories(category.subcategories);
      }else if(subcategories && 
        (!category.subcategories || 
          !Array.isArray(category.subcategories) ||
          category.subcategories.length <= 0)) {
        setSubcategories(null);
      }
    }
  }, [category, languageCode]);
  
  useEffect(() => {
    if(category) {
      let prodCount = parseInt(category.product_count);
      let subCatCount = parseInt(category.subcategory_count);
      
      if(Array.isArray(products) && products.length === 1 && subCatCount <= 0 &&
          props.objExists(products, `[0].product_id`)) {
        setRedirect(`/product/${products[0].product_id}?cat=${category.category_id}`);
      }else if(subCatCount === 1 && prodCount <= 1 &&
                props.objExists(category, `subcategories[0].category_id`)){
        setRedirect(`/category/${categories[0].category_id}`)
      }
    }
  }, [category, categories, products]);
  
  useEffect(() => {
    if(Array.isArray(props.objGetValue(props.store, 'products')) &&
      props.objExists(category, `category_id`)) {
      setProductsLoading(false);
      let catProducts = props.store.products.filter(prod => {
        return Array.isArray(prod.categories) ?
                prod.categories.filter(cat => parseInt(cat.category_id) === parseInt(category.category_id)).length > 0 : false;
      });
      
      catProducts = catProducts.sort((a,b) => {return a.product_order > b.product_order ? 1 : -1});
      
      if(Array.isArray(catProducts) && catProducts.length > 0 && catProducts !== products) {
        setProducts(catProducts);
      }else{
        setProducts(null);
      }
    }else if(!props.objExists(props.store, `products`) && !products) {
      setProductsLoading(true);
    }
  }, [props.store, category]);
  
  useEffect(() => {
    if(products && productsLoading) {
      setProductsLoading(false);
    }
  }, [products]);

  return (
    <div className="set-container">
      {redirect && <Redirect to={redirect} />}
      {Array.isArray(props.objGetValue(category, `category_breadcrumb`)) &&
        <Breadcrumb {...props} 
                    breadcrumb={category.category_breadcrumb} />  
      }
      <h2>{categoryName ? props.capitalizeString(categoryName, true) : `Category ${category_id}`}</h2>
      
      {Array.isArray(subcategories) &&
        subcategories.length > 0 &&
        languageCode &&
        <Fragment>
          <h3>Categories</h3>
          <div className="grid blocks">
            {subcategories.map((subcategory, index) => {
              return (
                <CategoryBlock {...props}
                  key={`catBlk${index}`}
                  languageCode={languageCode}
                  category={subcategory}
                  link={`/category/${subcategory.category_id}`}
                   /> 
              )
            })}
          </div>
        </Fragment>
      }
      {Array.isArray(products) &&
        products.length > 0 &&
        languageCode &&
        <Fragment>
          <h3>Products</h3>
          <div className="grid blocks">
            {products.map((product, index) => {
              return (
                <CategoryProductBlock {...props}
                  key={`cPrdBlk${index}`}
                  languageCode={languageCode}
                  product={product}
                  link={`/product/${product.product_id}?cat=${category.category_id}`}
                   /> 
              )
            })}
          </div>
          
        </Fragment>
      }
      {!products && productsLoading &&
        <Loader label="Getting products..." overlayContents={true} />
      }
    </div>
  )
}
