import React, {useEffect} from 'react';
import PagePublic from 'components/templates/PagePublic';

import Category from 'components/categories/Category';

export default (props) => {
  return (
    <PagePublic >
      <Category  />
    </PagePublic>
  )
}
