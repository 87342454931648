import React, {useEffect, useState} from 'react';

import FormDynamic from 'components/forms/FormDynamic';

export default (props) => {
  const [fieldsOption, setFieldsOption] = useState(null);
  const [formOption, setFormOption] = useState(null);

// HOOKS
useEffect(() => {
    console.log("HERE iS The index we are getting", props.index);
    setFieldsOption([
      {fieldset_name: 'Add an option',
      fields: [
        {field_type: 'number',
        field_label: 'Option Order',
        field_name: 'option_order',
        field_placeholder: 'Order in list (leave all at 1 for alphabetical)',
        field_value: props.objExists(props, 'option_order') ? props.option_order : null,
        field_order: 1,
        field_required: false,
        field_validate: false,},
        {field_type: 'text',
        field_label: 'Option Name',
        field_name: 'option_name',
        field_placeholder: 'What shows up in the list',
        field_value: props.objExists(props, 'option.option_name') ? props.option.option_name : null,
        field_order: 2,
        field_required: true,
        field_validate: true,},
        {field_type: 'text',
        field_label: 'Option Value',
        field_name: 'option_value',
        field_placeholder: 'The value submitted',
        field_value: props.objExists(props, 'option.option_value') ? props.option.option_value : null,
        field_order: 3,
        field_required: true,
        field_validate: true,},
        props.objExists(props, 'option.option_id') &&
        {field_type: 'hidden',
        field_name: 'option_id',
        field_value: props.option.option_id,
        field_required: false,
        field_validate: false
        },
        {field_type: 'hidden',
        field_name: 'process',
        field_value: props.objExists(props, 'option.option_id') ? 'update_group_option' : 'create_field_option',
        field_required: false,
        field_validate: false
        },
        props.objExists(props, 'option.option_order') &&
        {field_type: 'hidden',
        field_name: 'option_order',
        field_value: props.option.option_order,
        field_required: false,
        field_validate: false
        },
        props.objExists(props, 'group_id') &&
        {field_type: 'hidden',
        field_name: 'group_id',
        field_value: props.group_id,
        field_required: false,
        field_validate: false
        },
      ]},

    ])
}, [props.option]);

useEffect(() => {
  if(fieldsOption) {
    setFormOption(<FormDynamic {...props}
                          content={fieldsOption}
                          name="add-field-option"
                          returnObject={true}
                          fieldOnChange={handleChange}
                          cancelable={props.actionCancel ? true : false}
                          formActions={{formCancel: props.actionCancel ? handleCancel : null, formSubmit: handleAdd}} />);
  }
}, [fieldsOption]);



// FUNCTIONS
  function handleCancel(e) {
    if(props.actionCancel) {
      props.actionCancel(e);
    }
  }

  function handleChange(e) {
    // console.log("We're handling change!!");
  }

  function handleAdd(params) {
    props.setModal(null);
    if(props.actionAdd) {
      props.actionAdd(params);
    }
  }

  return (formOption)
}
