import React, {useEffect, useState, Fragment} from 'react';

import Link from 'components/navigation/Link';
import IconSVG from 'components/icons/IconSVG';

export default (props) => {
	const [breadcrumb, setBreadcrumb] = useState(null);
	
	useEffect(() => {
	
		if(props.breadcrumb && 
			props.breadcrumb !== breadcrumb) {
			setBreadcrumb(props.breadcrumb);
		}
	}, [props.breadcrumb]);
	
	return(
		Array.isArray(breadcrumb) &&
		breadcrumb.length > 0 &&
		<div className="breadcrumb">
			<IconSVG icon="arrow" className="rotate270" />
			{breadcrumb.map((crumb, index) =>{
				return (
					<Fragment key={`bc${index}`}>
						{index > 0 && 
							<span>/</span>
						}
						{index+1 < breadcrumb.length ?
							<Link to={crumb.breadcrumb_value} 
									key={`bcl${index}`}
									content={props.capitalizeString(crumb.breadcrumb_name, true)} />	
						:
							<span>
								{props.capitalizeString(crumb.breadcrumb_name, true)}
							</span>
						}
					</Fragment>
				)
			})}
		</div>
	)
}