import axios from 'axios';
import * as apis from 'methods/api.js';

export const adminSetComponent = (component, currentPath, pageTitle = null) => dispatch => {
  dispatch({type: 'ADMIN_SET_COMPONENT', payload: component, currentPath: currentPath, pageTitle: pageTitle});
}

export const adminSetProp = (name, value) => dispatch => {
  dispatch({type: 'ADMIN_SET_PROP', name: name, payload: value});
}

export const adminRemoveProp = (name, value, location = null, index = null) => dispatch => {
  dispatch({type: 'ADMIN_REMOVE_PROP', name: name, value: value, location: location, index: index});
}

export const adminSetVal = (name, value, sStore = true) => dispatch => {
  dispatch({type: 'ADMIN_SET_VALUE', name: name, value: value, sStore: sStore});
}

export const adminDeleteVal = (location) => dispatch => {
  dispatch({type: 'ADMIN_DELETE_VAL', payload: location});
}

export const adminUpdateVal = (name, value, location = null, isProp = false) => dispatch => {
  dispatch({type: 'ADMIN_UPDATE_VAL', name: name, value: value, location: location, isProp: isProp});
}

export const adminLoadFromSession = (name, value) => dispatch => {
  dispatch({type: 'ADMIN_LOAD_FROM_SESSION', name: name, payload: value});
}

export const adminProductAdd = (organization_id, user_id, json) => dispatch => {
  const formData = 'process=add_product&organization_id='+organization_id+'&user_id='+user_id+'&json='+json;
  axios.post(apis.api_url_products, formData).then(res => {
      if(Object.entries(res.data).length > 0) {
        dispatch({type: 'ADMIN_PRODUCT_ADD', payload: res.data});
        dispatch({type: 'SITE_SHOW_NOTIFICATION', payload: {icon: 'check', title: 'Success!', message: `${res.data.details.en.product_name} was added.`}});
      }else{
        dispatch({type: 'SITE_SHOW_NOTIFICATION', payload: {icon: 'warning', title: 'Error', message: `The Product was not added  - message returned: '${res.data}'`}});
      }
  }).catch( error => {
    console.log(error);
  })
}


export const adminProductUpdate = (organization_id, user_id, json, product_id) => dispatch => {
  const formData = 'process=update_product&organization_id='+organization_id+'&user_id='+user_id+'&json='+json;
  axios.post(apis.api_url_products, formData).then(res => {
      if(product_id > 0) {
        const reqData = `process=get_product&product_id=${product_id}`;
        axios.post(apis.api_url_categories, reqData).then( pres => {
          dispatch({type: 'ADMIN_PRODUCT_ADD', payload: pres.data});
        }).catch( error => {
          console.log(error);
        })
      }
  }).catch( error => {
    console.log(error);
  })
}

export const productTypesGet = (user_id = null, organization_id = null) => dispatch =>{
  const formData = 'process=get_product_types&user_id='+user_id+'&organization_id='+organization_id;
  axios.post(apis.api_url_types, formData).then(res => {
    dispatch({type: 'PRODUCT_TYPE_GET', payload: res.data});
  }).catch( error => {
    console.log(error);
  })
}

export const productCategoriesGet = (organization_id = null, category_published = true, language_id = null, order_by = null, order_by_dir = null) => dispatch =>{
  const formData = 'process=get_categories&organization_id='+organization_id+"&category_published="+category_published;
  if(language_id) {
    formData += '&language_id='+language_id;
  }
  if(order_by) {
    formData += '&language_id='+order_by;
  }
  if(order_by_dir) {
    formData += '&language_id='+order_by_dir;
  }
  axios.post(apis.api_url_categories, formData).then(res => {
    dispatch({type: 'PRODUCT_CATEGORIES_GET', payload: res.data});
  }).catch( error => {
    console.log(error);
  })
}

export const productPropertiesGet = ( language,
                                      user_id,
                                      user_private = false,
                                      property_published = false,
                                      language_id = null,
                                      order_by = null,
                                      order_by_dir = null) => dispatch => {
  const formData = `process=get_properties&user_id=${user_id}${user_private ? `&user_private=${user_private}` : ''}${property_published ? `&property_published=${property_published}` : ''}${language_id ? `&language_id=${language_id}` : ''}${order_by ? `&order_by=${order_by}` : ''}${order_by_dir ? `&order_by_dir=${order_by_dir}` : ''}`;
  axios.post(apis.api_url_properties, formData).then(res => {
    dispatch({type: 'PRODUCT_PROPERTIES_GET', language: language, payload: res.data});
  }).catch(error => {
    console.log(error);
  })
}

export const adminGetProducts = (organization_id,
                                      user_id,
                                      category_id = null,
                                      product_published = null,
                                      language_code = null,
                                      order_by = null,
                                      order_by_dir = null) => dispatch => {
  let formData = `process=get_products`;
  if(organization_id) { formData += `&organization_id=${organization_id}`}
  // if(user_id) { formData += `&user_id=${user_id}`}
  if(category_id) { formData += `&category_id=${category_id}`}
  if(product_published) { formData += `&product_published=${product_published}`}
  if(language_code) { formData += `&language_code=${language_code}`}
  if(order_by) { formData += `&order_by=${order_by}`}
  if(order_by_dir) { formData += `&order_by_dir=${order_by_dir}`}

  axios.post(apis.api_url_products, formData).then(res => {
    dispatch({type: 'ADMIN_GET_PRODUCTS', payload: res.data});
  }).catch(error => {
    console.log(error);
  })

  dispatch({type: 'NOTHING'});
}

 export const adminSetGuide = (guide) => dispatch => {
   dispatch({type: 'ADMIN_SET_GUIDE', payload: guide});
 }

export const adminLoadState = (params, api, state_location, callback = null, cbApi = null) => dispatch => {
  var formData = new FormData();

  Array.isArray(params) &&
  params.map (param => {
     if(param.name && param.value !== null) {
      formData.append(param.name, param.value);
     }
  })
  
  // for(var pair of formData.entries()) {
  //   console.log("FORM DATA", pair[0], ' : ', pair[1]);  
  // }
  

  if(apis.getReqUrl(api)) {
      axios.post(apis.getReqUrl(api), formData, {
        headers: {
          'Content-Type': 'multipart/form-data;charset=UTF-8'
        }
      }).then(res => {
        if(callback && Array.isArray(callback) && callback.length > 0) {
          var callbackData = new FormData();
          callback.map (param => {
            if(param.name && param.value) {
              if(param.value === 'result') {
                callbackData.append(param.name, res.data);
              }else{
                callbackData.append(param.name, param.value);
              }
            }
          })
          axios.post(apis.getReqUrl(cbApi ? cbApi : api), callbackData,  {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
            }
          }).then(cbRes => {

            dispatch({type: 'ADMIN_LOAD_STATE', location: state_location, payload: cbRes.data});
          }).catch(error => {
            console.log("DISPATCH ERROR LOAD CALLBACK", error);
          })
        }else{
          dispatch({type: 'ADMIN_LOAD_STATE', location: state_location, payload: res.data});
        }
      }).catch(error => {
        console.log("DISPATCH ERROR - LOAD STATE", apis.getReqUrl(api), error);
      })
    }else{
      // console.log("There's no reqUrl", api, reqUrl);
    }
 }


// adminAddLinkedObject is now availble to handle media add, linking and returning parent;
 export const adminAddMedia = (params, owner, ownerID, ownerLanguage, reloadOwner = true) => dispatch => {
   var formData = new FormData();
   formData.append('process', 'add_media');
   let userID;

   Array.isArray(params) &&
   params.map (param => {
      if(param.name && param.value) {
        if(param.name === 'user_id') {
          userID = param.value;
        }
       formData.append(param.name, param.value);
      }
   })
   axios.post(apis.getReqUrl('media'), formData).then(res => {
     let linkType;
     switch(owner) {
      case 'product':
      case 'Product':
        linkType = 'imageToProduct';
        break;
      case 'organization':
      case 'Organization':
        linkType = 'imageToOrganization';
        break;
      case 'category':
      case 'Category':
        linkType = 'imageToCategory';
        break;
      case 'user':
      case 'User':
        linkType = 'imageToUser';
      default:
        break;
     }

     if(parseInt(res.data) > 0) {
       const mediaID = res.data;
       var lFormData = new FormData();
       lFormData.append('process', 'add_link');
       lFormData.append('link_from', mediaID);
       lFormData.append('link_to', ownerID);
       lFormData.append('link_type', linkType);
       lFormData.append('link_published', true);
       lFormData.append('user_id', userID);

       axios.post(apis.getReqUrl('links'), lFormData).then(res => {

         if(res.data) {
           var llFormData = new FormData();
           llFormData.append('process', 'add_link');
           llFormData.append('link_from', mediaID);
           llFormData.append('link_to', ownerLanguage === 'fr' ? 2 : 1);
           llFormData.append('link_type', 'imageToLanguage');
           llFormData.append('link_published', true);
           llFormData.append('user_id', userID);

           axios.post(apis.getReqUrl('links'), llFormData).then(res => {
          }).catch(error => {
             console.log("DISPATCH ERROR - ADD LINK", error);
          })

         }
       }).catch(error => {
         console.log("DISPATCH ERROR - ADD LINK", error);
       })
    }
   }).catch(error => {
     console.log("DISPATCH ERROR - ADD MEDIA", error);
   })


 }

export const adminAddLinkedObject = (params, linkType, linkTo, addLink = true) => dispatch => {
  const formData = new FormData();
  Array.isArray(params) &&
  params.map(param => {
    formData.append(param.name, param.value);
  })
  axios.post(apis.getReqUrl(linkType.split('To')[0]), formData).then(res => {
    if(addLink === true) {
      let linkFrom = null;
      let linkSet = false;
      if(res.data) {
        if(parseInt(res.data) > 0) {
          linkFrom = parseInt(res.data);
        }else if(Object.entries(res.data).length > 0 &&
                res.data[`${linkType.split('To')[0]}_id`] &&
                parseInt(res.data[`${linkType.split('To')[0]}_id`]) > 0) {
          linkFrom = parseInt(res.data[`${linkType.split('To')[0]}_id`]);
        }
      }


      if(linkFrom) {
        var lFormData = new FormData();
        lFormData.append('process', 'add_link');
        lFormData.append('link_from', linkFrom);
        lFormData.append('link_to', linkTo);
        lFormData.append('link_type', linkType);
        lFormData.append('link_published', true);
        lFormData.append('user_id', params.find(data => data.name === 'user_id').value);
        axios.post(apis.getReqUrl('links'), lFormData).then(res => {
          if(res.data) {
            linkSet = res.data;
          }
        }).catch(error => {
            console.log("DISPATCH ERROR - ADD LINK", error);
        })
      }
    }

    let parentObj = linkType.split('To')[1].toLowerCase();
    let process = null;
    switch(parentObj) {
      case 'organization':
        process = 'get_organizations';
        break;
        case 'account':
        case 'user':
          parentObj = 'user';
          process = 'get_users';
          break;
        case 'product':
          process = 'get_product';
          break;
        default:
          process = `get_${linkType.split('To')[1].toLowerCase()}`;
    }

    const rFormData = new FormData();
    rFormData.append('process', process);
    rFormData.append('user_id', params.find(param => param.name === 'user_id'));
    rFormData.append('token', params.find(param => param.name === 'token'));
    rFormData.append('organization_id', linkTo);

    axios.post(apis.getReqUrl(linkType.split('To')[1].toLowerCase()), rFormData).then(res => {
      dispatch({type: 'ADMIN_LOAD_STATE', location: parentObj, payload: res.data});
    }).catch(error => {
      console.log("DISPATCH ERROR - LOAD OBJECT FROM LINK", error);
    });

  }).catch(error => {
    console.log("DISPATCH ERROR - ADD MEDIA", error);
  })

}

 export const adminTraverseArray = (node, value) => dispatch => {
   dispatch({type: 'DASBOARD_TEST_SET', node: node, value: value});
 }
