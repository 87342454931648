import React, {useEffect, useState, Fragment} from 'react';

import MediaFileToken from 'components/profiles/media/MediaFileToken';
import MediaFile from 'components/profiles/media/MediaFile';
import MessageList from 'components/profiles/messages/MessageList';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [file, setFile] = useState(null);
	const [title, setTitle] = useState(null);
	const [messages, setMessages] = useState(null);
	const [canEdit, setCanEdit] = useState(false);
	
	// HOOKS
	useEffect(() => {
		if(props.userID &&
			props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token &&
			props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.languageCode &&
			props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.file && props.file !== file) {
			setFile(props.file);
		}
	}, [props.file]);
	
	useEffect(() => {
		if(props.title && (!title || 
			props.title.toLowerCase() !== title.toLowerCase())) {
			setTitle(props.capitalizeString(props.title, true));
		}
	}, [props.title]);
	
	useEffect(() => {
		
		if(file && 
			Array.isArray(file.messages) &&
			file.messages.length > 0  &&
			file.messages !== messages) {
			setMessages(file.messages);
		}
	}, [file]);
	
	// FUNCTIONS
	function handleRemove(params) {
		props.actionRemove &&
		props.actionRemove(params);
	}
	
	console.log("MEDIA FILE SPOTLIGHT", languageCode, props.languageCode);
	return (
		<div className="file-spotlight">
			{file && messages &&
				<Fragment>
					<MediaFileToken {...props}
						key={`mftPrf0`}
						userID={userID}
						token={token}
						languageCode={languageCode}
						file={file}
						fileMediaType={props.fileMediaType}
						fileOwner={props.fileOwner}
						fileOwnerID={props.fileOwnerID}
						isHero={true}
						label={props.label}
						canEdit={canEdit}
						actionRemove={handleRemove}
						displayInfo={false} />
			
				<div className="spotlight-info grid cells">
					{title && <h4>{title}</h4>}
					<div className="name">File</div>
					<div className="value">
						<MediaFile {...props} media={file} />
						
					</div>
					{props.objExists(file, `media_version`) &&
						<Fragment>
							<div className="name">version</div>
							<div className="value">
								{file.media_version}
							</div>
						</Fragment>
					}
					{props.objExists(file, `media_file_size`) &&
						<Fragment>
							<div className="name">Size</div>
							<div className="value">
								{props.displayFileSize(file.media_file_size)}
							</div>
						</Fragment>
					}
					{props.objExists(file, `media_created`) &&
						<Fragment>
							<div className="name">Uploaded</div>
							<div className="value">
								{`${props.dateDisplay(file.media_created)} 
								${props.timeDisplay(file.media_created, false)}`}
							</div>
						</Fragment>
					}
					{props.objExists(file, `media_created`) &&
					props.objExists(file, `media_modified`) &&
					file.media_modified > file.media_created &&
						<Fragment>
							<div className="name">Updated</div>
							<div className="value">
								{`${props.dateDisplay(file.media_modified)} 
								${props.timeDisplay(file.media_modified, false)}`}
							</div>
						</Fragment>
					}
					{Array.isArray(messages) &&
						<Fragment>
							<div className="name"></div>
							<div className="value">
								<MessageList {...props}
									userID={userID}
									token={token}
									languageCode={languageCode}
									messages={messages}
									location={props.location}
									stateLocation={props.stateLocation}
									title={'Comments'} />
							</div>
						</Fragment>
					}
				</div>
			</Fragment>
		}
		</div>
	)
}