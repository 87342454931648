import React, {useEffect, useState, Fragment} from 'react';

import Control from 'components/atoms/Control';
import LiveFieldInput from 'components/forms/elements/LiveFieldInput';
import Switch from 'components/forms/elements/Switch';

export default (props) => {
  const [prefs, setPrefs] = useState(null);
  const [userID, setUserID] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [token, setToken] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [curLanguage, setCurLanguage] = useState(null);
  const [canEdit, setCanEdit] = useState(null);
  const [isSuper, setIsSuper] = useState(null);
  
  const corePrefs = [
    {pref_name: 'category_home', pref_type: 'text'},
    {pref_name: 'home_page', pref_type: 'text'},
    {pref_name: 'hide_pricing', pref_type: 'switch'},
    {pref_name: 'show_search', pref_type: 'switch'},
    {pref_name: 'restrict_user_locations', pref_type: 'switch'},
    {pref_name: 'order_item_limit', pref_type: 'number'},
    {pref_name: 'order_multiship', pref_type: 'switch'},
    {pref_name: 'client_review_creative_ignore', pref_type: 'switch'}
  ];

  // HOOKS
  useEffect(() => {
    props.userID &&
    props.userID !== userID &&
    setUserID(props.userID);
  }, [props.userID]);

  useEffect(() => {
    props.token &&
    props.token !== token &&
    setToken(props.token);
  }, [props.token])

  useEffect(() => {
    if(props.organizationID && 
        props.organizationID !== organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);

  useEffect(() => {
    props.languageCode && 
    !languageCode &&
    setLanguageCode(props.languageCode);
  }, [props.languageCode]);
  
  useEffect(() => {
    props.canEdit && 
    props.canEdit !== canEdit &&
    setCanEdit(props.canEdit);
  }, [props.canEdit]);
  
  useEffect(() => {
    props.isSuper &&
    props.isSuper !== isSuper &&
    setIsSuper(props.isSuper);
  }, [props.isSuper]);
  
  useEffect(() => {
    if(props.prefs && 
        Array.isArray(props.prefs) && 
        props.prefs.length > 0) {
          setPrefs(props.prefs);
        }
  }, [props.prefs]);

  // FUNCTIONS
  function handleClick(property_id) {
    if(props.actionAddEdit) {
      props.actionAddEdit(property_id);
    }
  }
  
  function addUpdatePref(name, value, params) {
    let propParams = [];
    if(value && 
        !params &&
        Array.isArray(value) &&
        value[0].name) {
      propParams = [
        ...value,
        {name: 'property_value', value: name}
      ];
    }else if(params && 
              Array.isArray(params) &&
              params[0].name) {
      propParams = [
        ...params,
        {name: 'property_value', value: value}
      ];
    }
    console.log("ADD UPDATE PREF", propParams);
    props.adminLoadState(propParams, 'properties', 'reload.organization');
    props.adminSetVal('reload.persona', true);
  }

  return (
    <div className="list-set">
      <h4>Site Preferences</h4>
      {corePrefs && corePrefs.map((pref, index) => {
        const property = prefs ? 
                          prefs.find(prop => prop.property_name && prop.property_name.toLowerCase() === pref.pref_name.toLowerCase()) : null;
        return (
          pref.pref_type === 'switch' ?
            <Switch {...props}
                    name={pref.pref_name}
                    label={props.capitalizeString(pref.pref_name.replaceAll('_', ' '), true)}
                    value={property && property.property_value === 'true' ? true : false}
                    action={addUpdatePref}
                    params={[
                      {name: 'property_name', value: pref.pref_name},
                      {name: 'process', value: property ? 'update_property_pair' : 'create_property'},
                      property ?
                      {name: 'property_id', value: property.property_id} :
                      {name: 'property_type', value: 'Organization'},
                      {name: 'property_link', value: organizationID},
                      {name: 'property_published', value: 1},
                      {name: 'user_id', value: userID},
                      {name: 'token', value: token},
                      {name: 'language_code', value: languageCode},
                      {name: 'return_boolean', value: true}
                    ]} />
          :
            <div className="form-row">
              <LiveFieldInput
                  {...props}
                  type={pref.pref_type}
                  label={props.capitalizeString(pref.pref_name.replaceAll('_', ' '), true)}
                  option_groups={pref.pref_options ? [{options: pref.pref_options}] : null}
                  name={pref.pref_name}
                  value={property ? property.property_value : null}
                  action={addUpdatePref}
                  optionalParams={[
                    {name: 'property_name', value: pref.pref_name},
                    {name: 'process', value: property ? 'update_property_pair' : 'create_property'},
                    property ?
                      {name: 'property_id', value: property.property_id} :
                      {name: 'property_type', value: 'Organization'},
                    {name: 'property_link', value: organizationID},
                    {name: 'property_published', value: 1},
                    {name: 'user_id', value: userID},
                    {name: 'token', value: token},
                    {name: 'language_code', value: languageCode},
                    {name: 'return_boolean', value: true}]}
                  disabled={canEdit && isSuper ? false : true} />
            </div>
        )
      })}
    </div>
  )
}
