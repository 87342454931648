import React, {useEffect, useState, Fragment} from 'react';

import MenuSetUser from 'components/navigation/MenuSetUser';
import MenuSetLogout from 'components/navigation/MenuSetLogout';

export default (props) => {
  const [menuComponent, setMenuComponent] = useState(null);
  
  useEffect(() => {
    if(props.moduleComponent) {
      switch(props.moduleComponent) {
        case 'MenuSetUser':
          setMenuComponent(
            <MenuSetUser {...props}
                          module={props.module ? props.module : null}
                          languageCode={props.languageCode ? props.languageCode : 'en'} />
          )
          break;
        case 'MenuSetLogout':
          setMenuComponent(
            <MenuSetLogout {...props}
                          module={props.module ? props.module : null}
                          languageCode={props.languageCode ? props.languageCode : 'en'} />
          )
          break;
        default:
          setMenuComponent(
            <div>
              {props.modulePlaceholder ? 
                props.modulePlaceholder : 
                  props.moduleComponent ? 
                    props.moduleComponent : 'Component'}
            </div>
          )
        break;
      }
    }
  }, [props.moduleComponent])
  
  
  function runActions(actions) {
    if(Array.isArray(actions)) {
      actions.map(action => {
        fireAction(action);
      })
    }else{
      fireAction(actions);
    }
  }

  function fireAction(action) {
    if(props[action.action_name]) {
      if (action.action_props) {
        props[action.action_name](...action.action_props)
      }else{
        props[action.action_name]();
      }
    }else{
      console.log(action.action_name, " : no such action");
    }
  }

  return (menuComponent)
}
