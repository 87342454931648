import React, {useEffect, useState, Fragment} from 'react';
import { useLocation, useHistory} from 'react-router';

import Loader from 'components/atoms/Loader';
import Select from 'components/forms/elements/Select';
import GroupList from 'components/profiles/groups/GroupList';
import Group from 'components/profiles/groups/Group';
import FormGroupAddEdit from 'components/forms/common_forms/FormGroupAddEdit';
import OrganizationSelector from 'components/profiles/orgs/OrganizationSelector'; 

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [organizationName, setOrganizationName] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [userAccess, setUserAccess] = useState(null);
	const [organizations, setOrganizations] = useState(null);
	const [coreOrg, setCoreOrg] = useState(null);
	const [coreOrgName, setCoreOrgName] = useState(null);
	const [curOrg, setCurOrg] = useState(null);
	const [newOrg, setNewOrg] = useState(null);
	const [orgUpdating, setOrgUpdating] = useState(false);
	const [searchParams, setSearchParams] = useState(false);
	
	const pgLocation = useLocation();
	const pgHistory = useHistory();
	
	// HOOKS
	useEffect(() => {
		if(pgLocation.search && 
			props.getURLSearchParams(pgLocation.search) !== searchParams) {
		  console.log('location search', props.getURLSearchParams(pgLocation.search));
		  setSearchParams(props.getURLSearchParams(pgLocation.search));
		}
	}, [pgLocation]);
	
	useEffect(() => {
		console.log("Got new searchParams", searchParams);
		if(searchParams && props.paramsToString(searchParams) !== pgLocation.search) {
			console.log("got new searchParams to push", props.paramsToString(searchParams));
		  pgHistory.push(`${pgLocation.pathname}${
			  props.paramsToString(searchParams) ? 
			  	props.paramsToString(searchParams) : ''}`);
		}
	}, [searchParams]);
	
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
			setUserID(props.userID)
		}
	}, [props.userID]);
	  
	useEffect(() => {
		if(props.token && props.token !== token) {
			setToken(props.token)
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.organizationID && 
			organizationID !== props.organizationID) {
			setOrganizationID(props.organizationID);
		}
	}, [props.organizationID]);
	  
	useEffect(() => {
		if(props.organizationName &&
		props.organizationName !== organizationName) {
			setOrganizationName(props.organizationName);
		}
	}, [props.organizationName]);
	  
	useEffect(() => {
		if(props.languageCode && 
			languageCode !== props.languageCode) {
			setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	  
	useEffect(() => {
		if(props.userAccess && 
			props.userAccess !== userAccess) {
			setUserAccess(props.userAccess);
		} 
	}, [props.userAccess]);
	
	useEffect(() => {
		if(props.coreOrg && props.coreOrg !== coreOrg) {
			// setCoreOrg(props.coreOrg);
		}else if(!props.coreOrg && parseInt(props.objGetValue(searchParams, 'org')) > 0) {
			if(parseInt(props.objGetValue(searchParams, 'org')) !== coreOrg) {
				// orgSelect(parseInt(props.objGetValue(searchParams, 'org')));
			}
		}else if(!props.coreOrg && 
				!props.coreOrg && parseInt(props.objGetValue(searchParams, 'org')) <= 0 &&
				coreOrg) {
			 //setCoreOrg(null);
		}
	}, [props.coreOrg, searchParams]);
	
	useEffect(() => {
		if(props.objExists(props.admin, 'organizations') &&
			Array.isArray(props.admin.organizations) && 
			props.admin.organizations.length > 0 &&
			props.admin.organizations !== organizations) {
				setOrganizations(props.admin.organizations);
		}
	}, [props.admin.organizations]);
	
	useEffect(() => {
		if(coreOrg && organizations &&
			Array.isArray(organizations)) {
			const orgUpdate = organizations.find(org => org.organization_id === coreOrg);
			if(orgUpdate){
				setCoreOrgName(orgUpdate.organization_name);
			}
		}else if(!coreOrg || isNaN(parseInt(coreOrg)) || parseInt(coreOrg) <= 0) {
			
		}
		
		if(coreOrg > 0 && parseInt(props.objGetValue(searchParams, `org`)) !== coreOrg) {
			setSearchParams(Object.entries(searchParams).length > 0 ?
								{...searchParams, org: coreOrg} : {org: coreOrg});
		}else if((!coreOrg || isNaN(parseInt(coreOrg)) || parseInt(coreOrg) <= 0) &&
				props.objExists(searchParams, `org`)) {
			let sParams = {...searchParams};
			delete sParams.org;
			setSearchParams(sParams);
		}
		
	}, [coreOrg, orgUpdating, organizations]);
	
	useEffect(() => {
		if(props.objGetValue(props.admin, 'groups.loading.user') === true) {
			setOrgUpdating(true);
		}else if(orgUpdating) {
			setOrgUpdating(false);	
		}
	}, [props.admin]);
	
	// FUNCTIONS
	function addEditGroup(params) {
		props.adminLoadState(params, 'groups', 'reload.groups.user');
	}
	
	function orgSelect(org) {
		console.log("changing Org", org);
		if(parseInt(org) > 0 && coreOrg !== parseInt(org)) {
		  setCoreOrg(parseInt(org));
		  if(props.actionSelectOrg) {
			props.actionSelectOrg(parseInt(org));
		  }
		}else if(coreOrg && (parseInt(org) <= 0 || !org)) {
		  setCoreOrg(null);
		  if(props.actionSelectOrg) {
			props.actionSelectOrg(parseInt(null));
		  }
		}
	}
	
	console.log("search params", searchParams);
	return (
		<Fragment>
			{props.pgParams.component === 'group' ?
				<Fragment>
					{!newOrg ?
						<Group {...props}
								userID={userID}
								token={token}
								coreOrg={coreOrg}
								languageCode={languageCode}
								userAccess={userAccess}
								groupID={parseInt(props.pgParams.cpntactivity)} />
					: 
						<Loader label="loading groups" /> 
					}
				</Fragment>
			:
				<Fragment>
					<h2>{coreOrgName ? `${coreOrgName} ` : ''}User Groups</h2>
					{organizations && 
						organizationID <= 1 &&
						<div className="form-row">
						  <h4>Filter by organization</h4>
						  <OrganizationSelector {...props}
								organizations={organizations}
								userAccess={userAccess}
								action={orgSelect}
								orgID={coreOrg}
								skipCore={false}  />
						</div>
					}
					
					{props.pgParams.component === 'group-add' ? 
						<Fragment>
							{!newOrg ?
								<FormGroupAddEdit {...props}
													userID={userID}
													token={token}
													coreOrg={coreOrg}
													languageCode={languageCode}
													userAccess={userAccess}
													groupType="user"
													groups={props.objExists(props.admin, 'groups.user') ?
															props.admin.groups.user : null}
													actionSubmit={addEditGroup}
													cancelable={false} />
							: 
								<Loader label="loading groups" /> 
							}
						</Fragment>
					: 
						<Fragment>
							{orgUpdating !== true ?
								<GroupList {...props}
											userID={userID}
											token={token}
											coreOrg={props.coreOrg}
											languageCode={languageCode}
											groupType="user"
											groups={props.objExists(props.admin, 'groups.user') ?
														props.admin.groups.user : null}
											location="/admin/organizations"
											stateLocation="admin"
											isAdmin={true} />
							: 
								<Loader label="loading groups" /> 
							}
						</Fragment>
					}
				</Fragment>
			}
		</Fragment>
	)
}