import React, {useEffect, useState, Fragment} from 'react';

import Select from 'components/forms/elements/Select';
import Input from 'components/forms/elements/Input';
import Button from 'components/forms/elements/Button';
import IconSVG from 'components/icons/IconSVG';
import Form from 'components/forms/Form';

export default (props) => {
	const [value, setValue] = useState(null);
	const [curValue, setCurValue] = useState(null);
	const [curName, setCurName] = useState(null);
	const [valueDisplay, setValueDisplay] = useState(null);
	const [index, setIndex] = useState(null);
	const [nameSelector, setNameSelector] = useState(null);
	const [form, setForm] = useState(null);
	const [updateValue, setUpdateValue] = useState(null);
	
	useEffect(() => {
		if(!value && props.value) {
			if(Object.entries(JSON.parse(props.value)).length > 0) {
				setValue(JSON.parse(props.value));	
			}
		}
	}, [props.value]);
	
	useEffect(() => {
		if(!index) {
			if(value && Object.entries(value).length > 0) {
				setIndex(Object.entries(value).length);
			}else{
				setIndex(0);
			}
		}
		
		if(value &&
			Object.entries(value).length > 0) {
			let values = [];
			Object.entries(value).forEach(([key, value]) => {
				values.push(<div className="row">
					<b>{displayKey(key)}:</b> {value}
				</div>);
			})
			if(values.length > 0) {
				setValueDisplay(
					<div className="fieldset-value-list">
						{values}
					</div>
				);
			} 	
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);
	
	useEffect(() => {
		if(props.option_groups &&
			Array.isArray(props.option_groups) &&
			props.option_groups[0].options &&
			Array.isArray(props.option_groups[0].options)) {
			const newSelector = <Select {...props}
										option_groups={props.option_groups}
										name="field_name"
										label={props.label ? `Add ${props.label} value` : "Value for"}
										placeholder=""
										defaultValue={curName ? curName : ''}
										required={false}
										onChange={handleSelectOnChange}
										/>
			setNameSelector(newSelector);
		}
	}, [props.option_groups, curName]);
	
	useEffect(() => {
		setForm(
			<Fragment>
				{nameSelector &&
					<div className="form-row">
						{nameSelector}
					</div>
				}	
				<div className="form-row">
					<Input {...props}
							type={props.type ? props.type : 'text'}
							name={'field_value'}
							label={!nameSelector && props.name ? props.name : 'Value'}
							placeholder={`Value for ${props.label ? props.label : 'field'}`}
							required={false}
							value={curValue ? curValue : ''}
							onChange={handleInputOnChange} />
				</div>
			</Fragment>
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nameSelector, curName, curValue])
	
	function handleSelectOnChange(param) {
		// RECIEVES OBJECT FROM SELECT {name: 'name', value: 'value'}
		if(param.value || param.value === 0) {
			setCurName(param.value);
		}else if(!param.value && curName) {
			setCurName(null);
		}
	}
	
	function handleInputOnChange(param) {
		setCurValue(param.value);
	}
	
	function addValue(e) {
		e.preventDefault();
		let vals = {...value};
		if(curValue || curValue === 0) {
			vals[objectName(curName)] = curValue;
			
			setValue(vals);
			setCurName(null);
			setCurValue(null);
			setUpdateValue(true);
			setNameSelector(null);
		}
	}
	
	function objectName(value) {
		if(!isNaN(value)) {
			return String(value);
		}else if(typeof value === 'string') {
			return value.replaceAll(/[\s\. ]/ig, '_');	
		}
	}
	
	function displayKey(value) {
		return props.capitalizeString(value.replaceAll(/[\_]/ig, ' '), true);
	}
	
	return (
		<div className="form-row">
			<fieldset>
				<legend>{props.label}</legend>
				{valueDisplay}
				{form}
				<div className="form-row">
				<Button icon="add" 
							label={`Add ${props.label}`}
							btnAction={addValue}
							btnClass="mini success-bg"
							disabled={curName && curValue ? false : true} />
					<div className="form-msg flex-msg">
						<IconSVG icon="reminder" />
						<div className="form-msg-content">
							Be sure to click <b>{`Add ${props.label}`}</b> button to save your value
						</div>
					</div>
				</div>
				<input type="hidden" name={props.name} value={JSON.stringify(value)} />
			</fieldset>
		</div>
	)
}