import React from 'react';
import PagePublic from 'components/templates/PagePublic';

import ProductList from 'components/products/ProductList';

export default (props) => {
  return (
    <PagePublic >
      <ProductList  />
    </PagePublic>
  )
}
