import React, {useState, useEffect, useRef} from 'react';

import MediaCollection from 'components/profiles/media/MediaCollection';
import MediaHero from 'components/profiles/media/MediaHero';
import IconFile from 'components/icons/IconFile';
import ModalConfirmation from 'components/molecules/ModalConfirmation';
import FormDynamic from 'components/forms/FormDynamic';
import FormMediaInfo from 'components/forms/common_forms/FormMediaInfo';

import * as forms from 'methods/forms';

export default (props) => {
  const media = [{name: 'Media 1'}, ];
  const [mediaHero, setHero] = useState(0);
  const [mediaDimensions, setDemensions] = useState(null);
  const [containerHeight, setCHeight] = useState(null);
  const heroRef = useRef();
  const imgRef = useRef();
  const setRef = useRef();

  useEffect(() => {
    let container = null;
    if(setRef.current) {
      setCHeight(setRef.current.parentNode.offsetHeight);
    }
  }, [setRef]);

  useEffect(() => {
    if(heroRef.current && mediaDimensions) {
      setRef.current.style.height = `${setRef.current.parentNode.offsetHeight}px`;
      heroRef.current.style.height = `${mediaDimensions.height}px`;
    }
  }, [mediaDimensions]);

  // FUNCTIONS
  function loadEditMedia(media_id) {
    const mediaInfo = props.media.find(m => m.media_id === media_id);
    props.setModal(<FormMediaInfo {...props}
                                  mediaInfo={mediaInfo}
                                  actionEdit={editMedia}
                                  actionCancel={()=>{props.setModal(null)}} />);
  }

  function editMedia(params) {
    params = [
      ...params,
      {name: 'user_id', value: parseInt(props.userID)},
      {name: 'token', value: props.token},
      {name: 'organization_id', value: parseInt(props.organizationID)},
      {name: 'language_code', value: props.language_code},
      {name: 'parent_id', value: parseInt(props.parent_id)},
      {name: 'parent_type', value: props.parent_type},
      {name: 'return_boolean', value: true}
    ]
    props.setModal(null);
    props[`${props.location}LoadState`](params, 'media', 'reload.product');

    console.log("Here's the Edit Params", params);
  }

  function loadPublishMedia(media_id) {
    if(media_id) {
      const media = props.media.find(m => m.media_id === media_id);
      props.setModal(<ModalConfirmation {...props}
                                        message={`<h3>${media.media_published ? 'Unpublish' : 'Publish'} ${media.media_caption ? media.media_caption : media.media_file_upload_name}?</h3>
                                                  <p>This will ${media.media_published ? `remove the ${props.mediaType(media.media_file_tag)} from public viewing` : `include the ${props.mediaType(media.media_file_tag)} in public viewing`}.</p>`}
                                        actionAccept={()=> {publishMedia(media_id, media.media_published ? false : true)}} />);
    }
  }

  function publishMedia(media_id, value) {
    console.log("Supposed to be publishing: ", media_id, value);
    if(media_id && parseInt(media_id) > 0) {
      const media = props.media.find(m => m.media_id === media_id);
      props.setModal(null);
      const params = [
        {name: 'process', value: 'update_links'},
        {name: 'user_id', value: props.userID},
        {name: 'token', value: props.token},
        {name: 'link_type', value: props.link_type ? props.link_type : 'imageToProduct'},
        {name: 'link_from', value: media.media_id},
        {name: 'link_to', value: props.parent_id},
        {name: 'link_field', value: 'link_published'},
        {name: 'link_value', value: value === true ? true : 'false'},
      ]
      console.log("Params", params);
      props[`${props.location}LoadState`](params, 'links', 'reload.product');

      props.notifyUser({icon: 'check', message: `${media.media_caption ? media.media_caption : media.media_file_upload_name} has been ${value === true ? 'published' : 'unpublished'}`});
    }else{
      props.notifyUser({icon: 'alert', message: `${media.media_caption ? media.media_caption : media.media_file_upload_name} could not be ${value === true ? 'published' : 'unpublished'}`});
    }

  }

  function loadRemoveMedia(media_id) {
    if(media_id && parseInt(media_id) > 0) {
      const media = props.media.find(m => m.media_id === media_id);
      props.setModal(<ModalConfirmation
                      {...props}
                      message={`<h3>Remove ${media.media_caption ? media.media_caption : media.media_file_upload_name}?</h3>
                                <p>Are you sure you wish to remove this ${['jpg', 'png', 'gif'].includes(media.media_file_tag) ? 'image' : media.media_file_tag}?`}
                      actionAccept={()=>{removeMedia(media)}}
                      />);
    }else{
      props.notifyUser({icon: 'alert', message: `${media.media_caption ? media.media_caption : media.media_file_upload_name} could not be removed.`});
    }

  }

  function removeMedia(media) {
    props.setModal(null);
    const params = [
      {name: 'process', value: 'update_links'},
      {name: 'user_id', value: props.userID},
      {name: 'token', value: props.token},
      {name: 'link_type', value: props.link_type ? props.link_type : 'imageToProduct'},
      {name: 'link_from', value: media.media_id},
      {name: 'link_to', value: props.parent_id},
      {name: 'link_field', value: 'link_removed'},
      {name: 'link_value', value: true},
    ]

    console.log("REMOVE PARAMS", params);
    props[`${props.location}LoadState`](params, 'links', 'reload.product');
    // props.notifyUser({icon: 'trash', message: `${media.media_caption ? media.media_caption : media.media_file_upload_name} has been removed.`});
  }

  return (
    <div className="media-set" ref={setRef}>
      {props.media && 
        props.setType === 'images' &&
        <MediaHero  {...props}
                    media={props.media[mediaHero]}
                    mediaFileName={props.media[mediaHero].media_file_name}
                    userID={props.userID ? props.userID : null}
                    token={props.token ? props.token : null}
                    location={props.location ? props.location : null}
                    isAdmin={props.isAdmin === true ? true : false}
                    actionRemove={loadRemoveMedia}
                    actionEdit={loadEditMedia}
                    actionPublish={loadPublishMedia}
                    imgRef={heroRef} />
      }
      {Array.isArray(props.media) && 
        (((props.setType === 'images' || props.setType === 'image') && props.media.length > 1) ||
          ((props.setType !== 'images' && props.setType !== 'image') && props.media.length > 0)) &&
        <MediaCollection media={props.media}
                          setType={props.setType}
                          mediaHero={(props.setType === 'images' || props.setType === 'image' ) ? 
                                      mediaHero : 
                                      null}
                          userID={props.userID ? props.userID : null}
                          token={props.token ? props.token : null}
                          location={props.location ? props.location : null}
                          isAdmin={props.isAdmin === true ? true : false}
                          mediaType="media"
                          altClass="media-set-nav"
                          action={setHero} />
      }
    </div>
  )
}
