import React, { Fragment } from 'react';

import CoreRule from 'components/atoms/CoreRule';
import CardControl from 'components/molecules/CardControl';

export default (props) => {

  return (
    <div className="card-info">
      <div className={`card-content${props.className ? ` ${props.className}` : ''}`}>{props.children}</div>
      {props.editable &&
        <Fragment>
          <CoreRule className="nopad" />
          <CardControl actionAdd={props.actionAdd} actionEdit={props.actionEdit} />
        </Fragment>
      }
    </div>
  )
}
