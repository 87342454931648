// SHARED METHODS FOR REDUCERS:

export function removeStateValue(rState, key) {
  let newState = rState;
  if(key.match(/\./g)) {
    const keyNodes = key.split('.');
    keyNodes.map((node, index) => {
      if(node.match(/\[(.*?)\]/g)) {
        let aNodes = node.split(/\[(.*?)\]/).filter(node => node !=='');;
        aNodes.map((aNode, aIndex) => {
          if(Number.isInteger(aNode) || (aNode !== '' && !aNode.match(/\D/))) {
            if(Array.isArray(newState) && newState[parseInt(aNode)]) {
              if(newState &&
                (index + 1 < keyNodes.length || aIndex + 1 < aNodes.length)) {
                newState = newState[parseInt(aNode)];
              }else if(newState &&
                        index + 1 === keyNodes.length &&
                        aIndex + 1 === aNodes.length) {
                newState.splice(parseInt(aNode), 1);
              }else if(!newState || !newState[parseInt(aNode)]) {
                newState = false;
              }
            }else{
              newState = false;
            }
          }else if (aNode && aNode !== ''){
            if(newState[aNode] &&
                (index + 1 < keyNodes.length || aIndex + 1 < aNodes.length)) {
                  newState = newState[aNode];
            }else if(newState[aNode] &&
                      index + 1 === keyNodes.length &&
                      aIndex + 1 === aNodes.length) {
                  delete newState[aNode];
            }else{
              newState = false;
            }
          }
          return true;
        })
      }else{
        if(newState && index+1 < keyNodes.length && newState[node]) {
          newState = newState[node];
        }else if(newState && index+1 === keyNodes.length && newState[node]){
          delete newState[node];
        }else{
          newState = false;
        }
      }
      return true;
    })
  }else{
    if(key.match(/\[(.*?)\]/g)) {
      let aNodes = key.split(/\[(.*?)\]/).filter(node => node !=='');
      aNodes.map((aNode, aIndex) => {
        if(Number.isInteger(aNode) || (aNode !== '' && aNode.match("^[0-9]+$"))) {
          if(newState && Array.isArray(newState) && newState[parseInt(aNode)]) {
            if((aIndex + 1) < aNodes.length) {
                newState = newState[parseInt(aNode)];
            }else if(aIndex + 1 === aNodes.length) {
              newState.splice(parseInt(aNode), 1);
            }else if(!newState || !newState[parseInt(aNode)]) {
              newState = false;
            }
          }else{
            newState = false;
          }
        }else if (aNode && aNode !== ''){
          if(newState[aNode] && ((aIndex + 1) < aNodes.length)) {
                newState = newState[aNode];
          }else if(newState[aNode] && (aIndex + 1) === aNodes.length) {
                delete newState[aNode];
          }else{
            newState = false;
          }
        }
        return true;
      })
    }else if(newState[key]) {
      delete newState[key];
    }else{
      newState = false;
    }
  }
  if(newState) {
    return rState;
  }else{
    return false;
  }
}

export function setStateValue(rState, key, value) {
  let newState = rState;
  if(String(key).match(/\./g)) {
    const keyNodes = key.split('.');
    keyNodes.map((node, index) => {
      if(node.match(/\[(.*?)\]/g)) {
          newState = traverseArrayNode(newState, node, index+1 === keyNodes.length ? value : null);
      }else{
          newState = traverseNode(newState, node, index+1 === keyNodes.length ? value : null);
      }
      return true;
    });
  }else if(key) {
    if(key.match(/\[(.*?)\]/g)) {
        newState = traverseArrayNode(newState, key, value);
    }else{
        newState = traverseNode(newState, key, value);
    }
  }
  if(newState) {
    return rState;
  }else{
    return false;
  }

}

export function traverseArrayNode(state, node, value) {
  let newState = state;
  if(node.match(/\[(.*?)\]/g)) {
    
    const aNodes = node.split(/\[(.*?)\]/).filter(nd => nd !== '');
    // newState[aNodes[0]][parseInt(aNodes[1])] = value;
    aNodes.map((aNode, aIndex) => {
      if(aNode.match(/[^0-9]/g) && (newState[aNode] || newState[aNode] === 0)) {
        if(aIndex+1 === aNodes.length && value) {
          newState[aNode] = value;
        }
        newState =  newState[aNode];
      }else if(!aNode.match(/[^0-9]/g) && (newState[parseInt(aNode)] || newState[parseInt(aNode)] === 0)) {
        if(aIndex+1 === aNodes.length && value) {
          newState[parseInt(aNode)] = value;
        }
        newState = newState[parseInt(aNode)];
      }else if(aNode.match(/[^0-9]/g) && newState && !newState[aNode]) {
        newState[aNode] = aIndex+1 === aNodes.length ? 
              value ? 
                Object.prototype.toString.call(value) === '[object String]' ? '' :
                Object.prototype.toString.call(value) === '[object Number]' ? 0 :
                Object.prototype.toString.call(value) === '[object Boolean]' ? false :
                {} :
              {} : [];
        newState = newState[aNode];
      }else if(!aNode.match(/[^0-9]/g) && !newState[parseInt(aNode)]) {
        while(newState.length < parseInt(aNode)+1) {
          newState.push(
            aIndex+1 === aNodes.length ? 
            value ? 
              Object.prototype.toString.call(value) === '[object String]' ? '' :
              Object.prototype.toString.call(value) === '[object Number]' ? 0 :
              Object.prototype.toString.call(value) === '[object Boolean]' ? false :
              {} :
            {} : []
          );
        }
        
        if(aIndex+1 === aNodes.length && value) {
          newState[parseInt(aNode)] = value;
        }
        
        newState = newState[parseInt(aNode)];
      }
    })
    if(value) {
      switch (Object.prototype.toString.call(newState)) {
        case '[object Array]':
          newState.push(value);
          break;
        default:
          newState = value; 
      }
    }
    
    return newState;
  }
}

export function traverseNode(state, node, value = null) {
  let newState = state;
  if(newState[node]) {
    if(value) {
      newState[node] = value;
    }
    newState = newState[node];
  }else{
    newState[node] = 
      value ? 
        value : {};
    newState = newState[node];
  }
  return newState;
}

export function assign(obj, path, value) {
    var schema = obj;  // a moving reference to internal objects within obj
    var pList = path.split('.');
    var len = pList.length;
    const valueType = Object.prototype.toString.call(value).replace('[object ', '').replace(']', '').toLowerCase();
    for(var i = 0; i < len; i++) {
      var elem = pList[i];
      if(String(elem).match(/\[(.*?)\]/g)) {
        const elemNodes = elem.replace(']', '').split('[');
        
        for(var j = 0; j < elemNodes.length; j++) {
          if(j > 0 && !isNaN(parseInt(elemNodes[j]))) {
            elemNodes[j] = parseInt(elemNodes[j]);
          }
        }
        
        elemNodes.map((elemNode, index, array) => {
          if(!schema[elemNode] || i+1 === len) {
            schema[elemNode] = 
              index + 1 < array.length ? [] :
                i+1 === len ? 
                  valueType === 'object' ? {...value} :
                  valueType === 'array' ?  [...value] :
                  value : {}
          }
          schema = schema[elemNode];
        })
      }else{
        if( !schema[elem] || i+1 === len ) {
          schema[elem] = 
            i+1 === len ? 
              valueType === 'object' ? {...value} :
              valueType === 'array' ?  [...value] :
              value : {}
        }
        schema = schema[elem];
      }
  }
}
