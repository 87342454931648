import React, { Fragment } from 'react';

import CoreRule from 'components/atoms/CoreRule';
import CardControl from 'components/molecules/CardControl';

export default (props) => {
  return (
    <div className={`card${props.className ? ` ${props.className}` : ''}`}>
      {props.title && <div className="card-title">{props.title}</div>}
      <div className="card-content">
        {props.children}
      </div>
      {props.editable &&
        <Fragment>
          <CoreRule className={props.classRule} />
          <CardControl actionAdd={props.actionAdd} actionEdit={props.actionEdit} />
        </Fragment>
      }
    </div>
  )
}
