import React from 'react';
import PagePublic from 'components/templates/PagePublic';

// import Store from 'components/organisms/Store';
import Home from 'components/organisms/Home';

export default () => {
  return (
    <PagePublic >
      <Home />
    </PagePublic>
  )
}
