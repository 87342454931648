import React from 'react';

import IconSVG from 'components/icons/IconSVG';

export default (props) => {
  return (
    <div className={`icon-token ${props.className ? props.className : ''}${props.action ? ' action' : ''}${props.disabled ? ` disabled` : '' }`}
      style={props.style}
          onClick={props.action && props.disabled !== true ? props.action : null }
          ref={props.iconRef ? props.iconRef : null}>
      <IconSVG icon={props.icon} />
      {props.content && <div className="icon-token-content">{props.content}</div>}
      {props.tooltip && <div className="tooltip">{props.tooltip}</div>}
    </div>
  )
}
