import React, {useEffect, useState, Fragment} from 'react';

import IconSVG from 'components/icons/IconSVG';
import Badge from 'components/atoms/Badge';
import MessageList from 'components/profiles/messages/MessageList';

export default (props) => {
	const [languageCode, setLanguageCode] = useState(null);
	const [approvals, setApprovals] = useState(null);
	const [approval, setApproval] = useState(null);
	const [messages, setMessages] = useState(null);
	const [messageList, setMessageList] = useState(null);
	const [showMessages, setShowMessages] = useState(null);
	const [status, setStatus] = useState(null);
	const [text, setText] = useState(null);
	const [icon, setIcon] = useState(null);
	const [statusClass, setStatusClass] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(props.languageCode && 
			props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}else if(!props.languageCode && !languageCode) {
			setLanguageCode('en');
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(Array.isArray(props.objGetValue(props, `approvals`)) && 
			props.approvals.length > 0) {
				const ordered = props.approvals.sort((a, b) => { 
									return props.convertISODate(a.status_modified) > props.convertISODate(b.status_modified) ?
										1 : -1;
								});
				
			if(ordered !== approvals) {
				setApprovals(ordered);	
			}
			
		}
	}, [props.approvals]);
	
	useEffect(() => {
		if(Array.isArray(approvals) &&
			approvals.length > 0 &&
			approvals[0] !== approval) {
			setApproval(approvals[0]);
		}
	}, [approvals]);
	
	useEffect(() => {
		if(languageCode && 
			props.objExists(approval, `${languageCode}.status_name`) && 
			approval[languageCode].status_name !== status) {
			setStatus(approval[languageCode].status_name);
		}
		
		if(Array.isArray(props.objGetValue(approval, `messages`)) &&
			approval.messages.length > 0 &&
			approval.messages !== messages) {
			setMessages(approval.messages);
		}
	}, [approval, languageCode]);
	
	useEffect(() => {
		if(Array.isArray(messages) 
			&& messages.length > 0) {
			const msgList = <MessageList {...props}
								messages={messages}
								title="Approval Comments"
								noReply={true} />;
			if(msgList !== messageList) {
				setMessageList(msgList);
			}
		}
	}, [messages]);
	
	useEffect(() => {
		if(showMessages && messageList) {
			props.setModal(messageList, false, true, ()=>{setShowMessages(false)});
		}
	}, [messageList, showMessages]);
	
	useEffect(() => {
		if(status) {
			
			switch(status) {
				case 'approved':
					setIcon('check');
					setStatusClass(' success-bg white');
					setText('Approved to Print');
					break;
				case 'proof requested':
					setIcon('document');
					setStatusClass(' caution-bg white bold all-caps');
					setText('Additional Proof Required!');
					break;
				case 'declined':
					setIcon('thumbs-down');
					setStatusClass(' error-bg white bold all-caps');
					setText('Declined');
					break;
				default:
					break;
			}
		}
	}, [status]);
	
	console.log("APPROVALS", approvals);
	
	return (
		status &&
		<Fragment>
			<div className={`item-status white${statusClass ? statusClass : ''}`}
					style={{
						padding: '.5em',
						display: 'flex',
						alignItems: 'center',
						marginRight: '0em'
					}}>
				{icon && <IconSVG icon={icon} style={{marginRight: '.5em'}} />}
				{text && props.capitalizeString(text, true)}
			</div>
			{messages && 
				<Badge 
				  type="messages" 
				  position='inline'
				  className={`info messages ${messages.length > 0 ? ` active` : ''}`}
				  action={()=>{setShowMessages(showMessages ? null : messages)}}
				  content={messages.length} />
			}	
		</Fragment>
	)
}