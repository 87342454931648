import React, {useEffect, useState, Fragment} from 'react';

import IconToken from 'components/icons/IconToken';
import FormFieldListItem from 'components/forms/form_display/FormFieldListItem';
import FormFieldList from 'components/forms/form_display/FormFieldList';
import DropZone from 'components/forms/elements/DropZone';
import ModalConfirmation from 'components/molecules/ModalConfirmation';

export default (props) => {

  function handleAddField(e) {
    let fieldset_id = null;
    let field_count = null;
    if(props.option.fieldsets &&
        Array.isArray(props.option.fieldsets) &&
        props.option.fieldsets.length > 0) {
      fieldset_id = props.option.fieldsets[0].fieldset_id;
      field_count = props.option.fieldsets[0].fields.length;
    }
    if(props.actionAddField) {
      props.actionAddField(fieldset_id, props.option.option_id, field_count);
    }
  }

  function handleEdit() {
    if(props.actionEdit) {
      props.actionEdit();
    }
  }

  function handleEditField(field = null, isSet = false, isConditional = false, fieldset_id, fieldset_type) {
    if(props.actionEditField) {
      props.actionEditField(field, isSet, isConditional, fieldset_id, fieldset_type);
    }
  }

  function loadRemove() {
    props.setModal(<ModalConfirmation {...props}
                                      message={`Are you sure you want to remove the option value <b>${props.option.option_name}</b>?`}
                                      actionAccept={handleRemove} />)
  }

  function handleRemove() {
    if(props.actionRemove) {
      props.actionRemove(props.option.option_id, props.group_id);
    }
  }

  return (
    <div className="form-field-list-item field-option active">
      <div className="form-field-list-item-row field-option">
        <div className="form-field-list-item-col">
          <b>{props.option.option_order}</b>
        </div>
        <div className="form-field-list-item-col field-adapt">
          <label>Name</label>{props.option.option_name}
        </div>
        <div className="form-field-list-item-col field-adapt">
          <label>Value</label>{props.option.option_value}
        </div>
        <div className="form-field-list-item-col field-ctl">
          <IconToken icon="conditional" className="btn" action={handleAddField} tooltip="Add Conditional Field" />
          <IconToken icon="edit" className="btn" action={handleEdit} tooltip="Edit" />
          <IconToken icon="trash" className="btn" tooltip="Remove" action={loadRemove} />
        </div>
      </div>
      {props.objExists(props.option, 'fieldsets') &&
        Array.isArray(props.option.fieldsets) &&
      <div className="form-field-list-item conditional">
          <div className="">Conditional Fields</div>
          {props.option.fieldsets.map((fieldset, index) => {
            return (
              <FormFieldList {...props}
                              isConditional={true}
                              title={fieldset.fieldset_name}
                              text_id={fieldset.text_id}
                              fields={fieldset.fields}
                              index={index}
                              fieldset_id={fieldset.fieldset_id}
                              actionAdd={props.actionAddField}
                              actionEdit={handleEditField}
                              actionUpdateFieldOrder={props.actionUpdateFieldOrder}
                              actionFieldsetName={props.actionFieldsetName} />

            )
          })}
      </div>
      }
    </div>
  )
}
