import React, {Fragment} from 'react';

import IconToken from 'components/icons/IconToken';
import ModalConfirmation from 'components/molecules/ModalConfirmation';
import FormRestrictionAddEdit from 'components/forms/common_forms/FormRestrictionAddEdit';

export default (props) => {
	
	function loadAddEdit() {
		props.setModal(
		  <FormRestrictionAddEdit {...props}
		  					  curLanguage={props.curLanguage}
							  organization_id={props.organization_id}
							  product_id={props.product_id}
							  restriction_id={props.restriction_id}
							  restriction={props.restriction}
							  actionCancel={()=>{props.setModal(null);}}
							  actionAddEdit={handleAddEdit} />);
	}
	
	function handleAddEdit(params) {
		if(props.actionAddEdit) {
			props.actionAddEdit(params);
		}
	}
	
	function loadRemove() {
		props.setModal(
		  <ModalConfirmation
			{...props}
			message={`Are you sure you want to remove this product restriction?`}
			actionAccept={handleRemove}/>
		)
	}
	
	function handleRemove(params) {
		if(props.actionRemove){
			if(params && Array.isArray(params)) {
				props.actionRemove(params);
			}else{
				props.setModal(null);
				props.actionRemove([
			    	{name: 'process', value: 'update_value'},
					{name: 'field_id', value: props.restriction_id},
					{name: 'field_name', value: 'restriction_removed'},
					{name: 'field_value', value: true}
				]);
			}
		}
	}
	
	return (
		<Fragment>
			<div className="column">
				{!props.restriction.group_name && !props.restriction.user_name && props.capitalizeString(props.details.product_name, true)}
				{props.restriction.group_name && props.restriction.group_name}
				{props.restriction.user_name && props.restriction.user_name}
			</div>
			<div className="column text-center">{props.restriction.restriction_priority}</div>
			<div className="column text-center">{props.restriction.restriction_quantity}</div>
			<div className="column text-center">{props.restriction.restriction_days}</div>
			<div className="column text-center">{props.restriction.restriction_date_start && props.dateDisplay(props.restriction.restriction_date_start)}</div>
			<div className="column text-center">{props.restriction.restriction_date_end && props.dateDisplay(props.restriction.restriction_date_end)}</div>
			{props.editable && <div className="column text-center">
				<h4>
					<IconToken icon="edit" action={loadAddEdit} />
					<IconToken icon="trash" action={loadRemove} />
				</h4>
			</div> }
		</Fragment>
	)
	
}