import React from 'react';

import Article from 'components/molecules/Article';

export default (props) => {
  return (
    <div className="article-set">
      {Array.isArray(props.articles) &&
        props.articles.map((article, index) => {
        return <Article {...props} 
                  article={article} 
                  key={`hpArtcl${index}`}
                  languageCode={props.languageCode ? props.languageCode : 'en'} />
      })}
    </div>
  )
}
