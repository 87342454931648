import React, {useEffect, useState} from 'react';

import Link from 'components/navigation/Link';
import Loader from 'components/atoms/Loader';
import ProjectList from 'components/profiles/projects/ProjectList';
import Button from 'components/forms/elements/Button';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [canEdit, setCanEdit] = useState(null);
  const [isSuper, setIsSuper] = useState(null);
  const [orders, setOrders] = useState(null);
  const [noPermission, setNoPermission] = useState(null);
  const [listArgs, setListArgs] = useState(null);
  const [reloading, setReloading] = useState(false);
  
  let intervalOrders;

  useEffect(() => {
    if(props.userID) {
      setUserID(props.userID)
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token) {
      setToken(props.token)
    }
  }, [props.token]);

  useEffect(() => {
    if(props.organizationID && organizationID !== props.organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    props.organizationName &&
    props.organizationName !== organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);
  
  useEffect(() => {
    if(props.languageCode && languageCode !== props.languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    if(props.userAccess && props.userAccess !== userAccess) {
      setUserAccess(props.userAccess);
    } 
  }, [props.userAccess]);
  
  useEffect(() => {
    props.canEdit &&
    props.canEdit !== canEdit &&
    setCanEdit(props.canEdit);
  }, [props.canEdit]);
  
  useEffect(() => {
    props.isSuper &&
    props.isSuper !== isSuper &&
    setIsSuper(props.isSuper);
  }, [props.isSuper]);

  useEffect(() => {
    if(props.orders &&
        props.orders !== orders){
        setOrders(props.orders);
        if(reloading){
          setReloading(false);  
        }
    }
  }, [props.orders, reloading]);
  
  useEffect(() => {
    if(props.listArgs && props.listArgs !== listArgs) {
      setListArgs(props.listArgs);
    }
  }, [props.listArgs]);
  
  useEffect(() => {
    if(props.noPermission && props.noPermission !== noPermission) {
      setNoPermission(props.noPermission);
    }
  }, [props.noPermission]);
  
  // useEffect(() => {
  //   if(Array.isArray(listArgs) &&
  //       props.actionListArgs) {
  //     props.actionListArgs(listArgs);
  //     props.adminSetVal('reload.orders', true);
  //   }
  // }, [listArgs]);
  
  // FUNCTIONS 
  function updateArg(arg = null) {
    let newArg = null;
    let currentArgs = listArgs;
    switch(arg) {
      case 'archived':
        newArg = {name: 'archived', value: true};
        break;
      default:
        break;
    }
    
    if(!currentArgs || !Array.isArray(currentArgs)) {
      currentArgs = [newArg];
    } else if(!currentArgs.includes(newArg)) {
      currentArgs = [...currentArgs ? currentArgs : [], newArg];
    }
    
    if(!arg || !currentArgs) {
      setListArgs(null);
      props.actionListArgs &&
      props.actionListArgs(null);
      setReloading(true);
    }else if(Array.isArray(currentArgs)) {
      setListArgs(currentArgs);
      props.actionListArgs &&
      props.actionListArgs(currentArgs);
      setReloading(true);
    }
  }
  
  // console.log("ADMIN ORDER LIST ADMIN", props.admin);
  return (
    <div>
      <h3>{`${![1,2].includes(organizationID) && 
              organizationName ? 
                organizationName : ""} Orders`}</h3>
      <div className="ctl header justify-right">
      {!listArgs || !listArgs.find(arg => arg.name === 'archived') ?
        <Button {...props} 
          label="Archived" 
          icon="archive" 
          btnClass="tiny info-bg" 
          btnAction={()=>{updateArg('archived')}} />
      :
        <Button {...props} 
          label="Current" 
          icon="gear" 
          btnClass="tiny info-bg" 
          btnAction={()=>{updateArg(null)}} />
      }
        
      </div>
      {orders && !reloading ?
        Array.isArray(orders) &&
          orders.length > 0 ?
          <ProjectList {...props}
                    orders={orders}
                    location='admin'
                    organizationID={organizationID}
                    organizationName={organizationName}
                    languageCode={languageCode}
                    isAdmin={true}
                    canEdit={canEdit}
                    isSuper={isSuper}
                    userAccess={userAccess}
                    />
             
        :
          <p>There are no orders in the system</p>
      :
        noPermission ?
          <p><b>Error:</b> No Permission</p>
        :
          <Loader label="Loading orders (this may take a few seconds)..."/>
      }
    </div>
  )
}
