import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router';

import * as utils from 'methods/site';
import FormDynamic from 'components/forms/FormDynamic';
import FileUpload from 'components/forms/elements/FileUpload';
import FileUploadPreview from 'components/forms/elements/FileUploadPreview';
import Button from 'components/forms/elements/Button';

export default (props) => {
  props = {...props.children, ...utils}
  const [reqIndex, setReqIndex] = useState(null);
  const [reqFieldsets, setReqFieldsets] = useState(null);
  const [reqForm, setReqForm] = useState(null);
  const [curLanguage, setCurLanguage] = useState(null);
  const [showUpload, setShowUpload] = useState(false);
  const [files, setFiles] = useState(false);
  const [formComplete, setFormComplete] = useState(null);
  const [quote, setQuote] = useState(false);
  const [verFields, setVerFields] =  useState(false);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    reqIndex >= 0 && curLanguage &&
    setVerFields([
      {field: `customItems[${reqIndex}].details.${curLanguage}.product_name`, test: 'notNull'},
      {field: `customItems[${reqIndex}].date_required`, test: 'notNull'},
      {field: `customItems[${reqIndex}].organization_reference`, test: 'notNull'},
      {field: `customItems[${reqIndex}].organization_group`, test: 'notNull'},
      {field: `customItems[${reqIndex}].product_quantity`, test: 'notNull'},
      // {field: `customItems[${reqIndex}].details.${curLanguage}.properties_object.page_count`, test: 'notNull'},
      // {field: `customItems[${reqIndex}].details.${curLanguage}.properties_object.paper_stock`, test: 'notNull'},
      // {field: `customItems[${reqIndex}].details.${curLanguage}.properties_object.finished_size_flat`, test: 'notNull'},
      // {field: `customItems[${reqIndex}].details.${curLanguage}.properties_object.finished_size_folded`, test: 'notNull'},
      // {field: `customItems[${reqIndex}].details.${curLanguage}.properties_object.folding`, test: 'notNull'},
      // {field: `customItems[${reqIndex}].details.${curLanguage}.properties_object.output_colour`, test: 'notNull'},
      // {field: `customItems[${reqIndex}].details.${curLanguage}.properties_object.binding`, test: 'notNull'},
      // {field: `customItems[${reqIndex}].details.${curLanguage}.properties_object.request_notes`, test: 'notNull'},
    ])
  }, [reqIndex, curLanguage]);

  useEffect(() => {
    if(props.objExists(props.cart, `customItems[${reqIndex}]`) && verFields) {
      let formTest = true;
      let formTestFields = [];
      Array.isArray(verFields) &&
      verFields.map(field => {
        if(field.test && field.test === 'notNull') {
          if(!props.objGetValue(props.cart, field.field)) {
            formTest = false;
            formTestFields.push(field.field);
          }
        }
      })
      setFormComplete({test: formTest, errFields: formTestFields});
    }else{
      setFormComplete({test: false});
    }
}, [verFields, props.cart]);

  useEffect(() => {
    if(reqIndex === null) {
      if(props.objExists(props.cart, 'customItems') &&
        Array.isArray(props.cart.customItems) &&
        props.cart.customItems.length > 0) {
          setReqIndex(props.cart.customItems.length);
      }else{
        setReqIndex(0);
      }
    }
  }, [props.cart.customItems]);

  useEffect(() => {
    props.objExists(props.site, 'persona.language_code') ?
      setCurLanguage(props.site.persona.language_code)
    : setCurLanguage('en');
  }, [props.site.persona])

  useEffect(() => {
    showUpload &&
    props.setModal(<FileUpload {...props} />);
  }, [showUpload, props.files.uploads]);

  useEffect(() => {
    if(props.cart && !props.cart.customItems) {
      if(sessionStorage.getItem('crtCSTM') &&
        sessionStorage.getItem('crtCSTM') !== 'undefined') {
          props.cartSetValue('customItems', JSON.parse(sessionStorage.getItem('crtCSTM')));
      }
    }else if(props.cart.customItems) {
      JSON.stringify(props.cart.customItems) !== sessionStorage.getItem('crtCSTM') &&
      sessionStorage.setItem('crtCSTM', JSON.stringify(props.cart.customItems));
    }
  }, [props.cart]);

  useEffect(() => {
    if(props.files.uploadComplete) {
      // let filesSet = files && Array.isArray(files) ? files : [];
      props.setModal(null);
      setShowUpload(false);

      if(props.files.uploads &&
      Array.isArray(props.files.uploads)) {
        // fielsSet = filesSet.splice(reqIndex, 0, props.files.uploads);
        // console.log("Here the files set", filesSet);
        setFiles(props.files.uploads);
        props.filesDeleteVal('uploads');
        sessionStorage.removeItem('filesUpl');
      }
    }
  }, [props.files, props.cart.customItems]);

  useEffect(() => {
    if(files && Array.isArray(files) &&
      !props.objExists(props.cart, `customItems[${reqIndex}].details[${curLanguage}].product_name`)) {
        props.cartSetValue(`customItems[${reqIndex}].details[${curLanguage}].media`, files);
        props.cartSetValue(`customItems[${reqIndex}].details[${curLanguage}].product_name`, files[0].media_file_upload_name);
      }
  }, [files]);

  useEffect(() => {
    if(curLanguage && reqIndex >= 0) {
      const fieldsets =
        [
          {fieldset_name: `Custom Request ${reqIndex}`,
            fields: [
              {field_type: 'switch',
                field_name: `customItems[${reqIndex}].request_quote`,
                field_label: 'Request Quote',
                field_placeholder: 'Request a Quote before ordering',
                field_value: props.objExists(props.cart, `customItems[${reqIndex}].request_quote`) ?
                                props.cart.customItems[reqIndex].request_quote : false,
                field_required: false,
                field_validate: false,
                field_order: 0,
              },
              {field_type: 'text',
                field_name: `customItems[${reqIndex}].details.${curLanguage}.product_name`,
                field_label: 'Name',
                field_placeholder: 'Name of product to print',
                field_value: props.objExists(props.cart, `customItems[${reqIndex}].details.${curLanguage}.product_name`) ?
                                props.cart.customItems[reqIndex].details[curLanguage].product_name : null,
                field_required: true,
                field_validate: true,
                field_order: 1,
              },
              {field_type: 'date',
                field_name: `customItems[${reqIndex}].date_required`,
                field_label: 'Date Required',
                field_placeholder: 'When do you need it?',
                field_value: props.objExists(props.cart, `customItems[${reqIndex}].date_required`) ?
                                props.cart.customItems[reqIndex].date_required : null,
                field_required: true,
                field_validate: true,
                field_order: 2,
              },
              {field_type: 'text',
                field_name: `customItems[${reqIndex}].organization_reference`,
                field_label: 'Cost Centre',
                field_placeholder: 'Cost Centre Code',
                field_value: props.objExists(props.cart, `customItems[${reqIndex}].organization_reference`) ?
                                props.cart.customItems[reqIndex].organization_reference : null,
                field_required: true,
                field_validate: true,
                field_order: 3,
              },
              {field_type: 'select',
                field_name: `customItems[${reqIndex}].organization_group`,
                field_label: 'Company Code',
                field_placeholder: 'Company Code',
                field_value: props.objExists(props.cart, `customItems[${reqIndex}].organization_group`) ?
                                props.cart.customItems[reqIndex].organization_group : null,
                field_required: true,
                field_validate: true,
                option_groups: [
                  {options: [
                    {option_name: 'Boehringer Ingelheim Canada', option_value: 'BI Canada'},
                    {option_name: 'Boehringer Ingelheim Animal', option_value: 'BI Animal'},
                    ]
                  }
                ],
                field_order: 4,
              },
              {field_type: 'number',
                field_name: `customItems[${reqIndex}].product_quantity`,
                field_label: 'Quantity',
                field_placeholder: 'Product Quantity',
                field_value: props.objExists(props.cart, `customItems[${reqIndex}].product_quantity`) ?
                                props.cart.customItems[reqIndex].product_quantity : null,
                field_required: true,
                field_validate: true,
                field_order: 5,
              },
              {field_type: 'number',
                field_name: `customItems[${reqIndex}].details.${curLanguage}.properties_object.page_count`,
                field_label: 'Page Count',
                field_placeholder: 'Number of pages in file',
                field_value: props.objExists(props.cart, `customItems[${reqIndex}].details.${curLanguage}.properties_object.page_count`) ?
                                props.cart.customItems[reqIndex].details[curLanguage].properties_object.page_count : null,
                field_required: true,
                field_validate: true,
                field_order: 6,
              },
              {field_type: 'select',
                field_name: `customItems[${reqIndex}].details.${curLanguage}.properties_object.paper_stock`,
                field_label: 'Stock',
                field_placeholder: 'Select Paper Stock',
                field_value: props.objExists(props.cart, `customItems[${reqIndex}].details.${curLanguage}.properties_object.paper_stock`) ?
                                props.cart.customItems[reqIndex].details[curLanguage].properties_object.paper_stock : null,
                field_required: true,
                field_validate: true,
                option_groups: [
                  {options: [
                    {option_name: '80lb silk text', option_value: '80lb silk text'},
                    {option_name: '60lb offset', option_value: '60lb offset'},
                    {option_name: '12pt gloss cover', option_value: '12pt gloss cover'},
                    ]
                  }
                ],
                field_order: 7,
              },
              {field_type: 'text',
                field_name: `customItems[${reqIndex}].details.${curLanguage}.properties_object.finished_size_flat`,
                field_label: 'Finished Size - Flat',
                field_placeholder: 'Finished size when flat (w" x h")',
                field_value: props.objExists(props.cart, `customItems[${reqIndex}].details.${curLanguage}.properties_object.finished_size_flat`) ?
                                props.cart.customItems[reqIndex].details[curLanguage].properties_object.finished_size_flat : null,
                field_required: true,
                field_validate: true,
                field_order: 8,
              },
              {field_type: 'text',
                field_name: `customItems[${reqIndex}].details.${curLanguage}.properties_object.finished_size_folded`,
                field_label: 'Finished Size - Folded',
                field_placeholder: 'Folded size if applicable (w" x h")',
                field_value: props.objExists(props.cart, `customItems[${reqIndex}].details.${curLanguage}.properties_object.finished_size_folded`) ?
                                props.cart.customItems[reqIndex].details[curLanguage].properties_object.finished_size_folded : null,
                field_required: false,
                field_validate: true,
                field_order: 9,
              },
              {field_type: 'radio',
                field_name: `customItems[${reqIndex}].details.${curLanguage}.properties_object.folding`,
                field_label: 'Folding',
                field_value: props.objExists(props.cart, `customItems[${reqIndex}].details.${curLanguage}.properties_object.folding`) ?
                                props.cart.customItems[reqIndex].details[curLanguage].properties_object.folding : 'None',
                field_required: true,
                field_validate: true,
                option_groups: [
                  {option_group_name: 'Select Fold',
                    options: [
                    {option_name: 'None', option_value: 'None'},
                    {option_name: 'In Half', option_value: 'In Half'},
                    {option_name: 'Letter', option_value: 'Letter'},
                    {option_name: '3 Fold Gateway', option_value: '3 Fold Gateway'},
                    ]
                  }
                ],
                field_order: 10,
              },
              {field_type: 'radio',
                field_name: `customItems[${reqIndex}].details.${curLanguage}.properties_object.output_colour`,
                field_label: 'Colour',
                field_value: props.objExists(props.cart, `customItems[${reqIndex}].details.${curLanguage}.properties_object.output_colour`) ?
                                props.cart.customItems[reqIndex].details[curLanguage].properties_object.output_colour : 1,
                field_required: true,
                field_validate: true,
                option_groups: [
                  {option_group_name: 'Select Colour Option',
                    options: [
                    {option_name: 'Colour', option_value: 'Colour'},
                    {option_name: 'Black & White', option_value: 'B&W'},
                    ]
                  }
                ],
                field_order: 11,
              },
              {field_type: 'radio',
                field_name: `customItems[${reqIndex}].details.${curLanguage}.properties_object.binding`,
                field_label: 'Binding',
                field_value: props.objExists(props.cart, `customItems[${reqIndex}].details.${curLanguage}.properties_object.binding`) ?
                                props.cart.customItems[reqIndex].details[curLanguage].properties_object.binding : 1,
                field_required: true,
                field_validate: true,
                option_groups: [
                  {option_group_name: 'Select Binding if Applicable',
                    options: [
                    {option_name: 'None', option_value: 'None'},
                    {option_name: 'Saddle Stitch', option_value: 'Saddle Stitch'},
                    {option_name: 'Corner Stitch', option_value: 'Corner Stitch'},
                    {option_name: 'Cerlox', option_value: 'Cerlox'},
                    {option_name: 'Wire-O', option_value: 'Wire-O'},
                    {option_name: 'Coil', option_value: 'Coil'},
                    ]
                  }
                ],
                field_order: 12,
              },
              {field_type: 'textarea',
                field_name: `customItems[${reqIndex}].details.${curLanguage}.properties_object.request_notes`,
                field_label: 'Special Intstruction',
                field_placeholder: 'Any additional information that may help us?',
                field_value: props.objExists(props.cart, `customItems[${reqIndex}].details.${curLanguage}.properties_object.request_notes`) ?
                                props.cart.customItems[reqIndex].details[curLanguage].properties_object.request_notes : null,
                field_required: true,
                field_validate: true,
                field_order: 13,
              },

            ]
          }
        ];
      setReqFieldsets(fieldsets);
    }
  }, [curLanguage, reqIndex, props.cart]);

  useEffect(() => {
    reqFieldsets &&
    setReqForm(
      <FormDynamic
        content={reqFieldsets}
        name="custom-request"
        fieldOnChange={fieldOnChange}
        closable={false}
        unmountOnClick={false}
        cancelable={false}
        includeControls={false}
        multiStep={false}
        formClass=""
        returnObject={true}
        valActions={{isEmail: props.isEmail}}
      />
    )
  }, [reqFieldsets]);

  // FUNCTIONS
  function fieldOnChange(field) {
    if(field.target) {
      props.cartSetValue(field.target.name, field.target.value);
    }else if(field.name && field.value && props.objGetValue(props.cart, field.name) !== field.value) {
      props.cartSetValue(field.name, field.value);
    }
  }

  function editFile(e) {
    
  }

  function removeFile(e) {
    const newFiles = files.slice(e+1);
    if(Array.isArray(newFiles) && newFiles.length > 0) {
      setFiles(newFiles);
    }else{
      setFiles(null);
    }

  }
  
  return(
    <div className="profile request">
      {redirect && <Redirect to={redirect} />}
      <div className="card">
        <h2>Custom Request</h2>
        <p>Have a print file you need printed? Need a quote on a print job?</p>
        <p>Tell us what you need, submit your file and we'll take care of everything!</p>
      </div>
      {files && Array.isArray(files) && files.length > 0 &&
        <div className="file-list">
        {files.map((file, index)=> {
              return <FileUploadPreview
                        {...props}
                        src={['png', 'jpg', 'bmp', 'gif'].includes(file.media_file_tag) &&
                                `http://core.accellgraphics.ca/assets/media/${file.media_file_name}`}
                        icon={!['png', 'jpg', 'bmp', 'gif'].includes(file.media_file_tag) &&
                              file.media_file_tag}
                        file={file}
                        actionRemove={removeFile}
                        index={index}
                        location='uploads'
                      />
        })}
        </div>
      }
      {reqForm &&
        <div className="form-row">
          <Button btnAction={()=> {setShowUpload(true)}}
                  disabled={showUpload ? true : false}
                  label={`Upload ${files ? `Another ` : ''}File`}
                  icon="save"
                  btnClass="info-bg" />
        </div>
      }
      {reqForm}
      {reqForm &&
        <div className="form-row">
          <Button btnAction={()=> {setRedirect('/custom-request/project/1')}}
                  disabled={formComplete && formComplete.test ? false : true}
                  label={quote ? "Request Quote" : "Submit Job"}
                  icon="arrow"
                  iconClass="rotate90"
                  iconRight={true}
                  btnClass={formComplete && formComplete.test ? 'success-bg' : 'disabled-bg'} />
        </div>
      }
    </div>
  )
}
