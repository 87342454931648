import React, {useEffect, useState}  from 'react';
import { useParams } from "react-router";

import PageInternal from 'components/templates/PageInternal';
import Admin from 'components/admin/Admin';

export default () => {

  return (
    <PageInternal>
      <Admin />
    </PageInternal>
  )
}
