import React, {useEffect, useState} from 'react';

import Checkbox from 'components/forms/elements/Checkbox';

export default (props) => {
  const [showAll, setShowAll] = useState(null);
  const [filters, setFilters] = useState(null);
  const [filtersActive, setFiltersActive] = useState(true);
  const [filter, setFilter] = useState(null);

  // HOOKS
  useEffect(() => {
    if(!filters &&
        props.activeFilters &&
        Array.isArray(props.activeFilters) &&
        props.activeFilters.length > 0 &&
        props.filter &&
        props.filter.filterOptions) {
      setShowAll(false);
      let filterState = {};
      Array.isArray(props.filter.filterOptions) &&
      props.filter.filterOptions.map(option => {
        filterState[option.option_value] = props.activeFilters.includes(option.option_value) ? true : false;
        props.activeFilters.includes(option.option_value) &&
        setFiltersActive(true);
      })
      setFilters(filterState);
    }else if(!filters){
      setShowAll(true);
    }
  }, [props.activeFilters, props.filter]);

  useEffect(() => {
    if(showAll && filters) {
      if(Object.keys(filters).length) {
        let filterUpdate = {};
        Object.keys(filters).map(key => {
          filterUpdate[key] = false;
        })
        setFilters(filterUpdate);
      }
    }
  }, [showAll]);

  useEffect(() => {
    if(filter && filters &&
      filters[filter.filterName] !== filter.filterValue) {
        let newFilters = filters;
        newFilters[filter.filterName] = filter.filterValue;
        let isActive = false;
        Object.entries(newFilters).length > 0 &&
        Object.entries(newFilters).map(filter => {
          if(filter[1] === true) {
            isActive = true;
          }
        });
        if(isActive) {
          setShowAll(false);
          setFiltersActive(true);
        }else{
          setShowAll(true);
          setFiltersActive(false);
        }
        setFilters(newFilters);
        setFilter(null);
      }
  }, [filter, filters]);

  useEffect(() => {
    if(filters && Object.entries(filters).length > 0) {
      let activeFilters = false;
      Object.entries(filters).map(filter => {
        if(filter[1] === true) {
          activeFilters = true;
        }
      })
      if(showAll && activeFilters) {
        setShowAll(false);
      }
    }
  }, [filters]);


  // FUNCTIONS
  function selectAll(name, value) {
    if(props.actionShowAll && value === true) {
      props.actionShowAll(name, value, props.filter.filterName);
    }
    if(value) {
      setShowAll(true);
    }else{
      setShowAll(false);
    }
  }

  function handleClick(name, value) {
    if(props.actionSelectFilter) {
      props.actionSelectFilter(name, value, props.filter.filterName);
    }
    setFilter({filterName: name, filterValue: value});
  }

  return(
    <div className="filter-set">
      <div className="filter-set-label">
        {props.filter.filterLabel}
      </div>
      <div className="checkbox-set">
        <Checkbox key={`chckbxX`}
                  checked={showAll}
                  option_name="All"
                  option_value={null}
                  onClick={selectAll}
                  disabled={showAll} />

      {props.filter.filterOptions &&
        Array.isArray(props.filter.filterOptions) &&
        props.filter.filterOptions.map((option, index) => {
          return <Checkbox key={`chkbx${index}`}
                            checked={filters && filters[option.option_value] ? true : false}
                            option_name={option.option_name}
                            option_value={option.option_value}
                            onClick={handleClick} />

        })
      }
      </div>
    </div>
  )
}
