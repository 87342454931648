import React from 'react';

export default (props) => {
  function runActions(actions) {
    if(Array.isArray(actions)) {
      actions.map(action => {
        fireAction(action);
      })
    }else{
      fireAction(actions);
    }
  }

  function fireAction(action) {
    if(action.action) {
      action.action();
    }else if(action.action_name && props[action.action_name]) {
      if (action.action_props) {
        props[action.action_name](...action.action_props)
      }else{
        props[action.action_name]();
      }
    }else{
      console.log(action.action_name, " : no such action");
    }
  }

  return (
    <div className="menu-set-action" onClick={()=>{runActions(props.actions)}}>
        {props.name}
     </div>
  )
}
