import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';

import FormUserAdd from 'components/forms/common_forms/FormUserAdd';
import Loader from 'components/atoms/Loader';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [users, setUsers] = useState(null);
  const [organizations, setOrganizations] = useState(null);
  const [userRoles, setUserRoles] = useState(null);
  const [component, setComponent] = useState(null);
  const [saveActive, setSaveActive] = useState(false);
  const [userSubmitted, setUserSubmitted] = useState(false);
  const [redirect, setRedirect] = useState(null);

  // HOOKS
  useEffect(() => {
    props.adminDeleteVal('user');
    setRedirect(null);
    
    return() => {
      props.adminDeleteVal('user');
      setRedirect(null);
    }
  }, []);
  
  useEffect(() => {
    if(props.userID && props.userID !== userID) {
      setUserID(props.userID)
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token && props.token !== token) {
      setToken(props.token)
    }
  }, [props.token]);

  useEffect(() => {
    if(props.organizationID && organizationID !== props.organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    props.organizationName &&
    props.organizationName !== organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);
  
  useEffect(() => {
    if(props.languageCode && languageCode !== props.languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    if(props.userAccess && props.userAccess !== userAccess) {
      setUserAccess(props.userAccess);
    } 
  }, [props.userAccess]);
  
  useEffect(() => {
    if(organizationID === 1 && 
      props.objExists(props.admin, 'organizations') &&
      Array.isArray(props.admin.organizations) && 
      props.admin.organizations.length > 0) {
        setOrganizations(props.admin.organizations);
    }
  }, [organizationID, props.admin.organizations]);
  
  useEffect(() => {
    if(props.objExists(props.admin, 'user_roles') &&
      Array.isArray(props.admin.user_roles) && 
      props.admin.user_roles.length > 0) {
        setUserRoles(props.admin.user_roles);
    }
  }, [props.admin.user_roles]);
  
  useEffect(() => {
    if(props.objExists(props.admin, 'users') &&
      Array.isArray(props.admin.users) && 
      props.admin.users.length > 0) {
        setUsers(props.admin.users);
    }
  }, [props.admin.users]);
  
  // FUNCTIONS
  function handleSubmit() {
    setUserSubmitted(true);
  }

  function handleSuccess(user_id) {
    if(user_id > 0) {
      setUserSubmitted(false);
      props.adminDeleteVal('user');
      setRedirect(`/admin/users/user/${user_id}`);
    }
  }

  console.log("ADMIN ADD USER", props.admin);
  return (
    <div>
      {redirect && <Redirect to={redirect} />}
      <FormUserAdd {...props}
                    userId={userID}
                    token={token}
                    organizationID={organizationID}
                    organizationName={organizationName}
                    organizations={organizations}
                    users={users}
                    userRoles={userRoles}
                    cancelable={false}
                    actionSubmit={handleSubmit}
                    actionSuccess={handleSuccess}
                    location='admin' />
      {userSubmitted && <Loader isOverlay={true} label="Creating user..." />}              
    </div>
  )
}
