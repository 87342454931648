import React, {useEffect, useState} from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

export default (props) => {
	const [links, setLinks] = useState(null);
	const [styles, setStyles] = useState(null);
	
	// HOOKS 
	useEffect(() => {
		if(props.links) {
			let pgLinks = [];
			props.links.forEach(link => {
				  if(link.rel === 'stylesheet') {
					  pgLinks.push(<link href={`${link.href}`} rel="stylesheet" />);
				  }
			})
			if(pgLinks.length > 0) {
				setLinks(pgLinks);
			}
		}
	}, [props.links]);
	
	useEffect(() => {
		if(props.styles) {
			let pgStyles = [];
			props.styles.forEach(style => {
				pgStyles.push(<style>{style.innerHTML}</style>);
			});
			if(pgStyles.length > 0) {
				setStyles(pgStyles);
			}
		}
	}, [props.styles]);
	
	return (
		<html>
			<head>
				<meta charset="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<title>{`${props.title ? props.title : 'AE Core'} :: Print`}</title>
				{links}
				{styles}
				<style dangerouslySetInnerHTML={{__html: `
					body, body * {
						color: rgb(0,0,0) !important;
						fill: rgb(0,0,0) !important;
					}
					
					body .icon-svg {
						display: none !important;
					}
					
					body {
						padding: 36px;
					}
					
					.no-print {
						display: none !important;
					}
					
					body .card, body .card-content-list {
						border: .125em solid rgb(0,0,0);
						padding: 18px;
						background-color: rgb(256,256,256);
					}
					
					.card-set {
						margin-bottom: 1em;	
					}
					
					body img {
						-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
						filter: grayscale(100%);
					}
				`}}></style>
			</head>
			<body>
				{props.children}
			</body>
		</html>
	)
}