import * as utils from 'methods/site.js';


// Common Option Sets for Dynamic formSubmit
export const optionsCountries = [
  {option_name: 'Canada', option_value: 'CA'},
  {option_name: 'USA', option_value: 'US'}];

export const optionsProvinces = [
  {option_name:'Alberta', option_value: 'AB'},
  {option_name:'British Columbia', option_value: 'BC'},
  {option_name:'Manitoba', option_value: 'MB'},
  {option_name:'New Brunswick', option_value: 'NB'},
  {option_name:'Newfoundland and Labrador', option_value: 'NL'},
  {option_name:'Northwest Territories', option_value: 'NT'},
  {option_name:'Nova Scotia', option_value: 'NS'},
  {option_name:'Nunavut', option_value: 'NU'},
  {option_name:'Ontario', option_value: 'ON'},
  {option_name:'Prince Edward Island', option_value: 'PE'},
  {option_name:'Quebec', option_value: 'QC'},
  {option_name:'Saskatchewan', option_value: 'SK'},
  {option_name:'Yukon', option_value: 'YT'},
  ];

export const optionsStates = [
  {option_name: 'Alabama', option_value: 'AL'},
  {option_name: 'Alaska', option_value: 'AK'},
  {option_name: 'Arizona', option_value: 'AZ'},
  {option_name: 'Arkansas', option_value: 'AR'},
  {option_name: 'California', option_value: 'CA'},
  {option_name: 'Colorado', option_value: 'CO'},
  {option_name: 'Connecticut', option_value: 'CT'},
  {option_name: 'Delaware', option_value: 'DE'},
  {option_name: 'Florida', option_value: 'FL'},
  {option_name: 'Georgia', option_value: 'GA'},
  {option_name: 'Hawaii', option_value: 'HI'},
  {option_name: 'Idaho', option_value: 'ID'},
  {option_name: 'Illinois', option_value: 'IL'},
  {option_name: 'Indiana', option_value: 'IN'},
  {option_name: 'Iowa', option_value: 'IA'},
  {option_name: 'Kansas', option_value: 'KS'},
  {option_name: 'Kentucky', option_value: 'KY'},
  {option_name: 'Louisiana', option_value: 'LA'},
  {option_name: 'Maine', option_value: 'ME'},
  {option_name: 'Maryland', option_value: 'MD'},
  {option_name: 'Massachusetts', option_value: 'MA'},
  {option_name: 'Michigan', option_value: 'MI'},
  {option_name: 'Minnesota', option_value: 'MN'},
  {option_name: 'Mississippi', option_value: 'MS'},
  {option_name: 'Missouri', option_value: 'MO'},
  {option_name: 'Montana', option_value: 'MT'},
  {option_name: 'Nebraska', option_value: 'NE'},
  {option_name: 'Nevada', option_value: 'NV'},
  {option_name: 'New Hampshire', option_value: 'NH'},
  {option_name: 'New Jersey', option_value: 'NJ'},
  {option_name: 'New Mexico', option_value: 'NM'},
  {option_name: 'New York', option_value: 'NY'},
  {option_name: 'North Carolina', option_value: 'NC'},
  {option_name: 'North Dakota', option_value: 'ND'},
  {option_name: 'Ohio', option_value: 'OH'},
  {option_name: 'Oklahoma', option_value: 'OK'},
  {option_name: 'Oregon', option_value: 'OR'},
  {option_name: 'Pennsylvania', option_value: 'PA'},
  {option_name: 'Rhode Islan', option_value: 'RI'},
  {option_name: 'South Carolina', option_value: 'SC'},
  {option_name: 'South Dakota', option_value: 'SD'},
  {option_name: 'Tennessee', option_value: 'TN'},
  {option_name: 'Texas', option_value: 'TX'},
  {option_name: 'Utah', option_value: 'UT'},
  {option_name: 'Vermont', option_value: 'VT'},
  {option_name: 'Virginia', option_value: 'VA'},
  {option_name: 'Washington', option_value: 'WA'},
  {option_name: 'West Virginia', option_value: 'WV'},
  {option_name: 'Wisconsin', option_value: 'WI'},
  {option_name: 'Wyoming', option_value: 'WY'}];

export const optionsCCType = [
  {option_name:'Visa', option_value: 'visa'},
  {option_name:'Mastercard', option_value: 'mastercard'},
  {option_name:'American Express', option_value: 'amex'},
]

export const optionsSides = [
  {option_name: 'Front', option_value: 'front'},
  {option_name: 'Back', option_value: 'back'},
  {option_name: 'Top', option_value: 'top'},
  {option_name: 'Bottom', option_value: 'bottom'},
  {option_name: 'Left', option_value: 'left'},
  {option_name: 'Right', option_value: 'right'},
  {option_name: 'Outside', option_value: 'outside'},
  {option_name: 'Inside', option_value: 'inside'}
]

export const optionsUserRoles = [
  {option_name: "Super User", option_value: 1},
  {option_name: "Administrator", option_value: 2},
  {option_name: "Admin", option_value: 3},
  {option_name: "User", option_value: 4},
  {option_name: "Observer", option_value: 5},
]

// Data forms for Dynamic Forms

export const fieldsLocation = [
  {field_type: 'text', field_name: 'location_name', field_label: 'Name', field_placeholder: 'Location Name', field_value: null, field_required: false, field_validate: true},
  {field_type: 'text', field_name: 'location_address_1', field_label: 'Address 1', field_value: null, field_required: true, field_validate: true},
  {field_type: 'text', field_name: 'location_address_2', field_label: 'Address 2', field_value: null, field_required: false, field_validate: false},
  {field_type: 'text', field_name: 'location_city', field_label: 'City', field_value: null, field_required: true, validate: true},
  {field_type: 'select', field_name: 'location_province', field_label: 'Province', field_value: null, field_required: true, field_validate: true, option_groups: [{options: optionsProvinces}]},
  {field_type: 'text', field_name: 'location_postal_code', field_label: 'Postal Code', field_value: null, field_required: true, validate: true},
  {field_type: 'select', field_name: 'location_country', field_label: 'Country', field_value: null, field_required: true, validate: true,
  option_groups: [{options: optionsCountries}]},
  {field_type: 'switch', field_name: 'location_default', field_label: 'Default Location', field_value: false, field_required: false, field_validate: false},
  ];

export const fieldsContact = [
  {field_type: 'text', field_name: 'contact_name_first', field_label: 'First Name', field_value: null, field_required: true, field_validate: true},
  {field_type: 'text', field_name: 'contact_name_last', field_label: 'Last Name', field_value: null, field_required: true, field_validate: true},
  {field_type: 'tel', field_name: 'contact_phone', field_label: 'Phone', field_value: null, field_required: true, field_validate: true},
  {field_type: 'number', field_name: 'contact_phone_ext', field_label: 'Phone Ext.', field_value: null, field_required: false, field_validate: true},
  {field_type: 'tel', field_name: 'contact_cell', field_label: 'Mobile', field_value: null, field_required: false, field_validate: true},
  {field_type: 'tel', field_name: 'contact_fax', field_label: 'Fax', field_value: null, field_required: false, field_validate: true},
  {field_type: 'email', field_name: 'contact_email', field_label: 'Email', field_value: null, field_required: true, field_validate: true}
]

export const fieldsContactEml = [
  {field_type: 'text', field_name: 'contact_name_first', field_label: 'First Name', field_value: null, field_required: true, field_validate: true},
  {field_type: 'text', field_name: 'contact_name_last', field_label: 'Last Name', field_value: null, field_required: true, field_validate: true},
  {field_type: 'email', field_name: 'contact_email', field_label: 'Email', field_value: null, field_required: true, field_validate: true}
]

export const fieldsPaymentCC = [
  {field_type: 'select', field_name: 'payment_cc_type', field_label: 'Card Type', placeholder: 'Credit Card Type', field_value: null, field_required: true, field_validate: true, option_groups: [{options: optionsCCType}]},
  {field_type: 'text', field_name: 'payment_cc_name', field_label: 'Cardholder Name', placeholder: 'Name on Card', field_value: null, field_required: true, field_validate: true},
  {field_type: 'cc-number', field_name: 'payment_cc_number', field_label: 'Card Number', field_value: null, field_required: true, field_validate: true},
  {field_type: 'cc-expiry', field_name: 'payment_cc_expiry', field_label: 'Expiry', field_placeholder: 'Expires (MM/YY)', field_value: null, field_required: true, field_validate: true},
  {field_type: 'cc-cvv', field_name: 'payment_cc_cvv', field_label: 'CVV/CID Code', field_placeholder: 'CVV/CID Security Code (000/0000)', field_value: null, field_required: true, field_validate: true},
]

export const mediaInfo = (index = null, location = null, locState = null, mediaValues = null) => {
  return (
    [
      {field_type: 'text',
      field_name: `${index && location ? `${location}[${index}].` : ''}media_caption`,
      field_label: 'Title',
      field_placeholder: 'Title or caption',
      field_value: locState &&
                    utils.objExists(locState, `${location}[${index}].media_caption`) ?
                    locState[location][index].media_caption :
                      utils.objExists(mediaValues, 'media_caption') ?
                        mediaValues.media_caption : null,
      field_required: false,
      field_validate: false,
      field_order: 1,
      field_focus: true,
      },
      {field_type: 'textarea',
      field_name: `${index && location ? `${location}[${index}].` : ''}media_description`,
      field_label: 'Description',
      field_placeholder: 'Media Description',
      field_value: locState &&
                    utils.objExists(locState, `${location}[${index}].media_description`) ?
                    locState[location][index].media_description :
                      utils.objExists(mediaValues, 'media_description') ?
                        mediaValues.media_description : null,
      field_required: false,
      field_validate: false,
      field_order: 2,
      },
      {field_type: 'switch',
      field_name: `${index && location ? `${location}[${index}].` : ''}media_frame`,
      field_label: 'Use Frame?',
      field_value: locState &&
                    utils.objExists(locState, `${location}[${index}].media_frame`) ?
                    locState[location][index].media_frame :
                      utils.objExists(mediaValues, 'media_frame') ?
                        mediaValues.media_frame : false,
      field_required: false,
      field_validate: false,
      field_order: 3,
      },
      {field_type: 'switch',
      field_name: `${index && location ? `${location}[${index}].` : ''}media_published`,
      field_label: 'Publish Media',
      field_value: locState &&
                    utils.objExists(locState, `${location}[${index}].media_published`) ?
                    locState[location][index].media_published :
                      utils.objExists(mediaValues, 'media_published') ?
                        mediaValues.media_published : true,
      field_required: false,
      field_validate: false,
      field_order: 4,
      },
      {field_type: 'switch',
      field_name: `${index && location ? `${location}[${index}].` : ''}media_default`,
      field_label: 'Default Image',
      field_value: locState &&
                    utils.objExists(locState, `${location}[${index}].media_default`) ?
                    locState[location][index].media_default :
                      utils.objExists(mediaValues, 'media_default') ?
                        mediaValues.media_default : false,
      field_required: false,
      field_validate: false,
      field_order: 5,
      },
    ])
}

export const moduleIcons = ['alert', 'attach', 'cart', 'chat', 'clock', 'compass', 'complete', 'date', 'draft', 'edit', 'email', 'eye', 'gear', 'hide', 'hold', 'image', 'info', 'link', 'lock', 'message', 'pin', 'phone', 'preview', 'print', 'node', 'save', 'disk', 'file', 'search', 'star', 'success', 'task', 'time', 'user'];

// INFORMATION MAPS
export const languageMap = [
  {language_id: 1, language_code: 'en', language_name: 'English',},
  {language_id: 2, language_code: 'fr', language_name: 'French',},
  {language_id: 3, language_code: 'kl', language_name: 'Klingon',},
]

export const commonPropertyMap = {
  product: {
    en: ['width', 'height', 'depth', 'material', 'weight', 'colours', 'finishing'],
    fr: ['largeur', 'hauteur', 'profondeur', 'matériel', 'poids', 'couleurs', 'finition'],
  }
}
