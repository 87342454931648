import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
//  requires installing react-html-parser :: npm install react-html-parser

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

export default (props) => {
  const [redirect, setRedirect] = useState(null);
  const [dimensions, setDimensions] = useState(null);
  // HOOKS
  useEffect(() => {
    if(props.aspectRatio) {
      const width = document.getElementById(`aecSlide${props.objExists(props, 'slide.slide_id') ? props.slide.slide_id : 'XXX'}`).offsetWidth;
      const factors = adjustAspect(props.aspectRatio);
      document.getElementById(`aecSlide${props.objExists(props, 'slide.slide_id') ? props.slide.slide_id : 'XXX'}`).style.height = `${width * factors.height}px`;
    }
  }, [props.aspectRatio, props.slide])

  function useLink(link) {
    if(link) {
      setRedirect(link);
    }
  }

  function handleClick(e) {
    if(props.slide.action) {
      props.slide.action(e);
    }else if(props.slide.slide_link) {
      setRedirect(props.slide.slide_link);
    }else{
      e.preventDefault();
    }
  }
  
  function adjustAspect(ratio) {
    const ratioValues = ratio.includes(':') ?
      ratio.split(':') :
      ratio.split('x');
    const ratioFactors = {width: parseFloat(ratioValues[0])/parseFloat(ratioValues[1]),
          height: parseFloat(ratioValues[1])/parseFloat(ratioValues[0])};
    return ratioFactors;
  }
  
  return (
    <div  id={`aecSlide${props.objExists(props, 'slide.slide_id') ? props.slide.slide_id : 'XXX'}`}
          className={`slide${props.slideActive ? ' slide-active' : ''}${props.slide.slide_link ? ' link' : ''}`} 
          onClick={handleClick}>
      {redirect && <Redirect to={redirect} /> }
      <div className={`slide-visual${props.slide.slide_style ? ` ${props.slide.slide_style}` : ''}`}
              onClick={props.objExists(props.slide, 'slide_url') ? () => {useLink(props.slide.slide_url)} : null}>

          {props.objExists(props.slide, `slide_html`) &&

            <div className={`slide-content${props.slide.slide_style ? ` ${props.slide.slide_style}` : ''}${props.slide.slide_position_x ? ` ${props.slide.slide_position_x}` : ''}${props.slide.slide_position_y ? ` ${props.slide.slide_position_y}` : ''}`}
              onClick={props.objExists(props.slide, 'slide_html') ? ()=>{useLink(props.slide.slide_url)} : null}>
              {ReactHtmlParser(props.slide.slide_html)}
            </div>
          }

          {props.objExists(props, 'slide.media.media_file_tag') &&
            props.objExists(props, 'slide.media.media_file_path') &&
            props.objExists(props, 'slide.media.media_file_name') &&
            props.mediaType(props.slide.media.media_file_tag) === 'image' &&
            <img src={`${props.localizeURL(props.slide.media.media_file_path)}${props.slide.media.media_file_name}`} 
                  className="slide-image" 
                  alt={props.objExists(props, 'slide.media.media_description') ? 
                          props.slide.media.media_file_description : 'Slideshow Slide'}/>}
          {props.objExists(props, 'slide.media.media_file_tag') &&
            props.objExists(props, 'slide.media.media_file_path') &&
            props.objExists(props, 'slide.media.media_file_name') &&
            props.mediaType(props.slide.media.media_file_tag) === 'video' &&
            <video className="slide-video" autoPlay loop muted>
              <source src={`${props.localizeURL(props.slide.media.media_file_path)}${props.slide.media.media_file_name}`} 
                      type={props.objExits(props, 'slide.media.media_file_type') ? props.slide.media.media_file_type : 'video/mp4'} />
            </video> }
      </div>
    </div>
  )
}
