import React, {useEffect, useState, useRef} from 'react';
import serialize from 'form-serialize';

import FormAlert from 'components/forms/FormAlert';

export default (props) => {
  const [submitted, setSubmitted] = useState(props.submitted);
  const [success, setSuccess] = useState(null);
  const [valueError, setValueError] = useState({});
  const [missingFields, setMissingFields] = useState([]);
  const [alertMsg, setAlertMsg] = useState(props.alertMsg);
  const [formFocus, setFormFocus] = useState(false);
  const [formChildren, setFormChildren] = useState(null);
  const [submit, setSubmit] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(null);

  const formRef = props.formRef ? props.formRef : useRef();

  useEffect(() => {
    const _keyListener = (e) => {
      keyListener(e);
    }
    window.addEventListener('keydown', _keyListener, true);
    return()=> {
      window.removeEventListener('keydown', _keyListener, true);
    }
  }, []);
  
  useEffect(() => {
    if(props.formSubmit === true && !submit) {
      setSubmit(true);
    }else if(!props.formSubmit && isSubmitted) {
      setIsSubmitted(false);
    }
  }, [props.formSubmit]);
  
  useEffect(() => {
    if(!isSubmitted && submit === true) {
      handleSubmit(null, formRef.current);
      setIsSubmitted(true);
      setSubmit(false);
      if(props.onComplete) {
        props.onComplete();
      }
    }else if(isSubmitted && submit) {
      setIsSubmitted(false);
      setSubmit(false);
      if(props.onComplete) {
        props.onComplete();
      }
    }
  }, [submit, isSubmitted]);
  
  function keyListener(e) {
    // TODO APP PROPPER EVENT LISTENER FOR KEY PRESS NAVIGATION
    // console.log("KEY: ", e.key, document.activeElement, formRef.current);
  }
  
  function handleSubmit(e = null, form = null) {
    let target;
    if(e !== null) {
      e.preventDefault();
      target = e.target;
    }else if(form){
      target = form;
    }
    
    if(target.tagName.toLowerCase() == "form") {
      setMissingFields([]);
      setValueError({});
      setSubmitted(true);
      setAlertMsg(null);
  
      if(!validateForm(target)) {
        return false;
      };
  
      let hiddenString = '';
      if(props.hidden) {
        Object.keys(props.hidden).map(key => {
          if(props.hidden[key]) {
            const thisValue =  props.hidden[key].toString();
            const valueString = thisValue.split(' ').join('+');
            return hiddenString += `&${key}=${valueString}`;
          }else{
            return null;
          }
        })
      }
      if(props.formType === 'file') {
      }
  
      // LOOK INTO A CLEANER FORM DATA TRANSFER
  
      let oData = [];
      const valueSource = ['input', 'select', 'textarea']
      for (var el of target.elements) {
        if(valueSource.includes(el.tagName.toLowerCase())) {
          oData.push({name: el.name, value: el.value});
        }
      }
  
      if(props.returnObject === true) {
        props.formAction &&
        props.formAction(oData, target);
      }else{
        props.formAction &&
        props.formAction(serialize(target)+hiddenString, target);
      }
  
      if(props.clearOnSubmit) {
        formChildren = React.Children.toArray(formChildren);
      }
    }
  }

  function validateForm(form) {
    let missingVals = [];
    let errorVals = [];
    Object.keys(form).map(key => {
      if(form[key].required && form[key].value === '') {
        missingVals.push(form[key].name);
      }

      let fieldErr = null;
      if(form[key].value && form[key].getAttribute('valuetype')) {
        fieldErr = isValueType(form[key].value, form[key].getAttribute('valuetype'));
      }else if (form[key].value && form[key].type){
        fieldErr = isValueType(form[key].value, form[key].type);
      }

      if(fieldErr) {
        form[key].fielderror = "fieldErr";
        errorVals.push({name:form[key].name, error: fieldErr});
      }

      return null;
    })

    if(missingVals.length > 0) {
      setMissingFields(missingVals);
      setAlertMsg(
        <div><b>There are some missing fields.</b><br />see below</div>);
      if(props.actionMissingValues) {
        props.actionMissingValues(missingVals);
      }
      return false;
    }else if (errorVals.length > 0) {
      setValueError(errorVals);
      setAlertMsg("There were some values that don't appear valid")
      return false;
    }
    return true;
  }

  function isValueType(value, type) {
    switch(type) {
      case 'email':
        if(!isEmail(value)) {
          return 'Email does not appear valid.';
        }else{
          return null;
        }
      case 'password':
        if(!isPassword(value)) {
          return 'Password does not appear valid';
        }else{
          return null;
        }
      default:
        return null;
    }
  }

  function isEmail(email) {
    if(typeof email !== "undefined"){
       let lastAtPos = email.lastIndexOf('@');
       let lastDotPos = email.lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 &&
          email.indexOf('@@') === -1 &&
          lastDotPos > 2 && (email.length - lastDotPos) > 2))
      {
          return false;
      }else{
        return true;
      }
    }else{
      return false;
    }
  }

  function isPassword(password) {
    if(password === null || password === '') {
      return false;
    }else{
      return true;
    }
  }

  useEffect(() => {
    if(props.alertMsg && !alertMsg) {
      setAlertMsg(props.alertMsg);
    }else if(!props.alertMsg && alertMsg) {
      setAlertMsg(null);
    }
  }, [props.alertMsg]);

  useEffect(() => {
    if(props.loadAction) {
      props.loadAction();
    }
  }, []);

  function handleFocus(e) {
    let target = e.target;
    while(target.tagName.toLowerCase() !== 'form' && target.tagName.toLowerCase() !== 'body') {
      target = target.parentNode;
    }

    const tabElements = target.querySelectorAll('[tabindex]');
    tabElements.forEach((el, index) => {
      el.setAttribute('tabindex', index+1);
    })

    tabElements && tabElements[0] && tabElements[0].click();

    setFormFocus(true);
  }

  function handleBlur(e) {
    let target = e.target;
    while(target.tagName.toLowerCase() !== 'form' && target.tagName.toLowerCase() !== 'body') {
      target = target.parentNode;
    }

    const tabElements = target.querySelectorAll('[tabindex]');
    tabElements.forEach(el => {
      el.setAttribute('tabindex', -1);
    })

    setFormFocus(false);
  }



  useEffect(() => {
    var _keyCtl = (e) => {
      if(e.key === 'Enter') {
        e.preventDefault();
        props.submitRef.current &&
        !props.submitRef.current.disable &&
        props.submitRef.current.click();
      }
    }
    if(formFocus) {
      window.addEventListener('keypress', _keyCtl, true);
    }

    if(formFocus === false) {
      handleBlur({target: formRef.current});
    }

    return() => {
      window.removeEventListener('keypress', _keyCtl, true);
    }
  }, [formFocus]);
  
  
  return (
    <form onSubmit={(e)=>{e.preventDefault()}}
          // onFocus={!formFocus ? handleFocus : null}
          // onBlur={formFocus ? handleBlur : null}
          acceptCharset="utf-8"
          encType={props.formType === 'file' ? 'multipart/form-data' : 'application/x-www-form-urlencoded'}
          name={props.name}
          id={props.name}
          formsubmitted={submitted ? 'true' : null}
          formsuccess={success ? 'true' : null}
          noValidate="noValidate" // for testing
          className={props.formClass ? props.formClass : ''}
          ref={props.formRef ? props.formRef : formRef}
          disabled={formFocus ? false : true}
    >
        <input type="hidden" name="form-type" value={props.name} />
        {alertMsg &&
          <FormAlert alertMsg={alertMsg} />}
        {React.Children.toArray(props.children).map((child, index) =>{

          let errorMsg = null;
          if(valueError[0]) {
            const error = valueError.find(field => field.name === child.props.name);
            if(error){
              errorMsg = error.error;
            }
          }

          let isMissing = false;
          if(missingFields[0]) {
            const missing = missingFields.find(field => field === child.props.name);
            if(missing){
              isMissing = true;
            }
          }
          return React.cloneElement(child, {key: index, errormsg:errorMsg, ismissing:isMissing.toString()});
        })}
    </form>
  )
}
