import React from 'react';
import SVGInline from 'react-svg-inline';

// Also requires raw-loader.
// Add the following to node_modules/react-scripts/config/webpack-config.js
// After test: [/\.bmp$/, /\.gif$/, /\.jpe?g$/, /\.png$/],
//
// {
//   test: /\.svg$/i,
//   use: 'raw-loader',
// },

import Add from 'svg/icons/aeIcon-add.svg';
import Admin from 'svg/icons/aeIcon-admin.svg';
import Alert from 'svg/icons/aeIcon-warning.svg';
import Approve from 'svg/icons/aeIcon-approve.svg';
import Archive from 'svg/icons/aeIcon-archive.svg';
import Arrow from 'svg/icons/aeIcon-arrow.svg';
import Attach from 'svg/icons/aeIcon-attach.svg';
import Bold from 'svg/icons/aeIcon-bold.svg';
import Cancel from 'svg/icons/aeIcon-cancel.svg';
import Cart from 'svg/icons/aeIcon-cart.svg';
import Chat from 'svg/icons/aeIcon-chat.svg';
import Check from 'svg/icons/aeIcon-check.svg';
import Clock from 'svg/icons/aeIcon-clock.svg';
import Close from 'svg/icons/aeIcon-close.svg';
import Code from 'svg/icons/aeIcon-code.svg';
import Collapse from 'svg/icons/aeIcon-collapse.svg';
import Compass from 'svg/icons/aeIcon-compass.svg';
import Complete from 'svg/icons/aeIcon-ctl-complete.svg';
import ConditionalAdd from 'svg/icons/aeIcon-conditional-add.svg';
import ConditionalFields from 'svg/icons/aeIcon-conditional-fields.svg';
import Dashboard from 'svg/icons/aeIcon-dashboard.svg';
import Date from 'svg/icons/aeIcon-date.svg';
import Decline from 'svg/icons/aeIcon-decline.svg';
import Document from 'svg/icons/aeIcon-document.svg';
import Download from 'svg/icons/aeIcon-download.svg';
import DownloadFile from 'svg/icons/aeIcon-download-file.svg';
import Draft from 'svg/icons/aeIcon-draft.svg';
import Edit from 'svg/icons/aeIcon-edit.svg';
import Elipse from 'svg/icons/aeIcon-elipse.svg';
import Email from 'svg/icons/aeIcon-email.svg';
import Expand from 'svg/icons/aeIcon-expand.svg';
import Eye from 'svg/icons/aeIcon-eye.svg';
import Fax from 'svg/icons/aeIcon-fax.svg';
import Field from 'svg/icons/aeIcon-field.svg';
import FieldGroup from 'svg/icons/aeIcon-field-group.svg';
import Filter from 'svg/icons/aeIcon-filter.svg';
import Flame from 'svg/icons/aeIcon-flame.svg';
import Gear from 'svg/icons/aeIcon-gear.svg';
import GearBadge from 'svg/icons/aeIcon-gear-badge.svg';
import Group from 'svg/icons/aeIcon-group.svg';
import GroupAdd from 'svg/icons/aeIcon-group-add.svg';
import Hold from 'svg/icons/aeIcon-hold.svg';
import Hide from 'svg/icons/aeIcon-eye-hide.svg';
import Ignore from 'svg/icons/aeIcon-ignore.svg';
import Image from 'svg/icons/aeIcon-image.svg';
import Info from 'svg/icons/aeIcon-info.svg';
import Italic from 'svg/icons/aeIcon-italic.svg';
import Kebob from 'svg/icons/aeIcon-kebob.svg';
import Label from 'svg/icons/aeIcon-label.svg';
import Link from 'svg/icons/aeIcon-link.svg';
import Loader from 'svg/icons/aeIcon-loader.svg';
import Lock from 'svg/icons/aeIcon-lock.svg';
import Menu from 'svg/icons/aeIcon-menu.svg';
import Merge from 'svg/icons/aeIcon-merge.svg';
import Message from 'svg/icons/aeIcon-message.svg';
import MessageBadge from 'svg/icons/aeIcon-message-badge.svg';
import MessageHide from 'svg/icons/aeIcon-message-hide.svg';
import MessageReply from 'svg/icons/aeIcon-message-reply.svg';
import MessageView from 'svg/icons/aeIcon-message-view.svg';
import Mobile from 'svg/icons/aeIcon-mobile.svg';
import Move from 'svg/icons/aeIcon-move.svg';
import MoveHorizontal from 'svg/icons/aeIcon-move-horizontal.svg';
import MoveVertical from 'svg/icons/aeIcon-move-vertical.svg';
import OnDemand from 'svg/icons/aeIcon-on-demand.svg';
import Optional from 'svg/icons/aeIcon-optional.svg';
import Options from 'svg/icons/aeIcon-options.svg';
import OptionsAdd from 'svg/icons/aeIcon-options-add.svg';
import Pause from 'svg/icons/aeIcon-ctl-pause.svg';
import Payment from 'svg/icons/aeIcon-payment.svg';
import Pencil from 'svg/icons/aeIcon-pencil.svg';
import Pending from 'svg/icons/aeIcon-pending.svg';
import Pin from 'svg/icons/aeIcon-pin.svg';
import PinAdd from 'svg/icons/aeIcon-pin-add.svg';
import PinEdit from 'svg/icons/aeIcon-pin-edit.svg';
import Phone from 'svg/icons/aeIcon-phone.svg';
import Play from 'svg/icons/aeIcon-play.svg';
import PointDown from 'svg/icons/aeIcon-point-down.svg';
import PointLeft from 'svg/icons/aeIcon-point-left.svg';
import PointRight from 'svg/icons/aeIcon-point-right.svg';
import PointUp from 'svg/icons/aeIcon-point-up.svg';
import Preview from 'svg/icons/aeIcon-zoom.svg';
import Print from 'svg/icons/aeIcon-print.svg';
import Progress from 'svg/icons/aeIcon-ctl-progressmark.svg';
import Reminder from 'svg/icons/aeIcon-reminder.svg';
import Restart from 'svg/icons/aeIcon-restart.svg';
import Return from 'svg/icons/aeIcon-return.svg';
import Save from 'svg/icons/aeIcon-save.svg';
import Search from 'svg/icons/aeIcon-search.svg';
import Shipping from 'svg/icons/aeIcon-shipping.svg';
import Skip from 'svg/icons/aeIcon-skip.svg';
import SortAsc from 'svg/icons/aeIcon-sort-asc.svg';
import SortDesc from 'svg/icons/aeIcon-sort-desc.svg';
import Split from 'svg/icons/aeIcon-split.svg';
import Star from 'svg/icons/aeIcon-star.svg';
import Start from 'svg/icons/aeIcon-ctl-start.svg';
import Strikethrough from 'svg/icons/aeIcon-strikethrough.svg';
import Success from 'svg/icons/aeIcon-success.svg';
import Task from 'svg/icons/aeIcon-task.svg';
import Target from 'svg/icons/aeIcon-target.svg';
import ThumbsUp from 'svg/icons/aeIcon-thumbs-up.svg';
import ThumbsDown from 'svg/icons/aeIcon-thumbs-down.svg';
import Time from 'svg/icons/aeIcon-time.svg';
import Trash from 'svg/icons/aeIcon-trash.svg';
import Underline from 'svg/icons/aeIcon-underline.svg';
import Upload from 'svg/icons/aeIcon-upload.svg';
import User from 'svg/icons/aeIcon-user.svg';
import UserAdd from 'svg/icons/aeIcon-user-add.svg';
import UserEdit from 'svg/icons/aeIcon-user-edit.svg';
import ViewFile from 'svg/icons/aeIcon-view-file.svg';
import Work from 'svg/icons/aeIcon-work.svg';

export default (props) => {
  let Icon = null;

  switch (props.icon) {
    case 'add':
      Icon = Add;
      break;
    case 'admin':
      Icon = Admin;
      break;
    case 'alert':
      Icon = Alert;
      break;
    case 'approve':
      Icon = Approve;
      break;
    case 'archive':
      Icon = Archive;
      break;
    case 'arrow':
      Icon = Arrow;
      break;
    case 'attach':
      Icon = Attach;
      break;
    case 'bold':
      Icon = Bold;
      break;
    case 'cancel':
      Icon = Cancel;
      break;
    case 'cart':
      Icon = Cart;
      break;
    case 'chat':
      Icon = Chat;
      break;
    case 'check':
      Icon = Check;
      break;
    case 'clock':
      Icon = Clock;
      break;
    case 'close':
      Icon = Close;
      break;
    case 'code':
      Icon = Code;
      break;
    case 'collapse':
      Icon = Collapse;
      break;
    case 'compass':
      Icon = Compass;
      break;
    case 'complete':
      Icon = Complete;
      break;
    case 'conditional':
      Icon = ConditionalFields;
      break;
    case 'conditional-add':
      Icon = ConditionalAdd;
      break;
    case 'dashboard':
      Icon = Dashboard;
      break;
    case 'date':
      Icon = Date;
      break;
    case 'decline':
      Icon = Decline;
      break;
    case 'draft':
      Icon = Draft;
      break;
    case 'document':
      Icon = Document;
      break;
    case 'download':
      Icon = Download;
      break;
    case 'download-file':
      Icon = DownloadFile;
      break;
    case 'edit':
      Icon = Edit;
      break;
    case 'elipse':
      Icon = Elipse;
      break;
    case 'email':
      Icon = Email;
      break;
    case 'expand':
      Icon = Expand;
      break;
    case 'eye':
      Icon = Eye;
      break;
    case 'fax':
      Icon = Fax;
      break;
    case 'field':
      Icon = Field;
      break;
    case 'field-group':
      Icon = FieldGroup;
      break;
    case 'filter':
      Icon = Filter;
      break;
    case 'flame':
      Icon = Flame;
      break;
    case 'gear':
      Icon = Gear;
      break;
    case 'gear-badge':
      Icon = GearBadge;
      break;
    case 'group':
      Icon = Group;
      break;
    case 'group-add':
      Icon = GroupAdd;
      break;
    case 'hide':
      Icon = Hide;
      break;
    case 'hold':
      Icon = Hold;
      break;
    case 'kebob':
      Icon = Kebob;
      break;
    case 'ignore':
      Icon = Ignore;
      break;
    case 'image':
      Icon = Image;
      break;
    case 'info':
      Icon = Info;
      break;
    case 'italic':
      Icon = Italic;
      break;
    case 'label':
      Icon = Label;
      break;
    case 'link':
      Icon = Link;
      break;
    case 'loader':
      Icon = Loader;
      break;
    case 'lock':
      Icon = Lock;
      break;
    case 'menu':
      Icon = Menu;
      break;
    case 'merge':
      Icon = Merge;
      break;
    case 'message':
      Icon = Message;
      break;
    case 'message-badge':
      Icon = MessageBadge;
      break;
    case 'message-reply':
      Icon = MessageReply;
      break;
    case 'message-hide':
    case 'messages-hide':
      Icon = MessageHide;
      break;
    case 'message-view':
    case 'messages-view':
    case 'message-show':
    case 'messages-show':
      Icon = MessageView;
      break;
    case 'mobile':
      Icon = Mobile;
      break;
    case 'move':
      Icon = Move;
      break;
    case 'move-horizontal':
      Icon = MoveHorizontal;
      break;
    case 'move-vertical':
      Icon = MoveVertical;
      break;
    case 'on-demand':
      Icon = OnDemand;
      break;
    case 'optional':
      Icon = Optional;
      break;
    case 'options':
      Icon = Options;
      break;
    case 'options-add':
    case 'option-add':
      Icon = OptionsAdd;
      break;
    case 'pause':
      Icon = Pause;
      break;
    case 'payment':
    case 'pay':
    case 'invoice':
      Icon = Payment;
      break;
    case 'pencil':
      Icon = Pencil;
      break;
    case 'pending':
      Icon = Pending;
      break;
    case 'pin':
    case 'location':
      Icon = Pin;
      break;
    case 'pin-add':
    case 'location-add':
      Icon = PinAdd;
      break;
    case 'pin-edit':
    case 'location-edit':
      Icon = PinEdit;
      break;
    case 'phone':
      Icon = Phone;
      break;
    case 'play':
      Icon = Play;
      break;
    case 'point-down':
      Icon = PointDown;
      break;
    case 'point-left':
      Icon = PointLeft;
      break;
    case 'point-right':
      Icon = PointRight;
      break;
    case 'point-up':
      Icon = PointUp;
      break;
    case 'preview':
      Icon = Preview;
      break;
    case 'print':
      Icon = Print;
      break;
    case 'progressmark':
      Icon = Progress;
      break;
    case 'reminder':
      Icon = Reminder;
      break;
    case 'restart':
    case 'reactivate':
    case 'refresh':
      Icon = Restart;
      break;
    case 'return':
    case 'node':
    case 'sub':
      Icon = Return;
      break;
    case 'save':
    case 'disk':
    case 'file':
      Icon = Save;
      break;
    case 'search':
      Icon = Search;
      break;
    case 'shipping':
    case 'ship':
    case 'truck':
      Icon = Shipping;
      break;
    case 'skip':
      Icon = Skip;
      break;
    case 'sort-asc':
      Icon = SortAsc;
      break;
    case 'sort-desc':
      Icon = SortDesc;
      break;
    case 'split':
      Icon = Split;
      break;
    case 'star':
      Icon = Star;
      break;
    case 'start':
      Icon = Start;
      break;
    case 'success':
      Icon = Success;
      break;
    case 'strikethrough':
      Icon = Strikethrough;
      break;
    case 'target':
      Icon = Target;
      break;
    case 'task':
      Icon = Task;
      break;
    case 'thumbs-up':
    case 'approve':
      Icon = ThumbsUp;
      break;
    case 'thumbs-down':
    case 'decline':
    case 'reject':
      Icon = ThumbsDown;
      break;
    case 'time':
      Icon = Time;
      break;
    case 'trash':
      Icon = Trash;
      break;
    case 'user':
      Icon = User;
      break;
    case 'user-add':
    case 'userAdd':
    case 'add-user':
      Icon = UserAdd;
      break;
    case 'user-edit':
    case 'userEdit':
    case 'edit-user':
      Icon = UserEdit;
      break;
    case 'underline':
      Icon = Underline;
      break;
    case 'upload':
      Icon = Upload;
      break;
    case 'view-document':
    case 'view-file':
      Icon = ViewFile;
      break;
    case 'work':
      Icon = Work;
      break;
    default:
      Icon = Alert;
      break;
  }
  return (
    <SVGInline
      svg={Icon}
      className={`icon-svg${props.className ? ` ${props.className}` : ''}`}
      style={props.style}
    />
  )
};
