import React from 'react';

import IconToken from 'components/icons/IconToken';
import Select from 'components/forms/elements/Select';

export default (props) => {

  function formatText(e) {
    console.log("Selected Text", window.getSelection());
  }

  return (
    <div className="text-area-ctl">
      <div className="text-area-ctl-set">
        <IconToken icon="bold" action={(e) => {props.actionFormatText('bold')}} />
        <IconToken icon="italic" action={(e) => {props.actionFormatText('italic')}} />
        <IconToken icon="underline" action={(e) => {props.actionFormatText('underline')}} />
        <IconToken icon="strikethrough" action={(e) => {props.actionFormatText('underline')}} />
      </div>
      <div className="text-area-ctl-set">
        <IconToken icon="code" action={props.actionToggleRaw} className={props.activeRaw ? `active` : null} />
      </div>
    </div>
  )
}
