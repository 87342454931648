import React, {useEffect, useState, useRef} from 'react';

import IconSVG from 'components/icons/IconSVG';

export default (props) => {
  const loaderRef = useRef();
  const [boxSize, setBoxSize] = useState();

  useEffect(() => {
    if(loaderRef.current) {
      const parent = loaderRef.current.parentNode;
      setBoxSize({width: parent.clientWidth,
                  height: parent.clientHeight});
    }
  }, []);

  return (
    props.isOverlay || props.overlayContents ?
      <div className={`loader-overlay${props.overlayContents ? ' contain' : ''}${props.lightBG ? ' dark' : ''}`}>
      <div className={`loader${props.className ? ` ${props.className}` : ''}${props.lightBG ? ' dark' : ''}`} 
            ref={loaderRef} >
              <IconSVG icon="clock" />
              <label>{props.label ? props.label.replaceAll('.', '') : `Loading`}</label>
      </div>  
    </div>
    :
      <div className={`loader${props.className ? ` ${props.className}` : ''}${props.lightBG ? ' dark' : ''}`} 
            ref={loaderRef} 
            style={boxSize && {height: `${boxSize.height}px`, width: `${boxSize.width}px`}}>
              <IconSVG icon="clock" />
              <label>{props.label ? props.label.replaceAll('.', '') : `Loading`}<span className="suffix"></span></label>
      </div>
  )
}
