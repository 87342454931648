import React from 'react';

export default (props) => {
  return (
    <div className={`cart-price${props.className ? ` ${props.className}` : ''}`} >
      <label>{props.label}</label>
      <div className="cart-price-value">
        {props.price}
      </div>
    </div>
  )
}
