import React, { useEffect, useState, useRef } from 'react';

import Slide from 'components/molecules/Slide';
import SlideShowCtl from 'components/molecules/SlideShowCtl';
import ControlNextPrev from 'components/molecules/ControlNextPrev';

export default (props) => {

  let ssid = 'slide-show';
  if(props.id) {
    ssid = props.id;
  }

  const [curSlide, setCurSlide] = useState(null);
  const [timerStatus, setTimerStatus] = useState(true);
  // const [timer, setTimer] = useState(null);
  const [timerCount, setTimerCount] = useState(0);
  const [slideTimer, setTimer] = useState(null);
  const [activeTimer, setActiveTimer] = useState(0);
  const [prevTimer, setPrevTimer] = useState(0);
  const [aspectRatio, setAspectRatio] = useState(props.aspectRatio ? props.aspectRatio : '16:9');
  const [slideIvl, setInterval] = useState(props.slideDuration ? props.slideDuration : 6000);
  const [slides, setSlides] = useState(null);

  let sldTimer

  let ivlSlides = null;

  function calcRatio(aspectRatio) {
    const ratioValues = aspectRatio.includes(':') ?
                          aspectRatio.split(':') :
                          aspectRatio.split('x');
    const ratioFactors = {width: parseFloat(ratioValues[0])/parseFloat(ratioValues[1]),
                          height: parseFloat(ratioValues[1])/parseFloat(ratioValues[0])};

    return ratioFactors;
  }

// USE EFFECT HOOKS



useEffect(() => {
  const ratios = calcRatio(aspectRatio);
  loadSlides(props.slides);

  return (() => {
    setTimerStatus(null);
    setTimerCount(null);
    setTimer(null);
    setActiveTimer(null);
    setPrevTimer(null);
  })
}, [props.slides]);

  useEffect(() => {
    if(!curSlide && document.getElementById(ssid)) {
      const slidesArray = document.getElementById(ssid).querySelectorAll('.slide');
      slidesArray.length > 0 && slidesArray[0].classList.add('slide-first');
      setCurSlide(slidesArray[0]);
    }
  }, [slides]);

  useEffect(() => {
    curSlide
    && timerStatus &&
    setTimeout( thisTimer, slideIvl);
    return()=> {
      clearTimeout(thisTimer);
      stopTimer();
    };
  }, [curSlide]);

// FUNCTIONS

  function calcHeightFactor(aspectRatio) {
    return calcRatio(aspectRatio).height*100;
  }

  function stopTimer() {
    clearTimeout(activeTimer);
    setActiveTimer(0);
    setPrevTimer(0);
    setTimer(null);
  }

  function activateTimer() {
    if(timerStatus && timerCount === 0) {
      setTimerCount(timerCount+1);
      setTimeout(thisTimer, slideIvl);
    }
  }

  function thisTimer() {
    setNextSlide();
  }

  function loadSlides(slides) {
    let slideSet = [];
    let slideRefs = [];
    if(Array.isArray(slides) && slides.length > 0) {
      slides.map((slide, index) => {
        slideSet.push(<Slide {...props}
                          slide={slide}
                          slideActive={index === 0 ? true : false}
                          key={`slide${index}`} />)
      })
    }
    setSlides(slideSet);
  }

  function runTimer(curTimer) {
    setActiveTimer(curTimer + 1);
    setTimer(setTimeout(()=>{runTimer(curTimer+1)}, slideIvl));
  }

  function setNextSlide() {
    if(timerStatus) {
      if(props.slides && Array.isArray(props.slides) && props.slides.length > 1) {
        if(curSlide) {
          let nextSlide;
          if(curSlide.nextSibling && curSlide.nextSibling.classList.contains('slide')) {
            nextSlide = curSlide.nextSibling;
          }else{
            if(document.getElementById(ssid)) {
              nextSlide = document.getElementById(ssid).getElementsByClassName('slide')[0];  
            }
          }
          if(nextSlide) {
            curSlide.classList.contains('prev') && curSlide.classList.remove('prev');
            nextSlide.classList.contains('prev') && nextSlide.classList.remove('prev');
            curSlide.classList.contains('slide-first') && curSlide.classList.remove('slide-first');
            curSlide.classList.add('slide-inactive');
            curSlide.classList.remove('slide-active');
            setTimeout(()=>{curSlide.classList.remove('slide-inactive');}, 1000);
            nextSlide.classList.add('slide-active');
            setCurSlide(nextSlide);  
          }
        }
      }
    }
  }

  function setPrevSlide() {
    if(props.slides && Array.isArray(props.slides) && props.slides.length > 1) {
      if(curSlide) {
        let prevSlide;
        if(curSlide.previousSibling && curSlide.previousSibling.classList.contains('slide')) {
          prevSlide = curSlide.previousSibling;
        }else{
          const allSlides = document.getElementById(ssid).getElementsByClassName('slide');
          prevSlide = allSlides[allSlides.length-1];
        }
        prevSlide.classList.contains('slide-inactive') && prevSlide.classList.remove('slide-inactive');
        !prevSlide.classList.contains('prev') && prevSlide.classList.add('prev');
        !curSlide.classList.contains('prev') && curSlide.classList.add('prev');
        curSlide.classList.contains('slide-first') && curSlide.classList.remove('slide-first');
        setTimeout(()=>{curSlide.classList.contains('slide-active') && curSlide.classList.remove('slide-active');}, 20);
        setTimeout(()=>{curSlide.classList.add('slide-inactive');}, 20);
        setTimeout(()=>{prevSlide.classList.add('slide-active');}, 20);
        setTimeout(()=>{curSlide.classList.remove('slide-inactive'); curSlide.classList.remove('prev');}, 1050);
        setCurSlide(prevSlide);
      }
    }
  }

  function moveToSlide(e) {
    clearTimeout(sldTimer);
    let target = e.target;
    while (!target.classList.contains('icon-ctl-dir') && target.tagName.toLowerCase() !== 'body') {
      target = target.parentNode;
    }

    if(target.classList.contains('next')) {
      setNextSlide();
      setTimerStatus(true);
    }else if(target.classList.contains('prev')){
      setPrevSlide();
      setTimerStatus(true);
    }

  }

  let slidecount = 0;
  return (
    <div className="slide-show" style={{height: `${calcHeightFactor(aspectRatio)}vw`}} id={props.id ? props.id : 'slide-show'}>
      { slides && Array.isArray(slides) && slides.length > 0 ?
        slides :
        <p>...loading</p>
      }

      {slides && Array.isArray(slides) && slides.length > 1 && <ControlNextPrev direction="prev" action={moveToSlide} /> }
      {slides && Array.isArray(slides) && slides.length > 1 && <ControlNextPrev direction="next" action={moveToSlide} /> }
      {slides && Array.isArray(slides) && slides.length > 1 && <SlideShowCtl slides={slides} slideStop={stopTimer} /> }
    </div>
  )
}
