import  React, {useEffect, useState, Fragment} from 'react';

import FormDynamic from 'components/forms/FormDynamic';
import Button from 'components/forms/elements/Button';

import * as forms from 'methods/forms';

export default (props) => {
	const [isSuper, setIsSuper] = useState(null);
	const [userID, setUserID] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [organizationName, setOrganizationName] = useState(null);
	const [token, setToken] = useState(null);
	const [users, setUsers] = useState(null);
	const [organizations, setOrganizations] = useState(null);
	const [userRoles, setUserRoles] = useState(null);
	const [userAvailable, setUserAvailable] = useState(true);
	const [userFields, setUserFields] = useState(null);
	const [userForm, setUserForm] = useState(null);
	const [userPassword, setUserPassword] = useState(null);
	const [optionsOrgs, setOptionsOrgs] = useState(null);
	const [optionsRoles, setOptionsRoles] = useState(null);

	// HOOKS
	useEffect(() => {
		
		props.adminDeleteVal('user');
		if(!userPassword) {
			setUserPassword(props.generatePassword());
		}
	}, []);
	
	
	useEffect(() => {
		props.userID &&
		setUserID(props.userID);
	}, [props.userID]);
	
	useEffect(() => {
		if(props.objGetValue(props.user, 'user_roles.1.role_id') === 1) {
			setIsSuper(true);
		}
	}, [props.user]);
	
	useEffect(() => {
	props.token &&
	setToken(props.token);
	}, [props.token])
	
	useEffect(() => {
		props.organizationID &&
		setOrganizationID(props.organizationID);
	}, [props.organizationID]);
	
	useEffect(() => {
		props.organizationName &&
		setOrganizationName(props.organizationName);
	}, [props.organizationName]);
	
	useEffect(() => {
		organizationID === 1 && props.organizations &&
		setOrganizations(props.organizations);
	}, [organizationID && props.organizations]);
	
	useEffect(() => {
		props.userRoles &&
		setUserRoles(props.userRoles);
	}, [props.userRoles]);
	
	useEffect(() => {
		props.users &&
		setUsers(props.users);
	}, [props.users]);
	
	useEffect(() => {
		if(organizations && 
			Array.isArray(organizations) &&
			organizations.length > 0) {
			let opts = [];
			organizations.map(org => {
				opts.push({option_name: org.organization_name, option_value: org.organization_id});
			});
			if(opts.length > 0) {
				setOptionsOrgs([{options: opts}]);
			}
		}
	}, [organizations])
	
	useEffect(() => {
		if(userRoles && 
			Array.isArray(userRoles) &&
			userRoles.length > 0) {
			let opts = [];
			userRoles.sort((a, b) => {return a.role_id > b.role_id ? 1 : -1}).map(role => {
				if(isSuper || role.role_id !== 1) {
					opts.push({option_name: role.role_name, option_value: role.role_id});
				}
			});
			if(opts.length > 0) {
				setOptionsRoles([{options: opts}]);
			}
		}
	}, [userRoles]);
	
	useEffect(() => {
		let orderFactor = 0;
		if(organizationID === 1 && optionsOrgs) {
			orderFactor = 1;
		}
		
		if(userID && token && organizationID && optionsRoles) {
			let uFields = [];
			if(organizationID === 1 && optionsOrgs) {
				uFields.push({field_type: 'select',
					field_name: 'organization_id',
					field_label: 'Organization',
					field_placeholder: 'Select Organization',
					field_value: null,
					option_groups: optionsOrgs,
					field_required: true,
					field_verify: true,
					fieldOnChange: null,
					fieldOnLoad: null,
					field_order: 1,
				});
			}else{
				uFields.push(
					{field_type: 'hidden',
					field_name: 'organization_id',
					field_value: organizationID
					}
				)
			};
			
			uFields.push(
				{field_type: 'text',
				field_name: 'contact_name_first',
				field_label: 'First Name',
				field_placeholder: `User's first name`,
				field_value: null,
				field_verify: true,
				field_required: true,
				fieldOnChange: null,
				field_order: orderFactor+2,
				},
				{field_type: 'text',
				field_name: 'contact_name_last',
				field_label: 'First Name',
				field_placeholder: `User's last name`,
				field_value: null,
				field_verify: true,
				field_required: true,
				fieldOnChange: null,
				field_order: orderFactor+3,
				},
				{field_type: 'email',
				field_name: 'contact_email',
				field_label: 'User Email',
				field_placeholder: `User's email address (will be login)`,
				field_value: null,
				field_verify: true,
				field_required: true,
				fieldOnChange: verifyUniqueUser,
				fieldOnLoad: null,
				field_order: orderFactor+4,
				},
				{field_type: 'password',
				field_name: 'user_key',
				field_label: 'User Password',
				field_placeholder: 'Provide a password',
				field_value: userPassword,
				field_verify: true,
				field_required: true,
				field_order: orderFactor+5,
				fieldOnChange: null,
				fieldOnLoad: null,
				field_reveal: true,
				},
				{field_type: 'select',
				field_name: 'role_id',
				field_label: 'User Role',
				field_placeholder: 'What will the user do?',
				field_value: null,
				option_groups: optionsRoles,
				field_required: true,
				field_verify: true,
				field_order: orderFactor+6,
				},
				{field_type: 'hidden',
				field_name: 'process',
				field_value: 'create_user'
				},
				{field_type: 'hidden',
				field_name: 'user_id',
				field_value: userID
				},
				{field_type: 'hidden',
				field_name: 'token',
				field_value: token
				},	
			);
			
			setUserFields([{fieldset_name: 'User Account Info',
							fields: uFields}]
			);
		}
	}, [userID, isSuper, organizationID, optionsOrgs, optionsRoles, userPassword]);
	
	useEffect(() => {
		
		userFields &&
		setUserForm(
		<FormDynamic
			content={userFields}
			name="addUser"
			formActions={{formSubmit: handleSubmit}}
			fieldOnChange={props.fieldOnChange ? props.fieldOnChange : null}
			fieldOnLoad={props.fieldOnLoad ? props.fieldOnLoad : null}
			closable={false}
			unmountOnClick={false}
			cancelable={props.cancelable ? true : false}
			includeControls={props.includeControls === false ? false : true}
			returnObject={true}
			multiStep={false}
			formClass=""
			valActions={{isEmail: props.isEmail}} />
		)
	}, [userFields]);
	
	useEffect(() => {
		if(props.objExists(props.admin, 'user.user_id') &&
			parseInt(props.admin.user.user_id) > 0 ) {
			if(props.actionSuccess) {
				props.actionSuccess(parseInt(props.admin.user.user_id));
			}   
		}
	}, [props.admin.user]);

	// FUNCTIONS
	function fieldOnChange(name, value) {
		// console.log("Field On Change", name, value);
	}
	
	function fieldOnLoad(name, value) {
		// console.log("Field On Load", name, value);
	}
	
	function verifyUniqueUser(email) {
		// console.log("Verify Unique User", email.value, users);
	}
	
	function handleSubmit(params) {
		props.adminLoadState(params, 'users', 'user.user_id');
		if(props.actionSubmit) {
			props.actionSubmit();
		}
	}
	
	// console.log("form options", props.admin);
	return (
		<Fragment>
			<div className="form-row">
			<Button icon="lock"
					label={`Generate${userPassword ? ' New' : ''} Password`}
					btnClass="info-bg mini"
					btnAction={()=>{setUserPassword(props.generatePassword())}} />
			</div>
			{userForm}
		</Fragment>
	)
}