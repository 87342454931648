import React, {useEffect, useState, Fragment} from 'react';

import Button from 'components/forms/elements/Button';
import RadioSet from 'components/forms/elements/RadioSet';
import Link from 'components/navigation/Link';
import Loader from 'components/atoms/Loader';
import FormDeclineTask from 'components/forms/common_forms/FormDeclineTask';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [task, setTask] = useState(null);
	const [status, setStatus] = useState(null);
	const [files, setFiles] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [canEdit, setCanEdit] = useState(null);
	const [isSuper, setIsSuper] = useState(null);
	const [approval, setApproval] = useState(null);
	const [paramsApproval, setParamsApproval] = useState(null);
	const [isUpdating, setIsUpdating] = useState(null);
	const [loadDecline, setLoadDecline] = useState(null);
	const [declineForm, setDeclineForm] = useState(null);
	
	// HOOKS
	useEffect(() => {
		return () => {
			setIsUpdating(false);
			setLoadDecline(false);
		}
	}, []);
	
	useEffect(() => {
		props.userID && 
		props.userID !== userID &&
		setUserID(props.userID);
	}, [props.userID]);
	
	useEffect(() => {
		props.token && 
		props.token !== token &&
		setToken(props.token);
	}, [props.token])
	
	useEffect(() => {
		props.organizationID && 
		props.organizationID !== organizationID &&
		setOrganizationID(props.organizationID);
	}, [props.organizationID]);
	
	useEffect(() => {
		props.languageCode && 
		props.languageCode !== languageCode &&
		setLanguageCode(props.languageCode);
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.task && (
			props.task !== task ||
			props.objGetValue(props.files, 'uploadComplete') === true
		)) {
			setTask(props.task);
			setIsUpdating(false);
		}
	}, [props.task, props.files]);
	
	useEffect(() => {
		// if(props.objExists(task, 'task_completed') && isUpdating) {
		// 	setIsUpdating(null);
		// }
		if(task && languageCode) {
			if(props.objExists(task, `status[0].${languageCode}.status_name`) &&
				task.status[0][languageCode].status_name !== status) {
					setStatus(task.status[0][languageCode].status_name);
				}
		}
	}, [task, languageCode]);
	
	useEffect(() => {
		if(task) {
			setParamsApproval([
				{name: 'process', value: 'advance_task'},
				{name: 'user_id', value: userID},
				{name: 'token', value: token},
				{name: 'task_id', value: task.task_id},
				{name: 'task_status', value: 'approved'},
				{name: 'task_owner_type', value: props.taskOwnerType},
				{name: 'task_owner_id', value: props.taskOwnerID},
				{name: 'task_root_type', value: props.taskRootType},
				{name: 'task_root_id', value: props.taskRootID}		
			]);
		}
	}, [task, userID, token, props.taskOwnerType, props.taskOwnerID, props.taskRootType, props.taskRootID]);
	
	useEffect(() => {
		if(task) {
			setDeclineForm(
				<FormDeclineTask {...props}
				userID={userID}
				token={token}
				languageCode={languageCode}
				task={task}
				task_owner_type={props.taskOwnerType}
				task_owner_id={props.taskOwnerID}
				task_root_type={props.taskRootType}
				task_root_id={props.taskRootID}
				actionCancel={()=>{props.setModal(null);}}
				actionSubmit={taskApprove}	 />
			)
		}
	}, [props.files, task, props.taskOwnerType, props.taskOwnerID, props.taskRootType, props.taskRootID]);
	
	useEffect(() => {
		if(loadDecline) {
			props.setModal(declineForm);
		}
	}, [loadDecline, declineForm, props.files]);
	
	// FUNCTIONS
	function taskApprove(params) {
		props.setModal(false);
		setLoadDecline(false);
		
		const mediaRevisions = params.find(param => param.name === 'media_revisions' && parseInt(param.value) > 0);
		
		if(mediaRevisions) {
			let fileParams = [
				{name: 'process', value: 'add_link'},
				{name: 'user_id', value: userID},
				{name: 'token', value: token},
				{name: 'link_from', value: mediaRevisions.value},
				{name: 'link_to', value: task.item_id},
				{name: 'link_type', value: 'revisionsToItem'},
				{name: 'link_published', value: true},
				{name: 'return_bool', value: true}
			];
			props[`${props.location}LoadState`](fileParams, 'links', `reload.${props.source ? props.source : 'revisions'}`);
		}
		
		const taskParams = params.filter(param => param.name !== 'media_revisions' || parseInt(param.value) > 0);
		const taskStatus = params.find(param => param.name === 'task_status');
		
		if(props.actionUpdate) {
			props.actionUpdate(props.task.task_id, taskStatus.value);
		}
		
		if(props.onUpdate) {
			props.onUpdate(true);
		}
		
		
		// setIsUpdating(true);
		setStatus(taskStatus.value);
		props[`${props.location}LoadState`](taskParams, 'tasks', `reload.${props.source ? props.source : 'project'}`);
	}
	
	function clearApproval() {
		
	}
	
	return (
		<Fragment>
			{isUpdating ?
				<p>Submitting...</p>
			:	
				task &&
				task.task_published && 
					!task.task_completed ?
					<p>Your approval is needed</p>
				:	status === 'declined' ?
						<p>Declined</p>
					:	task && task.task_completed ?
							<p>Completed</p>
						:	<p>Task not currently active</p>
			}
			{task &&
				<div className="form-row">
					<Button btnClass={task.task_published && !task.task_completed ? 'success-bg' : null} 
							icon="approve" 
							label="Approve"
							btnAction={!isUpdating ? ()=>{taskApprove(paramsApproval)} : null}
							disabled={task.task_published && !task.task_completed ? false : true} />
					<Button btnClass={task.task_published && !task.task_completed ? 'error-bg' : null} 
							icon="decline" 
							label="Decline"
							btnAction={!isUpdating ? ()=>{setLoadDecline(true)} : null}
							disabled={task.task_published && !task.task_completed ? false : true} />
					{isUpdating && <Loader overlayContents={true} label="Updating task..." />}
				</div>	
			}
		</Fragment>
	)
}