import React, {useEffect, useState, Fragment} from 'react';

import FormDynamic from 'components/forms/FormDynamic';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [coreOrg, setCoreOrg] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [groups, setGroups] = useState(null);
	const [groupType, setGroupType] = useState(null);
	const [fieldsets, setFieldsets] = useState(null);
	const [form, setForm] = useState(null);

	// HOOKS
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.coreOrg && props.coreOrg !== coreOrg) {
			setCoreOrg(props.coreOrg);
		}
	}, [props.coreOrg]);
	
	useEffect(() => {
		if(props.groupType && props.groupType !== groupType) {
			setGroupType(props.groupType);
		}
	}, [props.groupType]);
	
	useEffect(() => {
		if(props.langaugeCode && 
			props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}else if(!languageCode){
			setLanguageCode('en');
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.groups && 
			Array.isArray(props.groups) && 
			props.groups.length > 0  && 
			props.groups !== groups) {
			setGroups(props.groups);
		}
	}, [props.groups]);
	
	useEffect(() => {
		if(userID && token && coreOrg && languageCode && groupType && groups) {
			setFieldsets([
				{fieldset_name: `${props.objExists(props, 'group.group_id') ? 'Update' : 'Add'} Group`,
					fields: [
						{field_type: 'text',
						field_name: 'group_name',
						field_label: 'Name',
						field_placeholder: 'Group Name',
						field_value: props.objExists(props, `group.${languageCode}.group_name`) ?
										props.group[languageCode].group_name : null,
						field_required: true,
						field_validate: true,
						field_order: 1,
						},
						groups &&
						{field_type: 'select',
						field_name: 'group_parent',
						field_label: 'Parent',
						field_placeholder: 'Group Parent',
						field_value: props.objExists(props, `group.group_parent`) ?
										props.group.group_parent : null,
						field_required: false,
						field_validate: false,
						option_groups: [
							{options: 
								groups && Array.isArray(groups) &&
								groups.map(group => {
									return {option_name: group[languageCode].group_name,
											option_value: group.group_id}
								})}
						],
						field_order: 2,
						},
						{field_type: 'switch',
						field_name: 'group_published',
						field_label: 'Published',
						field_default: true,
						field_value: props.objExists(props, 'group.group_published') && 
										(props.group.group_published === false || 
										props.group.group_published === 0) ?
										false : true,
						field_required: false,
						field_validate: false,
						field_order: 3
						},
						{field_type: 'hidden',
						field_name: 'process',
						field_value: props.objExists(props.group, 'group_id') ? 'update_group' : 'add_group',
						},
						{field_type: 'hidden',
						field_name: 'group_id',
						field_value: props.objExists(props.group, 'group_id') ? props.group.group_id : null,
						},
						{field_type: 'hidden',
						field_name: 'group_type',
						field_value: groupType,
						},
						{field_type: 'hidden',
						field_name: 'user_id',
						field_value: userID,
						},
						{field_type: 'hidden',
						field_name: 'token',
						field_value: token,
						},
						{field_type: 'hidden',
						field_name: 'organization_id',
						field_value: coreOrg,
						},
						{field_type: 'hidden',
						field_name: 'return_bool',
						field_value: 'true'
						}
				]}]);
		}else if(fieldsets) {
			setFieldsets(null);
		}
	}, [userID, token, coreOrg, languageCode, groupType, groups]);
	
	useEffect(() => {
		if(fieldsets) {
			setForm(
				<FormDynamic {...props}
					content={fieldsets}
					name="add-edit-group"
					label="chubb"
					returnObject={true}
					fieldOnChange={null}
					fieldOnLoad={null}
					cancelable={props.cancelable === false ? false : true}
					formActions={{formSubmit: handleSubmit, formCancel: handleCancel}}
					formCompleteLabel={props.objExists(props.group, 'group_id') ? 'Update' : 'Create'} />
			);
		}
	}, [fieldsets]);
	
	
	// FUNCTIONS
	function handleSubmit(params) {
		if(props.actionSubmit) {
			props.actionSubmit(params);
		}
	}
	
	function handleCancel() {
		if(props.actionCancel) {
			props.actionCancel();
		}else{
			props.setModal(null);
		}
	}
	
	return form
}