import * as methods from 'methods/reducers';

export default function cart(state = {}, action) {
  switch (action.type) {
    case 'CART_ITEM_COUNT':
      let itemCount = 0;
      if(state.items && state.items.length > 0) {
        itemCount += state.items.length;
      }
      if(state.project && state.project.orders && state.project.orders.length > 0) {
        state.project.orders.map( order => {
          if(order.items && order.items.length > 0) {
            itemCount += order.items.length;
          }
          return true;
        })
      }
      return {...state, cartCount: itemCount}
    case 'CART_ADD_ITEM':
      let items = [];
      if(state.items) {
          let items = [];
          let duplicate = false;
          state.items.map(item => {
            if(action.payload.product_id === item.product_id &&
              compareObjects(action.payload.custom_info, item.custom_info)) {
                console.log('adding quantities: ', action.payload.product_quantity, '+', item.product_quantity);
                item.product_quantity = parseInt(action.payload.product_quantity)+parseInt(item.product_quantity);
                duplicate = true;
            }
            return items.push(item);
          })
          if(!duplicate) {
            items.push(action.payload);
          }
          sessionStorage.setItem('coreCRT', JSON.stringify(items));
          return {...state, items: items};
      }else{
          items.push(action.payload);
          sessionStorage.setItem('coreCRT', JSON.stringify(items));
          // console.log(JSON.stringify(items));
          return {...state, items: items};
      }
    case 'CART_MERGE_ITEM':
        let mergeCart = state;
        switch(true) {
          case action.duplicate.location === 'cart':
            mergeCart.items[action.duplicate.position].product_quantity = parseInt(mergeCart.items[action.duplicate.position].product_quantity)+parseInt(action.payload.product_quantity);
            sessionStorage.setItem('corePrjct', JSON.stringify(mergeCart.project));
            return mergeCart;
          case Number.isInteger(action.duplicate.location):
            console.log("You're adding to an order");
            mergeCart.project.orders[action.duplicate.location].items[action.duplicate.position].product_quantity += parseInt(action.payload.product_quantity);
            sessionStorage.setItem('corePrjct', JSON.stringify(mergeCart.project));
            break;
          default:
            return state;
        }
        return state;
    case 'CART_ADD_DUPLICATE_ITEM':
        console.log("reducer: ", action);
        let dupCart = state;
        switch(true) {
          case action.value === 'cart':
            if(dupCart.items) {
              dupCart.items.push(action.payload);
            }else{
              dupCart['items'] = [action.payload];
            }
            sessionStorage.setItem('corePrjct', JSON.stringify(dupCart.project));
            return dupCart;
          case Number.isInteger(action.value):
            console.log("You're adding to an order", dupCart.project.orders[action.value].items);
            if(dupCart.project.orders[action.value].items) {
              dupCart.project.orders[action.value].items.push(action.payload);
            }else{
              dupCart.project.orders[action.value].items = [action.payload];
            }
            console.log("reducer cart: ", dupCart);
            sessionStorage.setItem('corePrjct', JSON.stringify(dupCart.project));
            return dupCart;
          default:
            return state;
        }
    case 'CART_UPDATE_ITEM_VALUE':
      console.log("Reducer: cart updated item value :", action.payload);
      let updatedItems = state.items;
      if(Array.isArray(action.payload.fieldName)) {
        switch(action.payload.fieldName.length) {
          case 1:
            updatedItems[action.payload.itemIndex][action.payload.fieldName[0]] = action.payload.fieldValue;
            break;
          case 2:
            updatedItems[action.payload.itemIndex][action.payload.fieldName[0]][action.payload.fieldName[1]] = action.payload.fieldValue;
            break;
          case 3:
            updatedItems[action.payload.itemIndex][action.payload.fieldName[0]][action.payload.fieldName[1]][action.payload.fieldName[2]] = action.payload.fieldValue;
            break;
          case 4:
            updatedItems[action.payload.itemIndex][action.payload.fieldName[0]][action.payload.fieldName[1]][action.payload.fieldName[2]][action.payload.fieldName[3]] = action.payload.fieldValue;
            break;
          default:
            break;
        }

      }else{
        console.log("Having a problem here with action payload itemIndex", action.payload, updatedItems);

        if (updatedItems && updatedItems[action.payload.itemIndex]) {
          updatedItems[action.payload.itemIndex][action.payload.fieldName] = action.payload.fieldValue;
        }

      }

      // console.log('Updated Items: ', updatedItems);

      sessionStorage.setItem('coreCRT', JSON.stringify(updatedItems));
      return {...state, items: updatedItems};

    case 'LOAD_CART_FROM_SESSION':
      // console.log('cart session: ', action.payload);
      return {...state, items: action.payload};

      case 'LOAD_CO_CART_FROM_SESSION':
        return {...state, project: action.payload};

      case 'CART_SET_ADDRESS':
      if(action.addressType === 'billingDefault') {
        if (state.project) {
          return {...state, project: {...state.project, billing: {...state.project.billing, address: action.payload}}};
        } else {
          return {...state, project: { billing: {address: action.payload}}};
        }

      }else{
        return {...state, addresses: {...state.addresses, [action.addressType]: action.payload}};
      }

    case 'CART_SET_INFO':
      // console.log("here is the state: ", state);
      // console.log("Here is what we are setting: ", action.payload, action.infoType);
      const setLocation = action.infoType.split('.');
      let setVal = action.payload;
      for(var i = (setLocation.length-1); i >= 0; i--) {
        // start with isArray as false and aLocation empty;
        let isArray = false;
        let aLocation = [];

        // Check if current level is an array ([] supplied with or without index - [] or [1])
        if(setLocation[i].includes('[') && setLocation[i].includes(']')) {
          const aLoc = setLocation[i].split('[');
          aLocation = [aLoc[0], aLoc[1].replace(']', '')];
          isArray = true;
        }

        // Checking for existing values at this location
        // Start at base current state value
        let curVal = state;

        // console.log("checking loop", i);

        // iterate to find is there is a current value at the current level;
        for(var j = 0; j <= i; j++) {
          // console.log("checking J loop", j, i);
          let jaLocation = [];
          let jisArray = false;

          // console.log("checking for values at ", setLocation[j], setLocation);

          // Check if current level is an array ([] supplied with or without index - [] or [1])
          if(setLocation[j].includes('[') && setLocation[j].includes(']')) {
            const aLoc = setLocation[j].split('[');
            jaLocation = [aLoc[0], aLoc[1].replace(']', '')];
            jisArray = true;
            // console.log("there is an array here: ", setLocation[j], jaLocation);
          }

          if(curVal) {
            if(jisArray && curVal[jaLocation[0]]) {
              curVal = curVal[jaLocation[0]];
            } else if (curVal[setLocation[j]]) {
              curVal = curVal[setLocation[j]];
            }else{
              curVal = null;
            }
          }
        }

        if(isArray) {

          if(curVal && Array.isArray(curVal)) {
            if(aLocation[1].toLowerCase() === 'x') {
              // if x is supplied in braces [x] replace entire array with new value
              setVal = {[aLocation[0]]:[setVal]};
            }else if(aLocation[1] === '' || !aLocation[1]) {
              console.log("adding a new ray to the array");
              //  if no number is supplied in braces [] push set value to exisitng array
              let newVal;

              if(curVal && Array.isArray(curVal)) {
                console.log("There are exisiting rays:", aLocation[0], curVal);
                // if the current value is an array combine current and set
                newVal = curVal;
                newVal.push(setVal);
                setVal = {[aLocation[0]]: newVal};
              }else{
                // if no current value or value isn't an array
                setVal = {[aLocation[0]]: [setVal]};
              }
            }else if(!isNaN(parseInt(aLocation[1]))) {
              // if an index has been supplied [0] splice into existing array
              // Creating a holding for the new merged values
              let mergeVal = {};
              let newVal;

              // Check if the current value is an object and set value needs to be merged.
              if(curVal[aLocation[1]].constructor === Object &&
                  Object.keys(curVal[aLocation[1]]).length > 0) {

                const valKeys = Object.keys(curVal[aLocation[1]]);
                // valKeys.map(vKey => {
                //   if(curVal[aLocation[1]][vKey] && Object.keys(curVal[aLocation[1]][vKey]).length > 0 && setVal[vKey]) {
                //     mergeVal[vKey] = {...curVal[aLocation[1]][vKey], ...setVal[vKey]};
                //   }
                // })
                // CHANGED TO FOR STATEMENT TO PREVENT no-loop-func
                for(i = 0; i < valKeys.length; i++) {
                  if(curVal[aLocation[1]][valKeys[i]] && Object.keys(curVal[aLocation[1]][valKeys[i]]).length > 0 && setVal[valKeys[i]]) {
                    mergeVal[valKeys[i]] = {...curVal[aLocation[1]][valKeys[i]], ...setVal[valKeys[i]]};
                  }
                }
              }
              if(mergeVal) {
                // Merge current, set and then merge values
                newVal = {...curVal[aLocation[1]], ...setVal, ...mergeVal};

                // Add the value back into the array at the existing position:
                curVal.splice(aLocation[1], 1, newVal);
              }

              // return updated array and values into set values
              setVal = {[aLocation[0]]: curVal};
            }
          }else{
            setVal = {[aLocation[0]]:[setVal]};
          }

        } else {
          if(setVal && setVal.constructor === Object && curVal) {
            // console.log("setting an object?", setVal, curVal);
            setVal = {[setLocation[i]]:{...curVal, ...setVal}};
          }else{
            setVal = {[setLocation[i]]: setVal};
          }
        }
      }
      return {...state, ...setVal};
    case 'CART_DELETE_VAL':
      let removeValState;
      if(Array.isArray(action.payload)) {
        action.payload.map(location => {
          removeValState = methods.removeStateValue(state, location);
          return true;
        })
      }else{
        removeValState = methods.removeStateValue(state, action.payload);
      }
      
      if(removeValState) {
        return {...removeValState};
      }else{
        return {...state};
      }
    case 'CART_ASSIGN_VAL':
      const assignValState = methods.assign(state, action.name, action.value);
      // if(setValState) {
      //   let stateSetLoc = action.name.includes('.') ? action.name.split('.')[0] : action.name;
      //   if(stateSetLoc.match(/\[(.*?)\]/g)) {
      //     stateSetLoc = "items";
      //   }
      //   let storageName = stateSetLoc.replace(/\[(.*?)\]/g,'');
      //   switch(stateSetLoc) {
      //     case null:
      //       break;
      //     case 'project':
      //       storageName = 'corePrjct';
      //       break;
      //     case 'items':
      //       storageName = 'coreCRT';
      //       break;
      //     default:
      //       storageName = `cart${stateSetLoc}`;
      //       break;
      //   }
      //   sessionStorage.setItem(storageName, JSON.stringify(state[stateSetLoc]));
      // }
      return {...state};
    case 'CART_SET_VAL':
      // NEED TO TEST CHANTING setStateFunction CALL TO NEW assign() FUNCTION
      const setValState = methods.setStateValue(state, action.name, action.value);
      if(setValState) {
        let stateSetLoc = action.name.includes('.') ? action.name.split('.')[0] : action.name;
        if(stateSetLoc.match(/\[(.*?)\]/g)) {
          stateSetLoc = "items";
        }
        let storageName = stateSetLoc.replace(/\[(.*?)\]/g,'');
        switch(stateSetLoc) {
          case null:
            break;
          case 'project':
            storageName = 'corePrjct';
            break;
          case 'items':
            storageName = 'coreCRT';
            break;
          default:
            storageName = `cart${stateSetLoc}`;
            break;
        }
        sessionStorage.setItem(storageName, JSON.stringify(state[stateSetLoc]));
      }
      return {...state};
    case 'CART_REMOVE_VAL':
      let newState = state;
      const locationNodes = action.payload.split('.');
      locationNodes.map((node, index) => {
        if(node.includes('[')) {
          const aNodes = node.split('[');
          aNodes.map((aNode, aIndex) => {
            if(newState && newState[aNode.replace(']', '')]) {
              if(index+1 < locationNodes.length && aIndex+1 < aNodes.length) {
                newState = newState[aNode.replace(']', '')]
              }else{
                console.log("removing ", aNode.replace(']', ''));
                delete newState[aNode.replace(']', '')];
              }
            }else{
              newState = null;
            }
            return true;
          })
        }else{
          if(newState && newState[node]) {
            if(index+1 < locationNodes.length) {
              console.log("Removing this", node);
              newState = newState[node];
            }else{
              delete newState[node];
            }
          }else{
            newState = null;
          }
        }
        return true;
      })

      return {...newState};

    case 'CART_UPDATE_INFO':
      const infoKeys = Object.keys(action.payload);

      const updateLocation = infoKeys[0].split('.');


      let updateVal = action.payload[infoKeys[0]];
      for(i = (updateLocation.length-1); i >= 0; i--) {
        let curVal = state[updateLocation[0]];
        for(j = 1; j <= i; j++) {
          curVal = curVal[updateLocation[j]];
        }

        if(i === (updateLocation.length-1)) {
          updateVal = {[updateLocation[i]]: updateVal};
        }else{
          updateVal = {[updateLocation[i]]: {...curVal, ...updateVal}}
        }
      }

      return {...state, ...updateVal};
    case 'CART_SET_PREF':
      console.log("{}", action);
      const prefLocation = action.prefName.split('.');
      let pref = action.payload;
      for(i = (prefLocation.length-1); i >= 0; i--) {
        let curVal = state[prefLocation[0]];
        for(j = 1; j < i+1; j++) {
          curVal = curVal[prefLocation[j]];
        }

        if(i === (prefLocation.length-1)) {
          pref = {[prefLocation[i]]: pref};
        }else{
          pref = {[prefLocation[i]]: {...curVal, ...pref}};
        }
      }
      return {...state, ...pref};

    case 'CART_TRANSFER_ITEMS_TO_ORDER':
      let orders = state;
      if(!state.project) {
        orders.project = {billing: {}, shipping: {}, orders: [{items:[]}]};
      }else if(!state.project.orders) {
        orders.project.orders = [{items: []}];
      }
      orders = state.project.orders;
      if(!orders[action.orderIndex] || !orders[action.orderIndex].items) {
        console.log("No Action Order Index", action.orderIndex, orders[action.orderIndex]);
      }
      if(orders[action.orderIndex].items && orders[action.orderIndex].items.length > 0) {
        orders[action.orderIndex].items = orders[action.orderIndex].items.concat(action.payload);
      }else{
        orders[action.orderIndex].items = action.payload;
      }
      return {...state, project: {...state.project, orders: orders}};
      // return {...state}
    case 'CART_REMOVE_ITEMS':
      let cartItems = [];

      state.items.map(item => {
        let keepItem = true;
        action.payload.map(rItem => {
          if(state.items.indexOf(rItem) >= 0) {
            keepItem = false;
          }
          return true;
        })
        if(keepItem) {
          cartItems.push(item);
        }
        return true;
      })

      if(cartItems.length > 0) {
        sessionStorage.setItem('coreCRT', JSON.stringify(cartItems));
      }else{
        sessionStorage.removeItem('coreCRT');
      }

      return {...state, items: cartItems.length === 0 ? null : cartItems};

    case 'CART_REMOVE_ITEM':
      let removeProject;
      if(action.orderIndex === 'cart') {
        removeProject = state.items;
        removeProject.splice(action.payload, 1);
        return {...state, items: removeProject};
      }else{
        removeProject = state.project;
        removeProject.orders[parseInt(action.orderIndex)].items.splice(action.payload, 1);
        return {...state, project: removeProject};
      }
    case 'CART_MOVE_ITEM':
      let moveProject;
      if(action.orderIndex === action.targetIndex) {
        return state;
      }else if (action.orderIndex === 'cart') {
        return state;
      }else{
        moveProject = state.project;
        moveProject.orders[action.orderIndex].items.splice(action.itemIndex, 1);
        if(moveProject.orders[action.targetIndex].items) {
          moveProject.orders[action.targetIndex].items.push(action.payload);
        }else{
          moveProject.orders[action.targetIndex]['items'] = [action.payload];
        }

        return {...state, project: moveProject};
      }
    case 'CART_GET_TAX':
      return {...state, taxRates: action.payload};
    case 'CART_GET_SHIPPING':
      return {...state, shippingRates: action.payload};
    case 'CART_ADD_ORDER':
      console.log("REDUCER", action.payload);
      if(action.payload > 0) {
        const newState = state;
        delete newState.project;
        return {...newState, order_id: action.payload}
      }else{
        return {...state, order_submit: action.payload}
      }
    // BASED ON ADMIN REDUCERS 
    // case 'CART_SET_VALUE':
    //   const setValState = methods.setStateValue(state, action.name, action.value);
    //   if(setValState) {
    //     const stateSetLoc = action.name.includes('.') ? action.name.split('.')[0] : action.name;
    //     let storageName = '';
    //     switch(stateSetLoc) {
    //       case null:
    //         break;
    //       case 'organization':
    //         storageName = 'admOrg';
    //         break;
    //       case 'product':
    //         storageName = 'admPrd';
    //         break;
    //       case 'user':
    //         storageName = 'admUsr';
    //         break;
    //       default:
    //         storageName = `adm${stateSetLoc}`;
    //         break;
    //     }
    //     action.sStore &&
    //     sessionStorage.setItem(storageName, JSON.stringify(state[stateSetLoc]));
    //   }
    //   return {...state};
    // case 'CART_DELETE_VAL':
    //   let removeValState;
    //   if(Array.isArray(action.payload)) {
    //     action.payload.map(location => {
    //       removeValState = methods.removeStateValue(state, location);
    //       return true;
    //     })
    //   }else{
    //     removeValState = methods.removeStateValue(state, action.payload);
    //   }
    //   if(removeValState) {
    //     return {...removeValState};
    //   }else{
    //     return {...state};
    //   }
    case 'CART_LOAD_STATE':
      let payload = action.payload;
      if(action.location.indexOf('.')) {
        const locationNodes = action.location.split('.');
        let valVar = null;
        for(var i = locationNodes.length - 1; i >= 0; i--) {
          let stateVal = state;
          for(var j = 0; j <= i; j++) {
            if(stateVal && stateVal[locationNodes[j]]) {
              stateVal = stateVal[locationNodes[j]];
            }else{
              stateVal = null;
            }
          }
          if(i === locationNodes.length-1) {
            valVar = {[locationNodes[i]]: action.payload};
          }else{
            if(stateVal) {
              valVar = {[locationNodes[i]]: {...stateVal, ...valVar}}
            }else{
              valVar = {[locationNodes[i]]: {...valVar}};
            }
          }
        }
        payload = valVar;
      }
      return {...state, ...payload};
    case 'CART_EMPTY':
      return {};
    default:
      return state;
  }
}


function compareObjects(object1, object2) {
  let match = true;
  if(object1.length !== object2.length) {
    match = false;
  }else{
    for (let [key, value] of Object.entries(object1)) {
      if(object2[key] !== value) {
        match = false;
      }
    }
  }
  return match;
}
