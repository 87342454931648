import React, {useEffect, useState} from 'react';

import IconSVG from 'components/icons/IconSVG';
import Form from 'components/forms/Form';
import Select from 'components/forms/elements/Select';
import Button from 'components/forms/elements/Button';
import Control from 'components/atoms/Control';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [task, setTask] = useState(null);
	const [group, setGroup] = useState(null);
	const [optionsStaffGroups, setOptionsStaffGroups] = useState(null);
	const [optionsOrgGroups, setOptionsOrgGroups] = useState(null);
	const [formActive, setFormActive] = useState(null);
	const [selected, setSelected] = useState(null);
	
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.languageCode && props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.organizationID && props.organizationID !== organizationID) {
			setOrganizationID(props.organizationID);
		}
	}, [props.organizationID]);
	
	useEffect(() => {
		if(props.task && props.task !== task) {
			setTask(props.task);
		}
	}, [props.task]);
	
	useEffect(() => {
		if(props.group && props.group !== group) {
			setGroup(props.group);
		}
	}, [props.group]);
	
	useEffect(() => {
		if(group && group.group_id !== selected) {
			setSelected(group.group_id);
		}
	}, [group]);
	
	useEffect(() => {
		if(props.optionsStaffGroups &&
			props.optionsStaffGroups !== optionsStaffGroups) {
			setOptionsStaffGroups(props.optionsStaffGroups);
		}
	}, [props.optionsStaffGroups]);
	
	useEffect(() => {
		if(props.optionsOrgGroups &&
			props.optionsOrgGroups !== optionsOrgGroups) {
			setOptionsOrgGroups(props.optionsOrgGroups);
		}
	}, [props.optionsOrgGroups]);
	
	// FUNCTIONS
	function handleSelect(param) {
		if(parseInt(param.value) > 0 &&
			parseInt(param.value) !== selected) {
			setSelected(parseInt(param.value));
		}else if((isNaN(parseInt(param.value)) || parseInt(param.value) <= 0) &&
				selected >= 0) {
				setSelected(null);
		} 
	}
	
	function assignGroup() {
		setFormActive(false);
		if(group && group.group_id) {
			if(selected > 0 && selected !== group.group_id) {
				const udLinkParams = [
					{name: 'process', value: 'update_link'},
					{name: 'user_id', value: userID},
					{name: 'token', value: token},
					{name: 'link_id', value: group.link_id},
					{name: 'link_from', value: selected},
					{name: 'link_published', value: true}
				];
				
				props.adminLoadState(udLinkParams, 'links', 'reload.current.task_groups');
			}
		}else{
			if(selected > 0) {
				const addLinkParams = [
					{name: 'process', value: 'add_link'},
					{name: 'user_id', value: userID},
					{name: 'token', value: token},
					{name: 'link_from', value: selected},
					{name: 'link_to', value: organizationID},
					{name: 'link_type', value: `${props.camelCase(task.task_name)}GroupToOrg`},
					{name: 'link_published', value: true},
					{name: 'return_bool', value: true}
				];
				
				props.adminLoadState(addLinkParams, 'links', 'reload.current.task_groups');
			}
		}
	}
	
	function handleCancel() {
		setFormActive(false);
	}
	
	return (
		task &&
		<div style={{marginBottom: '1em', borderBottom: '.125em solid #333333', borderRadius: '0em'}}>
			<div className="flex" style={{paddingBottom: '1em'}}>
				<span className={`${task.task_ignored ? 'strikethrough screen-40' : !task.task_published ? 'screen-60' : ''}`}>
					<b>
					{`${props.capitalizeString(task.task_name, true)}`}
					{`${task.task_type ? ` (${task.task_type})` : ''}:`}
					</b>
				</span>
				{group && group.group_name &&
					<span>
						{group.group_name}
					</span>
				}
			</div>
			<div>
				<Control 
					icon={formActive ? 'close' : 'group'} 
					label={formActive ? 
							'Close Group Selector' :
							group && group.group_name ?
							'Change Group' : 
							'Assign Group'} 
					showLabel={true}
					className="ctl-btn"
					iconClass="no-btn"
					action={()=>{setFormActive(formActive ? false : true)}} />
			</div>
			<div className={`expandable${formActive ? ` active` : ''}`}
				style={{paddingBottom: formActive ? '1em' : `0em`}}>
				{optionsStaffGroups &&
				<Form {...props}>
					<div className="form-row">
						Select the group to assign to this task
					</div>
					<div className="form-row">
						<Select {...props}
							name="assign_group"
							label="Assign Group"
							option_groups={[{options: optionsStaffGroups}]}
							defaultValue={group && group.group_id ? group.group_id : selected ? selected : null}
							returnObject={true}
							onChange={handleSelect} />
					</div>
					<div className="form-row">
						<Button 
							icon="cancel" 
							label="Cancel" 
							btnClass="bg" 
							btnAction={handleCancel} />
						<Button 
							icon="group" 
							btnClass="success-bg"
							label="Assign Group"
							btnAction={assignGroup} />
					</div>
				</Form>
				}
			</div>
		</div>
	)
}