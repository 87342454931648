import React, {useEffect, useState} from 'react';
import PagePublic from 'components/templates/PagePublic';

import UserAccount from 'components/account/UserAccount';

export default () => {
  return (
    <PagePublic>
      <UserAccount />
    </PagePublic>
  )
}
