import React, {useEffect, useState, Fragment} from 'react';

import Control from 'components/atoms/Control';
import FormShippingAddEdit from 'components/forms/common_forms/FormShippingAddEdit';
import ShippingBlock from 'components/profiles/shipping/ShippingBlock';


export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [task, setTask] = useState(null);
	const [project, setProject] = useState(null);
	const [order, setOrder] = useState(null);
	const [orderLocations, setOrderLocations] = useState(null);
	const [shippingCarriers, setShippingCarriers] = useState(null);
	const [activeShipping, setActiveShipping] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(props.userID &&
			props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token &&
			props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.languageCode &&
			props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.task &&
			props.task !== task) {
			setTask(props.task);
		}
	}, [props.task]);
	
	useEffect(() => {
		if(props.project && 
			props.project !== project) {
			setProject(props.project);
		}
	}, [props.project])
	
	useEffect(() => {
		if(props.order &&
			props.userID !== order) {
			setOrder(props.order);
		}
	}, [props.order]);
	
	useEffect(() => {
		if(props.shippingCarriers &&
			props.shippingCarriers !== shippingCarriers) {
			setShippingCarriers(props.shippingCarriers);
		}
	}, [props.shippingCarriers]);
	
	useEffect(() => {
		if(Array.isArray(props.objGetValue(order, `shipping.locations`)) &&
			order.shipping.locations !== orderLocations) {
			setOrderLocations(order.shipping.locations);
		}
	}, [order]);
	
	
	
	return (
		order &&
		<div className="card">
			{Array.isArray(order.shipping.locations) &&
			order.shipping.locations.map(loc => {
				return (
					<div className="block-border">
							{(loc.carrier_name || loc.shipping_tracking) &&
								<ShippingBlock {...props} location={loc} />
							}
							<div className={activeShipping === loc.location_id ?
											'block-border' : ''}
								style={
									{marginBottom: activeShipping === loc.location_id ?
													'1em' : '0em',
									marginTop: activeShipping === loc.location_id ?
									'1em' : '0em',
									}
								}>
								<Control 
									icon={activeShipping === loc.location_id ? 
											'close' : 'shipping'} 
									action={()=>{setActiveShipping(
										activeShipping === loc.location_id ?
										null : loc.location_id)}}
									label={activeShipping === loc.location_id ? 
											'Close' : 
											(loc.carrier_id || 
												loc.shipping_tracking || 
												loc.shipping_started) ? 
												'Edit Shipping' : 'Add Shipping'}
									showLabel={true}
									className="ctl-btn info-bg"
									iconClass="no-btn" />
								<div className={`expandable${activeShipping === loc.location_id ? ' active' : ''}`} >
									<FormShippingAddEdit {...props}
										userID={userID}
										token={token}
										languageCode={languageCode}
										location={loc}
										shippingCarriers={shippingCarriers}
										stateLocation={props.stateLocation}
										resultLocation={`reload.project`}
										onUpdate={()=>{setActiveShipping(null);}} />
								</div>
							</div>
							<div className="card-block">
								<div className="subtext">Ship to:</div>
								{loc.location_name &&
								<div>
									<b>{loc.location_name}</b>
								</div>
								}
								<div>{`${loc.location_address_1}${
									loc.location_address_2 ? 
										` - ${loc.location_address_2}` :
										''
								}`}</div>
								<div>
									{`${loc.location_city}, ${loc.location_province}`}
								</div>
								<div>
									{`${loc.location_country}  ${loc.location_postal_code}`}
								</div>
								<div className="subtext" style={{marginTop: '1em'}}>Attn:</div>
								<div>
									<b>{`${loc.contact_name_first} ${loc.contact_name_last}`}</b>
								</div>
								<div>
									{loc.contact_phone}
								</div>
								<div>
									<a href={`mailto:${loc.contact_email}`} target="_blank">
										{loc.contact_email}
									</a>
								</div>
							</div>
							
							<div className="card-block" style={{marginTop: '1em'}}>
								<h4>Items</h4>
								
								<div className="grid" style={{gridTemplateColumns: 'auto 1fr auto auto'}}>
								<div className="grid-header">Qty</div>
								<div className="grid-header">Item</div>
								<div className="grid-header">Code</div>
								<div className="grid-header">SKU</div>
								{Object.entries(loc.items).length > 0 &&
								Object.entries(loc.items).map(lqty => {
									const item = order.items.find(oItem => oItem.item_id === parseInt(lqty[0]));
									return (
										<Fragment>
											<div className="column">
												{item.product_quantity}
											</div>
											<div className="column">
												{`${props.capitalizeString(item.product_name, true)}${
													item.product_nickname ? ` (${props.capitalizeString(item.product_nickname, true)})` : ''
												}`}
											</div>
											<div className="column">
												{item.details[languageCode].product_code}
											</div>
											<div className="column">
												{item.details[languageCode].product_sku}
											</div>
										</Fragment>
									)
								})}
							</div>
						</div>
					</div> // BORDER BLOCK END
				)
			})
		// LOCATIONS LOOP ENDS	
		} 
	
	</div>
	)
}