import React, {useEffect, useState, Fragment} from 'react';
import ProductCharge from 'components/profiles/products/ProductCharge';

export default (props) => {
	
	function addEditCharge(params) {
		if(props.actionAddEdit) {
		  props.actionAddEdit(params);
		}
	  }
	
	function removeCharge(params) {
		if(props.actionRemove) {
			props.actionRemove(params);
		}
	}
	
	return (
		<div className="margin-right-2em">
			{props.charges && Array.isArray(props.charges) && props.charges.length > 0 && (
				<Fragment>
					<div className={`grid col-${props.editable ? '6' : '5'}`} style={{gridTemplateColumns: `${props.editable ? '1fr 1fr 1fr 0.5fr 0.5fr 0.5fr' : '1fr 1fr 0.5fr 0.5fr 0.5fr'}`}}>
						<div className="grid-header">Name</div>
						<div className="grid-header text-center">Display</div>
						<div className="grid-header text-center">Field</div>
						<div className="grid-header text-center">Value</div>
						<div className="grid-header text-center">Multiply</div>
						{props.editable && <div className="grid-header text-center">&nbsp;</div>}
						{props.charges.map((charge, index) => {
							return (
								<ProductCharge {...props}
									key={`gPrdChrg${index}`}
									product_id={props.product.product_id}
									charge={charge}
									fields={props.fields}
									editable={props.editable}
									actionAddEdit={addEditCharge}
									actionRemove={removeCharge} />
							)}
						)}
					</div>
				</Fragment>
			)}
		</div>
	)
}
