export const setValueErrors = (errors) => dispatch => {
  dispatch({type: 'FORM_VALUE_ERRORS', payload: errors});
}

export const saveFormInfo = (formData) => dispatch => {
  console.log("form data: ", formData);
  let formInfo = {};
  formData = formData.split('&');
  let formType = 'generic';
  formData.map(info => {
    const infoPair = info.split('=');
    if(infoPair[0] === 'form-type') {
      formType = decodeURIComponent(infoPair[1].replace(/\+/g, ""));
      formType = formType.replace('-', '');
    }else{
      formInfo[infoPair[0]] = decodeURIComponent(infoPair[1].replace(/\+/g, " "));
    }
  })

  dispatch({type: 'SAVE_FORM_INFO', formType: formType, payload: formInfo});
  dispatch({type: 'SET_MODAL', payload: null});
}

export const clearFormInfo = (formName) => dispatch => {
  dispatch({type: 'CLEAR_FORM_INFO', formName: formName});
}

export const formSetValue = (name, value) => dispatch => {
  dispatch({type: 'FORM_SET_VAL', name: name, value: value});
}

export const formRemoveValue = (infoLocation) => dispatch => {
  dispatch({type: 'FORM_REMOVE_VAL', payload: infoLocation});
}
