import React, {useEffect, useState}  from 'react';
import { useParams } from "react-router";

import PageInternal from 'components/templates/PageInternal';
import Dashboard from 'components/dashboard/Dashboard';

export default () => {

  return (
    <PageInternal>
      <Dashboard />
    </PageInternal>
  )
}
