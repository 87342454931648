import React, {useEffect, useState, Fragment} from 'react';

import Loader from 'components/atoms/Loader';
import ModuleSet from 'components/profiles/modules/ModuleSet';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [groupID, setGroupID] = useState(null);
	const [coreUser, setCoreUser] = useState(null);
	const [allModules, setAllModules] = useState(null);
	const [groupModules, setGroupModules] = useState(null);
	const [modules, setModules] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
		  setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token !== token) {
		  setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.languageCode &&
		  props.languageCode !== languageCode) {
		  setLanguageCode(props.languageCode);
		}else if(!languageCode) {
		  setLanguageCode('en');	
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.groupID && props.groupID !== groupID) {
			setGroupID(props.groupID);
		}
	}, [props.groupID])
	
	useEffect(() => {
		if(userID && token) {
			const modParams = [
				{name: 'process', value: 'get_site_modules'},
				{name: 'user_id', value: userID},
				{name: 'token', value: token}
			];
			
			if(!props.admin.modules || props.objGetValue(props.admin, 'reload.modules') === true) {
				props.adminLoadState(modParams, 'site', 'modules');
			}else{
				setTimeout(()=>{
					props.adminLoadState(modParams, 'site', 'modules');
				}, 600000);
			}
		}
	}, [userID, token, props.admin.reload]);
	
	// 3cidG3rz
	
	useEffect(() => {
		if(userID && token && groupID) {
			const gModParams = [
				{name: 'process', value: 'get_site_modules'},
				{name: 'user_id', value: userID},
				{name: 'token', value: token},
				{name: 'group_id', value: groupID}
			]
			console.log("SHOULD WE RELOAD?", props.objGetValue(props.admin, 'reload.groupModules') === true);
			if(!props.objExists(props.admin, 'group.modules') || 
				props.objGetValue(props.admin, 'reload.groupModules') === true) {
				props.adminLoadState(gModParams, 'site', 'group.modules');	
			}else{
				setTimeout(() => {
					props.adminLoadState(gModParams, 'site', 'group.modules');
				}, 600000);
			}
		}
	}, [userID, token, groupID, props.admin.reload]);
	
	useEffect(() => {
		if(props.admin.modules && 
			props.admin.modules !== allModules) {
			setAllModules(props.admin.modules);
		}
	}, [props.admin.modules]);
	
	useEffect(() => {
		if(props.objExists(props.admin, 'group.modules') &&
			props.admin.group.modules !== groupModules) {
			setGroupModules(props.admin.group.modules);
		}
	}, [props.admin.group]);
	
	useEffect(() => {
		if(allModules && groupModules) {
			const activeMods = findActiveModules(allModules, groupModules);
			if(activeMods) {
				setModules(activeMods);
			}
		}	
	}, [allModules, groupModules]);
	
	// FUNCTIONS
	function findActiveModules(modules, activeModules) {
		if(Array.isArray(modules) && Array.isArray(activeModules)) {
			modules.map((module, index) => {
				const matchModule = activeModules.find(mod => mod.module_id === module.module_id);
				if(matchModule) {
					modules[index]['module_active'] = true;
					if(module.modules && Array.isArray(module.modules)) {
						modules[index][modules] = findActiveModules(modules[index].modules, matchModule.modules);
					}
				}
			})
			return modules;
		}else{
			return modules;
		}
	}
	
	function handleActivate(module, activate) {
		if(activate) {
			moduleActivate(module);
		}else{
			moduleDeactivate(module);
		}
	}
	
	function moduleDeactivate(module) {
		let moduleParams = [
			{name: 'process', value: 'update_links'},
			{name: 'user_id', value: userID},
			{name: 'token', value: token},
			{name: 'link_from', value: module.module_id},
			{name: 'link_field', value: 'link_removed'},
			{name: 'link_value', value: 1}
		];
		if(props.groupID) {
			moduleParams.push(
				{name: 'link_type', value: 'moduleToGroup'},
				{name: 'link_to', value: props.groupID},
			)
		}else if(props.coreUser) {
			moduleParams.push(
				{name: 'link_type', value: 'moduleToUser'},
				{name: 'link_to', value: props.coreUser},
			);
		}
		props.adminLoadState(moduleParams, 'links', 'reload.groupModules');
	}
	
	function moduleActivate(module) {
		
		let moduleParams = [
			{name: 'process', value: 'link_site_module'},
			{name: 'user_id', value: userID},
			{name: 'token', value: token},
			{name: 'module_id', value: module.module_id}
		];
		if(props.groupID) {
			moduleParams.push(
				{name: 'group_id', value: props.groupID},
			);
		}
		if(props.coreUser) {
			moduleParams.push(
				{name: 'core_user', value: props.coreUser},
			);
		}
		console.log("ACTIVATE", moduleParams);
		props.adminLoadState(moduleParams, 'site', 'reload.groupModules');
		}
	
	return (
		<Fragment>
			{languageCode && modules && Array.isArray(modules) ?
				<ModuleSet {...props}
							modules={modules}
							languageCode={languageCode}
							actionActivate={handleActivate} />
			:
				<Loader label="Loading modules..." />
			}
		</Fragment>
	)
}