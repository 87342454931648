import React, {useEffect, useState} from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import IconToken from 'components/icons/IconToken';
import Select from 'components/forms/elements/Select';
import LiveFieldInput from 'components/forms/elements/LiveFieldInput';
import InputNumberCtl from 'components/forms/elements/InputNumberCtl';
import ColourSelector from 'components/forms/elements/ColourSelector';
import Control from 'components/atoms/Control';

export default (props) => {
	const [fieldID, setFieldID] = useState(null);
	const [type, setType] = useState(null);
	const [contents, setContents] = useState(null);
	const [styleActive, setStyleActive] = useState(null);
	const [font, setFont] = useState(null);
	const [fieldFont, setFieldFont] = useState(null);
	const [optionsFonts, setOptionsFonts] = useState(null);
	const [fontColour, setFontColour] = useState(null);
	const [reset, setReset] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(props.fieldID && 
			props.fieldID !== fieldID) {
			setFieldID(props.fieldID);	
		}
	}, [props.fieldID]);
	
	useEffect(() => {
		if(props.type && 
			props.type !== type) {
			setType(props.type);	
		}
	}, [props.type]);
	
	useEffect(() => {
		if(props.contents && 
			props.contents !== contents) {
			setContents(props.contents);	
		}
	}, [props.contents]);
	
	useEffect(() => {
		if(props.font && 
			props.font !== font) {
			setFont(props.font);	
		}
	}, [props.font]);
	
	useEffect(() => {
		if(props.optionsFonts && 
			props.optionsFonts !== optionsFonts) {
			setOptionsFonts(props.optionsFonts);	
		}
	}, [props.optionsFonts]);
	
	useEffect(() => {
		if(props.fieldFont && 
			props.fieldFont !== fieldFont) {
			setFieldFont(props.fieldFont);	
		}
	}, [props.fieldFont]);
	
	useEffect(() => {
		if(props.objExists(font, `font_color`)) {
			setFontColour(props.setRGBArray(font.font_color));
		}else if(props.objExists(fieldFont, `font_color`)) {
			setFontColour(props.setRGBArray(fieldFont.font_color));
		}
	}, [font, fieldFont]);
	
	// FUNCTIONS
	function updateFont(params) {
		console.log("UPDATE APPENDAGE", params);
		if(props.actionUpdate) {
			props.actionUpdate(params);
		}
	}
	
	function updateFontColour(params) {
		console.log("UPDATE APPENDAGE COLOUR", params);
	}
	
	return (
		type && contents &&
		<div className="block-border">
			<h4>{props.capitalizeString(type)}</h4>
			<div style={{
				display: 'flex',
				alignItems: 'center'
			}}>
				<div style={{
					flex: '0 0 auto',
					}}>
					<b>Contents: </b>
					<span className="caution-bg"
						style={{
							borderRadius: '0em',
							padding: '.125em'
						}}>
						{ReactHtmlParser(props.encodeHTMLSpaces(contents))}
					</span>
				</div>
				<div className="ctl-row"
					style={{
						flex: '1 0 auto',
						marginLeft: '1em',
						display: 'flex',
						alignItems: 'center'
					}}>
					<IconToken icon="edit" tooltip="remove" />
					<IconToken icon="trash" tooltip="remove" />
				</div>
			</div>
			<div style={{marginTop: '1em'}}>
				<Control 
					icon={styleActive ? 'collapse' : 'expand'}
					label={`${styleActive ? 'Hide' : 'Show'} Font Styles`}
					showLabel={true}
					className="ctl-btn"
					iconClass="no-btn"
					action={()=>{setStyleActive(styleActive ? false : true)}} />
					<div className={`expandable${styleActive ? ' active' : ''}`}>
						
						{optionsFonts &&
						<div className="form-row">
							<Select {...props}
								name={`fonts.${type}.font_name`}
								defaultValue={props.objExists(font, 'font_name') ?
												font.font_name : 
												props.objExists(fieldFont, 'font_name') ? 
													fieldFont.font_name : 'helvetica'}
								label="Font Face"
								option_groups={[
									{options: optionsFonts}
								]}
								onChange={updateFont}
								returnObject={true} />
						</div>
						}
						
						<div className="form-row">
							<InputNumberCtl 
								{...props}
								label="Font Size"
								name={`fonts.${type}.font_size`}
								value={props.objExists(font, `font_size`) ?
										font.font_size : 
											props.objExists(fieldFont, `font_size`) ?
												fieldFont.font_size :
													10
								 }
								reset={reset}
								id={fieldID}
								onChange={updateFont}
								float={2}
								factor={.1}
								orientation="vertical" />
						</div>
						<div className="block-border">
							<h4>Colour</h4>
							<div className="flex">
								<div style={{flex: '1 0 10em',
											borderRadius: '0em', 
											paddingRight: '1em',
											marginRight: '1em',
											borderRight: '.125em solid #333333'}}>
									<ColourSelector {...props}
										name ={`fonts.${type}.font_color`}
										label="Font Colour"
										value={fontColour}
										actionOnChange={updateFontColour}
										/>
								</div>
								<div style={{flex: '1 0 5em',
											padding: '1em'}}>
									{fontColour &&
										<div style={{
											width: '5em',
											height: '5em',
											border: '.125em solid #333333',
											backgroundColor: `rgb(${fontColour.r}, ${fontColour.g}, ${fontColour.b})`
										}}>
										</div>
									}
								</div>
							</div>
						</div>
						
					</div>
			</div>
		</div>
	)
}