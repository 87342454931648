import React, { useState, useEffect, Fragment } from "react";

import IconToken from "components/icons/IconToken";
import DropZone from "components/forms/elements/DropZone";

export default (props) => {
  const [optionRootID, setOptionRootID] = useState(null);
  const [options, setOptions] = useState(null);
  const [optionMap, setOptionMap] = useState(null);
  const [activeOption, setActiveOption] = useState(null);
  const [move, setMove] = useState(null);

  useEffect(() => {
    if (props.options) {
      setOptionRootID(props.options.option_id);  
      setOptions(props.options);
    }
  }, [props.options]);
  
  useEffect(() => {
    if (options) {
      setOptionMap(<ul className="option-map">{buildMap(options)}</ul>);
    }
  }, [options]);

  useEffect(() => {
    if (activeOption && move && props.actionMove) {
      props.actionMove(activeOption, move);
      setActiveOption(null);
      setMove(null);
    }
  }, [activeOption, move]);

  // FUNCTIONS
  function buildMap(options, isChild = false) {
    if (Array.isArray(options)) {
      return options.map((option, index) => {
        return (
          <Fragment>
            {isChild && index === 0 && (
              <DropZone
                key={`omDZ${index}`}
                index={index + 1}
                params={{
                  option_parent: option.option_parent,
                  option_order: option.option_order,
                }}
                actionDrop={handleOptionMove}
              />
            )}
            <li
              className={isChild && options.length === 1 ? " single" : ""}
              onDragEnter={isChild ? expandContents : null}
              // onDragLeave={isChild ? collapseContents : null}
            >
              <div
                className={`option-node`}
                id={`option${option.option_id}`}
                draggable={isChild && props.actionMove ? true : false}
                onDragStart={(e) => {
                  handleDrag(e, option.option_id);
                }}
                onDragEnd={(e) => {
                  handleDrag(e, option.option_id);
                }}
                onDrop={
                  !option.options ||
                  (option.options &&
                    Array.isArray(option.options) &&
                    option.options.lenght <= 0)
                    ? (e) => {
                        handleDropInto(e, option.option_id);
                      }
                    : null
                }
                onDragEnter={
                  !option.options ||
                  (option.options &&
                    Array.isArray(option.options) &&
                    option.options.lenght <= 0)
                    ? allowDrop
                    : null
                }
                onDragOver={
                  !option.options ||
                  (option.options &&
                    Array.isArray(option.options) &&
                    option.options.lenght <= 0)
                    ? dragOver
                    : null
                }
                onDragLeave={
                  !option.options ||
                  (option.options &&
                    Array.isArray(option.options) &&
                    option.options.lenght <= 0)
                    ? dragOut
                    : null
                }
              >
                {isChild && options.length === index + 1 && props.actionAdd && (
                  <IconToken
                    icon="add"
                    className={`btn add-node`}
                    action={() => {
                      props.actionAdd(
                        null,
                        option.option_parent ? option.option_parent : null
                      );
                    }}
                    tooltip={`Add ${
                      props.option_type
                        ? props.capitalizeString(props.option_type)
                        : "Option"
                    }`}
                  />
                )}
                <div className="option-node-row">
                  <h4>
                    {option.options &&
                      Array.isArray(option.options) &&
                      option.options.length > 0 && (
                        <IconToken
                          icon="arrow"
                          className={!props.collapse ? "active" : ""}
                          action={toggleChildren}
                        />
                      )}
                    {option.option_name}
                  </h4>
                  {(props.actionEdit || props.actionRemove) && (
                    <div className="ctl">
                      {props.actionEdit && (
                        <IconToken
                          icon="edit"
                          className="btn"
                          action={() => {
                            props.actionEdit(option.option_id);
                          }}
                          tooltip={`Edit ${
                            props.option_type
                              ? props.capitalizeString(props.option_type)
                              : "Option"
                          }`}
                        />
                      )}
                      {props.actionRemove && isChild && (
                        <IconToken
                          icon="trash"
                          className="btn"
                          action={() => {
                            props.actionRemove(option.option_id);
                          }}
                          tooltip={`Remove ${
                            props.option_type
                              ? props.capitalizeString(props.option_type)
                              : "Option"
                          }`}
                        />
                      )}
                      {!option.options && (
                        <IconToken
                          icon="return"
                          className="btn"
                          action={() => {
                            props.actionAdd(null, option.option_id);
                          }}
                          tooltip={`Add Child ${
                            props.option_type
                              ? props.capitalizeString(props.option_type)
                              : "Option"
                          }`}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
              {option.options && (
                <ul
                  className={`option-node-options${
                    !props.collapse ? " active" : ""
                  }`}
                >
                  {buildMap(option.options, true)}
                </ul>
              )}
            </li>
            {isChild && (
              <DropZone
                key={`omDZ${index}`}
                index={index + 2}
                params={{
                  option_parent: option.option_parent,
                  option_order: option.option_order + 1,
                }}
                actionDrop={handleOptionMove}
              />
            )}
          </Fragment>
        );
      });
    } else if (options && Object.entries(options).length > 0) {
      return (
        <li>
          <div className="option-node">
            <h4>{options.option_name}</h4>
          </div>
          {options.options && (
            <ul className="option-node-options active">{buildMap(options.options, true)}</ul>
          )}
        </li>
      );
    } else {
      return;
    }
  }

  function handleDrag(e, option_id) {
    // console.log("Draging into ", e.dataTransfer)
    // e.dataTransfer.setData("text", e.target.id);
    // e.dataTransfer.effectAllowed = "move";

    let target = e.target;
    while (
      target.tagName.toLowerCase() !== "li" &&
      target.tagName.toLowerCase() !== "body"
    ) {
      target = target.parentNode;
    }

    if (target.tagName.toLowerCase() === "li") {
      if (e.type === "dragstart") {
        setActiveOption(option_id);
        !target.classList.contains("drag-item") &&
          target.classList.add("drag-item");
      } else if (e.type === "dragend") {
        target.classList.contains("drag-item") &&
          target.classList.remove("drag-item");
      }
    }
  }

  // FOR DRAGGING INTO A DROP ZONE
  function handleOptionMove(option_parent, params) {
    setMove(params);
    const activeDZ = document.querySelectorAll(".drop-zone.target");

    activeDZ.forEach((dz) => {
      if (dz.classList.contains("target")) {
        dz.classList.remove("target");
      }
    });
  }

  // FOR DROPPING OVER A NODE WITH NO CHILDREN
  function handleDropInto(e, option_parent) {
    setMove([
      { name: "option_parent", value: option_parent },
      { name: "option_order", value: 1 },
    ]);
    const activeDZ = document.querySelectorAll(".drop-zone.target");

    activeDZ.forEach((dz) => {
      if (dz.classList.contains("target")) {
        dz.classList.remove("target");
      }
    });
  }

  function allowDrop(e) {
    e.preventDefault();
  }

  function dragOver(e) {
    let target = e.target;
    while (
      !target.classList.contains("option-node") &&
      target.tagName.toLowerCase() !== "body"
    ) {
      target = target.parentNode;
    }
    if (target.classList.contains("option-node")) {
      !target.classList.contains("target") && target.classList.add("target");
    }
  }

  function dragOut(e) {
    let target = e.target;
    while (
      !target.classList.contains("option-node") &&
      target.tagName.toLowerCase() !== "body"
    ) {
      target = target.parentNode;
    }
    if (target.classList.contains("option-node")) {
      target.classList.contains("target") && target.classList.remove("target");
    }
  }

  // FOR CLICKING TOGGLE EXPAND
  function toggleChildren(e) {
    let target = e.target;
    while (
      target.tagName.toLowerCase() !== "li" &&
      target.tagName.toLowerCase() !== "body"
    ) {
      target = target.parentNode;
    }

    if (target.tagName.toLowerCase() === "li") {
      const optionChildren = target.querySelectorAll("ul.option-node-options");
      const optionTokens = target.querySelectorAll(
        ".option-node h4 .icon-token"
      );
      optionChildren.forEach((optionChild) => {
        optionChild.classList.contains("active")
          ? optionChild.classList.remove("active")
          : optionChild.classList.add("active");
      });
      optionTokens.forEach((oToken) => {
        oToken.classList.contains("active")
          ? oToken.classList.remove("active")
          : oToken.classList.add("active");
      });
    }
  }

  // FOR DRAG INTO:
  function expandContents(e) {
    let target = e.target;
    while (
      target.tagName.toLowerCase() !== "li" &&
      target.tagName.toLowerCase() !== "body"
    ) {
      target = target.parentNode;
    }

    if (target.tagName.toLowerCase() === "li") {
      const optionChildren = target.querySelectorAll("ul.option-node-options");
      const optionTokens = target.querySelectorAll(
        ".option-node h4 .icon-token"
      );
      optionChildren.forEach((optionChild) => {
        !optionChild.classList.contains("active") &&
          optionChild.classList.add("active");
      });
      optionTokens.forEach((oToken) => {
        !oToken.classList.contains("active") && oToken.classList.add("active");
      });
    }
  }

  // FOR DRAG OUT OF:
  function collapseContents(e) {
    let target = e.target;
    while (
      target.tagName.toLowerCase() !== "li" &&
      target.tagName.toLowerCase() !== "body"
    ) {
      target = target.parentNode;
    }

    if (target.tagName.toLowerCase() === "li") {
      setTimeout(() => {
        const optionChildren = target.querySelectorAll(
          "ul.option-node-options"
        );
        const optionTokens = target.querySelectorAll(
          ".option-node h4 .icon-token"
        );
        optionChildren.forEach((optionChild) => {
          optionChild.classList.contains("active") &&
            optionChild.classList.remove("active");
        });
        optionTokens.forEach((oToken) => {
          oToken.classList.contains("active") &&
            oToken.classList.remove("active");
        });
      }, 1000);
    }
  }

  return optionMap;
};
