import React, {useEffect, useState} from 'react';

import IconToken from 'components/icons/IconToken';

export default (props) => {
	
	function handleRemove() {
		if(props.actionRemove) {
			props.actionRemove(props.taskID, null, props.group.group_id);
		}
	}
	
	function handleAssign() {
		if(props.actionAssign) {
			props.actionAssign(props.taskID, null, props.group.group_id);
		}
	}
	
	return (
		<div className="task-user">
			<label>{props.objExists(props.group, `${props.languageCode ? props.languageCode : 'en'}.group_name`) ? props.group[props.languageCode ? props.languageCode : 'en'].group_name : 
					props.group.group_name ? props.group.group_name : 'Group'}</label>
			<div className="ctl">
				<IconToken icon={props.actionRemove ? 'close' : 'add'} 
							className="btn"
							tooltip={props.actionRemove ? 'Remove group' : 'Assign group'} 
							action={props.actionRemove ? handleRemove : handleAssign} />
			</div>
		</div>
	)
}