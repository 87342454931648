import React, {useEffect, useState, Fragment} from 'react';

import IconToken from 'components/icons/IconToken';
import MenuSetItem from 'components/navigation/MenuSetItem';
import MenuSetComponent from 'components/navigation/MenuSetComponent';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [modules, setModules] = useState(null);
  
  const topOffset = String(4*props.setCount+6)+'em';
  const indexStyle = {top: topOffset,};
  
  useEffect(() => {
    if(props.userID && props.userID !== userID) {
      setUserID(props.userID);
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token && props.token !== token) {
      setToken(props.token);
    }
  }, [props.token]);
  
  useEffect(() => {
    if(props.organizationID && props.organizationID !== organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    if(props.languageCode && props.languageCode !== languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    if(props.module.modules && props.module.modules !== modules) {
      setModules(props.module.modules);
    }
  }, [props.module.moduless]);
  
  // console.log("MENU SET MODULE", props.languageCode, props.module);
  return (
      <Fragment>
        {languageCode && modules &&
          <Fragment>
            <div className={`menu-index${props.active ? ' active' : ''}`} 
                  style={indexStyle}
                  id={props.module[languageCode].module_name} 
                  onClick={props.actionActivate} >
              <IconToken icon={props.module.module_icon} />
              <h5>{props.module[languageCode ? languageCode : 'en'].module_name}</h5>
            </div>
            <div className={`menu-options`}>
              {props.module.module_component ?
                <MenuSetComponent {...props} 
                                  moduleComponent={props.module.module_component}
                                  module={props.module}
                                  modulePlaceholder={props.module[languageCode ? languageCode : 'en'].module_name}
                                  languageCode={languageCode} />
              :
                <h4>{props.module[languageCode ? languageCode : 'en'].module_name}</h4>
              }
              { props.module.modules && 
                Array.isArray(props.module.modules) &&
                props.module.modules.map((module, index) => {
                  return (
                    <MenuSetItem {...props}
                                  key={`${props.module.module_name}-menu-link-${index}`}
                                  module={module}
                                  userID={userID}
                                  token={token}
                                  organizationID={organizationID}
                                  languageCode={languageCode}  />
                  )
                })}
                {props.content && props.content.map( (article, key) => {
                  return (
                    <article className="menu-content" key={`${props.name}-menu-article-${key}`}>
                      {article}
                    </article>
                  )
                })}
            </div>
          </Fragment>
        }
      </Fragment>
  )
}
