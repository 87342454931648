import React, {useEffect, useState, Fragment} from 'react';

import Link from 'components/navigation/Link';
import Control from 'components/atoms/Control';
import IconSVG from 'components/icons/IconSVG';
import Loader from 'components/atoms/Loader';

export default (props) => {
  const [userRole, setUserRole] = useState(null);
  const [emlStatus, setEmlStatus] = useState(null);
  const [userID, setUserID] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [token, setToken] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);

  useEffect(() => {
    props.userID &&
    setUserID(props.userID);
  }, [props.userID]);

  useEffect(() => {
    props.token &&
    setToken(props.token);
  }, [props.token])

  useEffect(() => {
    props.organizationID &&
    setOrganizationID(props.organizationID);
  }, [props.organizationID]);

  useEffect(() => {
    props.organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);

  useEffect(() => {
    props.languageCode &&
    setLanguageCode(props.languageCode);
  }, [props.languageCode]);

  useEffect(() => {
    if(props.objExists(props.user,`user_roles.${organizationID}.role_name`)) {
      setUserRole(props.user.user_roles[organizationID].role_name);
    }else if(props.objExists(props.user, 'user_roles') && Object.entries(props.user.user_roles).length > 0) {
      setUserRole(Object.entries(props.user.user_roles)[0][1].role_name);
    }
  }, [props.user, organizationID]);

  useEffect(() => {
    if(props.objExists(props.admin, `email_${props.user.user_id}`)) {
      if(props.admin[`email_${props.user.user_id}`] === 'Success') {
        props.notifyUser({icon: 'email',
                          title: 'Email Sent!',
                          message: `An email was sent to ${props.user.contact_name_first} with their login information`});
        setEmlStatus('sent');
      }else{
        console.log("Email didn't go.", props.admin[`email_${props.user.user_id}`]);
        setEmlStatus('error');
        props.notifyUser({icon: 'alert',
                          title: 'Email Error',
                          message: `The email did not send. Please try again. ERROR: ${props.admin[`email_${props.user.user_id}`]}`});
        props.adminDeleteVal(`email_${props.user.user_id}`);
      }
    }
  }, [props.admin[`email_${props.user.user_id}`]])

  function sendEml() {
    const params = [
      {name: 'process', value: 'eml_send_user_new'},
      {name: 'user_id', value: userID},
      {name: 'token', value: token},
      {name: 'core_user', value: props.user.user_id},
      {name: 'organization_name', value: props.user.organization},
    ]
    props.adminLoadState(params, 'mail', `email_${props.user.user_id}`);
    setEmlStatus('sending');
  }

  return (
    <div className="list-item user" >
      {props.user &&
        <Fragment>
          <div className="list-item-entry user-name">
          <b>
            <Link to={`/${props.location}/users/user/${props.user.user_id}`}>
              {`${props.user.user_name_first ? props.user.user_name_first : 
                  props.user.contact_name_first ? props.user.contact_name_first : 'First'} ${props.user.contact_name_last}`}
            </Link>
          </b>
          </div>
          <div className="list-item-entry user-role">
            {props.user.user_role ? props.user.user_role : 'n/a'}
          </div>
          <div className="list-item-entry user-email">
            {props.user.contact_email}
          </div>
          {props.showAll &&
            <div className="list-item-entry user-org">
              {props.objExists(props.user, 'organization') ?
                props.user.organization
              :
                <span className='error'>NO ORG!</span>
              }
            </div>
          }
          <div className="list-item-entry ctl">
            {props.objExists(props.user, 'user_roles.1.role_name') &&
              ['Super User'].includes(props.user.user_roles['1'].role_name) &&
              emlStatus !== 'sending' &&
              <Control icon="email"
                        action={sendEml}
                        label={emlStatus === 'sent' ? 'Resend Info' : 'Send Info'}
                        showLabel={true} />
            }
            {emlStatus === 'sending' && <Loader className="mini" />}
          </div>
        </Fragment>
      }

    </div>
  )
}
