import React, { useEffect, useState, useRef} from 'react';

import IconToken from 'components/icons/IconToken';

export default (props) => {
  const [isActive, setIsActive] = useState(false);
  
  const refTooltip = useRef();
  const refIcon = useRef();
  
  useEffect(() => {
    if(props.active && !isActive) {
      setIsActive(true);
    }
  }, [props.active])

  useEffect(() => {
    if(props.tooltip) {
      // console.log("REFS", refTooltip.current, refIcon.current.parentNode.style.overflow);
      // if(reIcon.current.)
    }
  }, [props.tooltip]);
  
  function handleClick(e) {
    if(isActive) {
      setIsActive(false);
    }else{
      setIsActive(true);
    }
    props.action(e);
  }
  
  

  return (
    <div className={`icon-toggle`} ref={refIcon}>
    <IconToken
      icon={props.icon ? props.icon : 'arrow'}
      className={`${isActive ? ` active` : ''}${props.horizontal ? ` horizontal` : ''}${props.className ? ` ${props.className}` : ''}`}
      action={handleClick} />
    {props.tooltip && 
      <div className="tooltip" ref={refTooltip}>
        {`${isActive ? 'Hide' : 'Show'} ${props.tooltip}`}
      </div>}
    </div>
  )
}
