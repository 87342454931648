import React, {useEffect, useState, Fragment} from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import Card from 'components/atoms/Card';
import CardContentContact from 'components/molecules/CardContentContact';
import LocationBlock from 'components/profiles/locations/LocationBlock';
import IconToken from 'components/icons/IconToken';
import FormLocationAddEdit from 'components/forms/common_forms/FormLocationAddEdit';
import FormContactAddEdit from 'components/forms/common_forms/FormContactAddEdit';
import RadioBtn from 'components/forms/elements/RadioBtn';
import LiveFieldInput from 'components/forms/elements/LiveFieldInput';
import ProjectPricing from 'components/cart/ProjectPricing';

import * as utils from 'methods/site';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [isDigital, setIsDigital] = useState(null);
	const [project, setProject] = useState(null);
	const [location, setLocation] = useState(null);
	const [locations, setLocations] = useState(null);
	const [items, setItems] = useState(null);
	const [shippingOptions, setShippingOptions] = useState(null);
	const [shippingMethod, setShippingMethod] = useState(null);
	const [shippingInfo, setShippingInfo] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
		  setUserID(props.userID);
		}
	}, [props.userID]);
	  
	useEffect(() => {
		if(props.token && props.token !== token) {
		  setToken(props.token);
		}
	}, [props.token]);
	  
	useEffect(() => {
		if(props.organizationID && props.organizationID !== organizationID) {
		  setOrganizationID(props.organizationID);
		}
	}, [props.organizationID]);
	  
	useEffect(() => {
		if(props.languageCode && props.languageCode !== languageCode) {
		  setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	  
	useEffect(() => {
		if(props.isDigital && !isDigital) {
			setIsDigital(true);
		}
	}, [props.isDigital]);
	
	useEffect(() => {
		if(props.project && props.project !== project) {
			setProject(props.project);
		}
	}, [props.project]);
	
	useEffect(() => {
		if(props.location && props.location !== location) {
			setLocation(props.location);
		}
	}, [props.location]);
	
	useEffect(() => {
		if(props.locations && props.locations !== locations) {
			setLocations(props.locations);
		}
	}, [props.locations]);
	
	useEffect(() => {
		if(props.shippingOptions && 
			props.shippingOptions !== shippingOptions) {
			setShippingOptions(props.shippingOptions);
		}
	}, [props.shippingOptions]);
	
	useEffect(() => {
		if(shippingOptions && location) {
			const sMethod = location.location_shipping_method;
			if(sMethod) {
				const sInfo = shippingOptions.find(opt => opt.option_name === sMethod);
				if(props.objExists(sInfo, 'shipping_info')) {
					setShippingInfo(sInfo.shipping_info);
				}
			}
		}
	}, [shippingOptions, location]);
	
	useEffect(() => {
		if(Array.isArray(props.items)) {
			let itemList = [];
			props.items.map((item, index) => {
				if(props.objGetValue(item, `location_quantities.${props.index}`) > 0) {
					itemList.push({
						...item,
						item_index: index,
						item_subtotal: parseFloat(item.product_price) * (parseInt(props.objGetValue(item, `location_quantities.${props.index}`)) / parseInt(item.product_quantity)),
						product_quantity: props.objGetValue(item, `location_quantities.${props.index}`)
					});
				}
			})
			// Object.keys(location.items).map((itemID, index) => {
			// 	const product = props.items.find(prod => prod.product_id === parseInt(itemID));
			// 	if(product) {
			// 		itemList.push(
			// 			{
			// 				product_id: parseInt(itemID),
			// 				product_name: props.objGetValue(product, `details.${languageCode}.product_name`),
			// 				product_quantity: parseInt(location.items[itemID]),
			// 				media: props.objGetValue(product, `details.${languageCode}.media`),
			// 			}
			// 		)
			// 	}
			// });
			if(itemList.length > 0) {
				setItems(itemList);
			}
		} 
	}, [props.cart, props.items]);
	
	useEffect(() => {
		if(props.objExists(location, 'shipping_method') &&
			location.shipping_method !== shippingMethod) {
			setShippingMethod(location.shipping_method);
		}
	}, [location]);
	
	// FUNCTIONS 
	function loadAddEdit (type, params) {
		props.setModal(
			type === 'location' ?
				<FormLocationAddEdit {...props}
					userID={userID}
					token={token}
					location={params}
					locations={params ? locations : null}
					items={params ? props.items : null}
					actionCancel={()=>{props.setModal(null)}}
					actionAddEdit={handleAddEdit}
					organizationID={null}
					locationType={null}
					locationLink={null}
					returnObject={true}
					 />
			: type === 'contact' ?
				<FormContactAddEdit {...props}
					userID={userID}
					token={token}
					contact={params}
					actionCancel={()=>{props.setModal(null)}}
					actionAddEdit={handleAddEdit}
					organizationID={null}
					locationType={null}
					locationLink={null}
					returnObject={true} />
			: null);
		
	}
	
	function handleAddEdit(params) {
		props.setModal(null);
		
		if(Array.isArray(params)) {
			const paramType = params.find(param => param.name === 'process');
			if(paramType && paramType.value) {
				let locationUpdate = {};
				if(props.objExists(props.cart, `project.orders[${props.orderIndex}].shipping.locations[${props.index}]`)) {
					locationUpdate = {...props.cart.project.orders[props.orderIndex].shipping.locations[props.index]};
				}
				let updateParams = [];
				switch(paramType.value) {
					case 'add_contact':
					case 'update_contact':
						updateParams = params.filter(param => param.name.startsWith('contact_') === true);
						break;
					case 'add_location':
					case 'update_location':
						updateParams = params.filter(param => param.name.startsWith('location_'));
						break;
					default:
						break;
				}
				updateParams.map(param => {
					locationUpdate[param.name] = param.value;
				});
				props.cartSetValue(`project.orders[${props.orderIndex}].shipping.locations[${props.index}]`, locationUpdate);
			}
			
		}
	}
	
	function handleRemove() {
		if(props.actionRemove) {
			props.actionRemove(
				{index: props.index,
				orderIndex: props.orderIndex,
				location: location, 
				items: items}
			);
		}
	}
	
	function updateLocationQty(name, value) {
		props.cartSetValue(name, value);
	}
	
	function selectShipping(value, name) {
		props.cartSetValue(name, value);
		if(value) {
		   const sInfo = shippingOptions.find(opt => opt.option_name === value)["shipping_info"];
		   setShippingInfo(sInfo);
		}
	}
	
	return (
		<article className={`order-location${props.multiple ? ' repeating' : ''}`} style={{marginBottom: 0, marginRight: 0}}>
			{props.multiple &&
				<h3>{`${props.index === 0 ? 'Primary ': ''}Location${props.index > 0 ? ` ${props.index+1}` : ''}`}</h3>	
			}
			<div className="form-row"  style={{paddingBottom: '0em'}}>
				<b className="row-heading">Ship via:</b>
				<div className="radio-btn-set-group">
				{location && Array.isArray(shippingOptions) && shippingOptions.length > 0 &&
					shippingOptions.map((sOption, index) => {
						return(
							<RadioBtn {...props}
								key={`shpMthdBtn${props.orderIndex}${props.index}${index}`}
								label={sOption.option_name}
								group={`project.orders[${props.orderIndex}].shipping.locations[${props.index}].location_shipping_method`}
								value={sOption.option_name}
								selected={location.location_shipping_method === sOption.option_name ? true : false}
								disabled={((props.shippingRequired && sOption.option_name.toLowerCase() === 'digital') ||
											(!props.shippingRequired && sOption.option_name.toLowerCase() !== 'digital')) ?
												true : false}
								selectAction={selectShipping} />
						)
					})
				}
				</div>
			</div>
			
			{shippingInfo && 
				<div className="info-block">{ReactHtmlParser(shippingInfo)}</div>
			}
			
			{!isDigital &&
				<Fragment>
					<LocationBlock {...props}
						className={props.multiple ? 'horizontal'  : null}
						location={location}
						locationIndex={props.index}
						orderIndex={props.orderIndex}
						locationHeading="Ship to:"
						actionLocationAddEdit={loadAddEdit}
						contact={location}
						contactHeading="Attn:"
						actionContactAddEdit={loadAddEdit}
						requiredFields={[
							'location_address_1',
							'location_city',
							'location_province',
							'location_postal_code',
						 	'location_country',
							'contact_name_first',
							'contact_name_last',
							'contact_email',
							'contact_phone'
						]}
						allowEdit={!props.restrictLocations ? true : false}
						errorFlags={props.errorFlags} />
				</Fragment>
			}
			{items && languageCode && props.multiple &&
				<Fragment>
					<h4>Items shipping to this address</h4>
					<div class="grid col-2 cells">
						<div className="column-header">Qty</div>
						<div className="column-header">
							<div className="flex-row" style={{marginLeft: '0em'}}>
								<div style={{flex: '1 0 auto'}}>Item</div>
								{!props.hidePricing &&
									<div style={{flex: '0 0 auto', textAlign: 'right', marginLeft: '0em'}}>Item Subtotal</div>
								}
							</div>
						</div>
						{items.map((item, iIndex) => {
							return (
								<Fragment>
									<div className="column">
										<LiveFieldInput {...props}
											type="number"
											name={`project.orders[${props.orderIndex}].items[${iIndex}].location_quantities.${props.index}`}
											value={parseInt(item.product_quantity)}
											action={updateLocationQty}
											isDecimal={false} />
									</div>
									<div className="column">
										<div className="flex-row" style={{marginLeft: '0em'}}>
											<div className="truncate"
												style={{flex: '1 0 3em'}}>
												{`${item.details[languageCode].product_name}${
													props.objExists(item, `product_nickname`) ?
												 	` (${item.product_nickname})` : ''}`}
											</div>
											{!props.hidePricing && 
												<div style={{flex: '0 0 auto', textAlign: 'right', marginLeft: '0em'}}>
													{utils.priceDisplay(item.item_subtotal)}
												</div>
											}
										</div>
									</div>
								</Fragment>
							)	
						})}
					</div>
					{props.cart.project &&
						<ProjectPricing {...props} project={project} pricingLocation="location" orderIndex={props.orderIndex} locationIndex={props.index} hidePricing={props.hidePricing} />
					}
				</Fragment>
			}
			{props.index > 0 &&
				<div className="ctl top-right">
					<IconToken icon="trash"
							tooltip="Remove Location"
							action={handleRemove} />
				</div>	
			}
		</article>
	)
}