import React, {useEffect, useState} from 'react';

import Select from 'components/forms/elements/Select';

export default (props) => {
	const [user, setUser] = useState(null);
	const [orgID, setOrgID] = useState(null);
	const [orgs, setOrgs] = useState(null);
	const [optionsOrgs, setOptionsOrgs] = useState(null);
	const [inProgress, setInProgress] = useState(null);
	
	// HOOKS
	useEffect(() => {
		return () => {
			setOrgID(null);
			setInProgress(null);
		}
	}, []);
	
	useEffect(() => {
		if(props.objExists(props.user, `user_id`) 
		&& props.user !== user) {
			setUser(props.user);
		}
	}, [props.user]);
	
	useEffect(() => {
		if(props.orgID && !orgID) {
			setOrgID(props.orgID);
		}
	}, [props.orgID]);
	
	useEffect(() => {
		if(props.organizations && orgs !== props.organizations) {
			setOrgs(props.organizations);
		}
	}, [props.organizations]);
	
	useEffect(() => {
		if(orgs && user &&
			Array.isArray(orgs) && 
			orgs.length > 0) {
			const options = [];
			orgs.map(org => {
				if((!props.skipCore || (props.skipCore && org.organization_id > 1)) &&
					((props.objGetValue(props.userAccess, `super`) === true ||
					props.objGetValue(props.userAccess, `staffAdmin.1`) === true) ||
					(user.organizations.find(uorg => uorg.organization_id === org.organization_id)))) {
					options.push({option_name: org.organization_name, option_value: org.organization_id});	
				}
			});
			if(options !== optionsOrgs) {
				setOptionsOrgs(options);
			}
		}
	}, [orgs, user, props.userAccess]);
	
	useEffect(() => {
		if((inProgress || inProgress === '' || inProgress === 0) && 
			inProgress === props.orgID) {
			setInProgress(null);
		}
	}, [orgID, inProgress]);
	
	
	// FUNCTIONS
	function handleOrgSelect(param) {
		if(!isNaN(parseInt(param.value))) {
			if(props.action && parseInt(param.value) !== orgID) {
				setOrgID(parseInt(param.value));
				setInProgress(parseInt(param.value));
				props.action(parseInt(param.value));
				// props.action(4);
			}else if(param.value && props.stateLocation && !inProgress &&
						parseInt(param.value) !== props.objGetValue(props.admin, props.stateLocation)) {
				setOrgID(parseInt(param.value));
				setInProgress(parseInt(param.value));
				props.adminSetVal(props.stateLocation, parseInt(param.value))
			}	
		}else if(props.action) {
			props.action(null);
		}
	}
	
	return (
		optionsOrgs &&
		<div className="form-row">
			<Select {...props}
				name="core_organization"
				label="Organization"
				defaultValue={orgID}
				option_groups={[{options: optionsOrgs}]}
				onChange={handleOrgSelect} />
				
		</div>
	)
}