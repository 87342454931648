import React, {useEffect, useState, useRef} from 'react';

import Feature from 'components/molecules/Feature';
import ControlNextPrev from 'components/molecules/ControlNextPrev';

export default (props) => {

  const wrapper = useRef();
  const scrollbox = useRef();
  const ctlPrev = useRef();
  const ctlNext = useRef();

  function checkOverflow() {
    if(scrollbox.current.scrollWidth > wrapper.current.clientWidth) {
      !wrapper.current.classList.contains('scroll') &&
      wrapper.current.classList.add('scroll')
    }else{
      wrapper.current.classList.contains('scroll') &&
      wrapper.current.classList.remove('scroll');
    }
  }

  function scrollFeatures(e) {
    let target = e.target;
    while (!target.classList.contains('icon-ctl-dir') && target.tagName.toLowerCase() !== 'body') {
      target = target.parentNode;
    }

    const transitionSpeed = props.transitionSpeed ? parseFloat(props.transitionSpeed) : .2;
    let offset = 0;

    const scrollboxStyle = window.getComputedStyle ? window.getComputedStyle(scrollbox.current) : scrollbox.current.currentStyle;
    const wrapperStyle = window.getComputedStyle ? window.getComputedStyle(wrapper.current) : wrapper.current.currentStyle;

    if(target.classList.contains('next')) {
      const scrollboxWidth = scrollbox.current.scrollWidth + parseInt(wrapperStyle.paddingRight);

      let nextRemainder = scrollboxWidth - Math.abs(scrollbox.current.offsetLeft) - wrapper.current.clientWidth;

      if(nextRemainder > 0 && nextRemainder < wrapper.current.clientWidth ) {
        offset = nextRemainder;
        scrollbox.current.style.transitionDuration = ((offset/100)*transitionSpeed)+'s';
        scrollbox.current.style.left = '-'+(Math.abs(scrollbox.current.offsetLeft)+nextRemainder)+'px';
      }else if (nextRemainder > 0 && nextRemainder > wrapper.current.clientWidth){
        offset = wrapper.current.clientWidth;
        scrollbox.current.style.transitionDuration = ((offset/100)*transitionSpeed)+'s';
        scrollbox.current.style.left = '-'+wrapper.current.clientWidth+'px';
      }

    }else if(target.classList.contains('prev')) {
      const scrollboxWidth = scrollbox.current.scrollWidth + parseInt(wrapperStyle.paddingLeft);
      let prevRemainder = Math.abs(scrollbox.current.offsetLeft) > wrapper.current.clientWidth;
      if(scrollbox.current.offsetLeft < 0 && prevRemainder){
        offset = wrapper.current.clientWidth;
        scrollbox.current.style.transitionDuration = ((offset/100)*transitionSpeed)+'s';
        scrollbox.current.style.left = '-'+ (Math.abs(scrollbox.current.offsetLeft) - wrapper.current.clientWidth) +'px';
      }else if(scrollbox.current.offsetLeft < 0 && !prevRemainder) {
        offset = scrollbox.current.offsetLeft;
        scrollbox.current.style.transitionDuration = ((offset/100)*transitionSpeed)+'s';
        scrollbox.current.style.left = '0px';
      }

    }
    setTimeout(toggleCtl, parseInt(((offset/100)*transitionSpeed)*1000)+100);
  }

  function toggleCtl() {
    const wrapperStyle = window.getComputedStyle ? window.getComputedStyle(wrapper.current) : wrapper.current.currentStyle;
    if(scrollbox.current.offsetLeft === 0) {
      !ctlPrev.current.classList.contains('disabled') &&
      ctlPrev.current.classList.add('disabled');
    }else{
      ctlPrev.current.classList.contains('disabled') &&
      ctlPrev.current.classList.remove('disabled');
    }

    if((Math.abs(scrollbox.current.offsetLeft)+wrapper.current.clientWidth) >= scrollbox.current.scrollWidth) {
      !ctlNext.current.classList.contains('disabled') &&
      ctlNext.current.classList.add('disabled');
    }else{
      ctlNext.current.classList.contains('disabled') &&
      ctlNext.current.classList.remove('disabled');
    }
  }

  useEffect(() => {
    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    toggleCtl();

    return(() => {
      window.removeEventListener('resize', checkOverflow);
    })
  }, []);

  return (
    <div className="feature-collection" ref={wrapper} >
      <div className="feature-scrollbox" ref={scrollbox}>
        {props.objExists(props, 'features') && Array.isArray(props.features) && props.features.length > 0 &&
          props.features.map((feature, index) => {
            return <Feature {...props} 
                            feature={feature}
                            languageCode={props.languageCode ? props.languageCode : 'en'}
                            key={`ftr${index}`} />
          })
        }
      </div>
      <ControlNextPrev direction="prev" action={scrollFeatures} ctlRef={ctlPrev} />
      <ControlNextPrev direction="next" action={scrollFeatures} ctlRef={ctlNext} />
    </div>
  )
}
