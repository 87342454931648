import React, {useEffect, useState} from 'react';

import Loader from 'components/atoms/Loader';
import FormDynamic from 'components/forms/FormDynamic';
import Feature from 'components/molecules/Feature';
import FileUpload from 'components/forms/elements/FileUpload';

export default (props) => {
	const [form, setForm] = useState(null);
	const [fieldsets, setFieldsets] = useState(null);
	const [newValue, setNewValue] = useState(null);
	const [feature, setFeature] = useState(null);
	
	useEffect(() => {
		setFieldsets([{fieldset_name: `${props.objExists(props, 'feature.feature_id') ? 'Edit' : 'Add'} Feature`,
			fields: [
				{field_type: 'switch',
				field_name: 'feature_published',
				field_label: 'Publish',
				field_value: props.objGetValue(props, 'feature.feature_published') === 1 ? true : false,
				field_required: false,
				field_validate: false,
				field_order: 1,
				},
				{field_type: 'select',
				field_name: 'feature_type',
				field_label: 'Feature Type',
				field_value: props.objExists(props, 'feature.feature_type') ? 
								props.feature.feature_type : 
								null,
				field_required: false,
				field_validate: false,
				option_groups: [
					{options: [
						{option_name: 'Product', option_value: 'product'},
						{option_name: 'Category', option_value: 'category'},
						{option_name: 'Group', option_value: 'group'},
						{option_name: 'Product Type', option_value: 'product type'},
					]}
				],
				},
				{field_type: 'text',
				field_name: 'feature_title',
				field_label: 'Title',
				field_placeholder: 'Feature title',
				field_value: props.objExists(props, `feature.details.${props.languageCode}.feature_title`) ?
								props.feature.details[props.languageCode].feature_title : null,
				field_required: false,
				field_validate: false,
				fieldOnChange: updateValue,
				field_order: 2,
				},
				{field_type: 'text',
				field_name: 'feature_subtitle',
				field_label: 'Subtitle',
				field_placeholder: 'Feature Subtitle',
				field_value: props.objExists(props, `feature.details.${props.languageCode}.feature_subtitle`) ?
								props.feature.details[props.languageCode].feature_subtitle : null,
				field_required: false,
				field_validate: false,
				fieldOnChange: updateValue,
				field_order: 3,
				},
				{field_type: 'text',
				field_name: 'feature_text',
				field_label: 'Feature text',
				field_placeholder: 'Feature body text',
				field_value: props.objExists(props, `feature.details.${props.languageCode}.feature_text`) ?
								props.feature.details[props.languageCode].feature_text : null,
				field_required: false,
				field_validate: false,
				fieldOnChange: updateValue,
				field_order: 4,
				},
				{field_type: 'text',
				field_name: 'feature_url',
				field_label: 'Feature URL',
				field_placeholder: 'Feature link URL',
				field_value: props.objExists(props, `feature.details.${props.languageCode}.feature_url`) ?
								props.feature.details[props.languageCode].feature_url : null,
				field_required: false,
				field_validate: false,
				field_order: 4,
				},
				{field_type: 'hidden',
				field_name: 'process',
				field_value: props.objExists(props, 'feature.feature_id') ?
								'update_site_feature' :
								'add_site_feature'
				},
				props.objExists(props, 'feature.feature_id') && 
				{field_type: 'hidden',
				field_name: 'feature_id',
				field_value: props.feature.feature_id
				},
				props.objExists(props, `feature.details.${props.languageCode}.text_id`) && 
				{field_type: 'hidden',
				field_name: 'text_id',
				field_value: props.feature.details[props.languageCode].text_id
				},
				{field_type: 'hidden',
				field_name: 'user_id',
				field_value: props.userID
				},
				{field_type: 'hidden',
				field_name: 'token',
				field_value: props.token
				},
				!props.objExists(props, 'feature.feature_id') &&
				props.organizationID &&
				{field_type: 'hidden',
				field_name: 'organization_id',
				field_value: props.organizationID
				},
				!props.objExists(props, 'feature.feature_id') &&
				{field_type: 'hidden',
				field_name: 'language_code',
				field_value: props.language_code ? props.language_code : 'en'
				},
				{field_type: 'hidden',
				field_name: 'return_bool',
				field_value: true,
				},
			]
		}]);
		
		if(props.feature.feature_id) {
			setFeature(props.feature);	
		}else{
			const newFeature = {details: {}};
			newFeature['details'][props.languageCode] = {feature_id: null};
			setFeature(newFeature);
		}
	}, [props.feature, props.files]);
	
	useEffect(() => {
		if(fieldsets) {
			setForm(
				<FormDynamic
				  content={fieldsets}
				  name="add-edit-slideshow"
				  returnObject={true}
				  fieldOnChange={handleChange}
				  fieldOnLoad={null}
				  formActions={{formCancel: handleCancel, formSubmit: handleAddEdit}} />
			)
		}
	}, [fieldsets]);
	
	useEffect(() => {
		if(newValue && feature) {
			const originalFeature = feature;
			switch(newValue.name) {
				case 'feature_title':
				case 'feature_subtitle':
				case 'feature_text':
				case 'feature_url':
					originalFeature['details'][props.languageCode][newValue.name] = newValue.value;
					break;
				default:
					break;
			}
			setFeature(originalFeature);
			setNewValue(null);
		}
	}, [newValue]);
	
	// FUNCTIONS
	function handleChange(e) {
		console.log("Slide Form is Changing");
	}
	
	function handleCancel(e) {
		console.log("Cancel Form");
		props.objExists(props, 'actionCancel') &&
		props.actionCancel();
	}
	
	function handleAddEdit(params) {
		props.objExists(props, 'actionAddEdit') &&
		props.actionAddEdit(params);
	}
	
	function adjustAspect(ratio) {
		const ratioValues = ratio.includes(':') ?
		  ratio.split(':') :
		  ratio.split('x');
		const ratioFactors = {width: parseFloat(ratioValues[0])/parseFloat(ratioValues[1]),
				  height: parseFloat(ratioValues[1])/parseFloat(ratioValues[0])};
		return ratioFactors;
	}
	
	function updateValue(param) {
		setNewValue(param);
	}
	
	console.log("EDTING FEATURE", feature);
	return (
		<div className="form-matrix">
			{form ? form : <Loader />}
			<div className="form-matrix-display">
				<div className="profile-component-feature">
				<Feature {...props}
						feature={feature}
						languageCode={props.languageCode}
						blockLinks={true} />
				</div>
				{props.objExists(props, 'feature.feature_id') ?
					<FileUpload {...props}
								retainModal={true}
								cueReload={true}
								stateLocation={'features'}
								returnInfo={true}
								media_type='image'
								media_owner_id={props.objExists(props, 'feature.feature_id') ? 
												props.feature.feature_id : null}
								media_owner_type ='Feature'
								media_published ={true}
								user_id={props.userID}
								token={props.token}
								organization_id={props.organizationID}
								language_code={props.languageCode ? props.languageCode : 'en'} />
					:
						<p>Image can be loaded after feature is created (choose edit)</p>
				}
			</div>
		</div>
	)
}