import React, {useEffect, useState, useRef, Fragment} from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import ReactDOM from 'react-dom';

import TextAreaCtl from 'components/forms/elements/TextAreaCtl.js';

export default (props) =>  {
  const [input, setInput] = useState(null);
  const [value, setValue] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [displayRaw, setDisplayRaw] = useState(false);
  const [toggleBold, setToggleBold] = useState(false);
  const [formatBold, setFormatBold] = useState(false);
  const [maxWidth, setMaxWidth] = useState(null);
  const textRef = useRef();
  const displayRef = useRef();



  useEffect(() => {
    // SET THE MAX WIDTH BASED ON THE CONTAINER TO KEEP IT FROM STRETCHING THE BOX
    // MOSTLY AN ISSUE WITH MODAL FORMS
    if(displayRef.current && displayRef.current.parentElement.clientWidth) {
      setMaxWidth(displayRef.current.parentElement.clientWidth-20);
    }
  }, []);
  
  useEffect(() => {
    if(props.value && props.value !== value) {
      setValue(props.value);
    }else if(props.defaultValue && props.defaultValue !== value){
      setValue(props.defaultValue);
    }else if(props.default_value && props.default_value !== value) {
      setValue(props.default_value);
    }
  }, [props.value, props.defaultValue, props.default_value]);
  
  useEffect(() => {
    if(props.clearContents && value) {
      setValue("");
    }
  }, [props.clearContents])

  useEffect(() => {
    var _keyInput = (e) => {
      keyboardInput(e);
    }

    if(isActive && props.richText === true) {
      window.addEventListener('keydown', _keyInput, true);
      return () => {
        window.removeEventListener('keydown', _keyInput, true);
      }
    }else{
      // window.addEventListener('keydown', _keyInput, true);
      return () => {
        // window.removeEventListener('keydown', _keyInput, true);
      }
    }
  }, [isActive]);

  useEffect(() => {
    if(input) {
      let val = value;

      if(input === 'delete') {
        let closingTag = '';
        while(val.substring(val.length-1, val.length) === '>') {
          closingTag += val.substring(val.lastIndexOf('<'), val.length);
          val = val.substring(0, val.lastIndexOf('<'));
        }
        val = val.substring(0, val.length - 1)+closingTag;
      }else{
        // if(formatBold === true) {
        //   if(val.substring(val.length - 4, val.length) === '</b>') {
        //     val = val.substring(0, val.length - 4);
        //   }
        //   val += input+`</b>`;
        // }else{
        //   val += input;
        // }
      }

      setValue(val);
    }
  }, [input]);

  useEffect(() => {
    // console.log("bold is set to", formatBold);
    if(formatBold && value) {
      setInput(`<b>`);
    }else if(value && value.length > 0){
      setInput(`</b>`);
    }
  }, [formatBold]);

  useEffect(() => {
    if(toggleBold) {
      if(formatBold) {
        setFormatBold(false);
      }else{
        setFormatBold(true);
      }
      setToggleBold(false);
    }
  }, [toggleBold]);

  function keyReturn() {
    const newValue = value + '\n';
    setValue(newValue);
  }

  function keyDelete() {
    const sel = window.getSelection();
    // console.log("Checking", sel.type);
    let val = '';
    if(sel.type === 'Range') {
      // console.log("we have found a ", sel.type);
      val += displayRef.current.innerHTML.substring(0, sel.focusOffset);
      val += displayRef.current.innerHTML.substring(sel.anchorOffset, displayRef.current.innerHTML.length);
    }else if(sel.type === 'Caret') {
      let caretPos = sel.anchorOffset > 0 ? sel.anchorOffset - 1 : null;
      let rangeNode = null;

      if(sel.anchorNode.data.length > 1) {
        if(caretPos !== null) {
          rangeNode = sel.anchorNode;
        }else{
          rangeNode = findPrevNode(sel.anchorNode);
        }
        sel.anchorNode.data = sel.anchorNode.data.substring(0, sel.anchorOffset-1)+sel.anchorNode.data.substring(sel.anchorOffset, sel.anchorNode.data.length);
      }else{
        rangeNode = findPrevNode(sel.anchorNode);
        sel.anchorNode.remove()
      }
      const selRange = document.createRange();
      selRange.setStart(rangeNode, caretPos >= 0 ? caretPos : rangeNode.length);
      selRange.collapse(true);
      sel.removeAllRanges();
      sel.addRange(selRange);
      // if(displayRef.current.innerHTML.substring(sel.focusOffset-1, sel.focusOffset) === '>') {
      //
      // }

      val += displayRef.current.innerHTML.substring(0, sel.focusOffset-1);
      val += displayRef.current.innerHTML.substring(sel.anchorOffset, displayRef.current.innerHTML.length);
    }
    // displayRef.current.innerHTML = val;
    // setValue(val);
  }

  function findPrevNode(curNode) {
    let prevNode = null;
    if(!curNode.parentNode.classList.contains('text-area-display')) {
      curNode = curNode.parentNode;
    }
    if(curNode.previousSibling) {
      prevNode = curNode.previousSibling;
      if(prevNode.firstChild) {
        prevNode = prevNode.firstChild;
      }
    }
    return prevNode;
  }

  function keyboardInput(e) {
    // console.log("check", e.key);
    if(e.key.length > 1) {
      if(!['ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp', 'Tab'].includes(e.key)) {
        e.preventDefault();
        if(e.key === 'Backspace') {
          keyDelete();
          // setInput('delete');
        }
        if(e.key === 'XXXEnter') {
          console.log("You tried to return");
          keyReturn();
        }
      }
    }else{
      if(e.metaKey && e.key === 'b') {
        e.preventDefault();
        ReactDOM.render((<b></b>), displayRef.current);
        // displayRef.current.innerHTML = displayRef.current.innerHTML + `<b></b>`;

        // console.log("Yeah???", document.getSelection(), displayRef.current.innerText);
        // displayRef.current.innerText.setSelectionRange(10, 12);
        setToggleBold(true);
      }else{
        setInput(e.key);
      }
    }
    if(props.richText === true && value !== displayRef.current.innerHTML) {
      setValue(displayRef.current.innerHTML);
    }
  }

  function addTags(tag) {
    if(window.getSelection) {
      let sel = window.getSelection();
    }
  }

  function handleChange(e) {
    let newValue;
    if(e.target.tagName.toLowerCase() === 'textarea') {
      // console.log("You have a text area");
      newValue = e.target.value;
    }else{
      // console.log("Changing", e.target.html());
      newValue = e.target.innerHTML;
    }
    
    setValue(newValue);
    
    if(props.updateAction && Array.isArray(props.updateAction)) {
      setTimeout(()=>{
        props.updateAction.map(action => {
           return action({name: e.target ? e.target.name : e.name, value: newValue});
        })
      }, 100);
    } else if (props.updateAction){
      setTimeout(()=>{props.updateAction({name: e.target ? e.target.name : e.name, value: newValue})}, 100);
    }
    
    props.onChange && props.onChange({name: props.name, value: newValue});
  }



  function handleKey(e) {
    if(document.activeElement.tagName.toLowerCase() === 'textarea' && document.activeElement === e.target){
      if(e.keyCode === 191 || e.keyCode === 222) {
        e.preventDefault();
        setValue(`${e.target.value}${e.key}`);
        document.activeElement.focus();
      }
    }
  }

  function focusInput(e) {
    textRef.current.focus();
  }

  function toggleRaw() {
    if(displayRaw === true) {
      setDisplayRaw(false);
    }else{
      setDisplayRaw(true);
    }
  }

  function formatText(style) {

    // console.log("Here's the format function", textRef.current.selectionStart, textRef.current.selectionEnd, textRef.current.innerHTML);
    setValue(`Hi there I am <b>BOLD</b>`);
  }

  function checkReturn(e) {
    // disabled for standard textarea
    // if(e.key === 'Enter') {
    //   e.preventDefault();
    //   setValue(textRef.current.value+'\n');
    // }
  }

  useEffect(() => {
    props.value &&
    setValue(props.value);
  }, [props.value])

  useEffect(() => {
    // textRef.current.style.height = textRef.current.scrollHeight+'px';
  }, [value])

  useEffect(() => {
    if(props.value && props.onLoad) {
      props.onLoad(props.name, props.value);
    }
  }, [])

  return (
      <div className={`text-area${props.className ? ` ${props.className}` : ''}${props.showCtl === true ? ` formatable` : ''}${isActive ? ` active` : ''}${props.isMissing ? ` form-input-missing` : ''}`}
            onClick={focusInput}
            onFocus={()=> {setIsActive(true)}}
            onBlur={()=> {setIsActive(false)}} >
        <label htmlFor={props.id? props.id : null} className="input-label" >
          {props.label}
          {props.required ? <span className="flag-required">&#10035;</span> : ''}
          {props.errormsg && <div className="form-input-error-msg">! {props.errormsg}</div>}
        </label>
        {props.richText === true &&
          <Fragment>
            <div className="text-area-display" style={maxWidth ? {maxWidth: `${maxWidth}px`} : null} contentEditable={true} ref={displayRef}>
              {value && value.length > 0 ? displayRaw ? value : ReactHtmlParser(value) : ''}
            </div>
            <input type="hidden" name={props.name} value={value} required={props.required ? true : false}/>
          </Fragment>
        }

        {!props.richText &&
          <textarea
            id={props.id? props.id : null}
            name={props.name}
            onChange={handleChange}
            onKeyDown={checkReturn}
            tabIndex={props.tabIndex}
            required={props.required}
            // onFocus={(e)=>{e.target.addEventListener("keydown", handleKey, false);}}
            // onBlur={(e)=>{e.target.removeEventListener("keydown", handleKey, false);}}
            // onKeyDown={this.handleKey}
            placeholder={props.placeholder ? props.placeholder : props.label}
            ref={textRef}
            value={value}
            ></textarea>

        }

        {props.showCtl === true &&
          <TextAreaCtl actionFormatText={formatText}
                        actionToggleRaw={toggleRaw}
                        activeRaw={displayRaw}/>
        }

        </div>
    )
}
