import React, {useEffect, useState, Fragment} from 'react';

import FormDynamic from 'components/forms/FormDynamic';
import IconSVG from 'components/icons/IconSVG';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [fieldsets, setFieldsets] = useState(null);
	const [form, setForm] = useState(null);
	
	useEffect(() => {
		if(props.userID && props.userID != userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token != token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.languageCode && 
			props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}else if (!props.languageCode && 
					!languageCode &&
					props.objExists(props.site, 'site_language')) {
			setLanguageCode(props.site.site_language);
		}else if (!languageCode) {
			setLanguageCode('en');
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		// SET FORM FIELDS
		let formFieldsets = [{
			fieldset_name: `${props.messageParent ? 'Reply to ' : 'Send '}Message`,
			fields: [
				{field_type: 'text',
				field_name: 'message_subject',
				field_label: 'Subject',
				field_placeholder: 'Optional Subject',
				field_value: props.messageSubject? props.messageSubject : null,
				field_required: false,
				field_validate: false,
				field_order: 1
				},
				{field_type: 'textarea',
				field_name: 'message_text',
				field_label: 'comments',
				field_placeholder: 'Add your message',
				field_value: null,
				field_required: true,
				field_validate: true,
				field_order: 2
				},
			]
		}];
		
		if(props.messageParent) {
			formFieldsets[0].fields.push(
				{field_type: 'hidden',
				field_name: 'message_parent',
				field_value: props.messageParent,
				}
			);
		}
		
		formFieldsets[0].fields.push(
			{field_type: 'hidden',
			field_name: 'process',
			field_value: 'add_message'
			},
			{field_type: 'hidden',
			field_name: 'user_id',
			field_value: userID
			},
			{field_type: 'hidden',
			field_name: 'token',
			field_value: token
			},
			{field_type: 'hidden',
			field_name: 'message_published',
			field_value: true
			},
			{field_type: 'hidden',
			field_name: 'message_type',
			field_value: props.messageType ? 
							props.messageType :
								props.messageParent ? 'reply' :
									null
			}
		)
			
		setFieldsets(formFieldsets);
	}, [userID, token, languageCode, props.messageParent, props.messageSubject, props.messageType]);
	
	useEffect(() => {
		if(fieldsets) {
			setForm(
				<FormDynamic {...props}
				  content={fieldsets}
				  name={props.messageParent ? 'reply-to-message' : 'send-message'}
				  returnObject={true}
				  fieldOnChange={null}
				  formActions={{formCancel: handleCancel, formSubmit: handleSubmit}}
				  formCompleteLabel="Confirm" />
			)
		}
	}, [fieldsets]);
	
	// FUNCTIONS 
	function handleCancel() {
		if(props.actionCancel) {
			props.actionCancel(true);
		}else{
			props.setModal(null);
		}
	}
	
	function handleSubmit(params) {
		if(props.actionSubmit) {
			props.actionSubmit(params);
		}else{
			props.setModal(null);
		}
	}
	
	
	
	return (
		<Fragment>
			{form}
		</Fragment>
	)
}