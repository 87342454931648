import React from 'react';

export default (props) => {
  // props = {...props.children. props.utils};

  // console.log("admin props", props);
  return (
    <div className="admin-component-content">
      <p>Now here's stuff for you to get going with!</p>

      <p onClick={()=>{props.adminSetComponent('tasks')}}>Go To Tasks</p>
    </div>
  )
}
