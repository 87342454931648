import React, {useEffect, useState} from 'react';

import IconToken from 'components/icons/IconToken';
import Button from 'components/forms/elements/Button';

export default (props) => {
  const [missingInfo, setMissingInfo] = useState(null);
  const [infoSet, setInfoSet] = useState(null);

  function auditInfo(infoSet) {
    let missingField = false;
    infoSet && Array.isArray(infoSet) && infoSet.length > 0 &&
    infoSet.map(info => {
      if(Array.isArray(info[1]) && info[1].length > 0) {
        info[1].map(infoField => {
          if(info[3] === true && (!props.info[infoField] ||
              props.info[infoField] === undefined ||
              props.info[infoField] === '')) {
            missingField = true;
          }
        })
      }else{
        if(info[3] && (!props.info[info[1]] || props.info[info[1]] === undefined || props.info[info[1]] === '')) {
          missingField = true;
        }
      }
    })
    missingField !== missingInfo &&
    setMissingInfo(missingField);
  }

  useEffect(() => {
    if(props.info && props.type) {
      let cardInfo;
      
      // INFO SET ARRAY [fieldLabel, fieldValue[s], fieldValuesSeperator, required]
      switch(props.type) {
        case 'location':
          cardInfo = [[null, 'location_name', null, false],
                      [null, 'location_address_1', null, true],
                      [null, 'location_address_2', null, false],
                      [null, ['location_city', 'location_province'], ', ', true],
                      [null, ['location_postal_code', 'location_country'], '  ', true],
                      [null, 'location_country', null, true],
                    ];
          break;
        case 'contact':
          cardInfo = [[null, [`contact_name_first`, `contact_name_last`], ' ', true],
                    ['Phone', `contact_phone`, true],
                    ['Email', `contact_email`, true],
                  ];
          break;
        case 'contact_location':
          cardInfo = [[null, 'location_name', null, false],
                      [null, 'location_address_1', null, true],
                      [null, 'location_address_2', null, false],
                      [null, ['location_city', 'location_province'], ', ', true],
                      [null, ['location_postal_code', 'location_country'], '  ', true],
                      ['C/O', [`contact_name_first`, `contact_name_last`], ' ', true],
                      ['Phone', `contact_phone`, true],
                      ['Email', `contact_email`, true],
                    ];
        default:
          break;
      }
      if(cardInfo && cardInfo !== infoSet) {
        setInfoSet(cardInfo);
      } 
    }
  }, [props.info, props.type]);
  
  useEffect(() => {
    if(infoSet) {
      auditInfo(infoSet);
    }else{
      setMissingInfo(true);
    }
  }, [infoSet]);

  // console.log("CARD INFO", props.info, infoSet);
  return (
    <div className="card-info">
      {missingInfo === true &&
        <div className="card-info-warning">
          <IconToken icon="warning" />
          More Infomation Required
        </div>
      }
      {missingInfo === true &&
        <Button icon="add" label="Add Info" btnClass="success-bg" btnAction={props.actionEdit} />
      }
      {props.info && infoSet && infoSet.map((info, index) => {
        return (
          <div className="card-info-row" key={`cinfo${index}`} >
          {info[0] && <span className="card-info-label" >{info[0]}{props.fieldsRequired.includes(info[1]) ? '*' : ''}</span>}
          {info[1] && Array.isArray(info[1]) ?
              info[1].map((infoField, index) => {
                return props.info[infoField] !== undefined ?
                        props.info[infoField]+((index+1) < info[1].length ? (info[2] !== undefined ? info[2] : ' ') : '')
                        :
                        '';

              })
            :
              props.info[info[1]] !== undefined ? props.info[info[1]] : ''
          }
        </div>
        )
      })}
    </div>
  )
}
