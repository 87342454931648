import React, {useEffect, useState, Fragment} from 'react';

import Control from 'components/atoms/Control';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [canEdit, setCanEdit] = useState(null);
	const [isSuper, setIsSuper] = useState(null);
	const [taskState, setTaskState] = useState(null);
	
	// HOOKS
	useEffect(() => {
		props.userID && 
		props.userID !== userID &&
		setUserID(props.userID);
	}, [props.userID]);
	
	useEffect(() => {
		props.token && 
		props.token !== token &&
		setToken(props.token);
	}, [props.token])
	
	useEffect(() => {
		props.organizationID && 
		props.organizationID !== organizationID &&
		setOrganizationID(props.organizationID);
	}, [props.organizationID]);
	
	useEffect(() => {
		props.languageCode && 
		props.languageCode !== languageCode &&
		setLanguageCode(props.languageCode);
	}, [props.languageCode]);
	
	useEffect(() => {
		props.canEdit &&
		props.canEdit !== canEdit &&
		setCanEdit(props.canEdit);
	}, [props.canEdit]);
	  
	useEffect(() => {
		props.isSuper &&
		props.isSuper !== isSuper &&
		setIsSuper(props.isSuper);
	}, [props.isSuper]);
	
	useEffect(() => {
		if(languageCode && props.objExists(props.task, `status[0].${languageCode}.status_name`)) {
			switch(props.task.status[0][languageCode].status_name) {
				case 'in progress':
					setTaskState('in progress');
					break;
				case 'on hold':
					setTaskState('on hold');
					break;
				case 'complete':
					setTaskState('complete');
					break;
				default:
					setTaskState(null);
					break;	
			}
			
		}
	}, [props.task, languageCode]);
	
	// FUNCTIONS 
	function completeTask() {
		setTaskState('complete');
		const completeParams = [
			{name: 'process', value: 'advance_task'},
			{name: 'user_id', value: props.userID},
			{name: 'token', value: props.token},
			{name: 'language_code', value: props.languagCode},
			{name: 'task_id', value: props.task.task_id},
			{name: 'task_owner_type', value: props.taskOwnerType},
			{name: 'task_owner_id', value: props.taskOwnerID},
			{name: 'task_status', value: 'complete'},
			{name: 'task_root_type', value: props.taskRootType},
			{name: 'task_root_id', value: props.taskRootID}	
		];
		console.log("COMPLETE TASK PARAMS", completeParams);
		props[`${props.location}LoadState`](completeParams, 'tasks', 'reload.project');
		if(props.actionUpdate) {
			props.actionUpdate(props.task.task_id, props.task.status[0][languageCode].status_name);
		}
	}
	
	return (
		<Fragment>
			<Control icon="complete" 
						tooltip={taskState !== 'cancelled' ? "Complete Task" : null} 
						disabled={taskState !== 'cancelled' ? false : true} 
						action={completeTask}
						label="Complete?"
						showLabel={true} />
		</Fragment>
	)
}