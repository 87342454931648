import React, {useEffect, useState, Fragment} from 'react';

import MenuSet from 'components/navigation/MenuSet';
import IconSVG from 'components/icons/IconSVG';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [modules, setModules] = useState(null);
  const [activeModule, setActiveModule] = useState(null);
  
  const [menuSets, setMenuSets] = useState(null);
  const [menus, getMenus] =  useState(null);
  const [menuState, setMenuState] = useState(null);
  
  useEffect(() => {
    if(props.userID && props.userID !== userID) {
      setUserID(props.userID);
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token && props.token !== token) {
      setToken(props.token);
    }
  }, [props.token]);
  
  useEffect(() => {
    if(props.organizationID && props.organizationID !== organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    if(props.languageCode && props.languageCode !== languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode])

  useEffect(() => {
    if(props.modules && props.modules !== modules) {
      setModules(props.modules);
    }
  }, [props.modules]);
  
  useEffect(() => {
    if(!activeModule && activeModule !== 0) {
      setActiveModule(0);
    }
  }, [activeModule]);
 
  return (
    <div className={`menu${props.active ? ' active' : ''}`} id="site-menu">
      {modules && Array.isArray(modules) &&
      modules.map((module, index) => {
          return (
            <MenuSet  {...props}
                      module={module}
                      active={activeModule === index ? true : false}
                      actionActivate={()=>{setActiveModule(index)}}
                      setCount={index} 
                      key={`mset${index}`} 
                      userID={userID}
                      token={token}
                      organizationID={organizationID}
                      languageCode={languageCode} />
          )
      })}
    </div>
  )
}
