import React, {useEffect, useState, Fragment} from 'react';
import {useParams, useLocation, Redirect} from 'react-router';

import TaskMatrix from 'components/profiles/tasks/TaskMatrix';
import Button from 'components/forms/elements/Button';
import Loader from 'components/atoms/Loader';

import * as forms from 'methods/forms';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [canEdit, setCanEdit] = useState(null);
  const [isSuper, setIsSuper] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [redirect, setRedirect] = useState(null);
  
  const pgParams = useParams();
  const location = useLocation();
  
  let ivlTasks;

  useEffect(() => {
    return() => {
      props.adminDeleteVal('current.tasks');
      ivlTasks &&
      clearInterval(ivlTasks);
    }
  }, []);

  useEffect(() => {
    if(props.userID) {
      setUserID(props.userID)
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token) {
      setToken(props.token)
    }
  }, [props.token]);

  useEffect(() => {
    if(props.organizationID && organizationID !== props.organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    props.organizationName &&
    props.organizationName !== organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);
  
  useEffect(() => {
    if(props.languageCode && languageCode !== props.languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    if(props.userAccess && props.userAccess !== userAccess) {
      setUserAccess(props.userAccess);
    } 
  }, [props.userAccess]);
  
  useEffect(() => {
    props.canEdit &&
    props.canEdit !== canEdit &&
    setCanEdit(props.canEdit);
  }, [props.canEdit]);
  
  useEffect(() => {
    props.isSuper &&
    props.isSuper !== isSuper &&
    setIsSuper(props.isSuper);
  }, [props.isSuper]);

  useEffect(() => {
    if(userID && pgParams.component && token && organizationID) {
      let params =  [
                      {name: 'process', value: 'get_tasks'},
                      {name: 'user_id', value: userID},
                      {name: 'token', value: token},
                      {name: 'task_owner_type', value: 'taskToProject'},
                      {name: 'task_owner_id', value: pgParams.component}]
      
      if(pgParams.component && !props.objExists(props.admin, 'current.tasks') ||
          props.objGetValue(props.admin, 'reload.current.tasks') === true) {
          
          props.adminLoadState(params, 'tasks', 'current.tasks');
          
          if(props.objGetValue(props.admin, 'reload.current.tasks') === true) {
            props.adminDeleteVal('reload.current.tasks');
          }
          
          if(ivlTasks) {
            clearInterval(ivlTasks);
          }
          
          ivlTasks = setInterval(() => {
            if(document.visibilityState === 'visible') {
              props.adminLoadState(params, 'tasks', 'current.tasks');  
            }
          }, props.minToMS(10));
      }
      
    }
  }, [userID, token, organizationID, pgParams.component, props.admin.current, props.admin.reload]);
  
  useEffect(() => {
    if(props.objExists(props.admin, 'current.tasks') && 
        props.admin.current.tasks !== tasks) {
      setTasks(props.admin.current.tasks);
    }
  }, [props.admin.current]);

  // FUNCTIONS

  console.log("ADMIN RELOAD", props.admin.reload);
  console.log("ADMIN CURRENT TASKS", props.admin.current);
  return(
      <Fragment>
        <div className="profile">
          {redirect && <Redirect to={redirect} />}
          <Button icon="arrow"
                  iconClass="rotate270"
                  label="Back to Order"
                  btnClass="success-bg"
                  btnAction={()=>{setRedirect(`/admin/orders/order/${pgParams.component}`)}} />
          {userID && tasks ?
            <TaskMatrix {...props}
                      isAdmin={true}
                      tasks={tasks}
                      location={'admin'}
                      source={'current.tasks'}
                      userID={userID}
                      token={token}
                      organizationID={organizationID}
                      languageCode={languageCode}
                      canEdit={canEdit}
                      isSuper={isSuper}
                      userAccess={userAccess}  />
              :
            <Loader />
          }
        </div>
      </Fragment>
  )
}
