import React, {useEffect, useState, Fragment} from 'react';

import IconSVG from 'components/icons/IconSVG';
import Link from 'components/navigation/Link';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [task, setTask] = useState(null);
	const [taskName, setTaskName] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(props.userID 
			&& props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token 
			&& props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.languageCode && 
			props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.task && props.task !== task) {
			setTask(props.task);
		}
	}, [props.task]);
	
	
	useEffect(() => {
		if(task && languageCode) {
			let tName;
			let itemName;
			let itemNickname;
			let orderNumber;
			let projectNumber;
			
			if(task.item_name) {
				itemName = props.capitalizeString(task.item_name, true);
				if(task.item_nickname) {
					itemName += ` (${task.item_nickname})`
				}
			}
			
			if(task.item_nickname) {
				itemNickname = task.item_nickname;
			}
			
			if(task.project_id && task.organization_code) {
				projectNumber = props.projectNumDisplay(task.project_id, task.organization_code);
			}
			
			if(task.order_id && task.project_id && task.organization_code) {
				orderNumber = props.orderNumDisplay(task.order_id, task.project_id, task.organization_code);
			}
			
			if(props.objExists(props.task, `${languageCode}.task_name`) &&
				props.task[languageCode].task_name !== '') {
				tName = props.capitalizeString(props.task[languageCode].task_name, true);	
			}
			
			if(itemName) {
				switch(task.type_name) {
					case 'admin':
						setTaskName(`ADMIN - :: ${itemName}`);
						break;
					case 'approval':
						setTaskName(`Approval ${!task.task_completed ? ' Required' : ''} :: ${itemName}`);
						break;
					default:
						setTaskName(`${tName} :: ${itemName}`);	
						break;
				}
			}else if(orderNumber){
				switch(task.type_name) {
					case 'admin':
						setTaskName(`ADMIN ORDER - :: ${orderNumber}`);
						break;
					default:
						setTaskName(`${tName} :: ${orderNumber}`);	
						break;
				}
			}else if(projectNumber) {
				switch(task.type_name) {
					case 'admin':
						setTaskName(`ADMIN - PROJECT :: ${projectNumber}`);
						break;
					case 'approval':
						setTaskName(`Project Approval ${!task.task_completed ? ' Required' : ''} :: ${projectNumber}`);
						break;
					default:
						setTaskName(`${tName} :: ${projectNumber}`);	
						break;
				}
			}else{
				setTaskName(`Task ${task.task_id}`);
			}
		}
		
	}, [task, languageCode]);
	
	return (
		<li className="list-item flex">
			<IconSVG
				className="li-icon"
				icon={props.objGetValue(props.task, 'type_name') === 'admin' ?
								'admin' : props.objGetValue(props.task, 'type_name') === 'approval' ?
									'preview' : 'work'}/>
			{languageCode && props.task &&
				<div className="li-content">
					<div className="li-content-row">
						<div className="li-content-row">
							<div>
								<b>
									<Link to={`/${props.location ? props.location : 'dashboard'}/task/${props.task.task_id}`}>{taskName}</Link>
								</b>
							</div>
						</div>
					</div>
					<div className="li-content-row" style={{fontSize: '.75em'}}>
						<div>
							{props.task.task_created &&
								<Fragment>
									<b>Created: </b>{props.dateDisplay(props.task.task_created)}
								</Fragment>
							}
						</div>
						{props.task.task_modified && 
						props.task.task_modified > props.task.task_created &&
						<div>
							<b>Updated: </b>{props.dateDisplay(props.task.task_modified)}
						</div>
						}
						{props.task.task_started && 
						<div>
							<b>Started: </b>{props.dateDisplay(props.task.task_started)}
						</div>
						}
						{props.task.task_completed && 
						<div>
							<b>Completed: </b>{props.dateDisplay(props.task.task_completed)}
						</div>
						}
					</div>
					<div className="li-content-row">
						{props.objExists(props.task, 'project_id') &&
							<div>
								{props.objExists(props.task, 'organization_code') ?
									props.projectNumDisplay(props.task.project_id, props.task.organization_code)
								:
									`Project ${props.task.project_id}`
								}
							</div>
						 }
						{props.objExists(props.task, 'order_id') &&
							<div>
								{`Order ${props.task.order_id}`}
							</div>
						}
						{props.objExists(task, `project_user_name`) &&
							<div>{task.project_user_name}</div>
						}
						{props.objExists(task, `project_created`) &&
							<div>{`${props.dateDisplay(task.project_created)} ${props.timeDisplay(task.project_created, false)}`}</div>
						}
					</div>
					
					<div className="li-content-row ctl">
						{props.task.task_owner && 
						parseInt(props.task.task_owner) !== userID ?
							'Task with another user'
						:
							''
						}
					</div>
				</div>
			}
		</li>
	)
}