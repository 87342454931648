import { combineReducers } from 'redux';

import userReducer from 'reducers/users';
// import alertReducer from 'reducers/alerts';
import formsReducer from 'reducers/forms';
import tasksReducer from 'reducers/tasks';
import storeReducer from 'reducers/store';
import modalReducer from 'reducers/modal';
import siteReducer from 'reducers/site';
import cartReducer from 'reducers/cart';
import adminReducer from 'reducers/admin';
import dashboardReducer from 'reducers/dashboard';
import filesReducer from 'reducers/files';


export default combineReducers({
    user: userReducer,
    form: formsReducer,
    modal: modalReducer,
    tasks: tasksReducer,
    store: storeReducer,
    site: siteReducer,
    cart: cartReducer,
    admin: adminReducer,
    dashboard: dashboardReducer,
    files: filesReducer,
});
