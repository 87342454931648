import React from 'react';

import IconToken from 'components/icons/IconToken';
import BtnClose from 'components/atoms/BtnClose';

export default (props) => {
  return (
    <div className="live-field-ctl no-print">
      <IconToken icon={props.value && props.value !== '' ?  `edit` : `add`}
                  action={props.actionEdit}
                  className="inactive btn baseline"
                  tooltip={props.showTooltips ? 'Edit' : null} />
      <IconToken icon="close"
                  className="btn active error"
                  action={props.actionCancel}
                  tooltip={props.showTooltips ? 'Cancel' : null} />
      <IconToken icon="check"
                  className="active btn success"
                  action={props.actionSave}
                  tooltip={props.showTooltips ? 'Save' : null} />
    </div>
  )
}
