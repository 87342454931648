import React, {useEffect, useState} from 'react';

import Link from 'components/navigation/Link';
import IconSVG from 'components/icons/IconSVG';

export default (props) => {
	const [languageCode, setLanguageCode] = useState(null);
	const [product, setProduct] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(props.languageCode &&
			props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}else if(!props.languageCode) {
			setLanguageCode('en');
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.product && 
			props.product !== product) {
			setProduct(props.product);
		}
	}, [props.product]);
	
	
	return(
		product && languageCode &&
		<Link to={props.link}>
		<div className="category block products">
			<div className="image">
				<img src={`https://aecore.app${props.objExists(product, `${languageCode}.product_image`) ? 
							product[languageCode].product_image : 
							"/assets/media/aeImageUnavailable.jpg"}`} 
							alt={props.objExists(product, `${languageCode}.product_name`) ? 
								props.capitalizeString(product[languageCode].product_name) : 
								`image unavailable`} />
			</div>
			<div className="title">
				{props.objExists(product, 'product_end') ? props.capitalizeString(product[languageCode].product_name, true) + ' (Expires ' + props.dateDisplay(product.product_end) + ')' : props.capitalizeString(product[languageCode].product_name, true)}
			</div>
			<div className="content" 
				style={{display: 'flex', justifyContent: 'space-between'}}>
				{props.objExists(product, `${languageCode}.product_type`) &&
					<div className="subtext text-align-center"  style={{flex: '1 1 100%'}}>
						{props.capitalizeString(product[languageCode].product_type, true)}
					</div>
				}
				<p style={{flex: '0 0 auto', width: 'auto', margin: '0em', padding: '0em'}}>
					<IconSVG icon="preview" />
					&nbsp;View Details
				</p>
			</div>
		</div>
		</Link>
	)
}