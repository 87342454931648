import React from 'react';

import ProgressMarker from 'components/molecules/ProgressMarker';


export default (props) => {
  function setMarkers(stages) {
    let markers = [];
    let stageCount = 0;
    let released = false;
    let complete = props.lastComplete ? true : false;

    stages.map((stage, index) =>{
      stageCount++;

      markers.push(<ProgressMarker stageCount={stageCount}
                       complete={complete}
                       released={released}
                       key={`marker${index}`}
                       stage={stage}
                       active={stage.ref && stage.ref.current === props.curStep ? true :
                                stage.name === props.curStage ? true : false}
                       action={stage.ref && props.action ? (e) => {props.action(e, stage.ref.current, props.progressive)} : null}
                         />);

      if((stage.ref && props.lastComplete === stage.ref.current) ||
          !stage.ref && props.curStage === stage.name) {
          complete = false;
          released = true;
      }else if (released) {
        released = false;
      }

      if(stage.ref === props.lastComplete) {
        complete = false;
      }

      return null;
    })
    return markers;
  }

  return (
    <div className="progress-wrapper">
      <div className={`progress${props.progressive ? ' progressive' : ''}`}>
        {props.stages && setMarkers(props.stages)}
      </div>
    </div>
  )
}
