import React, {useEffect, useState} from 'react';

import FormDynamic from 'components/forms/FormDynamic';

export default (props) => {
  const [fieldsets, setFieldsets] = useState(null);
  const [form, setForm] = useState(null);
  const [optionsGroups, setOptionsGroups] = useState(null);
  const [optionsUsers, setOptionsUsers] = useState(null);
  
  useEffect(() => {
	if(props.admin.groups.user && 
		Array.isArray(props.admin.groups.user) &&
		props.admin.groups.user.length > 0) {
			let groupOpts = [];
			props.admin.groups.user.map(group => {
				groupOpts.push(
					{option_name: group[props.curLanguage ? props.curLanguage : 'en'].group_name,
						option_value: group.group_id
					}
				);
			})
			if(groupOpts !== optionsGroups) {
				setOptionsGroups(groupOpts);
			}
    	}
	}, [props.admin.groups.user]
  );
  
  useEffect(() => {
	if(props.admin.users && 
		Array.isArray(props.admin.users) &&
		props.admin.users.length > 0) {
			let userOpts = [];
			props.admin.users.map(user => {
				userOpts.push(
					{option_name: user.contact_name_first + ' ' + user.contact_name_last,
						option_value: user.user_id
					}
				);
			})
			if(userOpts !== optionsUsers) {
				setOptionsUsers(userOpts);
			}
		}
	}, [props.admin.users]
  );

  useEffect(() => {
	setFieldsets([
	  {fieldset_name: `${props.objExists(props, 'restriction.restriction_id') ? 'Edit' : 'Add'} Restriction`,
		fields: [
			{field_type: 'hidden',
				field_name: 'process',
				field_value: props.objExists(props, 'restriction.restriction_id') ? 'update_product_restriction' : 'add_product_restriction'},
			  props.objExists(props, 'restriction.restriction_id') &&
			{field_type: 'hidden',
				field_name: 'restriction_id',
				field_value: props.restriction.restriction_id},
			{field_type: 'hidden',
				field_name: 'organization_id',
				field_value: props.organization_id},
			{field_type: 'select',
				field_name: 'group_id',
				field_label: 'Group',
				field_value: props.objExists(props.restriction, 'group_id') ? props.restriction.group_id : 0,
				field_required: false,
				field_validate: false,
				field_order: 1,
				option_groups: [{options: optionsGroups ? optionsGroups : []}],
			},
			{field_type: 'select',
				field_name: 'core_user',
				field_label: 'User',
				field_value: props.objExists(props.restriction, 'core_user') ? props.restriction.core_user : 0,
				field_required: false,
				field_validate: false,
				field_order: 1,
				option_groups: [{options: optionsUsers ? optionsUsers : []}],
			},
			{field_type: 'hidden',
				field_name: 'product_id',
				field_value: props.product_id},
			{field_type: 'number',
				field_name: 'restriction_priority',
				field_label: `priority`,
				field_value: props.objExists(props, 'restriction.restriction_priority') ? props.restriction.restriction_priority : null,
				field_placeholder: 'Priority',
				field_order: 3,
				field_required: true,
				field_validate: true,},
			{field_type: 'number',
				field_name: 'restriction_quantity',
				field_label: `quantity`,
				field_value: props.objExists(props, 'restriction.restriction_quantity') ? props.restriction.restriction_quantity : null,
				field_placeholder: 'Quantity',
				field_order: 4,
				field_required: true,
				field_validate: true,},
			{field_type: 'number',
				field_name: 'restriction_days',
				field_label: `days`,
				field_value: props.objExists(props, 'restriction.restriction_days') ? props.restriction.restriction_days : null,
				field_placeholder: 'Days',
				field_order: 5,
				field_required: false,
				field_validate: true,},
			{field_type: 'date',
				field_name: 'restriction_date_start',
				field_label: `start date`,
				field_value: props.objExists(props, 'restriction.restriction_date_start') ? props.restriction.restriction_date_start : null,
				field_placeholder: 'Start Date',
				field_order: 6,
				field_required: false,
				field_validate: true,},
			{field_type: 'date',
				field_name: 'restriction_date_end',
				field_label: `end date`,
				field_value: props.objExists(props, 'restriction.restriction_date_end') ? props.restriction.restriction_date_end : null,
				field_placeholder: 'End Date',
				field_order: 7,
				field_required: false,
				field_validate: true,},
		]
	  }
	]);
  }, [props.restriction, optionsGroups, optionsUsers]);

  useEffect(() => {
	fieldsets &&
	setForm(
	  <FormDynamic
		  content={fieldsets}
		  name="add-edit-restriction"
		  returnObject={true}
		  fieldOnChange={null}
		  fieldOnLoad={null}
		  formActions={{formCancel: handleCancel, formSubmit: handleAddEdit}} />
	)
  }, [fieldsets, optionsGroups, optionsUsers]);

  function handleCancel() {
	props.setModal(null);
  }

  function handleAddEdit(params) {
	props.setModal(null);
	if(props.actionAddEdit) {
	  props.actionAddEdit(params);
	}
  }

  return (fieldsets && form)
}
