import React, {useEffect, useState, Fragment} from 'react';

import TaskListCtl from 'components/profiles/tasks/TaskListCtl';
import TaskListItem from 'components/profiles/tasks/TaskListItem';
import IconSVG from 'components/icons/IconSVG';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [tasks, setTasks] = useState(null);
	const [taskCount, setTaskCount] = useState(null);
	const [filters, setFilters] = useState({sort: {name: 'task_created', order: 'asc'}});
	const [filtersUD, setFiltersUD] = useState(null);
	const [sortBy, setSortBy] = useState('task_created');
	const [filterOptions, setFilterOptions] = useState({});
	const [optionsTaskNames, setOptionsTaskNames] = useState(null);
	const [optionsTaskTypes, setOptionsTaskTypes] = useState(null);
	const [showCtl, setShowCtl] = useState(null);
	
	
	// HOOKS
	// useEffect(() => {
	// 	setFilters({
	// 		available: true,
	// 		adminTasks: false,
	// 		taskName: 'pre-production',
	// 	})
	// 
	// }, []);
	
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.languageCode && 
			props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.tasks && Array.isArray(props.tasks) && languageCode) {
			let taskNames = [];
			let taskTypes = [];
			taskCount !== props.tasks.length &&
			setTaskCount(props.tasks.length);
			props.tasks.map(task => {
				if(!taskNames.find(oTask => oTask.option_name === task[languageCode].task_name)) {
					taskNames.push({option_name: task[languageCode].task_name, 
											option_value: task[languageCode].task_name
					});
				}
				
				if(!taskTypes.find(oTask => oTask.option_name === task.type_name)) {
					taskTypes.push({option_name: task.type_name,
									option_value: task.type_name
					});
				}
			})
			
			
			let fOptions = filterOptions;
			if(taskNames.length > 1) {
				fOptions = {...fOptions, 
								task_name: taskNames.sort((a, b) => {
										return a.option_name > b.option_name ? 1 : -1
									})
							};
			}else if(fOptions.task_name) {
				delete fOptions.task_name;
			}
			
			if(taskTypes.length > 1) {
				fOptions = {...fOptions, 
							task_type: taskTypes.sort((a, b) => {
									return a.option_name > b.option_name ? 1 : -1}
							)}
			}else if(fOptions.task_type) {
				delete fOptions.task_type;
			}
			
			
			setFilterOptions(fOptions);
			
			let orderedTasks = props.tasks;
			sortBy &&
			orderedTasks.sort((a, b) => {
				if(a.task_urgent === b.task_urgent) { 
					return a[sortBy] > b[sortBy] ? 1 : -1
				}
				return a.task_urgent > b.task_urgent ? -1 : 1;
			});
			
			if(filters) {
				if(filters.available) {
					orderedTasks = orderedTasks.filter(task => task.task_owner <= 0 || task.task_ower === userID);
				}
				if(!filters.showAdmin) {
					orderedTasks = orderedTasks.filter(task => task.type_name !== 'admin');
				}
				
				if(filters.task_name && Array.isArray(filters.task_name)) {
					orderedTasks = orderedTasks.filter(task => filters.task_name.includes(task[languageCode].task_name));
				}
				
				if(filters.sort) {
					orderedTasks = orderedTasks.sort((a, b) => {
						return a[filters.sort.name] > b[filters.sort.name] &&
								filters.sort.order === 'desc' ? -1 :
								a[filters.sort.name] > b[filters.sort.name] &&
								filters.sort.order === 'asc' ? 1 :
								a[filters.sort.name] < b[filters.sort.name] &&
								filters.sort.order === 'desc' ? 1 : -1;
								
					})	
				}
			}
			
			if(orderedTasks !== tasks) {
				setTasks(orderedTasks);	
			}
		}
	}, [filters, filtersUD, props.tasks, sortBy, userID, languageCode]);
	
	useEffect(() => {
		if(filtersUD) {
			setFilters(filtersUD);
			setFiltersUD(null);
		}
	}, [filtersUD]);
	
	
	
	return(
		<div className="dashboard-widget">
			
			<div className="widget-header">
				<h3><IconSVG icon="gear" />TASKS</h3>
				<div>{tasks && `${tasks.length} of ${taskCount} pending tasks`}</div>
			</div>
			<TaskListCtl {...props}
				actionUpdateFilters={setFiltersUD}
				filters={filters}
				filterOptions={filterOptions} />
			<div className="scrollbox">
				<ul>
				{Array.isArray(tasks) ? 
					tasks.map((task, index) => {
						return <TaskListItem {...props}
										key={`tlItm${index}`}
										userID={userID}
										token={token}
										languageCode={languageCode}
										task={task} />
					})
				:
					<div style={{padding: '2em'}}>
						Nice! You have no pending tasks.
					</div>
				}
				</ul>
			</div>
			{showCtl &&
			<div className="widget-footer">
				Task Controls
			</div>
			}
		</div>
	)
}