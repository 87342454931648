import React, {useEffect, useState, useRef, Fragment} from 'react';

import Button from 'components/forms/elements/Button';
import FileUploadPreview from 'components/forms/elements/FileUploadPreview';
import FormDynamic from 'components/forms/FormDynamic';
import IconSVG from 'components/icons/IconSVG';
import Loader from 'components/atoms/Loader';

import * as forms from 'methods/forms';

export default (props) => {
  const [fileList, setFileList] =  useState([]);
  const [loadedFiles, setLoadedFiles] = useState(null);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [filePreviews, setFilePreviews] = useState([]);
  const [imagePreview, setImagePreview] = useState();
  const [showPreview, setShowPreview] = useState(false);
  const [uploadStart, setUploadStart] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [removeLocalFile, setRemoveLocalFile] = useState(null);
  const inputRef = useRef();
  const uploadRef = useRef();

  // HOOKS
  useEffect(() => {
    return () => {
      setFileList(null);
      setFilePreviews(null);
      props.filesDeleteVal('uploads');
      inputRef.current.files = null;
    }
  }, []);
  
  useEffect(() => {
    if(props.objExists(props.files, 'uploadComplete')) {
      props.filesDeleteVal('uploads');
      sessionStorage.getItem('filesUpl') &&
      sessionStorage.removeItem('filesUpl');
      props.filesDeleteVal('uploadComplete');
    }else if(props.objExists(props.files, 'uploads') && Array.isArray(props.files.uploads)) {
      if(!sessionStorage.getItem('filesUpl') ||
          (sessionStorage.getItem('filesUpl') &&
          JSON.parse(sessionStorage.getItem('filesUpl')) !== props.files.uploads) ) {
          sessionStorage.setItem('filesUpl', JSON.stringify(props.files.uploads));
      }
    }else if((!props.objExists(props.files, 'uploads') ||
              ((Array.isArray(props.files.uploads) &&
                props.files.uploads.length === 0))) &&
              sessionStorage.getItem('filesUpl')){
      props.filesSetVal('uploads', JSON.parse(sessionStorage.getItem('filesUpl')));
    }
    return() => {
      props.objExists(props.files, 'uploads') &&
      props.filesDeleteVal('uploads');
      
      props.objExists(props.files, 'uploadComplete') &&
      props.filesDeleteVal('uploadComplete');
      
      sessionStorage.getItem('filesUpl') &&
      sessionStorage.removeItem('filesUpl');
    }
  }, [props.files]);

  useEffect(()=> {
    if(removeLocalFile >= 0) {
      let newFiles = [];
      fileList.map((file, index) => {
        if(index !== removeLocalFile) {
          newFiles.push(file);
        }
      })
      let newPreviews = [];
      let newIndex = 0;
      filePreviews.map((prev, index) => {
        if(index !== removeLocalFile) {
          newPreviews.push({...prev, index: newIndex});
          newIndex++;
        }
      })
      setFileList(newFiles);
      setFilePreviews(newPreviews);
      setRemoveLocalFile(null);
    }
  }, [removeLocalFile]);
  
  useEffect(() => {
    if(fileList && filePreviews &&
      filePreviews.length > 0 &&
      fileList.length > 0 &&
      filePreviews.length === fileList.length) {
      setShowPreview(true);
    }
  }, [fileList, filePreviews]);

  useEffect(() => {
    if(uploadStart && props.objExists(props.files, 'uploadComplete')) {
      setUploadStart(false);
      props.filesDeleteVal('uploadComplete');
      setImagePreviews([]);
      setShowPreview(false);
    }
  }, [uploadStart, props.files]);

  useEffect(() => {
    if(props.objExists(props.files, 'uploads') &&
      Array.isArray(props.files.uploads) &&
      props.files.uploads.length > 0) {
        setLoadedFiles(props.files.uploads);
      }else{
        setLoadedFiles(null);
      }
  }, [props.files]);
  
  useEffect(() => {
    if(props.cueUpload === true) {
      uploadFiles();
    }
  }, [props.cueUpload]);

  // FUNCTIONS
  function handleFiles(e) {
    if(e.target.files.length > 0) {
      let aFiles = [];
      let filePrevs = []
      let previewIndex = imagePreviews.length > 0 ? imagePreviews.length : 0;
      for(var i = 0; i < e.target.files.length; i++) {

        aFiles.push(e.target.files[i]);
        switch(e.target.files[i].type) {
          case 'application/pdf':
            filePrevs.push(<FileUploadPreview icon="pdf" index={previewIndex} location="input" actionRemove={removeFile} file={e.target.files[i]} />);
            break;
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            filePrevs.push(<FileUploadPreview icon="doc" index={previewIndex} location="input" actionRemove={removeFile} file={e.target.files[i]} />);
            break;
          case 'application/x-photoshop':
            filePrevs.push(<FileUploadPreview icon="psd" index={previewIndex} location="input" actionRemove={removeFile} file={e.target.files[i]} />);
            break;
          case 'video/mp4':
            filePrevs.push(<FileUploadPreview icon="video" index={previewIndex} location="input" actionRemove={removeFile} file={e.target.files[i]} />);
            break;
          case 'application/postscript':
            filePrevs.push(<FileUploadPreview icon="ai" index={previewIndex} index={i} location="input" actionRemove={removeFile} file={e.target.files[i]} />);
            break;
          case 'application/zip':
            filePrevs.push(<FileUploadPreview icon="archive" index={previewIndex} location="input" actionRemove={removeFile} file={e.target.files[i]} />);
            break;
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            filePrevs.push(<FileUploadPreview icon="xls" index={previewIndex} location="input" actionRemove={removeFile} file={e.target.files[i]} />);
            break;
          case "image/gif":
          case "image/jpeg":
          case "image/pjpeg":
          case "image/svg+xml":
          case "image/png":
          case "image/bmp":
            filePrevs.push(<FileUploadPreview icon='img' index={previewIndex} location="input" actionRemove={removeFile} file={e.target.files[i]} />);
            break;
          default:
            filePrevs.push(<FileUploadPreview icon="file" index={previewIndex} location="input" actionRemove={removeFile} file={e.target.files[i]} />);
            break;
        }
        previewIndex++;
      }
      setFileList([...fileList, ...aFiles]);
      setFilePreviews([...filePreviews, ...filePrevs]);
      
      let fileDate = new Date(0);
      fileDate.setUTCMilliseconds(e.target.files[0].lastModified);
      
      if(props.actionSelect) {
        let selectedFile = {
          name: e.target.files[0].name,
          size: e.target.files[0].size,
          type: e.target.files[0].type,
          tag: props.getFileTag(e.target.files[0].type),
          lastModified: fileDate
        };
        // selectedFile[fileTag] = fileTag;
        props.actionSelect(selectedFile);
      }
    }
  }

  function uploadFiles() {
    const fileData = new FormData();
    fileData.append('process', 'upload_files');
    fileData.append('user_id', props.userID ? props.userID : props.user.user_id);
    fileData.append('token', props.token ? props.token : null);
    fileData.append('organization_id', props.organizationID ? props.organizationID : 1);
    fileData.append('language_code', props.language_code ? props.language_code : 'en');
    fileData.append('media_type', props.media_type ? props.media_type : null);
    fileData.append('media_published', props.media_published ? props.media_published : false);
    if(props.media_owner_type && props.media_owner_id) {
      fileData.append('media_owner_type', props.media_owner_type);
      fileData.append('media_owner_id', props.media_owner_id);
    }
    if(props.media_file_upload_name) {
      fileData.append('media_file_upload_name', props.media_file_upload_name);
    }else if(fileList && Array.isArray(fileList)) {
      fileData.append('media_file_upload_name', fileList[0].name);
    }
    if(props.file_location) {
      fileData.append('file_location', props.file_location);
    }
    if(props.return_boolean) {
      fileData.append('return_boolean', props.return_boolean);
    }
    
    if(props.create_images) {
      fileData.append('create_images', props.create_images);
    }else{
      fileData.append('create_images', false);
    }
    
    fileList && Array.isArray(fileList) &&
    fileList.map((file, index) => {
      fileData.append(`file${index}`, file);
    })
    
    console.log("FILE DATA", fileData);
    props.filesUpload(fileData, 
                        props.cueReload ? props.cueReload : null, 
                        props.stateLocation ? props.stateLocation : props.name ? props.name : null,
                        props.returnInfo === false ? false : true,
                        props.retainModal ? true : false);
    props.actionUpload &&
    props.actionUpload.action &&
    props.actionUpload.action(props.actionUpload.params ? props.actionUpload.params : null);
    // props.cueReload &&
    // props[`${props.cueReload.location ? props.cueReload.location : 'admin'}SetValue`](`reload.${props.cueReload.type ? props.cueReload.type : 'missing'}`);
    setFileList([]);
    setImagePreview(null);
    setUploadStart(true);
  }

  function buildPreview(file, index) {
    var reader = new FileReader();
    reader.onload = function (e) {
      return (<FileUploadPreview
                        src={e.target.result}
                        actionRemove={()=>{removeFile(index, 'input')}}
                        file={file}
                        index={index}
                        location='input' />);
    };
    return reader.readAsDataURL(file);
  }

  function editFile(file, location, files) {
      const form = <FormDynamic
                      content={setEditForm(file, location, files)}
                      name='Add/Edit Media Info'
                      closable={true}
                      unmountableOnClick={true}
                      returnObject={true}
                      cancelable={true}
                      includeControls={true}
                      multiStep={false}
                      formActions={{formSubmit: updateMediaInfo}}
                    />;
    props.setModal(form);
  }

  function updateMediaInfo(formData) {
    if(formData && Array.isArray(formData) && formData.length > 0) {
      formData.map(dataPair => {
        if(dataPair.name !== 'form-type') {
          props.filesSetVal(dataPair.name, dataPair.value);
        }
      })
    }
    // props.setModal(null);
  }

  function removeFile(file, location) {
    if(location === 'input') {
      setRemoveLocalFile(file);
    }else{
      props.filesDeleteVal(`${location}[${file}]`);
    }
  }

  function setEditForm(file, location, files) {
    return [{fieldset_name: 'Media Info', fields: forms.mediaInfo(file, location, files)}];
  }
  
  function openFileSelect(target) {
    let uploadBtn = null;
    while(!target.classList.contains('form-row') 
            && target.tagName.toLowerCase() !== 'body') {
          target = target.parentNode;
    }
    
    if(target.classList.contains('form-row')) {
      uploadBtn = target.querySelector('input[type=button]');
    }
    if(inputRef.current) {
      inputRef.current.click();
    }
  }
  
  function handleSubmit() {
    if(uploadRef.current) {
      const ulBtn = uploadRef.current.querySelector('button');
      if(ulBtn) {
        ulBtn.click();
      }
    }
  }
  
  return (
    <div className={`file-upload${uploadStart  ? ' in-progress' : ''}`}>
      <fieldset className="file-upload-zone">
        {props.legend && <legend>{props.legend}</legend>}
        {props.label && <label><IconSVG icon="file" />{props.label}</label>}
        <div className="form-row">
          <input type="file"
                  id="file-input"
                  name={props.name}
                  ref={inputRef}
                  multiple={false}
                  onChange={handleFiles}
                   />
        </div>
        {showPreview && filePreviews &&
          <Fragment>
            <div className="file-upload-preview-set pre">
              {filePreviews}
            </div>
            <p>Upload the selected file</p>
          </Fragment>
        }
        {fileList && fileList.length > 0 &&
          <div className="form-row" ref={uploadRef}>
            <Button icon="upload"
                    label={`Upload File${fileList && fileList.length > 1 ? 's' : ''}`}
                    btnClass="success-bg btn-upload"
                    btnAction={uploadFiles}
                    disabled={fileList && fileList.length > 0 && !props.disableUpload ? false : true}
                     />
          </div>
        }
      </fieldset>
      {loadedFiles &&
        Array.isArray(loadedFiles) &&
        <div className="file-upload-preview-set loaded">
          {loadedFiles.map((file, index)=> {

            return <FileUploadPreview
                      {...props}
                      src={['png', 'jpg', 'bmp', 'gif'].includes(file.media_file_tag) &&
                              `http://core.accellgraphics.ca/assets/media/${file.media_file_name}`}
                      icon={!['png', 'jpg', 'bmp', 'gif'].includes(file.file_tag) &&
                            file.file_tag}
                      file={file}
                      actionEdit={editFile}
                      actionRemove={removeFile}
                      index={index}
                      location='uploads'
                    />
          })}
        </div>
      }
      {props.actionCancel &&
        <div className="form-row">
          <Button icon="close" label="Cancel" btnAction={()=>{props.actionCancel()}} /> 
          {props.includeSubmit &&
          <Button icon="upload" label="Save" btnClass="success-bg" btnAction={handleSubmit} disable={true} />         
          }
        </div>  
      }
      {uploadStart &&
        <Loader label="Uploading file..." overlayContents={true} />
      }
    </div>
  )
}
