import React, {useEffect, useState, Fragment} from 'react';

import Loader from 'components/atoms/Loader';
import User from 'components/profiles/users/User';
import FormLogin from 'components/forms/common_forms/FormLogin';

import * as utils from 'methods/site';

export default (props) => {
  if(!utils.objExists(props, 'user') && utils.objExists(props.children, 'user')) {
    props = {...props.children, ...utils};
  }else{
    props = {...props, ...utils};
  }

  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [user, setUser] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);

  useEffect(() => {
    if(props.objExists(props.user, 'user_id') &&
        user !== props.user) {
      setUser(props.user);
      
      if(userID !== props.user.user_id) {
        setUserID(props.user.user_id);
      }
    }
    
    if(localStorage.getItem('token') && 
      localStorage.getItem('token') !== token) {
        setToken(localStorage.getItem('token'));
    }else if(sessionStorage.getItem('token') && 
            sessionStorage.getItem('token') !== token) {
      setToken(sessionStorage.getItem('token'));
    }
  }, [props.user]);
  
  useEffect(() => {
    if(props.objExists(props.site, 'persona.organization_id') &&
      organizationID !== props.site.persona.organization_id) {
      setOrganizationID(props.site.persona.organization_id);
    }
    if(props.objExists(props.site, 'persona.organization_name') &&
      organizationName !== props.site.persona.organization_name) {
      setOrganizationName(props.site.persona.organization_name);
    }
  }, [props.site]);
  
  useEffect(() => {
    if(props.objExists(props.site, 'site_language') &&
        languageCode !== props.site.site_language) {
      setLanguageCode(props.site.site_language);
    }else if(!languageCode) {
      setLanguageCode('en');
    }
  }, [props.site.site_language]);
  
  useEffect(() => {
    if(props.objGetValue(props.user, 'reload.user') === true) {
      const paramsUser = [
          {name: 'process', value: 'get_user_from_token'},
          {name: 'token', value: token}
      ];
      props.userLoadState(paramsUser, 'users', null);
      props.userDeleteVal('reload.user');
    }
  }, [props.user.reload]);

  return (
      <Fragment>
      {userID && token && user && organizationID && languageCode ?
        <User {...props}
              coreUser={user}
              userID={userID}
              token={token}
              organizationID={organizationID}
              organizationName={organizationName}
              admin={false}
              stateLocation='user' />
      :
        <Loader />
      }
      </Fragment>
  )
}
