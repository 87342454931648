import React from 'react';
import PagePublic from 'components/templates/PagePublic';

import Cart from 'components/cart/Cart';

export default () => {
  return (
    <PagePublic >
      <Cart />
    </PagePublic>
  )
}
