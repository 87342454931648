import React, {useEffect, useState} from 'react';

import IconSVG from 'components/icons/IconSVG';

export default (props) => {
	const [option, setOption] = useState(null);
	const [active, setActive] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(props.option && props.option !== option) {
			setOption(props.option);
		}
	}, [props.option]);
	
	// FUNCTIONS 
	function selectOption() {
		let selectVal = active ? false : true;
		setActive(selectVal);
		if(props.action) {
			props.action({name: option.option_value, 
							value: option.option_order ? option.option_order : selectVal,
							filter: props.filter,
							order: option.option_order});
		}
		
	}
	return (
		option &&
		<div className={`filter-option${active || props.active ? ' active' : ''}`}
			onClick={selectOption}>
			{option.option_icon &&
				<IconSVG icon={option.option_icon} />
			}
			{props.capitalizeString(option.option_name, true)}
		</div>
	)
}