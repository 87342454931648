import React, {useEffect, useState, Fragment} from 'react';

import IconToken from 'components/icons/IconToken';
import FormCompleteCreativeTask from 'components/forms/common_forms/FormCompleteCreativeTask';
import FormCompleteShippingTask from 'components/forms/common_forms/FormCompleteShippingTask';
import FormCompleteCostTask from 'components/forms/common_forms/FormCompleteCostTask';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [canEdit, setCanEdit] = useState(null);
	const [isSuper, setIsSuper] = useState(null);
	const [taskState, setTaskState] = useState(null);
	const [task, setTask] = useState(null);
	const [subtasks, setSubtasks] = useState(null);
	const [subtasksComplete, setSubtasksComplete] = useState(null);
	const [taskName, setTaskName] = useState(null);
	const [completeForm, setCompleteForm] = useState(null);
	const [loadComplete, setLoadComplete] = useState(null);
	const [shippingCarriers, setShippingCarriers] = useState(null);
	const [orderLocations, setOrderLocations] = useState(null);
	
	// HOOKS
	useEffect(() => {
		props.userID && 
		props.userID !== userID &&
		setUserID(props.userID);
	}, [props.userID]);
	
	useEffect(() => {
		props.token && 
		props.token !== token &&
		setToken(props.token);
	}, [props.token])
	
	useEffect(() => {
		props.organizationID && 
		props.organizationID !== organizationID &&
		setOrganizationID(props.organizationID);
	}, [props.organizationID]);
	
	useEffect(() => {
		props.languageCode && 
		props.languageCode !== languageCode &&
		setLanguageCode(props.languageCode);
	}, [props.languageCode]);
	
	useEffect(() => {
		props.canEdit &&
		props.canEdit !== canEdit &&
		setCanEdit(props.canEdit);
	}, [props.canEdit]);
	  
	useEffect(() => {
		props.isSuper &&
		props.isSuper !== isSuper &&
		setIsSuper(props.isSuper);
	}, [props.isSuper]);
	
	useEffect(() => {
		if(props.task && props.task !== task) {
			setTask(props.task);
		}
	}, [props.task]);
	
	useEffect(() => {
		if(props.objExists(task, `en.task_name`)) {
			setTaskName(task.en.task_name);
		}
		
		if(props.objExists(task, `subtasks`) &&
			subtasks !== task.subtasks) {
			setSubtasks(task.subtasks);
		}
	}, [task]);
	
	useEffect(() => {
		if(props.subtasksComplete && !subtasksComplete) {
			setSubtasksComplete(true);
		}else if (!props.subtasksComplete && subtasksComplete) {
			setSubtasksComplete(false);
		}
	}, [props.subtasksComplete]);
	
	useEffect(() => {
		if(Array.isArray(subtasks)) {
			const incompletedSubtasks = subtasks.filter(stask => !stask.task_completed);
			if(incompletedSubtasks.length > 0) {
				setSubtasksComplete(false);
			}else{
				setSubtasksComplete(true);
			}
		}
	}, [task, subtasks]);
	
	useEffect(() => {
		if(props.shippingCarriers && 
			props.shippingCarriers !== shippingCarriers) {
			setShippingCarriers(props.shippingCarriers);
		}
	}, [props.shippingCarriers]);
	
	useEffect(() => {
		if(props.orderLocations &&
			props.orderLocations !== orderLocations) {
			setOrderLocations(props.orderLocations);
		}
	})
	
	useEffect(() => {
		if(languageCode && props.objExists(task, `status[0].${languageCode}.status_name`)) {
			switch(task.status[0][languageCode].status_name) {
				case 'in progress':
					setTaskState('in progress');
					break;
				case 'on hold':
					setTaskState('on hold');
					break;
				case 'complete':
					setTaskState('complete');
					break;
				default:
					setTaskState(null);
					break;	
			}
			
		}
	}, [task, languageCode]);
	
	useEffect(() => {
		if(taskName === 'creative') {
			setCompleteForm(
				<FormCompleteCreativeTask {...props}
					userID={userID}
					token={token}
					languageCode={languageCode}
					task={props.task}
					task_owner_type={props.taskOwnerType}
					task_owner_id={props.taskOwnerID}
					task_root_type={props.taskRootType}
					task_root_id={props.taskRootID}
					actionCancel={()=>{props.setModal(null);}}
					actionSubmit={completeCreative}
					fileName={`${props.objExists(props.task, 'item_name') ? `${props.task.order_id ? `O${props.task.order_id}-I${props.task.item_id}` : `I${props.task.item_id}`} ${props.task.item_name}` : null}`}
					fileVersion={Array.isArray(props.proofs) ? props.proofs.length+1 : null} />
			)	
		}else if(taskName === 'shipping' || taskName === 'provide costs') {
			setCompleteForm(
				<FormCompleteShippingTask {...props}
					userID={userID}
					token={token}
					languageCode={languageCode}
					task={props.task}
					task_owner_type={props.taskOwnerType}
					task_owner_id={props.taskOwnerID}
					task_root_type={props.taskRootType}
					task_root_id={props.taskRootID}
					shippingCarriers={shippingCarriers}
					orderLocations={orderLocations}
					actionCancel={()=>{props.setModal(null);}}
					actionSubmit={completeTask}
				 	/>
			)
		}
		
	}, [props.files, task, props.taskOwnerType, props.taskOwnerID, props.taskRootType, props.taskRootID, props.proofs, taskName, shippingCarriers, orderLocations]);
	
	useEffect(() => {
		if(loadComplete && completeForm) {
			props.setModal(completeForm);
		}
	}, [loadComplete, completeForm]);
	
	// FUNCTIONS
	function updateTaskStatus(taskStatus) {
		setTaskState(taskStatus);
		const pauseParams = [
			{name: 'process', value: 'update_task_status'},
			{name: 'user_id', value: userID},
			{name: 'token', value: token},
			{name: 'task_id', value: task.task_id},
			{name: 'task_owner', value: userID},
			{name: 'task_status', value: taskStatus},
			{name: 'task_root_type', value: props.taskRootType},
			{name: 'task_root_id', value: props.taskRootID}	
			
		];
		
		props[`${props.location}LoadState`](pauseParams, 'tasks', `reload.${props.source ? props.source : 'tasks'}`);
		if(props.actionUpdate) {
			props.actionUpdate(props.task.task_id, taskStatus);
		}
	}
	
	function releaseTask() {
		setTaskState(null);
		const releaseParams = [
			{name: 'process', value: 'release_task'},
			{name: 'user_id', value: props.userID},
			{name: 'token', value: props.token},
			{name: 'task_id', value: props.task.task_id}
		];
		props[`${props.location}LoadState`](releaseParams, 'tasks', `reload.${props.source ? props.source : 'tasks'}`);
		if(props.actionUpdate) {
			props.actionUpdate(props.task.task_id, props.task.status[0][languageCode].status_name);
		}
	}
	
	function loadCompleteTask() {
		setLoadComplete(true);
	}
	
	function completeShippingCosts(params) {
		
		props.setModal(null);
		// NEED TO ASSIGN COMMENTS AND COSTS 
		
		let completeParams = params.filter(param => ![
			'form-type', 
			'handling_cost', 
			'shipping_cost',
			'message_text',
		].includes(param.name));
		
		props[`${props.location}LoadState`](completeParams, 'tasks', `reload.${props.source ? props.source : 'task'}`);
		setTaskState('complete');
		if(props.actionUpdate) {
			props.actionUpdate(task.task_id, props.task.status[0][languageCode].status_name);
		}
	}
	
	function completeCreative(params) {
		props.setModal(null);
		setLoadComplete(false);
		
		const mediaProof = params.find(param => param.name === 'item_file' && parseInt(param.value) > 0);
		const mediaFileUploadName = params.find(param => param.name === 'media_file_upload_name');
				if(mediaProof && mediaFileUploadName) {
					const fileNameParams = [
						{name: 'process', value: 'update_media_upload_name'},
						{name: 'user_id', value: userID},
						{name: 'token', value: token},
						{name: 'media_id', value: parseInt(mediaProof.value)},
						{name: 'media_file_upload_name', value: mediaFileUploadName.value}
					]
					
					props[`${props.location}LoadState`](fileNameParams, 'media', `reload.${props.source ? props.source : 'item'}`);
				}
				if(mediaProof) {
					let fileParams = [
						{name: 'process', value: 'add_link'},
						{name: 'user_id', value: userID},
						{name: 'token', value: token},
						{name: 'link_from', value: mediaProof.value},
						{name: 'link_to', value: task.item_id},
						{name: 'link_type', value: 'proofToItem'},
						{name: 'link_published', value: true},
						{name: 'return_bool', value: true}
					];
					props[`${props.location}LoadState`](fileParams, 'links', `reload.${props.source ? props.source : 'item'}`);
				}
				
				let completeParams = params.filter(param => ![
					'form-type', 
					'media_name', 
					'fileVersion', 
					'file_version',
					'item_file',
					'media_file_upload_name'
				].includes(param.name));
				
				completeParams.push(mediaProof);
				
				props[`${props.location}LoadState`](completeParams, 'tasks', `reload.${props.source ? props.source : 'task'}`);
				setTaskState('complete');
				if(props.actionUpdate) {
					props.actionUpdate(task.task_id, props.task.status[0][languageCode].status_name);
				}
				
	}
	
	function completeTask(params) {
		props.setModal(null);
		setTaskState('complete');
		setLoadComplete(false);
		
		const completeParams = [
			{name: 'process', value: 'advance_task'},
			{name: 'user_id', value: props.userID},
			{name: 'token', value: props.token},
			{name: 'language_code', value: props.languageCode},
			{name: 'task_id', value: props.task.task_id},
			{name: 'task_owner_type', value: props.taskOwnerType},
			{name: 'task_owner_id', value: props.taskOwnerID},
			{name: 'task_status', value: 'complete'},
			{name: 'task_root_type', value: props.taskRootType},
			{name: 'task_root_id', value: props.taskRootID}	
		];
		
		props[`${props.location}LoadState`](completeParams, 'tasks', `reload.${props.source ? props.source : 'project'}`);
		if(props.actionUpdate) {
			props.actionUpdate(props.task.task_id, 'complete');
		}
	}
	
	return (
		<Fragment>
			{subtasks && !subtasksComplete &&
				<p>Check off your To-Do's before you can Complete the task</p>
			}
			<div className="task-ctl-set">
				<IconToken icon="start" 
							tooltip={taskState === 'in progress' || taskState === 'complete' ? null : `${taskState === 'on hold' ? 'Resume' : 'Begin'} Task`} 
							disabled={taskState === 'in progress' || taskState === 'complete' ? true : false}
							action={()=>{updateTaskStatus(taskState === 'on hold' ? 'resume' : 'start')}} />
				<IconToken icon="pause" 
							tooltip={taskState !== 'in progress' || taskState === 'on hold' || taskState === 'complete' ? null : "Pause Task"}
							disabled={taskState !== 'in progress' || taskState === 'on hold' || taskState === 'complete' ? true : false}
							action={()=>{updateTaskStatus("on hold")}} />
				<IconToken icon="cancel" 
							tooltip={taskState === 'in progress' || taskState === 'in progress' ? "Cancel Task" : null} 
							disabled={taskState === 'in progress' || taskState === 'in progress' ? false : true}
							action={releaseTask} />			
				<IconToken icon="complete" 
							tooltip={taskState === 'in progress' || taskState === 'on hold' ? 
										props.subtasksComplete === false ? 
											"To-dos must be completed" : "Complete Task" : 
												null} 
							disabled={(taskState === 'in progress' || taskState === 'on hold') && props.subtasksComplete !== false ? false : true} 
							action={['creative', 'shipping', 'provide costs'].includes(taskName) ? 
										loadCompleteTask : 
											completeTask} />
			</div>
		</Fragment>
	)
}