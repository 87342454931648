import React, {useEffect, useState} from 'react';

import IconToken from 'components/icons/IconToken';
import Badge from 'components/atoms/Badge';
import Link from 'components/navigation/Link';


export default (props) => {
  const [cartCount, setCartCount] = useState(0);
  
  useEffect(() => {
    if(props.cart.cartCount && props.cart.cartCount > 0 &&
      props.cart.cartCount !== cartCount) {
      setCartCount(props.cart.cartCount);  
    }
  }, [props.cart.cartCount]);
  
  return (
      <div className={`token token-cart${props.className ? ` ${props.className}` : ''}`}>
          <Link to="/cart">
            <IconToken action={null} icon="cart" />
            <Badge className={cartCount > 0 ? 'active' : ''}
               content={cartCount} />
          </Link>
      </div>
  )
}
