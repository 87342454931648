import React, {useEffect, useState, Fragment} from 'react';

import OrganizationListItem from 'components/profiles/orgs/OrganizationListItem';
import OrganizationListCtl from 'components/profiles/orgs/OrganizationListCtl';
import Loader from 'components/atoms/Loader';
import Control from 'components/atoms/Control';
import Select from 'components/forms/elements/Select';

export default (props) => {
  const [organizations, setOrganizations] = useState(null);
  const [organizationsFiltered, setOrganizationsFiltered] = useState(null);
  const [filter, setFilter] = useState(null);
  const [clearFilter, setClearFilter] = useState(null);
  const [activeFilters, setActiveFilters] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  const [searchOption, setSearchOption] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [sort, setSort] = useState(null);
  const [order, setOrder] = useState(null);
  const [userID, setUserID] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [token, setToken] = useState(null);
  const [siteLanguage, setSiteLanguage] = useState(null);


  // const searchOptions = [{name: 'Name', value: 'name', default: true},
  //                       {name: 'Email', value: 'email', default: false}];

  useEffect(() => {
    props.userID &&
    setUserID(props.userID);
  }, [props.userID]);

  useEffect(() => {
    props.token &&
    setToken(props.token);
  }, [props.token])

  useEffect(() => {
    props.organizationID &&
    setOrganizationID(props.organizationID);
  }, [props.organizationID]);

  useEffect(() => {
    props.organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);

  useEffect(() => {
    props.siteLanguage &&
    setSiteLanguage(props.siteLanguage);
  }, [props.siteLanguage]);

  // BUILD MASTER ORGS OBJECT FROM PROPS
  useEffect(() => {
    if(props.isAdmin && props.organizations) {
      setOrganizations(props.organizations);
    }
    // if(props.users && props.organization_id && ![1,2].includes(props.organization_id)) {
    //   setUsers(props.users.filter(user => user.organizations && user.organizations.find(org => org.organization_id === props.organization_id)));
    // }else{
    //   setUsers(props.users);
    // }
    // if(props.users &&
    //     Array.isArray(props.users) &&
    //     props.users.length > 0) {
    //   let usersOrdered = props.users.sort((a, b) => {return a.organization_id > b.organization_id ? 1 : -1} );
    //   let userOrgs = [];
    //   let userOrg = null;
    //   let userRoles = [];
    //   usersOrdered.map(user => {
    //     if(user.organization_id !== userOrg) {
    //       userOrg = user.organization_id;
    //       userOrgs.push({option_name: user.organization, option_value: user.organization_id});
    //       if(!userRoles.includes({option_name: user.user_role, option_value: user.user_role})) {
    //         userRoles.push({option_name: user.user_role, option_value: user.user_role});
    //       }
    //     }
    //   })
    //   setOrganizations(userOrgs);
    //   setRoles(userRoles);
    // }
  }, [props.organizations]);

  // CREATE INITIAL FILTERED USERS LIST FROM USERS MASTER
  useEffect(() => {
    organizations && !organizationsFiltered &&
    setOrganizationsFiltered(organizations);
  }, [organizations]);

  // ON SHOW ALL: CLEAR FILTER FOR SPECIFIED FILTER SET
  useEffect(() => {
    // if(clearFilter) {
    //   let newFilters = activeFilters;
    //   if(newFilters[clearFilter]) {
    //     delete newFilters[clearFilter];
    //     setActiveFilters(newFilters);
    //   }
    //   setClearFilter(null);
    // }
  }, [clearFilter]);

  // AS FILTERS GET SET OR UNSET UPDATE ACTIVE FILTERS
  useEffect(() => {
    // if(filter) {
    //   let newFilters = activeFilters ? activeFilters : {};
    //   if(newFilters[filter.filterGroup] && Array.isArray(newFilters[filter.filterGroup])) {
    //
    //
    //     if(filter.filterValue === false && newFilters[filter.filterGroup].includes(filter.filterName)) {
    //       newFilters[filter.filterGroup].splice(newFilters[filter.filterGroup].indexOf(filter.filterName), 1);
    //     }else if(filter.filterValue === true) {
    //       if(!newFilters[filter.filterGroup]) {
    //         newFilters[filter.filterGroup] = [filter.filterName];
    //       }else if(newFilters[filter.filterGroup] && !newFilters[filter.filterGroup].includes(filter.filterName)) {
    //         newFilters[filter.filterGroup].push(filter.filterName);
    //       }
    //     }
    //   }else if (filter.filterValue === true){
    //     newFilters[filter.filterGroup] = [filter.filterName];
    //   }
    //   // newFilters !== activeFilters &&
    //   setActiveFilters(newFilters);
    //   setFilter(null);
    // }

  }, [filter, clearFilter]);

  // FILTER AND SEARCH LIST
  // useEffect(() => {
  //   console.log("Do we have filters?", activeFilters, users);
  //   let filteredList = users;
  //
  //   if(activeFilters && Object.entries(activeFilters).length > 0 ) {
  //     // let filteredList = [];
  //     let filterApplied = false;
  //     Object.entries(activeFilters).map(filter => {
  //       if(filter[1] && Array.isArray(filter[1]) && filter[1].length > 0) {
  //           filterApplied = true;
  //           filteredList = filteredList.filter(item => filter[1].includes(item[filter[0]]));
  //       }
  //     })
  //     // console.log("the filter was applied", filterApplied);
  //     // if(filterApplied) {
  //     //   filteredUsers = filteredList;
  //     // }
  //   }
  //
  //   let searchResults = filteredList;
  //   if(searchOption && searchValue) {
  //     switch(searchOption) {
  //       case 'email':
  //         searchResults = searchResults.filter(user => user.contact_email.toLowerCase().match(searchValue.toLowerCase()));
  //         break;
  //       case 'name':
  //         const nameNodes = searchValue.toLowerCase().split(' ');
  //         nameNodes.map(node => {
  //           searchResults = searchResults.filter(user => user.contact_name_first.toLowerCase().includes(node.toLowerCase()) ||
  //                                               user.contact_name_last.toLowerCase().includes(node.toLowerCase()));
  //         });
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  //   setUsersFiltered(searchResults);
  // }, [activeFilters, filter, clearFilter, searchOption, searchValue]);

  // FUNCTIONS
  function searchOrganizations(value) {
    setSearchValue(value);
  }

  function showAll(name, value, filterGroup) {
    setClearFilter(filterGroup);
  }

  function selectFilter(name, value, filterGroup) {
    setFilter({filterName: name, filterValue: value, filterGroup: filterGroup});
  }

  console.log("Orgainzations", props.admin.organizations, props.organizations);
  return (
    <div className="info-list users">
      <div className="info-list-header users" >
        <OrganizationListCtl listName="Organizations"
                      actionOptionSelect={setSearchOption}
                      actionSearch={searchOrganizations}
                      searchOptions={null}
                      filterSets={[{filterLabel: 'My Organization(s)', filterName: 'organization_id', filterOptions: organizations}]}
                      activeFilters={activeFilters}
                      actionShowAll={showAll}
                      actionSelectFilter={selectFilter}
                      liveSearch={true} />
      </div>
      { organizationsFiltered && Array.isArray(organizationsFiltered) ?
        organizationsFiltered.map((organization, index) => {
          return <OrganizationListItem {...props}
                                key={`orgLstItm${index}`}
                                organization={organization}
                                userID={userID}
                                token={token}
                                organizationID={organizationID}
                                organizationName={organizationName}
                                siteLanguage={siteLanguage}
                                showAll={props.showAll} />})
        :
        <Loader />
      }
    </div>
  )
}
