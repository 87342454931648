import React, {useEffect, useState} from 'react';

import LocationListItem from 'components/profiles/locations/LocationListItem';
import Loader from 'components/atoms/Loader';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [userAccess, setUserAccess] = useState(null);
	const [locations, setLocations] = useState(null);
	
	// HOOKS
	useEffect(() => {
		props.userID &&
		props.userID !== userID &&
		setUserID(props.userID);
	}, [props.userID]);
	
	useEffect(() => {
		props.token &&
		props.token !== token &&
		setToken(props.token);
	}, [props.token])
	
	useEffect(() => {
		props.organizationID &&
		props.organizationID !== organizationID &&
		setOrganizationID(props.organizationID);
	}, [props.organizationID]);
	
	 useEffect(() => {
		props.languageCode &&
		props.languageCode !== languageCode &&
		setLanguageCode(props.languageCode);
	}, [props.languageCode]);
	  
	useEffect(() => {
		props.userAccess &&
		props.userAccess !== userAccess &&
		setUserAccess(props.userAccess);
	}, [props.userAccess]);
	
	useEffect(() => {
		if(props.locations) {
			let locs = props.locations;
			if(props.omitDefault) {
				
			}
			if(locs !== locations) {
				setLocations(locs);
			}
		}
		
	}, [props.locations, props.omitDefault]);
	
	return (
		<div className="info-list">
			{Array.isArray(locations) ?
				locations.length > 0 ?
					locations.map((location, index) => {
						return (
							<LocationListItem {...props}
								key={`lli${index}`}
								userID={userID}
								token={token}
								organizationID={organizationID}
								languageCode={languageCode}
								location={location}
								locationType={props.locationType}
								locationTypeID={props.locationTypeID}
								stateLocation={props.stateLocation}
								listLocation={props.listLocation} />
						)	
					})
				: <p>No locations</p>
			:
				<Loader msg="Loading locations..." />
			}
		</div>
	)
}