import React, {useEffect, useState} from 'react';

import IconToken from 'components/icons/IconToken';

export default (props) => {
	
	function handleRemove() {
		if(props.actionRemove) {
			props.actionRemove(props.taskID, props.user.user_id);
		}
	}
	
	function handleAssign() {
		if(props.actionAssign) {
			props.actionAssign(props.taskID, props.user.user_id);
		}
	}
	
	return (
		<div className="task-user">
			<label>{`${props.user.contact_name_first} ${props.user.contact_name_last}`}</label>
			<div className="ctl">
				<IconToken icon={props.actionRemove ? 'close' : 'add'} 
							className="btn"
							tooltip={props.actionRemove ? 'Remove user' : 'Assign User'} 
							action={props.actionRemove ? handleRemove : handleAssign} />
			</div>
		</div>
	)
}