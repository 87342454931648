import React, {useEffect, useState, Fragment} from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import Control from 'components/atoms/Control';
import Input from 'components/forms/elements/Input';
import TextArea from 'components/forms/elements/TextArea';
import InputNumberCtl from 'components/forms/elements/InputNumberCtl';
import Select from 'components/forms/elements/Select';
import Switch from 'components/forms/elements/Switch';
import IconToken from 'components/icons/IconToken';
import IconSVG from 'components/icons/IconSVG';
import ColourSelector from 'components/forms/elements/ColourSelector';
import FormAddEditFieldAppendage from 'components/forms/common_forms/FormAddEditFieldAppendage';
import ShellFieldCtlAppendage from 'components/profiles/shells/ShellFieldCtlAppendage';

export default (props) => {
	const [field, setField] = useState(null);
	const [fieldCtl, setFieldCtl] = useState(null);
	const [group, setGroup] = useState(null);
	const [groupCtl, setGroupCtl] = useState(null);
	const [reset, setReset] = useState(null);
	const [inputPosX, setInputPosX] = useState(null);
	const [inputPosY, setInputPosY] = useState(null);
	const [inputs, setInputs] = useState(null);
	const [placeholderInput, setPlaceholderInput] = useState(null);
	const [fonts, setFonts] = useState(null);
	const [optionsFonts, setOptionsFonts] = useState(null);
	const [fieldFont, setFieldFont] = useState(null);
	const [fieldFontSize, setFieldFontSize] = useState(null);
	const [fieldFontStyle, setFieldFontStyle] = useState(null);
	const [fieldFontColour, setFieldFontColour] = useState(null);
	const [prefixFont, setPrefixFont] = useState(null);
	const [prefixFontSize, setPrefixFontSize] = useState(null);
	const [prefixFontStyle, setPrefixFontStyle] = useState(null);
	const [prefixFontColour, setPrefixFontColour] = useState(null);
	const [suffixFont, setSuffixFont] = useState(null);
	const [suffixFontSize, setSuffixFontSize] = useState(null);
	const [suffixFontStyle, setSuffixFontStyle] = useState(null);
	const [suffixFontColour, setSuffixFontColour] = useState(null);
	const [groupPos, setGroupPos] = useState(null);
	const [groupFont, setGroupFont] = useState(null);
	const [groupFieldID, setGroupFieldID] = useState(null);
	
	// HOOKS
	useEffect(() => {
		return () => {
			setField(null);
			setGroup(null);
		}
	}, []);
	
	useEffect(() => {
		if(props.reset && !reset) {
			setReset(props.reset);
		}
	}, [props.reset]);
	
	useEffect(() => {
		if(props.field && props.field !== field) {
			setField(props.field);
			setFieldCtl({...props.field});
		}
	}, [props.field]);
	
	useEffect(() => {
		if(props.group && props.group !== group) {
			setGroup(props.group);
			setGroupCtl({...props.group});
		}
	}, [props.group]);
	
	useEffect(() => {
		if(Array.isArray(props.objGetValue(field, `group.fields`))) {
			let gFields = field.group.fields.sort((a, b) => {
				return a.field_order < b.field_order ? -1 : 1;
			})
			if(props.objExists(gFields[0], `field_pos_x`)){
				setGroupPos({
					pos_x: gFields[0].field_pos_x,
					pos_y: gFields[0].field_pos_y,
				})
			}
			if(props.objExists(gFields[0], `fonts.field`)) {
				setGroupFont(gFields[0].fonts.field);	
			}
			if(props.objExists(gFields[0], `field_id`)) {
				setGroupFieldID(gFields[0].field_id);
			}
		
		}
	}, [field]);
	
	useEffect(() => {
		//console.log("here is where we check for reset", reset);
		if(reset && fieldCtl) {
			//console.log("here is where we received the rest", reset, fieldCtl, props.field);	
		}
	}, [reset, fieldCtl]);
	
	useEffect(() => {
		if(props.objExists(field, `fonts.field.font_size`) &&
			field.fonts.field.font_size !== fieldFontSize) {
			setFieldFontSize(field.fonts.field.font_size);
		}
		
		if(props.objExists(field, `fonts.field.font_color`)) {
			const fClr = props.setRGBArray(field.fonts.field.font_color);
			setFieldFontColour(fClr);
		}
		
		
		if(props.objExists(field, `field_type`)) {
			
			switch(field.field_type) {
				case 'textarea':
					setPlaceholderInput(
						<TextArea {...props}
							name="field_placeholder"
							value={field.field_placeholder}
							label="Placeholder"
							placeholder="Placeholder Text"
							onChange={1 === 1 ? updateField : ''} />
					)
					break;
				default:
					setPlaceholderInput(
						<Input {...props}
							type="text"
							name="field_placeholder"
							value={field.field_placeholder}
							label="Placeholder"
							placeholder="Placeholder Text"
							onChange={1 === 1 ? updateField : ''} />
					);
					break;
			}	
		}
			
		
	}, [field]);
	
	
	
	useEffect(() => {
		if(props.fonts && props.fonts !== fonts) {
			setFonts(props.fonts);
		}
	}, [props.fonts]);
	
	useEffect(() => {
		if(Array.isArray(fonts)) {
			const optFonts = 
				fonts.map(font => {
					return (
						{option_name: font.font_name,
						option_value: font.font_name
						}
					)
				});
			if(optFonts !== optionsFonts) {
				setOptionsFonts(optFonts);
			}
		}
	}, [fonts]);
	 
	// FUNCTIONS
	function resetField(closeCtl = false) {
		props.adminSetValue(`reload.product`, true);
	}
	
	
	function updateField(params, other,) {
		//console.log("Select Changed", params);
		if(!props.objExists(params, 'id')) {
			params = {...params, id:field.field_id};	
		}
		
		if(params.value === false) {
			params = {...params, value: 0}
		}else if(params.value === true) {
			params = {...params, value: 1}
		}
		
		if(params.name === 'field_font_size' &&
			fieldFontSize !== parseFloat(params.value)) {
			setFieldFontSize(parseFloat(params.value));
		}
		props.actionUpdate &&
		props.actionUpdate(params);
	}
	
	function loadConfirmation(type) {
		props.actionConfirm &&
		props.actionConfirm(type);
	}
	
	function updateColour(name, format, value) {
		if(format === 'rgb' && fieldFontColour !== value) {
			updateField(
				{name: name,
				value: `[${value.r}, ${value.g}, ${value.b}]`,
				id: field.field_id}
			);
			setFieldFontColour(value);
		}
	}
	
	function loadFieldAppendage(type, value = null) {
		props.setModal(
			<FormAddEditFieldAppendage {...props}
				type={type}
				value={value}
				actionCancel={()=>{props.setModal(null)}}
				actionUpdate={updateFieldAppendage} />
		);
	}
	
	function updateFieldAppendage(params) {
		props.setModal(null);
		//console.log("UPDATE APPENDAGE", params[1], field.field_id);
		updateField({
			name: params[1].name,
			value: params[1].value,
			id: field.field_id
		});
	}
	
	return (
		field &&
		<Fragment>
			<div className="shell-field-ctl" draggable={false} movable="true">
				<div className="intro">
				{field &&
					<h3>{props.objExists(field, `group.group_name`) ? `${props.capitalizeString(field.group.group_name)} Group: ` : ''}{field.field_label ? 
							props.capitalizeString(field.field_label, true) :
							field.field_placeholder ?
								props.capitalizeString(field.field_placeholder, true) :
									'Field'}</h3>
				}
					<div className="subtext">
						<b>NOTE:</b> Changes must be saved to take effect.
					</div>
				</div>
				{!field.field_prefix &&
					<div style={{
						display: 'flex',
						flexWrap: 'wrap',
						alignItems: 'flex-start',
						marginBottom: !field.field_suffix ? '1em' : '0em',
						borderRadius: '0em',
						borderBottom: !field.field_suffix ? '.125em dotted #333333' : 'none'
					}}>
						<Control icon="add" 
								label="Add a Prefix" 
								showLabel={true}
								action={()=>{loadFieldAppendage('prefix')}} />
						<div className="subtext info"
							style={{
								flex: '1 0 5em',
								marginTop: '0em',
							}}>
							<IconSVG icon="point-left" className="p info" />
							Text displayed BEFORE the field value
						</div>
					</div>
				}
				{!field.field_suffix &&
					<div style={{
						display: 'flex',
						flexWrap: 'wrap',
						alignItems: 'flex-start'
					}}>
						<Control icon="add" 
								label="Add a Suffix" 
								showLabel={true}
								action={()=>{loadFieldAppendage('suffix')}} />
						<div className="subtext info"
							style={{
								flex: '1 0 5em',
								marginTop: '0em',
							}}>
							<IconSVG icon="point-left" className="p info" />
							Text displayed AFTER the field value
						</div>
					</div>
				}
				
				{field &&
				<Fragment>
					<div className="block-border">
						<h4>Field Order</h4>
						<div className="form-row">
							<InputNumberCtl 
								{...props}
								label="Order"
								name="field_order"
								value={props.objExists(field, `field_order`) ?
										field.field_order: 0}
								reset={reset}
								id={groupFieldID ? groupFieldID : field.field_id}
								onChange={updateField}
								float={false}
								factor={1}
								orientation="horizontal" />
						</div>
					</div>
					<div className="block-border">
						<h4>{`${groupFieldID ? 'Group ' : ''}Position`}</h4>
						{!field.group_id &&
							<div className="form-row">
								<InputNumberCtl 
									{...props}
									label="X"
									name="field_pos_x"
									value={props.objExists(groupPos, `pos_x`) ?
											groupPos.pos_x :
												field.field_pos_x}
									reset={reset}
									id={groupFieldID ? groupFieldID : field.field_id}
									onChange={updateField}
									float={4}
									factor={.0033}
									orientation="vertical" />
								<InputNumberCtl 
									{...props}
									label="Y"
									name="field_pos_y"
									value={props.objExists(groupPos, `pos_y`) ?
											groupPos.pos_y :
												field.field_pos_y}
									reset={reset}
									id={field.field_id}
									onChange={updateField}
									float={4}
									factor={.0033}
									orientation="horizontal" />
							</div>
						}
						
						<div className="form-row">
							<InputNumberCtl 
								{...props}
								label="Width"
								name="field_width"
								value={props.objExists(groupPos, `width`) ?
										groupPos.width :
											field.field_width}
								reset={reset}
								id={groupFieldID ? groupFieldID : field.field_id}
								onChange={updateField}
								float={4}
								factor={.0625}
								orientation="vertical" />
							<InputNumberCtl 
								{...props}
								label="Height"
								name="field_height"
								value={props.objExists(groupPos, `height`) ?
										groupPos.height :
											field.field_height}
								reset={reset}
								id={field.field_id}
								onChange={updateField}
								float={4}
								factor={.0625}
								orientation="horizontal" />
						</div>
					</div>
					
					<div className="block-border">
						<h4>Contents</h4>
						{placeholderInput &&
							<div className="form-row">
								{placeholderInput}
							</div>
						}
						
						<div className="form-row">
							<Switch {...props} 
								name="field_space_before"
								label="Space Before"
								value={field.field_space_before ? true : false}
								onChange={updateField}
								/>
						</div>
						<div className="form-row">
							<Switch {...props} 
								name="field_space_after"
								label="Space After"
								value={field.field_space_after ? true : false}
								onChange={updateField}
								/>
						</div>
					</div>
					
					<div className="block-border">
						<h4>Font</h4>
						{optionsFonts &&
						<div className="form-row">
							<Select {...props}
								name="fonts.field.font_name"
								defaultValue={props.objExists(field, 'fonts.field.font_name') ?
												field.fonts.field.font_name : ''}
								label="Font Face"
								option_groups={[
									{options: optionsFonts}
								]}
								onChange={updateField}
								returnObject={true} />
						</div>
						}
						<div className="form-row">
							<Select {...props}
								name="field_align"
								defaultValue={props.objExists(field, 'field_align') ?
												field.field_align : ''}
								label="Alignment"
								option_groups={[
									{options: [
										{option_name: 'Left', option_value: 'L'},
										{option_name: 'Right', option_value: 'R'},
										{option_name: 'Centre', option_value: 'C'},
									]}
								]}
								onChange={updateField}
								returnObject={true} />
							<Select {...props}
								name="field_valign"
								defaultValue={props.objExists(field, 'field_valign') ?
												field.field_valign : ''}
								label="Vertical Alignment"
								option_groups={[
									{options: [
										{option_name: 'Top', option_value: 'T'},
										{option_name: 'Middle', option_value: 'M'},
										{option_name: 'Bottom', option_value: 'B'},
									]}
								]}
								onChange={updateField}
								returnObject={true} />
						</div>
						<div className="form-row">
							<InputNumberCtl 
								{...props}
								label="Font Size"
								name="fonts.field.font_size"
								value={props.objExists(field, `fonts.field.font_size`) ?
										field.fonts.field.font_size : 
											props.objExists(groupFont, `font_size`) ?
												groupFont.font_size : 0
								}
								reset={reset}
								id={groupFieldID ? groupFieldID : field.field_id}
								onChange={updateField}
								float={2}
								factor={.1}
								orientation="vertical" />
							<Select {...props}
								name="field_case"
								label="Capitalization"
								defaultValue={props.objExists(field, `field_case`) ?
										field.field_case : ''}
								option_groups={[{options: [
									{option_name: 'unchanged', option_value: ''},
									{option_name: 'UPPERCASE', option_value: 'uc'},
									{option_name: 'lowercase', option_value: 'lc'},
									{option_name: 'Title Case', option_value: 'tc'}
								]}]}
								onChange={updateField}
								returnObject={true} />
						</div>
					</div>
					
					<div className="block-border">
						<h4>Colour</h4>
						<div className="flex">
							<div style={{flex: '1 0 10em',
										borderRadius: '0em', 
										paddingRight: '1em',
										marginRight: '1em',
										borderRight: '.125em solid #333333'}}>
								<ColourSelector {...props}
									name = 'fonts.field.font_color'
									label="Font Colour"
									value={fieldFontColour}
									actionOnChange={updateColour}
									/>
							</div>
							<div style={{flex: '1 0 5em',
										padding: '1em'}}>
								{fieldFontColour &&
									<div style={{
										width: '5em',
										height: '5em',
										border: '.125em solid #333333',
										backgroundColor: `rgb(${fieldFontColour.r}, ${fieldFontColour.g}, ${fieldFontColour.b})`
									}}>
									</div>
								}
							</div>
						</div>
					</div>
				
					{field.field_prefix &&
					<ShellFieldCtlAppendage {...props}
						fieldID={field.field_id}
						type="prefix"
						contents={field.field_prefix}
						font={props.objGetValue(field, `fonts.prefix`)}
						fieldFont={props.objGetValue(field, `fonts.field`)}
						optionsFonts={optionsFonts}
						actionUpdate={1 === 1 ? updateField : ''} />
					}
					{field.field_suffix &&
					<ShellFieldCtlAppendage {...props}
						fieldID={field.field_id}
						type="suffix"
						contents={field.field_suffix}
						font={props.objGetValue(field, `fonts.suffix`)}
						fieldFont={props.objGetValue(field, `fonts.field`)}
						optionsFonts={optionsFonts}
						actionUpdate={1 === 1 ? updateField : ''} />
					}
					
				</Fragment>
				}
				<div className="ctl">
					<IconToken icon="refresh" tooltip="Reset" action={()=>{loadConfirmation('reset')}} />
					<IconToken icon="close" tooltip="Cancel" action={()=>{loadConfirmation('cancel')}} />
					<IconToken icon="save" tooltip="Save" action={()=>{loadConfirmation('update')}} />
				</div>
			</div>
		</Fragment>
	)
}