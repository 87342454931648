import React, {useEffect, useState, useRef} from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import IconSVG from 'components/icons/IconSVG';

export default (props) => {
  const [disabled, setDisabled] = useState(false);
  const [btnActive, setBtnActive] = useState(false);
  
  // HOOKS
  useEffect(() => {
    if(props.disabled === true) {
      setDisabled(true);
    }else{
      setDisabled(false);
    }
  }, [props.disabled]);
  
  useEffect(() => {
    if(btnActive && props.btnAction && !disabled) {
      props.btnAction(btnActive.target, btnActive);
      setBtnActive(null);
    }else if(btnActive && disabled) {
      setBtnActive(null);
    }
  }, [btnActive, disabled, props.btnAction]);
  
  // FUNCTIONS
  function handleClick(e) {
    e.persist()
    setBtnActive(e);
  }

  // REMOVED THE FOLLOWING FROM <button...
  // ref={props.btnRef ? props.btnRef : null} - 05/12/2021
  return (
      <button onClick={handleClick} 
              type={props.type ? props.type : 'button'}
              form={props.form? props.form : null}
              disabled={disabled || btnActive ? true : false}
              className={`${props.btnClass ? `${props.btnClass}` : ''}${disabled ? ' btn-disabled' : ''}${btnActive ? ' btn-click' : ''}`}
              value={props.value ? props.value : null}
              tabIndex={disabled ? -1 : props.tabIndex ? props.tabIndex : 50}
              id={props.id ? props.id : null} >
        {props.icon && !props.iconRight && <IconSVG icon={props.icon} className={`icon-left${props.iconClass ? ` ${props.iconClass}` : ''}`} />}
        <div className="button-label">{ReactHtmlParser(props.label)}</div>
        {props.icon && props.iconRight && <IconSVG icon={props.icon} className={`icon-right${props.iconClass ? ` ${props.iconClass}` : ''}`} />}
      </button>
    )
}
