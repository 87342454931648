import React, {useEffect, useState, useRef, Fragment} from 'react';
import axios from 'axios';

import MediaHero from 'components/profiles/media/MediaHero';
import IconToken from 'components/icons/IconToken';
import IconSVG from 'components/icons/IconToken';
import Link from 'components/navigation/Link';
import ProjectOrderItemCtl from 'components/profiles/projects/ProjectOrderItemCtl';
import ProgressMap from 'components/molecules/ProgressMap';
import TaskInline from 'components/profiles/tasks/TaskInline';
import TaskGeneral from 'components/profiles/tasks/TaskGeneral';
import LiveFieldInput from 'components/forms/elements/LiveFieldInput';
import PDFViewer from 'components/pdf/PDFViewer';
import ProjectOrderItemStatus from 'components/profiles/projects/ProjectOrderItemStatus';
import Approval from 'components/profiles/approvals/Approval';
import Badge from 'components/atoms/Badge';

import * as apis from 'methods/api.js';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [coreOrg, setCoreOrg] = useState(null);
  const [coreUser, setCoreUser] = useState(null);
  const [projectStatus, setProjectStatus] = useState(null);
  const [isAdmin, setIsAdmin] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [canEdit, setCanEdit] = useState(null);
  const [isSuper, setIsSuper] = useState(null);
  const [item, setItem] = useState(null);
  const [itemStatus, setItemStatus] = useState(null);
  const [itemApprovals, setItemApprovals] = useState(null);
  const [statusMessages, setStatusMessages] = useState(null);
  const [showMessages, setShowMessages] = useState(null);
  const [active, setActive] = useState(null);
  const [fields, setFields] = useState(null);
  const [fieldNames, setFieldNames] = useState(null);
  const [expand, setExpand] = useState(false);
  const [media, setMedia] = useState(null);
  const [activeTasks, setActiveTasks] = useState(null);
  const [odLink, setODLink] = useState(null);
  const [odPreview, setODPreview] = useState(null);

  const itemRef = useRef();

  // HOOKS
  useEffect(() => {
    props.userID &&
    props.userID !== userID &&
    setUserID(props.userID);
  }, [props.userID]);

  useEffect(() => {
    props.token &&
    props.token !== token &&
    setToken(props.token);
  }, [props.token])

  useEffect(() => {
    props.organizationID &&
    props.organizationID !== organizationID &&
    setOrganizationID(props.organizationID);
  }, [props.organizationID]);

  useEffect(() => {
    props.languageCode	&&
    props.languageCode	!== languageCode &&
    setLanguageCode(props.languageCode);
  }, [props.languageCode	]);
  
  useEffect(() => {
    if(props.coreOrg && 
      props.coreOrg !== coreOrg) {
      setCoreOrg(props.coreOrg);
    }
  }, [props.coreOrg]);
  
  useEffect(() => {
    if(props.coreUser && 
      props.coreUser !== coreUser) {
      setCoreUser(props.coreUser);
    }
  }, [props.coreUser]);
  
  useEffect(() => {
    if(props.projectStatus && 
      props.projectStatus !== projectStatus) {
      setProjectStatus(props.projectStatus);
    }
  }, [props.projectStatus]);
  
  useEffect(() => {
    if(props.isAdmin && !isAdmin) {
      setIsAdmin(true);
    }else if(!props.isAdmin && isAdmin){
      setIsAdmin(false);
    }
  }, [props.isAdmin]);
  
  useEffect(() => {
    props.userAccess &&
    props.userAccess !== userAccess &&
    setUserAccess(props.userAccess);
  }, [props.userAccess]);
  
  useEffect(() => {
    if(isAdmin && userAccess){
      if(userAccess.super || userAccess.admin) {
        setCanEdit(true);
      }else if(props.objGetValue(userAccess, `staffAdmin.${coreOrg}`) === true &&
              ['new', 'fulfilling order', 'approved'].includes(projectStatus)) {
        setCanEdit(true);
      }
    }else if(coreUser === userID && 
      ['new'].includes(projectStatus)) {
      setCanEdit(true);
    }
  }, [isAdmin, userAccess, coreOrg, coreUser, userID, projectStatus]);
  
  useEffect(() => {
    props.isSuper &&
    props.isSuper !== isSuper &&
    setIsSuper(props.isSuper);
  }, [props.isSuper]);
  
  useEffect(() => {
    if(props.item && 
      props.item !== item) {
      setItem(props.item);
    }
  }, [props.item]);
  
  useEffect(() => {
    if(props.objExists(item, `status[0].en.status_name`)) {
      setItemStatus({status: item.status[0].en.status_name, 
                    status_date: item.status[0].status_modified, 
                    status_user: item.status[0].user_id,
                    status_comment: item.status[0].comment});
    }
    
    if(Array.isArray(props.objGetValue(item, `approvals`)) &&
      item.approvals.length > 0) {
      setItemApprovals(item.approvals);
    }
    
    if(Array.isArray(props.objGetValue(item, `messages`)) &&
      item.messages.length > 0 &&
      item.messages !== statusMessages) {
      setStatusMessages(item.messages);
    }
  }, [item]);
  
  useEffect(() => {
    if(userID && token && props.objGetValue(item, `details.${languageCode}.shell.shell_published`) === 1) {
      const odl = `https://api.aecore.app/files/create_pdf.php?query=${btoa(`?process=output_shell_pdf&user_id=${userID}&token=${token}&item_id=${item.item_id ? item.item_id : 0}&box_model=BleedBox&target=D&order_id=${props.orderID ? props.orderID  : 0}&project_id=${props.projectID ? props.projectID  : 0}&preview_mode=false`)}`;
      
      if(isAdmin && odl !== odLink) {
        setODLink(odl);
      }
      
      const odp = `https://api.aecore.app/files/create_pdf.php?query=${btoa(`?process=output_shell_pdf&user_id=${userID}&token=${token}&item_id=${item.item_id ? item.item_id : 0}&box_model=TrimBox&target=I&order_id=${props.orderID ? props.orderID  : 0}&project_id=${props.projectID ? props.projectID  : 0}&preview_mode=false`)}`;
      
      if(odp !== odPreview) {
        setODPreview(odp);
      }
    }
  }, [userID, token, item, languageCode, isAdmin]);
  
  useEffect(() => {
    if(Array.isArray(props.objGetValue(item, 'fields')) &&
        item.fields.length > 0 &&
        item.fields !== fields) {
        setFields(item.fields);
    }
  }, [item]);

useEffect(() => {
    if(languageCode) {
      let fields = {};
      props.objExists(props.item, `details.${languageCode}.fieldsets`) &&
      Array.isArray(props.item.details[languageCode].fieldsets) &&
      props.item.details[languageCode].fieldsets.length > 0 &&
      props.item.details[languageCode].fieldsets.map(fieldset => {
        fieldset.fields && Array.isArray(fieldset.fields) &&
        fieldset.fields.map(field => {
          fields[field.field_id] = field.field_label;
        })
      })
      if(Object.entries(fields).length > 0) {
        setFieldNames(fields);
      }

      if(props.objExists(props.item, `details.${languageCode}.media`) &&
          Array.isArray(props.item.details[languageCode].media) &&
          props.item.details[languageCode].media.length > 0) {
            if(props.item.details[languageCode].media.find(med => med.media_default === 1)) {
              setMedia(props.item.details[languageCode].media.find(med => med.media_default === 1));
            }else{
              setMedia(props.item.details[languageCode].media[0]);
            }
      }
    }
  }, [languageCode]);
  
  useEffect(() => {
    if(languageCode, props.tasks && 
        Array.isArray(props.tasks) && 
        props.tasks.length > 0) {
      const aTasks = props.getActiveTasks(props.tasks, languageCode, true, true);
      if(aTasks) {
        setActiveTasks(aTasks);
      }
    }
  }, [props.tasks, languageCode]);

  // FUNCTIONS
  function expandItem() {
    if(itemRef.current.classList.contains('active')) {
      itemRef.current.classList.remove('active');
      setExpand(false);
    }else{
      itemRef.current.classList.add('active');
      setExpand(true);
    }
  }
  
  function updateItemPageCount(name, value, params) {
    let formData = `process=get_links&link_to=${props.item.item_id}&link_type=pageCountToItem`;
    axios.post(apis.getReqUrl('links'), formData).then(res => {
      if (res.data.length > 0 && value >= 0) {
        const updateParams = [
          {name: 'process', value: 'update_link'},
          {name: 'user_id', value: userID},
          {name: 'link_id', value: res.data[0].link_id},
          {name: 'link_from', value: value},
          {name: 'link_removed', value: value == 0 ? true : false},
        ];
        props[`${props.location ? props.location : 'admin'}LoadState`](updateParams, 'links');
      } else if (value >= 0) {
        const addParams = [
          {name: 'process', value: 'add_link'},
          {name: 'link_from', value: value},
          {name: 'link_to', value: props.item.item_id},
          {name: 'link_type', value: 'pageCountToItem'},
          {name: 'user_id', value: userID},
        ];
        props[`${props.location ? props.location : 'admin'}LoadState`](addParams, 'links');
      }
      const updateFinancialsParams = [
        {name: 'process', value: 'update_project_financials'},
        {name: 'project_id', value: props.projectID},
        {name: 'user_id', value: userID},
      ];
      props[`${props.location ? props.location : 'admin'}LoadState`](updateFinancialsParams, 'orders', 'reload.project');
    }).catch(error => {
      console.log("INIT SITE ERROR:", error);
    })
  }
  
  function updateItemQuantity(name, value, params) {
    const paramsItemQty = [
      {name: 'process', value: 'update_order_item_quantity'},
      ...params,
      {name: 'token', value: token},
      {name: 'item_quantity', value: value}
    ];
    props[`${props.location ? props.location : 'admin'}LoadState`](paramsItemQty, 'orders', 'reload.project');
  }
  
  function updateItemPrice(name, value, params) {
    const paramsItemPrice = [
      {name: 'process', value: 'update_value'},
      ...params,
      {name: 'field_name', value: 'item_price'},
      {name: 'field_value', value: value}
    ];
    console.log("paramsItemPrice", paramsItemPrice);
    props[`${props.location ? props.location : 'admin'}LoadState`](paramsItemPrice, 'commonTasks', 'reload.project');
  }
  
  function previewShell() {
    props.setModal(
      
        <PDFViewer {...props}
          file={odPreview}
          stealthCtl={true} />
      
    , false, true);
  }
  
  return (
    item &&
    <div className={`card-item${active ? ' active' : ''}`} ref={itemRef} >
    <div className="card-columns justify-top">
      <div className="card-block auto-width justify-top">
        {media &&
          <MediaHero {...props} 
                      mediaFileName={media.media_file_name}
                      mediaCaption={media.media_caption}
                      className="list-item-media"
                      isAdmin={false} />
        }
      </div>
      <div className="card-block">
        <div className="field">
          <div className="field-value">
            <b>{languageCode && props.objExists(props.item, `details.${languageCode}.product_name`) &&
                props.capitalizeString(props.item.details[languageCode].product_name, true)}</b>
                {props.objExists(props.item, `product_nickname`) ? ` (${props.item.product_nickname})` : ''}
          </div>
        </div>
        {itemApprovals &&
          <div style={{padding: '1em 0em'}}> 
            <div className="field" style={{alignItems: 'center'}}>
              <Approval {...props} approvals={itemApprovals} languageCode={languageCode} />
            </div>
            <div className="field">
              <div onClick={previewShell} href={odLink} class="link-btn"
                  style={{flex: '0 1 15em'}}>
                <IconSVG icon="view-file" />
                Preview Print File
              </div>
              {(props.objGetValue(itemStatus, `status`) === 'approved') && odLink &&
              <a href={odLink} target="_blank" class="link-btn"
                style={{flex: '0 1 15em'}}>
                <IconSVG icon="download-file" />
                Download Print File
              </a>
              }
            </div>
          </div>
        }
        {languageCode && 
        props.objExists(props.item, `details.${languageCode}.product_sku`) &&
          <div className="field">
            <label>SKU</label>
            <div className="field-value">
              {props.item.details[languageCode].product_sku}
            </div>
          </div>
        }
        
        {languageCode && props.objExists(props.item, `product_page_count`) &&
         <div className="field">
           <LiveFieldInput {...props}
             type="number"
             name="item_page_count"
             label="Page Count"
             value={item.product_page_count}
             action={updateItemPageCount}
             disabled={canEdit ? false : true} />
         </div>
        }
        
        <div className="field">
          <LiveFieldInput {...props}
            type="number"
            name="item_quantity"
            label="Qty"
            value={item.product_quantity}
            optionalParams={[
              {name: 'item_id', value: item.item_id},
              {name: 'location_id', value: props.locationID},
              {name: 'order_id', value: props.orderID},
              {name: 'user_id', value: userID} 
            ]}
            action={updateItemQuantity}
            disabled={canEdit && props.locationID ? false : true} />
        </div>
        
        <div className="field">
          {!props.hidePricing && 
            <LiveFieldInput {...props}
              type="decimal"
              name="item_price"
              label="Price"
              value={item.product_price}
              optionalParams={[
                {name: 'field_id', value: item.item_id},
                {name: 'update_id', value: userID} 
              ]}
              action={updateItemPrice}
              disabled={canEdit && props.locationID ? false : true} /> 
          }
        </div>
        
      </div>
      <div className="card-block auto-width justify-top justify-right no-print">
        {props.tasks && 
          Array.isArray(props.tasks) &&
          props.tasks.length > 0 &&
          props.tasks.map((task, index) => {
            return(
              <TaskGeneral {...props}
                className={active ? null : 'minified'}
                userID={userID}
                token={token}
                userAccess={userAccess}
                task={task} />
            ) 
          })
        }
      </div>
    </div>
    
    {((fields && fields.length > 0) || Array.isArray(props.item.products)) &&
      <Fragment>
      <div className="card-columns optional">
        {fields &&
        fields.length > 0 &&
          <div className="card-block border">
              <h4>Custom Information</h4>
              {fields && 
              fields.map((field, index) => {
                return (
                    <div key={`cusfield${index}`} className="field" style={{minHeight: '1em'}}>
                      <label>{props.capitalizeString(field.field_label, true)}</label>
                      <div className="field-value">
                        {field.field_type === 'file' ?
                          props.objExists(field, 'file.media_file_upload_name') ?
                            <a href={`/assets/media/${field.file.media_file_name}`} target="_blank">{field.file.media_file_upload_name}</a>
                          : `File ID: ${field.field_value}` 
                        :
                          field.field_value === true ? 'Yes' : field.field_value === 'true' ? 'Yes' : field.field_value === false ? 'No' : field.field_value === 'false' ? 'No' : field.field_value
                        }
                      </div>
                    </div>
                )})
              }
          </div>
        }
        
        {languageCode && Array.isArray(props.item.products) && props.item.products.length > 0 &&
          <div className="card-block border">
            <h4>Included Items</h4>
            {props.item.products.map((product, index) => {
                return (
                  <div key={`item${index}`} className="field">
                    <div className="field-value">
                      {props.capitalizeString(product.details[languageCode].product_name, true)}
                      {product.details[languageCode].product_sku && " [" + product.details[languageCode].product_sku + "]"}
                      {product.details[languageCode].product_quantity && ", Quantity: " + product.details[languageCode].product_quantity}
                    </div>
                  </div>
                )
              })
            }
          </div>
        }
        
      </div>
    
      <div className="card-ctl bottom">
        {!active && <div>Additional Information...</div>}
        <IconToken icon="arrow" className={`no-print${active  ? '' : ' rotate180'}`}  action={()=>{setActive(active ? false : true)}} />
      </div>
    </Fragment>
    }
    </div>
  )
}
