import React, {useEffect, useState} from 'react';

import IconFile from 'components/icons/IconFile';
import IconToken from 'components/icons/IconToken';
import Loader from 'components/atoms/Loader';

export default (props) => {
  const [file, setFile] = useState(null);
  const [src, setSrc] = useState(null);
  const [icon, setIcon] = useState(null);
  
  useEffect(() => {
    if(props.file && props.file !== file) {
      setFile(props.file);
    }
  }, [props.file]);
  
  useEffect(() => {
    // NOT COMPLETE - MAY NOT BE NEEDED
    if(props.src && props.src !== src) {
      setSrc(props.src);
    }else if(!props.src && file) {
      const fileSrc = ``;
    }
  }, [props.src, file]);
  
  
  function handleRemove(e) {
    if(props.actionRemove && (props.index || String(props.index) === '0') && props.location) {
      props.actionRemove(props.index, props.location);
    }
  }

  function handleEdit() {
    if(props.actionEdit && (props.index || String(props.index) === '0') && props.location) {
      props.actionEdit(props.index, props.location, props.files);
    }
  }
  // TODO add file controls (remove, edit...);
  return (
    <div className="file-upload-preview">
      {props.src && <img src={props.src} />}
      {props.icon && <IconFile icon={props.icon} />}
      <div className="file-upload-preview-label">
        {props.file.media_caption ? props.file.media_caption :
          props.file.media_file_upload_name ? props.file.media_file_upload_name :
          props.file.name ? props.file.name : 'Selected File'}
      </div>
      {(props.actionRemove || props.actionEdit) &&
        <div className="file-upload-preview-ctl">
          {props.actionEdit && <IconToken icon="edit" className="info-bg" action={handleEdit} />}
          {props.actionRemove && <IconToken icon="add" className="rotate45 error-bg" action={handleRemove} />}
        </div>
      }
      <Loader />
    </div>
  )
}
