import React, {useEffect, useState, Fragment} from 'react';

import FormDynamic from 'components/forms/FormDynamic';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [groups, setGroups] = useState(null);
	const [groupType, setGroupType] = useState(null);
	const [groupLink, setGroupLink] = useState(null);
	const [fieldsets, setFieldsets] = useState(null);
	const [form, setForm] = useState(null);

	// HOOKS
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.groupType && props.groupType !== groupType) {
			setGroupType(props.groupType);
		}
	}, [props.groupType]);
	
	useEffect(() => {
		if(props.groupLink && props.groupLink !== groupLink) {
			setGroupLink(props.groupLink);
		}
	}, [props.groupLink]);
	
	useEffect(() => {
		if(props.langaugeCode && 
			props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}else if(!languageCode){
			setLanguageCode('en');
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.groups && 
			Array.isArray(props.groups) && 
			props.groups.length > 0  && 
			props.groups !== groups) {
			setGroups(props.groups);
		}
	}, [props.groups]);
	
	useEffect(() => {
		if(userID && token && languageCode && groupType && groups) {
			console.log("ASSING GROUPS", groups);
			setFieldsets([
				{fieldset_name: `${props.objExists(props, 'group.group_id') ? 'Update' : 'Add'} Group`,
					fields: [
						{field_type: 'select',
						field_name: props.groupFrom ? 'link_from' : 'link_to',
						field_label: 'Group',
						field_placeholder: 'Select group',
						field_value: null,
						field_required: false,
						field_validate: false,
						option_groups: [
							{options: 
								groups && Array.isArray(groups) &&
								groups.map(group => {
									return {option_name: group.group_name ? 
															group.group_name : 
															props.objExists(group, `${languageCode}.group_name`) ?
																group[languageCode].group_name : 'missing',
											option_value: group.group_id}
								})}
						],
						field_order: 2,
						},
						{field_type: 'hidden',
						field_name: 'process',
						field_value: 'add_link',
						},
						{field_type: 'hidden',
						field_name: 'link_type',
						field_value: groupType,
						},
						{field_type: 'hidden',
						field_name: props.groupFrom ? 'link_to' : 'link_from',
						field_value: groupLink,
						},
						{field_type: 'hidden',
						field_name: 'user_id',
						field_value: userID,
						},
						{field_type: 'hidden',
						field_name: 'token',
						field_value: token,
						},
						{field_type: 'hidden',
						field_name: 'return_bool',
						field_value: 'true'
						}
				]}]);
		}else if(fieldsets) {
			setFieldsets(null);
		}
	}, [userID, token, languageCode, groupType, groups]);
	
	useEffect(() => {
		if(fieldsets) {
			setForm(
				<FormDynamic {...props}
					content={fieldsets}
					name="add-edit-group"
					label="chubb"
					returnObject={true}
					fieldOnChange={null}
					fieldOnLoad={null}
					cancelable={props.cancelable === false ? false : true}
					formActions={{formSubmit: handleSubmit, formCancel: handleCancel}}
					formCompleteLabel={'Assign'} />
			);
		}
	}, [fieldsets]);
	
	
	// FUNCTIONS
	function handleSubmit(params) {
		if(props.actionSubmit) {
			props.actionSubmit(params);
		}
	}
	
	function handleCancel() {
		if(props.actionCancel) {
			props.actionCancel();
		}else{
			props.setModal(null);
		}
	}
	
	return form
}