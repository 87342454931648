import React, {useEffect, useState} from 'react';

import FormDynamic from 'components/forms/FormDynamic';
import * as forms from 'methods/forms';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [orgLocationFields, setOrgLocationFields] = useState(null);
  const [orgLocationForm, setOrgLocationForm] = useState(null);
  const [orgIndex, setOrgIndex] = useState(0);
  const [siteLanguage, setSiteLanguage] = useState(null);

  useEffect(() => {
    if(props.userID) {
      setUserID(props.userID)
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token) {
      setToken(props.token)
    }
  }, [props.token]);

  useEffect(() => {
    if(props.organizationID && organizationID !== props.organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    props.organizationName &&
    props.organizationName !== organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);
  
  useEffect(() => {
    if(props.languageCode && languageCode !== props.languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    if(props.userAccess && props.userAccess !== userAccess) {
      setUserAccess(props.userAccess);
    } 
  }, [props.userAccess]);

  useEffect(() => {
    props.siteLanguage &&
    setSiteLanguage(props.siteLanguage);
  }, [props.siteLanguage]);

  useEffect(() => {
    let LocationFields = null;
    LocationFields = forms.fieldsLocation;
    LocationFields.map((field, index) => {
      if(!field.field_name.includes(`organization.locations[${orgIndex}].`)) {
        LocationFields[index]['field_name'] = `organization.locations[${orgIndex}].${field.field_name}`;
      }
      LocationFields[index]['field_value'] = props.objGetValue(props.admin, field.field_name) ? props.objGetValue(props.admin, field.field_name) : null;
    });

    setOrgLocationFields(
      [{fieldset_name: `Organization Location ${orgIndex+1}`,
        fields: [...LocationFields,
                  {field_type: 'switch',
                  field_name: `organization.locations[${orgIndex}].location_default`,
                  field_label: 'Default Location',
                  field_value: props.objExists(props.admin, `organization.locations[${orgIndex}].location_default`) ?
                                props.admin.organization.locations[orgIndex].location_default : orgIndex === 0 ? true : false,
                  field_required: false,
                  field_validate: false},
                ]}]
    )
  }, [userID, props.admin.organization]);

  useEffect(() => {
    orgLocationFields &&
    setOrgLocationForm(
      <FormDynamic
        content={orgLocationFields}
        name="orgAddDetails"
        fieldOnChange={fieldOnChange}
        fieldOnLoad={fieldOnLoad}
        closable={false}
        unmountOnClick={false}
        cancelable={false}
        includeControls={false}
        multiStep={false}
        formClass=""
        valActions={{isEmail: props.isEmail}}
      />
    )

  }, [orgLocationFields]);

  function fieldOnChange(e) {
    if(e.value !== props.objGetValue(props.admin, e.name)) {
      props.adminSetVal(e.name, typeof e.value === 'string' ? e.value.trim() : e.value);
    }else{
      // props.adminDeleteVal(e.name);
    }
    return;
  }

  function fieldOnLoad(e) {
    return;
  }

  return (
    <div>
      <p>Add Organization Locations</p>
      {orgLocationForm}
    </div>
  )
}
