import React, {useEffect, useState} from 'react';

import FilterOptions from 'components/forms/elements/FilterOptions';
import FilterOption from 'components/forms/elements/FilterOption';
import IconSVG from 'components/icons/IconSVG';

export default (props) => {
	const [filterOptions, setFilterOptions] = useState(null);
	const [filters, setFilters] = useState(null);
	
	useEffect(() => {
		if(props.filterOptions && props.filterOptions !== filterOptions) {
			setFilterOptions(props.filterOptions);
		}
	}, [props.filterOptions]);
	
	useEffect(() => {
		if(props.filters && props.filters !== filters) {
			setFilters(props.filters);
		}
	}, [props.filters]);
	
	// FUNCTIONS 
	function updateFilters(param) {
		let fltrs = filters ? filters : {};
		if(param.name && param.filter) {
			if(param.value === true) {
				if(Array.isArray(props.objGetValue(fltrs, `${param.filter}`)) &&
					!fltrs[param.filter].includes(param.name)) {
					fltrs[param.filter].push(param.name);
				}else{
					fltrs[param.filter] = [param.name];
				}
			}else if(Array.isArray(props.objGetValue(fltrs, `${param.filter}`)) &&
				fltrs[param.filter].includes(param.name)) {
					if(fltrs[param.filter].length > 1) {
						fltrs[param.filter] = fltrs[param.filter].filter(fValue => fValue !== param.name);
					}else{
						delete fltrs[param.filter];
					}
			}
			if(props.actionUpdateFilters) {
				props.actionUpdateFilters(fltrs);
			}
		}
	}
	
	function selectSort(param) {
		let fltrs = filters ? filters : {};
		fltrs['sort'] = {name: param.name, order: param.value};
		if(props.actionUpdateFilters) {
			props.actionUpdateFilters(fltrs);
		}
	}
	
	return (
		filterOptions &&
		Object.entries(filterOptions).length > 0 &&
		<div className="list-ctl">
			<label>Filter Tasks</label>
			<FilterOptions {...props} 
				options={filterOptions} 
				actionUpdateFilters={updateFilters} />
			<div>
				<label>Sort Tasks</label>
				<div className="flex">
					<FilterOption 
						{...props} 
						filter={'sort'}
						option={{option_name: `Date ${props.objGetValue(filters, `sort.name`) === 'task_created' &&
														props.objGetValue(filters, `sort.order`) === 'asc' ? 
													'Descending' : 'Ascending'}`,
								option_value: 'task_created',
								option_icon: props.objGetValue(filters, `sort.name`) === 'task_created' &&
											props.objGetValue(filters, `sort.order`) === 'asc' ? 
											'sort-desc' : 'sort-asc',
								option_order: props.objGetValue(filters, `sort.name`) === 'task_created' &&
												props.objGetValue(filters, `sort.order`) === 'asc' ?
												'desc' : 'asc'}}
						active={props.objGetValue(filters, `sort.name`) === 'task_created' ? true : false}
						action={selectSort}
					/>
				</div>
			</div>
		</div>
	)
	
}