import React, {useEffect, useState, Fragment} from 'react';

import IconToken from 'components/icons/IconToken';
import IconSVG from 'components/icons/IconSVG';
import Switch from 'components/forms/elements/Switch';
import LiveFieldInput from 'components/forms/elements/LiveFieldInput';

import * as utils from 'methods/site';

export default (props) => {
  props = {...props, ...utils};
  const [options, setOptions] = useState(null);
  const [active, setActive] = useState(null);
  
  useEffect(() => {
    if(props.contentOptions &&
        Array.isArray(props.contentOptions) &&
        props.contentOptions !== options) {
          setOptions(props.contentOptions);
        }
  }, [props.contentOptions]);

  function handleRemove(e) {
    props.actionRemove &&
    props.contentID &&
    props.actionRemove(props.contentID, props.values);
  }
  
  function handleOption(value, params) {
    if(params.action) {
      params.action(value, params);
    }
  }
  return (
    <Fragment>
      <div className={`card-value-tag${props.className ? ` ${props.className}`: ''}${options ? ' options' : ''}${active ? ' active' : ''}`}>
        <div className="card-value-tag-content">
          {props.content}
          {props.icon && 
            <IconSVG icon={props.icon} />
          }
        </div>
        
        {props.showOptions && options && 
          <div className="card-options-toggle">
            <IconToken icon="arrow" className={`no-print${active  ? '' : ' rotate180'}`}  action={()=>{setActive(active ? false : true)}} />
          </div>
        }
        
        {props.editable && props.actionRemove &&
          <div className="card-value-tag-ctl">
            <IconToken icon="close" className="btn" action={handleRemove} />
          </div>
        }
        
        {options && 
          <div className={`card-options${active ? ' active' : ''}`}>
            <div className="card-value-tag-options">
              {options.map((opt, index) => {
                switch(opt.option_type) {
                  case 'live-field-input':
                    return (
                      <div className="card-value-tag-option" key={`cvtopt${index}`}>
                        <LiveFieldInput
                          key={`cvt_prd_qty${props.contentID ? props.content_id : 'xxx'}${index}`}
                          value={opt.option_value}
                          name="product_quantity"
                          label="Qty"
                          style={{maxWidth: '7em'}}
                          optionalParams={[{name: 'product_category_link_id', value: opt.option_category_link_id},
                                           {name: 'product_quantity_link_id', value: opt.option_quantity_link_id}]}
                          action={opt.option_action}
                          disabled={props.editable ? false : true} />
                      </div>
                    )
                  case 'switch':
                    return (
                      <div className="card-value-tag-option" key={`cvtopt${index}`}>
                        {props.editable ?
                          <Switch 
                            key={`cvt_opt${props.contentID ? props.content_id : 'xxx'}${index}`}
                            name={opt.option_name.toLowerCase().replace(' ', '_')}
                            label={props.capitalizeString(opt.option_name, true)}
                            value={opt.option_value}
                            params={{
                              name: opt.option_name.toLowerCase().replace(' ', '_'),
                              content_id: props.contentID,
                              action: opt.option_action
                            }}
                            action={handleOption} />
                        :
                          <Fragment>
                            {opt.option_value === 1 ? 'Optional' : 'Required'}
                          </Fragment>
                        }
                      </div>
                    )
                }
              })}
            </div>
          </div>
        }
      </div>
    </Fragment>
  )
}
