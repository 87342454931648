import React from 'react';

import IconToken from 'components/icons/IconToken';
import FormPricingDiscountAddEdit from 'components/forms/common_forms/FormPricingDiscountAddEdit';
import ModalConfirmation from 'components/molecules/ModalConfirmation';

export default (props) => {

  // FUNCTIONS
  function loadEdit() {
    props.setModal(
      <FormPricingDiscountAddEdit
        {...props}
        discount={props.discount}
        pricing_id={props.pricing_id}
        actionAddEdit={handleAddEdit}
        actionCancel={()=>{props.setModal(null)}} />
    )
  }

  function loadRemove() {
    props.setModal(
      <ModalConfirmation
        {...props}
        message={`Are you sure you want to remove the <b>${props.discount.discount_percentage}% discount</b> beginning at a quantity of ${props.discount.discount_quantity}?`}
        actionAccept={handleRemove}/>
    )
  }

  function handleAddEdit(params) {
    if(props.actionAddEdit) {
      props.actionAddEdit(params);
    }
  }

  function handleRemove() {
    props.setModal(null);
    if(props.actionRemove){
      props.actionRemove([
        {name: 'process', value: 'update_link_no_id'},
        {name: 'link_type', value: 'discountToPricing'},
        {name: 'link_from', value: props.discount.discount_id},
        {name: 'link_to', value: props.pricing_id},
        {name: 'link_field', value: 'link_removed'},
        {name: 'link_value', value: true}
    ]);
    }
  }

  return (
    <div className="product-pricing pricing-discount">
      <div className="product-pricing-row">
        <label>Starting<br />Quantity</label>
        <div className="product-pricing-value">
          {props.discount.discount_quantity}
        </div>
      </div>
      <div className="product-pricing-row">
        <label>Discount<br />Percentage</label>
        <div className="product-pricing-value">
          {props.discount.discount_percentage}%
        </div>
      </div>
      <div className="product-pricing-row">
        <label>Quanity<br />Alert Range</label>
        <div className="product-pricing-value">
          {`${props.discount.discount_proximity} - ${parseInt(props.discount.discount_quantity)-1}`}
        </div>
      </div>
      <IconToken icon="edit" className="btn" action={loadEdit} tooltip="Edit Discount" />
      <IconToken icon="trash" className="btn" action={loadRemove} tooltip="Remove Discount"/>
    </div>
  )
}
