import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import IconToken from 'components/icons/IconToken';
import Button from 'components/forms/elements/Button';

export default (props) => {
  function cancelModal() {
    props.actionCancel ?
    props.actionCancel() :
    props.setModal(null);
  }

  return (
    <div className="modal-dialogue">
      <div className="modal-dialogue-content">
        {props.icon &&
          <div className="modal-dialogue-icon">
            <IconToken icon={props.icon} />
          </div>
        }
        <div className="modal-dialogue-message">
          {ReactHtmlParser(props.message)}
        </div>
      </div>
      <div className="form-row">
        <Button type="button"
                label={props.labelDecline ? props.labelDecline : 'cancel'}
                value={props.valueDecline ? props.valueDecline : null}
                btnClass="sml" icon={props.iconDecline ? props.iconDecline : 'close'}
                btnAction={props.actionDecline ? props.actionDecline : cancelModal} />
        <Button type="button"
                label={props.labelAccept ? props.labelAccept : 'proceed'}
                value={props.valueAccept ? props.valueAccept : null}
                btnClass="sml success-bg"
                icon={props.iconAccept ? props.iconAccept : 'check'}
                btnAction={props.actionAccept} />
      </div>
    </div>
  )
}
