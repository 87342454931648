import React, {useEffect, useState, Fragment} from 'react';
import {Redirect} from 'react-router-dom';

import Loader from 'components/atoms/Loader';
import CategoryList from 'components/profiles/categories/CategoryList';
import FormCategoryAddEdit from 'components/forms/common_forms/FormCategoryAddEdit';
import Category from 'components/profiles/categories/Category';
import OrganizationSelector from 'components/profiles/orgs/OrganizationSelector';
import ModalConfirmation from 'components/molecules/ModalConfirmation';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [organizationName, setOrganizationName] = useState(null);
	const [organizations, setOrganizations] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [userAccess, setUserAccess] = useState(null);
	const [canEdit, setCanEdit] = useState(null);
	const [isSuper, setIsSuper] = useState(null);
	const [coreOrg, setCoreOrg] = useState(null);
	const [coreOrgName, setCoreOrgName] = useState(null);
	const [loadingCats, setLoadingCats] = useState(null);
	const [categories, setCategories] = useState(null);
	const [redirect, setRedirect] = useState(null);
	
	// HOOKS
	useEffect(() => {
		return() => {
			// props.adminDeleteVal('categories');
		} 
	}, [])
	
	// HOOKS
	  useEffect(() => {
		if(props.userID && props.userID !== userID) {
		  setUserID(props.userID)
		}
	  }, [props.userID]);
	  
	  useEffect(() => {
		if(props.token && props.token !== token) {
		  setToken(props.token)
		}
	  }, [props.token]);
	
	  useEffect(() => {
		if(props.organizationID && organizationID !== props.organizationID) {
		  setOrganizationID(props.organizationID);
		}
	  }, [props.organizationID]);
	  
	  useEffect(() => {
		props.organizationName &&
		props.organizationName !== organizationName &&
		setOrganizationName(props.organizationName);
	  }, [props.organizationName]);
	
	useEffect(() => {
	  props.organizations &&
	  props.organizations !== organizations &&
	  setOrganizations(props.organizations);
	}, [props.organizations]);
	  
	  useEffect(() => {
		if(props.languageCode && languageCode !== props.languageCode) {
		  setLanguageCode(props.languageCode);
		}
	  }, [props.languageCode]);
	  
	  useEffect(() => {
		if(props.userAccess && props.userAccess !== userAccess) {
		  setUserAccess(props.userAccess);
		} 
	  }, [props.userAccess]);
	
	useEffect(() => {
		props.canEdit &&
		props.canEdit !== canEdit &&
		setCanEdit(props.canEdit);
	}, [props.canEdit]);
	  
	  useEffect(() => {
		props.isSuper &&
		props.isSuper !== isSuper &&
		setIsSuper(props.isSuper);
	}, [props.isSuper]);
	
	useEffect(() => {
		if(organizationID > 1){
			setCoreOrg(organizationID)
		}else if(props.coreOrg && props.coreOrg !== coreOrg) {
			setCoreOrg(props.coreOrg);
		}else if(organizationID <= 1 &&
				!props.coreOrg && coreOrg){
			setCoreOrg(null);
		}
	}, [props.coreOrg, organizationID]);
	
	useEffect(() => {
		if(props.objExists(props.admin, `categories.${languageCode}`) && 
			Array.isArray(props.admin.categories[languageCode]) &&
			props.admin.categories[languageCode].length > 0) {
			let cats = props.admin.categories[languageCode];
			if(coreOrg) {
				cats = cats.filter(oCat => oCat.organization_id === coreOrg);
			}
			if(cats !== categories) {
				setCategories(cats);
			}
		}else if(categories && 
				(!props.objExists(props.admin, `categories.${languageCode}`) || 
				!Array.isArray(props.admin.categories[languageCode]) ||
				props.admin.categories[languageCode].length <= 0)) {
			setCategories(null);
		}
	}, [props.admin.categories, coreOrg]);
	
	useEffect(() => {
		if(languageCode && props.objExists(props.admin, `categories.${languageCode}`)) {
			if(loadingCats) {
				setLoadingCats(false);
			}
			if(props.admin.categories[languageCode] !== categories) {
				// setCategories(props.admin.categories[languageCode]);	
			}
		} 
	}, [props.admin.categories, languageCode]);
	
	useEffect(() => {
		if(coreOrg && props.objExists(props.admin, 'organizations') &&
			Array.isArray(props.admin.organizations)) {
				const orgInfo = props.admin.organizations.find(org => org.organization_id === coreOrg);
				if(orgInfo) {
					setCoreOrgName(orgInfo.organization_name);
				}
		}else if(!coreOrg && coreOrgName) {
			setCoreOrgName(null);
		}
	}, [coreOrg, props.admin.organizations]);
	
	useEffect(() => {
		if(props.objGetValue(props.admin, `current.new_category`) === true) {
			setRedirect(`/admin/products/categories`);
			props.adminDeleteVal('current.new_category');
		}
	}, [props.admin.current]);
	
	// FUNCTIONS
	function loadAddEditCategory(category, category_parent) {
		props.setModal(
			<FormCategoryAddEdit
				{...props}
				userID={userID}
				token={token}
				organizationID={organizationID}
				organizations={organizations}
				languageCode={languageCode}
				isAdmin={true}
				isSuper={isSuper}
				canEdit={canEdit}
				category={category}
				categoryParent={category_parent}
				categories={categories}
				categoryType="product"
				coreOrg={coreOrg}
				location={'admin'}
				actionSubmit={addEditCategory}
				returnBool={true}
				actionSelectOrg={props.actionSelectOrg}
				cancelable={true} />
		)
	}
	
	function addEditCategory(params) {
		console.log("ADD EDIT CATEGORY PARAMS", params);
		props.setModal(null);
		props.adminLoadState(params, 'category', 'reload.categories');
	}
	
	function loadRemoveCategory(cat) {
		if(coreOrg && cat) {
			props.setModal(
				<ModalConfirmation {...props}
					message={`<p>Permanently remove <b>${cat.category_name ? props.capitalizeString(cat.category_name, true) : 'this category'}</b> from ${coreOrgName ? coreOrgName : 'this organization'}?</p>`}
					icon="close"
					actionAccept={()=>{removeCategory(cat.category_id, coreOrg)}} />
			);	
		}
	}
	
	function removeCategory(category_id, organization_id) {
		props.setModal(null);
		const paramsRemove = [
			{name: 'process', value: 'update_link_no_id'},
			{name: 'user_id', value: userID},
			{name: 'token', value: token},
			{name: 'link_from', value: category_id},
			{name: 'link_to', value: organization_id},
			{name: 'link_type', value: 'categoryToOrganization'},
			{name: 'link_field', value: 'link_removed'},
			{name: 'link_value', value: 1}
		];
		
		props.adminLoadState(paramsRemove, 'links', 'reload.categories');
	}
	
	console.log("ADMIN STATE", props.admin);
	return (
		<div className="full-row">
		{redirect && <Redirect to={redirect} />}
			<h2>{coreOrgName ? `${coreOrgName} ` : ''}Product Categories</h2>
			<Fragment>
				{props.pgParams.component === 'category-add' ?
					<Fragment>
						<FormCategoryAddEdit
							{...props}
							userID={userID}
							token={token}
							organizationID={organizationID}
							organizations={organizations}
							languageCode={languageCode}
							isAdmin={true}
							isSuper={isSuper}
							canEdit={canEdit}
							categories={categories}
							categoryType="product"
							coreOrg={coreOrg}
							location={'admin'}
							actionSubmit={addEditCategory}
							actionSelectOrg={props.actionSelectOrg} />
					</Fragment>
				:
				props.pgParams.component === 'category' ?
					<Category
						{...props}
						userID={userID}
						token={token}
						organizationID={organizationID}
						languageCode={languageCode}
						isAdmin={true}
						isSuper={isSuper}
						canEdit={canEdit}
						coreOrg={coreOrg}
						location="admin"
						pgParams={props.pgParams} />	
				:
				
				<Fragment>
					<OrganizationSelector {...props}
						organizations={organizations}
						action={props.actionSelectOrg}
						orgID={coreOrg}
						skipCore={true}
						userAccess={userAccess}  />
					{categories && !loadingCats ?
					<CategoryList
						{...props}
						userID={userID}
						token={token}
						organizationID={organizationID}
						coreOrg={coreOrg}
						languageCode={languageCode}
						isAdmin={true}
						isSuper={isSuper}
						canEdit={canEdit}
						categoryType="product"
						categories={categories}
						location='/admin/products'
						actionAddEdit={loadAddEditCategory}
						actionRemove={loadRemoveCategory} />
				: !categories && !loadingCats ?
					<p>To view categories, please select an organization</p>
				:
					
					<Loader label="Loading categories..." />	
				}
				</Fragment>
				}
			</Fragment>
		</div>
	)
}