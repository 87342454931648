import React, {useEffect, useState} from 'react';

import IconSVG from 'components/icons/IconSVG';
import RadioSet from 'components/forms/elements/RadioSet';
import TextArea from 'components/forms/elements/TextArea';
import Button from 'components/forms/elements/Button';
import Control from 'components/atoms/Control';

export default (props) => {
	const [active, setActive] = useState(null);
	const [approvalStatus, setApprovalStatus] = useState(null);
	const [approvalMessage, setApprovalMessage] = useState(null);
	const [approvalReady, setApprovalReady] = useState(null);
	const [approvalSet, setApprovalSet] = useState(null);
	const [btnLabel, setBtnLabel] = useState('Select Approval');
	const [clearMessage, setClearMessage] = useState(false);
	const [showInstruction, setShowInstruction] = useState(true);
	
	useEffect(() => {
		if(props.approval) {
			if(props.approval.approval_status &&
				props.approval.approval_status !== approvalStatus) {
				setApprovalStatus(props.approval.approval_status);
			}
			
			if(props.approval.approval_message &&
				props.approval.approval_message !== approvalMessage) {
				setApprovalMessage(props.approval.approval_message);
			}
			
			if(props.approval.approval_date) {
				setApprovalSet(true);
				setApprovalReady(true);
				setBtnLabel('Complete');
			}
		}	
	}, [props.approval]);
	
	
	
	useEffect(() => {
		switch(approvalStatus) {
			case 'approved':
				setApprovalReady(true);
				setBtnLabel('Submit');
				break;
			case 'proof_required':
				if(approvalMessage) {
					setApprovalReady(true);
					setBtnLabel('Submit');
				}else {
					setApprovalReady(false);
					setBtnLabel('Provide Instructions')
				}
				break;
			default:
				if(approvalReady) {
					setApprovalReady(false);
					setBtnLabel('Select Approval');
				}
				break;
		}
	}, [approvalStatus, approvalMessage]);
	
	function handleStatus(params) {
		if(params.value && params.value !== approvalStatus) {
			setApprovalStatus(params.value);
		}
	}
	
	function handleComment(params) {
		setClearMessage(false);
		if(params.value === '') {
			setApprovalMessage(null);
		}else{
			setApprovalMessage(params.value);
		}
	}
	
	function resetApproval() {
		setApprovalStatus(null);
		setApprovalMessage(null);
		setClearMessage(true);
		setApprovalSet(false);
		if(props.actionClear) {
			props.actionClear();
		}
	}
	
	function handleApproval() {
		if(approvalReady && !approvalSet) {
			setApprovalSet(true);
			setBtnLabel('Complete');
			
			if(props.actionApprove) {
				props.actionApprove({
					approval_status: approvalStatus,
					approval_message: approvalMessage
				});
			}
		} 
	}
	
	return (
		<div className={`block-border info-border${active ? ` active` : ''}`}
			style={{
				borderWidth: '.25em',
				borderRadius: '.5em'
			}}>
			
			<div className="info-block info-bg" 
				style={{
					display: 'flex',
					alignItems: 'flex-start',
					flexWrap: 'wrap'
				}}>
				<IconSVG icon="view-file" 
						className="white"
						style={{
							marginRight: '.5em',
							flex: `0 0 ${showInstruction ? '2em' : '1em'}`,
							height: showInstruction ? '2em' : '1em'
						}} />
				<div style={{
					flex: '1 0 13em'
				}}>
					<b style={{fontSize: '1.25em', lineHeight: '1'}}>This product requires approval.</b>
					<div className={`expandable${showInstruction ? ' active' : ''}`}>
						Review carefully and if satisfied select <b>Approved to Proceed</b>.<br />
						If not try editing or select <b>Additional Proof Required</b> and supply instructions (instructions required).
					</div>
				</div>
				<div style={{flex: '0 0 100%', display: 'flex', justifyContent: 'flex-end'}}>
					<Control icon={showInstruction ? 'collapse' : 'expand'}
							iconClass="no-btn"
							label={showInstruction ? 'Hide Instructions' : 'Show Instruction'}
							showLabel={true}
							style={{opacity: 1, margin: '0em', marginTop: '.5em'}}
							className="white subtext"
							action={()=>{setShowInstruction(showInstruction ? false : true)} } />
				</div>
			</div>
			<div className="form-row" style={{justifyContent: 'center'}}>
				<RadioSet {...props}
					name="approval_status"
					option_groups={[{options: [
						{option_name: 'Approved To Proceed', option_value: 'approved'},
						{option_name: 'Additional Proof Required', option_value: 'proof_required'}	
					]}]}
					value={approvalStatus}
					onChange={handleStatus}
					
					/>	
			</div>
			<div className="form-row">
				<TextArea {...props}
					name="approval_comment"
					label="Intructions"
					placeholder="Provide details on additional changes"
					value={approvalMessage}
					required={true}
					onChange={handleComment}
					clearContents={clearMessage}
					/>	
			</div>
			<div className="form-row" style={{justifyContent: 'center'}}>
				<Button icon={approvalReady ? `check` : `caution`}
					label={btnLabel}
					btnClass={`lg ${approvalReady ? 'success-bg' : 'caution-bg'}`}
					disabled={approvalReady && !approvalSet ? false : true}
					btnAction={handleApproval} />
			</div>
			<div className="form-row" style={{justifyContent: 'center'}}>
				<Control icon="refresh" 
					label="Clear approval?"
					iconClass="no-btn"
					showLabel={true} 
					action={resetApproval} />
			</div>
		</div>
	)
}