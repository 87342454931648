import React, {useEffect, useState} from 'react';

import IconSVG from 'components/icons/IconSVG';
import IconToken from 'components/icons/IconToken';
import FormDynamic from 'components/forms/FormDynamic';

import * as forms from 'methods/forms';

export default (props) => {
  const [expandView, setExpandView] = useState(false);
  const [editActive, setEditActive] = useState(false);
  const [fieldsLocation, setFieldsLocation] = useState(null);
  const [formLocation, setFormLocation] = useState(null);

  useEffect(() => {
    let locFields = [];

    locFields.push({
      field_type: 'hidden',
      field_name: `location_id`,
      field_value: props.location.location_id,
      field_required: false,
      field_validate: false,
      field_order: -1,
    })

    forms.fieldsLocation.map((field, index) => {
      locFields.push({...field,
                      field_value: props.location[field.field_name],
                      field_order: index+1,

                    });
    })
    let adtlCount = forms.fieldsLocation.length;


    setFieldsLocation(locFields);

  }, [props.location]);

  useEffect(() => {
    if(fieldsLocation) {
      console.log("Location Fields: ", fieldsLocation);

      setFormLocation(
        <FormDynamic
            content={[{fieldset_name: 'Edit Location', fields: fieldsLocation}]}
            name="locationUpdate"
            fieldOnChange={fieldOnChange}
            fieldOnLoad={fieldOnLoad}
            formActions={{formSubmit: submitForm, formCancel: cancelForm}}
            closable={true}
            unmountOnClick={true}
            cancelable={true}
            includeControls={true}
            multiStep={false}
            returnObject={true}
            formClass=""
            valActions={{isEmail: props.isEmail}}
         />
      )
    }
  }, [fieldsLocation]);

  function fieldOnChange(e) {
    return;
  }

  function fieldOnLoad(e) {
    return;
  }

  function cancelForm(e) {
    props.setModal(null);
  }

  function submitForm(params) {
    params.push({name: 'process', value: 'update_location'});
    params.push({name: 'user_id', value: props.userID});
    params.push({name: 'token', value: props.token});
    console.log("formData", params);
    const cbParams = [
      {name: 'process', value: 'get_organizations'},
      {name: 'user_id', value: props.userID},
      {name: 'token', value: props.token},
      {name: 'organization_id', value: props.organization.organization_id}
    ]

    props.adminLoadState(params, 'locations', 'organization', cbParams, 'organizations');
    props.setModal(null);

  }

  function openEdit(e) {
    props.setModal(formLocation)
  }

  return (
    <div className={`editable-set${expandView ? ' expand' : ''}${editActive ? ' active' : ''}`}>
      <IconSVG icon="pin" className="set-icon" />
      <div className="editable-set-info-display" >
        { props.location.location_name ? props.location.location_name :

          `${props.location.location_address_1}${props.location.location_address_1 && props.location.location_address_2 ? ' - ' : ''}${props.location.location_address_2 ? props.location.location_address_2 : ''}`}
        {(props.location.location_city || props.location.location_province.toUpperCase()) &&
          <div className="editable-set-info-display-shorthand">

            { props.location.location_city && props.location.location_province ?
                `, ${props.location.location_city}, ${props.location.location_province.toUpperCase()}`
              :
                props.location.location_city ? `, ${props.location.location_city}` :
                  props.location.location_province ? `, ${props.location.location_province.toUpperCase()}` :
                    ''
            }
          </div>
        }
        <div className="editable-set-info-display-full">
        { props.location.location_name ?
            <div>{`${props.location.location_address_1}${props.location.location_address_1 && props.location.location_address_2 ? ' - ' : ''}${props.location.location_address_2 ? props.location.location_address_2 : ''}`}</div>
          : ''
        }
          <div>
            {props.location.location_city}, {props.location.location_province.toUpperCase()}
          </div>
          <div>
            {props.location.location_postal_code}  {props.location.location_country && props.location.location_country.toUpperCase()}
          </div>
        </div>
      </div>
      <div className="editable-set-ctl">
        <IconToken icon="edit" action={openEdit} className="btn" />
        <IconToken icon={expandView ? 'hide' : 'preview'} action={()=>{expandView ? setExpandView(false) : setExpandView(true)}} className="btn" />
      </div>
    </div>
  )
}
