import React, {useEffect, useState} from 'react';

import MenuSetAction from 'components/navigation/MenuSetAction';
import ModalLogin from 'components/molecules/ModalLogin';

export default (props) => {
  const [user, setUser] = useState(null);
  
  useEffect(() => {
    if(props.objExists(props.user, 'user_id') &&
        user !== props.user) {
      setUser(props.user);
    }
  }, [props.user]);

  
  
  const modalContent = <ModalLogin formAction={props.userLogin} unmountOnClick={false} />
  return(
    user ?
      <h4>{`${user.contact.contact_name_first} ${user.contact.contact_name_last}`}</h4>
    :
      <MenuSetAction  {...props} name="Login" actions={{action_name: 'setModal', action_props: [modalContent]}} />
  )
}
