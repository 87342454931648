import React, {useEffect, useState, Fragment} from 'react';

import FileUpload from 'components/forms/elements/FileUpload';
import FormFileUpload from 'components/forms/common_forms/FormFileUpload';
import MediaFileToken from 'components/profiles/media/MediaFileToken';
import MediaFile from 'components/profiles/media/MediaFile';
import Control from 'components/atoms/Control';
import LoaderFileUpload from 'components/atoms/LoaderFileUpload';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [task, setTask] = useState(null);
	const [item, setItem] = useState(null);
	const [project, setProject] = useState(null);
	const [proofs, setProofs] = useState(null);
	const [proofCount, setProofCount] = useState(null);
	const [revisions, setRevisions] = useState(null);
	const [files, setFiles] = useState(null);
	const [fileUploading, setFileUploading] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(props.userID && 
			props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && 
			props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.languageCode && 
			props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.task && 
			props.task !== task) {
			setTask(props.task);
		}
	}, [props.task]);
	
	useEffect(() => {
		if(props.item && 
			props.item !== item) {
			setItem(props.item);
		}
	}, [props.item]);
	
	useEffect(() => {
		if(props.project && 
			props.project !== project) {
			setProject(props.project);
		}
	}, [props.project]);
	
	useEffect(() => {
		if(props.objGetValue(props.files, 'dashboard.uploadComplete') === true) {
			props.dashboardSetValue('reload.item', true);
			props.FilesDeleteValue('dashboard');
		}
	}, [props.files]);
	
	useEffect(() => {
		if(Array.isArray(props.objGetValue(item, 'files.proofs')) &&
			item.files.proofs.length > 0 &&
			item.files.proofs !== proofs) {
			setProofs(item.files.proofs);
			fileUploading === 'proof' &&
			setFileUploading(null);
		}
		
		if(Array.isArray(props.objGetValue(item, 'files.revisions')) &&
			item.files.revisions.length > 0 &&
			item.files.revisions !== revisions) {
			setRevisions(item.files.revisions);	
			fileUploading === 'revision' &&
			setFileUploading(null);	
		}
		
		if(Array.isArray(props.objGetValue(item, 'files.general')) &&
			item.files.general.length > 0 &&
			item.files.general !== files) {
			setFiles(item.files.general);
			fileUploading === 'file' &&
			setFileUploading(null);	
		}
		
	}, [item]);
	
	function uploadFile(params) {
		props.setModal(null);
		setFileUploading(params.value);
		setTimeout(()=>{
			props[`${props.stateLocation ? props.stateLocation : 'dashboard'}SetVal`](`reload.${props.reloadCue ? props.reloadCue : 'task'}`, true);
		}, props.minToMS(.25));
	}
	
	// FUNCTIONS 
	function loadUploadFile(type) {
		props.setModal(
			<FormFileUpload {...props}
				userID={userID}
				token={token}
				languageCode={languageCode}
				organization_id={props.objGetValue(task, 'organization_id')}
				media_type={type}
				media_published={true}
				media_owner_type="item"
				media_owner_id={props.objGetValue(task, 'item_id')}
				fileName={type == 'proof' ? `${props.objExists(task, 'item_name') ? `${task.order_id ? `O${task.order_id}-I${task.item_id}` : `I${task.item_id}`} ${task.item_name}` : type}` : null}
				fileVersion={type === 'proof' && Array.isArray(proofs) ? proofs.length+1 : null}		
				return_boolean='true'
				stateLocation={props.stateLocation ? props.stateLocation : 'dashboard'}
				actionUpload={uploadFile}
				uploadParams={{name: 'type', value: type}}
				includeCtl={true}
				/>
		)
	}
	
	function removeFile(media_id, media_type, link_type) {
		const paramsRemove = [
			{name: 'process', value: 'update_link_no_id'},
			{name: 'user_id', value: userID},
			{name: 'token', value: token},
			{name: 'link_from', value: media_id},
			{name: 'link_to', value: props.taskType === 'item' && item.item_id ? item.item_id : task.task_id},
			{name: 'link_type', value: `${media_type.toLowerCase()}To${props.capitalizeString(props.taskType)}`},
			{name: 'link_field', value: 'link_removed'},
			{name: 'link_value',value: 'true'}
		];
		console.log("REMOVE PARAMS", paramsRemove);
		props[`${props.stateLocation ? props.stateLocation : 'dashboard'}LoadState`](paramsRemove, 'links', 'reload.item');
	}
	
	
	console.log("***PROOFS***", proofs);
	return (
		<div className="card">
			{(!proofs && fileUploading === 'proof') &&
				<LoaderFileUpload {...props} />
			}
			
			{proofs && ((props.currentProof && proofs.length > 1) || 
				!props.currentProof) &&
				<div className="card-block border-bottom">
					<h3>Proofs{fileUploading === 'proof' && <span>Uploading new proof...</span>}</h3>
					<div className="info-block">
						Creative proofs uploaded for review.
					</div>
					{(fileUploading === 'proof') &&
						<LoaderFileUpload {...props} />
					}
					
					<div className="file-collection">
						{!props.currentProof &&
							<MediaFileToken {...props}
								key={`mftPrf0`}
								userID={userID}
								token={token}
								languageCode={languageCode}
								file={proofs[0]}
								fileMediaType='proof'
								fileOwner='item'
								fileOwnerID={item ? item.item_id : null}
								isHero={true}
								label='Current Proof'
								actionRemove={userID === proofs[0].media_created_id ? removeFile : null}
								displayInfo={true} />
						}
						{proofs.length > 1 &&
						<div className="file-set">
							<h4>Previous Proofs</h4>
							{proofs.map((proof, index) => {
								if(index > 0) {
									return (
										<MediaFile {...props}
											key={`mftPrf${index}`}
											userID={userID}
											token={token}
											inline={false}
											media={{...proof, media_type: 'proof'}}
											location={props.stateLocation ? props.stateLocation : "dashboard"}
											showSpecs={true}
											actionRemove={userID === proof.media_created_id ? removeFile : null} />
									);	
								}
							})}
						</div>
						}
					</div>
					{props.taskAccess && 
					props.taskAccess.owner &&
						<Control {...props} 
							icon="file"
							iconClass="no-btn"
							className="ctl-btn info-bg" 
							label="Upload New Proof"
							showLabel={true}
							action={()=>{loadUploadFile('proof')}} />
					}
				</div>
			}
			
				
			{revisions &&
			<div className="card-block border-bottom">
				<h3>Revisions</h3>
				<div className="info-block">
					Marked up files with edits required for approval.
				</div>
				<div className="file-set">
					{revisions.map((revision, index) => {
						return (
							<MediaFile {...props}
									key={`mftPrf${index}`}
									userID={userID}
									token={token}
									inline={false}
									media={revision}
									location={props.stateLocation ? props.stateLocation : "dashboard"}
									showSpecs={true} />
						)	
					})}
				</div>
			</div>
			}
			
			{item &&
			<div className="card-block border-bottom">
				<h3>General Files</h3>
				<div className="info-block">
					Add any non-proof or non-revision files related to this item.
				</div>
				{(fileUploading === 'file') &&
					<LoaderFileUpload {...props} />
				}
				{files &&
				<div className="file-set">
					{files.map((file, index) => {
						return (
							<MediaFile {...props}
								key={`mftPrf${index}`}
								userID={userID}
								token={token}
								inline={false}
								media={file}
								location={props.stateLocation ? props.stateLocation : "dashboard"}
								showSpecs={true} />
						)	
					})}
				</div>
				}
				<Control {...props} 
					icon="file"
					iconClass="no-btn"
					className="ctl-btn info-bg" 
					label="Upload Other File"
					showLabel={true}
					action={()=>{loadUploadFile('file')}} />
			</div>
			}
		</div>
	)
}