import React, {useEffect, useState, Fragment} from 'react';

import IconToken from 'components/icons/IconToken';
import ContactBlock from 'components/profiles/contacts/ContactBlock';

export default (props) => {
	const [location, setLocation] = useState(null);
	const [shippingMethod, setShippingMethod] = useState(null);
	const [contact, setContact] = useState(null);
	const [locationIndex, setLocationIndex] = useState(null);
	const [orderIndex, setOrderIndex] = useState(null);
	const [required, setRequired] = useState(null);
	const [contactMissing, setContactMissing] = useState(null);
	const [errorFlags, setErrorFlags] = useState(null);
	const [errorLocation, setErrorLocation] = useState(null);
	const [errorContact, setErrorContact] = useState(null);
	const [errorFields, setErrorFields] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(props.location && Object.keys(props.location).length > 0) {
			const locTest = Object.keys(props.location).filter(key => key.indexOf('location_') >= 0);
			if(locTest.length > 0) {
				let filterLoc = {};
				locTest.map(lKey => {
					if(lKey !== 'location_shipping_method') {
						filterLoc[lKey] = props.location[lKey] !== '' ? props.location[lKey] : null;	
					}else if(props.location[lKey] !== '' && props.location[lKey] !== shippingMethod){
						setShippingMethod(props.location[lKey]);
					}
					
				})
				if(Object.keys(filterLoc).length > 0 &&
					filterLoc !== location) {
						setLocation(filterLoc);
				}
			}
			
			
		}
	}, [props.location]);
	
	useEffect(() => {
		if(props.contact) {
			const contactTest = Object.keys(props.contact).filter(key => key.indexOf('contact_') >= 0);
			if(contactTest.length > 0) {
				 let filteredContact = {};
				 contactTest.map(cKey => {
					 filteredContact[cKey] = props.contact[cKey] !== '' ? props.contact[cKey] : null;
				 })
				 if(Object.keys(filteredContact).length > 0 &&
			 		filteredContact !== contact) {
						setContact(filteredContact);
				 }
			}
		}
	}, [props.contact]);
	
	useEffect(() => {
		if(props.locationIndex &&
			props.locationIndex !== locationIndex) {
			setLocationIndex(props.locationIndex);
		}
	}, [props.locationIndex]);
	
	useEffect(() => {
		if(props.orderIndex >= 0 && 
			(!orderIndex ||
			props.orderIndex !== orderIndex)) {
			setOrderIndex(props.orderIndex);
		}
	}, [props.orderIndex]);
	
	useEffect(() => {
		if(Array.isArray(props.requiredFields) && 
			props.requiredFields !== required) {
			const requiredLocationFields = props.requiredFields.filter(rField => rField.match('location_'));
			setRequired(props.requiredFields);
		}
	}, [props.requiredFields]);
	
	useEffect(() => {
		// console.log("MISSING CONTACT STUFF required", required);
		if(Array.isArray(required) && required.length > 0) {
			const reqContactFields = required.filter(fKey => fKey.indexOf('contact_') >= 0);
			if(contact && Object.keys(contact).length > 0) {
				let missingCFields = [];
				let cFieldsMissing = false;
				reqContactFields.map(rKey => {
					if(!contact[rKey]) {
						missingCFields.push(rKey);
						cFieldsMissing = true;
					}
				});
				
				if(cFieldsMissing && !contactMissing) {
					setContactMissing(true);
					// console.log("MISSING CONTACT STUFF", cFieldsMissing, missingCFields);
				}
			}else if(reqContactFields.length > 0 && !contactMissing) {
				// console.log("MISSING CONTACT STUFF", true, reqContactFields);
				setContactMissing(true);
			}
		}
	}, [contact, required]);
	
	useEffect(() => {
		if(props.errorFlags && 
			props.errorFlags !== errorFlags) {
			setErrorFlags(props.errorFlags);
		}
	}, [props.errorFlags]);
	
	useEffect(() => {
		if(location && orderIndex, locationIndex && errorFlags) {
			if(props.objExists(errorFlags, `orders[${orderIndex}].shipping.locations[${locationIndex}]`)) {
				const eFields = errorFlags.orders[orderIndex].shipping.locations[locationIndex];
				const eLocFields = Object.keys(eFields).filter(key => key.indexOf('location') >= 0);
				const eConFields = Object.keys(eFields).filter(key => key.indexOf('contact') >= 0);
				
				eLocFields.length > 0 && setErrorLocation(true);
				eConFields.length > 0 && setErrorContact(true);
				setErrorFields(eFields);
			}
		}
	}, [location, contact, orderIndex, locationIndex, errorFlags]);
	
	useEffect(() => {
		if(Array.isArray(required) && location) {
			let missing = {};
			required.map((field, index) => {
				if(!location[field] || 
					location[field] === '') {
						missing[field] = true;
				}
			})
		}
	}, [required, location]);
	
	// FUNCTIONS 
	function handleAddEdit(type, value) {
		switch (type) {
			case 'location':
				props.actionLocationAddEdit &&
				props.actionLocationAddEdit(type, value);
				break;
			case 'contact':
				props.actionContactAddEdit &&
				props.actionLocationAddEdit(type, value);
				break;
			default:
				break;
		}
	}
	
	return (
		<Fragment>
			<div>
				<div className="flex">
					<div className="flex-col">
						<Fragment>
							<div className={`block location auto${props.className ? ` ${props.className}` : ''}`}>
								<div className="flex-row" style={{marginLeft: '0em'}}>
									<div className="truncate" style={{flex: '0 0 4.25em'}}>
									{props.locationHeading &&
										<div className="block-heading">
											{props.locationHeading}
										</div>
									}
									</div>
									{location ?
									<div className="truncate" style={{flex: '1 0 1em'}}>
										{location.location_name &&
											<div className={`block-row title`}>
												{location.location_name}
												{location.location_default &&
													<IconToken icon="star" tooltip="Default Location" />
												}
											</div>
										}
										<div className={`block-row`}>
											{`${location.location_address_1}${location.location_address_2 ? ` - ${location.location_address_2}` : ''}`}
											{location.location_default &&
												<IconToken icon="star" tooltip="Default Location" />
											}
										</div>
										<div className={`block-row`} >
											{`${location.location_city}, ${location.location_province}  ${location.location_postal_code}`}
										</div>
										<div className={`block-row optional`} >
											{location.location_country}
										</div>
									</div>
									
									:
									
										<div className="block-row error">
											Location Required!
										</div>
									}
								</div>
							</div>
							{props.allowEdit && 
							(!props.className || !props.className.match('horizontal')) &&
								<div className="ctl">
									{location ? 
										<IconToken 
											icon="location-edit" 
											tooltip="Edit Address" 
											action={()=>{handleAddEdit('location', location)}} />
									:
										<IconToken 
											icon="location-add" 
											tooltip="New Address"
											action={()=>{handleAddEdit('location', null)}} />
									}
								</div>
							}
						</Fragment>
					</div>
					<div className="flex-col">
						{(contact || contactMissing) &&
							<ContactBlock {...props}
								className={props.className}
								contact={contact}
								contactHeading={props.contactHeading}
								actionContactAddEdit={handleAddEdit}
								actionLocationAddEdit={handleAddEdit}
								requiredFields={props.requiredFields}
								allowEdit={props.allowEdit}
								error={errorContact}
								/>
						}
					</div>
				</div>
			</div>
			{props.allowEdit && props.actionLocationAddEdit && 
			props.className && 
			props.className.match('horizontal') &&
				<Fragment>
					<div className="ctl">
						{location &&
							<IconToken 
								icon="location-edit" 
								tooltip="Edit Address" 
								action={()=>{handleAddEdit('location', location)}} />	
						}
						{!location &&
							<IconToken 
								icon="location-add" 
								tooltip="New Address"
								action={()=>{handleAddEdit('location', null)}} />	
						}
						
						<Fragment>
							{contact &&
								<IconToken
									icon="user-edit" 
									tooltip="Edit Contact" 
									action={()=>{handleAddEdit('contact', contact)}} />
							}
							{!contact && 
								<IconToken
									icon="user-add" 
									tooltip="New Contact"
									className={!contact ? `error` : null}
									action={()=>{handleAddEdit('contact', null)}} />	
							}
						</Fragment>
					</div>
				</Fragment>
			}
		</Fragment>
	)
}