import React from 'react';

export default (props) => {


  return (
    <div className={`core-rule${props.vertical ? ' vertical' : ''}${props.color ? ` ${props.color}` : ''}${props.className ? ` ${props.className}` : ''}`}>
      <div className="core-rule-elipse" id="core-rule-elipse-left"></div>
      <div className="core-rule-line" id="core-rule-line"></div>
      <div className="core-rule-elipse" id="core-rule-elipse-right"></div>
    </div>
  )
}
