import React, {useEffect, useState, Fragment} from 'react';

import ProductChild from 'components/products/ProductChild';
import Control from 'components/atoms/Control';
import IconSVG from 'components/icons/IconSVG'

export default (props) => {
	const [products, setProducts] = useState(null);
	const [selectedProducts, setSelectedProducts] = useState(null);
	const [children, setChildren] = useState(null);
	const [additionalChildren, setAdditionalChildren] = useState(null);
	const [selectedChild, setSelectedChild] = useState(null);
	const [cueUpdate, setCueUpdate] = useState(null);
	const [select, setSelect] = useState(null);
	
	useEffect(() => {
		if(props.selectedProducts && 
			props.selectedProducts !== selectedProducts) {
			setSelectedProducts(props.selectedProducts);
		}
	}, [props.selectedProducts]);
	
	useEffect(() => {
		if(props.optionalProperties && 
		Array.isArray(props.optionalProperties) &&
		props.optionalProperties.length > 0) {
			let selectedProductsProp = props.optionalProperties.find(prop => prop.property_name === 'selected_products');
			selectedProductsProp.property_value && setSelect(selectedProductsProp.property_value);
		}
	}, [props.optionalProperties]);
	
	useEffect(() => {
		if(props.products && 
			Array.isArray(props.products) &&
			props.products.length > 0) {
			let pChildren = [];
			props.products.map((product, index) => {
				pChildren.push(
					{...product, 
					selected: props.selectedProducts &&
								Array.isArray(props.selectedProducts) &&
								props.selectedProducts.length > 0 && 
								props.selectedProducts.find(prod => prod.product_id === product.product_id) ?
								true : false}
				);
			});
			pChildren.length > 0 &&
			setProducts(pChildren);
		}
		
		if(props.selectedProducts && 
			Array.isArray(props.selectedProducts) && 
			props.selectedProducts.length > 0) {
			setSelectedProducts(props.selectedProducts);
		}else if (!props.selectedProducts || !Array.isArray(props.selectedProducts) || props.selectedProducts.length <= 0){
			setSelectedProducts(null);
		}
	}, [props.products, props.selectedProducts, props.store]);
	
	useEffect(() => {
		if(products && Array.isArray(products)) {
			let prods = products;
			prods.sort((a, b) => {return a.product_optional - b.product_optional});
			const childrenSet = buildProductGrid(prods, true);
			setChildren(childrenSet);
		}else if(!products && children) {
			setChildren(null);
		}
	}, [products, selectedProducts]);
	
	useEffect(() => {
		if(select) {
			if(props.actionBulkSelect) {
				props.actionBulkSelect(select);
			}
		}
	}, [select]);
	
	
	useEffect(() => {
		if(selectedChild && cueUpdate) {
			if(props.actionSelect) {
				// setSelectedProducts(null);
				// setAdditionalProducts(null);
				props.actionSelect(selectedChild);
			}
			setCueUpdate(false);
		}
	}, [selectedChild, cueUpdate]);
	
	// FUNCTIONS
	function buildProductGrid(products, selected = true) {
		let childrenSet = [];
		Array.isArray(products) &&
		products.map((product, index, array) => {
			childrenSet.push(<ProductChild
								{...props}
								key={`pchld${index}`}
								product={product}
								actionSelect={selectProduct}
								productLanguage={props.productLanguage ? 
												props.productLanguage : 
												'en'}
								active={product.selected === true ? true : false}
								optional={product.product_optional} />);
		})
		return childrenSet;
	}
	
	
	function selectProduct(product_id, value) {
		setSelectedChild({product_id: product_id, value: value});
		setCueUpdate(true);
	}
	
	return (
		<Fragment>
			{children && Array.isArray(children) &&
				children.length > 0 &&
				<div className="product-children-interface">
					<div className="flex-full-row">
						<Control 
							icon={selectedProducts && 
								Array.isArray(selectedProducts) &&
								(selectedProducts.length - selectedProducts.filter(prod => parseInt(prod.product_optional) === 0).length) > 0 ? `close` : `check`}
							className={`ctl-btn ${selectedProducts && 
								Array.isArray(selectedProducts) &&
								(selectedProducts.length - selectedProducts.filter(prod => parseInt(prod.product_optional) === 0).length) > 0 ? 'error-bg' : 'success-bg'}`}
							label={`${selectedProducts && 
										Array.isArray(selectedProducts) &&
										(selectedProducts.length - selectedProducts.filter(prod => parseInt(prod.product_optional) === 0).length) > 0 ? 'Unselect' : 'Select'} all`}
							disabled={select === 'none'}
							showLabel={true}
							action={()=>{setSelect(selectedProducts && 
													Array.isArray(selectedProducts) &&
													(selectedProducts.length - selectedProducts.filter(prod => parseInt(prod.product_optional) === 0).length) > 0 ? 'none' : 'all')}} />
						<div className="inline-alert">
							<IconSVG icon="check" />
							<p>Selected products will be included with your order. (deselect any products you do not wish to order)</p>
						</div>
					</div>
					<legend>Selected Products</legend>
					<div className="product-children grid selected">
						{children}
					</div>
				</div>
			}
		</Fragment>
	)
}