import React, {useEffect, useState} from 'react';

import FormDynamic from 'components/forms/FormDynamic';

export default (props) => {
  const [fieldsDiscount, setFieldsDiscount] = useState(null);
  const [formDiscount, setFormDiscount] = useState(null);
  const [updating, setUpdating] =useState(false);

// HOOKS
useEffect(() => {
    setFieldsDiscount([
      {fieldset_name: `${props.objExists(props, 'pricing.pricing_id') || props.objExists(props, 'discount.discount_id') ? 'Edit' : 'Add'} discount`,
      fields: [
        {field_type: 'number',
        field_name: 'discount_quantity',
        field_label: 'Starting Quantity',
        field_placeholder: 'Quantity discount begins at',
        field_value: props.objGetValue(props, 'discount.discount_quantity'),
        field_order: 1,
        field_required: true,
        field_validate: true,},
        {field_type: 'float',
        field_name: 'discount_percentage',
        field_label: 'Discount(%)',
        field_placeholder: 'Percentage discounted from price',
        field_value: props.objGetValue(props, 'discount.discount_percentage'),
        field_order: 2,
        field_required: true,
        field_validate: true,},
        {field_type: 'number',
        field_name: 'discount_proximity',
        field_label: 'Alert Qty Trigger',
        field_placeholder: 'Quantity to being discount nudge',
        field_value: props.objGetValue(props, 'discount.discount_proximity'),
        field_order: 3,
        field_required: false,
        field_validate: true,},
        {field_type: 'switch',
        field_name: 'discount_published',
        field_label: 'Publish',
        field_value: props.objGetValue(props, 'pricing.discount_published') === 0 ||
                      props.objGetValue(props, 'pricing.discount_published') === false ||
                      props.objGetValue(props, 'pricing.discount_published') === 'false' ?
                        false : true,
        field_required: false,
        field_validate: false,
        field_order: 4},
        {field_type: 'hidden',
        field_name: 'process',
        field_value: props.objExists(props, 'discount.discount_id') ? 'update_pricing_discount' : 'create_discount',
        field_required: false,
        field_validate: false
        },
        props.objExists(props, 'discount.discount_id') &&
        {field_type: 'hidden',
        field_name: 'discount_id',
        field_value: props.discount.discount_id,
        field_required: false,
        field_validate: false
        },
        props.objExists(props, 'pricing_id') &&
        {field_type: 'hidden',
        field_name: 'pricing_id',
        field_value: props.pricing_id,
        field_required: false,
        field_validate: false
        },
      ]},

    ])
}, [props.discount]);

useEffect(() => {
  if(fieldsDiscount) {
    setFormDiscount(<FormDynamic {...props}
                          content={fieldsDiscount}
                          name="add-pricing-discount"
                          returnObject={true}
                          cancelable={props.actionCancel ? true : false}
                          formActions={{formCancel: props.actionCancel ? handleCancel : null, formSubmit: handleAddEdit}} />);
  }
}, [fieldsDiscount]);

// FUNCTIONS
  function handleCancel(e) {
    if(props.actionCancel) {
      props.actionCancel(e);
    }
  }

  function handleAddEdit(params) {
    props.setModal(null);
    if(props.actionAddEdit) {
      props.actionAddEdit(params);
    }
  }

  return (formDiscount)
}
