import React, {useEffect, useState} from 'react';
import { Redirect } from 'react-router';

import Control from 'components/atoms/Control';

const CartAddSuccess = (props) => {
	const [languageCode, setLanguageCode] = useState('en');
	const [product, setProduct] = useState(null);
	const [productName, setProductName] = useState(null);
	const [productQty, setProductQty] = useState(null);
	const [productID, setProductID] = useState(null);
	const [redirect, setRedirect] = useState(null);
	const [categoryHome, setCategoryHome] = useState('/categories');
	
	useEffect(() => {
		if(props.languageCode && 
			props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.product && props.product !== product) {
			setProduct(props.product);
		}
	}, [props.product]);
	
	useEffect(() => {
		if(props.objExists(props.site, `preferences.category_home`)) {
			setCategoryHome(props.site.preferences.category_home);
		}
	}, [props.site]);
	
	useEffect(() => {
		if(props.productQty && props.productQty !== productQty) {
			setProductQty(parseInt(props.productQty));
		}
	}, [props.productQty]);
	
	useEffect(() => {
		let pName;
		if(props.objExists(product, `details.${languageCode}.product_name`)) {
			pName = `${product.details[languageCode].product_name}`;
			if(props.objExists(product, `product_nickname`)) {
				pName += ` (${product.product_nickname})`;
			}
		}
		if(pName && pName !== productName) {
			setProductName(pName);
		}
		
		let pQty;
		if(props.objExists(product, `details.${languageCode}.product_quantity`)) {
			pQty = parseInt(product.details[languageCode].product_quantity);
		}
		
		if(pQty && pQty !== productQty) {
			//setProductQty(pQty);
		}
		
	}, [product]);
	
	function handleOption(option) {
		if(props.handleSelect) {
			props.handleSelect();
		}
		switch(option) {
			case 'cart':
				setRedirect(`/cart`);
				break;
			case 'products':
				setRedirect(categoryHome);
				break;
			case 'product':
				props.filesClearUploads();
			    break;
			default:
				break;
		}
		
		props.setModal(null);
	}
	
	return (
		<div style={{display: 'flex', flexDirection: 'column', alignItems: 'stretch'}}>
			{redirect && <Redirect to={redirect} />}
			<div>
				<div className="text-center" style={{fontSize: '2em'}}><b>{productQty ? `${productQty} ` : ''}</b></div>
				<div className="text-center" style={{fontSize: '1.25em'}}><b>{productName ? props.capitalizeString(productName, true) : 'This product'}</b></div>
				<div className="text-center margin-bottom-1em" style={{fontSize: '1.25em'}}>{productQty && productQty > 1 ? `have` : `has`} been added to your cart.</div>
			</div>
			<div className="form-row>">
				<Control icon="cart" 
					label="View Cart" 
					className="ctl-btn info-bg" 
					style={{flex: '1 0 auto', width: '100%'}}
					iconClass="no-btn"
					showLabel={true} 
					action={()=>{handleOption('cart')}} />
			</div>
			<div className="form-row>">
				<Control icon="target" 
					label="Return to product" 
					className="ctl-btn info-bg" 
					style={{flex: '1 0 auto', width: '100%'}}
					iconClass="no-btn"
					showLabel={true} 
					action={()=>{handleOption('product')}} />
			</div>
			<div className="form-row>">
				<Control icon="task" 
					label="View more products" 
					className="ctl-btn info-bg" 
					style={{flex: '1 0 auto', width: '100%'}}
					iconClass="no-btn"
					showLabel={true} 
					action={()=>{handleOption('products')}} />
			</div>
		</div>
	)
}

export default CartAddSuccess;