import React, {useEffect, useState} from 'react';

import CardContent from 'components/molecules/CardContent';
import CardContentContact from 'components/cards/CardContentContact';

export default (props) => {
	
	
	
	return (
		<CardContent editable={false} >
			<div className="card-content-heading">Order will be billed to:</div>
			{props.objExists(props.site, 'persona.site_billing') &&
				<CardContentContact {...props}
									info={{...props.site.persona.site_billing.contact, 
											...props.site.persona.site_billing.location}}
									fieldsRequired={[]}
									type={'contact_location'} />
			
			}
		
		</CardContent>
	)
}