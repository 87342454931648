import React, {useEffect, useState} from 'react';

import FormDynamic from 'components/forms/FormDynamic';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

export default (props) => {
  const [optionsProperties, setOptionsProperties] = useState(null);
  const [fieldsProperties, setFieldsProperties] = useState(null);
  const [formProperties, setFormProperties] = useState(null);

  const propNameField = {field_type: 'text',
                        field_name: 'property_name',
                        field_label: 'Spec Name',
                        field_value: '',
                        field_required: true,
                        field_validate: true,
                      };
// HOOKS
useEffect(() => {
  if(props.properties &&
      Array.isArray(props.properties) &&
      props.properties.length > 0) {
        console.log("we're going in to proptions");
    let proptions = [
                      {option_name: 'Add New Spec...',
                      option_value: '---',
                      fieldsets: [{fields: [{...propNameField, field_order: 2}]}]},
                    ];
    props.properties.map(prop => {
      if(!props.activeProperties || props.activeProperties.length ||
        (Array.isArray(props.activeProperties) &&
        !props.activeProperties.includes(prop))) {
          proptions.push({option_name: props.capitalizeString(prop, true), option_value: prop.toLowerCase()});
        }
    })

    setOptionsProperties([{options: proptions}]);
    console.log("proptions", proptions);
  }
}, [props.properties, props.activeProperties]);

useEffect(() => {
  let propFields = [];
  if(optionsProperties) {
    propFields.push({field_type: 'select',
      field_name: 'property_name',
      field_label: 'Spec Name',
      field_placeholder: 'Select spec type',
      field_required: true,
      field_verify: true,
      option_groups: optionsProperties,
    });
  }else{
    propFields.push({...propNameField, field_order: 1});
  }
  propFields.push({
    field_type: 'text',
    field_name: 'property_value',
    field_label: 'Spec Value',
    field_placeholder: 'Spec value',
    field_value: '',
    field_required: true,
    field_verify: true,
    field_order: optionsProperties ? 3 : 2,
  });
  propFields.push({
    field_type: 'switch',
    field_name: 'property_private',
    field_label: 'Private Spec',
    field_default: false,
    field_required: false,
    field_verify: false,
    field_order: optionsProperties ? 4 : 3,
  });
  propFields.push({
    field_type: 'switch',
    field_name: 'property_published',
    field_label: 'Publish Spec',
    field_default: true,
    field_required: false,
    field_verify: false,
    field_order: optionsProperties ? 5 : 4,
  });
  Array.isArray(propFields) &&
  propFields.length > 0 &&
  setFieldsProperties([{fieldset_name: props.formName ? props.formName : 'Add Property', fields: propFields}]);
}, [optionsProperties]);

useEffect(() => {
  if(fieldsProperties) {
    console.log("Here's the form content", fieldsProperties);
    setFormProperties(
      <FormDynamic
          content={fieldsProperties}
          name="add-edit-category"
          returnObject={true}
          fieldOnChange={handleChange}
          fieldOnLoad={(e)=>{e.preventDefault()}}
          formActions={{formCancel: handleCancel, formSubmit: handleAdd}} />
    )
  }
}, [fieldsProperties]);

// FUNCTIONS
  function handleCancel(e) {
    if(props.actionCancel) {
      props.actionCancel(e);
    }
  }

  function handleChange(e) {
    console.log("We're handling change!!");
  }

  function handleAdd(params) {
    if(props.actionAdd) {
      props.actionAdd(params);
    }
  }

  return (formProperties)
}
