import React, {useEffect, useState} from 'react';

import AdminOrgInfo from 'components/admin/orgs/AdminOrgInfo';
import AdminOrgAddInfo from 'components/admin/orgs/AdminOrgAddInfo';
import AdminOrgAddLocation from 'components/admin/orgs/AdminOrgAddLocation';
import AdminOrgAddMedia from 'components/admin/orgs/AdminOrgAddMedia';

export default (props) => {
  const [orgComponent, setComponent] = useState(null);
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [siteLanguage, setSiteLanguage] = useState(null);

  useEffect(() => {
    if(props.userID) {
      setUserID(props.userID)
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token) {
      setToken(props.token)
    }
  }, [props.token]);

  useEffect(() => {
    if(props.organizationID && organizationID !== props.organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    props.organizationName &&
    props.organizationName !== organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);
  
  useEffect(() => {
    if(props.languageCode && languageCode !== props.languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    if(props.userAccess && props.userAccess !== userAccess) {
      setUserAccess(props.userAccess);
    } 
  }, [props.userAccess]);

  useEffect(() => {
    return() => {
      // if(props.objExists(props.admin, 'organization') &&
      //     props.admin.organization.organization_name &&
      //     props.admin.organization.
      // )

      console.log("Deleting Org");
      props.adminDeleteVal('organization');
      props.filesDeleteVal('uploads')
      sessionStorage.removeItem('admOrg');
      sessionStorage.removeItem('filesUpl');
    }

  }, []);

  useEffect(() => {
    // console.log("params?", props.pgParams.component);
    if(props.objExists(props.admin, 'params.component') || props.pgParams.component) {
      const component = props.pgParams.component ?
                props.pgParams.component :
                props.admin.params.component;
      // console.log("params?", props.pgParams.component, component);
      switch(component) {
        case 'organization-location':
          setComponent(<AdminOrgAddLocation {...props}
                                        userID={userID}
                                        organizationID={organizationID}
                                        token={token}
                                        organizationName={organizationName}
                                        pgParams={props.pgParams}
                                        testForSave={testForSave} />);
          return;
        case 'organization-media':
          setComponent(<AdminOrgAddMedia {...props}
                                        userID={userID}
                                        organizationID={organizationID}
                                        token={token}
                                        organizationName={organizationName}
                                        pgParams={props.pgParams}
                                        testForSave={testForSave} />);
          return;
        case 'organization-info':
        default:
          setComponent(<AdminOrgAddInfo {...props}
                                        userID={userID}
                                        organizationID={organizationID}
                                        token={token}
                                        organizationName={organizationName}
                                        pgParams={props.pgParams}
                                        testForSave={testForSave} />);
          return;
      }
    }else{
      setComponent(<AdminOrgAddInfo {...props} testForSave={testForSave}
                                    userID={userID}
                                    organizationID={organizationID}
                                    token={token}
                                    organizationName={organizationName}
                                    pgParams={props.pgParams}
                                    testForSave={testForSave} />);
    }
  }, [props.pgParams, props.user]);

  function testForSave() {
    // TODO
  }

  console.log("DASHBOARD:", props.objExists(props.admin, `organization`), props.admin);
  return (
    <div>
      {props.objExists(props.admin, `organization`) &&
        <AdminOrgInfo {...props}
                      userID={userID}
                      token={token} 
                      organization={props.admin.organization} />
      }
      {orgComponent}
    </div>
  )
}
