import React, {useEffect, useState, Fragment} from 'react';

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import IconToken from 'components/icons/IconToken';
import Control from 'components/atoms/Control';
import FormSendMessage from 'components/forms/common_forms/FormSendMessage';
import MessageList from 'components/profiles/messages/MessageList';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [message, setMessage] = useState(null);
	const [messageText, setMessageText] = useState(null);
	const [messageDate, setMessageDate] = useState(null);
	const [author, setAuthor] = useState(null);
	const [replies, setReplies] = useState(null);
	const [repliesActive, setRepliesActive] = useState(null);
	
	useEffect(() => {
		if(props.userID &&
			props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token &&
			props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.languageCode &&
			props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.message &&
			props.message !== message) {
			setMessage(props.message);
		}
	}, [props.message]);
	
	useEffect(() => {
		if(languageCode) {
			if(props.objExists(message, `${languageCode}.message_text`) &&
				message[languageCode].message_text !== messageText) {
				setMessageText(message[languageCode].message_text);
			}	
			
			if(props.objExists(message, `${languageCode}.message_created`) &&
				message[languageCode].message_created !== messageDate) {
					setMessageDate(message[languageCode].message_created);
			}else if(!props.objExists(message, `${languageCode}.message_created`) &&
				props.objExists(message, `message_created`) &&
				message.message_created !== messageDate) {
				setMessageDate(message.message_created);
			}
	
			if(props.objExists(message, `message_author`) &&
				message.message_author !== author) { 
				setAuthor(message.message_author);	
			}else if(!props.objExists(message, `message_author`) &&
						props.objExists(message, `contact_name_first`) &&
						`${props.objExists(message, `contact_name_first`)} ${props.objExists(message, `contact_name_last`)}` !==
						author) {
				setAuthor(`${props.objExists(message, `contact_name_first`)} ${props.objExists(message, `contact_name_last`)}`)
			}
		}
	}, [message, languageCode]);
	
	useEffect(() => {
		if(message && Array.isArray(message.replies) &&
			message.replies !== replies) {
			setReplies(message.replies);		
		}
	}, [message]);
	
	// FUNCTIONS 
	function loadReply() {
		props.setModal(
			<FormSendMessage {...props}
				messageParent={message.message_id}
				messageSubject={props.objExists(message, `${languageCode}.message_subject`) ?
								`RE: ${props.capitalizeString(message[languageCode].message_subject)}` :
									null}
				userID={userID}
				token={token}
				languageCode={languageCode}
				location={props.location}
				stateLocation={props.stateLocation}
				actionCancel={()=>{props.setModal(null)}}
				actionSubmit={reply} />
		);
	}
	
	function reply(params) {
		props.setModal(null);
		props[`${props.location ? props.location : 'dashboard'}LoadState`](params, 'messages', `reload.${props.stateLocation ? props.stateLocation : 'messages'}`);
	}
	
	return (
		<div className="message list-item">
			{message &&
			languageCode &&
			<Fragment>
				{props.objExists(message, `${languageCode}.message_subject`) &&
					<div className="subject">
						{props.capitalizeString(message[languageCode].message_subject, true)}
					</div>
				}
				<div className="subtext">
					<b>{author ? author : 'Core User '}</b>
					{messageDate && props.dateDisplay(messageDate)}
				</div>
				<div className="message-body">
					{messageText && ReactHtmlParser(props.encodeHTML(messageText))}
				</div>
				{!props.noReply &&
					<div className="ctl row">
						<Control 
							className="ctl-btn"
							icon="message-reply"
							iconClass="no-btn"
							label="Reply to message" 
							showLabel={true}
							action={loadReply} />
					</div>	
				}
				{replies && 
				<Fragment>
					<div className={`message-replies expandable${repliesActive ? '' : ' active'}`}>
						<Control 
							icon="messages-view" 
							iconClass="no-btn" 
							label={`Show ${replies.length} ${replies.length === 1 ? 'Reply' : 'Replies'}`}
							showLabel={true}
							action={()=>{setRepliesActive(repliesActive ? false : true)}} />
					</div>
					<div className={`message-replies expandable${repliesActive ? ' active' : ''}`}>
						<MessageList {...props}
							userID={userID}
							token={token}
							languageCode={languageCode}
							location={props.location}
							stateLocation={props.stateLocation}
							messages={replies}
							title={`${replies.length} ${replies.length === 1 ? 'Reply' : 'Replies'}`} />
						<Control 
							icon="messages-hide" 
							iconClass="no-btn" 
							label={`Hide ${replies.length === 1 ? 'Reply' : 'Replies'}`}
							showLabel={true}
							action={()=>{setRepliesActive(repliesActive ? false : true)}} />
					</div>
				</Fragment>
				}	
			</Fragment>
			}
		</div>
	)
}