import React, {useEffect, useState, Fragment} from 'react';

import IconToken from 'components/icons/IconToken';
import ProductRestriction from 'components/profiles/products/ProductRestriction';

export default (props) => {
	
	const [restrictions, setRestrictions] = useState(null);
	const [productRestrictions, setProductRestrictions] = useState(null);
	const [groupRestrictions, setGroupRestrictions] = useState(null);
	const [userRestrictions, setUserRestrictions] = useState(null);
	
	useEffect(() => {
		props.restrictions &&
		setRestrictions(props.restrictions);
	}, [props.restrictions]);
	
	useEffect(() => {
		if(restrictions) {
			let pRestrictions = [];
			let gRestrictions = [];
			let uRestrictions = [];
			restrictions.map((restriction, index) => {
				if (restriction.group_id === 0 && restriction.user_id === 0) {
					let pRestriction = [];
					pRestriction["restriction_id"] = restriction.restriction_id;
					pRestriction["product_id"] = restriction.product_id;
					pRestriction["restriction_priority"] = restriction.restriction_priority;
					pRestriction["restriction_quantity"] = restriction.restriction_quantity;
					pRestriction["restriction_days"] = restriction.restriction_days;
					pRestriction["restriction_date_start"] = restriction.restriction_date_start;
					pRestriction["restriction_date_end"] = restriction.restriction_date_end;
					pRestrictions.push(pRestriction);
				} else if (restriction.group_id > 0) {
					let gRestriction = [];
					gRestriction["restriction_id"] = restriction.restriction_id;
					gRestriction["group_id"] = restriction.group_id;
					gRestriction["group_name"] = restriction.group_name;
					gRestriction["restriction_priority"] = restriction.restriction_priority;
					gRestriction["restriction_quantity"] = restriction.restriction_quantity;
					gRestriction["restriction_days"] = restriction.restriction_days;
					gRestriction["restriction_date_start"] = restriction.restriction_date_start;
					gRestriction["restriction_date_end"] = restriction.restriction_date_end;
					gRestrictions.push(gRestriction);
				} else if (restriction.user_id > 0) {
					let uRestriction = [];
					uRestriction["restriction_id"] = restriction.restriction_id;
					uRestriction["core_user"] = restriction.user_id;
					uRestriction["user_name"] = restriction.contact_name_first + ' ' + restriction.contact_name_last;
					uRestriction["restriction_priority"] = restriction.restriction_priority;
					uRestriction["restriction_quantity"] = restriction.restriction_quantity;
					uRestriction["restriction_days"] = restriction.restriction_days;
					uRestriction["restriction_date_start"] = restriction.restriction_date_start;
					uRestriction["restriction_date_end"] = restriction.restriction_date_end;
					uRestrictions.push(uRestriction);
				}
			});
			if (pRestrictions.length > 0) {
				setProductRestrictions(pRestrictions);
			} else {
				setProductRestrictions(null);
			}
			if (gRestrictions.length > 0) {
				gRestrictions = [...new Map(gRestrictions.map(restriction => [restriction.group_name, restriction])).values()];
				setGroupRestrictions(gRestrictions);
			} else {
				setGroupRestrictions(null);
			}
			if (uRestrictions.length > 0) {
				setUserRestrictions(uRestrictions);
			} else {
				setUserRestrictions(null);
			}
		}
	}, [restrictions]);
	
	function addEditRestriction(params) {
		if(props.actionAddEdit) {
		  props.actionAddEdit(params);
		}
	  }
	
	function remove(params) {
		if(props.actionRemove) {
			props.actionRemove(params);
		}
	}
	
	return (
		<div className="margin-right-2em">
			{productRestrictions && Array.isArray(productRestrictions) && productRestrictions.length > 0 && (
				<Fragment>
					<h4>Product</h4>
					<div className="grid col-7" style={{gridTemplateColumns: '2fr 1fr 1fr 1fr 1fr 1fr 1fr'}}>
						<div className="grid-header">Name</div>
						<div className="grid-header text-center">Priority</div>
						<div className="grid-header text-center">Quantity</div>
						<div className="grid-header text-center">Days</div>
						<div className="grid-header text-center">Start Date</div>
						<div className="grid-header text-center">End Date</div>
						<div className="grid-header text-center">&nbsp;</div>
						{productRestrictions.map((restriction, index) => {
							return (
								<ProductRestriction {...props} 
									key={`pRes${index}`}
									curLanguage={props.curLanguage}
									organization_id={props.organization_id}
									product_id={props.product_id}
									restriction_id={restriction.restriction_id}
									details={props.details}
									restriction={restriction}
									actionAddEdit={addEditRestriction}
									actionRemove={remove} />
							)}
						)}
					</div>
				</Fragment>
			)}
			{groupRestrictions && Array.isArray(groupRestrictions) && groupRestrictions.length > 0 && (
				<Fragment>
					<h4>Groups</h4>
					<div className="grid col-7" style={{gridTemplateColumns: '2fr 1fr 1fr 1fr 1fr 1fr 1fr'}}>
						<div className="grid-header">Name</div>
						<div className="grid-header text-center">Priority</div>
						<div className="grid-header text-center">Quantity</div>
						<div className="grid-header text-center">Days</div>
						<div className="grid-header text-center">Start Date</div>
						<div className="grid-header text-center">End Date</div>
						<div className="grid-header text-center">&nbsp;</div>
						{groupRestrictions.map((restriction, index) => {
							return (
								<ProductRestriction {...props} 
									key={`gRes${index}`}
									curLanguage={props.curLanguage}
									organization_id={props.organization_id}
									product_id={props.product_id}
									restriction_id={restriction.restriction_id}
									details={props.details}
									restriction={restriction}
									actionAddEdit={addEditRestriction}
									actionRemove={remove} />
							)}
						)}
					</div>
				</Fragment>
			)}
			{userRestrictions && Array.isArray(userRestrictions) && userRestrictions.length > 0 && (
				<Fragment>
					<h4>Users</h4>
					<div className={`grid col-${props.editable ? '7' : '6'}`} style={{gridTemplateColumns: `${props.editable ? '2fr 1fr 1fr 1fr 1fr 1fr 1fr' : '2fr 1fr 1fr 1fr 1fr 1fr'}`}}>
						<div className="grid-header">Name</div>
						<div className="grid-header text-center">Priority</div>
						<div className="grid-header text-center">Quantity</div>
						<div className="grid-header text-center">Days</div>
						<div className="grid-header text-center">Start Date</div>
						<div className="grid-header text-center">End Date</div>
						{props.editable && <div className="grid-header text-center">&nbsp;</div>}
						{userRestrictions.map((restriction, index) => {
							return (
								<ProductRestriction {...props} 
									editable={props.editable}
									key={`uRes${index}`}
									curLanguage={props.curLanguage}
									organization_id={props.organization_id}
									product_id={props.product_id}
									restriction_id={restriction.restriction_id}
									details={props.details}
									restriction={restriction}
									actionAddEdit={addEditRestriction}
									actionRemove={remove} />
							)}
						)}
					</div>
				</Fragment>
			)}
		</div>
	)
}
