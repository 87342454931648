import React, {useEffect, useState, Fragment} from 'react';
import {Redirect} from 'react-router';

import Button from 'components/forms/elements/Button';
import IconToken from 'components/icons/IconToken';
import IconToggle from 'components/icons/IconToggle';
import Order from 'components/cart/CheckoutOrder';

import * as formData from 'methods/forms';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [userLocations, setUserLocations] = useState(null);
  const [project, setProject] = useState(null);
  const [order, setOrder] = useState(null);
  const [items, setItems] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [active, setActive] = useState(true);
  const [isDigital, setIsDigital] = useState(null);
  const [hidePricing, setHidePricing] = useState(null);
  
  // HOOKS
  useEffect(() => {
    if(props.userID && props.userID !== userID) {
      setUserID(props.userID);
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token && props.token !== token) {
      setToken(props.token);
    }
  }, [props.token]);
  
  useEffect(() => {
    if(props.organizationID && props.organizationID !== organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    if(props.languageCode && props.languageCode !== languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    if(Array.isArray(props.userLocations) && props.userLocations !== userLocations) {
      setUserLocations(props.userLocations);
    }
  }, [props.userLocations]);
  
  // useEffect(() => {
  //   if(props.StageComplete === true && active !== true) {
  //     setActive(true);
  //   }else if(active === true) {
  //     setActive(false);
  //   }
  // }, [props.stageComplete]);
  
  useEffect(() => {
    if(props.project && props.project !== project) {
      setProject(props.project);
    }
  }, [props.project]);
  
  useEffect(() => {
    if(props.order && props.order !== order) {
      setOrder(props.order);
    }
  }, [props.order]);
  
  useEffect(() => {
    if(order && Array.isArray(order.items) && 
        order.items !== items) {
      setItems(order.items);
    }  
  }, [order]);
  
  useEffect(() => {
    if(languageCode && Array.isArray(items)) {
      let orderDigital = true;
      items.map(item => {
        let itemDigital = false;
        if(Array.isArray(props.objGetValue(item, `details.${languageCode}.fieldsets`))) {
          item.details[languageCode].fieldsets.map(fieldset => {
            if(Array.isArray(fieldset.fields)) {
              const fieldDelivery = fieldset.fields.find(field => 
                                field.field_label && 
                                field.field_label.toLowerCase() === 'delivery');
              if(fieldDelivery && item.field_values[fieldDelivery.field_id] === 'digital') {
                itemDigital = true;
              }
            }
          })
        }
        if(!itemDigital && orderDigital) {
          orderDigital = false;
        }
        
        if(orderDigital && !isDigital) {
          setIsDigital(true);
        }else if (!orderDigital && isDigital) {
          setIsDigital(false);
        }
      });
    } 
  }, [items, languageCode]);
  
  useEffect(() => {
    if(isDigital) {
      // IF THE ORDER IS ALL DIGITAL: CHECK FOR A DIGITAL SHIPPING OPTION
      if(Array.isArray(props.shippingOptions) &&
        props.shippingOptions.find(shipOpt => shipOpt.option_name === "Digital") &&
        props.objGetValue(props.cart, `project.orders[${props.index}].shipping.order_shipping_method`) !== "Digital") {
        // IF A DIGITAL SHIPPING OPTION IS AVAILABLE, SET THIS FOR THE ORDER
        props.cartSetValue(`project.orders[${props.index}].shipping.order_shipping_method`, "Digital");
      }
    }else{
      if(props.objGetValue(props.cart, `project.orders[${props.index}].shipping.order_shipping_method`) === "Digital") {
        props.cartDeleteVal(`project.orders[${props.index}].shipping.order_shipping_method`); 
      }
      if(props.actionOrderType) {
        props.actionOrderType(true);
      }
    }  
  }, [isDigital, items]);
  
  useEffect(() => {
    if(props.hidePricing && hidePricing !== true) {
      setHidePricing(true);
    }
  }, [props.hidePricing]);

  function setShipping() {
    if(props.objExists(props.cart, 'project.billing') && (!props.objExists(props.cart, 'orders.shipping') ||
        !Array.isArray(props.cart.orders) ||
        props.cart.orders.length <= 0)) {
          props.objExists(props.cart, 'project.billing.contact') &&
          props.cartSetInfo(props.cart.project.billing.contact, 'project.orders[0].shipping.contact');

          props.objExists(props.cart, 'project.billing.location') &&
          props.cartSetInfo(props.cart.project.billing.location, 'project.orders[0].shipping.location');

    }else if(props.objExists(props.cart, 'project.billing')){
      props.cart.orders.map((order, index) => {
        props.cart.project.billing.contact &&!order.shipping.contact &&
        props.cartSetInfo(props.cart.project.billing.contact, `project.orders[${index}].shipping.contact`);

        // props.cart.project.billing.contact && !order.billing.contact &&
        // props.cartSetInfo(props.cart.project.billing.contact, `project.orders[${index}].billing.contact`);

        props.cart.project.billing.location &&!order.shipping.location &&
        props.cartSetInfo(props.cart.project.billing.location, `project.orders[${index}].shipping.location`);

        // props.cart.project.billing.location && !order.billing.location &&
        // props.cartSetInfo(props.cart.project.billing.location, `project.orders[${index}].billing.location`);
      })
    }
  }
  
  function loadAddEdit(type, location, index = null) {
    if(props.loadAddEdit) {
      props.loadAddEdit(type, location, index);
    }
  }
  
  return (
    <div className={`progressive-section${active ? ' active' : ''}`}>
      <div className="details-preview">
        <div className="contents">
          <div className="content-row">
            {props.order && 
              props.objExists(props.order, `shipping.location`) &&
              props.objExists(props.order, `shipping.contact`) &&
              <Fragment>
              <b>{`${props.objExists(props.order, `items`) && Array.isArray(props.order.items) ? props.order.items.length : 0} Item${props.objExists(props.order, `items`) && Array.isArray(props.order.items) && props.order.items.length === 1 ? '' : 's'}`}</b>{` shipping to: `}
              {props.objExists(props.order, `shipping.location.location_name`) ? 
                <b>{`${props.order.shipping.location.location_name}`}</b>
              : <b>{`${props.order.shipping.contact.contact_name_first} ${props.order.shipping.contact.contact_name_last}`}</b>
              }
              {` -  ${props.order.shipping.location.location_address_1}${props.order.shipping.location.location_address_2 ?
                    `, ${props.order.shipping.location.location_address_2}` : ''}, ${props.order.shipping.location.location_city}, ${props.order.shipping.location.location_province}`
              }
              {props.objExists(props.order, 'shipping.order_shipping_method') ? 
                      <b>{` :: ${props.capitalizeString(props.order.shipping.order_shipping_method, true)} Shipping`}</b> : ''}
              </Fragment>
            }
          </div>
        </div>
      </div>
      <div className="details-expand">
      {order && 
        <Order {...props} 
                userID={userID}
                token={token}
                organizationID={organizationID}
                languageCode={languageCode}
                userLocations={userLocations}
                project={project}
                order={order}
                index={props.index}
                isDigital={isDigital}
                active={active}
                location="cart"
                shippingOptions={props.shippingOptions}
                actionAddEdit={loadAddEdit}
                shippingRequired={props.shippingRequired}
                restrictLocations={props.restrictLocations}
                orderMultiShip={props.orderMultiship}
                hidePricing={hidePricing}
                onUpdate={props.onUpdate}
                errorFlags={props.errorFlags} /> }
      </div>
      {/* <div className="ctl-row">
        <IconToggle action={()=>{setActive(active ? false : true)}} tooltip="Details" />
      </div> */}
    </div>
  )
}
