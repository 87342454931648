import axios from 'axios';
import * as apis from 'methods/api.js';

const tmpPayOptions = [
  {organization_id: 1,
    public: ['credit_card', 'contact_payment'],
    account: ['invoice_account']},
  {organization_id: 2,
    public: ['credit_card', 'contact_payment'],
    account: ['invoice_account']},
  {organization_id: 3,
    account: ['invoice_account']},
  {organization_id: 4,
    account: ['invoice_account']},
  {organization_id: 5,
    account: ['invoice_account']},

]

const tmpUserPayOptions = [
  // {user_id: 1,
  //   options: ['invoice_direct'],
  //   restrictions: ['invoice_account']}
]

export const initSite = (location, userID, token) => dispatch => {
  let port = location.port;
  if(!port) {
    if(location.protocol === 'http:') {
      port = '80';
    }else if(location.protocol === 'https:') {
      port = '443';
    }
  }
  let formData = `process=get_site_persona_db&host_name=${location.hostname}&port_num=${port}`;
  if(userID && token) {
    formData += `&user_id=${userID}&token=${token}`;
  }
  axios.post(apis.api_url_site, formData).then(res => {
    dispatch({type: 'INIT_SITE', payload: res.data});
  }).catch(error => {
    console.log("INIT SITE ERROR:", error);
  })
}

export const initFeatures = (organization_id, language_code) => dispatch => {
  const formData = `process=get_features&organization_id=${organization_id}&language_code=${language_code}`;
  axios.post(apis.api_url_site, formData).then(res => {
    dispatch({type: 'INIT_FEATURES', payload: res.data});
  }).catch( error => {
    console.log("DISPATCH ERROR:", error);
    dispatch({type: 'DISPATCH_ERROR', payload: error});
  })
}

export const sitePayOptions = (organization_id, user_id, user_role) => dispatch => {
  console.log("action pay options", organization_id, user_id, user_role);
  let pay_options = [];
  const orgOptions = tmpPayOptions &&
                      Array.isArray(tmpPayOptions) && tmpPayOptions.length > 0 &&
                      tmpPayOptions.find(options => options.organization_id === parseInt(organization_id)) ?
                        tmpPayOptions.find(options => options.organization_id === parseInt(organization_id)) :
                          null;
  const userOptions = tmpUserPayOptions &&
                      Array.isArray(tmpUserPayOptions) && tmpUserPayOptions.length > 0 &&
                      tmpUserPayOptions.find(options => options.user_id === parseInt(user_id)) ?
                        tmpUserPayOptions.find(options => options.user_id === parseInt(user_id)) :
                          null;

  if(orgOptions && orgOptions.public && Array.isArray(orgOptions.public) && orgOptions.public.length > 0) {
    orgOptions.public.map(payOption => {
      pay_options.push(payOption);
    })
  }

  if(parseInt(user_id) > 0 &&
    Array.isArray(orgOptions.account) && orgOptions.account.length > 0) {
    orgOptions.account.map(payOption => {
      !pay_options.includes(payOption) &&
      pay_options.push(payOption);
    })
  }

  if(user_role === 'Admin' || user_role === 'Super User' &&
    Array.isArray(orgOptions.admin) && orgOptions.admin.length > 0) {
    orgOptions.admin.map(payOption => {
      !pay_options.includes(payOption) &&
      pay_options.push(payOption);
    })
  }

  if(parseInt(user_id) > 0 &&
      userOptions &&
      Array.isArray(userOptions.options) &&
      userOptions.options.length > 0) {
    userOptions.options.map(payOption => {
      !pay_options.includes(payOption) &&
      pay_options.push(payOption);
    })
  }

  if(parseInt(user_id) > 0 &&
      userOptions &&
      Array.isArray(userOptions.restrictions) &&
      userOptions.restrictions.length > 0) {
    userOptions.restrictions.map(banOption => {
      pay_options.map((payOption, index) => {
        payOption === banOption &&
        pay_options.splice(index, 1 );
      })
    })
  }
  dispatch({type: 'SITE_PAY_OPTIONS', pay_options: pay_options});
}

export const menuSetState = (menuState) => dispatch => {
  dispatch({
    type: 'SET_MENU_STATE',
    payload: menuState,
  })
};

export const notifyUser = (notification) => dispatch => {
  dispatch({
    type: 'SITE_SHOW_NOTIFICATION',
    payload: notification,
  })
}

export const removeNotification = (notification) => dispatch => {
  dispatch({
    type: 'SITE_REMOVE_NOTIFICATION',
    payload: (notification),
  })
}

export const removeStorageItem = (item, location) => dispatch => {
  dispatch({
    type: 'REMOVE_STORAGE_ITEM',
    item: item,
    location: location
  })
}

export const siteSetValue = (name, value, sStore = true) => dispatch => {
  dispatch({type: 'SITE_SET_VALUE', name: name, value: value, sStore: sStore});
}

export const siteRemoveValue = (location) => dispatch => {
  dispatch({type: 'SITE_DELETE_VAL', payload: location});
}

export const siteLoadState = (params, api, state_location, callback = null, cbApi = null) => dispatch => {
  var formData = new FormData();

  Array.isArray(params) &&
  params.map (param => {
     if(param.name && param.value) {
      formData.append(param.name, param.value);
     }
  })

  if(apis.getReqUrl(api)) {
      axios.post(apis.getReqUrl(api), formData).then(res => {
        if(callback && Array.isArray(callback) && callback.length > 0) {
          var callbackData = new FormData();
          callback.map (param => {
            if(param.name && param.value) {
              if(param.value === 'result') {
                callbackData.append(param.name, res.data);
              }else{
                callbackData.append(param.name, param.value);
              }
            }
          })
          axios.post(apis.getReqUrl(cbApi ? cbApi : api), callbackData).then(cbRes => {

            dispatch({type: 'SITE_LOAD_STATE', location: state_location, payload: cbRes.data});
          }).catch(error => {
            console.log("DISPATCH ERROR LOAD CALLBACK", error);
          })
        }else{
          dispatch({type: 'SITE_LOAD_STATE', location: state_location, payload: res.data});
        }
      }).catch(error => {
        console.log("DISPATCH ERROR - LOAD STATE", apis.getReqUrl(api), error);
      })
    }else{
      // console.log("There's no reqUrl", api, reqUrl);
    }
 }
