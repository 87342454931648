import React, {useEffect, useState} from 'react';

import Switch from 'components/forms/elements/Switch';
import OrganizationOrderTask from 'components/profiles/orgs/OrganizationOrderTask';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [languageID, setLanguageID] = useState(null);
	const [userAccess, setUserAccess] = useState(null);
	const [coreOrg, setCoreOrg] = useState(null);
	const [taskList, setTaskList] = useState(null);
	const [taskSettings, setTaskSettings] = useState(null);
	
	// HOOKS
	useEffect(() => {
		props.userID &&
		props.userID !== userID &&
		setUserID(props.userID);
	}, [props.userID]);
	
	useEffect(() => {
		props.token &&
		props.token !== token &&
		setToken(props.token);
	}, [props.token])
	
	useEffect(() => {
		props.organizationID &&
		props.organizationID !== organizationID &&
		setOrganizationID(props.organizationID);
	}, [props.organizationID]);
	
	useEffect(() => {
		if(props.languageCode &&
			props.languageCode	!== languageCode) {
			setLanguageCode(props.languageCode);
		}else if(!props.languageCode && !languageCode) {
			setLanguageCode('en');
		}
		
	}, [props.languageCode]);
	
	useEffect(() => {
		if(languageCode) {
			const lID = props.getLanguageId(languageCode);
			if(lID !== languageID) {
				setLanguageID(lID);
			}	
		}
	}, [languageCode]);
	
	useEffect(() => {
		if(props.userAccess && props.userAccess !== userAccess) {
  			setUserAccess(props.userAccess);
		} 
	}, [props.userAccess]);
	
	useEffect(() => {
		if(props.coreOrg && props.coreOrg !== coreOrg) {
			setCoreOrg(props.coreOrg);
		}
	}, [props.coreUser]);
	
	useEffect(() => {
		if(props.taskList && props.taskList !== taskList) {
			setTaskList(props.taskList) 
		}
	}, [props.taskList]);
	
	return (
		<div>
			<div className="info-block">
				Order Tasks are default tasks that are automatically set upon order submition. <b>Ignored</b> tasks will be skipped. <b>Published</b> tasks are pre-activated and imediately assigned. Unpublished tasks will be automatically activated as tasks ahead of them are completed - unless the unpublished task is set to be 'ignored'.
			</div>
			
			{Array.isArray(taskList) &&
				taskList.map((task, index) => {
					return (
						<OrganizationOrderTask {...props}
							key={`rTskRw${index}`}
							userId={userID}
							token={token}
							languageCode={languageCode}
							organizationID={organizationID}
							task={task} />
					)
				})
			}
		</div>
	)
}