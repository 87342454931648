import React, {useEffect, useState, Fragment} from 'react';
import {useParams, useLocation} from 'react-router';

import User from 'components/profiles/users/User';
import Loader from 'components/atoms/Loader';

import * as forms from 'methods/forms';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [coreUser, setCoreUser] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [userRequested, setUserRequested] = useState(false);
  const [user, setUser] = useState(null);
  const [userGroups, setUserGroups] = useState(null);
  
  const pgParams = useParams();
  const location = useLocation();
  
  let ivlUser;
  
  useEffect(() => {
    return() => {
      props.adminDeleteVal('user');
      if(ivlUser) {
        clearInterval(ivlUser);
      }
    }
  }, []);
  
  // HOOKS
  useEffect(() => {
    if(props.userID && props.userID !== userID) {
      setUserID(props.userID)
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token && props.token !== token) {
      setToken(props.token)
    }
  }, [props.token]);

  useEffect(() => {
    if(props.organizationID && organizationID !== props.organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    props.organizationName &&
    props.organizationName !== organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);
  
  useEffect(() => {
    if(props.languageCode && languageCode !== props.languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    if(pgParams.component && 
      coreUser !== parseInt(pgParams.component)) {
      setCoreUser(parseInt(pgParams.component));
    }
  }, [pgParams])
  
  useEffect(() => {
    if(props.userAccess && props.userAccess !== userAccess) {
      setUserAccess(props.userAccess);
    } 
  }, [props.userAccess]);
  
  useEffect(() => {
    if(Array.isArray(props.objGetValue(props.admin, `groups.user`))) {
      let uGroups = {};
      props.admin.groups.user.map((uGroup, index) => {
        if(props.objExists(uGroup, `organization_name`)) {
          if(Array.isArray(props.objGetValue(uGroups, `${uGroup.organization_name}`))) {
            uGroups[uGroup.organization_name].push(uGroup);
          }else{
            uGroups[uGroup.organization_name] = [uGroup];
          }
        }
      })
      if(Object.entries(uGroups).length > 0) {
        setUserGroups(uGroups)  
      }
    }
  }, [props.admin.groups]);

  useEffect(() => {
    if(userID && token && coreUser && organizationID) {
      let params =  [
                      {name: 'process', value: 'get_users'},
                      {name: 'user_id', value: userID},
                      {name: 'token', value: token},
                      {name: 'core_user', value: coreUser},
                      {name: 'organization_id', value: organizationID}
                    ];
      
      if(!userRequested || 
            !props.objGetValue(props.admin, 'user.user_id') !== coreUser || 
            props.objGetValue(props.admin, 'reload.user') === true) {
          props.adminLoadState(params, 'users', 'user');
          if(props.objExists(props.admin, 'reload.user')) {
            props.adminSetVal('reload.users', true);
            props.adminDeleteVal('reload.user');
          }
          setUserRequested(true);
          if(ivlUser) {
            clearInterval(ivlUser);
          }
          ivlUser = setInterval(() => {
            if(document.visibilityState === 'visible') {
              props.adminLoadState(params, 'users', 'user');
              setUserRequested(true);
            }
            
          }, props.minToMS(60));
      }
    }
  }, [userID, token, pgParams.component, userRequested, props.admin.reload, organizationID]);


  useEffect(() => {
    props.objExists(props.admin, 'user') &&
    setUser(props.admin.user);
  }, [props.admin.user]);


  // FUNCTIONS

  return(
      <Fragment>
        {userID && user ?
          <User {...props} 
                isAdmin={true}
                userID={userID}
                token={token}
                organizationID={organizationID}
                organizationName={organizationName}
                coreUser={user}
                orgGroups={userGroups}
                stateLocation="admin"  />
            :
          <Loader />
        }
      </Fragment>
  )
}
