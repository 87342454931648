import React from 'react';
// import React,{useState, useEffect} from 'react';

import IconSVG from 'components/icons/IconSVG';

// <span onClick={props.slideStop}>Stop</span>

export default (props) => {
  return (
    <div className="slide-show-ctl">
      {props.slides && props.slides.length > 0 &&
        props.slides.map((slide, index) => {
          return <IconSVG key={`slideshowctl${index}`} icon="elipse" />
        })
      }
    </div>
  )
}
