import React, {useEffect, useState} from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import Loader from 'components/atoms/Loader';
import FormDynamic from 'components/forms/FormDynamic';
import FileUpload from 'components/forms/elements/FileUpload';
import Input from 'components/forms/elements/Input';
import Button from 'components/forms/elements/Button';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [fileName, setFileName] = useState(null);
	const [fileVersion, setFileVersion] = useState(null);
	const [uploadName, setUploadName] = useState(null);
	const [fileTag, setFileTag] = useState(null);
	const [fileOwnerType, setFileOwnerType] = useState(null);
	const [fileOwnerID, setFileOwnerID] = useState(null);
	const [disableUpload, setDisableUpload] = useState(true);
	const [enableSave, setEnableSave] = useState(null);
	const [uploadNow, setUploadNow] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(props.userID && 
			props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && 
			props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.languageCode && 
			props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.organizationID && 
			props.organizationID !== organizationID) {
			setOrganizationID(props.organizationID);
		}
	}, [props.organizationID]);
	
	
	useEffect(() => {
		if(props.fileOwnerType && 
			props.fileOwnerType !== fileOwnerType) {
			setFileOwnerType(props.fileOwnerType);
		}
	}, [props.fileOwnerType]);
	
	useEffect(() => {
		if(props.fileOwnerID && 
			props.fileOwnerID !== fileOwnerID) {
			setFileOwnerID(props.fileOwnerID);
		}
	}, [props.fileOwnerID]);
	
	useEffect(() => {
		if(fileName) {
			let ulName = fileName.replaceAll(' ', '_');
			
			if(props.media_type === 'proof') {
				ulName += '_proof';
			}
			if(fileVersion) {
				ulName += `_rev${fileVersion}`;
			}
			if(ulName !== uploadName) {
				setUploadName(ulName);
			}
			
		}else if(uploadName) {
			setUploadName(null);
		}
	}, [fileName, fileVersion]);
	
	useEffect(() => {
		if(fileTag && uploadName) {
			if(!uploadName.includes(`.${fileTag}`)) {
				if(uploadName.search('.') < 0) {
					setUploadName(`${uploadName}.fileTag`);
				}else{
					const nameNodes = uploadName.split('.');
					setUploadName(`${nameNodes[0]}.${fileTag}`);
				}
			}
		}
	}, [uploadName, fileTag]);
	
	useEffect(() => {
		if(uploadName) {
			setDisableUpload(false);
		}else if (!uploadName && disableUpload) {
			setDisableUpload(false);
		}
	}, [uploadName]);
	
	// FUNCTIONS
	function updateFileName(param) {
		if(param.value !== fileName) {
			setFileName(param.value);
		}
	}
	
	function updateFileVersion(param) {
		if(parseInt(param.value) !== fileVersion) {
			setFileVersion(parseInt(param.value));
		}
	}
	
	function uploadFile(params) {
		if(props.actionUpload) {
			props.actionUpload(params);
		}
	}
	
	function saveUpload() {
		setUploadNow(true);
	}
	
	function handleCancel(e) {
		props.setModal(null);
		props.objExists(props, 'actionCancel') &&
		props.actionCancel();
	}
	
	function setSelected(selected) {
		if(selected.name) {
			setEnableSave(true);
			if(selected.tag) {
				setFileTag(selected.tag);
			}
		}else if(!selected.name) {
			if(fileTag) {
				setFileTag(null);
			}
			if(enableSave) {
				setEnableSave(false);
			}
		}
	}
	
	return (
		<div className="form">
			<p>File Upload Name: {uploadName}</p>
			<div className="form-row">
				<Input {...props}
					name="media_name"
					label="File Name"
					defaultValue={props.fileName}
					onChange={updateFileName}
					/>
			</div>
			<div className="form-row">
				<Input {...props}
					type="number"
					name="fileVersion"
					label="File Version"
					defaultValue={props.fileVersion}
					onChange={updateFileVersion}
					/>
			</div>
			
			<FileUpload {...props}
				userID={userID}
				token={token}
				languageCode={languageCode}
				organizationID={organizationID}
				media_type={props.media_type}
				media_published={true}
				media_owner_type={props.media_owner_type}
				media_owner_id={props.media_owner_id}
				media_file_upload_name={uploadName}
				return_boolean='true'
				stateLocation={props.stateLocation ? props.stateLocation : 'dashboard'}
				disableUpload={disableUpload}
				actionUpload={{action:uploadFile, params:props.uploadParams}}
				actionSelect={setSelected}
				cueUpload={uploadNow}
				/>
			{props.includeCtl &&
				<div className="form-row">
					<Button icon="close" label="Cancel" btnAction={handleCancel} />
					<Button icon="close" label="Save" btnClass="success-bg" btnAction={saveUpload} disabled={enableSave ? false : true} />
				</div>
			}
		</div>
	)
}