import axios from 'axios';
import * as apis from 'methods/api.js';

export const dashboardSetVal = (name, value) => dispatch => {
  dispatch({type: 'DASHBOARD_SET_VALUE', name: name, value: value});
}

export const dashboardDeleteVal = (location) => dispatch => {
  dispatch({type: 'DASHBOARD_DELETE_VAL', payload: location});
}

export const dashboardLoadFromSession = (name, value) => dispatch => {
  dispatch({type: 'DASHBOARD_LOAD_FROM_SESSION', name: name, payload: value});
}

export const dashboardLoadState = (params, api, state_location, callback = null, cbApi = null) => dispatch => {
  var formData = new FormData();

  Array.isArray(params) &&
  params.map (param => {
     if(param.name && param.value !== null) {
      formData.append(param.name, param.value);
     }
  })

  if(apis.getReqUrl(api)) {
      axios.post(apis.getReqUrl(api), formData).then(res => {
        if(callback && Array.isArray(callback) && callback.length > 0) {
          var callbackData = new FormData();
          callback.map (param => {
            if(param.name && param.value) {
              if(param.value === 'result') {
                callbackData.append(param.name, res.data);
              }else{
                callbackData.append(param.name, param.value);
              }
            }
          })
          axios.post(apis.getReqUrl(cbApi ? cbApi : api), callbackData).then(cbRes => {

            dispatch({type: 'DASHBOARD_LOAD_STATE', location: state_location, payload: cbRes.data});
          }).catch(error => {
            console.log("DISPATCH ERROR LOAD CALLBACK", error);
          })
        }else{
          dispatch({type: 'DASHBOARD_LOAD_STATE', location: state_location, payload: res.data});
        }
      }).catch(error => {
        console.log("DISPATCH ERROR - LOAD STATE", apis.getReqUrl(api), error);
      })
    }else{
      // console.log("There's no reqUrl", api, reqUrl);
    }
 }