import React, {useEffect, useState} from 'react';

import TaskMatrixItem from 'components/profiles/tasks/TaskMatrixItem';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [canEdit, setCanEdit] = useState(null);
	const [isSuper, setIsSuper] = useState(null);
	const [tasks, setTasks] = useState(null);
	  
	
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.organizationID && props.organizationID !== organizationID) {
			setOrganizationID(props.organizationID);
		}
	}, [props.organizationID]);
	
	useEffect(() => {
		if(props.languageCode && props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}else if(!languageCode) {
			setLanguageCode('en');
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		props.canEdit &&
		props.canEdit !== canEdit &&
		setCanEdit(props.canEdit);
	}, [props.canEdit]);
	  
	useEffect(() => {
		props.isSuper &&
		props.isSuper !== isSuper &&
		setIsSuper(props.isSuper);
	}, [props.isSuper]);
	
	useEffect(() => {
		if(props.tasks &&
			Array.isArray(props.tasks) &&
			props.tasks.length > 0 && 
			props.tasks !== tasks) {
			
			setTasks(props.tasks);	
		}
		console.log("HERE's THE TASKS", props.tasks);
	}, [props.tasks]);
	
	return (
		<div className="grid">
			<h3>{`Project Tasks`}</h3>
			{tasks &&
				tasks.map((task, index) => {
					return (
						<TaskMatrixItem {...props}
							userID={userID}
							token={token}
							organizationID={organizationID}
							languageCode={languageCode}
							canEdit={canEdit}
							isSuper={isSuper}
							task={task}
							key={`tdkMtxItm-${index}`}
							index={index} />
					)
				})
			}
		</div>
	)
}