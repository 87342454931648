import React, {useEffect, useState} from 'react';

import * as utils from 'methods/site';
import MediaCollection from 'components/profiles/media/MediaCollection';
import Button from 'components/forms/elements/Button';
import Card from 'components/atoms/Card';

export default (props) => {
  props = {...props.children, ...utils}
  const [request, setRequest] = useState(null);
  const [user, setUser] = useState(null);
  const [location, setLocation] = useState(null);

  useEffect(() => {
    if(props.cart && !props.cart.customItems) {
      if(sessionStorage.getItem('crtCSTM') &&
        sessionStorage.getItem('crtCSTM') !== 'undefined') {
          props.cartSetValue('customItems', JSON.parse(sessionStorage.getItem('crtCSTM')));
      }
    }

    if(props.objExists(props.cart, 'customItems') &&
      Array.isArray(props.cart.customItems) &&
      props.cart.customItems.length > 0) {
        setRequest(props.cart.customItems[0]);
      }
  }, [props.cart.customItems]);

  useEffect(() => {
    if(props.user) {
      setUser(props.user);

      if(props.objExists(props.user, 'locations') &&
          Array.isArray(props.user.locations) &&
          props.user.locations.length > 0) {
            if(props.user.locations.find(loc => loc.location_default === '1')) {
              setLocation(props.user.locations.find(loc => loc.location_default === '1'));
            }else{
              setLocation(props.user.locations[0]);
            }
      }
    }
  }, [props.user])

  console.log("USER?", request);
  return(
    <div className="profile request">
      <div className="card">
        <h2>We're On It!</h2>
        <p style={{margin: '0em'}}>
          We have recieved your request and are working on your {request && request.request_quote ? 'Quote' : 'Project'}.
          We'll update you shortly and let you knnow if there's anything else we need
        </p>
      </div>

      {user && user.contact &&
        <Card>
          <div className="card-row">
            <div className="card-block">
              <div className="card-row">
                <label>Ordered by</label>
                {user.contact.contact_name_first} {user.contact.contact_name_last}
              </div>
              <div className="card-row">
                <label>Email</label>
                {user.contact.contact_email}
              </div>
              <div className="card-row">
                <label>Phone</label>
                {user.contact.contact_phone}
              </div>
            </div>
            {location &&
              <div className="card-block">
                <div className="card-row">
                  <label>Ship to</label>
                  <div className="card-block">
                    <p>
                      {location.location_address_1}{location.location_address_2 ? ` - ${location.location_address_2}` : ''},
                    </p>
                    <p>
                      {location.location_city}, {location.location_province}
                    </p>
                    <p>
                      {location.location_country}  {location.location_postal_code}
                    </p>
                  </div>
                </div>
              </div>
            }
          </div>
        </Card>
      }

      {request && request.details.en.product_name &&
        <Card title='Print Request'>
          <div className="card-block">
            <div className="card-row">
              <label>File Name</label>
              {request.details.en.product_name}
            </div>
            <div className="card-row">
              <label>Qty</label>
              {request.product_quantity}
            </div>
          </div>
          <div className="card-block">
            {request.details.en.properties_object &&
              Object.entries(request.details.en.properties_object).length > 0 &&
              Object.entries(request.details.en.properties_object).map((prop, index) => {
                return <div className="card-row">
                  <label>{prop[0].replace('_', ' ')}</label>
                  {prop[1]}
                </div>;
              })
            }
          </div>
          <div className="card-block">
            <label>Required by</label>
            {props.dateDisplay(request.date_required)}
          </div>
          <div className="card-block">
            <MediaCollection media={request.details.en.media} />
          </div>
        </Card>
      }

    </div>
  )
}
