import React, {useEffect, useState } from 'react';

import Form from 'components/forms/Form';

import FormProgressiveField from 'components/molecules/FormProgressiveField';

export default (props) => {
  const [progressiveFields, setProgressiveFields] = useState(null);
  const [activeField, setActiveField] = useState(null);
  const [activeEdit, setActiveEdit] = useState(false);

  useEffect(() => {
    let active = null;
    props.fieldsets &&
    Array.isArray(props.fieldsets)
    && props.fieldsets.length > 0 &&
    props.fieldsets.map(fieldset => {
      fieldset.fields &&
      Array.isArray(fieldset.fields) &&
      fieldset.fields.length > 0 &&
      fieldset.fields.map((field, index) => {
        if(((!field.field_value || field.field_value === '') &&
          (!field.field_default || field.field_default === '')) &&
          (null === active) && !activeEdit) {
            active = index;
        }
      })
    })
    if(null !== active) {
      setActiveField(active);
      setActiveEdit(true);
    }
  }, [props.fieldsets]);

  useEffect(() => {
    console.log("Are we seeing this change?", props.activeField);
    props.activeField &&
    makeActive(props.activeField);

  }, [props.activeField])

  useEffect(() => {
    let fields = []
    let active = null;
    props.fieldsets &&
    Array.isArray(props.fieldsets)
    && props.fieldsets.length > 0 &&
    props.fieldsets.map(fieldset => {

      fieldset.fields &&
      Array.isArray(fieldset.fields) &&
      fieldset.fields.length > 0 &&
      fieldset.fields.map((field, index) => {

        fields.push(<FormProgressiveField {...props} {...field}
                                          active={activeField === index ? true : false}
                                          actions={{activate: makeActive,
                                                    cancel: cancelEdit,
                                                    confirm: confirmEdit,
                                                    updateField: updateField,
                                                    updateValue: updateValue}}
                                          index={index}
                                          key={`prgFld${index}`} />)
      })
    })

    setProgressiveFields(fields);
  }, [activeField]);

  function makeActive(index) {
    if(index === 'next') {
      let nextIndex = activeField + 1;
      if(progressiveFields[nextIndex]) {
        while(progressiveFields[nextIndex] &&
              !progressiveFields[nextIndex].props.field_value) {
          nextIndex++;
          index = activeField + 1;
        }
      }else{
        index = null;
      }

    }
    console.log("Index is at", index);
    if(index >= 0) {
      console.log("setting index to: ", index);
      setActiveEdit(true);
      setActiveField(index);
    }
  }

  function cancelEdit() {
    setActiveEdit(false);
    setActiveField(null);
  }

  function confirmEdit() {
    console.log("confirming edit");
    setActiveEdit(false);
    setActiveField(null);
  }

  function updateField(e) {
    // console.log(e.target);
  }

  function updateValue(field) {
    props.cartSetInfo(field.value, field.name);
  }

  return(
    <Form action={[]}>
      {progressiveFields}
    </Form>
  )
}
