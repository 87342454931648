import React, {useEffect, useState} from 'react';

import FormDynamic from 'components/forms/FormDynamic';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [productFields, setProductFields] = useState(null);
	const [fieldOptions, setFieldOptions] = useState(null);
	const [shell, setShell] = useState(null);
	const [page, setPage] = useState(null);
	const [group, setGroup] = useState(null);
	const [pageFields, setPageFields] = useState(null);
	const [fieldsets, setFieldsets] = useState(null);
	const [form, setForm] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.languageCode && props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.page && props.page !== page) {
			setPage(props.page);
		}
	}, [props.page]);
	
	useEffect(() => {
		if(Array.isArray(props.objGetValue(page, `fields`)) &&
			page.fields.length > 0 &&
			page.fields !== pageFields) {
				setPageFields(page.fields);
			}
	}, [page]);
	 
	useEffect(() => {
		if(Array.isArray(props.productFields) &&
			props.productFields.length > 0 &&
			props.productFields !== productFields) {
				setProductFields(props.productFields);
			}
	}, [props.productFields]);
	
	useEffect(() => {
		if(props.group && props.group !== group) {
			setGroup(props.group);
		}
	}, [props.group]);
	
	useEffect(() => {
		if(Array.isArray(productFields)) {
			let fOptions = [];
			productFields.map(prField => {
				fOptions.push(
					{
						option_name: prField.field_label,
						option_value: prField.field_id
					}
				)
			});
			if(fOptions.length > 0 && 
				fOptions !== fieldOptions) {
				setFieldOptions(fOptions);	
			}
		}	
	}, [productFields, pageFields]);
	
	useEffect(() => {
		if(fieldOptions) {
			let fFieldsets = [{
				fieldset_name: 'Assign Field',
				fields: [
					{
						field_type: 'select',
						field_name: 'field_id',
						field_label: 'Form Field',
						option_groups: [{options: fieldOptions}],
						field_required: true,
						field_validate: true,
						field_order: 1
					},
					{
						field_type: 'hidden',
						field_name: 'process',
						field_value: 'add_shell_field'
					},
					{
						field_type: 'hidden',
						field_name: 'user_id',
						field_value: userID
					},
					{
						field_type: 'hidden',
						field_name: 'token',
						field_value: token
					},
					{
						field_type: 'hidden',
						field_name: 'shell_id',
						field_value: shell.shell_id
					},
					{
						field_type: 'hidden',
						field_name: 'page_id',
						field_value: page.page_id
					},
					{
						field_type: 'hidden',
						field_name: 'group_id',
						field_value: group ? group.group_id : null
					},
					{
						field_type: 'hidden',
						field_name: 'field_publish',
						field_value: 1
					},
					{
						field_type: 'hidden',
						field_name: 'return_bool',
						field_value: 1
					},
				]
			}];
			
			if(group) {
				fFieldsets[0]['fields'].push(
					{
						field_type: 'number',
						field_name: 'field_order',
						field_label: 'Field Order',
						field_value: Array.isArray(group.fields) ?
										group.fields.length + 1 : 1,
						field_required: true,
						field_validate: true,
						field_order: 2
					}
				);
			}
			
			if(fFieldsets !== fieldsets) {
				setFieldsets(fFieldsets);
			}
			
		}
	}, [fieldOptions, page, shell]);
	
	useEffect(() => {
		if(fieldsets) {
			setForm(
				<FormDynamic {...props}
					content={fieldsets}
					returnObject={true}
					formActions={{
						formSubmit: props.actionSubmit,
						formCancel: props.actionCancel
					}} />
			)
		}
	}, [fieldsets]);
	
	
	useEffect(() => {
		if(props.shell && props.shell !== shell) {
			setShell(props.shell);
		}
	}, [props.shell]);
	
	return (
		<div className="border-block">
			{form}
		</div>
	)
}