import React, {useEffect, useState, Fragment} from 'react';

import IconSVG from 'components/icons/IconSVG';
import IconToken from 'components/icons/IconToken';
import Switch from 'components/forms/elements/Switch';
import FormShippingOptionAddEdit from 'components/forms/common_forms/FormShippingOptionAddEdit';
import ModalConfirmation from 'components/molecules/ModalConfirmation';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [userAccess, setUserAccess] = useState(null);
	const [itemClass, setItemClass] = useState(null);
	const [isUnpublished, setIsUnpublished] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
		  setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token !== token) {
		  setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.organizationID && organizationID !== props.organizationID) {
		  setOrganizationID(props.organizationID);
		}
	}, [props.organizationID]);
	
	useEffect(() => {
		if(props.languageCode && languageCode !== props.languageCode) {
		  setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.userAccess && props.userAccess !== userAccess) {
		  setUserAccess(props.userAccess);
		} 
	}, [props.userAccess]);
	
	// FUNCTIONS
	function loadEditOption() {
		props.setModal(
			<FormShippingOptionAddEdit {...props}
			  userID={userID}
			  token={token}
			  organizationID={organizationID}
			  languageCode={languageCode}
			  userAccess={userAccess}
			  shipping={props.shipping}
			  coreOrg={null}
			  actionCancel={()=>{props.setModal(null)}}
			  actionAddEdit={editShippingOption} />
		)
	}
	
	function editShippingOption(params) {
		props.setModal(null);
		props.adminLoadState(params, 'organizations', 'reload.shippingOptions');
	}
	
	function loadRemoveOption() {
		props.setModal(
			<ModalConfirmation {...props}
				message="TODO: REMOVE FROM CORE OR ORG"
				actionAccept={removeOption} />
		)
	}
	
	function removeOption() {
		props.setModal(null);
		console.log("TODO REMOVE OPTION");
	}
	
	function setPublished(params) {
		console.log("Setting Published", params);
	}
	
	return (
		props.shipping &&
		<Fragment>
			<div className={`text-center${itemClass ? itemClass : ''} icon`}>
				<IconSVG icon={`${isUnpublished ? 'hide' : 'eye'}`} tooltip={`${isUnpublished ? 'Hidden' : 'Published'}`} />
			</div>
			<div className={`${itemClass ? itemClass : ''}`}>
				{props.capitalizeString(props.shipping.option_name, true)}
			</div>
			<div className={`${itemClass ? itemClass : ''}`}>
				{props.shipping.option_description}
			</div>
			<div className={`${itemClass ? itemClass : ''}`}>
				<div className="ctl">
					{(props.shipping.option_published || 
					props.shipping.option_published === 0) &&
						<Switch label="Publish" 
								name="option_published"
								value={props.shipping.option_published ? true : false} 
								onChange={setPublished} />
					}
					<IconToken className="btn" icon="edit" action={loadEditOption} />
					<IconToken className="btn" icon="trash" action={loadRemoveOption} />
				</div>
			</div>
		</Fragment>
	)
}