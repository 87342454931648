import React, { useEffect, useState } from 'react';

import RadioBtn from 'components/forms/elements/RadioBtn';
import Button from 'components/forms/elements/Button';

function moveItem() {
  console.log("Move it man!");
}

export default (props) => {
  const [itemOrder, setItemOrder] = useState(props.orderIndex);

  function moveItem() {
    console.log("You have selected to move the time to order ", props.itemIndex, itemOrder+1, props.item);
    props.cartMoveItem(props.item, props.orderIndex, props.itemIndex, itemOrder);
    props.setModal(null);
  }

  useEffect(() => {
    console.log("move the item to Order ", itemOrder+1);
  }, [itemOrder])

  return (
    <fieldset>
      <legend>Move Items</legend>
      <h3>{props.item.product_name}</h3>
      <p>will ship to:</p>
      {props.objExists(props.cart, 'project.orders') &&
        props.cart.project.orders.map((order, index) => {
          return (
            <div className="form-row">
              <RadioBtn label={order.shipping.location.location_name ? order.shipping.location.location_name : `Order ${index+1}`}
                        value={index}
                        selectAction={()=>{setItemOrder(index)}}
                        selected={props.orderIndex === index ? true : false}
                        group="move_to_order"
                        labelDescription={ [`${order.shipping.location.location_address_1}${order.shipping.location.location_address_2 ? ` - ${order.shipping.location.location_address_2}` : ''},`,
                                            `${order.shipping.location.location_city}, ${order.shipping.location.location_province}  ${order.shipping.location.location_postal_code}`]}
                        labelTruncate={false} />
            </div>
            )
      })
    }
    <div className="form-row">
      <Button label="cancel" icon="cancel" btnAction={()=>{props.setModal(null)}}  />
      <Button label="Move Item" icon="check" btnClass="success-bg" btnAction={moveItem} />
    </div>
    </fieldset>
  )
}
