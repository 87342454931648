import React, {useEffect, useState, Fragment} from 'react';

import FormDynamic from 'components/forms/FormDynamic';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [productID, setProductID] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [fieldsets, setFieldsets] = useState(null);
	const [form, setForm] = useState(null);
	const [operationType, setOperationType] = useState(null);
	const [qty, setQty] = useState(null);
	const [newQty, setNewQty] = useState(null);
	
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
			setUserID(props.userID)
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.organizationID && props.organizationID !== organizationID) {
			setOrganizationID(props.organizationID);
		}
	}, [props.organizationID]);
	
	useEffect(() => {
		if(props.langaugeCode) {
		  setLanguageCode(props.langaugeCode);
		}else{
		  setLanguageCode('en');
		}
	  }, [props.langaugeCode]);
	
	useEffect(() => {
		if(props.productID && props.productID !== productID) {
			setProductID(props.productID);
		}
	}, [props.productID]);
	
	useEffect(() => {
		if(userID && token && productID) {
			setFieldsets([
				{fieldset_name: 'Add Inventory',
					fields: [
						{field_type: 'hidden',
						field_name: 'user_id',
						field_value: userID,
						},
						{field_type: 'hidden',
						field_name: 'token',
						field_value: token,
						},
						{field_type: 'hidden',
						field_name: 'organization_id',
						field_value: organizationID,
						},
						{field_type: 'hidden',
						field_name: 'product_id',
						field_value: productID,
						},
						{field_type: 'radio',
						field_name: 'inventory_operation',
						field_label: 'Operation',
						field_value: null,
						field_required: true,
						field_validate: true,
						option_groups: [
							{options: [
								{option_name: 'Add', option_value: 'add'},
								{option_name: 'Remove', option_value: 'subtract'}
							]}
						],
						fieldOnChange: handleOperationType,
						},
						{field_type: 'number',
						field_name: 'item_quantity',
						field_label: 'Product Qty',
						field_value: null,
						field_required: true,
						field_validate: true,
						fieldOnChange: handleQtyInput
						},
					]
				}
			]);
		}
	}, [userID, token, organizationID, productID, operationType]);
	
	useEffect(() => {
		if(fieldsets) {
			setForm(
				<FormDynamic
				  content={fieldsets}
				  name="add-inventory"
				  returnObject={true}
				  cancelable={props.cancelable}
				  fieldOnChange={null}
				  fieldOnLoad={null}
				  formActions={{formCancel: handleCancel, formSubmit: handleAdd}} />
			)
		}
	}, [fieldsets]);
	
	useEffect(() => {
		if(parseInt(qty) > 0 && operationType) {
			let mathQty = parseInt(props.product.details[languageCode].product_stock);
			if(operationType === 'add') {
				mathQty += parseInt(qty);
			}else if(operationType === 'subtract') {
				mathQty -= parseInt(qty);
			}
			
			if(mathQty !== parseInt(props.product.details[languageCode].product_stock)) {
				setNewQty(mathQty);
			}
		}
	}, [props.product, languageCode, qty, operationType]);
	
	// FUNCTIONS
	function handleAdd(params) {
		if(props.actionAdd) {
			props.actionAdd(params);
		}
	}
	
	function handleCancel() {
		props.setModal(null);
	}
	
	function handleOperationType(params) {
		setOperationType(params.value);
	}
	
	function handleQtyInput(param) {
		setQty(param.value)
	}
	
	console.log("STOCK", props.product);
	return (
		<Fragment>
			{props.objGetValue(props.product, `details.${languageCode}.product_stock`) > 0 &&
				<Fragment>
					<div>Current: {props.product.details[languageCode].product_stock} {props.product.details[languageCode].product_bundle > 0 ? `bundle${props.product.details[languageCode].product_stock > 1 || props.product.details[languageCode].product_stock === 0 ? 's' : ''} of ${props.product.details[languageCode].product_bundle}` : 'pieces'}
					</div>
					<div>{newQty && 
						<Fragment>
							Updated: <b>{newQty} {props.product.details[languageCode].product_bundle > 0 ? `bundle${props.product.details[languageCode].product_stock > 1 || props.product.details[languageCode].product_stock === 0 ? 's' : ''} of ${props.product.details[languageCode].product_bundle}` : 'pieces'}</b>
						</Fragment>
						}
					</div>
				</Fragment>
			}
			{form}
		</Fragment>
	)
}