import axios from 'axios';
import * as apis from 'methods/api.js';



export const cartItemCount = () => dispatch => {
  dispatch({type: 'CART_ITEM_COUNT'});
}

export const cartAddItem = (product, orderIndex = 'cart') => dispatch => {
  dispatch({type: 'CART_ADD_ITEM', payload: product, orderIndex: orderIndex});
  dispatch({type: 'SITE_SHOW_NOTIFICATION', payload: {icon: 'cart', 
                                                        title: 'Success!', 
                                                        message: 'You have added this item to your cart.'}});
}

export const cartMergeItem = (product, duplicate, value) => dispatch => {
  dispatch({type: 'CART_MERGE_ITEM', payload: product, duplicate: duplicate});
  dispatch({type: 'SITE_SHOW_NOTIFICATION', payload: {icon: 'cart', title: 'Success!', message: 'This item has been merged.'}});
}

export const cartAddDuplicateItem = (product, duplicate, value) => dispatch => {
  dispatch({type: 'CART_ADD_DUPLICATE_ITEM', payload: product, duplicate: duplicate, value: value});
  dispatch({type: 'SITE_SHOW_NOTIFICATION', payload: {icon: 'cart', title: 'Success!', message: 'You have added this item to your cart.'}});
}

export const cartUpdateItemValue = (fieldName, fieldValue, itemIndex) => dispatch => {
  dispatch({type: 'CART_UPDATE_ITEM_VALUE', payload: {fieldName: fieldName, fieldValue: fieldValue, itemIndex: itemIndex}});
}

export const sessionCart = (items) => dispatch => {
  dispatch({type: 'LOAD_CART_FROM_SESSION', payload: items});
}

export const sessionCOCart = (items) => dispatch => {
  dispatch({type: 'LOAD_CO_CART_FROM_SESSION', payload: items});
}

export const cartSetAddress = (addressInfo, addressType) => dispatch => {
  dispatch({type: 'CART_SET_ADDRESS', addressType: addressType, payload: addressInfo});
}

export const cartSetInfo = (info, infoType) => dispatch => {
  dispatch({type: 'CART_SET_INFO', infoType: infoType, payload: info});
}

export const cartSetValue = (name, value) => dispatch => {
  dispatch({type: 'CART_SET_VAL', name: name, value: value});
}

export const cartAssignValue = (name, value) => dispatch => {
  // TO REPLACE OR UPDATE cartSetValue
  dispatch({type: 'CART_ASSIGN_VAL', name: name, value: value});
}

export const cartRemoveVal = (infoLocation) => dispatch => {
  dispatch({type: 'CART_REMOVE_VAL', payload: infoLocation});
}

export const cartEmpty = () => dispatch => {
  dispatch({type: 'CART_EMPTY'});
}

 // export const cartSetValue = (name, value, sStore = true) => dispatch => {
//   dispatch({type: 'STORE_SET_VALUE', name: name, value: value, sStore: sStore});
// }
// 
export const cartDeleteVal = (location) => dispatch => {
  dispatch({type: 'CART_DELETE_VAL', payload: location});
}



export const cartLoadState = (params, api, state_location, callback = null, cbApi = null) => dispatch => {
  var formData = new FormData();

  Array.isArray(params) &&
  params.map (param => {
     if(param.name && param.value) {
      formData.append(param.name, param.value);
     }
  })

  if(apis.getReqUrl(api)) {
      axios.post(apis.getReqUrl(api), formData).then(res => {
        if(callback && Array.isArray(callback) && callback.length > 0) {
          var callbackData = new FormData();
          callback.map (param => {
            if(param.name && param.value) {
              if(param.value === 'result') {
                callbackData.append(param.name, res.data);
              }else{
                callbackData.append(param.name, param.value);
              }
            }
          })
          axios.post(apis.getReqUrl(cbApi ? cbApi : api), callbackData).then(cbRes => {

            dispatch({type: 'CART_LOAD_STATE', location: state_location, payload: cbRes.data});
          }).catch(error => {
            console.log("DISPATCH ERROR LOAD CALLBACK", error);
          })
        }else{
          dispatch({type: 'CART_LOAD_STATE', location: state_location, payload: res.data});
        }
      }).catch(error => {
        console.log("DISPATCH ERROR - LOAD STATE", apis.getReqUrl(api), error);
      })
    }else{
      // console.log("There's no reqUrl", api, reqUrl);
    }
 }

export const cartSetPref = (name, value) => dispatch => {
  dispatch({type: 'CART_SET_PREF', prefName: name, payload: value});
}

export const cartUpdateInfo = (formData) => dispatch => {
  const formObject = formDataToObject(formData);
  const formKeys = Object.keys(formObject);

  formKeys.map(key => {
    dispatch({type: 'CART_SET_INFO', infoType: key, payload: formObject[key]});
  })
  dispatch({type: 'SET_MODAL', payload: null});
}

export const cartTransferItemsToOrder = (items, orderIndex ) => dispatch => {
  dispatch({type: 'CART_TRANSFER_ITEMS_TO_ORDER', orderIndex: orderIndex, payload: items});
  dispatch({type: 'CART_REMOVE_ITEMS', payload: items});
}

export const cartRemoveItem = (itemIndex, orderIndex) => dispatch => {
  dispatch({type: 'CART_REMOVE_ITEM', orderIndex: orderIndex, payload:itemIndex});
}

export const cartMoveItem = (item, itemIndex, orderIndex, targetIndex) => dispatch => {
  dispatch({type: 'CART_MOVE_ITEM', payload: item, itemIndex: itemIndex, orderIndex: orderIndex, targetIndex: targetIndex});
}

export const cartGetTax = (organization_id) => dispatch => {
  const formData = `process=get_tax_rates&organization_id=${organization_id}`;
  axios.post(apis.api_url_rates, formData).then(res => {
    dispatch({type: 'CART_GET_TAX', payload: res.data});
  }).catch(error => {
    console.log("DISPATCH ERROR - GET TAX", error);
  })
}

export const cartGetShipping = (organization_id, location_province = null, shipping_method = null) => dispatch => {
  let formData = `process=get_shipping_rates&organization_id=${organization_id}`;
  if(location_province) { formData += `&location_province=${location_province}`};
  if(shipping_method) { formData += `&shipping_method=${shipping_method}`};
  axios.post(apis.api_url_rates, formData).then(res => {
    dispatch({type: 'CART_GET_SHIPPING', payload: res.data});
  }).catch(error => {
    console.log("DISPATCH ERROR - SHIPPING RATES", error);
  })
}

export const cartAddOrder = (order, organization_id, user_id, user_password = null) => dispatch => {
  let formData = `process=add_order&json=${order}&organization_id=${organization_id}&user_id=${user_id}`;
  if(user_password) { formData += `&user_password=${user_password}`}
  console.log("ACTION", formData);
  axios.post(apis.api_url_orders, formData).then(res => {
    dispatch({type: 'CART_ADD_ORDER', payload: res.data});
  }).catch(error => {
    console.log("DISPATCH ERROR : ADD ORDER", error);
  })
}


function formDataToObject(formData) {
  let formInfo = {};
  let formType;
  const formSplit = decodeURIComponent(formData).split('&');
  formSplit.map(pair => {
    const pairSplit = pair.split('=');
    if(pairSplit[0] === 'form-type') {
      formType = pairSplit[1];
    }else{
      formInfo[pairSplit[0]] = decodeURIComponent(pairSplit[1].replace(/\+/g, " "));
    }
  })
  return {[formType]: formInfo}
}

function convertUrlStringWithArray(string) {
  const split = string.split('.');
  let arrString = '';

  split.map( node => {
    var isnum = /^\d+$/.test(node);
    if(isnum) {
      arrString += '['+node+']';
    }else{
      arrString += '.'+node;
    }
  })
  arrString = arrString.replace('.', '');
  return arrString;
}
