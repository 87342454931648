import React, {useEffect, useState} from 'react';

import HostListItem from 'components/profiles/hosts/HostListItem';

export default(props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [organizations, setOrganizations] = useState(null);
  const [hosts, setHosts] = useState(null);
  const [isSuper, setIsSuper] = useState(null);
  
  // HOOKS
  useEffect(() => {
    if(props.userID && props.userID !== userID) {
      setUserID(props.userID)
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token && props.token !== token) {
      setToken(props.token)
    }
  }, [props.token]);

  useEffect(() => {
    if(props.organizationID && organizationID !== props.organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    props.organizationName &&
    props.organizationName !== organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);
  
  useEffect(() => {
    if(props.languageCode && languageCode !== props.languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    if(props.userAccess && props.userAccess !== userAccess) {
      setUserAccess(props.userAccess);
    } 
  }, [props.userAccess]);
  
  useEffect(() => {
    if(props.isSuper && props.isSuper !== userID) {
      setIsSuper(props.isSuper);
    }
  }, [props.isSuper]);
  
  useEffect(() => {
    if(userID && token && isSuper) {
      const orgParams = [
        {name: 'process', value: 'get_organizations'},
      ];
      if(!props.objExists(props.admin, 'organizations') || 
        props.objGetValue(props.admin, 'reload.organizations') === true) {
        props.adminLoadState(orgParams, 'organizations', 'organizations');
        props.adminDeleteVal('reload.organizations');
      }
      
    }
  }, [userID, token, isSuper, props.admin.organizations, props.admin.reload]);
  
  useEffect(() => {
    if(props.admin.organizations && props.admin.organizations !== organizations) {
      setOrganizations(props.admin.organizations);
    }
  }, [props.admin.organizations]);
  
  useEffect(() => {
    if(userID && token && props.objGetValue(userAccess, 'super') === true) {
      const orgParams = [
        {name: 'process', value: 'get_site_hosts'},
        {name: 'user_id', value: userID},
        {name: 'token', value: token}
      ];
      if(!props.objExists(props.admin, 'hosts') || 
        props.objGetValue(props.admin, 'reload.hosts') === true) {
        props.adminLoadState(orgParams, 'site', 'hosts');  
        props.adminDeleteVal('reload.hosts');
      }
    }
  }, [userID, token, userAccess, props.admin.hosts, props.admin.reload]);
  
  useEffect(() => {
    if(props.admin.hosts && props.admin.hosts !== hosts) {
      setHosts(props.admin.hosts);
    }
  }, [props.admin.hosts]);
  
  console.log("HOSTS : ", props.admin);
  return (
    userAccess && userAccess.super ?
      <div className="info-list">
        <h2>Hosts</h2>
        {hosts && Array.isArray(hosts) &&
          hosts.map((host, index) => {
            return <HostListItem {...props} 
                      key={`crHst${index}`}
                      userID={userID}
                      token={token}
                      isSuper={isSuper}
                      userAccess={userAccess}
                      host={host}
                      organizations={organizations} />
          })
        }
        
      </div>
    :
      <div>
        <h2>NO ACCESS</h2>
      </div>
  )
}
