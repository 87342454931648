import React, { useState, useEffect } from 'react';

// import ElementSVG from 'components/atoms/ElementSVG';
import ProgressMap from 'components/molecules/ProgressMap';

export default (props) => {

  const curStep = useState(props.curStep);
  const [isListener, setListener] = useState(false);

  let keyEvent = null;
  if(props.stages.length > 1 && !isListener) {
    window.addEventListener('keydown', formKeyNav);
    setListener(true);
  }

  function formKeyNav(e) {

    if(!document.body.classList.contains('modal-active')) {
      e.preventDefault();
      window.removeEventListener('keydown', formKeyNav);
      return null;
    }

    // console.log(e, e.key, ' : ', document.activeElement);
    let target = document.activeElement;
    while (target.classList && !target.classList.contains('form-row') && target.tagName.toLowerCase() !== 'body') {
      target = target.parentNode;
    }

    if((e.key === 'Tab' && !e.shiftKey && !target.nextSibling) ||
        (e.key === 'Tab' && e.shiftKey && !target.previousSibling)) {
      e.preventDefault();
    }

    if(e.key === 'ArrowLeft' ||e.key === 'ArrowRight') {
      let parent = target;
      while(parent.tagName.toLowerCase() !== 'fieldset' && parent.tagName.toLowerCase() !== 'body') {
        parent = parent.parentNode;
      }

      const progMarkers = document.getElementsByClassName('progress-marker');
      let curMarker;

      for(var i = 0; i < progMarkers.length; i++) {
        if(progMarkers[i].classList.contains('active')) {
          curMarker = progMarkers[i];
        }
      }
      let targetMarker = null;
      if(e.key === 'ArrowRight' && curMarker.nextSibling &&
        (curMarker.nextSibling.classList.contains('released') || curMarker.nextSibling.classList.contains('complete'))) {
          targetMarker = curMarker.nextSibling;
        // curMarker.nextSibling.dispatchEvent('click');
      }else if (e.key === 'ArrowLeft' && curMarker.previousSibling &&
        (curMarker.previousSibling.classList.contains('released') || curMarker.previousSibling.classList.contains('complete'))) {
          targetMarker = curMarker.previousSibling;
      }

      if(targetMarker) {
          const markerNumber = targetMarker.getElementsByClassName('progress-marker-stage-number')[0];
          markerNumber.click();
        }
    }

  }

  function showStage(e, stageSet, progressive = false) {
    // Check to see if the stageSet is active or no stage progress (last complete stage) or
    if(stageSet.classList.contains('active') ||
        !props.lastComplete ||
        (progressive && props.lastComplete &&
        (stageSet.previousSibling !== props.lastComplete && !isComplete(stageSet, props.lastComplete)))
      ) {
        e.preventDefault();
        return null;
      }

      // GET STAGE MARKER CONTAINER AND ALL STAGE MAKERS WITHIN
      let target = e.target;
      while (!target.classList.contains('progress-marker') && target.tagName.toLowerCase !== 'body') {
        target = target.parentNode;
      }
      const markerParent = target.parentNode;
      const markers = markerParent.childNodes;

      markers.forEach(marker => {
        if(marker === target) {
          !marker.classList.contains('active') && marker.classList.add('active');
        }else{
          marker.classList.contains('active') && marker.classList.remove('active');
        }
      })

      // GET FORM STAGES PARENT AND ALL STAGES WITHIN
      const stageParent = stageSet.parentNode;
      const stages = stageParent.childNodes;

      // FIND ACTIVE STAGE OR IF NO ACTIVE THEN DEFAULT TO FIRST STAGE
      stages.forEach(stage => {
        !stage.classList.contains('active') && stage.classList.remove('active');

        if(!curStep && stage.tagName.toLowerCase() === 'fieldset') {
          props.setAction(stage);
        }else if (stage.tagName.toLowerCase() === 'fieldset' && stage.classList.contains('active')) {
          props.setAction(stage);
        }
      })

      // BACK OUT TO FIND PREVIOUS STAGE FROM EVENT REF
      let curStepPos = props.curStep;
      while (curStepPos !== stageSet && curStepPos !== null) {
        curStepPos = curStepPos.previousSibling;
      }

      // IF PREVIOUS STAGE IS FOUND ADD PREVIOUS CLASS TO THAT STAGE
      if(curStepPos) {
        !stageSet.classList.contains('previous') && stageSet.classList.add('previous');
        !props.curStep.classList.contains('previous') && props.curStep.classList.add('previous');
      }else{
        stageSet.classList.contains('previous') && stageSet.classList.remove('previous');
        props.curStep.classList.contains('previous') && props.curStep.classList.remove('previous');
      }

      // UPDATING ACTIVE CLASS STATES TO STAGES:
      // IF CURRENT STAGE CONTAINS ACTIVE CLASS REMOVE IT
      props.curStep.classList.contains('active') && props.curStep.classList.remove('active');
      // IF CURRENT STAGE IS DOES NOT CONTAIN INACTIVE CLASS ADD IT
      !props.curStep.classList.contains('inactive') && props.curStep.classList.add('inactive');
      props.curStep.setAttribute('disabled', true);

      // IF THE SUPPLIED STAGE (FROM EVENT REF) CONTAINS INACTIVE CLASS REMOVE IT
      stageSet.classList.contains('inactive') && stageSet.classList.remove('inactive');
      // IF THE SUPPLIED STAEGE (FROM EVENT REF) DOESN'T CONTAIN ACTIVE CLASS ADD IT
      !stageSet.classList.contains('active') && stageSet.classList.add('active');
      stageSet.removeAttribute('disabled', false);

      setFocus(stageSet);

      // IF AN ADDITIONAL ACTION HAS BEEN SUPPLIED EXECUTE IT
      if(props.setAction) {
        props.setAction(stageSet);
      }

      return null;
  }

  function setFocus(stage) {
    const inputs = stage.querySelectorAll('input');
    inputs.forEach(input => {
      if(input.tabIndex === 1) {
          input.focus();
      }
    })
    return null;
  }

  function isComplete(thisStage, completeStage) {
    let isCmplt = false;
      while(thisStage) {
        if(thisStage === completeStage) {
          isCmplt = true;
        }
        thisStage = thisStage.nextSibling;
      }
    return isCmplt;
  }

  useEffect(() => {
    setFocus(props.stages[0].ref.current);
  }, []);

  return (
    <div>
      <ProgressMap stages={props.stages} curStep={props.curStep} lastComplete={props.lastComplete} action={showStage}  progressive={props.progressive} />
    </div>
  )
}
