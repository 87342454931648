import React, {useEffect, useState} from 'react';

import Message from 'components/profiles/messages/Message';
import IconSVG from 'components/icons/IconSVG';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [messages, setMessages] = useState(null);
	
	useEffect(() => {
		if(props.userID &&
			props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token &&
			props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.languageCode &&
			props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.messages &&
			props.messages !== messages) {
			setMessages(props.messages);
		}
	}, [props.messages]);
	
	return (
		Array.isArray(messages) &&
		<div className="message-list list">
			<h4><IconSVG icon="chat" />{props.title ? props.capitalizeString(props.title, true) : 'Messages'}</h4>
			{messages.map((message, index) => {
				return(
					<Message {...props}
						key={`msgLst${index}`}
						userID={userID}
						token={token}
						languageCode={languageCode}
						message={message}
						location={props.location}
						stateLocation={props.stateLocation}
						noReply={props.noReply} />
				)	
			})}
		</div>
	)
}