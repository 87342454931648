import React, {useEffect, useState, Fragment} from 'react';

import IconToken from 'components/icons/IconToken';
import GroupListItem from 'components/profiles/groups/GroupListItem';
import Control from 'components/atoms/Control';
import FormGroupAddEdit from 'components/forms/common_forms/FormGroupAddEdit';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [coreOrg, setCoreOrg] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [userAccess, setUserAccess] = useState(null);
	const [groups, setGroups] = useState(null);
	const [groupType, setGroupType] = useState(null);

	// HOOKS
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
		  setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token !== token) {
		  setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.coreOrg && 
		  props.coreOrg !== coreOrg) {
		  setCoreOrg(props.coreOrg);
		}
	}, [props.coreOrg]);
	
	useEffect(() => {
		if(props.languageCode &&
		  props.languageCode !== languageCode) {
		  setLanguageCode(props.languageCode);
		}else if(!languageCode) {
		  setLanguageCode('en');	
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.userAccess && props.userAccess !== userAccess) {
			setUserAccess(props.userAccess);
		}
	}, [props.userAccess]);
	
	useEffect(() => {
		if(props.groups && 
			Array.isArray(props.groups) && 
			props.groups.length > 0  && 
			props.groups !== groups) {
			setGroups(props.groups);
		}
	}, [props.groups]);
	
	useEffect(() => {
		if(props.groupType && props.groupType !== groupType) {
			setGroupType(props.groupType);
		}
	}, [props.groupType]);
	
	// FUNCTIONS
	function loadAddGroup() {
		props.setModal(
			<FormGroupAddEdit {...props}
				userID={userID}
				token={token}
				coreOrg={coreOrg}
				languageCode={languageCode}
				groupType="user"
				groups={groups}
				actionSubmit={addGroup} />
		);
	}
	
	function addGroup(params) {
		props.setModal(null);
		props[`${props.stateLocation ? props.stateLocation : 'admin'}LoadState`](params, 'groups', 'reload.groups.user')
	}
	
	return (
		<Fragment>
		<div className="grid" style={{gridTemplateColumns: 'auto auto 1fr auto'}}>
			{groups && languageCode &&
				groups.map((group, index) => {
					return (
						<GroupListItem {...props}
										key={`gli${index}`}
										userID={userID}
										token={token}
										coreOrg={coreOrg}
										languageCode={languageCode}
										userAccess={userAccess}
										group={group}
										isSub={props.isSub}
										groupType={groupType}
										groups={groups}
										location={props.location}
										stateLocation={props.stateLocation}
										isAdmin={props.isAdmin} />
										
					)
				})
			}
		</div>
		<Control
			icon="group-add"
			label="Add A Group"
			showLabel={true}
			action={loadAddGroup} />
		</Fragment>
	)
}