export const orderReq = {
	digital: [
		{name: 'shipping.locations[x].contact_name_first', value: 'not-blank'},
		{name: 'shipping.locations[x].contact_name_last', value: 'not-blank'},
		{name: 'shipping.locations[x].contact_email', value: 'not-blank'},
		{name: 'shipping.locations[x].contact_phone', value: 'not-blank'},
		{name: 'shipping.locations[x].location_shipping_method', value: 'not-blank'},
		{name: 'items', value: '>0'}
	],
	physical: [
		{name: 'shipping.locations[x].contact_name_first', value: 'not-blank'},
		{name: 'shipping.locations[x].contact_name_last', value: 'not-blank'},
		{name: 'shipping.locations[x].contact_email', value: 'not-blank'},
		{name: 'shipping.locations[x].contact_phone', value: 'not-blank'},
		{name: 'shipping.locations[x].location_address_1', value: 'not-blank'},
		{name: 'shipping.locations[x].location_city', value: 'not-blank'},
		{name: 'shipping.locations[x].location_province', value: 'not-blank'},
		{name: 'shipping.locations[x].location_postal_code', value: 'not-blank'},
		{name: 'shipping.locations[x].location_shipping_method', value: 'not-blank'},
		{name: 'items', value: '>0'}
	],
};

export const projectReq = [
	{name: 'organization_id', value: 'not-blank'},
	{name: 'language_id', value: 'not-blank'},
	{name: 'billing.order_payment_method', value: 'not-blank'},
	{name: 'orders', value: '>0'}
];