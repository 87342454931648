import React, {useEffect, useState} from 'react';

import Checkbox from 'components/forms/elements/Checkbox';

export default (props) => {
  const [options, setOptions] = useState(null);
  
  // HOOKS
  useEffect(() => {
    if(props.checklist && props.checklist !== options) {
      setOptions(props.checklist);
    }
  }, [props.checklist]);
  
  return (
    <div className="checklist">
      {props.label &&
        <label>{props.label}</label>
      }
      {options && Array.isArray(options) &&
        Array.isArray(options[0].options) ?
          options.map((optionGroup, gindex) => {
            return (
                <div className="checklis-section">
                  {optionGroup.option_group_name &&
                    <label>{optionGroup.option_group_name}</label>
                  }
                  {Array.isArray(optionGroup.options) &&
                    optionGroup.options.map((option, oindex) => {
                      return(
                        <Checkbox {...props}
                          key={`cbin${oindex}`}
                          {...option} 
                          checked={option.option_active ? true : false} 
                          onClick={props.onClick ? props.onClick : null} />
                      )
                    })
                  }
                </div>
              );
            })
          : options && 
            Array.isArray(options) &&
            options.map((option, oindex) => {
              return(
                <Checkbox {...props}
                  key={`cbin${oindex}`}
                  {...option} 
                  checked={option.option_active ? true : false} 
                  onClick={props.onClick ? props.onClick : null} />
              )
            })
          }
    </div>
  )
}
