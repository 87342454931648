import React, {useEffect, useState, useCallback, Fragment} from 'react';
import {Redirect} from 'react-router';

import IconToken from 'components/icons/IconToken';
import Input from 'components/forms/elements/Input';

export default (props) => {
	const [active, setActive] = useState(null);
	const [search, setSearch] = useState(null);
	const [redirect, setRedirect] = useState(null);
	const [submit, setSubmit] = useState(null);
	const [clear, setClear] = useState(null);
	
	// const keyNav = useCallback((e) => {handleKey(e)}, [active]);
	
	useEffect(() => {
		
	}, [active]);
	
	useEffect(() => {
	  if (!active) {
		return;
	  }
	
	  function handleKeyUp(e) {
		switch(e.key) {
		  case 'Enter':
		  case 'Tab':
			e.preventDefault();
			setSubmit(true);
			setActive(false);
			break;
		  case 'Escape':
			e.preventDefault();
			setClear(true);
			break;
		  default:
			break;
		}
	  }
	  document.getElementById("site_search").focus();
	  window.addEventListener("keyup", handleKeyUp);
	  return () => window.removeEventListener("keyup", handleKeyUp);
	}, [active]);
	
	useEffect(() => {
		if(submit) {
			performSearch();
			setSubmit(false);
		}
	}, [submit]);
	
	useEffect(() => {
		if(clear) {
			clearSearch();
			setClear(false);
		}
	}, [clear]);
	
	// FUNCTIONS
	function performSearch() {
		props.storeSetVal('search', search);
		setActive(false);
		setRedirect(`/product/search/${search.toLowerCase().replace(' ', '+')}`);
	}
	
	function clearSearch() {
		props.storeDeleteVal('search');
		setActive(false);
		setSearch(null);
	}
	
	function handleUpdate(params) {
		setSearch(params.value);
	}
	
	function handleKey(e){
		switch(e.key) {
		  case 'Enter':
		  case 'Tab':
			e.preventDefault();
			performSearch();
			break;
		  case 'Escape':
			e.preventDefault();
			clearSearch();
			break;
		  default:
			break;
		}
	}
	
	return (
		<Fragment>
		{redirect && <Redirect to={redirect} />}
		<div className={`search${active ? ' active' : ''}`}>
			<div className="search-form">
				<Input id="site_search"
						name="site_search"
						value={''}
						clearValue={clear}
						label={null}
						onChange={handleUpdate}
						placeholder="Search the Site" />
				<div className="ctl">
					<IconToken icon="search"
						className="input-ctl"
						disabled={!search ? true : false}
						action={!search ? null : performSearch} />
					<IconToken icon="close"
						className="input-ctl"
						action={()=>{setActive(false)}} />
				</div>
			</div>
			<IconToken icon="search"
						className="search-btn" 
						action={()=>{setActive(active ? false : true)}} />
		</div>
		</Fragment>
	)
}