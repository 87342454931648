import React, {useEffect, useState} from 'react';

import IconToken from 'components/icons/IconToken';

export default (props) => {
  const [icon, setIcon] = useState(null);
  const [position, setPosition] = useState(null);
  
  useEffect(() => {
    if(props.type) {
      switch(props.type.toLowerCase()) {
        case 'tasks':
          setIcon('gear-badge');
          break;
        case 'messages':
          setIcon('message-badge');
          break;
        default:
          icon &&
          setIcon(null);
      }
    }
  }, [props.type]);
  
  useEffect(() => {
    if(props.position) {
      setPosition(props.position.toLowerCase());
    }else{
      setPosition('top-right');
    }
  }, [props.position]);
  
  function handleClick() {
    if(props.action) {
      props.action();
    }
  }
  
  return (
    <div className={`badge${props.className ? ` ${props.className}` : ''}${position ? ` ${position}` : ''}${icon ? ` icon` : ''}${props.action ? ' action' : ''}`}
      onClick={handleClick}>
      {icon &&
        <IconToken icon={icon} />
      }
      <div className="badge-content">
        {props.content}
      </div>
    </div>
  )
}
