import React, {useEffect, useState, Fragment} from 'react';
import {Redirect} from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import FormDynamic from 'components/forms/FormDynamic';
import FormPasswordChange from 'components/forms/common_forms/FormPasswordChange';
import Link from 'components/navigation/Link';
import IconSVG from 'components/icons/IconSVG';
import Notification from 'components/organisms/Notification';
import Loader from 'components/atoms/Loader';


export default (props) => {
  const [referrer, setReferrer] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [loginTask, setLoginTask] = useState(null);
  const [loginCode, setLoginCode] = useState(null);
  const [verify, setVerify] = useState(false);
  const [resetVerified, setResetVerified] = useState(false);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [key, setKey] = useState(null);
  const [keyCtl, setKeyCtl] = useState(null);
  const [keyValid, setKeyValid] = useState(false);
  const [updateMsg, setUpdateMsg] = useState(null);
  const [requestMsg, setRequestMsg] = useState(null);
  const [updateError, setUpdateError] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [loginFields, setLoginFields] = useState(null);
  const [resetFields, setResetFields] = useState(null);
  
  const [form, setForm] = useState(null);
  const [resetRequested, setResetRequested] = useState(null);
  const [formInProgress, setFormInProgress] = useState(false);
  const [clearForm, setClearForm] = useState(null);

  // HOOKS
  useEffect(() => {
    if(props.objExists(props.site, 'navigation.referrer') && 
        !props.site.navigation.referrer.match('/login') &&
        referrer !== props.site.navigation.referrer) {
      setReferrer(props.site.navigation.referrer);
    }
  }, [props.site]);
  
  useEffect(() => {
    if(props.organizationID && organizationID != props.organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    if(props.languageCode && languageCode != props.languageCode) {
      setLanguageCode(props.languageCode);
    }else if (!languageCode) {
      setLanguageCode('en');
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    if(props.token && token !== props.token) {
      setToken(props.token);
    }else if(props.objExists(props.user, 'user_id') &&
              (sessionStorage.getItem('token') || 
              localStorage.getItem('token'))){
      const storageToken = sessionStorage.getItem('token') ?
                            sessionStorage.getItem('token') :
                              localStorage.getItem('token') ?
                                localStorage.getItem('token') :
                                  null;
      if(storageToken && token !== storageToken) {
        setToken(storageToken);
      }
    }else if(!props.token && token) {
      setToken(null);
    }
  }, [props.token, props.user]);
  
  useEffect(() => {
    if(props.loginTask && 
      ((!props.isModal && loginTask !== props.LoginTask) || 
      !loginTask)) {
        setLoginTask(props.loginTask);
    }else{
      setLoginTask(null);
    } 
  }, [props.loginTask]);
  
  useEffect(() => {
    if(props.loginCode && loginCode !== props.loginCode) {
      setLoginCode(props.loginCode);
    }else if(loginCode && !props.loginCode) {
      setLoginCode(null);
    }
  }, [props.loginCode]);
  
  useEffect(() => {
    if(props.objGetValue(props.user, 'update.key') === true && 
        token && organizationID) {
          setLoginTask('updateComplete');
          setClearForm(true);
          setUpdateMsg(
            <Fragment>
              <p>
                Your password has successfully been updated.<br />
                <Link to="/">Click Here to continue to the site...</Link>
              </p>
            </Fragment>
          );
          const userParams = [
            {name: 'process', value: 'get_user_from_token'},
            {name: 'token', value: token},
            {name: 'organization_id', value: organizationID}
          ];
        props.userLoadState(userParams, 'users', null);
        
    }
  }, [props.user, token, organizationID, loginTask]);
  
  // LOGIN TASK HAS BEEN SET TO UPDATE AND LOGIN CODE IS PRESENT
  // VERIFY LOGIN CODE
  useEffect(() => {
    if(loginTask === 'update' && !verify && loginCode) {
      const verifyParams = [
        {name: 'process', value: 'user_verify_reset'},
        {name: 'language_code', value: languageCode},
        {name: 'urc', value: loginCode}
      ]
      
      setFormInProgress(true);
      props.userLoadState(verifyParams, 'users', 'reset');
      setVerify(true);
    }
  }, [loginTask, loginCode]);
  
  useEffect(() => {
    if(loginTask === 'update') {
      if(loginCode && props.objExists(props.user, 'reset')) {
        if(props.objGetValue(props.user, 'reset') === 'Code Expired') {
          setFormInProgress(false);
          setUpdateMsg(<p>This reset has expired please <Link to="/login/reset">request another reset here...</Link></p>);
        }else if(props.objGetValue(props.user, 'reset') === 'No User') {
          setFormInProgress(false);
          setUpdateMsg(<p>Your code could not be verified. <Link to="/login/reset">request another reset here...</Link></p>);
        }else if(props.objGetValue(props.user, 'reset.key') === true) {
          setFormInProgress(false);
          setForm(null);
          setUpdateMsg(
            <Fragment>
              <p>
                <b>Success!</b> Your password has been successfully updated. Please log in now
                  <br />
                  <Link to="/login/">Return to login</Link>
              </p>
              
            </Fragment>
          )
          props.notifyUser({icon: 'user', message: 'Your password has been successfully changed'});
        }else if(props.objExists(props.user, 'reset.user_id') && 
                  props.objExists(props.user, 'reset.user_init_key')) {
          setUser({user_id: props.user.reset.user_id, user_init_key: props.user.reset.user_init_key});
          setResetVerified(true);
          setFormInProgress(false);
          setUpdateMsg(<p>Your reset code has been verified. Please update your password</p>);
        }
      }else if(loginCode && !props.objExists(props.user, 'reset')) {
        setUpdateMsg(
          <p>Hang on, we are verifying your reset code</p>
        )
      }
    }else if(loginTask === 'updateRequired') {
        setUser(props.user);
        setUpdateMsg(
          <p>It is recommended that you update your password before using this site.</p>
        );
    }
  }, [props.user, loginCode, loginTask]);
  
  useEffect(() => {
    if(key && keyCtl) {
      if(key.length > 0 && keyCtl.length > 0 && key === keyCtl && !keyValid) {
        setKeyValid(true);
        setUpdateError(null);
      }else if(key.length > 0 && keyCtl.length > 0 && key !== keyCtl) {
        setUpdateError("Passwords provided do not match!");
      }
    }else if(keyValid) {
      setKeyValid(false);
    }
  }, [key, keyCtl]);
  

  useEffect(() => {
    setLoginFields([
      { fieldset_name: 'User Login',
        fields: [
            {field_type: 'hidden',
            field_name: 'process',
            field_value: 'user_login'},
            {field_type: 'hidden',
            field_name: 'organization_id',
            field_value: organizationID},
            {field_type: 'email',
            field_name: 'contact_email',
            field_label: 'Email',
            field_placeholder: 'Your email address',
            field_required: true},
            {field_type: 'password',
            field_name: 'user_key',
            field_label: 'Password',
            field_placeholder: 'Your password',
            field_required: true},
            {field_type: 'switch',
            field_name: 'persist',
            field_label: 'Remember Me',
            field_default: false}
          ]
      }
    ]);

    setResetFields([{
      fieldset_name: 'Reset Password',
        fields: [
          {field_type: 'hidden',
          field_name: 'process',
          field_value: 'user_request_reset'},
          {field_type: 'hidden',
          field_name: 'organization_id',
          field_value: organizationID},
          {field_type: 'email',
          field_name: 'contact_email',
          field_label: 'Email',
          field_placeholder: 'Your email address',
          field_required: true},
        ]
      }
    ]);

  }, [organizationID]);

  useEffect(() => {
    if(props.objExists(props.user, 'user_id')) {
      if(props.objGetValue(props.user, 'user_key_reset') === 1 &&
          props.objGetValue(props.user, 'update.key') !== true) {
          setLoginTask('updateRequired');
          // setRedirect('/login/reset');
          // setUpdateMsg(
          //   <p>A password change recommended for your account</p>
          // );
      }else if(loginTask !== 'updateRequired' && loginTask !== 'updateComplete'
                && props.objGetValue(props.user, 'update.key') !== true) {
        if(referrer) {
          props.siteRemoveValue('navigation.referrer');
          setRedirect(referrer);
        }else if(props.objExists(props.site, 'preferences.home_page')){
          setRedirect(`/${props.site.preferences.home_page}`);
        }else{
          setRedirect('/');
        }
      }
    }
  }, [props.user, props.site, loginTask]);

  useEffect(() => {
    if(clearForm === true) {
      setForm(null);
      setClearForm(false);
    }
    
    switch(loginTask) {
      case 'reset':
        setForm(<Fragment>
                  <div className="form-intro alert">
                    <IconSVG icon="email" className="p"/>
                    {requestMsg ? requestMsg : 
                      <p>
                        <b>Forgot your password?</b> we'll send you an email with  a link to reset your password.
                      </p>
                    }
                  </div>
                  {resetFields && 
                    <Fragment>
                      <FormDynamic content={resetFields}
                                  name="reset-password"
                                  formActions={{formSubmit: requestReset}}
                                  formCompleteLabel="Send"
                                  closable={false}
                                  unmountOnClick={false}
                                  cancelable={props.cancelable ? true : false}
                                  includeControls={true}
                                  multiStep={false}
                                  returnObject={true}
                                  alertMsg={props.objGetValue(props.user, 'alert_error')}
                                  formClass=""
                                  valActions={{isEmail: props.isEmail}}/>
                      <div><Link to={!props.isModal ? `/login/` : null} action={props.isModal ? ()=>{setLoginTask(null)} : null}>Return to Login</Link></div>
                    </Fragment>
                  }
                </Fragment>)
        break;
      case 'update':
        setForm(<Fragment>
                  <div className="form-intro alert caution-bg">
                    <IconSVG icon="lock" className="p"/>
                    {updateMsg}
                  </div>
                  {resetVerified && 
                    loginCode && 
                    organizationID &&
                    <FormPasswordChange {...props}
                      userID={null}
                      urc={loginCode}
                      organizationID={organizationID}
                      resultLocation={'reset.key'}
                      resultCallBack={null}
                      languageCode={languageCode}
                      actionSubmit={handlePasswordReset}  />
                  }
                </Fragment>);

          break;
        case 'updateRequired':
          setForm(
            <Fragment>
              <div className="form-intro alert caution-bg">
                <IconSVG icon="lock" className="p"/>
                {updateMsg}
              </div>
              {props.objExists(user, 'user_id') && token &&
                <FormPasswordChange {...props}
                  userID={user.user_id}
                  token={token}
                  organizationID={organizationID}
                  resultLocation={'update.key'}
                  resultCallBack={null}
                  languageCode={languageCode}
                  actionSubmit={handlePasswordUpdate}  />
              }
            </Fragment>)
        break;
      case 'updateComplete':
        setForm(
          <div className="form-intro alert caution-bg">
            <IconSVG icon="lock" className="p"/>
            {updateMsg}
          </div>
        );
        break;
      default:
        setForm(<Fragment>
                  <div className="form-intro">
                    <p>{props.objExists(props.site, 'public') && !props.site.public ?
                          'You must be logged in to view this site' :
                          'Please login to access account info'
                        }
                    </p>
                  </div>
                  {!props.objExists(props.user, 'user_id') &&
                    loginFields &&
                    <Fragment>
                      <FormDynamic content={loginFields}
                                  name="login"
                                  formActions={{formSubmit: login}}
                                  formCompleteLabel="Login"
                                  closable={false}
                                  unmountOnClick={false}
                                  cancelable={props.cancelable ? true : false}
                                  includeControls={true}
                                  multiStep={false}
                                  returnObject={true}
                                  alertMsg={props.objGetValue(props.site, 'userError')}
                                  formClass=""
                                  valActions={{isEmail: props.isEmail}}/>
                      <div>
                        <Link to={!props.isModal ? `/login/reset/` : null} action={props.isModal ? ()=>{setLoginTask('reset')} : null}>
                          Forgot your password?
                        </Link>
                      </div>
                      <div>
                        <a href="mailto:digital@accellgraphics.ca">Having Troubles?</a>
                      </div>
                    </Fragment>
                  }
                </Fragment>)
          break;
        }
  }, [organizationID, token, props.user, loginTask, loginFields, resetFields, props.site, keyValid, updateError, updateMsg, requestMsg, clearForm]);
  
  useEffect(() => {
    if(formInProgress && 
      props.user &&
      props.user.resetRequest === false) {
      setFormInProgress(null);
      setResetRequested(null);
      setRequestMsg(
        <p>
          <b>No account found</b> with this email address. Please try again.
        </p>
      );
      setClearForm(true);
    }else if(props.objGetValue(props.user, 'resetRequest') === true){
      setFormInProgress(null);
      setResetRequested(null);
      setRequestMsg(
        <p>
          <b>Success!</b> An email has been sent with a link reset your password.
        </p>
      );
    }
  }, [props.user, formInProgress, resetRequested]);
  
  // FUNCTIONS
  function login(formData) {
    props.siteSetValue('reload.modules', true);
    props.userLogin(formData, false);
  }

  function requestReset(formData) {
    props.userLoadState(formData, 'users', 'resetRequest');
    setFormInProgress(true);
    setResetRequested(true);
  }

  function updatePW(formData) {
    setFormInProgress(true);
    
    if(formData.find(param => param.name === 'token') && 
        formData.find(param => param.name === 'token').value !== '') {
      const cbParams = [
        {name: 'process', value: 'user_login'},
        {name: 'contact_email', value: user.contact.contact_email},
        {name: 'user_key', value: formData.find(param => param.name === 'new_key') && 
                                  formData.find(param => param.name === 'new_key').value},
        {name: 'organization_id', value: organizationID}
      ];    
          
      props.userLoadState(formData, 'users', 'user', cbParams);
    }else if(formData.find(param => param.name === 'urc') &&
              formData.find(param => param.name === 'urc').value !== '') {
      props.userLoadState(formData, 'users', 'reset.update');
    }
  }

  function keyTest(params) {
    switch(params.name) {
      case 'new_key':
        setKey(params.value);
        break;
      case 'new_key_repeat':
        setKeyCtl(params.value);
        break;
      default:
        break;
    }
  }
  
  function handlePasswordUpdate(isUpdated) {
    
  }
  
  function handlePasswordReset(isReset) {
    
  }
  
  return (
    <div className="form-full">
      {redirect && <Redirect to={redirect} />}
      {form}
      {formInProgress && 
        <Loader label="Processing..."
                isOverlay={true}
                lightBG={true} />
      }
    </div>
  )
}
