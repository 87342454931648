import * as methods from 'methods/reducers';

export default function admin(state = {}, action) {
  switch(action.type) {
    case 'ADMIN_SET_COMPONENT':
      let pathNodes;
      if(action.currentPath) {
        pathNodes = action.currentPath.split('/');
        pathNodes = pathNodes.filter(node => node !== '');

        if(state.component && !state.component.activities) {
          pathNodes[pathNodes.length - 1] = action.payload.value;
        }else{
          pathNodes.push(action.payload.value);
        }
      }else{
        pathNodes = window.location.pathname.split('/');
        pathNodes = pathNodes.filter(node => node !== '');

        let pathEnd = false;
        pathNodes.map((node, index ) => {
          if(node === action.payload.value) {
            pathEnd = index+1;
          }
          return true;
        })
        if(pathEnd) {
          pathNodes.splice((pathEnd));
        }
      }

      let path = '';
      pathNodes.map(node => {
        if(path[path.length -1] === '/') {
          path += '/'+node;
        }else{
          path += '/'+node;
        }
        return true;
      })
      const params = {section: pathNodes[1], activity: pathNodes[2], component: pathNodes[3], cpntactivity: pathNodes[4], cpntcomponent: pathNodes[5], }

      window.history.pushState(state.component ? state.component : null , action.pagetTitle ? action.pagetTitle : null , path);
      return {...state, component: action.payload, params: params};
    case 'ADMIN_SET_PROP':
      const setLocation = action.name.split('.');
      let setVal = action.payload;
      for(var i = (setLocation.length-1); i >= 0; i--) {
        // start with isArray as false and aLocation empty;
        let isArray = false;
        let aLocation = [];

        // Check if current level is an array ([] supplied with or without index - [] or [1])
        if(setLocation[i].includes('[') && setLocation[i].includes(']')) {
          const aLoc = setLocation[i].split('[');
          aLocation = [aLoc[0], aLoc[1].replace(']', '')];
          isArray = true;
        }

        // Checking for existing values at this location
        // Start at base current state value
        let curVal = state;

        // iterate to find if there is a current value at the current level;
        for(var j = 0; j <= i; j++) {
          let jaLocation = [];
          let jisArray = false;

          // Check if current level is an array ([] supplied with or without index - [] or [1])
          if(setLocation[j].includes('[') && setLocation[j].includes(']')) {
            const aLoc = setLocation[j].split('[');
            jaLocation = [aLoc[0], aLoc[1].replace(']', '')];
            jisArray = true;
          }

          if(curVal) {
            if(jisArray && curVal[jaLocation[0]]) {
              curVal = curVal[jaLocation[0]];
            } else if (curVal[setLocation[j]]) {
              curVal = curVal[setLocation[j]];
            }else{
              curVal = null;
            }
          }
        }

        if(isArray) {

          if(curVal && Array.isArray(curVal)) {
            if(aLocation[1].toLowerCase() === 'x') {
              // if x is supplied in braces [x] replace entire array with new value
              setVal = {[aLocation[0]]:[setVal]};
            }else if(aLocation[1] === '' || !aLocation[1]) {
              //  if no number is supplied in braces [] push set value to exisitng array
              let newVal;

              if(curVal && Array.isArray(curVal)) {
                // if the current value is an array combine current and set
                newVal = curVal;
                newVal.push(setVal);
                setVal = {[aLocation[0]]: newVal};
              }else{
                // if no current value or value isn't an array
                setVal = {[aLocation[0]]: [setVal]};
              }
            }else if(!isNaN(parseInt(aLocation[1]))) {
              // if an index has been supplied [0] splice into existing array
              // Creating a holding for the new merged values
              let mergeVal = {};
              let newVal;

              // Check if the current value is an object and set value needs to be merged.
              if(curVal[aLocation[1]].constructor === Object &&
                  Object.keys(curVal[aLocation[1]]).length > 0) {

                const valKeys = Object.keys(curVal[aLocation[1]]);
                // valKeys.map(vKey => {
                //   if(curVal[aLocation[1]][vKey] && Object.keys(curVal[aLocation[1]][vKey]).length > 0 && setVal[vKey]) {
                //     mergeVal[vKey] = {...curVal[aLocation[1]][vKey], ...setVal[vKey]};
                //   }
                // })
                // SWITCHED TO A FOR STATEMENT TO AVOID no-loop-funciton ERROR
                for(i = 0; i < valKeys.length; i++) {
                  if(curVal[aLocation[1]][valKeys[i]] && Object.keys(curVal[aLocation[1]][valKeys[i]]).length > 0 && setVal[valKeys[i]]) {
                    mergeVal[valKeys[i]] = {...curVal[aLocation[1]][valKeys[i]], ...setVal[valKeys[i]]};
                  }
                }
              }
              if(mergeVal) {
                // Merge current, set and then merge values
                newVal = {...curVal[aLocation[1]], ...setVal, ...mergeVal};

                // Add the value back into the array at the existing position:
                curVal.splice(aLocation[1], 1, newVal);
              }

              // return updated array and values into set values
              setVal = {[aLocation[0]]: curVal};
            }
          }else{
            setVal = {[aLocation[0]]:[setVal]};
          }

        } else {
          if(setVal && setVal.constructor === Object && curVal) {
            setVal = {[setLocation[i]]:{...curVal, ...setVal}};
          }else{
            setVal = {[setLocation[i]]: setVal};
          }
        }
      }
      sessionStorage.setItem('admPrd', JSON.stringify(state.product));
      return {...state, ...setVal};
    case 'ADMIN_REMOVE_PROP':
      let newVal = state;
      if(action.location) {
        const removeNodes = action.location.split('.');
        Array.isArray(removeNodes) &&
        removeNodes.map(node => {
          newVal = newVal[node];
          return true;
        })
        if(Array.isArray(newVal) && action.index) {
          newVal.splice(action.index, 1);
        }
      }
      sessionStorage.setItem('admPrd', JSON.stringify(state.product));
      return {...state};
    case 'ADMIN_DELETE_VAL':
      let removeValState;
      if(Array.isArray(action.payload)) {
        action.payload.map(location => {
          removeValState = methods.removeStateValue(state, location);
          return true;
        })
      }else{
        removeValState = methods.removeStateValue(state, action.payload);
      }

      if(removeValState) {
        // sessionStorage.setItem('admPrd', JSON.stringify(state.product));
        return {...removeValState};
      }else{
        return {...state};
      }
      case 'ADMIN_SET_VALUE':
        const setValState = methods.setStateValue(state, action.name, action.value);
        if(setValState) {
          const stateSetLoc = action.name.includes('.') ? action.name.split('.')[0] : action.name;
          let storageName = '';
          switch(stateSetLoc) {
            case null:
              break;
            case 'organization':
              storageName = 'admOrg';
              break;
            case 'product':
              storageName = 'admPrd';
              break;
            case 'user':
              storageName = 'admUsr';
              break;
            default:
              storageName = `adm${stateSetLoc}`;
              break;
          }
          action.sStore &&
          sessionStorage.setItem(storageName, JSON.stringify(state[stateSetLoc]));
        }
        return {...state};
      case 'ADMIN_DELETE_VALX' :
      const deleteNodes = action.location.split('.');
      let newState = state;

      deleteNodes.map((node, index) => {
        if(index+1 < deleteNodes.length) {
          newState = newState[node];
        }else{
          delete newState[node];
        }
        return true;
      })
      sessionStorage.setItem('admPrd', JSON.stringify(state.product));
      return {...state};
    case 'ADMIN_UPDATE_VAL':
      if(action.location) {
        const locationNodes = action.location.split('.');
        let updatedProp = state;
        locationNodes.map(node => {
          updatedProp = updatedProp[node];
          return true;
        })

        if(Array.isArray(updatedProp)) {
          let emptyArray = true;
          while(emptyArray === true) {
            emptyArray = false;
            for(i = 0; i < updatedProp.length; i++ ) {
              if(updatedProp[i] === '' || (typeof updatedProp[i] === 'object' && Object.entries(updatedProp[i]).length <= 0)) {
                updatedProp.splice(i, 1);
                emptyArray = true;
                break;
              }
            }
          }

          let propUpdated = false;
          if(action.isProp) {
            updatedProp.map((prop, index) => {
              if(prop.property_name && prop.property_name.toLowerCase() === action.name.toLowerCase()) {
                updatedProp.slice(index, 1);
                updatedProp.splice(index, 1, {property_name: prop.property_name.toLowerCase(), property_value: action.value});
                propUpdated = true;
              }
              return true;
            })
            if(!propUpdated) {
              updatedProp.push({property_name: action.name, property_value: action.value});
            }
          } else {
            // TODO for none property formValues
            console.log("TODO - Non-Property Values in Reducer");
          }
        }
      }
      sessionStorage.setItem('admPrd', JSON.stringify(state.product));
      return {...state};
    case 'ADMIN_LOAD_FROM_SESSION':
      return {...state, [action.name]: action.payload};
    case 'ADMIN_PRODUCT_ADD':
      sessionStorage.setItem('admPrd', JSON.stringify(action.payload));
      sessionStorage.setItem('admPrdCtl', JSON.stringify(action.payload));
      return {...state, product: action.payload, greeby: 'crab', productCtl: action.payload};
    case 'PRODUCT_TYPE_GET':
      action.payload.sort((a, b) => {return a.en && b.en && a.en.type_name > b.en.type_name ? 1 : -1});
      return {...state, product_types: action.payload};
    case 'PRODUCT_CATEGORIES_GET':
      return {...state, product_categories: action.payload}
    case 'PRODUCT_PROPERTIES_GET':
      return {...state, product_properties: {...state.product_properties, [action.language]: action.payload}};
    case 'ADMIN_GET_PRODUCTS':
      return {...state, products: action.payload}
    case 'ADMIN_SET_GUIDE':
      return {...state, guide: action.payload};
    case 'ADMIN_LOAD_STATE':
      let payload = action.payload;
      if(action.location.indexOf('.')) {
        const locationNodes = action.location.split('.');
        let valVar = null;
        for(i = locationNodes.length - 1; i >= 0; i--) {
          let stateVal = state;
          for(j = 0; j <= i; j++) {
            if(stateVal && stateVal[locationNodes[j]]) {
              stateVal = stateVal[locationNodes[j]];
            }else{
              stateVal = null;
            }
          }
          if(i === locationNodes.length-1) {
            valVar = {[locationNodes[i]]: action.payload};
          }else{
            if(stateVal) {
              valVar = {[locationNodes[i]]: {...stateVal, ...valVar}}
            }else{
              valVar = {[locationNodes[i]]: {...valVar}};
            }
          }
        }
        payload = valVar;
      }
      return {...state, ...payload};
    case 'ADMIN_TEST_SET':
      methods.traverseArrayNode(state, action.node, action.value);
      return state;
    default:
      return state;
  }

}
