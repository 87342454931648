import React, {useEffect, useState, Fragment} from 'react';

import InputMulti from 'components/forms/elements/InputMulti';
import Loader from 'components/atoms/Loader';
import ShippingOption from 'components/profiles/shipping/ShippingOption';
import FormShippingOptionAddEdit from 'components/forms/common_forms/FormShippingOptionAddEdit';
import Control from 'components/atoms/Control';

export default(props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [shippingOptions, setShippingOptions] = useState(null);
  
  // HOOKS
  useEffect(() => {
    if(props.userID) {
      setUserID(props.userID)
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token) {
      setToken(props.token)
    }
  }, [props.token]);

  useEffect(() => {
    if(props.organizationID && organizationID !== props.organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    props.organizationName &&
    props.organizationName !== organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);
  
  useEffect(() => {
    if(props.languageCode && languageCode !== props.languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    if(props.userAccess && props.userAccess !== userAccess) {
      setUserAccess(props.userAccess);
    } 
  }, [props.userAccess]);
  
  useEffect(() => {
    if(userID && token && 
      (userAccess.super || userAccess.admin)) {
      const shippingParams = [
        {name: 'process', value: 'get_shipping_options'},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
      ];
      if(!props.objExists(props.admin, 'shippingOptions') ||
          props.objGetValue(props.admin, 'reload.shippingOptions') === true) {
            props.adminLoadState(shippingParams, 'organization', 'shippingOptions');
            if(props.objGetValue(props.admin, 'reload.shippingOptions') === true) {
              props.adminDeleteVal('reload.shippingOptions');
              setShippingOptions(null);
            }
        }
      
    }
  }, [userID, token, userAccess, props.admin]);
  
  useEffect(() => {
    if(props.objExists(props.admin, 'shippingOptions')) {
      setShippingOptions(props.admin.shippingOptions);
    }
  }, [props.admin.shippingOptions]);
  
  // FUNCTIONS
  function loadAddShippingOption() {
    props.setModal(
      <FormShippingOptionAddEdit {...props}
          userID={userID}
          token={token}
          organizationID={organizationID}
          languageCode={languageCode}
          userAccess={userAccess}
          coreOrg={null}
          actionCancel={()=>{props.setModal(null)}}
          actionAddEdit={addShippingOption} />
    )
  }
  
  function addShippingOption(params) {
    props.setModal(null);
    console.log("Add Params", params);
    props.adminLoadState(params, 'organizations', 'reload.shippingOptions');
  }
  
  console.log("SHIPPING OPTIONS", props.admin);
  return (
    <div>
      <h2>Shipping Options</h2>
      
      {shippingOptions ?
        <Fragment>
          <div className="grid col-4" style={{gridTemplateColumns: 'auto 1fr 4fr auto'}}>
            <div className="grid-header text-center">Active</div>
            <div className="grid-header">Option</div>
            <div className="grid-header">Description</div>
            <div className="grid-header">Controls</div>
            
            {shippingOptions.map((shipping, index) => {
              return <ShippingOption {...props}
                          userID={userID}
                          token={token}
                          organizationID={organizationID}
                          userAccess={userAccess}
                          shipping={shipping} />
            })}
            
          </div>
          <Control icon="truck" 
              label="Add Shipping Option"
              showLabel={true} 
              action={loadAddShippingOption} />
        </Fragment>
      :
        <Loader label="Getting shipping options..." />
      }
    </div>
  )
}
