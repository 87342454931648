import React, {useEffect, useState} from 'react';

import FormDynamic from 'components/forms/FormDynamic';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [coreUser, setCoreUser] = useState(null);
  const [userName, setUserName] = useState(null);
  const [userOrgs, setUserOrgs] = useState(null);
  const [orgs, setOrgs] = useState(null);
  const [userRoles, setUserRoles] = useState(null);
  const [value, setValue] = useState(null);
  const [optionsOrgs, setOptionsOrgs] = useState(null);
  const [optionsRoles, setOptionsRoles] = useState(null);
  const [fieldsets, setFieldsets] = useState(null);
  const [form, setForm] = useState(null);

  // HOOKS
  useEffect(() => {
    props.userID &&
    props.userID !== userID &&
    setUserID(props.userID);
  }, [props.userID]);

  useEffect(() => {
    props.token &&
    props.token !== token &&
    setToken(props.token);
  }, [props.token])

  useEffect(() => {
    props.coreUser &&
    props.coreUser !== coreUser &&
    setCoreUser(props.coreUser);
  }, [props.coreUser]);
  
  useEffect(() => {
    props.organizations &&
    props.organizations !== orgs &&
    setOrgs(props.organizations);
  }, [props.organizations]);
  
  useEffect(() => {
    props.userRoles &&
    props.userRoles !== userRoles &&
    setUserRoles(props.userRoles);
  }, [props.userRoles]);
  
  useEffect(() => {
    if(props.userOrgs && props.userOrgs !== userOrgs) {
      setUserOrgs(props.userOrgs);
    }
  }, [props.userOrgs]);
  
  useEffect(() => {
    if(orgs && Array.isArray(orgs) && orgs.length > 0) {
      let orgList = orgs;
      if(userOrgs && Array.isArray(userOrgs) && userOrgs.length > 0) {
        orgList = orgList.filter(org => !userOrgs.find(uOrg => uOrg.organization_id === org.organization_id));
      }
      
      if(Array.isArray(orgList) 
          && orgList.length > 0) {
        let orgOpts = [];
        orgList.map(org => {
            orgOpts.push(
              {option_name: org.organization_name,
                option_value: org.organization_id
              },
            );
          })
        if(Array.isArray(orgOpts) && orgOpts.length > 0) {
          setOptionsOrgs(orgOpts);
        }else{
          setOptionsOrgs(null);
        }
      }
    }
  }, [orgs, userOrgs]);
  
  useEffect(() => {
    if(userRoles && 
        Array.isArray(userRoles) && 
        userRoles.length > 0) {
      let roleOpts = [];
      userRoles.map(role => {
        role.role_name !== 'Super User' &&
        roleOpts.push(
          {option_name: role.role_name,
            option_value: role.role_id
          }
        )
      });
      if(Array.isArray(roleOpts) && roleOpts.length > 0) {
        setOptionsRoles(roleOpts);
      }else{
        setOptionsRoles(null);
      }
    }
  }, [userRoles]);
  
  useEffect(() => {
    if(userID, token, optionsOrgs && optionsRoles) {
      setFieldsets([
        {fieldset_name: `${props.isChange ? 'Change' : 'Add'} Category`,
          fields: [
            {field_type: 'select',
            field_name: 'organization_id',
            field_label: `Organization`,
            field_value: null,
            field_placeholder: 'Select Organization...',
            field_order: 1,
            field_required: true,
            option_groups: [{options: optionsOrgs}]},
            {field_type: 'select',
            field_name: 'role_id',
            field_label: `User Role`,
            field_value: null,
            field_placeholder: 'Select Role...',
            field_order: 2,
            field_required: true,
            option_groups: [{options: optionsRoles}]},
            {field_type: 'hidden',
            field_name: 'process',
            field_value: 'add_user_to_org'},
            {field_type: 'hidden',
            field_name: 'user_id',
            field_value: userID},
            {field_type: 'hidden',
            field_name: 'token',
            field_value: token},
            {field_type: 'hidden',
            field_name: 'core_user',
            field_value: coreUser},
          ]
        }
      ]);
    }else{
      setFieldsets(null);
    }
  }, [optionsOrgs, optionsRoles]);

  useEffect(() => {
    fieldsets &&
    setForm(
      <FormDynamic
          content={fieldsets}
          name="assign-user-to-org"
          returnObject={true}
          fieldOnChange={handleChange}
          fieldOnLoad={(e)=>{e.preventDefault()}}
          formActions={{formCancel: handleCancel, formSubmit: handleSubmit}} />
    )
  }, [fieldsets]);

  function handleCancel() {
    if(props.actionCancel) {
      props.actionCancel();
    }else{
      props.setModal(null);  
    }
  }

  function handleSubmit(params) {
    if(props.actionAssign) {
      props.actionAssign(params);
    }
  }

  function handleChange(e) {
    if(e && e.name) {
      switch(e.name) {
        case 'category_id':
          // setValue(e.value ? parseInt(e.value) : null);
          break;
        case 'role_id':
          // setParentValue(parseInt(e.value));
          break;
        default:
          break;
      }
    }
  }

  return (fieldsets && form)
}
