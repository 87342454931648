import axios from 'axios';
import * as apis from 'methods/api.js';
import {adminSetVal} from 'actions/admin';

export const filesUpload = (formData, cueReload, stateLocation = null, returnInfo = true, retainModal = false) => dispatch => {
  axios.post(apis.api_url_files, formData).then(res => {
    if(res.data && cueReload) {
      if(cueReload.location && cueReload.type) {
        dispatch({type: `${cueReload.location.toUpperCase()}_SET_VALUE`, name: `reload.${cueReload.type.toLowerCase()}`, value: true});
      }else if(Array.isArray(cueReload)) {
        cueReload.map((reload, index) => {
          dispatch({type: `${reload.location.toUpperCase()}_SET_VALUE`, name: `reload.${reload.type.toLowerCase()}`, value: true});
        })
      }
      retainModal &&
      dispatch({type: 'SET_MODAL', payload: null});  
    }else if(Array.isArray(res.data) && res.data.length > 0) {
      dispatch({type: 'FILES_UPLOAD', payload: res.data, stateLocation: stateLocation});
    }else{
      dispatch({type: 'FILES_UPLOAD', payload: {error: res.data}});
    }
  }).catch(error => {
    dispatch({type: 'FILES_UPLOAD', payload: {error: error}});
    console.log("DISPATCH ERROR - FILES UPLOAD", error);
  })
}

export const filesDeleteVal = (key) => dispatch => {
  dispatch({type: 'FILES_DELETE_VAL', payload: key});
}

export const filesClearUploads = () => dispatch => {
  dispatch({type: 'FILES_CLEAR_UPLOADS'});
}

export const filesSetVal = (key, value) => dispatch => {
  dispatch({type: 'FILES_SET_VAL', key: key, value: value});
}
