import React from 'react';

import ProductPricingDiscounts from 'components/profiles/products/ProductPricingDiscounts';
import FormPricingAddEdit from 'components/forms/common_forms/FormPricingAddEdit';
import IconToken from 'components/icons/IconToken';
import ModalConfirmation from 'components/molecules/ModalConfirmation';

export default (props) => {

  function loadAddEdit() {
    props.setModal(
      <FormPricingAddEdit {...props}
                          pricing={props.priceOption}
                          product_id={props.product_id}
                          actionCancel={()=>{props.setModal(null);}}
                          actionAddEdit={handleAddEdit} />);
  }

  function loadRemove() {
    props.setModal(
      <ModalConfirmation
        {...props}
        message={`Are you sure you want to remove pricing for <b>${props.priceOption.pricing_option}</b>? Any discounts will also be removed.`}
        actionAccept={handleRemove}/>
    )
  }

  function handleAddEdit(params) {
    if(props.actionAddEdit) {
      props.actionAddEdit(params);
    }
  }

  function handleRemove(params) {
    if(props.actionRemove){
      if(params && Array.isArray(params)) {
        props.actionRemove(params);
      }else{
        props.setModal(null);
        props.actionRemove([
          {name: 'process', value: 'update_link_no_id'},
          {name: 'link_type', value: props.pagePricing ? 'pagePricingToProduct' : 'pricingToProduct'},
          {name: 'link_from', value: props.priceOption.pricing_id},
          {name: 'link_to', value: props.product_id},
          {name: 'link_field', value: 'link_removed'},
          {name: 'link_value', value: true}
        ]);
      }
    }
  }

  function handleDiscountAddEdit(params) {
    if(props.actionDiscountAddEdit) {
      props.actionDiscountAddEdit(params);
    }
  }

  return (
    <div className="product-pricing">
      <h4>{props.priceOption.pricing_option}
          <IconToken icon="edit" action={loadAddEdit} />
          <IconToken icon="trash" action={loadRemove} /></h4>
      <div className="product-pricing-row">
        <label>Unit Price</label>
        <div className="product-pricing-value">
          {props.priceDisplay(props.priceOption.pricing_unit_price, props.language_code)}
        </div>
      </div>
      <div className="product-pricing-row">
        <label>Minimum Quantity</label>
        <div className="product-pricing-value">
          {props.priceOption.pricing_min_quantity}
        </div>
      </div>
      <div className="product-pricing-row">
        <label>Base (Minimum Price) Price</label>
        <div className="product-pricing-value">
          {props.priceDisplay(props.priceOption.pricing_base_price, props.language_code)}
        </div>
      </div>
      <div className="product-pricing-row">
        <label>Rounding Method</label>
        <div className="product-pricing-value">
          {props.capitalizeString(props.priceOption.pricing_round, true)}
        </div>
      </div>
      <div className="product-pricing-row">
        <label>Controlled Quantity</label>
        <div className="product-pricing-value">
          {props.priceOption.pricing_ctl_qty ? 'Yes': 'No'}
        </div>
      </div>
      <ProductPricingDiscounts {...props}
                                  discounts={props.objGetValue(props, 'priceOption.discounts')}
                                  pricing_id={props.priceOption.pricing_id}
                                  actionAddEdit={handleDiscountAddEdit}
                                  actionRevmove={handleRemove} />
    </div>
  )
}
