import React, {useEffect, useState, Fragment} from 'react';

import Loader from 'components/atoms/Loader';
import IconSVG from 'components/icons/IconSVG';
import ProductInventoryItem from 'components/profiles/products/ProductInventoryItem';

export default (props) => {	
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [coreOrg, setCoreOrg] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [canEdit, setCanEdit] = useState(null);
	const [isSuper, setIsSuper] = useState(null);
	const [products, setProducts] = useState(null);
	const [inventory, setInventory] = useState(null);
	const [inventorySort, setInventorySort] = useState('product_name');
	const [inventorySortDir, setInventorySortDir] = useState('asc');
	const [filterActive, setFilterActive] = useState(false);
	const [filterInStock, setFilterInStock] = useState(false);
	
	// HOOKS
	useEffect(() => {
		props.userID &&
		setUserID(props.userID);
	  }, [props.userID]);
	
	  useEffect(() => {
		props.token &&
		setToken(props.token);
	  }, [props.token])
	
	  useEffect(() => {
		props.canEdit &&
		setCanEdit(props.canEdit);
	  }, [props.canEdit]);
	  
	  useEffect(() => {
		props.isSuper &&
		setIsSuper(props.isSuper);
	  }, [props.isSuper]);
	
	  useEffect(() => {
		props.organizationID &&
		setOrganizationID(props.organizationID);
	  }, [props.organizationID]);
	
	  useEffect(() => {
		if(props.languageCode) {
		  setLanguageCode(props.languageCode);
		}else{
		  setLanguageCode('en');
		}
	  }, [props.languageCode]);
	 
	useEffect(() => {
		if(props.coreOrg && 
			props.coreOrg !== coreOrg) {
			setCoreOrg(props.coreOrg);
		}
	}, [props.coreOrg]);
	  
	useEffect(()=> {
		if(props.products && 
			props.products !== products) {
			setProducts(props.products);
		}
	}, [props.products]);
	
	useEffect(() => {
		props.filterActive && setFilterActive(true);
		!props.filterActive && setFilterActive(false);
	}, [props.filterActive]);
	
	useEffect(() => {
		props.filterInStock && setFilterInStock(true);
		!props.filterInStock && setFilterInStock(false);
	}, [props.filterInStock]);
	  
	useEffect(() => {
		if(languageCode && 
			Array.isArray(products) && 
			products.length > 0) {
			let inventoryProducts = []; 
			if(props.objExists(products, '[0].product_id')) {
				const prodInventory = products.filter(prod => prod.product_inventoried === 1);
				if(prodInventory && prodInventory.length > 0 ) {
					inventoryProducts.push(...prodInventory);
				}
			}else if(props.objExists(products, '[0].organization_id')) {
				products.map(org => {
					const orgInventory = 
						props.objExists(org, `${languageCode}`) &&
						Array.isArray(org.details[languageCode]) ?
							org.details[languageCode].filter(prod => prod.product_inventoried === 1)
						: null;
					if(orgInventory && orgInventory.length > 0) {
						inventoryProducts.push(...orgInventory);	
					}
					
				}) 
			}
			if(inventoryProducts && inventoryProducts.length > 0) {
				let filteredInventoryProducts = inventoryProducts;
				if (filterActive) {
					filteredInventoryProducts = filteredInventoryProducts.filter(prod => prod.product_published === 1);
				}
				if (filterInStock) {
					filteredInventoryProducts = filteredInventoryProducts.filter(prod => prod.product_stock > 0);
				}
				if (filteredInventoryProducts && filteredInventoryProducts.length > 0 ) {
					inventoryProducts = filteredInventoryProducts;
				}
				switch(inventorySort) {
					case 'product_published':
					case 'product_days_available':
					case 'product_days_ordered':
					case 'product_bundle':
					case 'product_stock_min':
					case 'product_stock':
						inventorySortDir === 'asc' ? setInventory(inventoryProducts.sort((a, b) => {return a[inventorySort] > b[inventorySort] ? 1 : -1})) : setInventory(inventoryProducts.sort((a, b) => {return a[inventorySort] > b[inventorySort] ? -1 : 1}));
						break;
					case 'product_sku':
						inventorySortDir === 'asc' ? setInventory(inventoryProducts.sort((a, b) => {return a[languageCode][inventorySort] > b[languageCode][inventorySort] ? 1 : -1})) : setInventory(inventoryProducts.sort((a, b) => {return a[languageCode][inventorySort] > b[languageCode][inventorySort] ? -1 : 1}));
						break;
					case 'product_name':
					default:
					  inventorySortDir === 'asc' ? setInventory(inventoryProducts.sort((a, b) => {return a[languageCode][inventorySort].toLowerCase() > b[languageCode][inventorySort].toLowerCase() ? 1 : -1})) : setInventory(inventoryProducts.sort((a, b) => {return a[languageCode][inventorySort].toLowerCase() > b[languageCode][inventorySort].toLowerCase() ? -1 : 1}));
					  break; 
				  }
			}else{
				setInventory('no products');
			}
		} else {
			setInventory('no products');
		}
	}, [products, languageCode, coreOrg, inventorySort, inventorySortDir, filterActive, filterInStock]);
	
	function handleHeaderClick(field_name) {
		setInventorySort(field_name);
		if (inventorySort === field_name) {
			setInventorySortDir(inventorySortDir === 'asc' ? 'dsc' : 'asc');
		}
	}
	
	return (
		<div className="grid col-8" style={{gridTemplateColumns: 'auto 8fr 1fr 1fr 1fr 1fr 1fr 1fr'}}>
			<div className={`cursor-pointer grid-header ${inventorySortDir === 'asc' ? 'sort-asc' : 'sort-dsc'} ${inventorySort === 'product_sku' ? 'active' : ''}`} onClick={(e) => handleHeaderClick('product_sku')}>Product SKU &nbsp;{inventorySort === 'product_sku' && <IconSVG icon="arrow" />}</div>
			<div className={`cursor-pointer grid-header ${inventorySortDir === 'asc' ? 'sort-asc' : 'sort-dsc'} ${inventorySort === 'product_name' ? 'active' : ''}`} onClick={(e) => handleHeaderClick('product_name')}>Product Name &nbsp;{inventorySort === 'product_name' && <IconSVG icon="arrow" />}</div>
			<div className={`cursor-pointer grid-header ${inventorySortDir === 'asc' ? 'sort-asc' : 'sort-dsc'} text-center ${inventorySort === 'product_published' ? 'active' : ''}`} onClick={(e) => handleHeaderClick('product_published')}>Active{inventorySort === 'product_published' && <IconSVG icon="arrow" />}</div>
			<div className={`cursor-pointer grid-header ${inventorySortDir === 'asc' ? 'sort-asc' : 'sort-dsc'} text-center ${inventorySort === 'product_days_available' ? 'active' : ''}`} onClick={(e) => handleHeaderClick('product_days_available')}>Days<br />Available {inventorySort === 'product_days_available' && <IconSVG icon="arrow" />}</div>
			<div className={`cursor-pointer grid-header ${inventorySortDir === 'asc' ? 'sort-asc' : 'sort-dsc'} text-center ${inventorySort === 'product_days_ordered' ? 'active' : ''}`} onClick={(e) => handleHeaderClick('product_days_ordered')}>Days<br />Ordered {inventorySort === 'product_days_ordered' && <IconSVG icon="arrow" />}</div>
			<div className={`cursor-pointer grid-header ${inventorySortDir === 'asc' ? 'sort-asc' : 'sort-dsc'} text-center ${inventorySort === 'product_bundle' ? 'active' : ''}`} onClick={(e) => handleHeaderClick('product_bundle')}>Bundle<br />Qty {inventorySort === 'product_bundle' && <IconSVG icon="arrow" />}</div>
			<div className={`cursor-pointer grid-header ${inventorySortDir === 'asc' ? 'sort-asc' : 'sort-dsc'} text-center ${inventorySort === 'product_stock_min' ? 'active' : ''}`} onClick={(e) => handleHeaderClick('product_stock_min')}>Stock<br />Min {inventorySort === 'product_stock_min' && <IconSVG icon="arrow" />}</div>
			<div className={`cursor-pointer grid-header ${inventorySortDir === 'asc' ? 'sort-asc' : 'sort-dsc'} text-center ${inventorySort === 'product_stock' ? 'active' : ''}`} onClick={(e) => handleHeaderClick('product_stock')}>In<br />Stock {inventorySort === 'product_stock' && <IconSVG icon="arrow" />}</div>
			
			{inventory ?
				inventory === 'no products' || (Array.isArray(inventory) && inventory.length <= 0) ?
					<div style={{gridColumn: '1 / span 8'}}>No Products in Inventory</div>
				:
					Array.isArray(inventory) &&
					<Fragment>
						{inventory.map((product, index) => {
							return(
								<ProductInventoryItem
									{...props}
									key={`ipLI${index}`}
									product={product}
									languagecode={languageCode} />
							)
						})}
					</Fragment>
			:
				<Loader label="Checking inventory..." />
			}
		
		</div>
	)
}