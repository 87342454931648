import React, {useEffect, useState, Fragment} from 'react';

import ValueSelector from 'components/forms/elements/ValueSelector';
import InputNumberCtl from 'components/forms/elements/InputNumberCtl';
import Input from 'components/forms/elements/Input';

export default (props) => {
	const [format, setFormat] = useState('rgb');
	const [colourRGB, setColourRGB] = useState(null);
	const [colourCMYK, setColourCMYK] = useState(null);
	const [colourHex, setColourHex] = useState(null);
	const [update, setUpdate] = useState(null);
	
	// useEffect(() => {
	// 	// setColourRGB({
	// 	// 	r: 200,
	// 	// 	g: 50,
	// 	// 	b: 50
	// 	// });
	// 	
	// }, [props.colourRGB]);
	
	useEffect(() => {
		if(props.value && 
			Object.entries(props.value).length === 3 && 
			props.value.r >= 0 &&
			props.value.g >= 0 &&
			props.value.b >= 0) {
			setColourRGB(props.value);
			setUpdate('rgb');
		}
	}, [props.value]);
	
	useEffect(() => {
		if(colourRGB){
			if(props.actionOnChange) {
				props.actionOnChange(props.name, 'rgb', colourRGB);
			}
			if(update === 'rgb' || update == 'hex' ) {
				const clrCMYK = convertRGBToCMYK(colourRGB);
				if(clrCMYK && clrCMYK !== colourCMYK) {
					setColourCMYK(clrCMYK);
				}	
			}
			
			if(update === 'rgb' || update == 'cmyk' ) {
				const clrHex = convertRGBToHex(colourRGB)
				if(clrHex && clrHex !== colourHex) {
					setColourHex(clrHex);
				}	
			}
			setUpdate(null);
		}
			
	}, [colourRGB, update]);
	
	useEffect(()=> {
		if(update === 'cmyk' && colourCMYK) {
			let clrRGB = convertCMYKtoRGB(colourCMYK);
			if(clrRGB && clrRGB !== colourRGB) {
				setColourRGB(clrRGB);
			}
		}
	}, [colourCMYK, update]);
	
	useEffect(()=> {
		if(update === 'hex' && colourHex) {
			let clrRGB = convertHexToRGB(colourHex);
			if(clrRGB && clrRGB !== colourRGB) {
				setColourRGB(clrRGB);
			}
		}
	}, [colourHex, update]);
	
	// FUNCTIONS 
	function selectFormat(value) {
		setFormat(value.toLowerCase())
	}
	
	function updateColour(params) {
		if(params.id === 'rgb') {
			const clrValue = parseInt(params.value);
			setUpdate('rgb');
			setColourRGB({
				...colourRGB,
				[params.name]: parseInt(params.value)
			})
		}else if(params.id === 'cmyk') {
			const clrValue = parseInt(params.value);
			setUpdate('cmyk');
			setColourCMYK({
				...colourCMYK,
				[params.name]: parseInt(params.value)
			})
		}else if(params.name === 'colour_hex') {
			setUpdate('hex');
			if(params.value && params.value !== colourHex){
				setColourHex(params.value.toUpperCase());
			}
		}
	}
	
	function convertRGBToCMYK(rgb) {
		let clrR = rgb.r/255;
		let clrG = rgb.g/255;
		let clrB = rgb.b/255;
		
		let clrK = 1 - Math.max(rgb.r/255, rgb.g/255, rgb.b/255);
		let clrC = (1-clrR-clrK)/(1-clrK);
		let clrM = (1-clrG-clrK)/(1-clrK);
		let clrY = (1-clrB-clrK)/(1-clrK);
		
		return {
			c: Math.round(clrC*100), 
			m: Math.round(clrM*100), 
			y: Math.round(clrY*100), 
			k: Math.round(clrK*100)
		};
	}
	
	function convertRGBToHex(rgb) {
		let clrR = rgb.r;
		let clrG = rgb.g;
		let clrB = rgb.b;
		return `${clrR ? clrR.toString(16).toUpperCase() : ''}${clrG ? clrG.toString(16).toUpperCase() : ''}${clrB ? clrB.toString(16).toUpperCase() : ''}`;
	}
	
	function convertHexToRGB(hex) {
		if(typeof hex === 'string' && hex.length >= 6) {
			let clrR = parseInt(hex.substring(0, 2), 16);
			let clrG = parseInt(hex.substring(2,4), 16);
			let clrB = parseInt(hex.substring(4, 6), 16);
			
			return {r: clrR, g: clrG, b: clrB};
		}else{
			return null;
		}
		
	}
	
	function convertCMYKtoRGB(cmyk) {
		let clrC = cmyk.c/100;
		let clrM = cmyk.m/100;
		let clrY = cmyk.y/100;
		let clrK = cmyk.k/100;
		
		let clrR = Math.round(255 * (1-clrC) * (1-clrK));
		let clrG = Math.round(255 * (1-clrM) * (1-clrK));
		let clrB = Math.round(255 * (1-clrY) * (1-clrK));
		
		return {r: clrR, g: clrG, b: clrB};
	}
	
	
	return (
		<div className="block-border">
			<div className="form-row">
				<ValueSelector {...props}
					label="Colour Format"
					values={['rgb', 'cmyk', 'hex']}
					value={format}
					actionSelect={selectFormat}
					textValues={true} />
			</div>
			{format === 'rgb' &&
				<Fragment>
					<div className="form-row">
						<InputNumberCtl 
							{...props}
							label="R"
							name="r"
							value={colourRGB && colourRGB.r}
							reset={null}
							onChange={updateColour}
							id={'rgb'}
							float={0}
							factor={1}
							maxValue={255}
							minValue={0}
							orientation="vertical" />
						<InputNumberCtl 
							{...props}
							label="G"
							name="g"
							value={colourRGB && colourRGB.g}
							reset={null}
							onChange={updateColour}
							id={'rgb'}
							float={0}
							factor={1}
							maxValue={255}
							minValue={0}
							orientation="vertical" />
						<InputNumberCtl 
							{...props}
							label="B"
							name="b"
							value={colourRGB && colourRGB.b}
							reset={null}
							onChange={updateColour}
							id={'rgb'}
							float={0}
							factor={1}
							maxValue={255}
							minValue={0}
							orientation="vertical" />
					</div>
				</Fragment>
			}
			{format === 'cmyk' &&
				<Fragment>
					<div className="form-row">
						<InputNumberCtl 
							{...props}
							label="C"
							name="c"
							value={colourCMYK && colourCMYK.c}
							reset={null}
							onChange={updateColour}
							id={'cmyk'}
							float={0}
							factor={1}
							maxValue={100}
							minValue={0}
							orientation="vertical" />
						<InputNumberCtl 
							{...props}
							label="M"
							name="m"
							value={colourCMYK && colourCMYK.m}
							reset={null}
							onChange={updateColour}
							id={'cmyk'}
							float={0}
							factor={1}
							maxValue={100}
							minValue={0}
							orientation="vertical" />
						<InputNumberCtl 
							{...props}
							label="Y"
							name="y"
							value={colourCMYK && colourCMYK.y}
							reset={null}
							onChange={updateColour}
							id={'cmyk'}
							float={0}
							factor={1}
							maxValue={100}
							minValue={0}
							orientation="vertical" />
						<InputNumberCtl 
							{...props}
							label="K"
							name="k"
							value={colourCMYK && colourCMYK.k}
							reset={null}
							onChange={updateColour}
							id={'cmyk'}
							float={0}
							factor={1}
							maxValue={100}
							minValue={0}
							orientation="vertical" />
					</div>
				</Fragment>
			}
			{format === 'hex' &&
				<Fragment>
					<div className="form-row">
						<Input {...props}
							name="colour_hex"
							label="Hex Colour"
							value={colourHex}
							onChange={updateColour} />
					</div>
				</Fragment>
			}
		
		</div>
		
	)
}