import React, {useEffect, useState} from 'react';

import FormDynamic from 'components/forms/FormDynamic';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [orgCode, setOrgCode] = useState(null);
  const [orgCodeSet, setOrgCodeSet] = useState(false);
  const [orgFields, setOrgFields] = useState(null);
  const [orgForm, setOrgForm] = useState(null);
  
  useEffect(() => {
    if(props.userID) {
      setUserID(props.userID)
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token) {
      setToken(props.token)
    }
  }, [props.token]);

  useEffect(() => {
    if(props.organizationID && organizationID !== props.organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    props.organizationName &&
    props.organizationName !== organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);
  
  useEffect(() => {
    if(props.languageCode && languageCode !== props.languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    if(props.userAccess && props.userAccess !== userAccess) {
      setUserAccess(props.userAccess);
    } 
  }, [props.userAccess]);

  
  // useEffect(() => {
  //   if(props.objExists(props.admin, 'organization') && orgCode) {
  //     !orgCodeSet &&
  //     props.adminSetVal('organization.organization_code', orgCode);
  //   }else{
  //     // props.objExists(props.admin, 'organization.organization_code') &&
  //     // props.adminDeleteVal('organization.organization_code');
  //   }
  //
  // }, [orgCode, props.admin.organization]);



  useEffect(() => {
    setOrgFields(
      [{fieldset_name: 'New Organization',
        fields: [
          {field_type: 'text',
          field_name: 'organization.organization_name',
          field_label: 'Organization Name',
          field_value: props.objExists(props.admin, 'organization.organization_name') ?
                        props.admin.organization.organization_name : null,
          field_required: true,
          field_validate: true,
          field_order: 1
          },
          {field_type: 'text',
          field_name: 'organization.organization_code',
          field_label: 'Organization Code',
          field_value: props.objExists(props.admin, 'organization.organization_code') ?
                        props.admin.organization.organization_code : orgCode ? orgCode : null,
          field_required: true,
          field_validate: true,
          field_order: 2
          },
        ]}]
    )

    if(props.objExists(props.admin, `organization.organization_id`) &&
      props.objExists(props.admin, `organization.organization_code`) &&
      props.admin.organization.organization_code !== null) {
        console.log("!!!!!!Setting Org Code", props.admin.organization.organization_code);
        setOrgCode(props.admin.organization.organization_code);
        setOrgCodeSet(true);
    }

    // props.adminDeleteVal('organization.media');
    // props.adminDeleteVal('organization.locations');
  }, [userID, orgCode, props.admin.organization]);

  useEffect(() => {
    orgFields &&
    setOrgForm(
      <FormDynamic
        content={orgFields}
        name="orgAddDetails"
        fieldOnChange={fieldOnChange}
        fieldOnLoad={fieldOnLoad}
        closable={false}
        unmountOnClick={false}
        cancelable={false}
        includeControls={false}
        multiStep={false}
        formClass=""
        valActions={{isEmail: props.isEmail}}
      />
    )

  }, [orgFields]);

  function setCode(value) {
    if(value) {
      value = value.toLowerCase().replace(/[^a-z ]/g, '');
      const valueNodes = value.split(' ');
      let code = '';
      valueNodes.map(node => {
        if(node[0]) {
          code += node[0].toUpperCase();
        }
      })
      if(code && code.length > 0) {
        setOrgCode(code);
        props.adminSetVal('organization.organization_code', code);
      }
    }
    return;
  }

  function fieldOnChange(e) {
    if(e.value &&
      e.value !== null &&
      e.value !== undefined &&
      e.value.replace(/[ ]/g, '').length > 0) {
      if(e.name.includes('.organization_name') &&
        (props.objGetValue(props.admin, 'organization.ogranization_code') !== orgCode ||
        !props.objExists(props.admin, 'organization.organization_code'))) {
        setCode(e.value);
      }
      console.log("Setting a value for", e.name, e.value);
      props.adminSetVal(e.name, e.value.trim());
    }else{
      if(e.name.includes('.organization_name')) {
        // setOrgCode(null);
      }
      // props.adminDeleteVal(e.name);
    }
    return;
  }

  useEffect(() => {
    console.log("org name", props.objGetValue(props.admin, 'organization.organization_name'));
    if(!orgCodeSet && props.objExists(props.admin, 'organization.organization_name')) {
      setCode(props.admin.organization.organzization_name);
    }

  }, [props.objExists(props.admin, 'organization.organization_name') && props.admin.organization.organzization_name,
      props.objExists(props.admin, 'organization.organization_code') && props.admin.organization.organzization_code]);



  function fieldOnLoad(e) {
    console.log("on load", e.name, e.value);

    return;
  }

  console.log("Organization:", props.admin.organization);
  console.log("ORGANIZATION", props.admin.organizations);
  return (
    <div>
      <p>Create an organization</p>
      {orgForm}
    </div>
  )
}
