import React from 'react';

export default (props) => {
  return (
    <div className="admin-com-board">
      <div className="user-id">
        <div className="user-avatar">
        </div>
        {props.user && props.objExists(props.user, 'contact') &&
          <div className="user-info">
            <h3>{`${props.user.contact.contact_name_first} ${props.user.contact.contact_name_last}`}</h3>
            <p>{props.user.user_role}</p>
          </div>
        }
      </div>
      {props.user.user_role === 'Super Userz' &&
        <div className="admin-com-msgs">
          <h4>Your messages</h4>
          <p><b>Jamie:</b> "you are cool"</p>
          <p><b>Jamie:</b> "I love Star Wars"</p>
        </div>
      }
      {props.user.user_role === 'Super Userz' &&
        <div className="admin-com-tasks">
          <h4>Your tasks</h4>
          <p>Drink coffee</p>
          <p>Finish this application</p>
        </div>
      }
    </div>
  )
}
