import React, {useEffect, useState} from 'react';

import ProductListItem from 'components/profiles/products/ProductListItem';
import ProductListCtl from 'components/profiles/products/ProductListCtl';


export default (props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [coreOrg, setCoreOrg] = useState(null);
  const [products, setProducts] = useState(null);
  const [productsFiltered, setProductsFiltered] = useState(null);
  const [curProducts, setCurProducts] = useState(null);
  const [categories, setCategories] = useState(null);
  const [types, setTypes] = useState(null);
  const [languages, setLanguages] = useState(null);
  const [organizations, setOrganizations] = useState(null);
  const [filter, setFilter] = useState(null);
  const [clearFilter, setClearFilter] = useState(null);
  const [activeFilters, setActiveFilters] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  const [searchOption, setSearchOption] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [sort, setSort] = useState(null);
  const [order, setOrder] = useState(null);
  const [userAccess, setUserAccess]=useState(null);
  const [curLanguage, setCurLanguage] = useState(null);
  const [searchParams, setSearchParams] = useState(null);

  const searchOptions = [{name: 'Name', value: 'product_name', default: true},
                        {name: 'Description', value: 'product_description', default: false},
                        {name: 'SKU', value: 'product_sku', default: false}];

  useEffect(() => {
    props.userID &&
    props.userID !== userID &&
    setUserID(props.userID);
  }, [props.userID]);

  useEffect(() => {
    props.token &&
    props.token !== token &&
    setToken(props.token);
  }, [props.token])

  useEffect(() => {
    props.organizationID &&
    props.organizationID !== organizationID &&
    setOrganizationID(props.organizationID);
  }, [props.organizationID]);

  useEffect(() => {
    props.organizationName &&
    props.organizationName !== organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);

  useEffect(() => {
    props.languageCode &&
    props.languageCode !== languageCode &&
    setLanguageCode(props.languageCode);
  }, [props.languageCode]);

  useEffect(() => {
    languageCode && !curLanguage &&
    setCurLanguage(languageCode);
  }, [languageCode]);
  
  useEffect(() => {
    if(props.coreOrg && props.coreOrg !== coreOrg) {
      setCoreOrg(props.coreOrg);
    }else if(!props.coreOrg && coreOrg) {
      setCoreOrg(null);
    }
  }, [props.coreOrg]);
  
  useEffect(() => {
    if(props.userAccess && 
        props.userAccess !== userAccess) {
      setUserAccess(props.userAccess);
    }
  }, [props.userAccess]);
  
  useEffect(() => {
    if(props.products && 
        props.products !== products) {
      setProducts(props.products);
    }
  }, [props.products]);
  
  useEffect(() => {
    if(props.searchParams && 
      !searchParams) {
      setSearchParams(searchParams);
    }
  }, [props.searchParams]);
  
  useEffect(() => {
    if(searchParams && props.actionUpdateSearch) {
      props.actionUpdateSearch(searchParams);
    }
  }, [searchParams]);
  
  // BUILD FILTER OPTIONS FOR ORGANIZATION, CATEGORY & TYPE
  useEffect(() => {
    let types = [];
    let cats = [];
    let langs = [];

    if(Array.isArray(products) && languageCode) {
        products.map((product, index) => {
          if(!types.find(type => type.option_name === product[languageCode].product_type)) {
            types.push({option_name: product[languageCode].product_type, option_value: product[languageCode].product_type});
          }
          if(Array.isArray(product.categories)) {
            product.categories.map(cat => {
              if(!cats.find(acat => acat.option_name === cat.category_name)) {
                cats.push({option_name: cat.category_name, option_value: cat.category_name});
              }
            })
          }
          if(!langs.find(lang => lang.option_name === product.language_code)) {
            langs.push({option_name: product.language_code, option_value: product.language_code})
          }
        });
    }
    setCategories(cats);
    setTypes(types);
    setLanguages(langs);
  }, [products, coreOrg]);

  // FILTER PRODUCTS - START AT CURRENT LANGUAGE
  useEffect(() => {
    //console.log("Filtering here:", coreOrg, products);
    if(products && curLanguage) {
      //console.log("CORE ORG CHANGES", coreOrg);
      let filterResults = coreOrg > 1 ?
                          products.filter(prod => prod.organization_id === coreOrg) :
                          products;
      if(activeFilters &&
        Object.entries(activeFilters).length > 0) {
        Object.entries(activeFilters).map(filter => {
          if(filter[0].indexOf('[]') >= 0) {
            const filterNodes = filter[0].split('[].');
            filterResults = 
              filterResults.filter(prod => {
                return(
                  Array.isArray(props.objGetValue(prod, filterNodes[0])) &&
                  props.objGetValue(prod, filterNodes[0]).filter(fValue => {
                    return(
                      props.objExists(fValue, filterNodes[1]) &&
                      Array.isArray(filter[1]) &&
                      filter[1].includes(props.objGetValue(fValue, filterNodes[1]))
                    )
                  }).length > 0 ? true : false
                )
              });
          }else{
            filterResults = filterResults.filter(prod => filter[1].includes(props.objGetValue(prod, filter[0])));
          }
        })
      }
      
      let searchResults = filterResults;
      if(searchOption && searchValue) {
        switch(searchOption) {
          case 'product_name':
            searchResults = searchResults.filter(product => product[languageCode].product_name.toLowerCase().match(searchValue.toLowerCase()));
            break;
          case 'product_description':
            const nameNodes = searchValue.toLowerCase().split(' ');
            nameNodes.map(node => {
              searchResults = searchResults.filter(product => product[languageCode].product_description && product[languageCode].product_description.toLowerCase().includes(node.toLowerCase()));
            });
            break;
          case 'product_sku':
            searchResults = searchResults.filter(product => product[languageCode].product_sku !== null);
            searchResults = searchResults.filter(product => product[languageCode].product_sku.toLowerCase().match(searchValue.toLowerCase()));
            break;
          default:
            break;
        }
      }

      setProductsFiltered(searchResults);
    }
  }, [coreOrg, activeFilters, filter, clearFilter, products, curLanguage, searchValue, searchOption]);
  
  useEffect(() => {
    //console.log("ACTIVE FILTERS IS UPDATING", activeFilters);
    if(!filter && activeFilters && Object.entries(activeFilters).length > 0) {
      let sParams = searchParams ? searchParams : {};
      Object.keys(activeFilters).map( key => {
        let pName;
        switch(key) {
          case 'categories[].category_name':
            pName = 'cat';
            break;
          case `${languageCode}.product_type`:
            pName = 'type';
            break;
          default:
            break;
        }
        if(activeFilters[key] && pName) {
          sParams[pName] = Array.isArray(activeFilters[key]) ?
          activeFilters[key].toString() : activeFilters[key];
        }
      })
      // console.log("WHADDWE HAVE?", 
      //               sParams && Object.entries(sParams).length > 0 ? true : false,
      //               sParams,
      //               searchParams,
      //               sParams === searchParams ? true : false);
      if(sParams && Object.entries(sParams).length > 0) {
        //console.log("SETTING SEARCH PARAMS", sParams);
        setSearchParams(sParams);
      }
    }
  }, [activeFilters, filter]);

  // AS FILTERS GET SET OR UNSET UPDATE ACTIVE FILTERS
  useEffect(() => {
    if(filter) {
      //console.log("FILTER HAS CHANGED", filter);
      let newFilters = activeFilters ? activeFilters : {};
      if(newFilters[filter.filterGroup] && Array.isArray(newFilters[filter.filterGroup])) {
        if(filter.filterValue === false && newFilters[filter.filterGroup].includes(filter.filterName)) {
          newFilters[filter.filterGroup].splice(newFilters[filter.filterGroup].indexOf(filter.filterName), 1);
        }else if(filter.filterValue === true) {
          if(!newFilters[filter.filterGroup]) {
            newFilters[filter.filterGroup] = [filter.filterName];
          }else if(newFilters[filter.filterGroup] && !newFilters[filter.filterGroup].includes(filter.filterName)) {
            newFilters[filter.filterGroup].push(filter.filterName);
          }
        }
      }else if (filter.filterValue === true){
        newFilters[filter.filterGroup] = [filter.filterName];
      }
      //console.log("HERE's THE NEW FILTERS", newFilters);
      setActiveFilters(newFilters);
      setFilter(null);
    }

  }, [filter, clearFilter]);

  // ON SHOW ALL: CLEAR FILTER FOR SPECIFIED FILTER SET
  useEffect(() => {
    if(clearFilter) {
      let newFilters = activeFilters;
      if(newFilters[clearFilter]) {
        delete newFilters[clearFilter];
        setActiveFilters(newFilters);
      }
      setClearFilter(null);
    }
  }, [clearFilter]);

  // FUNCTIONS
  function searchProducts(value) {
    setSearchValue(value);
  }

  function showAll(name, value, filterGroup) {
    setClearFilter(filterGroup);
  }

  function selectFilter(name, value, filterGroup) {
    setFilter({filterName: name, filterValue: value, filterGroup: filterGroup});
  }

  return (
    <div className="info-list">
      <ProductListCtl listName="Products"
                      actionOptionSelect={setSearchOption}
                      actionSearch={searchProducts}
                      searchOptions={searchOptions}
                      filterSets={[
                        {filterLabel: 'Type(s)', filterName: `${languageCode}.product_type`, filterOptions: types},
                        {filterLabel: 'Categories(s)', filterName: 'categories[].category_name', filterOptions: categories}]}
                      activeFilters={activeFilters}
                      actionShowAll={showAll}
                      actionSelectFilter={selectFilter}
                      liveSearch={true} />
      {productsFiltered && Array.isArray(productsFiltered) &&
        productsFiltered.map((product, index) => {
          return <ProductListItem {...props}
                                  reload={props.admin.reload}
                                  location={props.location}
                                  curLanguage={curLanguage}
                                  userID={userID}
                                  userAccess={userAccess}
                                  product={product}
                                  key={`prdct${index}`}
                                  isAdmin={props.isAdmin} />
        })
      }
    </div>
  )
}
