import React, {useEffect, useState} from 'react';

import IconSVG from 'components/icons/IconSVG';

export default (props) => {
  props = {...props.children, ...props, children: null};

  return (
    <div className="profile">
      <h2><IconSVG icon="gear" className="h2" />User Settings</h2>
      <p><IconSVG icon="warning" className="p" />User Settings is under development</p>

    </div>
  )
}
