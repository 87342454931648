import React, {useEffect, useState} from 'react';

import AdminUsersMain from 'components/admin/users/AdminUsersMain';
import AdminUsersList from 'components/admin/users/AdminUsersList';
import AdminStaffList from 'components/admin/users/AdminStaffList';
import AdminUsersAdd from 'components/admin/users/AdminUsersAdd';
import AdminUser from 'components/admin/users/AdminUser';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [coreOrg, setCoreOrg] = useState(null);
  const [component, setComponent] = useState(null);
  const [showAll, setShowAll] = useState(false);
  
  // HOOKS
  
  useEffect(() => {
    if(props.userID && props.userID !== userID) {
      setUserID(props.userID)
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token && props.token !== token) {
      setToken(props.token)
    }
  }, [props.token]);

  useEffect(() => {
    if(props.organizationID && organizationID !== props.organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    props.organizationName &&
    props.organizationName !== organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);
  
  useEffect(() => {
    if(props.languageCode && languageCode !== props.languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    if(props.userAccess && props.userAccess !== userAccess) {
      setUserAccess(props.userAccess);
    } 
  }, [props.userAccess]);
  
  useEffect(() => {
    if(props.coreOrg && props.coreOrg !== coreOrg) {
      setCoreOrg(props.coreOrg);
    } 
  }, [props.coreOrg]);

  useEffect(() => {
    if(userID && organizationID && token) {
     // LOAD USER ROLES
     let roleParams = [
        {name: 'process', value: 'get_user_roles'}
      ];
      if(!props.objExists(props.admin, `user_roles`) || 
        props.objGetValue(props.admin, `reload.user_roles`) === true) {
        props.adminLoadState(roleParams, 'users', 'user_roles');
        if(props.objGetValue(props.admin, `reload.user_roles`) === true) {
          props.adminRemoveVal(`reload.user_roles`);
        }
      }
      
      
      // LOAD USER GROUPS
      let groupParams = [
        {name: 'process', value: 'get_org_groups_list'},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
        {name: 'group_type', value: 'user'},
        {name: 'group_published', value: true},
      ];
      
      if(organizationID > 1) {
        groupParams.push({name: 'organization_id', value: organizationID});
      }
      
      if((!props.objExists(props.admin, `groups.user`) && 
          props.objGetValue(props.admin, `groups.user`) !== null) || 
        props.objGetValue(props.admin, `reload.groups.user`) === true) {
        props.adminLoadState(groupParams, 'groups', 'groups.user');
        if(props.objGetValue(props.admin, `reload.groups.user`) === true) {
          props.adminRemoveVal(`reload.groups.user`);
        }
      }
    }
  }, [userID, token, organizationID, coreOrg, props.admin.reload, props.admin.user_roles, props.admin.groups]);

  useEffect(() => {
    if(props.pgParams.activity && userID) {
      const activity = props.pgParams.activity;
      switch(activity) {
        case 'user-add':
          setComponent(<AdminUsersAdd {...props}
                                              userID={userID}
                                              token={token}
                                              organizationID={organizationID}
                                              organizationName={organizationName}
                                              languageCode={languageCode} />);
          return;
        case 'user':
          setComponent(<AdminUser {...props}
                                              userID={userID}
                                              token={token}
                                              organizationID={organizationID}
                                              organizationName={organizationName}
                                              languageCode={languageCode} />);
          return;
        case 'staff-list':
          setComponent(<AdminStaffList {...props}
                                              userID={userID}
                                              token={token}
                                              organizationID={organizationID}
                                              organizationName={organizationName}
                                              languageCode={languageCode}
                                              showAll={showAll} />);
          return;
        case 'users-list':
        default:
          setComponent(<AdminUsersList {...props}
                                              userID={userID}
                                              token={token}
                                              organizationID={organizationID}
                                              organizationName={organizationName}
                                              languageCode={languageCode}
                                              showAll={showAll}
                                              coreOrg={coreOrg} />);
          return;
      }
    }else{
      setComponent(<AdminUsersMain {...props}
                                          userID={userID}
                                          token={token}
                                          organizationID={organizationID}
                                          organizationName={organizationName}
                                          languageCode={languageCode} />);
    }
  }, [props.pgParams, userID, token, organizationID, organizationName, userAccess, showAll, props.admin]);


  console.log("ADMIN", props.admin);
  return (
    <div>
      {component}
    </div>
  )
}
