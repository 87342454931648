import React, {useEffect, useState} from 'react';

import Switch from 'components/forms/elements/Switch';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [task, setTask] = useState(null);
	const [published, setPublished] = useState(null);
	const [ignored, setIgnored] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.languageCode && props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.organizationID && props.organizationID !== organizationID) {
			setOrganizationID(props.organizationID);
		}
	}, [props.organizationID]);
	
	useEffect(() => {
		if(props.task && props.task !== task) {
			setTask(props.task);
		}
	}, [props.task]);
	
	useEffect(() => {
		if(task && languageCode) {
			if(task.task_virtual) {
				if(props.objGetValue(task, `task_properties.${task.link_type}ignored.value`) === 1) {
					setIgnored(true);
				}else if(props.objGetValue(task, `task_properties.${task.link_type}ignored.value`) === 0) {
					setIgnored(false);
				}else if(task.task_ignored === 1) {
					setIgnored(true);
				}else{
					setIgnored(false);
				}
				
				if(props.objGetValue(task, `task_properties.${task.link_type}published.value`) === 1) {
					setPublished(true);
				}else if(props.objGetValue(task, `task_properties.${task.link_type}published.value`) === 0) {
					setPublished(false);
				}else if(task.task_published === 1) {
					setPublished(true);
				}else{
					setPublished(false);
				}
			}else{
				if(props.objGetValue(task, `task_properties.task_ignored.value`) === 1) {
					setIgnored(true);
				}else if(props.objGetValue(task, `task_properties.task_ignored.value`) === 0) {
					setIgnored(false);
				}else if(task.task_ignored === 1) {
					setIgnored(true);
				}else{
					setIgnored(false);
				}
				
				if(props.objGetValue(task, `task_properties.task_published.value`) === 1) {
					setPublished(true);
				}else if(props.objGetValue(task, `task_properties.task_published.value`) === 0) {
					setPublished(false);
				}else if(task.task_published === 1) {
					setPublished(true);
				}else{
					setPublished(false);
				}
			}
		}
	}, [task]);
	
	// FUNCTIONS
	function updateTaskProperty(value, params) {
		const propertyParams = [
			...params,
			{name: 'process', value: 'add_task_property'},
			{name: 'user_id', value: userID},
			{name: 'token', value: token},
			{name: 'property_value', value: value},
			{name: 'organization_id', value: organizationID},
			{name: 'return_bool', value: true},
			{name: 'task_id', value: task.task_id}
		];
		
		props.adminLoadState(propertyParams, 'properties', 'reload.current.default_tasks');
	}
	
	function updateTaskSetting(value, params) {
		console.log("LOOKY LOOKY", value, params, task);
		let settingUpdate = false;
		if(Array.isArray(params)) {
			const propID = params.find(param => param.name === 'property_id');
			if(propID && parseInt(propID.value) > 0) {
				settingUpdate = true;
			}
			
			// updatePropertyPair($user_id = 0, $property_id = 0, $property_name = null, $property_value = null, $language_id = 1)
			
			const settingParams = 
			settingUpdate ?
				[
					{name: 'process', value: 'update_property_pair'},
					{name: 'user_id', value: userID},
					{name: 'token', value: token},
					{name: 'property_id', value: propID.value},
					{name: 'property_value', value: value}
				]
			:
				[
					...params,
					{name: 'process', value: 'create_property'},
					{name: 'user_id', value: userID},
					{name: 'token', value: token},
					{name: 'language_code', value: languageCode},
					{name: 'organization_id', value: organizationID},
					{name: 'property_value', value: value},
					{name: 'property_published', value: true},
					{name: 'property_type', value: 'organization'},
					{name: 'link_type', value: 'taskSettingToOrg'},
					{name: 'property_link', value: organizationID},
					{name: 'return_boolean', value: true}
				];
			
			console.log("ADDING A SETTING", settingParams);
			props.adminLoadState(settingParams, 'properties', 'reload.current.task_settings');
		}
	}
	
	return (
		task &&
		<div
			style={{
				opacity:
					ignored ?
						'.3' :
							!published ?
								'.6' :
									'1'
			}}>
			<h4 style={
					{textDecoration: 
						ignored ?
							'line-through' : 'none'
					}
				}>
				{`${task.task_parent_name ? `${task.task_parent_name} :: ` : ''}${task.type_name} -  ${task[languageCode].task_name}`}
			</h4>
			<div className="form-row">
				<div
					style={{
						flex: '0 0 auto', 
						marginRight: '2em',
						alignItems: 'center'
					}} 
					className="flex">
					<Switch {...props}
						className="auto-width"
						label="Ignore"
						value={ignored}
						params={[
							{
								name: 'property_id', 
								value: task.task_virtual ?
											props.objGetValue(task, `task_properties.${task.link_type}ignored.property_id`)
										:
											props.objGetValue(task, `task_properties.task_ignored.property_id`)
							},
							{
								name: 'property_name', 
								value: task.task_virtual ? 
											`${task.link_type}ignored` 
										: 
											'task_ignored'
							},
							{
								name: 'task',
								value: task
							}
						]}
						action={task.task_virtual ? updateTaskSetting : updateTaskProperty} />
					<div>
						{(ignored ? 1 : 0) !== task.task_ignored ?
							<b>(Custom)</b> : '(Default)'
						}
					</div>
				</div>
				<div
					style={{
						flex: '0 0 auto', 
						marginRight: '2em',
						alignItems: 'center'
					}} 
					className="flex">
					<Switch {...props}
						className="auto-width"
						label={`Publish`}
						value={published}
						params={[
							{
								name: 'property_id', 
								value: task.task_virtual ?
											props.objGetValue(task, `task_properties.${task.link_type}published.property_id`)
										:
											props.objGetValue(task, `task_properties.task_published.property_id`)
							},
							{
								name: 'property_name', 
								value: task.task_virtual ? 
											`${task.link_type}published` 
										: 
											'task_published'
							}
						]}
						action={task.task_virtual ? updateTaskSetting : updateTaskProperty} />
					<div>
						{(published ? 1 : 0) !== task.task_published ?
							<b>(Custom)</b> : '(Default)'
						}
					</div>
				</div>
			</div>
		</div>
	)
}