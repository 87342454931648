import React, {useEffect, useState} from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import Loader from 'components/atoms/Loader';
import FormDynamic from 'components/forms/FormDynamic';
import FileUpload from 'components/forms/elements/FileUpload';

export default (props) => {
	const [form, setForm] = useState(null);
	const [fieldsets, setFieldsets] = useState(null);
	const [aspectRatio, setAspectRatio] = useState(null);
	const [slideHTML, setSlideHTML] = useState(null);
	const [posX, setPosX] = useState(null);
	const [posY, setPosY] = useState(null);
	const [style, setStyle] = useState(null);
	
	useEffect(() => {
		setFieldsets([{fieldset_name: `${props.objExists(props, 'slide.slide_id') ? 'Edit' : 'Add'} Slide`,
			fields: [
				!props.objExists(props, 'slideshow.slideshow_id') &&
				{field_type: 'text',
				field_name: 'slideshow_name',
				field_label: 'Slideshow Name',
				field_placeholder: 'Name this new slideshow',
				field_value: null,
				field_required: false,
				field_validate: false,
				field_order: 1
				},
				!props.objExists(props, 'slideshow.slideshow_id') &&
				{field_type: 'text',
				field_name: 'slideshow_aspect_ratio',
				field_label: 'Aspect Ratio',
				field_placeholder: 'Aspect Ratio (#:#)',
				field_value: aspectRatio,
				field_required: false,
				field_validate: false,
				fieldOnChange: updateValue,
				field_order: 2
				},
				{field_type: 'switch',
				field_name: 'slide_published',
				field_label: 'Publish',
				field_value: props.objGetValue(props, 'slide.slide_published') === 1 ? true : 0,
				field_required: false,
				field_validate: false,
				field_order: 3,
				},
				{field_type: 'text',
				field_name: 'slide_link',
				field_label: 'Link',
				field_placeholder: 'Link URL',
				field_value: props.objExists(props, 'slide.slide_link') ? props.slide.slide_link : null,
				field_required: false,
				field_validate: false,
				fieldOnChange: updateValue,
				field_order: 5
				},
				{field_type: 'textarea',
				field_name: 'slide_html',
				field_label: 'Content (HTML)',
				field_placeholder: 'Slide Text or Raw HTML',
				field_value: props.objExists(props, 'slide.slide_html') ? props.slide.slide_html : null,
				field_required: false,
				field_validate: false,
				fieldOnChange: updateValue,
				field_order: 4
				},
				{field_type: 'radio',
				field_name: 'slide_style',
				field_label: 'Style',
				field_value: props.objExists(props, 'slide.slide_style') ? props.slide.slide_style : 'dark',
				option_groups: [
					{options: [
						{option_name: 'Dark', option_value: 'dark'},
						{option_name: 'Light', option_value: 'light'},
					]}
				],
				fieldOnChange: updateValue,
				field_order: 6
				},
				{field_type: 'radio',
				field_name: 'slide_positionx',
				field_label: 'Horizontal Position',
				field_value: props.objExists(props, 'slide.slide_positionx') ? props.slide.slide_positionx : 'left',
				option_groups: [
					{options: [
						{option_name: 'Left', option_value: 'left'},
						{option_name: 'Right', option_value: 'right'},
					]}
				],
				fieldOnChange: updateValue,
				field_order: 7	
				},
				{field_type: 'radio',
				field_name: 'slide_positiony',
				field_label: 'Vertical Position',
				field_value: props.objExists(props, 'slide.slide_positiony') ? props.slide.slide_positiony : 'top',
				option_groups: [
					{options: [
						{option_name: 'Top', option_value: 'top'},
						{option_name: 'Bottom', option_value: 'bottom'},
					]}
				],
				fieldOnChange: updateValue,
				field_order: 8
				},
				{field_type: 'hidden',
				field_name: 'return_bool',
				field_value: true,
				},
				{field_type: 'hidden',
				field_name: 'user_id',
				field_value: props.userID
				},
				props.objExists(props, 'organizationID') &&
				{field_type: 'hidden',
				field_name: 'organization_id',
				field_value: !props.objExists(props, 'organizationID') ?
								props.organizationID : null
				},
				{field_type: 'hidden',
				field_name: 'slide_id',
				field_value: props.objExists(props, 'slide.slide_id') ? props.slide.slide_id : null
				},
				{field_type: 'hidden',
				field_name: 'process',
				field_value: props.objExists(props, 'slide.slide_id') ?
								'update_site_slideshow_slide' :
								'add_site_slideshow_slide'
				},
				{field_type: 'hidden',
				field_name: 'token',
				field_value: props.token
				},
				{field_type: 'hidden',
				field_name: 'language_code',
				field_value: props.language_code ? props.language_code : 'en'},
				props.objExists(props, 'slideshow.slideshow_id') && 
				{field_type: 'hidden',
				field_name: 'slideshow_id',
				field_value: props.slideshow.slideshow_id
			},
			]
		}])
		props.objExists(props, 'slide.slide_html') &&
		setSlideHTML(props.slide.slide_html);
	}, [props.slide, props.slideshow, aspectRatio, props.files]);
	
	useEffect(() => {
		if(!aspectRatio && props.objExists(props, 'slideshow.slideshow_aspect_ratio')) {
			setAspectRatio(props.slideshow.slideshow_aspect_ratio);
		}else if(!aspectRatio){
			setAspectRatio('16:9');
		}
	}, [props.slideshow, aspectRatio]);
	
	useEffect(() => {
		if(slideHTML) {
			props.adminSetVal('test.html', slideHTML);
		}
	}, [slideHTML]);
	
	useEffect(() => {
		if(aspectRatio) {
			const ratios = adjustAspect(aspectRatio);
			document.getElementById('aspect-ratio').style.height = `${document.getElementById('aspect-ratio').offsetWidth*ratios.height}px`;
		}
	}, [aspectRatio]);
	
	useEffect(() => {
		console.log(" FORM ISSUES: ", fieldsets);
		if(fieldsets) {
			setForm(
				<FormDynamic
				  content={fieldsets}
				  name="add-edit-slide"
				  returnObject={true}
				  fieldOnChange={handleChange}
				  fieldOnLoad={null}
				  formActions={{formCancel: handleCancel, formSubmit: handleAddEdit}} />
			)
		}
	}, [fieldsets]);
	
	// FUNCTIONS
	function handleChange(e) {
		console.log("Slide Form is Changing");
	}
	
	function handleCancel(e) {
		console.log("Cancel Form");
		props.objExists(props, 'actionCancel') &&
		props.actionCancel();
	}
	
	function handleAddEdit(params) {
		props.objExists(props, 'actionAddEdit') &&
		props.actionAddEdit(params);
	}
	
	function adjustAspect(ratio) {
		const ratioValues = ratio.includes(':') ?
		  ratio.split(':') :
		  ratio.split('x');
		const ratioFactors = {width: parseFloat(ratioValues[0])/parseFloat(ratioValues[1]),
				  height: parseFloat(ratioValues[1])/parseFloat(ratioValues[0])};
		return ratioFactors;
	}
	
	function updateValue(param) {
		switch(param.name) {
			case 'slide_html':
				setSlideHTML(param.value);
				break;
			case 'slide_positionx':
				setPosX(param.value);
				break;
			case 'slide_positiony':
				setPosY(param.value);
				break;
			case 'slide_style':
				setStyle(param.value);
				break;
			case 'slideshow_aspect_ratio':
				if(param.value && 
					param.value !== "" && 
					param.value !== aspectRatio) {
					setAspectRatio(param.value);		
				}else if((!param.value || param.value === "") &&
							aspectRatio) {
					setAspectRatio('16:9');	
				}
				break;
				// setAspectRatio(param.value);
			default:
				break;
		}
	}
	
	// console.log("FILES", props.files, props.media);
	// console.log("ADMIN", props.admin);
	// console.log("ASPECT Ratio", aspectRatio);
	return (
		<div className="form-matrix">
			{form ? form : <Loader />}
			<div className="form-matrix-display">
				<div id="aspect-ratio"
						style={{
							backgroundImage: props.objExists(props, 'slide.media.media_id') ?
												`url(${props.localizeURL(props.slide.media.media_file_path)}${props.slide.media.media_file_name})` :
													'none'
						}}>
					{aspectRatio}
					{slideHTML &&
						<div id="slide-content"
								style={{left: posX === 'right' ? '70%' : '5%'}}
								className={style === 'light' ? 'light' : null} >
							<div id="slide-content-text" 
									style={{top: posY === 'bottom' ? '70%' : '10%',}}>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div>	
					}
				</div>
				{slideHTML && 
					<div id="html-preview">
						<h4>Text Content</h4>
						{ReactHtmlParser(slideHTML)}
					</div>
				}
				<FileUpload {...props}
							retainModal={true}
							stateLocation={'slides'}
							returnInfo={true}
							media_type='media'
							media_owner_id={props.objExists(props, 'slide.slide_id') ? 
											props.slide.slide_id : null}
							media_owner_type ='Slide'
							media_published ={true}
							user_id={props.userID}
							token={props.token}
							organization_id={props.organizationID}
							language_code={props.languageCode ? props.languageCode : 'en'} />
			</div>
		</div>
	)
}