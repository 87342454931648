import React, {useEffect, useState} from 'react';

import Card from 'components/atoms/Card';
import CardContent from 'components/molecules/CardContent';
import CardContentContact from 'components/molecules/CardContentContact';
import RadioBtn from 'components/forms/elements/RadioBtn';
import Button from 'components/forms/elements/Button';
import CoreRule from 'components/atoms/CoreRule';

import CheckoutBillingPaymentsAccount from 'components/cart/CheckoutBillingPaymentsAccount';
import CheckoutBillingPaymentsInvoice from 'components/cart/CheckoutBillingPaymentsInvoice';

import * as formData from 'methods/forms';

export default (props) => {
  const [paymentOption, setPaymentOption] = useState(null);
  const [paymentModule, setPaymentModule] = useState(null);
  
  // useEffect(() => {
  //   // if(props.objExists(props.cart, `${props.location}.billing.order_payment_method`) && 
  //   //     props.objGetValue(props.cart, `${props.location}.billing.order_payment_method`) !== paymentOption) {
  //   //   setPaymentOption(props.objGetValue(props.cart, `${props.location}.billing.order_payment_method`));
  //   // }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.cart.project]);
  
  useEffect(()=> {
    if(paymentOption && props.paymentOptions) {
      switch(paymentOption) {
        case 'invoice_account':
          setPaymentModule(<CheckoutBillingPaymentsAccount 
                                {...props}
                                location={props.location} />);
          break;
      case 'invoice_direct':
        setPaymentModule(<CheckoutBillingPaymentsInvoice 
                                {...props}
                                location={props.location} />);
        break;
      default:
          setPaymentModule(<CardContent editable={false} >
                              Please select a payment option
                            </CardContent>)
          break;
      }  
    }
    
    if(paymentOption && props.objGetValue(props.cart, `${props.location}.billing.order_payment_method`) !== paymentOption) {
      props.cartSetValue(`${props.location}.billing.order_payment_method`, paymentOption);
    }
    
  }, [paymentOption, props.paymentOptions]);
  

  return (
    <Card title="Payment Method"
          classRule="nopad"
          editable={false}>
        <div className="flex-row">
          <CardContent editable={false}>
          <div>
            {props.paymentOptions &&
            Array.isArray(props.paymentOptions) &&
            props.paymentOptions.map((payOption, index, array) => {
              if(array.length === 1) {
                paymentOption !== payOption.option_component &&
                setPaymentOption(payOption.option_component);
              }
              return <RadioBtn label={payOption.option_name}
                                labelDescription={payOption.option_description ? payOption.option_description : null}
                                value={payOption.option_component}
                                selected={array.length === 1 ? true :
                                            props.objGetValue(props.cart, `${props.location}.billing.order_payment_method`) === payOption.option_component ? 
                                              true : false}
                                disabled={array.length === 1}
                                group={`${props.location}.billing.order_payment_method`}
                                selectAction={()=>{
                                  props.cartSetInfo(`${props.location}.billing.order_payment_method`, payOption.option_component);
                                  setPaymentOption(payOption.option_component);
                                }} />
            })
          }
          </div>
          </CardContent>
          <CoreRule vertical={true} />
          {paymentModule}
          
          {props.objExists(props.cart, `${props.location}.billing.order_payment_method`) &&
            props.objGetValue(props.cart, `${props.location}.billing.order_payment_method`) === 'contact_payment' &&
            <CardContent editable={false} >
              <div className="card-content-heading">How would you prefer to be contacted?</div>
              <RadioBtn label="By Phone"
                        labelDescription={props.objExists(props.cart, 'project.billing.contact.contact_phone') &&
                                          props.cart.project.billing.contact.contact_phone ? props.phoneDisplay(props.cart.project.billing.contact.contact_phone) :
                                          null}
                        value="phone"
                        selected={props.objExists(props.cart, 'project.billing.order_contact_preference') &&
                                props.cart.project.billing.order_contact_preference === 'phone' ? true : false}
                        group="project.billing.order_contact_preference"
                        selectAction={props.cartSetInfo} />
              <RadioBtn label="By Email"
                        labelDescription={props.objExists(props.cart, 'project.billing.contact.contact_email') &&
                                          props.cart.project.billing.contact.contact_email ?
                                            props.cart.project.billing.contact.contact_email :
                                            null}
                        value="email"
                        selected={props.objExists(props.cart, 'project.billing.order_contact_preference') &&
                                props.cart.project.billing.order_contact_preference === 'email' ? true : false}
                        group="project.billing.order_contact_preference"
                        selectAction={props.cartSetInfo} />
            </CardContent>
          }

          {(props.objExists(props.cart, 'project.billing.order_payment_method') &&
          props.cart.project.billing.order_payment_method === 'invoice') &&
          <CardContent editable={true}
                        actionEdit={()=>{props.infoEdit('project.billing.contact',
                                          props.cart.project.billing.contact,
                                          [{fieldset_name: 'Edit Billing Contact', fields: formData.fieldsContactEml}],
                                          [{field_type: 'switch',
                                            field_name: 'save_default',
                                            field_label: 'Save as default?',
                                            field_value: props.cart.project.billing.contact.save_default ? true : false,
                                            field_required: false,
                                            field_validate: false},]
                                         )}} >
          <RadioBtn label="Invoice"
                      value="invoice"
                      selectAction={props.cartSetInfo}
                      selected={props.objExists(props.cart, 'project.billing') && props.cart.project.billing.order_payment_method === 'invoice' ? true : false}
                      group="project.billing.order_payment_method"
                      labelDescription={props.objExists(props.cart, 'project.billing.contact') ?
                                        [`${props.cart.project.billing.contact.contact_name_first} ${props.cart.project.billing.contact.contact_name_last}`, props.cart.project.billing.contact.contact_email] : null} />
          </CardContent>
        }

        {(props.objExists(props.cart, 'project.billing.order_payment_method') &&
          props.cart.project.billing.order_payment_method === 'credit_card') &&
          <CardContent editable={true}
                        actionEdit={()=>{props.infoEdit('project.billing.payment',
                                          props.cart.project.billing.payment,
                                          [{fieldset_name: 'Edit Payment Card', fields: formData.fieldsPaymentCC}],
                                          [{field_type: 'switch',
                                            field_name: 'save_default',
                                            field_label: 'Save as default?',
                                            field_value: props.objExists(props.cart, 'project.billing.payment.save_default') ? true : false,
                                            field_required: false,
                                            field_validate: false},]
                                         )}} >
            <h3>{props.objExists(props.cart, 'project.billing.payment') && props.cart.project.billing.payment.payment_cc_type ?
            props.capitalizeString(props.cart.project.billing.payment.payment_cc_type) : 'Credit Card'}</h3>
            {props.objExists(props.cart, 'project.billing.payment') &&
              props.cart.project.billing.payment.payment_cc_type &&
              props.cart.project.billing.payment.payment_cc_number &&
              props.cart.project.billing.payment.payment_cc_expiry &&
              props.cart.project.billing.payment.payment_cc_cvv &&
              <div>
                <label>Card Number</label>
                <p>{props.creditCardDisplay(props.cart.project.billing.payment.payment_cc_number)}</p>
                <label>Card Expiry</label>
                <p>{props.cart.project.billing.payment.payment_cc_expiry}</p>
              </div>
          }
          {!props.objExists(props.cart, 'project.billing.payment') ||
            (!props.cart.project.billing.payment.payment_cc_name ||
            !props.cart.project.billing.payment.payment_cc_type &&
            !props.cart.project.billing.payment.payment_cc_number &&
            !props.cart.project.billing.payment.payment_cc_expiry &&
            !props.cart.project.billing.payment.payment_cc_cvv) &&
            <p><span className="action-text" onClick={()=>{props.infoEdit('project.billing.payment',
                              props.cart.project.billing.payment,
                              [{fieldset_name: 'Edit Payment Card', fields: formData.fieldsPaymentCC}],
                              [{field_type: 'switch',
                                field_name: 'save_default',
                                field_label: 'Save as default?',
                                field_value: props.objExists(props.cart, 'project.billing.payment.save_default') ? true : false,
                                field_required: false,
                                field_validate: false},]
                             )}}>Enter</span> your Credit Card information</p>
          }
          {(!props.objExists(props.cart, 'project.billing.payment') ||
            !props.cart.project.billing.payment.payment_cc_type ||
            !props.cart.project.billing.payment.payment_cc_name ||
            !props.cart.project.billing.payment.payment_cc_number ||
            !props.cart.project.billing.payment.payment_cc_expiry ||
            !props.cart.project.billing.payment.payment_cc_cvv) &&
            <p><span className="action-text" onClick={()=>{props.infoEdit('project.billing.payment',
                              props.cart.project.billing.payment,
                              [{fieldset_name: 'Edit Payment Card', fields: formData.fieldsPaymentCC}],
                              [{field_type: 'switch',
                                field_name: 'save_default',
                                field_label: 'Save as default?',
                                field_value: props.objExists(props.cart, 'project.billing.payment.save_default') ? true : false,
                                field_required: false,
                                field_validate: false},]
                             )}}>Complete</span> your Credit Card information</p>
          }
          </CardContent>
        }
        </div>
    </Card>
  )
}
