import React, {useEffect, useState, Fragment} from 'react';
import {Redirect} from 'react-router';

import MenuSetAction from 'components/navigation/MenuSetAction';

export default (props) => {
  const [user, setUser] = useState(null);
  const [redirect, setRedirect] = useState(null);
  
  useEffect(() => {
    if(props.objExists(props.user, 'user_id') &&
        user !== props.user) {
      setUser(props.user);
    }
  }, [props.user]);

  // FUNCTIONS
  function userLogout() {
    props.siteSetValue('navigation.referrer', window.location.pathname);
    props.siteRemoveValue('modules');
    props.logout();
    props.siteSetValue('reload.modules', true);
    setRedirect('/login');
  }
  
  return(
    user &&
      <Fragment>
        {redirect && <Redirect to={redirect} />}
        <MenuSetAction {...props} 
                        name="Sign Out" 
                        actions={{action: userLogout}}
                         />
      </Fragment>
  )
}
