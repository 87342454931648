import React, {useEffect, useState, Fragment} from 'react';
import {useParams, useLocation} from 'react-router';

import TaskInterface from 'components/profiles/tasks/TaskInterface';
import Loader from 'components/atoms/Loader';
import Link from 'components/navigation/Link';
import IconSVG from 'components/icons/IconSVG';

import * as utils from 'methods/site';

export default (props) => {
	props = {...props.children, ...utils};
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [task, setTask] = useState(null);
	const [taskLoading, setTaskLoading] = useState(false);
	const [item, setItem] = useState(null);
	const [itemLoading, setItemLoading] = useState(false);
	const [project, setProject] = useState(null);
	const [projectLoading, setProjectLoading] = useState(null);
	const [shippingCarriers, setShippingCarriers] = useState(null);
	
	
	const pgParams = useParams();
	const location = useLocation();
	
	let ivlTask;
	
	// HOOKS 
	useEffect(() => {
		props.filesDeleteVal('uploads');
		return () => {
			props.dashboardDeleteVal('task');
			props.dashboardDeleteVal('project');
			props.dashboardDeleteVal('item');
			setTask(null);
			setItem(null);
			setProject(null);
			props.filesDeleteVal('uploadComplete');
			props.filesDeleteVal('uploads');
			if(ivlTask){
				clearInterval(ivlTask);
			}
			
		}
	}, [])
	
	useEffect(() => {
		if(props.objExists(props.user, 'user_id') && 
			userID !== props.user.user_id) {
		  setUserID(props.user.user_id);
		}
		
		if(props.objExists(props.user, 'user_id')) {
		  if(localStorage.getItem('token')) {
			setToken(localStorage.getItem('token'));
		  }else if(sessionStorage.getItem('token')) {
			setToken(sessionStorage.getItem('token'));
		  }
		}
	}, [props.user]);
	  
	useEffect(() => {
		if(props.objExists(props.site, 'site_language') && 
			props.site.site_language !== languageCode) {
		  setLanguageCode(props.site.site_language);
		}else if(!props.objExists(props.site, 'site_language')){
		  props.siteSetValue('site_language', 'en');
		  setLanguageCode('en');
		}
	}, [props.site]);
	
	useEffect(() => {
		if(pgParams.task_id && 
			userID &&
			token) {
			let timeoutTask;
			const paramsTask = [
				{name: 'process', value: 'get_tasks'},
				{name: 'user_id', value: userID},
				{name: 'token', value: token},
				{name: 'task_id', value: pgParams.task_id},
				{name: 'task_published', value: false},
				{name: 'task_removed', value: false}
			];
			
			if(!props.objExists(props.dashboard, 'task.task_id') || 
				props.objGetValue(props.dashboard, 'reload.task') === true) {
				props.dashboardLoadState(paramsTask, 'tasks', 'task');
				if(props.objExists(props.dashboard, 'reload.task')) {
					props.dashboardDeleteVal('reload.task');	
				}else{
					setTaskLoading(true);
				}
				
				if(ivlTask) {
					clearInterval(ivlTask);
				}
				
				ivlTask = setInterval(()=>{
						props.dashboardLoadState(paramsTask, 'tasks', 'task');
				}, props.minToMS(5));
			}
		}
	
	}, [pgParams, userID, token, props.dashboard.reload]);
	
	useEffect(() => {
		if(userID && token) {
			const paramsCarriers = [
				{name: 'process', value: 'get_shipping_carriers'},
				{name: 'user_id', value: userID},
				{name: 'token', value: token}
			]
			
			if(!props.objExists(props.dashboard, 'shipping_carriers') ||
				props.objGetValue(props.dashboard, 'reload.shipping_carriers') === true) {
				if(props.objGetValue(props.dashboard, 'reload.shipping_carriers') === true) {
					props.dashboardDeleteVal('reload.shipping_carriers');
				}
				props.dashboardLoadState(paramsCarriers, 'order', 'shipping_carriers');
			}
		}
	}, [userID, token, props.dashboard.reload]);
	
	useEffect(() => {
		if(Array.isArray(props.objGetValue(props.dashboard, 'shipping_carriers')) &&
			props.dashboard.shipping_carriers.length > 0 &&
			props.dashboard.shipping_carriers !== shippingCarriers) {
				setShippingCarriers(props.dashboard.shipping_carriers);
		}
	}, [props.dashboard.shipping_carriers]);
	
	
	
	useEffect(() => {
		if(props.objExists(props.dashboard, 'task.task_id') &&
			props.dashboard.task !== task) {
			setTask(props.dashboard.task);
			taskLoading && setTaskLoading(false);
		}
	}, [props.dashboard.task]);
	
	useEffect(() => {
		if(task && userID && token) {
			if(props.objExists(task, 'item_id') && 
				(!props.dashboard.item ||
					props.objGetValue(props.dashboard, 'reload.item') === true)) {
				const paramsItem = [
					{name: 'process', value: 'get_order_item'},
					{name: 'user_id', value: userID},
					{name: 'token', value: token},
					{name: 'item_id', value: task.item_id},
					{name: 'task_id', value: task.task_id}
				];
				props.dashboardLoadState(paramsItem, 'orders', 'item');
				if(props.objExists(props.dashboard, 'reload.item')) {
					props.dashboardDeleteVal('reload.item');
				}else{
					setItemLoading(true);
				}
			}
			
			if(props.objExists(task, 'project_id') && 
				props.objExists(task, 'organization_id') && 
				(!props.dashboard.project ||
					props.objGetValue(props.dashboard, 'reload.project') === true)) {
				const paramsItem = [
					{name: 'process', value: 'get_projects'},
					{name: 'user_id', value: userID},
					{name: 'token', value: token},
					{name: 'project_id', value: task.project_id},
					{name: 'organization_id', value: task.organization_id}
				];
				props.dashboardLoadState(paramsItem, 'orders', 'project');
				if(props.objExists(props.dashboard, 'reload.project')) {
					props.dashboardDeleteVal('reload.project');
				}else{
					setProjectLoading(true);
				}
			}	
		} 
	}, [task, userID, token, props.dashboard]);
	
	useEffect(() => {
		if(props.objExists(props.dashboard, 'item.item_id')) {
			setItem(props.dashboard.item);
			itemLoading && setItemLoading(false);
		}
	}, [props.dashboard.item]);
	
	useEffect(() => {
		if(props.objExists(props.dashboard, 'project.project_id') &&
			props.dashboard.project !== project) {
			setProject(props.dashboard.project);
			projectLoading && setProjectLoading(false);
		}
	}, [props.dashboard.project]);
	
	return (
		<article>
			<p>
			<Link to="/dashboard">
				<IconSVG icon="arrow" className="p rotate270" />
				Return to dashboard
			</Link>
			</p>
			{task && languageCode ?
				<Fragment>
					<h2>{task[languageCode].task_name ? props.capitalizeString(task[languageCode].task_name, true) : `Task ${task.task_id}`}</h2> 
					<TaskInterface {...props}
						userID={userID}
						token={token}
						languageCode={languageCode}
						task={task}
						item={item}
						project={project}
						taskLoading={taskLoading}
						itemLoading={itemLoading}
						projectLoading={projectLoading}
						stateLocation='dashboard'
						shippingCarriers={shippingCarriers}
						/>
				</Fragment>	
			:
				<Loader label="Loading task..."  />
			}
		</article>
	)
	
}