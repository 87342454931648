import React from 'react';
import PagePublic from 'components/templates/PagePublic';

import Product from 'components/products/Product';

export default () => {
  return (
    <PagePublic >
      <Product  />
    </PagePublic>
  )
}
