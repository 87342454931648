import React, {useEffect, useState} from 'react';

import IconSVG from 'components/icons/IconSVG';

const CartErrorConsole = (props) => {
	const [project, setProject] = useState(null);
	const [orderCount, setOrderCount] = useState(null);
	const [locationCount, setLocationCount] = useState(null);
	const [missingValues, setMissingValues] = useState(null);
	const [projectErrors, setProjectErrors] = useState(null);
	const [logMsg, setLogMsg] = useState(null);
	
	useEffect(() => {
		if(props.project && props.project !== project) {
			setProject(props.project);
		}
	}, [props.project]);
	
	useEffect(() => {
		if(Array.isArray(props.objGetValue(project, 'orders')) &&
			project.orders.length > 0) {
			setOrderCount(project.orders.length);
			let locCount = 0;
			project.orders.map(order => {
				// console.log("COUNTING LOCATIONS", order);
				if(Array.isArray(props.objGetValue(order, `shipping.locations`)) &&
					order.shipping.locations.length > 0) {
					locCount += order.shipping.locations.length;
				}
			})
			if(locCount > 0 && locCount !== locationCount) {
				setLocationCount(locCount);
			}
		}
	}, [project]);
	
	useEffect(() => {
		if(Array.isArray(props.missingValues) && 
	   		JSON.stringify(props.missingValues) !== JSON.stringify(missingValues)) {
			setMissingValues(props.missingValues);
	   }
	}, [props.missingValues]);
	
	useEffect(() => {
		if(Array.isArray(missingValues)) {
			let errorMatrix = {};
			missingValues.map(mVal => {
				props.objSetValue(errorMatrix, mVal, true);
			})
			if(Object.keys(errorMatrix).length > 0 &&
				errorMatrix !== projectErrors) {
				setProjectErrors(errorMatrix);
			}
		}
	}, missingValues);
	
	useEffect(() => {
		if(projectErrors) {
			if(props.actionErrorFlags) {
				props.actionErrorFlags(projectErrors);
			}
			let errLog = [];
			if(Array.isArray(projectErrors.orders) 
				&& projectErrors.orders.length > 0) {
				projectErrors.orders.map((order, oIndex) => {
					if(orderCount > 1) {
						errLog.pus(<p>{`Order ${oIndex+1}`}</p>);
					}
					
					// console.log("TESTING LOCATIONS",
					// 			Array.isArray(props.objGetValue(order, `shipping.locations`)),
					// 			order.shipping.locations.length > 0);
					if(Array.isArray(props.objGetValue(order, `shipping.locations`)) &&
						order.shipping.locations.length > 0) {
						order.shipping.locations.map((loc, lIndex) => {
							if(loc && Object.keys(loc).length > 0) {
								let locContactFields = '';
								let locLocationFields = '';
								Object.keys(loc).map((fKey, fIndex) => {
									// console.log("IS CONTACT FIELD?", fKey, fKey.indexOf('contact'));
									if(loc[fKey] && fKey.indexOf('contact') >= 0) {
										if(locContactFields != '') { locContactFields += ', '; }
										locContactFields += props.capitalizeString(fKey.replaceAll('_', ' ').replace('contact', ''), true);	
									}
									
									if(loc[fKey] && fKey.indexOf('location') >= 0) {
										if(locLocationFields != '') { locLocationFields += ', '; }
										locLocationFields += props.capitalizeString(fKey.replaceAll('_', ' ').replace('location', ''), true);	
									} 
									
								})
								if(locContactFields !== '') {
									errLog.push(
										<p>
											{`Location ${lIndex + 1} is missing the following shipping contact info:`}<br />
											<b>{locContactFields}</b><br />
											<span className="error-console-action">
												<IconSVG icon='edit' style={{marginRight: '.5em'}} />
												{`Update location ${lIndex + 1} contact info?`}
											</span>
										</p>);
								}
								
								if(locLocationFields !== '') {
									errLog.push(
										<p>
											{`Location ${lIndex + 1} is missing the following shipping info:`}<br />
											<b>{locLocationFields}</b><br />
											<span className="error-console-action">
												<IconSVG icon='edit' style={{marginRight: '.5em'}} />
												{`Update location ${lIndex + 1} contact info?`}
											</span>
										</p>);
								}
							}
						})	
					}
				});
			}
			// console.log("ERR LOG", errLog);
			
			if(errLog && errLog !== logMsg) {
				setLogMsg(errLog);
			}
			
		}
	}, [projectErrors]);
	
	// console.log("PROJECT ERRORS", missingValues, projectErrors);
	return (
		(1 === 2) && logMsg &&
		<div className="error-console">
			<IconSVG icon='caution' className="error-console-icon" />
			<div className="error-console-content">
				<h3>Some additional information is required before you can submit your order</h3>
				<div className="error-console-msg">
					{logMsg}
				</div>
			</div>
		</div>
	)
}

export default CartErrorConsole;