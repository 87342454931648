import React, {useEffect, useState, Fragment} from 'react';
import ReactDOM from 'react-dom';

import Card from 'components/atoms/Card';
import ProjectOrder from 'components/profiles/projects/ProjectOrder';
import ProjectFinancials from 'components/profiles/projects/ProjectFinancials';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [task, setTask] = useState(null);
	const [project, setProject] = useState(null);
	const [hidePricing, setHidePricing] = useState(null);
	
	useEffect(() => {
		if(props.userID &&
			props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token &&
			props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.languageCode &&
			props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.task &&
			props.task !== task) {
			setTask(props.task);
		}
	}, [props.task]);
	
	useEffect(() => {
		if(props.project && 
			props.project !== project) {
			setProject(props.project);
		}
	}, [props.project]);
	
	useEffect(() => {
		if(props.objExists(props.site, 'persona.prefs') && 
		  Array.isArray(props.site.persona.prefs) && props.site.persona.prefs.length > 0) {
			const oProp = props.site.persona.prefs.filter(property => property.property_name === 'hide_pricing');
			if (oProp.length > 0 && oProp[0].property_value == 'true') {
			  setHidePricing(true);
			} else {
			  setHidePricing(false);
			}
		}
	  }, [props]);
	
	return (
		project &&
		<div className="card">
			{project.orders &&
			  Array.isArray(project.orders) &&
			  project.orders.length > 0 &&
			  project.orders.map((order, index) => {
				return <ProjectOrder  key={`prjto${index}`}
									  {...props}
									  userID={userID}
									  token={token}
									  organizationID={project.organization_id}
									  languageCode={languageCode}
									  index={index}
									  projectID={project.project_id}
									  coreOrgCode={project.organization_code}
									  coreOrg={project.organization_id}
									  coreUser={project.user_id}
									  order={order}
									  multiple={project.orders.length > 1 ? true : false}
									  hidePricing={hidePricing} />
			  })
			}
			{hidePricing !== true && parseFloat(props.objGetValue(project, 'project_total')) > 0 && 
			  <Card title="Financials">
				<div className="card-block">
				  <ProjectFinancials {...props} 
					project={project} 
					userID={userID}
					token={token}
					financialType="project" />
				</div>
			  </Card>
			}
		</div>
	)
}