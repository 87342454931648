import React, { useState, useEffect, useRef } from 'react';

import IconSVG from 'components/icons/IconSVG';
import CoreRule from 'components/atoms/CoreRule';


export default (props) => {
  const [ctlAction, setAction] = useState(props.labelOpen ? props.labelOpen : 'Read more...');
  const textArea = useRef();


  function toggleText(e) {
    let thisTarget = e.target;
    while(!thisTarget.classList.contains('text-truncate') && thisTarget.tagName !== 'BODY') {
      thisTarget = thisTarget.parentNode;
    }

    if(thisTarget.classList.contains('active')) {
      thisTarget.classList.add('inactive');
      thisTarget.classList.remove('active');
      setAction(props.labelOpen ? props.labelOpen : 'Read more...');
    }else{
      thisTarget.classList.add('active');
      thisTarget.classList.contains('inactive') && thisTarget.classList.remove('inactive');
      setAction(props.labelClose ? props.labelClose : 'Show less...');
    }
  }

  useEffect(()=> {
    if(textArea ) {

      if(textArea.current.firstChild.offsetHeight <= 112) {
        textArea.current.nextSibling.classList.add('hidden');
      }

    }

  }, [textArea])



  return (
    <div className={`text-truncate${props.altClass? ` ${props.altClass}` : ''}`} >
      <div  ref={textArea} className="text-truncate-content">
        <div className="text-truncate-full">
          {props.children}
        </div>
      </div>
      <div className="text-truncate-ctl">
        <CoreRule />
        <div className="text-truncate-ctl-btn" onClick={toggleText}>
          {ctlAction}
          <IconSVG icon="arrow" />
        </div>
      </div>
    </div>
  )
}
