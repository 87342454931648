import React, {useEffect, useState, Fragment} from 'react';

import UsersListItem from 'components/profiles/users/UsersListItem';
import Loader from 'components/atoms/Loader';
import Control from 'components/atoms/Control';
import UserListCtl from 'components/profiles/users/UserListCtl';
import Select from 'components/forms/elements/Select';

export default (props) => {
  const [users, setUsers] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);

  useEffect(() => {
    if(props.languageCode &&
      props.languageCode !== languageCode) {
      setLanguageCode(props.languageCode);
    }else if(!languageCode) {
      setLanguageCode('en');	
    }
  }, [props.languageCode]);

  useEffect(() => {
    if(props.users && props.users !== users) {
      setUsers(props.users);
    }
  }, [props.users]);
  
  console.log("LIST USERS", props.users, users);
  return (
    <div className="info-list users">
      {props.showFiler &&
        <div className="info-list-header users" >
          
      </div>  
      }
      
      {users && Array.isArray(users) &&
        users.length > 0  ?
        users.map((user, index) => {
          return <UsersListItem {...props}
                    key={`usrsLstItm${index}`}
                    user={user}
                    languageCode={languageCode}
                    location={'admin'}
                    showOrg={props.showOrg}
                    showAll={props.showAll} />
        })
      :
        <Loader />
      }
    </div>
  )
}
