import React, {useEffect, useState} from 'react';

import IconToken from 'components/icons/IconToken';

export default (props) => {
	const [user, setUser] = useState(null);
	
	useEffect(() => {
		if(props.user && props.user !== user) {
			setUser(props.user);
		}
	}, [props.user]);
	
	function handleRemove() {
		if(props.actionRemove) {
			props.actionRemove(props.user.user_id);
		}
	}
	
	function handleAssign() {
		if(props.actionAssign) {
			props.actionAssign(props.user.user_id);
		}
	}
	
	return (
		user &&
		<div className="task-user">
			<label>{`${user.contact_name_first} ${user.contact_name_last}`}</label>
			<div className="ctl">
				<IconToken icon={props.actionRemove ? 'close' : 'add'} 
							className="btn"
							tooltip={props.actionRemove ? 'Remove user' : 'Assign User'} 
							action={props.actionRemove ? handleRemove : handleAssign} />
			</div>
		</div>
	)
}