import React, {useEffect, useState, useRef, Fragment} from 'react';
import ReactDOM from 'react-dom';

import IconToken from 'components/icons/IconToken';
import IconSVG from 'components/icons/IconSVG';

export default (props) => {
  const [active, setActive] = useState(false);
  const [optionsActive, setOptionsActive] = useState(false);
  const [optionsMounted, setOptionsMounted] = useState(false);
  const [value, setValue] = useState(null);
  const [optionValue, setOptionValue] = useState(null);
  const [optionList, setOptionList] = useState(null);

  const wrapper = useRef();
  const searchOptions = useRef();
  const searchOptionList = useRef();

  useEffect(() => {
    // if(props.searchOptions) {}
    if(props.searchOptions) {
      let optSet = [];

      Array.isArray(props.searchOptions) &&
      props.searchOptions.length > 0 &&
        props.searchOptions.map((option, index) => {
          optSet.push(<div key={`sopt${index}`}
                          className="search-option-list-option"
                          rel={option.value}
                          onClick={selectOption}
                          >
                          {option.name}
                      </div>);
      });
      setOptionList(<div className="search-option-list">{optSet}</div>);

      if(!optionValue && props.searchOptions.find(opt => opt.default === true)) {
        setOptionValue(props.searchOptions.find(opt => opt.default === true).name);
        if(props.actionOptionSelect) {
          props.actionOptionSelect(props.searchOptions.find(opt => opt.default === true).value)
        }
      }
  }
  }, [props.searchOptions]);

  useEffect(() => {
    if(optionList && optionsActive) {
      const labelSpecs = searchOptions.current.getBoundingClientRect();
      const optionSelect = document.createElement('div');
      optionSelect.setAttribute('id', 'search-option-list-wrapper');
      optionSelect.setAttribute('ref', searchOptionList);
      optionSelect.style.top = `${labelSpecs.top + document.documentElement.scrollTop}px`;
      optionSelect.style.left = `${labelSpecs.left}px`;
      ReactDOM.render(optionList, optionSelect);
      document.body.append(optionSelect);
      setOptionsMounted(true);
    }
  }, [optionsActive, optionList]);

  useEffect(() => {
    if(optionsMounted) {
      const listEl = document.getElementById('search-option-list-wrapper');
      const labelSpecs = searchOptions.current.getBoundingClientRect();
      const listSpecs = listEl.getBoundingClientRect();
      listEl.style.minWidth = `${labelSpecs.width}px`;
      searchOptions.current.style.minWidth = `${listSpecs.width}px`;
      !listEl.classList.contains('active') &&
      listEl.classList.add('active');
    }
  }, [optionsMounted]);

  function handleChange(e) {
    setValue(e.target.value);
    if(props.onChange) {
      props.onChange(e.target.value);
    }

    if(props.liveSearch && props.actionSearch) {
      props.actionSearch(e.target.value);
    }
  }

  function search() {
    if(props.action) {
      props.action(value);
    }
  }

  function activateOptions() {
    searchOptionList.current.classList.add('active');
    console.log("clicked", searchOptionList.current);
  }

  function deactivateOptions() {
    const listEl = document.getElementById('search-option-list-wrapper');
    listEl.remove();
    setOptionsActive(false);
    setOptionsMounted(false);
  }

  function selectOption(e) {
    setOptionValue(e.target.innerHTML);
    deactivateOptions();
    if(props.actionOptionSelect) {
      props.actionOptionSelect(e.target.getAttribute('rel'));
    }
  }

  return(
    <div className="search-field-wrapper" ref={wrapper}>
      <div className={`search-field${active ? ` active` : ''}`}>
        <IconToken icon="search"
                    className="search-icon"
                    action={active ? search : () => {setActive(true)}} />
        <div className="search-field-placeholder">
          Search for...
        </div>
        <div className="search-field-input">
          <div className="search-field-options" ref={searchOptions}>
            <div className="search-field-options-label"
                  onClick={()=>{setOptionsActive(true)}}>
              {optionValue ? optionValue : 'Search By...'}
              <IconSVG icon="arrow" className="rotate180" />
            </div>
          </div>
          <input type="text" name="search-value" value={value ? value : ''} onChange={handleChange} />
          <IconToken icon="add"
                      className="btn rotate45 search-close"
                      action={() => {setValue(''); handleChange({target: {value: ''}})}} />
        </div>
      </div>
    </div>
  )
}
