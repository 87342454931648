import React, {useEffect, useState, useRef, Fragment} from 'react';

import Button from 'components/forms/elements/Button';
import MessageContactTag from 'components/profiles/messages/MessageContactTag';
import PredictiveTextMenu from 'components/forms/elements/PredictiveTextMenu';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [messageTo, setMessageTo] = useState('');
	const [messageSubject, setMessageSubject] = useState('');
	const [messageBody, setMessageBody] = useState('');
	const [messagePrivate, setMessagePrivate] = useState(true);
	const [messageInternal, setMessageInternal] = useState(true);
	const [users, setUsers] = useState(null);
	const [messageUsers, setMessageUsers] = useState(null);
	const [messageGroups, setGroups] = useState(null);
	const [suggestedUsers, setSuggestedUsers] = useState(null);
	const [suggestedOptions, setSuggestedOptions] = useState(null);
	const [predictive, setPredictive] = useState(null);
	
	const refInputTo = useRef()
	
	// HOOKS
	useEffect(() => {
		setUsers([
			{user_id: 1, contact_name_first: 'Greg', contact_name_last: 'Teeter', contact_email: 'greg.teeter@accellgraphics.ca', organization_name: 'AE Core'},
			{user_id: 2, contact_name_first: 'Jamie', contact_name_last: 'Herron', contact_email: 'mamie.herron@accellgraphics.ca', organization_name: 'AE Core'},
			{user_id: 999, contact_name_first: 'John', contact_name_last: 'Smith', contact_email: 'john.smith@acme.com', organization_name: 'Acme Widgets'},
			{user_id: 73, contact_name_first: 'Tess', contact_name_last: 'Ting', contact_email: 'greg@teeterville.ca', organizationg_name: 'AE Core'}
		]);
	}, [props.users, props.staff]);
	
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(messageTo && messageTo.length > 0) {
			let sUsers = users.filter(function (usr) {
				if(messageTo.includes(' ')) {
					const searchNodes = messageTo.split(' ');
					console.log("USR LOWER CASE", usr.contact_name_first.toLowerCase(), searchNodes[0].toLowerCase());
					let firstMatch = usr.contact_name_first.toLowerCase() === searchNodes[0].toLowerCase();
					let secondMatch = searchNodes[1]  && searchNodes.length > 0 ? 
										usr.contact_name_last.toLowerCase().includes(searchNodes[1].toLowerCase()) :
										true;
					let infoExceeded = searchNodes[2] && searchNodes[2].length > 0 ? true : false;
					
					if(Array.isArray(messageUsers) && messageUsers.find(musr => musr.user_id === usr.user_id)) {
						return false;
					}
					
					if(firstMatch && secondMatch && !infoExceeded) {
						return true;
					}else{
						return false;
					}
				}else{
					return usr.contact_name_first.toLowerCase().includes(messageTo.toLowerCase()) || 
							usr.contact_name_last.toLowerCase().includes(messageTo.toLowerCase());
				}
			});
			
			if(Array.isArray(messageUsers)) {
				sUsers = sUsers.filter(sUsr => {return !messageUsers.find(mUser => mUser.user_id === sUsr.user_id)});
			}
			
			if(Array.isArray(sUsers) && sUsers.length > 0) {
				setSuggestedUsers(sUsers);
			}else{
				setSuggestedUsers(null);
			}	
		}else if(suggestedUsers && (!messageTo || messageTo.length <= 0)) {
			setSuggestedUsers(null);
		}
		
	}, [messageTo, users, messageUsers]);
	
	useEffect(() => {
		if(Array.isArray(messageUsers) && 
			Array.isArray(suggestedUsers)) {
			let sUsers = [];
			suggestedUsers.map(sUsr => {
				if(!messageUsers.find(mUsr => mUsr.user_id === sUsr.user_id)) {
					sUsers.push(sUsr);
				}
			});
			
			if(sUsers.length > 0) {
				setSuggestedUsers(sUsers);
			}else{
				setSuggestedUsers(null);
			}
		}
	}, [messageUsers]);
	
	// FUNCTIONS
	function handleChange(e) {
		switch(e.target.name) {
			case 'message_to':
				setMessageTo(e.target.value);
				break;
			case 'message_subject':
				setMessageSubject(e.target.value);
				break;
			case 'message_body':
				setMessageBody(e.target.value);
				console.log("TEXT AREA HEIGHT", e.target.height, e.target.scrollHeight);
		}
		
	}
	
	function selectUser(option) {
		if(props.objExists(option, `option_value`) && Array.isArray(users)) {
			
			const user = users.find(usr => usr.user_id === option.option_value);
			setSuggestedUsers(suggestedUsers.filter(usr => usr.user_id !== option.option_value));
			
			if(user) {
				setMessageTo("");
				refInputTo.current.value = '';
				if(Array.isArray(messageUsers) &&
					!messageUsers.find(usr => usr.user_id === user.user_id)) {
					setMessageUsers([...messageUsers, user]);
					
					
				}else if(!Array.isArray(messageUsers) || messageUsers.length <= 0) {
					setMessageUsers([user]);
				}
			}
		}
	}
	
	function removeUser(user) {
		if(user && Array.isArray(messageUsers)) {
			setMessageUsers(messageUsers.filter(mUsr => mUsr.user_id !== user.user_id));
		}
	}
	
	function loadSendMessage() {
		let errMsg = null;
		let errValues = [];
		if(!messageBody || messageBody === '') {
			errMsg = 'You have not provided a message';
			errValues.push('messageBody');
		}
		
		if(!errMsg) {
			sendMessage();	
		}
	}
	
	function sendMessage() {
		props.setModal(null);
		
		let mUsers;
		if(Array.isArray(messageUsers) && 
			messageUsers.length > 0) {
			mUsers = JSON.stringify(
				messageUsers.map(usr => {
					return usr.user_id
				}));
		};
		
		const msgParams = [
			{name: 'process', value: 'send_message'},
			{name: 'user_id', value: userID},
			{name: 'token', value: token},
			{name: 'message_users', value: mUsers},
			{name: 'message_groups', value: messageGroups},
			{name: 'message_subject', value: messageSubject === '' ? null : messageSubject},
			{name: 'message_text', value: messageBody},
			{name: 'message_private', value: messagePrivate},
			{name: 'message_internal', value: messageInternal}
		];
		
		console.log("SEND MESSAGE", msgParams);
		props.userLoadState(msgParams, 'messages', 'reload.messages');
	}
	
	return (
		<Fragment>
		{Array.isArray(suggestedUsers) &&
			<PredictiveTextMenu {...props}
				target={props.objGetValue(predictive, `current`)}
				options={
					suggestedUsers.map(user => {
						return {
							option_name: `${user.contact_name_first} ${user.contact_name_last}`,
							option_value: user.user_id
						}
				})}
				actionSelect={selectUser} /> 
		}
		<div className="message-console">
			<div className="form-row">
				<div>To:</div>
				<div className="message-input-multi">
					{Array.isArray(messageUsers) &&
						messageUsers.map((contact, index) => {
							return (
								<MessageContactTag {...props}
									key={`msgCntctTg${index}`}
									contact={contact}
									actionRemove={removeUser} />
							)
						})
						
					}
					<input type="text" 
							name="message_to" 
							value={messageTo}
							onChange={handleChange}
							ref={refInputTo}
							onFocus={()=>{setPredictive(refInputTo)}}
							onBlur={()=>{setPredictive(null)}} />
				</div>
			</div>
			<div className="form-row">
				<div>Subject:</div>
				<input type="text" 
						name="message_subject" 
						value={messageSubject}
						onChange={handleChange} />
			</div>
			<div className="form-row">
				<div>Message:</div>
				<textarea name="message_body" onChange={handleChange} value={messageBody} />
			</div>
			<div className="message-ctl">
				<Button icon="close" label="Cancel" btnAction={()=>{props.setModal(null)}} />
				<Button icon="email" label="Send" btnClass="success-bg" btnAction={sendMessage} />
			</div>
		</div>
		</Fragment>
	)
}