import * as methods from 'methods/reducers';

export default function store(state = {}, action) {
  switch (action.type) {
    case 'GET_CATEGORIES':
      return {...state, categories: action.payload, };
      case 'GET_CATEGORY':
        return {...state, category: action.payload, };
      case 'GET_PRODUCTS' :
      return {...state, products: action.payload,};
    case 'GET_PRODUCT' :
      return {...state, product: action.payload,}
    case 'GET_MAIN_FEATURES' :
      return {...state, featuresMain: action.payload, };
    case 'GET_SUB_FEATURES' :
      return {...state, featuresSub: action.payload, };
    case 'CLEAR_STORE' :
      return {...state, [action.payload]: null};
    case 'STORE_SET_VALUE':
      const setValState = methods.setStateValue(state, action.name, action.value);
      if(setValState) {
        const stateSetLoc = action.name.includes('.') ? action.name.split('.')[0] : action.name;
        let storageName = '';
        switch(stateSetLoc) {
          case null:
            break;
          case 'product':
            storageName = 'strPrd';
            break;
          case 'category':
            storageName = 'strCat';
            break;
          default:
            storageName = `str${stateSetLoc}`;
            break;
        }
        action.sStore &&
        sessionStorage.setItem(storageName, JSON.stringify(state[stateSetLoc]));
      }
      return {...state};
    case 'STORE_DELETE_VAL':
      let removeValState;
      if(Array.isArray(action.payload)) {
        action.payload.map(location => {
          removeValState = methods.removeStateValue(state, location);
          return true;
        })
      }else{
        removeValState = methods.removeStateValue(state, action.payload);
      }
      if(removeValState) {
        return {...removeValState};
      }else{
        return {...state};
      }
    case 'STORE_LOAD_STATE':
      let payload = action.payload;
      if(action.location.indexOf('.')) {
        const locationNodes = action.location.split('.');
        let valVar = null;
        for(var i = locationNodes.length - 1; i >= 0; i--) {
          let stateVal = state;
          for(var j = 0; j <= i; j++) {
            if(stateVal && stateVal[locationNodes[j]]) {
              stateVal = stateVal[locationNodes[j]];
            }else{
              stateVal = null;
            }
          }
          if(i === locationNodes.length-1) {
            valVar = {[locationNodes[i]]: action.payload};
          }else{
            if(stateVal) {
              valVar = {[locationNodes[i]]: {...stateVal, ...valVar}}
            }else{
              valVar = {[locationNodes[i]]: {...valVar}};
            }
          }
        }
        payload = valVar;
      }
      return {...state, ...payload};
    default:
      return state;
  }
}
