import React, {useEffect, useState} from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import Loader from 'components/atoms/Loader';
import FormDynamic from 'components/forms/FormDynamic';
import ModalConfirmation from 'components/molecules/ModalConfirmation';

import * as formData from 'methods/forms.js';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [userAccess, setUserAccess] = useState(null);
	const [shipping, setShipping] = useState(null);
	const [form, setForm] = useState(null);
	const [fieldsets, setFieldsets] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
		  setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token !== token) {
		  setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.organizationID && organizationID !== props.organizationID) {
		  setOrganizationID(props.organizationID);
		}
	}, [props.organizationID]);
	
	useEffect(() => {
		if(props.languageCode && languageCode !== props.languageCode) {
		  setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.userAccess && props.userAccess !== userAccess) {
		  setUserAccess(props.userAccess);
		} 
	}, [props.userAccess]);
	
	useEffect(() => {
		if(userID) {
			setFieldsets([
				{fieldset_name: props.objExists(props.shipping, 'option_id') ?
									'Update Shipping Option' : 'Add Shipping Option',
				fields: [
					{field_type: "hidden",
					field_name: 'process',
					field_value: props.objExists(props.shipping, 'option_id') ?
									'update_shipping_option' : 'add_shipping_option'
					},
					{field_type: 'hidden',
					field_name: 'user_id', 
					field_value: userID
					},
					{field_type: 'hidden',
					field_name: 'token', 
					field_value: token
					},
					{field_type: 'hidden',
					field_name: 'option_published', 
					field_value: true
					},
					{field_type: 'hidden',
					field_name: 'core_org', 
					field_value: props.coreOrg ? props.coreOrg : null
					},
					{field_type: 'hidden',
					field_name: 'option_id', 
					field_value: props.objExists(props.shipping, 'option_id') ? 
									props.shipping.option_id : null
					},
					{field_type: 'text',
					field_name: 'option_name',
					field_label: 'Name',
					field_placeholder: 'Shipping Option Name',
					field_required: true,
					field_validate: true,
					field_order: 1,
					field_value: props.objExists(props.shipping, 'option_name') ?
									props.shipping.option_name : null
					},
					{field_type: 'textarea',
					field_name: 'option_description',
					field_label: 'Description',
					field_placeholder: 'Shipping Option Desicription',
					field_required: true,
					field_validate: true,
					field_order: 2,
					field_value: props.objExists(props.shipping, 'option_description') ?
									props.shipping.option_description : null
					},
				]}
			])
		}
	}, [userID, props.shipping]);
	
	useEffect(() => {
		if(fieldsets) {
			setForm(
				<FormDynamic
				  content={fieldsets}
				  name={props.objExists(props.shipping, 'option_id') ? `updated-shipping-option` : `add-shipping-option`}
				  formCompleteLabel={props.objExists(props.shipping, 'option_id') ? `Update` : `Create`}
				  returnObject={true}
				  fieldOnChange={null}
				  fieldOnLoad={null}
				  formActions={{formCancel: handleCancel, formSubmit: handleAddEdit}} />
			)
		}
	}, [fieldsets, props.shipping]);
	
	
	
	// FUNCTIONS
	
	
	function handleCancel() {
		if(props.actionCancel) {
			props.actionCancel();
		}
	}
	
	function handleAddEdit(params) {
		if(props.actionAddEdit) {
			props.actionAddEdit(params, props.actionParams ? props.actionParams : null);	
		}
	}
	
	return (
		form ? form : <Loader />
	)
}