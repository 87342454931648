import * as methods from 'methods/reducers';

export default function dashboard(state = {}, action) {
  switch(action.type) {
    case 'DASHBOARD_DELETE_VAL':
      let removeValState;
      if(Array.isArray(action.payload)) {
        action.payload.map(location => {
          removeValState = methods.removeStateValue(state, location);
          return true;
        })
      }else{
        removeValState = methods.removeStateValue(state, action.payload);
      }

      if(removeValState) {
        // sessionStorage.setItem('admPrd', JSON.stringify(state.product));
        return {...removeValState};
      }else{
        return {...state};
      }
      case 'DASHBOARD_SET_VALUE':
        const setValState = methods.setStateValue(state, action.name, action.value);
        console.log("WHAT DID WE GET HERE?", setValState);
        if(setValState) {
          const stateSetLoc = action.name.includes('.') ? action.name.split('.')[0] : action.name;
          let storageName = '';
          switch(stateSetLoc) {
            case null:
              break;
            case 'organization':
              storageName = 'admOrg';
              break;
            case 'product':
              storageName = 'admPrd';
              break;
            case 'user':
              storageName = 'admUsr';
              break;
            default:
              storageName = `adm${stateSetLoc}`;
              break;
          }
          sessionStorage.setItem(storageName, JSON.stringify(state[stateSetLoc]));
        }
        return {...state};
      case 'DASHBOARD_LOAD_STATE':
        let payload = action.payload;
        if(action.location.indexOf('.')) {
          const locationNodes = action.location.split('.');
          let valVar = null;
          for(var i = locationNodes.length - 1; i >= 0; i--) {
            let stateVal = state;
            for(var j = 0; j <= i; j++) {
              if(stateVal && stateVal[locationNodes[j]]) {
                stateVal = stateVal[locationNodes[j]];
              }else{
                stateVal = null;
              }
            }
            if(i === locationNodes.length-1) {
              valVar = {[locationNodes[i]]: action.payload};
            }else{
              if(stateVal) {
                valVar = {[locationNodes[i]]: {...stateVal, ...valVar}}
              }else{
                valVar = {[locationNodes[i]]: {...valVar}};
              }
            }
          }
          payload = valVar;
        }
        return {...state, ...payload};
    default:
      return state;
  }

}
