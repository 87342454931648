import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactHtmlParser from 'react-html-parser';

import * as utils from 'methods/site';

import LiveFieldCtl from 'components/forms/elements/LiveFieldCtl';
import Input from 'components/forms/elements/Input';
import InputDate from 'components/forms/elements/InputDate';
import Select from 'components/forms/elements/Select';
import TextArea from 'components/forms/elements/TextArea';


export default (props) => {
  const [displayValue, setDisplayValue] = useState(null);
  const [editFields, setEditFields] =  useState(null);
  const [value, setValue] = useState(null);
  const [valueList, setValueList] = useState(null);
  const [media, setMedia] = useState(null);
  const [valueCtl, setValueCtl] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [processUpdate, setProcessUpdate] = useState(false);
  const [isListener, setListener] = useState(false);
  const fieldRef = useRef();
  const editRef = useRef();
  const inputRef = useRef();
  const textRef = useRef();

  useEffect(() => {
    return () => {
      setValue(null);
    }
  }, []);
  
  useEffect(() => {
    if(props.value === 0 || props.value) {
      setValue(props.value);
      setValueCtl(props.value);
    }
  }, [props.value]);
  
  useEffect(() => {
    if(props.type === 'multi' && props.value) {
      const multiValue = JSON.parse(props.value);
      if(Object.entries(multiValue).length > 0) {
        setValueList(multiValue);
      }
    }
  }, [props.value, props.type]);

  useEffect(() => {
    if(Array.isArray(value) && value.length > 0) {
      let displayVal = '';
      let eFields = [];
      value.map((val, index) => {
        displayVal += `${val.value}${index+1 < value.length ? ' ' : ''}`;
        eFields.push(setField(val));
      })
      setDisplayValue(displayVal);
      eFields.length > 0 &&
      setEditFields(eFields);
    }else{
      switch(props.type) {
        case 'select':
          setDisplayValue(utils.getOptionName(props.option_groups, value));
          break;
        case 'file':
          setDisplayValue(media && media.media_file_upload_name ? media.media_file_upload_name : value);
          break;
        case 'date':
          setDisplayValue(value ? utils.dateDisplay(value) : null);
          break;
        default:
          setDisplayValue(value);
          break;  
      }
      
      setEditFields([setField({name: props.id ? props.id : props.name,
                              id: props.id,
                              value: value,
                              type: props.type,
                              label: props.label,
                              option_groups: props.option_groups})]);
    }
  }, [value, props.option_groups, media]);
  
  useEffect(() => {
    if(props.type === 'file' && parseInt(props.value) > 0 &&
      Array.isArray(props.media)) {
        if(props.media.find(file => file.media_id === parseInt(props.value))) {
          setMedia(props.media.find(file => file.media_id === parseInt(props.value)));
        }
    }
  }, [props.type, props.media]);

  useEffect(() => {
    if(isActive) {
      var _keyNav = (e) => {
        handleKey(e);
      }
      window.addEventListener('keydown', _keyNav, true);
      return() => {
        window.removeEventListener('keydown', _keyNav, true);
      }
    }
  }, [isActive]);

  function handleKey(e) {
    switch(e.key) {
      case 'Enter':
      case 'Tab':
        if(props.type !== 'textarea') {
          e.preventDefault();
          setProcessUpdate(true);  
        }
        
        break;
      case 'Escape':
        e.preventDefault();
        updateCancel();
        break;
      default:
        break;
    }
  }

  function setField(field) {
    switch(field.type) {
        case 'select':
          return(<Select key={`lfs-${field.name}`}
                          name={field.id && parseInt(field.id) > 0 ? parseInt(field.id) : field.name}
                          defaultValue={field.value}
                          label={field.label}
                          option_groups={field.option_groups}
                          onChange={handleChange}
                          onLoad={false}
                          className="no-print" />);
        case 'textarea':
          return(<TextArea key={`lfta-${field.name}`}
                            name={field.id && parseInt(field.id) > 0 ? parseInt(field.id) : field.name}
                            defaultValue={field.value}
                            label={field.label}
                            onChange={handleChange}
                            className="no-print" />);
        case 'date':
          return(
            <InputDate {...utils}
              key={`lfid-${field.name}`}
              name={field.id ? parseInt(field.id) > 0 : field.name}
              defaultValue={field.value}
              onChange={handleChange}
              minDate={props.minDate}
              maxDate={props.maxDate}
              className="no-print" />
          )
        default:
          return(<Input key={`lfi-${field.name}`}
                        name={field.id && parseInt(field.id) > 0 ? parseInt(field.id) : field.name}
                        defaultValue={field.value}
                        placeholder={field.label}
                        type={field.type}
                        onChange={handleChange}
                        className="no-print" />);
    }
  }

  function activateField(e, field = null) {
    if(!field) {
      field = fieldRef.current;
    }
    let liveFields = document.querySelectorAll('.live-field.active');
    liveFields.forEach( lField => {
      deactivateField(null, lField);
    })
    setIsActive(true);
    !field.classList.contains('active') &&
    field.classList.add('active');
    const targetInput = field.querySelectorAll('input, textarea');
    (targetInput[0].type !== 'hidden') &&
    targetInput[0].focus();
  }

  function deactivateField(e, field = null) {
    if(!field) {
      field = fieldRef.current;
    }

    setIsActive(false);
    !field.classList.contains('inactive') &&
    field.classList.add('inactive');
    field.classList.contains('active') &&
    field.classList.remove('active');
    setTimeout(function(){
      field.classList.contains('inactive') &&
      field.classList.remove('inactive');
    }, 300);
  }

  function display(val) {
    // TODO
    if(val !== null) {
      switch (props.type) {
        case 'number':
          return utils.numberDisplay(val, props.isDecimal);
        case 'price':
          return utils.priceDisplay(val);
        default:
          return val;
      }
    }else{
      return null;
    }
  }

  function handleChange(e){
    
    let val = e.target ? e.target.value : e.value;
    let name = e.target ? e.target.name : e.name;
    if(val || val === '' || val === 0) {
      if(Array.isArray(value)) {
        let valIndex = null;
        let newValArray = [...value];
        value.map((v, index) => {
          if(String(v.name) === String(name)) {
            valIndex = index;
          }
        });
        if(valIndex >= 0) {
          newValArray[valIndex].value = val;
          setValue(newValArray);
        }
      }else{
        if((val || val === '' || val === 0) && value !== val ) {
          if(props.type === 'tel' || props.type === 'phone') {
            setValue(utils.phoneDisplay(val));
          }else if(props.type === 'number'){
            if(props.preventNull && (!val || val === '0')) {
                setValue(props.minValue ? utils.numberInput(props.minValue, props.isDecimal) : 0)
            } else {
                setValue(utils.numberInput(val, props.isDecimal));
            }
          }else if(props.type === 'float') {
            setValue(utils.numberInput(val, true));
          }else if(props.type === 'price') {
            if(val.toString().replace(/[^0-9]/g, '').length > 0) {
              setValue(utils.numberInput(val, true, 2));
            }else{
              setValue(null);
            }
          }else if(props.type === 'email') {
            setValue(val.toLowerCase())
          }else if(props.type === 'cc-number') {
            setValue(utils.creditCardNumberInput(val));
          }else if(props.type === 'cc-expiry') {
            setValue(utils.creditCardExpiryInput(val));
          }else if(props.type === 'cc-cvv') {
            setValue(utils.creditCardCVVInput(val));
          }else{
            setValue(val);
          }
        }
      }
    }
  }

  function updateAccept(val) {
    setProcessUpdate(true);
  }

  useEffect(() => {
    if(processUpdate) {
      let updateLocation = null;
      let updateValue = null;

      let optionalParams = null;
      if(props.optionalParams) {
        optionalParams = props.optionalParams;
      }
      if(Array.isArray(value)) {
        value.map((val, index) => {
          if(props.action) {
            props.action(val.name, val.value, optionalParams);
          }else if(props.objGetValue(props.cart, `${props.location ?  `${props.location}.` : ''}${val.name}`) !== val.value) {
            props.cartSetValue(`${props.location ?  `${props.location}.` : ''}${val.name}`, val.value);
          }
        })
      }else{
        if(props.action) {
          props.action(props.name, value, optionalParams);
        }else{
          props.objGetValue(props.cart, `${props.location ?  `${props.location}.` : ''}${props.name}`) !== value &&
          props.cartSetValue(`${props.location ?  `${props.location}.` : ''}${props.name}`, value);
        }

      }
      setValueCtl(value);
      deactivateField();
      setProcessUpdate(false);
    }
  }, [processUpdate, value]);

  function updateCancel(e) {
    if(value !== valueCtl) {
      setValue(valueCtl);
    }
    deactivateField();
  }
  
  function displayKey(value) {
    return props.capitalizeString(value.replaceAll(/[\_]/ig, ' '), true);
  }
  
  return (
    <div className={`live-field${props.className ? ` ${props.className}` : ''}${props.type ? ` ${props.type}` : ''}${props.disabled ? ' disabled' : ''}`} style={props.style ? props.style : {}} ref={fieldRef}>
      {props.label && <div className="live-field-col live-field-label">{props.label}</div>}
      <div className="live-field-col live-field-info">
        {props.type === 'multi' && valueList ? 
          <div className="live-field-text list">
            {valueList &&
              Object.entries(valueList).length > 0 &&
              Object.entries(valueList).map((val, index) => {
                return (<div className="list-row" key={`${props.name}lfls-${index}`}>
                          <span className="list-key">{displayKey(val[0])}</span>
                          <span className="list-value">{isNaN(val[1]) ? props.capitalizeString(val[1]) : val[1]}</span>
                        </div>);
              })
            }
          </div>
        : 
          <div className={`live-field-text ${props.className ? ` ${props.className}` : ''}`} onClick={props.disabled === true || isActive ? null : activateField} ref={textRef}>
            {ReactHtmlParser((displayValue  && displayValue !== '') || displayValue === 0 ? display(displayValue) :
                  props.placeholder ? props.capitalizeString(props.placeholder) :
                    props.disabled ? 'TBD' : 'Add Value?')}
          </div>
        }
        <div className="live-field-edit" ref={editRef}>
            {editFields}
        </div>
      </div>
      <div className="live-field-col live-field-col-ctl">
        {props.disabled !== true &&
          <LiveFieldCtl
            value={displayValue}
            active={isActive}
            actionEdit={activateField}
            actionSave={()=>{updateAccept(value)}}
            actionCancel={updateCancel}
            showTooltips={props.showTooltips === false ? false : true} />
        }
      </div>
    </div>
  )
}
