import React from 'react';

import IconToken from 'components/icons/IconToken';

export default (props) => {
  return (
    <IconToken icon="arrow" className={`icon-ctl-dir${props.direction ? ` ${props.direction}` : ''}${props.direction === 'prev' ? ` rotate270` : ' rotate90'}`}
      action={props.action}
      iconRef={props.ctlRef ? props.ctlRef : null}
    />
  )
}
