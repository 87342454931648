import React, {useEffect, useState} from 'react';

import IconToken from 'components/icons/IconToken';

export default (props) => {
	const [defaultValue, setDefaultValue] = useState(null);
	const [value, setValue] = useState(null);
	const [maxValue, setMaxValue] = useState(null);
	const [minValue, setMinValue] = useState(null);
	
	useEffect(() => {
		setValue(null);
		setDefaultValue(null);	
	}, [])
	
	useEffect(() => {
		let initVal = null;
		if(props.value !== null) {
			initVal = props.float ?
						!isNaN(parseInt(props.float)) ?
						parseFloat(props.value).toFixed(parseInt(props.float)) : 
						parseFloat(props.value) :
						parseInt(props.value);
			//console.log('number ctl setting intiVal', initVal);
		}else if(!props.value && props.defaultValue !== null) {
			initVal = props.float ? 
						!isNaN(parseInt(props.float)) ?
						parseFloat(props.defaultValue).toFixed(parseInt(props.float)) : 
						parseFloat(props.defaultValue) :
						parseInt(props.defaultValue);
		}
		if(initVal !== null && !isNaN(initVal)) {
			setValue(initVal);
			setDefaultValue(initVal);
		}
	}, [props.value, props.defaultValue, props.reset]);
	
	useEffect(() => {
		if(props.maxValue && 
			props.maxValue !== maxValue) {
			setMaxValue(props.maxValue);
		}
	}, [props.maxValue]);
	
	useEffect(() => {
		if(props.minValue && 
			props.minValue !== minValue) {
			setMinValue(props.minValue);
		}
	}, [props.minValue]);
	
	useEffect(() => {
		if(value >= 0 && 
			value !== defaultValue &&
			props.onChange) {
			props.onChange({name:props.name, value: value, id: props.id ? props.id : null});
		} 
	}, [value]);
	
	// FUNCTIONS 
	function updateValue(e) {
		let iVal = e.target.value;
		let decCount = 0;
		
		if(iVal.includes('.') && iVal !== '.' && iVal.indexOf('.') !== (iVal.length - 1)) {
			const valNodes = iVal.split('.');
			decCount = valNodes[1].length;
		}
		const float = decCount < parseInt(props.float) ? decCount : parseInt(props.float);
		const udVal = iVal === '.' || iVal === '' || iVal.indexOf('.') === (iVal.length - 1) ? iVal : props.float ? parseFloat(iVal).toFixed(float) : parseInt(iVal);
		setValue(maxValue && udVal > maxValue ? maxValue : minValue && udVal < minValue ? minValue : udVal);
	}
	
	function nudgeValue(direction) {
		let factor = 1;
		let newVal;
		if(props.float) {
			factor = props.factor ? 
							parseFloat(props.factor).toFixed(props.float) :
							parseFloat(`.${'1'.padStart(props.float, '0')}`);
			if(direction === 'up') {
				newVal = (parseFloat(value) + parseFloat(factor)).toFixed(props.float)
				setValue(maxValue && newVal > maxValue ? maxValue : newVal);
			}else if(direction === 'down') {
				newVal = parseFloat(value) - factor >= 0 ?
									(parseFloat(value) - factor).toFixed(props.float) : 
										0.0000;
				setValue(minValue && newVal < minValue ? minValue : newVal);
			}	
		}else{
			factor = props.factor ? parseInt(props.factor) : 1;
			if(direction === 'up') {
				newVal = (parseInt(value) + factor);
				setValue(maxValue && newVal > maxValue ? maxValue : newVal);
			}else if(direction === 'down') {
				newVal = parseInt(value) - factor >= 0 ? (parseInt(value) - factor) : 0;
				setValue(minValue && newVal < minValue ? minValue : newVal);
			}
		}
	}
	
	//console.log("NUMBER CTL", props.value, value, props.name);
	return (
		<div className="input-controlled">
			<div className="label">{props.label}</div>
			<input name={props.name} style={props.inputStyle ? props.inputStyle : null} value={value ? value : ''} onChange={updateValue} />
			<div className="ctl">
				<IconToken icon="arrow" 
							className={props.orientation === 'vertical' ? 'rotate270' : ''} 
							action={()=>{nudgeValue('down')}} />
				<IconToken icon="arrow" 
							className={props.orientation === 'vertical' ? 'rotate90' : 'rotate180'} 
							action={()=>{nudgeValue('up')}} />
			</div>
		</div>
	)
}