import React, {useEffect, useState, Fragment} from 'react';

import IconSVG from 'components/icons/IconSVG';
import IconToken from 'components/icons/IconToken';
import FormTaskAssign from 'components/forms/common_forms/FormTaskAssign';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [canEdit, setCanEdit] = useState(null);
	const [isSuper, setIsSuper] = useState(null);
	const [isActive, setIsActive] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.organizationID && props.organizationID !== organizationID) {
			setOrganizationID(props.organizationID);
		}
	}, [props.organizationID]);
	
	useEffect(() => {
		if(props.languageCode && props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}else if(!languageCode) {
			setLanguageCode('en');
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		props.canEdit &&
		props.canEdit !== canEdit &&
		setCanEdit(props.canEdit);
	}, [props.canEdit]);
	  
	useEffect(() => {
		props.isSuper &&
		props.isSuper !== isSuper &&
		setIsSuper(props.isSuper);
	}, [props.isSuper]);
	
	// FUNCTIONS
	function loadAssignUser() {
		props.setModal(
			<FormTaskAssign {...props}
				userID={userID}
				token={token}
				organizationID={organizationID}
				languageCode={languageCode}
				location={props.location}
				source={props.source}
				canEdit={canEdit}
				isSuper={isSuper}
				task={props.task}
				actionComplete={assignTaskComplete}
				users={props.objExists(props.admin, 'staff.users') ? props.admin.staff.users : null}
				groups={props.objExists(props.admin, 'staff.groups') ? props.admin.staff.groups : null} />
		)
	}
	
	function assignTaskComplete() {
		props.setModal(null);
	}
	
	function ignoreTask(val) {
		console.log("IGNORE TASK?", val);
		const ignoreParams = [
			{name: 'process', value: 'update_task'},
			{name: 'user_id', value: userID},
			{name: 'token', value: token},
			{name: 'task_id', value: props.task.task_id},
			{name: 'task_ignored', value: val},
			{name: 'return_bool', value: true}
		];
		console.log("IGNORE PARAMS", ignoreParams);
		props[`${props.location ? props.location : 'admin'}LoadState`](ignoreParams, 'tasks', 'reload.current.tasks')
	}
	
	function publishTask(val) {
		console.log("IGNORE TASK?", val);
		const publishedParams = [
			{name: 'process', value: 'update_task'},
			{name: 'user_id', value: userID},
			{name: 'token', value: token},
			{name: 'task_id', value: props.task.task_id},
			{name: 'task_published', value: val},
			{name: 'return_bool', value: true}
		];
		console.log("IGNORE PARAMS", publishedParams);
		props[`${props.location ? props.location : 'admin'}LoadState`](publishedParams, 'tasks', 'reload.current.tasks')
	}
		
	return (
		props.task && languageCode &&
		<Fragment>
			<h3><IconSVG icon={props.isSubtask ? 'sub' : 'task'} />
				{`${props.capitalizeString(props.task[languageCode].task_name, true)}${props.task.task_ignored ? ' (Ignored)' : ''}`}
				{props.task.task_completed && <IconSVG icon="complete" />}
				{props.task.task_published === 0 && props.task.task_ignored === 0 && 
					<IconSVG icon='pending' />
				}
				{props.task.task_published  === 1 && !props.task.task_completed && 
					<IconSVG icon='work' />
				}
			</h3>
			<div className="grid-block">
				<div>Task ID</div>
				<div>{props.task.task_id}</div>
				
				<div>Status</div>
				<div>
					{
						props.objExists(props.task, `status[0]`) ?
							props.capitalizeString(props.task.status[0][languageCode].status_name, true) :
							'unset'
					}
				</div>
				
				{props.task.task_started && props.task.task_started !== props.task.task_completed &&
					<Fragment>
						<div>Began</div>
						<div>{`${props.dateDisplay(props.task.task_started)} ${props.timeDisplay(props.task.task_started, false)}`}</div>
					</Fragment>
				}
				{props.task.task_completed &&
					<Fragment>
						<div>Completed</div>
						<div>{`${props.dateDisplay(props.task.task_completed)} ${props.timeDisplay(props.task.task_completed, false)}`}</div>
					</Fragment>
				}
				{props.objExists(props.task, 'users.owner.user_id') &&
					<Fragment>
						<div>
							{props.task.task_completed ? 
							`${props.task.type_name === 'approval' ? 'Approved' : 'Completed'} by` : 
							'Current User'}
						</div>
						<div>{`${props.task.users.owner.contact.contact_name_first} ${props.task.users.owner.contact.contact_name_last}`}</div>
					</Fragment>
				}
				{Array.isArray(props.objGetValue(props.task, 'users.linked')) &&
					<Fragment>
						<div>Assigned users</div>
						<div>{props.task.users.linked.map((user, index, array) => {
							return `${user.contact_name_first} ${user.contact_name_last}${index + 1 < array.length ? ', ' : ''}`;})}
						</div>
					</Fragment>
				}
				{Array.isArray(props.objGetValue(props.task, 'users.groups')) &&
					<Fragment>
						<div>Assigned groups</div>
						<div>{props.task.users.groups.map((group, index, array) => {
							return `${group.group_name}${index + 1 < array.length ? ', ' : ''}`;})}
						</div>
					</Fragment>
				}
			</div>
			{!props.task.task_completed &&
				<div className="ctl left multi">
					<IconToken icon="user-add" tooltip="Assign user" action={loadAssignUser} />
					<IconToken icon="group-add" tooltip="Assign group" />
					<IconToken icon={props.task.task_ignored ? `target` : `ignore`} 
								tooltip={props.task.task_ignored ? `Activate Task` : `Ignore Task`}
								action={()=>{ignoreTask(props.task.task_ignored ? false : true)}} />
					<IconToken icon={props.task.task_published ? `hide` : `eye`} 
								tooltip={props.task.task_published ? `Unpublish Task` : `Publish Task`}
								action={()=>{publishTask(props.task.task_published ? false : true)}} />
					<IconToken icon="cancel" tooltip="Cancel task" />
					<IconToken icon="complete" tooltip="Complete task" />
				</div>
			}
		</Fragment>
	)
}