import React from 'react';
import PagePublic from 'components/templates/PagePublic';

import * as utils from 'methods/site';

import Messages from 'components/organisms/Messages';

export default () => {
  return (
    <PagePublic>
      <Messages {...utils} />
    </PagePublic>
  )
}
