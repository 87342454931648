import React from 'react';
import PagePublic from 'components/templates/PagePublic';

import Checkout from 'components/cart/Checkout';
// import Checkout from 'components/cart/CartCheckout';

export default () => {
  return (
    <PagePublic >
      <Checkout />
    </PagePublic>
  )
}
