import React, {useEffect, useState} from 'react';

import Link from 'components/navigation/Link';
import {getPath} from 'methods/site';

export default (props) => {
  
  const headerID = <div className="header-id">
                      <img className="logo" 
                            src={props.site_logo ? props.site_logo : '/assets/img/ae-core-logo.svg'} 
                            alt={props.organization_name} />
                    </div>
  return (
    <Link to={props.linkTo ? `/${props.linkTo}` : '/'} content={headerID} />
  )
}
