import React, {useEffect, useState, Fragment} from 'react';

import TaskMatrixItemContent from 'components/profiles/tasks/TaskMatrixItemContent';
import IconToken from 'components/icons/IconToken';
import Loader from 'components/atoms/Loader';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [canEdit, setCanEdit] = useState(null);
	const [isSuper, setIsSuper] = useState(null);
	const [isActive, setIsActive] = useState(null);
	const [task, setTask] = useState(null);
	const [parentTask, setParentTask] = useState(null);
	const [subtasks, setSubtasks] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.organizationID && props.organizationID !== organizationID) {
			setOrganizationID(props.organizationID);
		}
	}, [props.organizationID]);
	
	useEffect(() => {
		if(props.languageCode && props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}else if(!languageCode) {
			setLanguageCode('en');
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		props.canEdit &&
		props.canEdit !== canEdit &&
		setCanEdit(props.canEdit);
	}, [props.canEdit]);
	  
	useEffect(() => {
		props.isSuper &&
		props.isSuper !== isSuper &&
		setIsSuper(props.isSuper);
	}, [props.isSuper]);
	
	useEffect(() => {
		if(props.task) {
			setTask(props.task);
		}
	}, [props.task]);
	
	useEffect(() => {
		if(task && task.subtasks) {
			const sTasks = setSubTasks(task.subtasks, task[languageCode].task_name);
			if(sTasks) {
				setSubtasks(sTasks);
			}
		}
	}, [task]);
	
	// FUNCTIONS
	function setSubTasks(tasks, parent) {
		if(Array.isArray(tasks)) {
			let taskSet = [];
			tasks.map((stask, index) => {
				const subtasks = stask.subtasks ? 
									setSubTasks(stask.subtasks, parent) : 
									null;
				taskSet.push(
					<div className={`grid-item subtask${stask.task_ignored ? ' ignored' : ''}${stask.task_published === 0 && stask.task_ignored === 0 ? ' pending' : ''}${stask.task_published === 1 && !stask.task_completed && !subtasks ? ' current' : ''}`}>
						<TaskMatrixItemContent {...props}
							userID={userID}
							token={token}
							organizationID={organizationID}
							languageCode={languageCode}
							canEdit={canEdit}
							isSuper={isSuper}
							task={stask}
							parentTask={parentTask}
							isSubtask={true} />
						{subtasks &&
							<Fragment>
							<h4>Subtasks</h4>
							<div className="grid-item sub">
								{subtasks}
							</div>
							</Fragment>
						}
					</div>
				);					
			});
			if(taskSet.length > 0) {
				return taskSet;
			}else{
				return null;	
			}
		}
	}
		
	return (
		<div className={`grid-item task row${task && task.task_completed ? ' complete' : ''}${isActive ? ' active' : ''}${task && task.task_ignored ? ' ignored' : ''}${task && task.task_published === 0 && task.task_ignored === 0 ? ' pending' : ''}${task && task.task_published && !task.task_completed ? ' current' : ''}`}>
			
			{task && languageCode ?
				<Fragment>
					
					<TaskMatrixItemContent {...props}
						userID={userID}
						token={token}
						organizationID={organizationID}
						languageCode={languageCode}
						canEdit={canEdit}
						isSuper={isSuper}
						task={task}
						parentTask={parentTask} />
					{subtasks &&
						<div className="expandable">
							{subtasks}
						</div>
					}
					{subtasks &&
						<div className="ctl right">
							<IconToken 
								icon="arrow" 
								className={isActive ? null : `rotate180`} 
								action={()=>{isActive ? setIsActive(false) : setIsActive(true)}} />
						</div>
					}
				</Fragment>
			:
				<Loader />	
			}
			
		</div>
	)
}