import React, {useEffect, useState, Fragment} from 'react';

import InputHook from 'components/forms/elements/Input';
import Select from 'components/forms/elements/Select';
import TextArea from 'components/forms/elements/TextArea';
import Switch from 'components/forms/elements/Switch';
import Checkbox from 'components/forms/elements/Checkbox';
import RadioSet from 'components/forms/elements/RadioSet';
import Button from 'components/forms/elements/Button';

export default (props) => {
  const [field, setField] = useState(null);

  useEffect(() => {
    if(props.objExists(props.field, 'field_type')) {
      switch(props.field.field_type) {
        case 'text':
        case 'email':
        case 'password':
        case 'tel':
        case 'phone':
        case 'date':
        case 'number':
        case 'float':
        case 'price':
        case 'cc-number':
        case 'cc-expiry':
        case 'cc-cvv':
          setField(<InputHook type={props.field.field_type}
                              defaultValue={props.field.field_default ? props.field.field_default :
                                                props.field.field_value ? props.field.field_value : null}
                              isDecimal={props.field.field_type === 'float' ? true: false}
                              name={props.field.field_id ? props.field.field_id : props.field.field_name}
                              label={props.field.field_label}
                              placeholder={props.field.field_placeholder ? props.field.field_placeholder : null}
                              required={props.field.field_required}
                              updateAction={null}
                              onChange={null}
                              onBlur={null}
                              onLoad={null}
                              tabIndex={props.field.field_order ? props.field.field_order : 1}
                              disabled={props.field.field_disabled === true ? true : false}
                              focus={false}
                              reveal={props.field.field_reveal ? props.field.field_reveal : null} /> )
            break;
          case 'select':
            setField(props.field.option_groups &&
                        Array.isArray(props.field.option_groups) &&
                        props.field.option_groups ?

                          <Select option_groups={props.field.option_groups}
                                  name={props.field.field_id ? props.field.field_id : props.field.field_name}
                                  index={props.field.field_index ? props.field.field_index : null}
                                  defaultValue={props.field.field_default ? props.field.field_default :
                                                  props.field.field_value ? props.field.field_value : null}
                                  conditionalOnLoad={null}
                                  label={props.field.field_label}
                                  required={props.field.field_required ? true : false}
                                  updateAction={null}
                                  onChange={null}
                                  clearConditional={null}
                                  onLoad={null}
                                  placeholder={props.field.field_placeholder}
                                  tabIndex={props.field.field_order ? props.field.field_order : 1} />
                        : <p>You need to set options for this Select field</p>);
            break;
            case 'switch':

                  setField(<Switch name={props.field.field_name}
                                    value={props.field.field_value ? props.field.field_value ? true : false : props.field.field_default ? true : false}
                                    label={props.field.field_label}
                                    onChange={null}
                                    onLoad={null}
                                    tabIndex={props.field.field_order ? props.field.field_order : 1}
                                     />);
            break;
            case 'textarea':
            setField(<TextArea name={props.field.field_id ? props.field.field_id : props.field.field_name}
                                label={props.field.field_label}
                                placeholder={props.field.field_placeholder}
                                onChange={props.field.fieldOnChange ? props.field.fieldOnChange : props.fieldOnChange ? props.fieldOnChange : null}
                                onLoad={props.fieldOnLoad ? props.fieldOnLoad : null}
                                value={props.field.field_value ? props.field.field_value : props.field.field_default}
                                tabIndex={props.field.field_order ? props.field.field_order : 1}
                                required={props.field.field_required}
                                richText={props.field_format_text === true ? true : false}
                                codeText={props.field_allow_code === true ? true : false}
                                showCtl={props.field_show_ctl === true ? true : false} />);
            break;
            case 'radio':
              setField(<RadioSet name={props.field.field_id ? props.field.field_id : props.field.field_name}
                                  label={props.field.field_label}
                                  onChange={null}
                                  onLoad={null}
                                  value={props.field.field_value ? props.field.field_value : props.field.field_default}
                                  tabIndex={props.field.field_order ? props.field.field_order : 1}
                                  required={props.field.field_required}
                                  />)
              break;
            case 'hidden':
              setField(<p>This field is hidden</p>);
            break;
        default:
          break;
      }
    }
  }, [props.field]);

  // FUNCTIONS
  function closeModal(e) {
    props.setModal(null);
    if(props.actionClearPreview) {
      props.actionClearPreview(e);
    }
  }



  return (
    <Fragment>
      <div className="form-row" style={{minWidth: '25em'}}>
        {field ? field : "Field Incomplete"}
      </div>
      {props.modalDisplay &&
        <div className="form-row" style={{minWidth: '25em', justifyContent: 'center'}}>
          <Button icon="add"
                  label="Close"
                  btnClass="errorBG"
                  iconClass="rotate45"
                  btnAction={closeModal} />
        </div>
      }
    </Fragment>
  )
}
