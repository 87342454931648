import React, {useEffect, useState} from 'react';

import IconSVG from 'components/icons/IconSVG';

export default (props) => {
	const [status, setStatus] = useState(null);
	const [statusMsg, setStatusMsg] = useState(null);
	const [statusIcon, setStatusIcon] = useState('gear');
	const [statusClass, setStatusClass] = useState(null);
	
	useEffect(() => {
		if(props.projectStatus) {
			if(props.projectStatus !== 'new') {
				setStatus(props.projectStatus);
			}else{
				setStatus("pending");
			}
		}else if(props.taskStatus) {
			setStatus(props.taskStatus);
		}
	}, [props.taskStatus, props.projectStatus]);
	
	useEffect(() => {
		if(status) {
			switch(status.toLowerCase()) {
				case 'process':
					setStatusIcon('gear');
					setStatusMsg('Ready to process');
					setStatusClass('caution-dark-bg');
					break;
				case 'client review':
					setStatusMsg('Pending client review');
					setStatusIcon('search');
					setStatusClass('caution-bg');
					break;
				case 'shipped':
					setStatusMsg(props.capitalizeString(status, true));
					setStatusIcon('shipping');
					setStatusClass('info-bg');
					break;
				case 'complete':
					setStatusMsg('Complete');
					setStatusIcon('check');
					setStatusClass('success-bg');
					break;
				case 'cancelled':
					setStatusMsg('Cancelled');
					setStatusIcon('cancel');
					setStatusClass('error-bg');
					break;
				case 'archived':
					setStatusMsg('Archived');
					setStatusIcon('archive');
					setStatusClass('info-bg');
					break;
				default:
					setStatusMsg(props.capitalizeString(status, true));
					break;	
			}
		}
	}, [status])
	
	return (
		<div className={`status-inline${statusClass ? ` ${statusClass}` : ''}`}>
			<IconSVG icon={statusIcon}
					className="btn" />&nbsp;&nbsp;
			{statusMsg ? props.capitalizeString(statusMsg, true) : 'Pending'}
		</div>
	)
}