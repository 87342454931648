import React, {useEffect, useState} from 'react';

import Loader from 'components/atoms/Loader';
import FormDynamic from 'components/forms/FormDynamic';

export default (props) => {
	const [form, setForm] = useState(null);
	const [fieldsets, setFieldsets] = useState(null);
	
	useEffect(() => {
		setFieldsets([{fieldset_name: `${props.shipping_info ? 'Edit' : 'Add'} Blob`,
			fields: [
				{field_type: 'textarea',
				field_name: 'shipping_info',
				field_label: 'Shipping Info',
				field_value: props.shipping_info ? props.shipping_info : null,
				field_required: true,
				field_validate: false,
				field_order: 1,
				},
				{field_type: 'hidden',
				field_name: 'process',
				field_value: props.shipping_info ?
								'update_shipping_info' :
								'add_shipping_info'
				},
				{field_type: 'hidden',
				field_name: 'user_id',
				field_value: props.userID
				},
				{field_type: 'hidden',
				field_name: 'token',
				field_value: props.token
				},
				{field_type: 'hidden',
				field_name: 'organization_id',
				field_value: props.organizationID
				},
				{field_type: 'hidden',
				field_name: 'option_id',
				field_value: props.option_id
				},
			]
		}]);
	}, [props.shipping_info]);
	
	useEffect(() => {
		if(fieldsets) {
			setForm(
				<FormDynamic
				  content={fieldsets}
				  name="add-edit-blob"
				  returnObject={true}
				  fieldOnLoad={null}
				  formActions={{formCancel: handleCancel, formSubmit: handleAddEdit}} />
			)
		}
	}, [fieldsets]);
	
	function handleCancel(e) {
		props.objExists(props, 'actionCancel') &&
		props.actionCancel();
	}
	
	function handleAddEdit(params) {
		props.objExists(props, 'actionAddEdit') &&
		props.actionAddEdit(params);
	}
	
	return (
		<div>
			{form ? form : <Loader />}
		</div>
	)	
}