import * as methods from 'methods/reducers';

export default function site(state = {}, action) {
  switch (action.type) {
    case 'INIT_SITE':
      return {...state, persona: action.payload};
    case 'INIT_FEATURES':
      return {...state, site_features: action.payload};
    case 'SITE_PAY_OPTIONS':
      return {...state, pay_options: action.pay_options};
    case 'SET_MENU_STATE':
      return {...state, menuState: action.payload, };
    case 'SITE_SHOW_NOTIFICATION':
      return {...state, notification: action.payload,};
    case 'SITE_REMOVE_NOTIFICATION':
      return {...state, notification: null};
    case 'REMOVE_STORAGE_ITEM':
      switch(action.location) {
        case 'session':
          sessionStorage.removeItem(action.item);
          break;
        case 'local':
          localStorage.removeItem(action.item);
          break;
        default:
          break;
      }
      return state;
    case 'ALERT_ERROR':
      return {...state, [action.errorType]: action.payload};
    case 'SITE_SET_VALUE':
      const setValState = methods.setStateValue(state, action.name, action.value);
      if(setValState) {
        const stateSetLoc = action.name.includes('.') ? action.name.split('.')[0] : action.name;
        let storageName = '';
        switch(stateSetLoc) {
          case null:
            break;
          case 'organization':
            storageName = 'admOrg';
            break;
          case 'product':
            storageName = 'admPrd';
            break;
          case 'user':
            storageName = 'admUsr';
            break;
          default:
            storageName = `adm${stateSetLoc}`;
            break;
        }
        action.sStore &&
        sessionStorage.setItem(storageName, JSON.stringify(state[stateSetLoc]));
      }
      return {...state};
    case 'SITE_DELETE_VAL':
      let removeValState;
      if(Array.isArray(action.payload)) {
        action.payload.map(location => {
          removeValState = methods.removeStateValue(state, location);
          return true;
        })
      }else{
        removeValState = methods.removeStateValue(state, action.payload);
      }
      if(removeValState) {
        return {...removeValState};
      }else{
        return {...state};
      }
    case 'SITE_LOAD_STATE':
      let payload = action.payload;
      if(action.location.indexOf('.')) {
        const locationNodes = action.location.split('.');
        let valVar = null;
        for(var i = locationNodes.length - 1; i >= 0; i--) {
          let stateVal = state;
          for(var j = 0; j <= i; j++) {
            if(stateVal && stateVal[locationNodes[j]]) {
              stateVal = stateVal[locationNodes[j]];
            }else{
              stateVal = null;
            }
          }
          if(i === locationNodes.length-1) {
            valVar = {[locationNodes[i]]: action.payload};
          }else{
            if(stateVal) {
              valVar = {[locationNodes[i]]: {...stateVal, ...valVar}}
            }else{
              valVar = {[locationNodes[i]]: {...valVar}};
            }
          }
        }
        payload = valVar;
      }
      return {...state, ...payload};
    default:
      return state;
  }
}
