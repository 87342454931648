import React, {useEffect, useState} from 'react';

import Link from 'components/navigation/Link';

export default (props) => {
	const [languageCode, setLanguageCode] = useState(null);
	const [category, setCategory] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(props.languageCode &&
			props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}else if(!props.languageCode) {
			setLanguageCode('en');
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.category && 
			props.category !== category) {
			setCategory(props.category);
		}
	}, [props.category]);
	
	
	return(
		category && languageCode &&
		<Link to={props.link}>
		<div className="category block">
			<div className="image">
				<img src={`https://aecore.app${props.objExists(category, `${languageCode}.category_image`) ? 
						category[languageCode].category_image : 
						"/assets/media/aeImageUnavailable.jpg"}`} 
						alt={props.objExists(category, `${languageCode}.category_name`) ? 
							props.capitalizeString(category[languageCode].category_name) : 
							`image unavailable`} />
			</div>
			<div className="title">
				{props.capitalizeString(category[languageCode].category_name, true)}
			</div>
			<div className="content">
				{category.subcategory_count > 0 &&
					<div className="subtext">
						{`${category.subcategory_count} ${
							category.subcategory_count === 1 ? 'Category' : 'Categories'}`}
					</div>
				}
				
				{category.product_count > 0 &&
					<div className="subtext">
						{`${category.product_count} ${
							category.product_count === 1 ? 'Product' : 'Products'}`}
					</div>
				}
			</div>
		</div>
		</Link>
	)
}