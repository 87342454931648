import React, {useEffect, useState} from 'react';

import ModalConfirmation from 'components/molecules/ModalConfirmation';
import PersonaHosts from 'components/profiles/orgs/PersonaHosts';
import PersonaPorts from 'components/profiles/orgs/PersonaPorts';
import PersonaPayOptions from 'components/profiles/orgs/PersonaPayOptions';
import PersonaCharges from 'components/profiles/orgs/PersonaCharges';
import FormChargesAddEdit from 'components/forms/common_forms/FormChargesAddEdit';
import PersonaShippingOptions from 'components/profiles/orgs/PersonaShippingOptions';
import PersonaPrefs from 'components/profiles/orgs/PersonaPrefs';
import FormHostAddEdit from 'components/forms/common_forms/FormHostAddEdit';
import FormPortAddEdit from 'components/forms/common_forms/FormPortAddEdit';
import PersonaSlideshow from 'components/profiles/orgs/PersonaSlideshow';
import PersonaArticles from 'components/profiles/orgs/PersonaArticles';
import PersonaFeatures from 'components/profiles/orgs/PersonaFeatures';
import PersonaClientAssignments from 'components/profiles/orgs/PersonaClientAssignments';
import Switch from 'components/forms/elements/Switch';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [persona, setPersona]= useState(null);
  const [token, setToken] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [curLanguage, setCurLanguage] = useState(null);
  const [canEdit, setCanEdit] = useState(null);
  const [isSuper, setIsSuper] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [users, setUsers] = useState(null);
  const [orgGroups, setOrgGroups] = useState(null);
  const [staffGroups, setStaffGroups] = useState(null);
  
  useEffect(() => {
    props.userID &&
    props.userID !== userID &&
    setUserID(props.userID);
  }, [props.userID]);

  useEffect(() => {
    props.token &&
    props.token !== token &&
    setToken(props.token);
  }, [props.token]);

  useEffect(() => {
    if(props.organizationID && 
        props.organizationID !== organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);

  useEffect(() => {
    props.languageCode && 
    !languageCode &&
    setLanguageCode(props.languageCode);
  }, [props.languageCode]);
  
  useEffect(() => {
    props.userAccess && 
    !userAccess &&
    setUserAccess(props.userAccess);
  }, [props.userAccess]);
  
  useEffect(() => {
    props.canEdit && 
    props.canEdit !== canEdit &&
    setCanEdit(props.canEdit);
  }, [props.canEdit]);
  
  useEffect(() => {
    props.isSuper &&
    props.isSuper !== isSuper &&
    setIsSuper(props.isSuper);
  }, [props.isSuper]);
  
  useEffect(() => {
    if(props.persona && props.persona !== persona) {
      setPersona(props.persona);
    }
  }, [props.persona]);
  
  useEffect(() => {
    if(props.orgGroups && props.orgGroups !== orgGroups) {
      setOrgGroups(props.orgGroups);
    }
  }, [props.orgGroups]);
  
  useEffect(() => {
    if(props.staffGroups && props.staffGroups !== staffGroups) {
      setStaffGroups(props.staffGroups);
    }
  }, [props.staffGroups]);

  // FUNCTIONS
  function loadAddEditHost(host_id = null) {
      props.setModal(
        <FormHostAddEdit {...props}
                          host={host_id && 
                                Array.isArray(props.objGetValue(persona, 'hosts')) ? 
                                  persona.hosts.find(host => host.host_id === host_id) :
                                  null}
                          action={addEditHost} />
      )
  }

  function addEditHost(params) {
    props.setModal(null);
    params = [
      ...params,
      {name: 'user_id', value: userID},
      {name: 'token', value: token},
      {name: 'organization_id', value: organizationID},
      {name: 'return_bool', value: true}
    ]
    props.adminLoadState(params, 'site', 'reload.persona');
  }

  function loadAddEditPort(port_id = null) {
      props.setModal(
        <FormPortAddEdit {...props}
                          port={persona.ports.find(port => port.port_id === port_id)}
                          action={addEditPort} />
      )
  }

  function addEditPort(params) {
    props.setModal(null);
    params = [
      ...params,
      {name: 'user_id', value: userID},
      {name: 'token', value: token},
      {name: 'organization_id', value: organizationID},
      {name: 'return_bool', value: true}
    ]
    props.adminLoadState(params, 'site', 'reload.persona');
  }

  function loadPaymentOptionChange(params, value) {
      console.log("Changing Payment Options", params, value);
      props.setModal(<ModalConfirmation {...props}
                        message={`${value === true ? 'Add ' : 'Remove '} <b>${params.option_name}</b> ${value === true ? 'to' : 'from'} payment options for this organization?`}
                        actionAccept={() => {value === true ? activatePaymentOption(params.option_value) : removeSetting(params.option_value, 'paymentOptionToOrganization')}} />
                    )
  }

  function activatePaymentOption(option_id) {
    props.setModal(null);
    const params = [
      {name: 'process', value: 'add_link'},
      {name: 'user_id', value: userID},
      {name: 'token', value: token},
      {name: 'organization_id', value: organizationID},
      {name: 'link_from', value: option_id},
      {name: 'link_to', value: persona.organization_id},
      {name: 'link_type', value: 'paymentOptionToOrganization'},
      {name: 'link_published', value: true},
      {name: 'return_bool', value: true}
    ]
    console.log("Here's the payment options params", params);
    props.adminLoadState(params, 'links', 'reload.persona');
  }
  
  function loadAddEditCharge(charge = null, type = null) {
      props.setModal(
        <FormChargesAddEdit {...props}
                          charges={charge}
                          type={type}
                          actionCancel={()=>{props.setModal(null);}}
                          actionAddEdit={addEditCharge} />
      )
  }
  
  function addEditCharge(params) {
    props.setModal(null);
    params = [
      ...params,
      {name: 'user_id', value: userID},
      {name: 'token', value: token},
      {name: 'organization_id', value: organizationID}
    ]
    props.adminLoadState(params, 'pricing', 'reload.persona');
  }
  
  function loadRemoveCharge(charge_id, charge_type) {
    props.setModal(<ModalConfirmation {...props}
                        message="Are you sure you wish to remove this charge?"
                        actionAccept={()=>{removeSetting(charge_id, charge_type)}} />)
  }
  
  function loadShippingOptionChange(params, value) {
      props.setModal(<ModalConfirmation {...props}
                        message={`${value === true ? 'Add ' : 'Remove '} <b>${params.option_name}</b> ${value === true ? 'to' : 'from'} shipping options for this organization?`}
                        actionAccept={() => {activateShippingOption(params.option_value, value)}} />
                    )
  }
  
  function activateShippingOption(option_id, value) {
    props.setModal(null);
    console.log("ACTIVATE SHIPPING OPTION RECEIVED", option_id, value);
    
    const paramsShippingOption = [
      {name: 'process', value: 'update_org_shipping_option'},
      {name: 'user_id', value: userID},
      {name: 'token', value: token},
      {name: 'option_id', value: option_id},
      {name: 'organization_id', value: organizationID},
      {name: 'option_published', value: value}
    ];
    
    console.log("ACTIVATE SHIPPING LOAD STATE", paramsShippingOption);
    
    props.adminLoadState(paramsShippingOption, 'organizations', 'reload.persona');
  }

  function loadRemoveSetting(host_id, setting_type) {
    props.setModal(<ModalConfirmation {...props}
                        message="Are you sure you wish to remove this host?"
                        actionAccept={()=>{removeSetting(host_id, setting_type)}} />)
  }

  function removeSetting(link_from, setting_type) {
    props.setModal(null);
    const params = [
      {name: 'process', value: 'update_links'},
      {name: 'link_from', value: link_from},
      {name: 'link_to', value: organizationID},
      {name: 'link_type', value: setting_type},
      {name: 'link_field', value: 'link_removed'},
      {name: 'link_value', value: true},
      {name: 'user_id', value: userID},
      {name: 'token', value: token}
    ]
    props[`${props.location ? props.location : 'admin'}LoadState`](params, 'links', 'reload.persona');
  }

  function setPublicAccess(val) {
    console.log("Public Access is ", val);
    const publicAccess = props.objGetValue(persona, `organization_public`) === 1 ? true : false;
    if( publicAccess !== val.value) {
      const params = [
        {name: 'process', value: 'update_organization_field'},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
        {name: 'organization_id', value: persona.organization_id},
        {name: 'organization_field', value: 'organization_public'},
        {name: 'organization_value', value: val.value},
      ]
      console.log("Here's teh public params", params);

      props.adminLoadState(params, 'organizations', 'reload.persona');
    }
  }

  function loadAddEditPref(property_id) {
    console.log("ADD EDIT PREF", property_id);
  }

  return (
    <div>
      <Switch label="Public Access (No Login Req?)"
              name="organization_public"
              value={persona && persona.organization_public === 1 ? true : false}
              onChange={setPublicAccess}
               />
      <PersonaPrefs {...props}
                    prefs={props.objExists(persona, 'prefs') ? persona.prefs : null}
                    actionAddEdit={loadAddEditPref}
                    userID={userID}
                    token={token}
                    canEdit={canEdit}
                    isSuper={isSuper}
                    organizationID={organizationID}
                    languageCode={languageCode} />
      <PersonaClientAssignments {...props}
                    approverGroups={props.objExists(persona, `client_approvers.groups`) ? persona.client_approvers.groups : null}
                    approverUsers={props.objExists(persona, `client_approvers.users`) ? persona.client_approvers.users : null}
                    users={users}
                    orgGroups={orgGroups}
                    staffGroups={staffGroups}
                    userID={userID}
                    token={token}
                    canEdit={canEdit}
                    isSuper={isSuper}
                    userAccess={userAccess}
                    organizationID={organizationID}
                    languageCode={languageCode}  />
      <PersonaHosts {...props}
                    hosts={props.objExists(persona, 'hosts') ? persona.hosts : null}
                    actionAddEdit={loadAddEditHost}
                    actionRemove={loadRemoveSetting}
                    userID={userID}
                    token={token}
                    canEdit={canEdit}
                    isSuper={isSuper}
                    organizationID={organizationID}
                    languageCode={languageCode} />
      <PersonaPorts {...props}
                    ports={props.objExists(persona, 'ports') ? persona.ports : null}
                    actionAddEdit={loadAddEditPort}
                    actionRemove={loadRemoveSetting}
                    userID={userID}
                    token={token}
                    canEdit={canEdit}
                    isSuper={isSuper}
                    organizationID={organizationID}
                    languageCode={languageCode} />
      <PersonaShippingOptions {...props}
                    shippingOptions={props.objExists(persona, `shipping_options.${languageCode}`) ? persona.shipping_options[languageCode] : null}
                    loadOptionChange={loadShippingOptionChange}
                    userID={userID}
                    token={token}
                    canEdit={canEdit}
                    isSuper={isSuper}
                    userAccess={userAccess}
                    organizationID={organizationID}
                    languageCode={languageCode}  />
      <PersonaPayOptions {...props}
                    payOptions={props.objExists(persona, 'payment_options') ? persona.payment_options : null}
                    billingContact={props.objExists(persona, 'site_billing') ? persona.site_billing : null}
                    users={props.users ? props.users : null}
                    loadOptionChange={loadPaymentOptionChange}
                    userID={userID}
                    token={token}
                    canEdit={canEdit}
                    isSuper={isSuper}
                    organizationID={organizationID}
                    languageCode={languageCode}  />
      <PersonaCharges {...props}
                      charges={props.objExists(persona, 'charges') ? persona.charges : null}
                      actionAddEdit={loadAddEditCharge}
                      actionRemove={loadRemoveCharge} />
      <p>Site Components</p>
      <PersonaSlideshow {...props}
                      slideshows={props.objExists(persona, 'slideshows') ? persona.slideshows : null}
                      userID={userID}
                      token={token}
                      ssLanguage={props.siteLanguage}
                      organizationID={organizationID}
                      userID={userID}
                      token={token}
                      canEdit={canEdit}
                      isSuper={isSuper}
                      organizationID={organizationID}
                      languageCode={languageCode} />
      <PersonaArticles {...props}
                        articles={props.objExists(persona, 'articles') ? persona.articles : null}
                        userID={userID}
                        token={token}
                        Language={props.siteLanguage}
                        organizationID={organizationID}
                        userID={userID}
                        token={token}
                        canEdit={canEdit}
                        isSuper={isSuper}
                        organizationID={organizationID}
                        languageCode={languageCode} />
      <PersonaFeatures {...props}
                        features={props.objExists(persona, 'features') ? persona.features : null}
                        userID={userID}
                        token={token}
                        Language={props.siteLanguage}
                        organizationID={organizationID}
                        userID={userID}
                        token={token}
                        canEdit={canEdit}
                        isSuper={isSuper}
                        organizationID={organizationID}
                        languageCode={languageCode} />

    </div>
  )
}
