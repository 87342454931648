import React, {useEffect, useState, Fragment, useRef} from 'react';

import Card from 'components/atoms/Card';
import IconToken from 'components/icons/IconToken';
import IconSVG from 'components/icons/IconSVG';
import ProjectOrderItem from 'components/profiles/projects/ProjectOrderItem';
import ProjectFinancials from 'components/profiles/projects/ProjectFinancials';
import ProjectOrderLocation from 'components/profiles/projects/ProjectOrderLocation';
import Control from 'components/atoms/Control';
import TaskInline from 'components/profiles/tasks/TaskInline';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [coreOrg, setCoreOrg] = useState(null);
  const [coreUser, setCoreUser] = useState(null);
  const [projectStatus, setProjectStatus] = useState(null);
  const [isAdmin, setIsAdmin] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [canEdit, setCanEdit] = useState(null);
  const [isSuper, setIsSuper] = useState(null);
  const [order, setOrder] = useState(null);
  const [locations, setLocations] = useState(null);
  const [orderTasks, setOrderTasks] = useState(null);
  const [expand, setExpand] = useState(false);
  const [approved, setApproved] = useState(false);
  const [printOrder, setPrintOrder] = useState(false);
  const printRef = useRef();

  useEffect(() => {
    props.userID &&
    props.userID !== userID &&
    setUserID(props.userID);
  }, [props.userID]);

  useEffect(() => {
    props.token &&
    props.token !== token &&
    setToken(props.token);
  }, [props.token])

  useEffect(() => {
    props.organizationID &&
    props.organizationID !== organizationID &&
    setOrganizationID(props.organizationID);
  }, [props.organizationID]);
  
  useEffect(() => {
    props.languageCode	&&
    props.languageCode	!== languageCode &&
    setLanguageCode(props.languageCode);
  }, [props.languageCode	]);
  
  useEffect(() => {
    props.coreOrg &&
    props.coreOrg !== coreOrg &&
    setCoreOrg(props.coreOrg);
  }, [props.coreOrg]);
  
  useEffect(() => {
    if(props.coreUser &&
      props.coreUser !== coreUser) {
      setCoreUser(props.coreUser);
    }
  }, [props.coreUser]);
  
  useEffect(() => {
    if(props.projectStatus &&
      props.projectStatus !== projectStatus) {
      setProjectStatus(props.projectStatus);
    }
  }, [props.projectStatus]);
  
  useEffect(() => {
    if(props.isAdmin && !isAdmin) {
      setIsAdmin(true);
    }else if(!props.isAdmin && isAdmin) {
      setIsAdmin(false);
    }
  }, [props.isAdmin]);
  
  useEffect(() => {
    props.userAccess &&
    props.userAccess !== userAccess &&
    setUserAccess(props.userAccess);
  }, [props.userAccess]);
  
  useEffect(() => {
    props.canEdit &&
    props.canEdit !== canEdit &&
    setCanEdit(props.canEdit);
  }, [props.canEdit]);
  
  useEffect(() => {
    props.isSuper &&
    props.isSuper !== isSuper &&
    setIsSuper(props.isSuper);
  }, [props.isSuper]);
  
  useEffect(() => {
    if(props.order && props.order !== order) {
      setOrder(props.order);
    }
  }, [props.order]);
  
  useEffect(() => {
    if(Array.isArray(props.objGetValue(order, `shipping.locations`)) &&
      order.shipping.locations !== locations) {
      setLocations(order.shipping.locations);
    }
  }, [order]);
  
  useEffect(() => {
    if(!props.multiple && !expand) {
      setExpand(true);
    }
  }, [props.multiple]);
  
  useEffect(() => {
    if(props.tasks && Array.isArray(props.tasks)) {
      const oTasks = props.tasks.filter(task => task.task_parent_type === 'taskToOrder');
      if(oTasks && oTasks.length > 0) {
        setOrderTasks(oTasks);
      }
    }
  }, [props.tasks]);

  useEffect(() => {
    setApproved(props.projectApproved);
  }, [props.projectApproved]);

  useEffect(() => {
    if(printOrder) {
      props.orderActions.printOrder &&
      props.orderActions.printOrder(printRef);
      setPrintOrder(false)
    }
  }, [printRef, printOrder]);

  // FUNCTIONS
  function handlePrint(e) {
    let target = e.target;
    while(!target.classList.contains('card') && target.tagName.toLowerCase() !== 'body') {
      target = target.parentNode;
    }

    if(target.classList.contains('card') &&
      props.orderActions.printOrder) {
        props.orderActions.printOrder(target);
      }
  }

  
  return (
    order &&
    <Fragment>
      <Card className={expand ? 'active' : ''}
            forwardRef={printRef} >
        <div className="card-columns">
          {props.multiple &&
            <div className="card-block">
              <div className="title">
                {`Order ${props.index+1}`}
              </div>
            </div>
            }
        </div>
            
        <div className="card-columns wrap-reverse">
          <div className="card-block">
            
            <div className="field">
              <label>Order N<sup><u>O</u></sup></label>
              {props.orderNumDisplay(props.projectID, order.order_id, props.coreOrgCode)}
            </div>
            
            <div className="field">
              <label>Billing Method</label>
                {props.objExists(order, 'order_payment_method') ? props.capitalizeString(order.order_payment_method.replace(/\_/g, ' '), true) : 'Account'}
            </div>
          
            {props.isAdmin &&
              <div className="card-content optional">
                
                {props.objExists(order, 'billing.contact.contact_email') &&
                  <div className="field optional">
                    <IconSVG icon="email" />
                    <div className="field-value">
                      <a href={`mailto:${order.billing.contact.contact_email}`}>
                        {order.billing.contact.contact_email}
                      </a>
                    </div>
                  </div>
                }
                
                {props.objExists(order, 'billing.contact.contact_phone') &&
                  <div className="field optional">
                    <IconSVG icon="phone" />
                    <div className="field-value">
                      <a href={`tel:${order.billing.contact.contact_phone}`}>
                        {order.billing.contact.contact_phone}
                      </a>
                    </div>
                  </div>
                }
                
                {props.objExists(order, 'billing.contact.contact_fax') &&
                  <div className="field optional">
                    <IconSVG icon="fax" />
                    <div className="field-value">
                      <a href={`tel:${order.billing.contact.contact_fax}`}>
                        {order.billing.contact.contact_fax}
                      </a>
                    </div>
                  </div>
                }
                
                {props.objExists(order, 'billing.contact.contact_mobile') &&
                  <div className="field optional">
                    <IconSVG icon="mobile" />
                    <div className="field-mobile">
                      <a href={`tel:${order.billing.contact.contact_mobile}`}>
                        {order.billing.contact.contact_mobile}
                      </a>
                    </div>
                  </div>
                }
              
              </div>
            }
            
            {order.order_created < order.order_modified &&
              <div className="field">
                <label>Updated</label>
                {`${props.dateDisplay(order.order_modified)} ${props.timeDisplay(order.order_modified)}`}
              </div>
            }
          </div>
        
          <div className="card-block no-print">
            {props.isAdmin && props.orderActions && props.orderActions.printOrder &&
              <div className="field align-right">
                {props.tasks && 
                  Array.isArray(props.tasks) && 
                  props.tasks.length > 0 &&
                      <div className="task-badge">
                        <IconSVG icon="task" />
                        {`${props.tasks.length} pending task${props.tasks.length === 1 ? '' : 's'}`}  
                      </div>
                }
                {order.order_rush_fee > 0 &&
                  <div className="error">
                    <IconSVG icon="flame" /><b>RUSH</b>
                  </div>  
                }
                <IconToken icon="print" action={handlePrint} tooltip="Print Shipment" />
              </div>
            }
          
            </div>
        </div>
        <div className="card-ctl top no-print">
          {order.order_rush_fee > 0 &&
            <div className="error">
              <b>RUSH</b>
            </div>
          }
          
          {order.status && Array.isArray(order.status) &&
            props.getCurrentStatus(order.status, props.siteLanguage ? props.siteLanguage : 'en') &&
            <div>
              <div className={`status-light${props.getCurrentStatus(order.status, props.siteLanguage ? props.siteLanguage : 'en') ?
                                              ` ${props.getCurrentStatus(order.status, props.siteLanguage ? props.siteLanguage : 'en')}` : ''}`}></div>
              {props.getCurrentStatus(order.status, props.siteLanguage ? props.siteLanguage : 'en')}
            </div>
          }
        </div>
        <div className="card-set">
          <div className="card-set-block">
            {Array.isArray(locations) &&
              locations.map((location, lIndex) => {
                return (
                  <ProjectOrderLocation {...props}
                        userID={userID}
                        token={token}
                        organizationID={organizationID}
                        languageCode={languageCode}
                        coreOrg={coreOrg}
                        coreUser={coreUser}
                        key={`poLoc${lIndex}`}
                        index={lIndex}
                        location={location}
                        stateLocation={props.location}
                        orderShippingMethod={order.shipping.order_shipping_method}
                        multiple={order.shipping.locations.length > 1 ? true : false}
                        shrink={expand ? false : true}
                        items={order.items}
                        orderID={order.order_id}
                        projectID={props.projectID}
                        projectStatus={projectStatus}
                        hidePricing={props.hidePricing} />
                )
              })
            }
          </div>
        </div>
        {order.items && Array.isArray(order.items) && order.items.length > 0 &&
          <div className="card-block">
            <div className="card-content preview">
              <b>{`${order.items.length} Item${order.items.length > 1 ? 's' : ''}`}</b>
            </div>
            <div className="card-content optional margin-top-2em">
              <div className="title">Order Items</div>
              <div className="card-content-list margin-top-1em">
                {order.items.map((item, index) => {
                  let itemTasks;
                  if(props.tasks && Array.isArray(props.tasks)) {
                    itemTasks = props.tasks.filter(task => 
                      task.task_parent_type === 'taskToItem' &&
                      task.task_parent_link === item.item_id
                    );
                  }
                  return <ProjectOrderItem {...props}
                                            key={`prjtOI${index}`}
                                            userID={userID}
                                            token={token}
                                            organizationID={organizationID}
                                            languageCode={languageCode}
                                            coreOrg={coreOrg}
                                            coreUser={coreUser}
                                            isAdmin={isAdmin}
                                            userAccess={userAccess}
                                            canEdit={canEdit}
                                            isSuper={isSuper}
                                            item={item}
                                            tasks={itemTasks}
                                            taskSource={props.taskSource}
                                            projectID={props.projectID}
                                            projectStatus={projectStatus}
                                            hidePricing={props.hidePricing}
                                            orderID={order && order.order_id}
                                            locationID={Array.isArray(locations) &&
                                                        locations.length === 1 ? 
                                                          locations[0].location_id : null}
                                             />})
                }
              </div>
              {props.objGetValue(props.site, 'preferences.hide_pricing') !== true &&
                  <ProjectFinancials {...props}
                                      userID={userID}
                                      token={token}
                                      organizationID={organizationID}
                                      languageCode={languageCode}
                                      isAdmin={props.isAdmin}
                                      project_id={props.project_id}
                                      projectStatus={projectStatus}
                                      coreUser={coreUser}
                                      coreOrg={coreOrg}
                                      order={order}
                                      userAccess={userAccess}
                                      financialType="order" />
              }
            </div>
          </div>
        }
        <div className="card-ctl bottom no-print">
          <IconToken icon="arrow" action={()=>{setExpand(expand ? false : true)}} className={expand ? '' : 'rotate180'} />
        </div>
      </Card>
    </Fragment>
  )
}
