import React, {Fragment} from 'react';

import IconToken from 'components/icons/IconToken';
import ModalConfirmation from 'components/molecules/ModalConfirmation';
import FormChargesAddEdit from 'components/forms/common_forms/FormChargesAddEdit';

export default (props) => {
	
	function loadAddEdit() {
		props.setModal(
		  <FormChargesAddEdit {...props}
							  product_id={props.product_id}
							  charge={props.charge}
							  fields={props.fields}
							  actionCancel={()=>{props.setModal(null);}}
							  actionAddEdit={handleAddEdit} />);
	}
	  
	function handleAddEdit(params) {
		if(props.actionAddEdit) {
			props.actionAddEdit(params);
		}
	}
	
	function loadRemove() {
		props.setModal(
			<ModalConfirmation
				{...props}
				message={`Are you sure you want to remove the product charge?`}
				actionAccept={handleRemove} />
		)
	}
	
	function handleRemove(params) {
		if (props.actionRemove){
			if (params && Array.isArray(params)) {
				props.actionRemove(params);
			} else {
				props.setModal(null);
				props.actionRemove([
					{name: 'process', value: 'update_link_no_id'},
					{name: 'link_type', value: props.charge.field_id ? 'chargeToField' : 'chargeToProduct'},
					{name: 'link_from', value: props.charge.charge_id},
					{name: 'link_to', value: props.charge.field_id ? props.charge.field_id : props.product_id},
					{name: 'link_field', value: 'link_removed'},
					{name: 'link_value', value: true}
				]);
			}
		}
	}
	
	return (
		<Fragment>
			<div className="column">{props.capitalizeString(props.charge.charge_name, true)}</div>
			<div className="column text-center">{props.capitalizeString(props.charge.charge_display, true)}</div>
			<div className="column text-center">{props.capitalizeString(props.charge.field_label, true)}</div>
			<div className="column text-center">{props.priceDisplay(props.charge.charge_value)}</div>
			<div className="column text-center">{props.charge.charge_multiply ? 'Yes' : 'No'}</div>
			{props.editable && <div className="column text-center">
				<h4>
					<IconToken icon="edit" action={loadAddEdit} />
					<IconToken icon="trash" action={loadRemove} />
				</h4>
			</div> }
		</Fragment>
	)
	
}