import React from 'react';


export default (props) => {
  return (
    <div className="site-badge">
      {props.children}
    </div>
  )
}
