import React, {useEffect, useState, Fragment} from 'react';

import Loader from 'components/atoms/Loader';
import UsersList from 'components/profiles/users/UsersList';
import Modules from 'components/profiles/modules/Modules';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [group, setGroup] = useState(null);
	
	useEffect(() => {
		return ()=> {
			props.adminDeleteVal('group');
		}
	}, []);
	
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
		  setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token !== token) {
		  setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.languageCode &&
		  props.languageCode !== languageCode) {
		  setLanguageCode(props.languageCode);
		}else if(!languageCode) {
		  setLanguageCode('en');	
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(userID && token && props.groupID) {
			
			const groupParams = [
				{name: 'process', value: 'get_groups'},
				{name: 'user_id', value: userID},
				{name: 'token', value: token},
				{name: 'group_id', value: props.groupID}
			];
			if(!props.objExists(props.admin, 'group') || 
				props.objGetValue(props.admin, 'reload.group') === true) {
				props.adminLoadState(groupParams, 'groups', 'group');	
			}else{
				setTimeout(() => {
					props.adminLoadState(groupParams, 'groups', 'group');
				}, 600000);
			}
		}
	}, [userID, token, props.groupID]);
	
	useEffect(() => {
		if(props.objExists(props.admin, 'group')) {
			if(props.admin.group !== group) {
				if(group){
					props.notifyUser("Group has been updated");
				}
				setGroup(props.admin.group);
			}
		}
	}, [props.admin.group]);
	
	return (
		<Fragment>
			{languageCode && group ?
				<div className="profile">
					<h2>{props.objExists(group, 'organization.organization_name') ? `${group.organization.organization_name} : ` : ''}{group[languageCode].group_name}</h2>
					
					<article className="section">
						<h3>Users</h3>
						<div className="list-scroll">
							<UsersList {...props}
										users={group.users} />
						</div>
					</article>
					
					<article className="section">
						<h3>Modules</h3>
							<Modules {...props}
										userID={userID}
										token={token}
										groupID={group.group_id} />
					</article>
				
				</div>
			:
				<Loader label="Loading group..." />	
			}
		</Fragment>
	)
}