import React, {useEffect, useState, useRef, Fragment} from 'react';

import IconToken from 'components/icons/IconToken';
import IconToggle from 'components/icons/IconToggle';

import Card from 'components/atoms/Card';
import CardContent from 'components/molecules/CardContent';
import CardContentContact from 'components/molecules/CardContentContact';
import RadioBtn from 'components/forms/elements/RadioBtn';
import Button from 'components/forms/elements/Button';
import Control from 'components/atoms/Control';
import CartItem from 'components/cart/CartItem';
import CartTotals from 'components/cart/CartTotals';
import CheckoutShippingLocation from 'components/cart/CheckoutShippingLocation';
import FormLocationAddEdit from 'components/forms/common_forms/FormLocationAddEdit';
import ModalConfirmation from 'components/molecules/ModalConfirmation';
import ProjectPricing from 'components/cart/ProjectPricing';

import MatrixItemMove from 'components/organisms/MatrixItemMove';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [userLocations, setUserLocations] = useState(null);
  const [isDigital, setIsDigital] = useState(null);
  const [index, setIndex] = useState(null);
  const [order, setOrder] = useState(null);
  const [project, setProject] = useState(null);
  const [locations, setLocations] = useState(null);
  const [orderItems, setOrderItems] = useState(null);
  const [multiLoc, setMultiLoc] = useState(false);
  const [hidePricing, setHidePricing] = useState(null);
  const orderRef = useRef();

  const todayDate = new Date();
  let standardDeliveryDate = new Date();
  let expressDeliveryDate = new Date();
  standardDeliveryDate.setDate(todayDate.getDate()+14);
  expressDeliveryDate.setDate(todayDate.getDate()+7);
  
  // HOOKS
  useEffect(() => {
    if(props.userID && props.userID !== userID) {
      setUserID(props.userID);
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token && props.token !== token) {
      setToken(props.token);
    }
  }, [props.token]);
  
  useEffect(() => {
    if(props.organizationID && props.organizationID !== organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    if(props.languageCode && props.languageCode !== languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    if(Array.isArray(props.userLocations) && props.userLocations !== userLocations) {
      setUserLocations(props.userLocations);
    }
  }, [props.userLocations]);
  
  useEffect(() => {
    if(props.isDigital && !isDigital) {
      setIsDigital(true);
    }else if(!props.isDigital && isDigital) {
      setIsDigital(false);
    }
  }, [props.isDigital]);
  
  useEffect(() => {
    if(props.index >= 0 && index !== props.index) {
      setIndex(props.index);
    }
  }, [props.index]);
  
  useEffect(() => {
    if(props.project && props.project !== project) {
      setProject(props.project);
    }
  }, [props.project]);
  
  useEffect(() => {
    if(props.order && props.order !== order) {
      setOrder(props.order);
    }
  }, [props.order]);
  
  useEffect(() => {
    if(order) {
      // SETTING ORDER LOCATIONS
      if(Array.isArray(props.objGetValue(order, 'shipping.locations')) &&
        order.shipping.locations.length > 0) {
          setLocations(props.order.shipping.locations);
          if (order.shipping.locations.length > 1) {
            setMultiLoc(true);
          } else {
            setMultiLoc(false);
          }
      }
      
      // SET ORDER ITEMS AND CHECK IF DIGITAL ONLY
      if(Array.isArray(order.items)) {
        setOrderItems(order.items);
        
        order.items.map((item) => {
          if(props.itemIsDigital(item, props.languageCode)) {
            if(isDigital) {
              // setIsDigital(false);  
            }
          }
        });
      }
    }
  }, [order, locations, isDigital, props.cart]);
  
  useEffect(() => {
    if(props.hidePricing && hidePricing !== true) {
      setHidePricing(true);
    }
  }, [props.hidePricing]);
  
  // FUNCTIONS
  function toggleOrder(e) {
    if(orderRef.current.classList.contains('active')) {
      orderRef.current.classList.remove('active');
    }else{
      orderRef.current.classList.add('active');
    }
  }

  function moveItem(e, itemIndex) {
    const moveMatrix = <MatrixItemMove
                          {...props}
                          item={props.items[itemIndex]}
                          itemIndex={itemIndex}
                          orderIndex={props.index} />
    props.setModal(moveMatrix);

    // props.cartRemoveItem(itemIndex, props.index);

  }

  function removeItem(e, itemIndex) {
    props.cartRemoveItem(itemIndex, props.index);
    if(props.objExists(props.order, 'items') &&
      Array.isArray(props.order.items) &&
      props.order.items.length > 0 &&
      props.project !== JSON.parse(sessionStorage.getItem('corePrjct'))) {
        props.cartSetValue('cartCount', props.order.items.length);
        sessionStorage.setItem('corePrjct', JSON.stringify(props.project));
    } else {
      sessionStorage.removeItem('coreCRT');
      sessionStorage.removeItem('corePrjct');
      props.cartRemoveVal('project');
      props.cartRemoveVal('items');
      props.filesDeleteVal('uploads');
      props.filesDeleteVal('uploadComplete');
    }
  }

  function handleAddEdit(type, location, index = null) {
    if(props.actionAddEdit) {
      props.actionAddEdit(type, 'shipping', index);
    }
  }
  
  function handleSetValue(name, value) {
    if(props.location) {
      if(props.location === 'cart') {
        props.cartSetValue(name, value);
        props.onUpdate &&
        props.onUpdate(true);
      }else if(props.actionUpdateValue){
        props.actionUpdateValue(name, value);
        props.onUpdate &&
        props.onUpdate(true);
      }
    }
  }

  function loadAdditionalShippingLocation() {
    props.setModal(
      <FormLocationAddEdit {...props}
        userID={userID}
        token={token}
        location={null}
        locations={userLocations}
        items={order && order.items}
        actionCancel={()=>{props.setModal(null)}}
        actionAddEdit={handleAddlLocation}
        organizationID={null}
        locationType={null}
        locationLink={null}
        returnObject={true} />);
  }

  function handleAddlLocation(params) {
    props.setModal(null);
    let addlLocation = {};
    let locQtys = {};
    
    const newIndex = Array.isArray(props.objGetValue(order, 'shipping.locations')) ?
    order.shipping.locations.length : 0;
    
    const locItemParams = params.filter(param => param.name.includes('items.'));
    if(locItemParams && locItemParams.length > 0) {
      locItemParams.map((iParam) => {
        locQtys[iParam.name.replace('items.', '')] = {[newIndex]: parseInt(iParam.value)};
        props.cartSetValue(`project.orders[${props.index}].items[${parseInt(iParam.name.replace('items.', ''))}].product_quantity`, 
        parseInt(
          props.objGetValue(props.cart, 
                            `project.orders[${props.index}].items[${parseInt(iParam.name.replace('items.', ''))}].product_quantity`))+1);
      })
    }
    
    const locParams = params.filter(param => param.name.includes('location_'));
    if(locParams && locParams.length > 0) {
      locParams.map(lParam => {
        addlLocation[lParam.name] = lParam.value === 'true' ? true :
                                    lParam.value === 'false' ? false :
                                    lParam.value;
                        
      })
    }
    
    Object.entries(addlLocation).length > 0 &&
    props.cartSetValue(`project.orders[${props.index}].shipping.locations[${newIndex}]`, addlLocation);
    
    // START HERE: SETTING LOCATION QUANTITIES ON THE ITEMS
    let itemsShipped = {};
    Object.entries(locQtys).length > 0 &&
    Object.keys(locQtys).map(qKey => {
      
      if(!props.itemIsDigital(props.objGetValue(props.cart, `project.orders[${props.index}].items[${parseInt(qKey)}]`))) {
        itemsShipped = true;
      }
      
      if(props.objExists(props.cart, `project.orders[${props.index}].items[${parseInt(qKey)}]`)) {
        
        let itemLocQty = {};
        if(props.objExists(props.cart, `project.orders[${props.index}].items[${parseInt(qKey)}].location_quantities`)) {
          itemLocQty = {...props.cart.project.orders[props.index].items[parseInt(qKey)].location_quantities};
        }
        itemLocQty = {...itemLocQty, ...locQtys[qKey]}  
        props.cartSetValue(`project.orders[${props.index}].items[${parseInt(qKey)}].location_quantities`, itemLocQty);
        
        // Calculate updated product_quantity value
        let productQuantity = 0;
        Object.keys(itemLocQty).map(index => {
          productQuantity += itemLocQty[index];
        });
        
        // Calculate updated product_price value
        let pricing;
        let discounts;
        let pageCount;
        let pagePricingOption;
        let pageBasePrice;
        let pageDiscounts;
        let productCharge;
        pricing = props.cart.project.orders[props.index].items[parseInt(qKey)].details[languageCode].pricing.find((price) => {
          return price.pricing_id == props.cart.project.orders[props.index].items[parseInt(qKey)].product_pricing_id;
        });
        if (props.objExists(pricing, `discounts`)) {
          discounts = pricing.discounts;
        }
        if(props.objExists(props.cart.project.orders[props.index].items[parseInt(qKey)], `page_count`) && props.cart.project.orders[props.index].items[parseInt(qKey)].page_count > 0) {
          pageCount = props.cart.project.orders[props.index].items[parseInt(qKey)].page_count;
        }
        if (props.objExists(props.cart.project.orders[props.index].items[parseInt(qKey)], `page_pricing_option`)) {
          pagePricingOption = props.cart.project.orders[props.index].items[parseInt(qKey)].page_pricing_option;
        }
        if(pageCount && pagePricingOption) {
          pageBasePrice = parseFloat(pageCount * pagePricingOption.pricing_base_price);
          pageDiscounts = pagePricingOption.discounts;
        }
        if(props.objExists(props.site, `persona.charges.product[0].charge_value`)) {
          productCharge = props.site.persona.charges.product[0].charge_value;
        }
        if(props.objExists(props.cart.project.orders[props.index].items[parseInt(qKey)], `details.${languageCode}.charges.charge_value`)) {
          productCharge = props.cart.project.orders[props.index].items[parseInt(qKey)].details[languageCode].charges.charge_value;
        }
        const productPrice = pricing ? props.priceCalc(productQuantity, 
          props.numberInput(pricing.pricing_unit_price),
          props.numberInput(pricing.pricing_base_price),
          discounts,
          pricing.pricing_round,
          pageBasePrice,
          pageDiscounts,
          productCharge
        ) : 0;
        props.cartSetValue(`project.orders[${props.index}].items[${parseInt(qKey)}].product_price`, productPrice);
        
      }
    });
    
    props.cartSetValue(`project.orders[${props.index}].shipping.locations[${newIndex}].order_shipping_method`, 
    itemsShipped ? 'Standard' : 'Digital');
    props.cartSetValue(`project.orders[${props.index}].shipping.locations[${newIndex}].location_shipping_method`, 
                        itemsShipped ? 'Standard' : 'Digital');
  }
  
  function loadRemoveLocation(params) {
    props.setModal(
      <ModalConfirmation {...props}
          icon="shipping"
          message={`<div>
                Are you sure you want to remove a shipment to:<br />
                <b>${params.location.location_name ? params.location.location_name : 
                      `${params.location.location_address_1}${params.location.location_address_2 ? ` - ${params.location.location_address_2}` : ''}<br />
                      ${params.location.location_city}, ${params.location.location_province}`}</b><br />
                    This will also remove:<br />
                    ${Array.isArray(params.items) && params.items.map (item => {
                      return `<b>${item.product_quantity} ${item.details[languageCode].product_name}</b><br />`;
                    })}
                    from order item quantities.
              </div>`}
            actionAccept = {()=> {removeLocation(params)}}
           />
    )
  }
  
  function removeLocation(params) {
    props.setModal(null);
    let udOrder = 
      props.objExists(props.cart, `project.orders[${params.orderIndex}]`) ?
        {...props.cart.project.orders[params.orderIndex]} : null;
    if(udOrder) {
      udOrder.shipping.locations.splice(params.index, 1);
      if(Array.isArray(udOrder.items)) {
        udOrder.items.map(oItem => {
          let locationQuantities = oItem.location_quantities;
          delete locationQuantities[params.index];
          let iQty = 0;
          Object.keys(locationQuantities).map(locKey => {
            const lQty = locationQuantities[locKey];
            if(parseInt(locKey) > params.index) {
              delete locationQuantities[locKey];
              locationQuantities[String(parseInt(locKey) - 1)] = lQty;
              iQty += lQty;
            }else if(parseInt(locKey) < params.index) {
              iQty += lQty;
            }
          });
          oItem.product_quantity = iQty;
        })
      }
    }
  }
  
  return (
    <div className={`order${props.objExists(props.cart, 'project.orders') && props.cart.project.orders.length > 1 ? ' multiple' : ''}${props.isActive ? ' active' : ''}`} ref={orderRef}>
      <div className="order-header">
        <div className="order-number">{props.index+1}</div>
        <div className="order-header-title">
          <h3>
            {`${props.items ? props.items.length : 'No '} Item${(props.items && (props.items.length > 1 || props.items.length < 1) ) || !props.items ? 's' : ''}`}
            {(!props.items || props.items.length === 0 ) && <IconToken icon="alert" className="caution"/>}
          </h3>
          <div className="order-header-address">
            {props.objExists(props, 'shipping.location') &&
              <Fragment>
                <strong>Ships To: </strong>{`${props.shipping.location.location_address_1}${props.shipping.location.location_address_2 ? ` -
                ${props.shipping.location.location_address_2},` : ','}
                ${props.shipping.location.location_city}, ${props.shipping.location.location_province}`}
              </Fragment>
            }
          </div>
        </div>
        <div className="order-toggle">
          <IconToggle action={toggleOrder} />
        </div>
      </div>
      <div className="order-body">
        <h3>{`Order ${isDigital ? 'Delivery' : 'Shipping'}`}</h3>
        <div className="flex column">
        {Array.isArray(locations) &&
          locations.map((location, lindex) => {
            return <CheckoutShippingLocation
                      {...props}
                      key={`oloc${lindex}`}
                      userID={userID}
                      token={token}
                      organizationID={organizationID}
                      languageCode={languageCode}
                      orderIndex={index}
                      project={project}
                      index={lindex}
                      location={location}
                      locations={userLocations}
                      actionRemove={loadRemoveLocation}
                      shippingOptions={props.shippingOptions}
                      shippingRequired={props.shippingRequired}
                      items={props.objGetValue(order, 'items')}
                      multiple={locations.length > 1 ? true : false}
                      restrictLocations={props.restrictLocations}
                      orderMultiship={props.orderMultiship}
                      hidePricing={hidePricing}
                      errorFlags={props.errorFlags} />
          })
          
        }
        
        {!locations &&
          <div>
            <div style={{fontWeight: 'bold'}}>Ship to:</div>
            <Control icon="location-add" label="Add Shipping Location" showLabel={true} className="ctl-btn error-bg" iconClass="no-btn" />
          </div>
        }
        </div>
        {!props.restrictLocations &&
          !isDigital && 
          props.orderMultiship &&
            <Control {...props}
            icon="shipping"
            className="ctl-btn info-bg"
            label="Ship items to an additional address"
            showLabel={true}
            action={loadAdditionalShippingLocation} />  
        }
        
        {orderItems &&
          <Fragment>
            <h3>{`Order Item${props.items && props.items.length > 1 ? 's' : ''}`}</h3>
            {orderItems &&
              orderItems.map((oItem, iIndex) => {
                const itemActions = [
                  // {icon: 'split', action: moveItem, label: 'Move...', subject: index},
                  {icon: 'trash', action: removeItem, label: 'Remove...'},
                ];
                
                return(
                  <CartItem 
                    {...props}
                    index={iIndex} 
                    key={`cartitem${iIndex}`}
                    item={oItem} 
                    languageCode={languageCode}
                    orderIndex={props.index} 
                    itemLocation={`project.orders[${props.index}].items[${iIndex}]`} 
                    updateAction={props.cartUpdateItemValue} 
                    itemActions={itemActions}
                    isDigital={props.itemIsDigital(oItem, props.languageCode)}
                    multiLoc={multiLoc} />
                );
              })
            }
          </Fragment>
        }
        {project &&
          <ProjectPricing {...props} project={project} pricingLocation="order" orderIndex={index} hidePricing={hidePricing} />
        }
      </div>
    </div>
  )
}
