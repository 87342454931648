import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import IconSVG from 'components/icons/IconSVG';
import IconToken from 'components/icons/IconToken';
import CoreRule from 'components/atoms/CoreRule';
import BtnClose from 'components/atoms/BtnClose';


export default (props) => {
  let notificationRef = null;

  function detectTouches(e) {

  }

  const notificationContent = <div  onClick={removeNotification} className="notification-content">
                                <div className="notification-icon">
                                  <IconSVG icon={props.icon ? props.icon : 'alert'} />
                                </div>
                                <div className="notification-message">
                                  {props.title && <h3>{props.title}</h3>}
                                  <p>{ReactHtmlParser(props.message)}</p>
                                </div>
                                <IconToken icon="close" className="btn notification-ctl" action={removeNotification} />

                              </div>
  function setNotification() {
    const notification = document.createElement('div');
    notification.classList.add('notification');
    props.maskBG && notification.classList.add('masking');
    props.localized && notification.classList.add('localized');
    props.static && notification.classList.add('static');
    props.className && notification.classList.add(props.className);

    const notificationBox = document.createElement('div');
    notificationBox.classList.add('notification-box');
    ReactDOM.render(notificationContent, notificationBox);
    notification.append(notificationBox);
    notificationRef = notification;
    document.body.append(notification);
    setTimeout(removeNotification, 3000);
  }

  function removeNotification(e) {
    notificationRef.classList.add('inactive');
    setTimeout(()=> {notificationRef.remove()}, 700);
    props.actionRemove();
  }

  useEffect(() => {
    if(props.message) {
        setNotification();
    }
  }, [props.message]);



  return null;
}
