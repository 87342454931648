import React from 'react';
import PagePublic from 'components/templates/PagePublic';

import CustomRequestComplete from 'components/organisms/CustomRequestComplete';

export default () => {
  return (
    <PagePublic >
      <CustomRequestComplete  />
    </PagePublic>
  )
}
