import React, {useEffect, useState} from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import Loader from 'components/atoms/Loader';
import FormDynamic from 'components/forms/FormDynamic';
import ModalConfirmation from 'components/molecules/ModalConfirmation';

import * as formData from 'methods/forms.js';

export default (props) => {
	const [contact, setContact] = useState(null);
	const [contacts, setContacts] = useState(null);
	const [form, setForm] = useState(null);
	const [fieldsets, setFieldsets] = useState(null);
	
	useEffect(() => {
		if(props.contact && !contact) {
			setContact(props.contact);
		}
	}, [props.contact]);
	
	useEffect(() => {
		if(props.contacts && !contacts) {
			if(props.location && props.location.location_id) {
				let locContacts = [];
				props.contacts.map(lCon => {
					if(!lCon.location_id || parseInt(lCon.location_id) === parseInt(props.location.location_id)) {
						locContacts.push(lCon);
					}
				})
				setContacts(locContacts);	
			}else{
				setContacts(props.contacts);	
			}
		}
	}, [props.contacts]);
	
	useEffect(() => {
		let contactFields = formData.fieldsContact;
		let fields = [];
		
		if(contacts && Array.isArray(contacts) && 
			(contacts.length > 1 || contacts[0].location_id !== contact.location_id)) {
				
			let contactOptions = [{option_name: 'Your contacts...', option_value: null}];
			contacts.map(cnt => {
				contactOptions.push({
					option_name: `${cnt.contact_name_first} ${cnt.contact_name_last}`,
					option_value: cnt.contact_id,
				})
			});
			contactOptions.push({option_name: 'New Contact', option_value: 0});
				
			fields.push(
				{field_type: 'select',
				field_name: 'contacts',
				field_label: 'Your contacts',
				field_value: contact && contact.contact_id ? contact.contact_id : 0,
				fieldOnChange: loadContact,
				field_required: false,
				field_validate: false,
				option_groups: [{options: contactOptions}],
				}
			);		
		}
		
		contactFields.map((field, index) => {
			if(contact && field.field_name === 'contact_default') {
				contactFields[index].field_value = (contact.contact_user_default && 
													contact.contact_user_default	=== 1) ||
													(contact.contact_org_default && 
													contact.contact_org_default === 1) ||
													(contact.contact_default &&
													contact.contact_default === 1) 		?
															true : false;
			}else if(contact && contact[field.field_name] !== null) {
				contactFields[index].field_value = contact[field.field_name];
			}else{
				contactFields[index].field_value = null;
			}
		})
		
		fields.push(...contactFields);
		
		fields.push({field_type: 'hidden', field_name: 'process', 
					field_value: contact && contact.contact_id ? 'update_contact' : 'add_contact'});
		
		props.userID && props.token &&
		fields.push(
			{field_type: 'hidden', field_name: 'user_id', field_value: props.userID},
			{field_type: 'hidden', field_name: 'token', field_value: props.token},
		);
		
		props.locationID &&
		fields.push(
			{field_type: 'hidden', field_name: 'location_id', field_value: props.locationID}
		);
		
		props.organizationID &&
		fields.push(
			{field_type: 'hidden', field_name: 'organization_id', field_value: props.organizationID}
		);
		
		
		if(contact && contact.contact_id) {
			fields.push(
				{field_type: 'hidden', field_name: 'contact_id', field_value: contact.contact_id,}
			);
		}
		
		if(props.contactType && props.contactLink) {
			fields.push(
				{field_type: 'hidden', field_name: 'contact_type', field_value: props.contactType},
				{field_type: 'hidden', field_name: 'contact_link', field_value: props.contactLink}
			);
		}
		
		props.returnBool &&
		fields.push(
			{field_type: 'hidden', field_name: 'return_boolean', field_value: true}
		);
		
		setFieldsets(
			[
				{fieldset_name: `${contact && contact.contact_id ? 'Edit' : 'Add New'} Contact`,
				  fields: fields
			  }
			])
	}, [contacts, contact]);
	
	useEffect(() => {
		if(fieldsets) {
			setForm(
				<FormDynamic
				  content={fieldsets}
				  name="add-contact"
				  formCompleteLabel={contact && contact.contact_id ? `Update` : `Create`}
				  returnObject={true}
				  fieldOnChange={null}
				  fieldOnLoad={null}
				  formActions={{formCancel: handleCancel, formSubmit: handleAddEdit}} />
			)
		}
	}, [fieldsets]);
	
	
	
	// FUNCTIONS
	function loadContact(param) {
		if(param.value === 0) {
			setForm(null);
			setContact(null);
		}else if(param.value) {
			const contactSelected = contacts.find(cnt => cnt.contact_id === parseInt(param.value));
			if(contactSelected && contactSelected !== contact) {
				setForm(null);
				setContact(contactSelected);
			}
		}
	}
	
	function handleCancel() {
		if(props.actionCancel) {
			props.actionCancel();
		}
	}
	
	function handleAddEdit(params) {
		console.log("Hello?");
		if(props.actionAddEdit) {
			props.actionAddEdit(params, props.actionParams ? props.actionParams : null);	
		}
	}
	
	console.log("contact", contact, contacts);
	return (
		form ? form : <Loader />
	)
}