import React, {useEffect, useState} from 'react';
import PagePublic from 'components/templates/PagePublic';

import UserOrders from 'components/profiles/users/UserOrders';


export default () => {
  return (
    <PagePublic>
      <UserOrders />
    </PagePublic>
  )
}
