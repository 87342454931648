import React, {useEffect, useState, Fragment} from 'react';

import Loader from 'components/atoms/Loader';
import MediaToken from 'components/profiles/media/MediaToken';
import Checkbox from 'components/forms/elements/Checkbox';
import IconToken from 'components/icons/IconToken';

export default (props) => {
	const [active, setActive] = useState(null);
	
	// HOOKS
	useEffect(()=>{
		setActive(props.active ? true : false);
	}, [props.active]);
	
	function handleClick(e) {
		if(props.product.product_optional) {
			if(props.actionSelect) {
				props.actionSelect(props.product.product_id, active ? false : true);
			}
			setActive(active ? false : true);
		}else{
			e.preventDefault();
		}
	}
	// console.log("CHILD PRODUCT", props.product);
	return (
		<div className={`product-child${active ? ' active' : ''}${props.optional ? ' optional' : ''}`}
				onClick={handleClick}>
			{props.objExists(props.product, 'product_id') ?
				<Fragment>
					<div className="product-child-image">
						{props.objExists(props.product, 'product_image') ? 
							<MediaToken media_file_name={props.product.product_image}
										media_published={true}
										active={active ? true : false} />
						: 
							<MediaToken active={active ? true : false} /> 
						}
						
						{props.product.product_optional ?
							<Checkbox {...props} 
								name="product.included_products" 
								value={props.product.product_id}
								checked={active ? true : false}
								action={handleClick} />
							:
								<IconToken icon="lock" className="status" />
						}
					</div>
				
					<div className="label">
						{props.objExists(props.product, 'product_quantity') &&
							<div className="product-child-label-qty">{props.product.product_quantity}&nbsp;|&nbsp;</div>
						}
						<div className="product-child-label-name">{props.capitalizeString(props.product.product_name, true)}</div>
					</div>
				
				</Fragment>
			:
				<Loader />
			}
			
		</div>
	)
}