import React, {useRef} from 'react';

export default (props) => {
  const refDropZone = useRef();

  function handleDrop(e) {
    if(props.actionDrop) {
      props.actionDrop(props.index, props.params ? props.params : null);
    }
    refDropZone.current.classList.contains('target') &&
    refDropZone.current.classList.remove('target');
  }

  function allowDrop(e) {
    e.preventDefault();
  }

  function dragOver(e) {
    e.preventDefault();
    !refDropZone.current.classList.contains('target') &&
    refDropZone.current.classList.add('target');
  }

  function dragOut(e) {
    e.preventDefault();
    refDropZone.current.classList.contains('target') &&
    refDropZone.current.classList.remove('target');
  }

  function dragStart(e) {
    props.actionDragStart &&
    props.actionDragStart(e, props.params ? props.params : null);
  }

  function dragEnd(e) {
    props.actionDragEnd &&
    props.actionDragEnd(e, props.params ? props.params : null);
  }

  return (
    <div className={`drop-zone${props.className ? ` ${props.className}` : ''}`}
        ref={refDropZone}
        onDragEnter={allowDrop}
        onDragOver={dragOver}
        onDragLeave={dragOut}
        onDrop={handleDrop}
        onDragStart={dragStart}
        onDragEnd={dragEnd} >
        {props.contents ? props.content : props.children ? props.children : ''}
    </div>
  )
}
