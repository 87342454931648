import React from 'react';
import { Link } from 'react-router-dom';

// to object eg: {pathname: '/path/', query: {key1: value1, key2: value2}, hash: '#a-hash', state: ''}

export default (props) => {

  return (
    <Link
      to={props.to}
      onClick={props.action ? props.action : null}
      className={props.className ? props.className : null}
    >{props.children || props.content}</Link>
  )

}
