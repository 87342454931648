import React, {useEffect, useState} from 'react';

import Link from 'components/navigation/Link';

import Button from 'components/forms/elements/Button';
export default (props) => {
  const [defaultLocation, setDefaultLocation] = useState(null);
  const [saveActive, setSaveActive] = useState(false);
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);

  const reqInfo = [
    {field_name: 'organization_name', field_test: 'notEmpty'},
    {field_name: 'organization_code', field_test: 'notEmpty'},
  ];

  useEffect(() => {
    if(props.userID) {
      setUserID(props.userID);
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token) {
      setToken(props.token);
    }
  }, [props.token]);

  useEffect(() => {
    let saveReady = true;
    reqInfo.map(field => {
        if(saveReady && !props.objExists(props.organization, field.field_name) ||
            (field.field_test === 'notEmpty' &&
              props.objGetValue(props.organization, field.field_name).length <= 0)) {
                console.log("no value for ", field.field_name);
            saveReady = false;
          }
    })
    setSaveActive(saveReady);
  }, [props.organization]);

  useEffect(() => {
    if (props.organization.locations &&
        Array.isArray(props.organization.locations) &&
        props.organization.locations.length > 0) {
          let defaultLoc = props.organization.locations.filter(loc => loc.location_default === true);
          if(!defaultLoc || Array.isArray(defaultLoc) || defaultLoc.length <= 0) {
            defaultLoc = props.organization.locations[0];
          }

          setDefaultLocation(defaultLoc);
        }
  }, [props.organization.locations]);

  function orgAdd() {
    let params = [
      {name:'user_id', value: userID},
      {name: 'token', value: token},
      {name:'json', value: JSON.stringify(props.admin.organization)},
    ];

    if(props.objExists(props.admin, 'organization.organization_id') && props.admin.organization.organization_id > 0) {
      params.push({name:'process', value: 'update_organization'});
    }else{
      params.push({name:'process', value: 'add_organization'});
    }
    props.adminLoadState(params, 'organizations', 'organization');
  }

  return (
    <div className={`admin-object-info${props.organization ? ' active' : ''}`} >
      {props.objExists(props.organization, `site_identity.media_file_name`) &&
        <div className="admin-object-info-entry img-identity">
          <img src={`http://core.accellgraphics.ca/assets/media/${props.organization.site_identity.media_file_name}`} />
        </div>
      }
      {props.organization.organization_name &&
          <h3>{props.organization.organization_name}</h3>
      }
      {props.organization.organization_code &&
          <div className="admin-object-info-entry"><label>Org Code:</label> {props.organization.organization_code}</div>
      }

      {defaultLocation ?
        <div className="admin-object-info-entry block">
          {`${defaultLocation.location_address_1 ? defaultLocation.location_address_1 : ''}${defaultLocation.location_address_2 ? `${defaultLocation.location_address_1 ? ' - ' : ''}${defaultLocation.location_address_2}` : ''}${defaultLocation.location_city ? `, ${defaultLocation.location_city}` : ''}${defaultLocation.location_province ? `, ${defaultLocation.location_province}` : ''}${defaultLocation.location_country ? `, ${defaultLocation.location_country}` : ''}`}
        </div>
      :
        <div className="admin-object-info-entry">
          <span className="inline-text-link">
            <Link to='/admin/organizations/organization-add/organization-location' content='Add Org Location?' />
          </span>
        </div>
      }
      <div className="form-row">
        <Button icon="save"
                label={props.objExists(props.organization, 'organization_id') ? 'Update' : 'Create'}
                disabled={saveActive ? false : true}
                btnAction={orgAdd}
                btnClass="sml" />
      </div>
    </div>
  )
}
