import React from 'react';

import BadgeRename from 'components/molecules/BadgeRename';


export default (props) => {
  const date = new Date();
  return (
    <footer className={props.class !== null ? props.class : ''} >
      <div className="footer-legal">
        {`©${date.getFullYear()} AE Graphics Group`}
      </div>
      <div className="footer-content">

        <BadgeRename>
          <label>Powered by</label>
          <img src="/assets/img/ae-core-logo.svg" alt="Powered By AE Core" className="badge-logo" />
        </BadgeRename>
      </div>

    </footer>
  )
}
