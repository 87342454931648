import React from 'react';
import ReactDOM from 'react-dom';

import 'normalize.css/normalize.css';
import 'styles/styles.scss';

import Root from 'Root';
import App from 'App';

import * as serviceWorker from './serviceWorker';


ReactDOM.render(
  <Root>
    <App />
  </Root>,
  document.getElementById('root'));

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
