import React, {useEffect, useState} from 'react';

import IconToken from 'components/icons/IconToken';
import CartAccount from 'components/molecules/CartAccount';


const CartUser = (props) => {
	const [active, setActive] = useState(null);
	
	
	return (
		<div className={`progressive-section${active ? ' active' : ''}`}>
		  <h3>Acount{props.stageComplete === true && <IconToken icon="check" className="success" />}</h3>
		  
		  {!props.objExists(props.user, 'user_id') &&
			<CartAccount {...props} />
		  }
		  
		</div>
	)
}

export default CartUser;