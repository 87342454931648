import React, {useEffect, useState} from 'react';

import TruncatedText from 'components/atoms/TruncatedText';
import PropertyPair from 'components/molecules/PropertyPair';

export default (props) => {
  const [propertySet, setProperties] = useState(null);
  // const [properties, initProperties] = useState(props.properties);
  const [propertyLanguage, setLanguage] = useState(null)

  function buildProperties(properties) {
    if(properties) {
      if(Array.isArray(properties) && properties.length > 0) {
        return buildPropertiesObject(properties);
      }else if(Object.entries(properties).length > 0) {
        let propertiesCollection = []
        Object.keys(properties).map((key, index) => {
          propertiesCollection.push(<div className="property-set-sub">
                                      <h4>{props.getLanguageName(key)} Specs</h4>
                                      {buildPropertiesObject(properties[key], key)}
                                    </div>);
        })
        return propertiesCollection;
      }
    }
    return false;
  }

  useEffect(() => {

  }, [props.properties]);

  function buildPropertiesObject(properties, language_code = null) {
    let propSet = [];
    let propSetDimensions = {};

    properties.map((property, index) => {
      if(property.property_name && property.property_name !== '') {
        switch(property.property_name.toLowerCase()) {
          case 'width':
          case 'height':
          case 'depth':
            propSetDimensions[property.property_name.toLowerCase()] = {value: property.property_value,
                                                                        name: property.property_name,
                                                                        index: index,
                                                                        property_id: property.property_id,
                                                                        location: props.location && props.location.replace('-LANG-', language_code)};
            break;
          default:
            propSet.push(setProp(property.property_name.toLowerCase(),property.property_value, index, language_code, property.property_id ? property.property_id : null));
            break;
        }
      }
    })
    if(Object.keys(propSetDimensions).length > 0) {
      propSet.unshift(setDimensions(propSetDimensions, 'Dimensions'));
    }
    return propSet;
  }

  function setDimensions(dim, groupName) {
    let dimSet = '';
    if(dim.width) {
      dimSet += dim.width.value+'" W';
    }
    if(dim.width && (dim.height || dim.depth)) {
      dimSet += ' x ';
    }

    if(dim.height) {
      dimSet += dim.height.value+'" H';
    }

    if(((dim.width && dim.height) || dim.height) && dim.depth) {
      dimSet += ' x ';
    }

    if(dim.depth) {
      dimSet += dim.depth.value+'" D';
    }

    return setProp(dim, dimSet, groupName);
  }

  function setProp(name, value, index = null, language_code = null, property_id = null) {
    return <PropertyPair
              {...props}
              key={`pspp${index}`}
              name={name}
              value={value}
              property_id={property_id}
              objectPath={props.objectPath}
              location={language_code ? props.location.replace('-LANG-', language_code) : props.location}
              index={(index || String(index) === '0') && parseInt(index) >= 0 ? index : null}
              groupName={index && String(index).match(/[a-z]/g) ? index : null}
              className={props.editable ? 'editable' : null}
              ctlActions={props.ctlActions}
              updateObj={props.updateObj === false ? false : true} />
  }

  return (
    <TruncatedText altClass={props.disableTruncate ? 'no-truncate' : '' }>
      <div className={`property-set${props.properties &&
                        Object.entries(props.properties).length > 1 ?
                          ' multiple' : ''}`} >
        {props.showTitle &&
          <h4>{props.product && props.product.product_name ? props.product.product_name : 'Product'}</h4>
         }
        {buildProperties(props.properties)}
      </div>
    </TruncatedText>
  )
}
