import React, {useEffect, useState} from 'react';

import FormDynamic from 'components/forms/FormDynamic';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [urc, setUrc] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [formContent, setFormContent] = useState(null);
	const [form, setForm] = useState(null);
	const [key, setKey] = useState(null);
	const [keyCtl, setKeyCtl] = useState(null);
	const [keyValid, setKeyValid] = useState(false);
	const [updateError, setUpdateError] = useState(false);
	
	// HOOKS
	useEffect(() => {
		if(props.userID && userID !== props.userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && token !== props.token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.urc && urc !== props.urc) {
			setUrc(props.urc);
		}
	}, [props.urc]);
	
	useEffect(() => {
		if(props.organizationID && organizationID !== props.organizationID) {
			setOrganizationID(props.organizationID);
		}
	}, [props.organizationID]);
	
	useEffect(() => {
		if((userID && token) || urc) {
			setFormContent([{
				fieldset_name: 'Update Password',
				  fields: [
					{field_type: 'hidden',
					field_name: 'process',
					field_value: 'user_update_password'},
					{field_type: 'hidden',
					field_name: 'user_id',
					field_value: userID ? userID : null},
					{field_type: 'hidden',
						field_name: urc ? 'urc' : 'token',
						field_value: urc ? urc : token},
					{field_type: 'password',
					field_name: 'new_key',
					field_label: 'Password',
					field_placeholder: 'New password',
					fieldOnChange: keyTest,
					field_required: true},
					{field_type: 'password',
					field_name: 'new_key_repeat',
					field_label: 'Repeat Password',
					fieldOnChange: keyTest,
					field_placeholder: 'Repeat new password',
					field_required: true},
				  ]
				}
			  ]);
		}
	}, [userID, token, urc]);
	
	useEffect(() => {
		if(formContent) {
			setForm(
				<FormDynamic 
					content={formContent}
					name="new-password"
					formActions={{formSubmit: updatePW}}
					formCompleteLabel="Update"
					closable={false}
					unmountOnClick={false}
					cancelable={props.cancelable ? true : false}
					includeControls={true}
					multiStep={false}
					returnObject={true}
					disabled={keyValid ? false : true}
					alertMsg={updateError}
					formClass=""
					valActions={{isEmail: props.isEmail}}/>
			);
		}
	}, [formContent, keyValid, updateError]);
	
	useEffect(() => {
		if(key && keyCtl) {
		  if(key.length > 0 && keyCtl.length > 0 && key === keyCtl && !keyValid) {
			setKeyValid(true);
			setUpdateError(null);
		  }else if(key.length > 0 && keyCtl.length > 0 && key !== keyCtl) {
			setUpdateError("Passwords provided do not match!");
		  }
		}else if(keyValid) {
		  setKeyValid(false);
		}
	  }, [key, keyCtl]);
	
	// FUNCTIONS
	function updatePW(params) {
		console.log("Update Password Params", params);
		props.userLoadState(params, 'users', props.resultLocation, props.resultCallBack);
		if(props.actionSubmit) {
			props.actionSubmit(true);
		}
	 }
	
    function keyTest(params) {
		switch(params.name) {
	    	case 'new_key':
				setKey(params.value);
				break;
			case 'new_key_repeat':
				setKeyCtl(params.value);
				break;
			default:
				break;
		}
	}
	
	return form;
}