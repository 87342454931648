import React, {useEffect, useState, useRef} from 'react';
import ReactDOM from 'react-dom';

import IconToken from 'components/icons/IconToken';
import ActionMenuItem from 'components/molecules/ActionMenuItem';


export default (props) => {
  const [actionsState, setActionsState] = useState(false);
  const [target, setTarget] = useState(null);
  const [menuNode, setMenuNode] = useState(null);
  const [windowListener, setWindowListener] = useState(null);

  const ctlRef = useRef();

  function toggleActions() {
    actionsState ?
    setActionsState(false)
    : setActionsState(true);
  }

  function unmountActions(actions) {
    actions.classList.remove('active');
    setTimeout(()=>{actions.remove(); toggleActions()}, 500);
  }

  function mountActions(target) {
    if(!menuNode && actionsState) {
      // Getting container:
      let container = ctlRef.current;
      while(!!container.classList.contains('cart-item') && container.tagName.toLowerCase() != 'body') {
        container = container.parentNode;
      }

      let actions = document.createElement('div');
      actions.classList.add('actions-menu');
      actions.style.top = (parseInt(ctlRef.current.offsetTop))+"px";
      actions.style.right = 12+"px";

      let actionsList = [];
      props.actions && props.actions.map((action, index) => {
        actionsList.push(<ActionMenuItem key={`alItem${index}`} 
                            action={action.params ? ()=>{action.action(action.params)} : action.action}
                            icon={action.icon}
                            label={action.label}
                            subject={action.subject} 
                            actionAfter={()=>{unmountActions(actions)}} />);
        return null;
      })


      ReactDOM.render(actionsList, actions);
      container.appendChild(actions);

      setMenuNode(actions);
      // actions.addEventListener('click', ()=>{actions.remove()});
      setTimeout(()=>{actions.classList.add('active')}, 50);
    }
  }

  // function handleClick(target, actions) {
  //   while(!target.classList.contains('actions-menu') && target.tagName.toLowerCase() !== 'body') {
  //     target = target.parentNode;
  //   }
  //   if(target !== actions) {
  //     actions.remove();
  //     window.removeEventListener('click', _clickListen);
  //   }
  // }

  function clickListener(e) {
    // console.log("click", e.target);
    let target = e.target;
    while(!target.classList.contains('actions') && target.tagName.toLowerCase() !== 'body') {
      target = target.parentNode;
    }
    if(target !== ctlRef.current) {
      const actionsMenu = ctlRef.current.querySelectorAll('.actions-menu');
      actionsMenu.forEach(node => {
        unmountActions(node);
      })
    }
  }

  function keyListener(e) {
    console.log("click", e.key);
  }

  useEffect(() => {
    if(target && actionsState) {
      mountActions(target);

      var _click = (e) => {
        clickListener(e);
      }

      var _key = (e) => {
        keyListener(e);
      }

      document.addEventListener('keydown', _key, true);
      document.getElementById('root').addEventListener('click', _click, true);

      return () => {
        document.removeEventListener('keydown', _key, true);
        document.getElementById('root').removeEventListener('click', _click, true);
      }


    }else if(menuNode){
      menuNode.remove();
      setMenuNode(null);
    }
  }, [actionsState, target]);

  useEffect(() => {
    if(actionsState) {
      setTarget(ctlRef.current.getBoundingClientRect());
    }

  }, [actionsState, ctlRef])


  return (
    <div className="actions" ref={ctlRef} >
    <IconToken icon="kebob" action={toggleActions}   />
    </div>
  )
}
