import React, {useEffect, useState, Fragment} from 'react';

import Control from 'components/atoms/Control';
import IconSVG from 'components/icons/IconSVG';
import CardValueTag from 'components/molecules/CardValueTag';
import FormGroupAssign from 'components/forms/common_forms/FormGroupAssign';
import ModalConfirmation from 'components/molecules/ModalConfirmation';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [approverGroups, setApproverGroups] = useState(null);
  const [approverUsers, setApproverUsers] = useState(null);
  const [orgGroups, setOrgGroups] = useState(null);
  const [staffGroups, setStaffGroups] = useState(null);

  // HOOKS
  useEffect(() => {
    if(props.userID && props.userID !== userID) {
      setUserID(props.userID);
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token && props.token !== token) {
      setToken(props.token);
    }
  }, [props.token]);
  
  useEffect(() => {
    if(props.organizationID && props.organizationID !== organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    if(props.languageCode && props.languageCode !== languageCode) {
      setLanguageCode(props.languageCode);
    }else if(!props.langaugeCode && !languageCode) {
      setLanguageCode('en');
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    if(props.userAccess && props.userAccess !== userAccess) {
      setUserAccess(props.userAccess);
    } 
  }, [props.userAccess]);
  
  useEffect(() => {
    if(props.approverGroups && props.approverGroups !== approverGroups) {
      setApproverGroups(props.approverGroups);
    } 
  }, [props.approverGroups]);
  
  useEffect(() => {
    if(props.approverUsers && props.approverUsers !== approverUsers) {
      setApproverUsers(props.approverUsers);
    } 
  }, [props.approverUsers]);
  
  useEffect(() => {
    if(props.orgGroups && props.orgGroups !== orgGroups){
      setOrgGroups(props.orgGroups);
    }
  }, [props.orgGroups]);
  
  useEffect(() => {
    if(props.staffGroups && props.staffGroups !== staffGroups){
      setStaffGroups(props.staffGroups);
    }
  }, [props.staffGroups]);

  // FUNCTIONS
  function loadAddGroup(type) {
    props.setModal(
      <FormGroupAssign {...props}
            userID={userID}
            token={token}
            languageCode={languageCode}
            name="Client Approver Group"
            groupType="clientApproverGroupToOrg"
            groupLink={organizationID}
            groupFrom={true}
            groups={orgGroups}
            staffGroups={staffGroups}
            actionSubmit={assignGroup} / >
    )
  }
  
  function assignGroup(params) {
    props.setModal(null);
    props.adminLoadState(params, 'links', 'reload.persona');
  }
  
  function loadRemoveGroup(link_id, params) {
    props.setModal(
      <ModalConfirmation {...props}
        message={`Remove ${params.assignedType.toLowerCase() === 'group' ? 'the ' : ''}<b>${params.name}</b>${params.assignedType.toLowerCase() === 'group' ? ' Group' : ''} from ${params.assignedName}?`}
        actionAccept={()=>{removeLink(link_id)}} />
    )
  }
  
  function removeLink(link_id) {
    props.setModal(null);
    const linkParams = [
      {name: 'process', value: 'update_link'},
      {name: 'user_id', value: userID},
      {name: 'link_id', value: link_id},
      {name: 'link_removed', value: 1}
    ];
    // console.log('REMOVE LINK PARAMS', linkParams);
    props.adminLoadState(linkParams, 'links', 'reload.persona');
  }
  
  return (
    <div className="list-set">
      <h4><IconSVG icon="group" /> Client Assignments</h4>
      <div className="info-block">
        Client Approver Groups and Users will be notified when orders require review and approval on behalf of the client to proceed.
      </div>
      
      {approverGroups &&
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          width: 'auto',
          minWidth: '15em',
          maxWidth: '25em',
          paddingBottom: '.5em'
        }}>
          <h4>Client Order Approver Groups</h4>
          {approverGroups.map((group, index) => {
            return (
              <CardValueTag {...props}
                            key={`cAppGrpTag${index}`}
                            content={group.group_name}
                            contentID={group.link_id}
                            editable={true}
                            actionRemove={loadRemoveGroup}
                            values={{
                              assignedName: 'Client Approvers',
                              assignedType: 'Group',
                              name: group.group_name}} />
            )
          })}
        </div>
      }
      
      {approverUsers &&
        <div>
        <b>Client Order Approver Users</b>
        {approverUsers.map((user, index) => {
          return <div className="margin-bottom-1em margin-top-1em">
                  {`${user.contact_name_first} ${user.contact_name_last}`}
                </div>
        })}
        </div>
      }
      <Control icon="group-add" 
                label="Add Client Approver Group" 
                showLabel={true} 
                className="ctl-btn"
                iconClass="no-btn"
                action={()=>{loadAddGroup('clientApprover')}} />
    </div>
  )
}
