import React, {useEffect, useState, Fragment} from 'react';

// TaskListAdmin is part of the task interface used to track and update subtasks

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [task, setTask] = useState(null);
	const [tasks, setTasks] = useState(null);
	const [taskList, setTaskList] = useState(null);
	const [project, setProject] = useState(null);
	const [order, setOrder] = useState(null);
	const [item, setItem] = useState(null);
	
	// HOOKS
	useEffect(() => {
		setTask(null);
		setTasks(null);
		setProject(null);
		setOrder(null);
		setItem(null);
	}, []);
	
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.languageCode && props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.task && props.task !== task) {
			setTask(props.task);
		}
	}, [props.task]);
	
	useEffect(() => {
		if(task && 
			Array.isArray(task.subtasks) &&
			task.subtasks.length > 0) {
			setTasks(task.subtasks);
		}
	}, [task]);
	
	useEffect(() => {
		if(Array.isArray(tasks)) {
			setTaskList(buildTaskList(tasks));
		}
	}, [tasks]);
	
	useEffect(() => {
		if(props.project && props.project !== project) {
			setProject(props.project);
		}
	}, [props.project]);
	
	useEffect(() => {
		if(props.order && props.order !== order) {
			setOrder(props.order);
		}
	}, [props.order]);
	
	useEffect(() => {
		if(props.item && props.item !== item) {
			setItem(props.item);
		}
	}, [props.item]);
	
	// FUNCTIONS 
	function buildTaskList(tasks, parent = "") {
		
		if(Array.isArray(tasks)) {
			let tList = [];
			tasks.map(lTask => {
				let stList = [];
				if(lTask.subtasks && 
					Array.isArray(lTask.subtasks) && 
					lTask.subtasks.length > 0) {
						stList.push(...buildTaskList(lTask.subtasks, `${parent}${lTask[languageCode].task_name} > `));
				}
				tList.push(
					<div>
						{`${parent}${lTask[languageCode].task_name}`}
						{stList && stList}
						
					</div>
				);
				
			})
			return tList;
		}else{
			return null;
		}
	}
	
	return (
		<div className="">
			{Array.isArray(tasks) &&
				<div>{`${tasks.length} Tasks`}</div>
			}
			{taskList}
		</div>
	)
}