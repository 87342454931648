import React, {useEffect, useState, Fragment} from 'react';

import UserListItem from 'components/profiles/users/UserListItem';
import Loader from 'components/atoms/Loader';
import Control from 'components/atoms/Control';
import UserListCtl from 'components/profiles/users/UserListCtl';
import Select from 'components/forms/elements/Select';

export default (props) => {
  const [users, setUsers] = useState(null);
  const [organizations, setOrganizations] = useState(null);
  const [roles, setRoles] = useState(null);
  const [usersFiltered, setUsersFiltered] = useState(null);
  const [filter, setFilter] = useState(null);
  const [clearFilter, setClearFilter] = useState(null);
  const [activeFilters, setActiveFilters] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  const [searchOption, setSearchOption] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [sort, setSort] = useState(null);
  const [order, setOrder] = useState(null);
  const [userID, setUserID] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [token, setToken] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);

  const searchOptions = [{name: 'Name', value: 'name', default: true},
                        {name: 'Email', value: 'email', default: false}];

  useEffect(() => {
    props.userID &&
    setUserID(props.userID);
  }, [props.userID]);

  useEffect(() => {
    props.token &&
    setToken(props.token);
  }, [props.token])

  useEffect(() => {
    props.organizationID &&
    setOrganizationID(props.organizationID);
  }, [props.organizationID]);

  useEffect(() => {
    props.organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);

  useEffect(() => {
    props.languageCode &&
    setLanguageCode(props.languageCode);
  }, [props.languageCode]);

  // BUILD MASTER USER OBJECT FROM PROPS
  useEffect(() => {
    if(props.users && organizationID > 1) {
      setUsers(props.users.filter(user => user.organizations && user.organizations.find(org => org.organization_id === props.organization_id)));
    }else{
      setUsers(props.users);
    }
  }, [props.users, organizationID]);
  
  useEffect(() => {
    if(users && Array.isArray(users) && users.length > 0) {
      let usersOrdered = users.sort((a, b) => {return a.organization_id > b.organization_id ? 1 : -1} );
      let userOrgs = [];
      let userOrg = null;
      let userRoles = [];
      usersOrdered.map(user => {
        if(!userOrgs.find(opt => opt.option_value === user.organization_id)) {
          userOrgs.push({option_name: user.organization, option_value: user.organization_id});
        }
        if(!userRoles.find(opt => opt.option_value === user.user_role)) {
          userRoles.push({option_name: user.user_role, option_value: user.user_role});
        }
      });
      
      userOrgs.length > 1 &&
      setOrganizations(userOrgs);
      
      userRoles.length > 1 &&
      setRoles(userRoles);
    }
  }, [users]);

  // CREATE INITIAL FILTERED USERS LIST FROM USERS MASTER
  useEffect(() => {
    users && !usersFiltered &&
    setUsersFiltered(users);
  }, [users]);

  // ON SHOW ALL: CLEAR FILTER FOR SPECIFIED FILTER SET
  useEffect(() => {
    if(clearFilter) {
      let newFilters = activeFilters;
      if(newFilters[clearFilter]) {
        delete newFilters[clearFilter];
        setActiveFilters(newFilters);
      }
      setClearFilter(null);
    }
  }, [clearFilter]);

  // AS FILTERS GET SET OR UNSET UPDATE ACTIVE FILTERS
  useEffect(() => {
    if(filter) {
      let newFilters = activeFilters ? activeFilters : {};
      if(newFilters[filter.filterGroup] && Array.isArray(newFilters[filter.filterGroup])) {


        if(filter.filterValue === false && newFilters[filter.filterGroup].includes(filter.filterName)) {
          newFilters[filter.filterGroup].splice(newFilters[filter.filterGroup].indexOf(filter.filterName), 1);
        }else if(filter.filterValue === true) {
          if(!newFilters[filter.filterGroup]) {
            newFilters[filter.filterGroup] = [filter.filterName];
          }else if(newFilters[filter.filterGroup] && !newFilters[filter.filterGroup].includes(filter.filterName)) {
            newFilters[filter.filterGroup].push(filter.filterName);
          }
        }
      }else if (filter.filterValue === true){
        newFilters[filter.filterGroup] = [filter.filterName];
      }
      // newFilters !== activeFilters &&
      setActiveFilters(newFilters);
      setFilter(null);
    }

  }, [filter, clearFilter]);

  // FILTER AND SEARCH LIST
  useEffect(() => {
    let filteredList = users;

    if(activeFilters && Object.entries(activeFilters).length > 0 ) {
      // let filteredList = [];
      let filterApplied = false;
      Object.entries(activeFilters).map(filter => {
        if(filter[1] && Array.isArray(filter[1]) && filter[1].length > 0) {
            filterApplied = true;
            filteredList = filteredList.filter(item => filter[1].includes(item[filter[0]]));
        }
      })
    }

    let searchResults = filteredList;
    if(searchOption && searchValue) {
      switch(searchOption) {
        case 'email':
          searchResults = searchResults.filter(user => user.contact_email.toLowerCase().match(searchValue.toLowerCase()));
          break;
        case 'name':
          const nameNodes = searchValue.toLowerCase().split(' ');
          nameNodes.map(node => {
            searchResults = searchResults.filter(user => user.contact_name_first.toLowerCase().includes(node.toLowerCase()) ||
                                                user.contact_name_last.toLowerCase().includes(node.toLowerCase()));
          });
          break;
        default:
          break;
      }
    }
    setUsersFiltered(searchResults);
  }, [activeFilters, filter, clearFilter, searchOption, searchValue]);

  // FUNCTIONS
  function searchUsers(value) {
    setSearchValue(value);
  }

  function showAll(name, value, filterGroup) {
    setClearFilter(filterGroup);
  }

  function selectFilter(name, value, filterGroup) {
    setFilter({filterName: name, filterValue: value, filterGroup: filterGroup});
  }

  return (
    <div className="info-list users">
      <h3>{props.title ? props.capitalizeString(props.title, true) : `${props.organizationName ? `${props.organizationName} ` : ''}Users`}</h3>
      <div className="info-list-header users" >
        <UserListCtl listName={'Users'}
                      actionOptionSelect={setSearchOption}
                      actionSearch={searchUsers}
                      searchOptions={searchOptions}
                      filterSets={[{filterLabel: 'Organization(s)', filterName: 'organization_id', filterOptions: organizations},
                                    {filterLabel: 'Role(s)', filterName: 'user_role', filterOptions: roles}]}
                      activeFilters={activeFilters}
                      actionShowAll={showAll}
                      actionSelectFilter={selectFilter}
                      liveSearch={true} />
      </div>
      { usersFiltered && Array.isArray(usersFiltered) ?
        <div className={props.scroll ? `scroll` : ''}>
        {usersFiltered.map((user, index) => {
          return <UserListItem {...props}
                                key={`usrLstItm${index}`}
                                user={user}
                                userID={userID}
                                token={token}
                                organizationID={organizationID}
                                organizationName={organizationName}
                                languageCode={languageCode}
                                showOrg={![1,2].includes(props.organizationID) ? false : true}
                                showAll={props.showAll} />})}
        </div>
        :
        <Loader />
      }
    </div>
  )
}
