import React, {useEffect, useState} from 'react';

import CartPrice from 'components/cart/CartPrice';
import CoreRule from 'components/atoms/CoreRule';

import * as utils from 'methods/site';

export default (props) => {
  const [charges, setCharges] = useState(null);
  const [taxes, setTaxes] = useState(null);
  const [cartItems, setCartItems] = useState(null);
  const [cartPricing, setCartPricing] = useState(null);
  const [pricingDisplay, setPricingDisplay] = useState(null);

  const labelUnavailable = "To Be Determined";
  
  useEffect(() => {
    if(props.site && props.site.persona.charges !== charges) {
      setCharges(props.site.persona.charges);
    }
  }, [props.site]);
  
  useEffect(() => {
    if(props.site && props.site.persona.taxes !== taxes) {
      setTaxes(props.site.persona.taxes);
    }
  }, [props.site]);
  
  useEffect(() => {
    if(props.items && props.items !== cartItems) {
      setCartItems(props.items);
    }
  }, [props.items]);
  
  useEffect(() => {
    if(Array.isArray(cartItems)) {
      let cPricing = [];
      let cSubtotalItems = 0;
      let cCharges = 0;
      let cChargesArr = [];
      let cAddCharges = 0;
      let cTaxes = 0;
      let cTaxRates = [];
      let cSubtotal = 0;
      let cTotal = 0;
      cartItems.map((item, index) => {
        cSubtotalItems += parseFloat(item.product_price);
      });
      if(Array.isArray(props.objGetValue(charges, `order`)) && charges.order.length > 0) {
        charges.order.map((charge, index) => {
          cCharges += parseFloat(charge.charge_value);
          cChargesArr.push(charge);
        });
      }
      cSubtotal += cSubtotalItems + cCharges;
      if (props.objExists(props, 'user.locations')) {
        let userDefaultLocation =  props.user.locations.find((location) => { return location.location_user_default == true});
        let taxRates = taxes[userDefaultLocation.location_country ? userDefaultLocation.location_country : 'CA'][userDefaultLocation.location_province ? userDefaultLocation.location_province : 'ON'];
        if(Array.isArray(taxRates) && taxRates.length > 0) {
          taxRates.map(tax => {
            let cTaxRate = parseFloat((cSubtotal * tax.tax_rate).toFixed(2));
            cTaxes += cTaxRate;
            let cTaxRateTotal = 0;
            if(props.objExists(cTaxRates, `${tax.tax_name}.tax_rate_total`)) {
              cTaxRateTotal = cTaxRates[`${tax.tax_name}`].tax_rate_total + cTaxRate;
            } else {
              cTaxRateTotal = cTaxRate;
            }
            cTaxRates[`${tax.tax_name}`] = {tax_rate: tax.tax_rate, tax_rate_total: parseFloat(cTaxRateTotal.toFixed(2))}
          });
        }
      }
      if(Array.isArray(props.objGetValue(charges, `project`)) && charges.project.length > 0) {
        charges.project.map((charge, index) => {
          cAddCharges += parseFloat(charge.charge_value);
          cChargesArr.push(charge);
        });
      }
      cSubtotal += cAddCharges;
      if (taxes) {
        let addTaxRate = taxes['CA']['ON'];
        if(Array.isArray(addTaxRate) && addTaxRate.length > 0) {
          addTaxRate.map(tax => {
            let cAddTaxes = parseFloat((cAddCharges * tax.tax_rate).toFixed(2));
            cTaxes += cAddTaxes;
            let cTaxRateTotal = 0;
            if(props.objExists(cTaxRates, `${tax.tax_name}.tax_rate_total`)) {
              cTaxRateTotal = cTaxRates[`${tax.tax_name}`].tax_rate_total + cAddTaxes;
            } else {
              cTaxRateTotal = cAddTaxes;
            }
            cTaxRates[`${tax.tax_name}`] = {tax_rate: tax.tax_rate, tax_rate_total: parseFloat(cTaxRateTotal.toFixed(2))}
          });
        }
      }
      cTotal = parseFloat((cSubtotal + cTaxes).toFixed(2));
      cPricing["cart_subtotal_items"] = parseFloat(cSubtotalItems);
      cPricing["cart_charges"] = parseFloat(cCharges);
      cPricing["cart_charges_add"] = parseFloat(cAddCharges);
      cPricing["cart_subtotal"] = parseFloat(cSubtotal);
      cPricing["cart_taxes"] = parseFloat(cTaxes.toFixed(2));
      cPricing["cart_tax_rates"] = cTaxRates;
      cPricing["cart_total"] = parseFloat(cTotal.toFixed(2));
      cPricing["charges"] = cChargesArr;
      if(cPricing && cPricing !== cartPricing) {
        setCartPricing(cPricing);
      }else{
        setCartPricing(null);
      }
    }
  }, [cartItems]);
  
  useEffect(() => {
    if (cartPricing && cartPricing.cart_total > 0) {
      let pricingDisplay = [];
      pricingDisplay["displayItemsSubtotal"] = cartPricing.cart_subtotal_items;
      pricingDisplay["displayCharges"] = cartPricing.cart_charges + cartPricing.cart_charges_add;
      pricingDisplay["displayShipping"] = 0;
      pricingDisplay["displaySubtotal"] = cartPricing.cart_subtotal;
      pricingDisplay["displayTaxes"] = cartPricing.cart_taxes;
      pricingDisplay["displayTotal"] = cartPricing.cart_total;
      setPricingDisplay(pricingDisplay);
    }
  }, [cartPricing]);

  return (
    pricingDisplay && 
      <div className="cart-box">
        <div className="cart-totals-set">
          <CartPrice label="Cart Subtotal" price={pricingDisplay ? utils.priceDisplay(pricingDisplay.displayItemsSubtotal, 'en') : labelUnavailable} />
        </div>
        <div className="cart-totals-legal margin-top-1em">
          <p>* Cart item(s) total only. Taxes and any additional charges will be calculated at checkout or when order is processed.</p>
        </div>
      </div>
  )
}