import React from 'react';
import PagePublic from 'components/templates/PagePublic';

import UserSettings from 'components/account/UserSettings';

export default () => {
  return (
    <PagePublic>
      <UserSettings />
    </PagePublic>
  )
}
