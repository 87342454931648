import React, {useEffect, useState, Fragment} from 'react';

import Button from 'components/forms/elements/Button';
import Input from 'components/forms/elements/Input';
import UserSelector from 'components/profiles/users/UserSelector';
import IconSVG from 'components/icons/IconSVG';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [coreOrg, setCoreOrg] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [canEdit, setCanEdit] = useState(null);
	const [isSuper, setIsSuper] = useState(null);
	const [userAccess, setUserAccess] = useState(null);
	const [groupMembers, setGroupMembers] = useState(null);
	const [users, setUsers] = useState(null);
	const [staff, setStaff] = useState(null);
	const [userList,setUserList] = useState(null);
	const [curSet, setCurSet] = useState(null);
	
	// HOOKS
	useEffect(() => {
		props.userID && 
		props.userID !== userID &&
		setUserID(props.userID);
	}, [props.userID]);
	
	useEffect(() => {
		props.token && 
		props.token !== token &&
		setToken(props.token);
	}, [props.token])
	
	useEffect(() => {
		props.coreOrg && 
		props.coreOrg !== coreOrg &&
		setCoreOrg(props.coreOrg);
		
		if(props.coreOrg === 1) {
			setCurSet('staff');
		}
	}, [props.coreOrg]);
	
	useEffect(() => {
		props.languageCode && 
		props.languageCode !== languageCode &&
		setLanguageCode(props.languageCode);
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.userAccess && props.userAccess !== userAccess) {
			setUserAccess(props.userAccess);
		}
	}, [props.userAccess]);
	
	useEffect(() => {
		props.canEdit &&
		props.canEdit !== canEdit &&
		setCanEdit(props.canEdit);
	}, [props.canEdit]);
	  
	useEffect(() => {
		props.isSuper &&
		props.isSuper !== isSuper &&
		setIsSuper(props.isSuper);
	}, [props.isSuper]);
	
	useEffect(() => {
		if(Array.isArray(props.objGetValue(props.group, 'users')) &&
			props.group.users.length > 0 && 
			props.group.users !== groupMembers) {
			setGroupMembers(props.group.users);
		}else if((!Array.isArray(props.objGetValue(props.group, 'users')) ||
					props.group.users.length <= 0) && groupMembers) {
			setGroupMembers(null);
		}
	}, [props.group]);
	
	useEffect(() => {
		if(Array.isArray(props.staff) && 
			props.staff.length > 0) {
			const availableStaff = [];
			
			if(Array.isArray(groupMembers) && 
				groupMembers.length > 0) {
				props.staff.map(user => {
					if(!groupMembers.find(member => member && member.user_id === user.user_id)) {
						availableStaff.push(user);
					}
				});
			}else{
				availableStaff.push(...props.staff);
			}
			
			if(availableStaff.length > 0 && availableStaff !== staff) {
				setStaff(availableStaff);
			}else if(availableStaff.length <= 0 && staff) {
				setStaff(null);
			}
		}
	}, [props.staff, groupMembers]);
	
	useEffect(() => {
		if(coreOrg && coreOrg > 1 &&
			Array.isArray(props.users) && 
			props.users.length > 0) {
				
			const availableUsers = [];
			
			if(Array.isArray(groupMembers) && 
				groupMembers.length > 0) {
				props.users.map(user => {
					if(!groupMembers.find(member => member && member.user_id === user.user_id) &&
						user.organization_id === coreOrg) {
						availableUsers.push(user);
					}
				});
			}else{
				availableUsers.push(...props.users.filter(user => user.organization_id === coreOrg));
			}
			
			if(Array.isArray(staff) && 
				staff.length > 0 && 
				Array.isArray(availableUsers &&
				availableUsers.length > 0)) {
				const nonStaffUsers = [];
				availableUsers.map(user => {
					if(!staff.find(member => member.user_id === user.user_id)) {
						nonStaffUsers.push(user);
					}
				});
				if(nonStaffUsers.length > 0) {
					availableUsers = nonStaffUsers;
				}
			}
			
			if(availableUsers.length > 0 && availableUsers !== users) {
				setUsers(availableUsers);
			}
		}
	}, [props.users, coreOrg, staff, groupMembers]);
	
	useEffect(() => {
		if(staff && users) {
			switch(curSet) {
				case 'staff':
					staff &&
					staff !== userList &&
					setUserList(staff);
					break;
				case 'users':
				default:
					users && 
					users !== userList &&
					setUserList(users);
					break;	
			}
		}else if(!users && staff &&
				userList !== staff) {
			setUserList(staff);
		}else if(!staff && coreOrg !== 1 &&
				users &&
				userList !== users) {
			setUserList(users);
		}else if(!staff && !users && userList){
			setUserList(null);
		}
	}, [curSet, users, staff, coreOrg]);
	
	// FUNCTIONS
	function handleComplete() {
		if(props.actionComplete) {
			props.actionComplete();
		}
	}
	
	function removeUser(coreUser) {
		setGroupMembers(groupMembers.filter(user => user.user_id !== coreUser));
		
		// unassignTask($user_id = 0, $token = null, $task_id = 0, $core_user = 0)
		const unassignParams = [
			{name: 'process', value: 'update_links'},
			{name: 'user_id', value: userID},
			{name: 'token', value: token},
			{name: 'link_from', value: coreUser},
			{name: 'link_to', value: props.group.group_id},
			{name: 'link_type', value: 'userToGroup'},
			{name: 'link_field', value: 'link_removed'},
			{name: 'link_value', value: true}
		];
		props[`${props.stateLocation ? props.stateLocation : 'admin'}LoadState`](unassignParams, 'links', `reload.${props.source ? props.source : 'tasks'}`);
	}
	
	function addUser(coreUser) {
		const userAssign = curSet === 'staff' ? staff.find(user => user.user_id === coreUser) : users.find(user => user.user_id === coreUser);
		
		if(Array.isArray(groupMembers)) {
			setGroupMembers([...groupMembers, userAssign]);	
		}else{
			setGroupMembers([userAssign]);
		}
		
		const assignParams = [
			{name: 'process', value: 'add_link'},
			{name: 'user_id', value: userID},
			{name: 'token', value: token},
			{name: 'link_from', value: coreUser},
			{name: 'link_to', value: props.group.group_id},
			{name: 'link_type', value: 'userToGroup'},
			{name: 'link_published', value: true},
			{name: 'return_bool', value: true}
		];
		props[`${props.stateLocation ? props.stateLocation : 'admin'}LoadState`](assignParams, 'links', `reload.${props.source ? props.source : 'groups'}`);
	}
	
	function handleFilter(filter) {
		const filterBase = curSet === 'staff' ? staff : users;
		
		if(filterBase) {
			let userBase = availableUsers(filterBase, groupMembers);
				 
			const filterNodes = filter.value && filter.value !== '' ? 
									filter.value.toLowerCase().split(' ') :
									[] ;
				
			if(userBase && filterNodes.length > 0) {
				setUserList(userBase.filter((user) => {
					let result = false;
					filterNodes.map(node => {
						if(node !== '' &&
							(user.contact_name_first.toLowerCase().indexOf(node) === 0 ||
							user.contact_name_last.toLowerCase().indexOf(node) === 0)) {
							result = true;
						}else if(node !== '') {
							result = false;
						}
					})
					return result;
				}));
			}else{
				setUserList(userBase);
			}
		}
	}
	
	function availableUsers(usersAll, usersAssigned) {
		let filteredUsers;
		if(Array.isArray(usersAll) && usersAssigned && 
			Array.isArray(usersAssigned) &&
			usersAssigned.length > 0) {
			const assignedIDs = usersAssigned.map(user => {
				if(user && user.user_id) {
					return user.user_id;
				}
			});
			if(assignedIDs && Array.isArray(assignedIDs)) {
				filteredUsers = usersAll.filter(user => !assignedIDs.includes(user.user_id));		
			}
		}
		if(filteredUsers) {
			return filteredUsers;
		}else{
			return usersAll;
		}
	}
	
	return (
		<div style={{minWidth: '270px'}}>
			<h3>{`${props.capitalizeString(props.group.group_name ? `${props.group.group_name} : ` : '', true)}${props.group.group_name ? props.captitalizeString(props.group.group_name, true) : props.objExists(props.group, `${languageCode}.group_name`) ? props.capitalizeString(props.group[languageCode].group_name, true) : `Group ${props.group.group_id}`}`}</h3>
			{groupMembers &&
				<div className="list-scroll-container">
					<legend>Current Members</legend>
					<div className="list-scroll">
						{groupMembers.map((user, index) => {
							return <UserSelector {...props}
											key={`taTU${index}`}
											taskID={props.group.group_id}
											user={user}
											actionRemove={removeUser} />
						})}
					</div>
				</div>
			}
			<div>
			{users && staff && props.coreOrg !== 1 &&
				<div className="form-row">
					<span style={{alignSelf: 'baseline', marginRight: '1em'}} >Show </span>
					<div style={{display: 'inline-flex', width: '100%' }}>
					<Button icon="user" 
							label="Staff" 
							btnClass={`tiny${curSet === 'staff' ? ' active-element' : ''}`}
							btnAction={curSet !== 'staff' ? ()=>{setCurSet('staff')} : null} />
					<Button icon="user" 
							label="Users" 
							btnClass={`tiny info-bg${curSet === 'users' || !curSet ? ' active-element' : ''}`}
							btnAction={curSet !== 'users' ? ()=>{setCurSet('users')} : null} />
					</div>
				</div>
			}
			{userList &&
				<Fragment>
					<div className="form-row">
						<Input {...props}
								icon="search"
								name="user_filter"
								onChange={handleFilter}
								label="Search for user" />
					</div>
					<div className="list-scroll-container">
						<legend><IconSVG icon="user-add" />{curSet ? curSet : 'Users'}</legend>
						<div className="list-scroll">
						{userList && 
							Array.isArray(userList) && 
							userList.length > 0 &&
							userList.map((user, index) => {
								return (
									<UserSelector {...props}
										key={`taTU${index}`}
										user={user}
										actionAssign={addUser} />
								)
							})
						}
						</div>
					</div>
				</Fragment>
			}
			</div>
			<div className="form-row">
				<Button icon="complete" btnClass="info-bg" label="Done" btnAction={handleComplete} />
			</div>
		</div>
	)
}