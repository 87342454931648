import axios from 'axios';
import * as apis from 'methods/api.js';

export const getCategories = (organization_id, language_code) => dispatch => {
  const formData = 'process=get_categories&organization_id='+organization_id+'&language_code='+language_code;
  axios.post(apis.api_url_store, formData).then(res => {
      dispatch({type: 'GET_CATEGORIES', payload: res.data});
  }).catch( error => {
    console.log(error);
  })
}

export const clearCategories = () => dispatch => {
  dispatch({type: 'GET_CATEGORIES', payload: null});
}

export const clearStore = (object) => dispatch => {
  dispatch({type: 'CLEAR_STORE', payload: object});
}

export const getCategory = (category_id, organization_id, language_code) => dispatch => {
  const formData = `process=get_category&category_id=${category_id}&organization_id=${organization_id}&language_code=${language_code}`;
  axios.post(apis.api_url_store, formData).then(res => {
    dispatch({type: 'GET_CATEGORY', payload: res.data});
  }).catch(error => {
    console.log("DISPATCH ERROR - GET CATEGORY", error);
  })
}

export const getProducts = (category_id = null, organization_id = null, language_code = 'en') => dispatch => {
  const formData = 'process=get_products&category_id='+category_id+'&organization_id='+organization_id+'&language_code='+language_code;
  console.log("getting product", formData);
  axios.post(apis.api_url_store, formData).then(res => {
    console.log("get products res data:", formData, res.data);
    dispatch({type: 'GET_PRODUCTS', payload: res.data});
  }).catch( error => {
    console.log(error);
  })
}

export const clearProducts = () => dispatch => {
  dispatch({type: 'GET_PRODUCTS', payload: null});
}

export const getProduct = (product_id, language_code, organization_id) => dispatch => {
  const formData = 'process=get_product&product_id='+product_id+'&language_code='+language_code+'&organization_id='+organization_id;
  axios.post(apis.api_url_store, formData).then(res => {
    dispatch({type: 'GET_PRODUCT', payload: res.data});
  }).catch( error => {
    console.log(error);
  })
}

export const clearProduct = () => dispatch => {
  dispatch({type: 'GET_PRODUCT', payload: null});
}

export const getFeaturesMain = () => dispatch => {
  const formData = 'process=get_features_main';
  axios.post(apis.api_url_store, formData).then(res => {
    dispatch({type: 'GET_MAIN_FEATURES', payload: res.data});
  }).catch(error => {
    console.log(error);
  })
}

export const getFeaturesSub = () => dispatch => {
  const formData = 'process=get_features_sub';
  axios.post(apis.api_url_store, formData).then(res => {
    dispatch({type: 'GET_SUB_FEATURES', payload: res.data});
  }).catch(error => {
    console.log(error);
  })
}

export const storeSetVal = (name, value, sStore = true) => dispatch => {
  dispatch({type: 'STORE_SET_VALUE', name: name, value: value, sStore: sStore});
}

export const storeDeleteVal = (location) => dispatch => {
  dispatch({type: 'STORE_DELETE_VAL', payload: location});
}

export const storeLoadState = (params, api, state_location, callback = null, cbApi = null) => dispatch => {
  var formData = new FormData();

  Array.isArray(params) &&
  params.map (param => {
     if(param.name && param.value) {
      formData.append(param.name, param.value);
     }
  })

  if(apis.getReqUrl(api)) {
      axios.post(apis.getReqUrl(api), formData).then(res => {
        if(callback && Array.isArray(callback) && callback.length > 0) {
          var callbackData = new FormData();
          callback.map (param => {
            if(param.name && param.value) {
              if(param.value === 'result') {
                callbackData.append(param.name, res.data);
              }else{
                callbackData.append(param.name, param.value);
              }
            }
          })
          axios.post(apis.getReqUrl(cbApi ? cbApi : api), callbackData).then(cbRes => {

            dispatch({type: 'STORE_LOAD_STATE', location: state_location, payload: cbRes.data});
          }).catch(error => {
            console.log("DISPATCH ERROR LOAD CALLBACK", error);
          })
        }else{
          dispatch({type: 'STORE_LOAD_STATE', location: state_location, payload: res.data});
        }
      }).catch(error => {
        console.log("DISPATCH ERROR - LOAD STATE", apis.getReqUrl(api), error);
      })
    }else{
      // console.log("There's no reqUrl", api, reqUrl);
    }
 }

