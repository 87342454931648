import React, {useEffect, useState} from 'react';
import {useParams, useLocation} from 'react-router';

import Loader from 'components/atoms/Loader';
import TaskList from 'components/profiles/tasks/TaskList';
import MessagesWidget from 'components/profiles/messages/MessagesWidget';

import * as utils from 'methods/site';

export default (props) => {
  props = {...props.children, ...utils};
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [tasksLoading, setTasksLoading] = useState(null);
  const [messages, setMessages] = useState(null);
  const [component, setComponent] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  let ivlTasks;

  const pgParams = useParams();
  const location = useLocation();

  // HOOKS
  useEffect(() => {
    return () => {
      props.dashboardDeleteVal('tasks');
      
      if(ivlTasks) {
        clearInterval(ivlTasks);
      }
    }
  }, [])
  
  useEffect(() => {
    if(props.objExists(props.user, 'user_id') && 
        userID !== props.user.user_id) {
      setUserID(props.user.user_id);
    }
    
    if(props.objExists(props.user, 'user_id')) {
      if(localStorage.getItem('token')) {
        setToken(localStorage.getItem('token'));
      }else if(sessionStorage.getItem('token')) {
        setToken(sessionStorage.getItem('token'));
      }
    }
    
    console.log("MESSAGES RECEIVED", Array.isArray(props.objGetValue(props.user, `messages`)) ? props.user.messages.length : 0);
    if(Array.isArray(props.objGetValue(props.user, `messages`)) &&
      props.user.messages.length > 0) {
      setMessages(props.user.messages);  
    }
    
    if(props.objExists(props.user, `user_roles`)) {
      let uAccess = {};
      
      let staffAdmin = {};
      let roleAdmin = {};
      let permissionEdit = false;
      let permissionSuper = false;
      // STAFF ADMIN ACCESS - if user is a Core staff admin
      if(Object.entries(props.objGetValue(props.user, `user_roles`)).length > 0) {
        Object.entries(props.user.user_roles).map(role => {
          if(parseInt(role[0]) === 1 && parseInt(role[1].role_id) === 1) {
            uAccess['super'] = true;
          }else if(parseInt(role[0]) === 1 && parseInt(role[1].role_id === 2)) {
            uAccess['admin'] = true;
          }else if(parseInt(role[0]) > 1 && role[1].role_id <= 2){
            roleAdmin[role[0]] = true;
          }
        })
      }
      
      if(props.objExists(props.user, `user_groups`) &&
                Array.isArray(props.user.user_groups)) {
          props.user.user_groups.map(group => {
            if(group.group_name.toLowerCase() === 'staff admin') {
              staffAdmin[group.group_organization_id] = true;
            }
          });
      }
      
      const adminByOrgs = {...roleAdmin, ...staffAdmin};
      
      if(Object.entries(adminByOrgs).length > 0 
          && uAccess.staffAdmin !== adminByOrgs) {
        uAccess['staffAdmin'] = adminByOrgs;  
      }
      
      if(Object.entries(uAccess).length > 0 &&
          uAccess !== userAccess) {
          setUserAccess(uAccess);
      }
    }
    
  }, [props.user]);
  
  useEffect(() => {
    if(props.objExists(props.site, 'site_language') && 
        props.site.site_language !== languageCode) {
      setLanguageCode(props.site.site_language);
    }else if(!props.objExists(props.site, 'site_language')){
      props.siteSetValue('site_language', 'en');
      setLanguageCode('en');
    }
  }, [props.site]);
  
  
  useEffect(() => {
    if(userID && token) {
      const taskParams = [
        {name: 'process', value: 'get_user_tasks'},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
        {name: 'core_user', value: userID},
        {name: 'task_published', value: true},
        {name: 'task_removed', value: false}
      ];
      
      console.log("TASK PARAMS", taskParams);
      if(!props.objExists(props.dashboard, 'tasks') ||
          props.objGetValue(props.dashboard, 'reload.tasks') === true) {
            setTasksLoading(true);
            props.dashboardLoadState(taskParams, 'tasks', 'tasks');
            
            if(ivlTasks) {
              clearInterval(ivlTasks);
            }
            ivlTasks = setInterval(function(){
              if(document.visibilityState === 'visible') {
                props.dashboardLoadState(taskParams, 'tasks', 'tasks');
              }
            }, props.minToMS(1));
      }
    }
  }, [userID, token, ivlTasks, props.dashboard.reload]);
  
  useEffect(() => {
    if(props.objGetValue(props.dashboard, 'tasks') === 'no tasks found' || 
      Array.isArray(props.objGetValue(props.dashboard, 'tasks'))) {
      if(tasksLoading) {
        setTasksLoading(false);
      }
    
      if(props.dashboard.tasks !== tasks) {
        setTasks(props.dashboard.tasks);  
      }
    } 
  }, [props.dashboard.tasks]);

  useEffect(() => {
    if(pgParams.section) {
      switch(pgParams.section) {
        case 'products':
          setComponent(null);
          return;
        default:
          setComponent(null);
          return;
      }
    }
  }, [userID]);
  
  function reloadTasks() {
    props.dashboardSetVal('reload.tasks', true);
  };

  // console.log("DASHBOARD", props.dashboard);
  return (
    <div className="dashboard">
      <div className="dashboard-interface">
        <div className="dashboard-interface-row">
          <h2>Dashboard</h2>
        </div>
        <div className="dashboard-interface-row set">
          <div className="dashboard-interface-column main">
            {tasks ? 
              <TaskList {...props}
                userID={userID}
                token={token}
                languageCode={languageCode}
                userAccess={userAccess}
                tasks={tasks}
                location="dashboard"
                actionReload={reloadTasks}
              />
            :
              tasksLoading && 
              <Loader label="Getting tasks..." />
            }
            
          </div>
          {userAccess && userAccess.super && messages &&
          <div className="dashboard-interface-column aside">
            <MessagesWidget {...props}
              userID={userID}
              token={token}
              languageCode={languageCode}
              messages={messages} />
          </div>
          }
        </div>
      </div>
    </div>
  )
}
