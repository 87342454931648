import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router';

import Card from 'components/atoms/Card';
import CardContent from 'components/molecules/CardContent';
import CardContentContact from 'components/molecules/CardContentContact';
import RadioBtn from 'components/forms/elements/RadioBtn';
import IconToken from 'components/icons/IconToken';
import IconToggle from 'components/icons/IconToggle';
import Button from 'components/forms/elements/Button';
import CheckoutBillingPayments from 'components/cart/CheckoutBillingPayments';

import * as formData from 'methods/forms';

export default (props) => {
  const [redirect, setRedirect] = useState(null);
  const [active, setActive] = useState(null);
  
  useEffect(() => {
    if(props.stageComplete === true) {
      setActive(false);
    }else{
      setActive(true);
    }
  }, [props.stageComplete])
  
  // { props.objExists(props.site, 'pay_options') &&
  //         Array.isArray(props.site.pay_options) &&
  //         props.site.pay_options.includes('invoice') &&
  // 
  //         <Card title="Invoice Delivery"
  //               classRule="nopad"
  //               editable={false}
  //               actionAdd={(e)=>{e.preventDefault()}}
  //               actionEdit={(e)=>{e.preventDefault()}}>
  //             <div className="flex-row">
  //               
  //             </div>
  
  
  return (
    <div className={`progressive-section${active ? ' active' : ''}`}>
      <h3>Billing{props.stageComplete === true && <IconToken icon="check" className="success" />}</h3>
                  
      <div className="details-preview">
          {props.objExists(props.cart, 'project.billing.location') &&
            <div className="contents">
              Bill to: {props.cart.project.billing.location.location_name ? 
                        <b>{`${props.cart.project.billing.location.location_name}`}</b>
                      : <b>{`${props.cart.project.billing.contact.contact_name_first} ${props.cart.project.billing.contact.contact_name_last}`}</b>
                      }
              {` - ${props.cart.project.billing.location.location_address_1}${props.cart.project.billing.location.location_address_2 ?
                                              ` - ${props.cart.project.billing.location.location_address_2}` : ''}, ${props.cart.project.billing.location.location_city}, ${props.cart.project.billing.location.location_province}`}
          </div>
        }
      </div>
      <div className="details-expand">
        {redirect && <Redirect to={redirect} />}
        
        {props.paymentOptions && 
          Array.isArray(props.paymentOptions) &&
          (props.paymentOptions.length > 1 ||
          (props.paymentOptions.length === 1 &&
          props.paymentOptions[0].option_component !== 'invoice_account')) &&
          
          <div className="card-set">
            <Card editable={true}
                  title="Billing Address"
                  classRule="nopad"
                  actionEdit={()=>{props.infoEdit('project.billing.location',
                                                    props.cart.project.billing.location,
                                                    [{fieldset_name: 'Billing Address', fields: formData.fieldsLocation}],
                                                    [props.user && props.user.isLogin === true && (!props.isUserLocation(props.cart.project.billing.location, props.user) &&{field_type: 'switch',
                                                      field_name: 'save_location',
                                                      field_label: 'Add location to your account?',
                                                      field_value: props.cart.project.billing.location.save_location ? true : false,
                                                      field_required: false,
                                                      field_validate: false}),
                                                    {field_type: 'switch',
                                                      field_name: 'save_default',
                                                      field_label: 'Save as default?',
                                                      field_value:
                                                      props.objExists(props.cart, 'project.billing.location.save_default') && props.cart.project.billing.location.save_default ? true : false,
                                                      field_required: false,
                                                      field_validate: false},
                                                    ]
                                                  )}} >
              <CardContentContact
                {...props}
                title="Billing Address"
                info={props.objExists(props.cart, 'project.billing.location') && props.cart.project.billing.location}
                type="location"
                editable={true}
                fieldsRequired={['address_1', 'city', 'province', 'code', 'country']}
                actionEdit={()=>{props.infoEdit('project.billing.location',
                                                  props.cart.project.billing.location,
                                                  [{fieldset_name: 'Billing Address', fields: formData.fieldsLocation}],
                                                  [props.user && props.user.isLogin === true && (!props.isUserLocation(props.cart.project.billing.location, props.user) &&{field_type: 'switch',
                                                    field_name: 'save_location',
                                                    field_label: 'Add location to your account?',
                                                    field_value: props.cart.project.billing.location.save_location ? true : false,
                                                    field_required: false,
                                                    field_validate: false}),
                                                  {field_type: 'switch',
                                                    field_name: 'save_default',
                                                    field_label: 'Save as default?',
                                                    field_value:
                                                    props.objExists(props.cart, 'project.billing.location.save_default') && props.cart.project.billing.location.save_default ? true : false,
                                                    field_required: false,
                                                    field_validate: false},
                                                  ]
                                                )}} />
            </Card>
    
            <Card editable={true}
                  title="Billing Contact"
                  classRule="nopad"
                  actionEdit={()=>{props.infoEdit('project.billing.contact',
                                    props.cart.project.billing.contact,
                                    [{fieldset_name: 'Edit Billing Contact', fields: formData.fieldsContact}],
                                    [{field_type: 'switch',
                                      field_name: 'save_default',
                                      field_label: 'Save as default?',
                                      field_value: (props.cart, 'project.billing.contact.save_default') && props.cart.project.billing.contact.save_default ? true : false,
                                      field_required: false,
                                      field_validate: false},]
                                   )}} >
              <CardContentContact
                  {...props}
                 title="Billing Contact"
                 info={props.objExists(props.cart, 'project.billing.contact') && props.cart.project.billing.contact}
                 type="contact"
                 editable={true}
                 fieldsRequired={['name_first', 'name_last', 'phone', 'email']}
                 actionEdit={()=>{props.infoEdit('project.billing.contact',
                                   props.cart.project.billing.contact,
                                   [{fieldset_name: 'Edit Billing Contact', fields: formData.fieldsContact}],
                                   [{field_type: 'switch',
                                     field_name: 'save_default',
                                     field_label: 'Save as default?',
                                     field_value: (props.cart, 'project.billing.contact.save_default') && props.cart.project.billing.contact.save_default ? true : false,
                                     field_required: false,
                                     field_validate: false},]
                                  )}} />
            </Card>
          </div>
          
        }
        
        <CheckoutBillingPayments {...props}
                        paymentOptions={props.paymentOptions}
                        billing={props.billing}
                        location={'project'} />
      </div>
      <div className="ctl-row">
        <IconToggle action={()=>{setActive(active ? false : true)}} tooltip="Details" />
      </div>
    </div>
  )
}
