import React, {useEffect, useState, useRef, Fragment} from 'react';

import IconFile from 'components/icons/IconFile';
import IconToken from 'components/icons/IconToken';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [file, setFile] = useState(null);
	const [approval, setApproval] = useState(null);
	const [preview, setPreview] = useState(null);
	const [fileOwner, setFileOwner] = useState(null);
	
	const fileLink = useRef();
	
	// HOOKS
	useEffect(() => {
		if(props.userID && 
			props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && 
			props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.languageCode && 
			props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.file && 
			props.file !== file) {
			setFile(props.file);
		}
	}, [props.file]);
	
	
	
	useEffect(() => {
		if(file && file.media_file_tag &&
			['jpg', 'svg', 'gif', 'png', 'tif'].includes(file.media_file_tag)) {
			setPreview(
				<div className="modal-image-preview">
					<img src={`https://aecore.app/assets/media/${file.media_file_name}`} />
				</div>
			)
		}
		
		if(props.objExists(file, `approvals[0].en.status_name`) &&
			file.approvals[0].en.status_name !== approval) {
			setApproval(file.approvals[0].en.status_name);
		}
	}, [file]);
	
	useEffect(() => {
		if(userID && props.file.media_created_id === userID) {
			setFileOwner(true);
		}else if(fileOwner) {
			setFileOwner(false);
		}
	}, [userID, props.file.media_created_id, props.userAccess]);
	
	// FUNCTIONS
	function downloadFile() {
		fileLink.current.click();
	}
	
	function removeFile() {
		props.actionRemove &&
		props.actionRemove(props.file.media_id, props.file.media_type);
	}
	
	return (
		file &&
		<div className={`media-file-token${props.isHero ? ' hero' : ''}${props.className ? ` ${props.className}` : ''}`}>
			<IconFile icon={file.media_file_tag} approval={approval} />
			
			{props.isHero &&
			props.displayInfo &&
			props.label &&
				<div className="title-bar">
					{props.capitalizeString(props.label)}
				</div>
			}
			
			{props.displayInfo &&
				<Fragment>
					{props.objExists(file, `approvals[0].en.status_name`) &&
						<div className={`row${file.approvals[0].en.status_name === 'approved' ? ` success` : file.approvals[0].en.status_name === 'declined' ? ` error` : ''} bold`}>
							{props.capitalizeString(file.approvals[0].en.status_name, true)}
						</div> 
					}
					<div className="media-name">
						{props.objExists(props.file, `media_file_upload_name`) ?
							props.file.media_file_upload_name : props.file.media_file_name
						}
					</div>
					{props.objExists(props.file, `media_file_size`) &&
					<div className="media-size">
						{props.displayFileSize(props.file.media_file_size)}
					</div>
					}
					{props.objExists(props.file, `media_created`) &&
					<div className="media-date">
						{`${props.dateDisplay(props.file.media_created)} ${props.timeDisplay(props.file.media_created, false)}`}
					</div>
					}
				</Fragment>
			}
			<a href={`https://api.aecore.app/files/download.php?file=${props.file.media_id}`} 
				target="_blank"
				ref={fileLink}></a>
			<div className="ctl">
				{preview &&
					<IconToken icon="preview" tooltip="View file info" action={()=>{props.setModal(preview, true)}} />
				}
				<IconToken icon="download" tooltip="Download" action={downloadFile} />
				{fileOwner &&
					<IconToken icon="trash" tooltip="Remove" action={removeFile} />
				}
			</div>
		</div>
	)
}