import React from 'react';

import IconToken from 'components/icons/IconToken';

export default (props) => {

  // FUNCTIONS
  function editItem() {
    if(props.actionEdit && props.id) {
      props.actionEdit(props.id, props.type ? props.type : null);
    }
  }

  function removeItem() {
    if(props.actionRemove && props.id) {
      props.actionRemove(props.id);
    }
  }

  return (
    <li className={`li${props.inline ? ' inline' : ''}`}>
      {props.icon && <IconToken icon={props.icon} className="li-bullet" /> }
      {props.item}
      {props.editable &&
        <div className="li-ctl">
          {props.actionEdit && <IconToken icon="edit" className="btn" action={editItem} />}
          {props.actionRemove && <IconToken icon="cancel" className="btn" action={removeItem} />}
        </div>
      }
    </li>
  )
}
