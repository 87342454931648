import React, {useEffect, useState}  from 'react';
import { useParams } from "react-router";

import PageInternal from 'components/templates/PageInternal';
import DashboardTask from 'components/dashboard/DashboardTask';

export default () => {

  return (
    <PageInternal>
      <DashboardTask />
    </PageInternal>
  )
}
