import React from 'react';

import IconToken from 'components/icons/IconToken';

export default (props) => {
  return (
    <div className={`ctl-combo${props.className ? ` ${props.className}` : ''}`}
      style={props.style}
      onClick={props.action ? props.action : null}>
      {props.icon &&
        <IconToken icon={props.icon}
              className={props.iconClass ? props.iconClass : 'btn'}
              tooltip={props.tooltip ? props.tooltip : null}
              style={{textAlign: 'left'}} />
      }
      {props.showLabel && props.label &&
        <span className="ctl-label">{props.label}</span>
      }
    </div>
  )
}
