import React, {useEffect, useState} from 'react';

import FormDynamic from 'components/forms/FormDynamic';

export default (props) => {
  const [categories, setCategories] = useState(null);
  const [value, setValue] = useState(null);
  const [parentValue, setParentValue] = useState(null);
  const [optionsCategories, setOptionsCategories] = useState(null);
  const [optionsParentCategories, setOptionsParentCategories] = useState(null);
  const [fieldsets, setFieldsets] = useState(null);
  const [form, setForm] = useState(null);
  
  useEffect(() => {
    if(Array.isArray(props.categories)) {
      setCategories(
        categoryList(props.categories).sort((a, b) => {
          return a.category_name > b.category_name ? 1 : -1;
        })
      );
    }
  }, [props.categories]);

  useEffect(() => {
    let catOptions = [];
    let parentOptionGroups = [
      {options: [
        {option_name: 'New Parent Category',
        option_value: '0',
        fieldsets: [{fields: [
          {field_type: 'text',
            field_name: 'category_parent_name',
            field_label: 'Parent Category Name',
            field_placeholder: 'Name of parent category',
            field_value: '',
            field_required: true,
            field_validate: true,
            field_order: 4,
          }
        ]}]}
      ]}
    ]

    if(Array.isArray(categories)) {
      categories.map(cat => {
        if(!props.activeCategories || !Array.isArray(props.activeCategories) ||
          (!props.activeCategories.find(aCat => aCat.category_id === cat.category_id))) {
            catOptions.push(
              {option_name: props.capitalizeString(cat.category_name, true), option_value: cat.category_id}
            );
            parentOptionGroups[0].options.push(
              {option_name: props.capitalizeString(cat.category_name, true), option_value: cat.category_id}
            );
          }
        return true;
      })
    }

    let optionGroups = [
      {options: [
        {option_name: 'New Category',
        option_value: '0',
        fieldsets: [{fields: [
          {field_type: 'text',
            field_name: 'category_name',
            field_label: 'Category Name',
            field_placeholder: 'Name of category',
            field_value: '',
            field_required: true,
            field_validate: true,
            field_order: 2,
          },
          {field_type: 'select',
            field_name: 'category_parent',
            field_label: `Parent Category`,
            field_value: '',
            field_placeholder: 'Select Parent...',
            field_order: 3,
            option_groups: parentOptionGroups },
        ]}]},
      ]}
    ];
    catOptions.map(opt => {
      optionGroups[0].options.push(opt);
    })
    
    setOptionsCategories(props.objClone(optionGroups));
  }, [categories]);

  useEffect(() => {
    if(optionsParentCategories) {
      if(Array.isArray(optionsParentCategories)) {
        let parentCategories = props.objClone(optionsParentCategories);



        if(value) {
          parentCategories[0] = {options: parentCategories[0].options.filter(option => option.option_value !== value)};
        }
        setOptionsParentCategories(parentCategories);
      }
    }
  }, [value]);

  useEffect(() => {
    if(optionsCategories) {
      setFieldsets([
        {fieldset_name: `${props.isChange ? 'Change' : 'Add'} Category`,
          fields: [
            {field_type: 'select',
              field_name: 'category_id',
              field_label: `Category`,
              field_value: null,
              field_placeholder: 'Select Category...',
              field_order: 1,
              option_groups: optionsCategories},
            {
              field_type: 'text',
              field_name: 'product_quantity',
              field_label: `Quantity`,
              field_value: null,
              field_order: 2,
            },
            {
              field_type: 'switch',
              field_name: 'optional_product',
              field_label: `Optional Product`,
              field_value: null,
              field_order: 3,
            },
          ]
        }
      ]);
    }
  }, [optionsCategories]);

  useEffect(() => {
    fieldsets &&
    setForm(
      <FormDynamic
          content={fieldsets}
          name="add-edit-category"
          returnObject={true}
          fieldOnChange={handleChange}
          fieldOnLoad={(e)=>{e.preventDefault()}}
          formActions={{formCancel: handleCancel, formSubmit: handleSubmit}} />
    )
  }, [fieldsets]);

  function categoryList(categories, parentName = '') {
    let catList = [];
    if(Array.isArray(categories)) {
      categories.map(cat => {
        cat = {...cat, category_name: `${parentName ? `${parentName} > ` : ''}${cat.category_name}`}
        if(!catList.includes(cat)) {

          catList.push(cat);
        }
        if(cat.sub_categories && 
            Array.isArray(cat.sub_categories)) {
            catList.push(...categoryList(cat.sub_categories, cat.category_name));
        }
      });
    }
    return catList;
  }

  function handleCancel() {
    props.setModal(null);
  }

  function handleSubmit(params) {
    if(props.action) {
      props.action(params);
    }
  }

  function handleChange(e) {
    if(e && e.name) {
      switch(e.name) {
        case 'category_id':
          setValue(e.value ? parseInt(e.value) : null);
          break;
        case 'parent_id':
          setParentValue(parseInt(e.value));
          break;
        default:
          break;
      }
    }
  }

  return (fieldsets && form)
}
