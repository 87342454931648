import React, {useEffect, useState} from 'react';
import ReactDOM, {findDOMNode} from 'react-dom';

export default (props) => {
	const [textOptions, setTextOptions] = useState(null);
	const [activeOption, setActiveOption] = useState(null);
	const [target, setTarget] = useState(null);
	const [menuStyle, setMenuStyle] = useState(null);
	const [domTarget, setDOMTarget] = useState(null);
	
	const listenerKeyDown = null;
	
	useEffect(() => {
		if(Array.isArray(props.options) &&
			props.options !== textOptions) {
			setTextOptions(props.options);
		}
	}, [props.options]);
	
	useEffect(() => {
		if(props.target && props.target !== target) {
			setTarget(props.target);
		}
	}, [props.target]);
	
	useEffect(() => {
		
		if(target) {
			setDOMTarget(findDOMNode(target));
			const targetSpecs = target.getBoundingClientRect(); 
			setMenuStyle({
				top: `${targetSpecs.y+targetSpecs.height}px`,
				left: `${targetSpecs.x}px`,
				width: targetSpecs.width,
				zIndex: 5000	
			})
		}
	}, [target]);
	
	useEffect(() => {
		if(target && menuStyle) {
			const _keyListener = (e) => {
				navigateKeys(e);
			}
			target.onkeydown = _keyListener;
		}
	}, [target, menuStyle, activeOption]);
	
	// FUNCTIONS
	function navigateKeys(e) {
		switch(e.key) {
			case 'ArrowDown':
				e.preventDefault();
				if(Array.isArray(textOptions)) {
					if(!activeOption) {
						setActiveOption(textOptions[0].option_value);
					}else{
						const curKey = textOptions.map(opt => opt.option_value).indexOf(activeOption);
						if(curKey+1 < textOptions.length) {
							setActiveOption(textOptions[curKey+1].option_value);
						}
					}	
				}
				
				break;
			case 'ArrowUp':
				e.preventDefault();
				if(activeOption && Array.isArray(textOptions)) {
					const curKey = textOptions.map(opt => opt.option_value).indexOf(activeOption);
					if(curKey > 0) {
						setActiveOption(textOptions[curKey-1].option_value);
					}else{
						setActiveOption(null);
					}
				}
				break;
			case 'Enter':
				e.preventDefault();
				if(Array.isArray(textOptions) && activeOption) {
					const selectedOption = textOptions.find(opt => opt.option_value === activeOption);
					selectOption(selectedOption);
				}
			default:
				break;
		}
	}
	
	function selectOption(option) {
		setTextOptions(null);
		if(props.actionSelect) {
			props.actionSelect(option);
		}
	}
	
	return (
		textOptions && menuStyle &&
		ReactDOM.createPortal(<div className="predictive-text-menu" style={menuStyle}>
				{textOptions.map((option, index) => {
					return (
						<div className={`predictive-text-menu-option${activeOption === option.option_value ? ` active`: ''}`}
							>
							{option.option_name}
						</div>
					)
				})}
		</div>, document.body)
	)
}