import React from 'react';

// import IconToken from 'components/icons/IconToken';

export default (props) => {
return (
  <div className={`progress-marker${props.active ? ' active' : ''}${props.complete ? ' complete' : ''}${props.released ? ' released' : ''}`}>
    {props.stageCount > 1 && <div className="progress-marker-rule"></div>}
    <div className="progress-marker-stage">
      <div className="progress-marker-stage-number" onClick={props.action} >{props.stageCount}</div>
      {props.stage.name && <div className="progress-marker-label">{props.stage.name}</div>}
    </div>
  </div>

)

}
