import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router';

import ProjectPricing from 'components/cart/ProjectPricing';
import CartItem from 'components/cart/CartItem';
import CartTotals from 'components/cart/CartTotals';
import Button from 'components/forms/elements/Button';
import Link from 'components/navigation/Link';

import * as utils from 'methods/site';

export default (props) => {
  props = {...props.children, ...utils};

  const [items, setItems] = useState(null);
  const [project, setProject] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [languageCode, setLanguageCode] = useState('en');
  const [organizationID, setOrganizationID] = useState(null);
  const [hidePricing, setHidePricing] = useState(null);
  const [cartItems, setCartItems] = useState(null);
  const [isDigital, setIsDigital] = useState(null);
  const [isSuper, setIsSuper] = useState(null);
  
  // HOOKS
  useEffect(() => {
    if(props.objExists(props.site, 'persona.organization_id') &&
        organizationID !== props.site.persona.organization_id) {
      setOrganizationID(props.site.persona.organization_id);
    }
    if(props.objExists(props.site, 'site_language') && 
        languageCode !== props.site.site_language) {
      setLanguageCode(props.site.site_language);
    }else{
      setLanguageCode('en');  
    }
  }, [props.site]);
  
  useEffect(() => {
    if(organizationID) {
      document.title = `${props.site.persona.organization_name} : Cart`;
    }
  }, [organizationID, props.store.persona]);
  
  // CHECK SITE PREFERENCES HERE:
  useEffect(() => {
    if(props.objExists(props.site, 'preferences.hide_pricing') &&
        hidePricing !== props.getBoolean(props.site.preferences.hide_pricing)) {
      setHidePricing(props.getBoolean(props.site.preferences.hide_pricing));
    }
  }, [props.site.preferences]);
  
  useEffect(() => {
    if(organizationID &&
        languageCode &&
        !props.objExists(props.cart, 'taxRates') &&
        !props.objExists(props.cart, 'shippingRates')) {
          // props.cartGetTax(organizationID);
          const taxParams = [
            {name:'process', value:'get_tax_rates'},
            {name:'organization_id', value: organizationID},
          ];
          
          if(!props.objExists(props.cart, 'taxRates')) {
            props.cartLoadState(taxParams, 'rates', 'taxRates');  
          }
          
          const shippingParams = [
            {name:'process', value:'get_shipping_rates'},
            {name:'organization_id', value: organizationID},
            // if location_province then param for location,
            // if shipping_method then param for shipping_method,
          ];
          
          if(!props.objExists(props.cart, 'shippingRates')) {
            props.cartLoadState(shippingParams, 'rates', 'shippingRates');
          }
    }
  }, [organizationID, languageCode]);
  
  useEffect(() => {
    if(props.objExists(props.user, 'user_id')) {
      if(props.objGetValue(props.user, `user_roles.1.role_id`) === 1) {
        setIsSuper(true);
      }
    }
  }, [props.user]);

  useEffect(() => {
    if(props.objExists(props.cart, 'items') && 
        sessionStorage.getItem('coreCRT') !== JSON.stringify(props.cart.items)) {
      sessionStorage.setItem('coreCRT', JSON.stringify(props.cart.items));
    };
    
    // CHECKING FOR DIGITAL ONLY PROUDUCTS BY SEARCHING FOR A DELIVERY FIELD
    // WITH A FIELD VALUE OF DIGTAL:
    if(languageCode && props.objExists(props.cart, 'items') && Array.isArray(props.cart.items)) {
      // let digitalDelivery = true;
      props.cart.items.map(item => {
        // if(Array.isArray(props.objGetValue(item, `details.${languageCode}.fieldsets`))) {
        //   item.details[languageCode].fieldsets.map(fieldset => {
        //     if(Array.isArray(fieldset.fields)) {
        //       const itemDelivery = fieldset.fields.find(field => field.field_label.toLowerCase() === 'delivery');
        //       if(itemDelivery && props.objGetValue(item, `field_values.${itemDelivery.field_id}`) !== 'digital') {
        //         digitalDelivery = false;
        //       }
        //     }else{
        //       digitalDelivery = false;
        //     }
        //    
        //   })
        // }else{
        //   digitalDelivery = false;
        // }
      });
      // setIsDigital(digitalDelivery);
      // console.log("DIGITAL ONLY ?", digitalDelivery);
    }
  }, [props.cart.items, languageCode]);
  
  useEffect(() => {
   let itemSet = [];
    if(props.objExists(props.cart, 'items') &&
        Array.isArray(props.cart.items) &&
        props.cart.items.length > 0) {
        props.cart.items.map((item, index) => {
          itemSet.push({...item, itemLocation:`items[${index}]`});
        });  
    }
    
    if(props.objExists(props.cart, 'project.orders') &&
        Array.isArray(props.cart.project.orders)) {
          props.cart.project.orders.map((order, index) => {
            if(order.items &&
                Array.isArray(order.items) &&
                order.items.length > 0) {
              order.items.map((item, itemIndex) => {
                itemSet.push({...item, 
                              shipping_to: props.objExists(order, 'shipping.location') ? order.shipping.location : 'tbd', 
                              itemLocation:`project.orders[${index}].items[${itemIndex}]`});
              })
            }
          })
    }
    
    if(props.objExists(props.cart, 'project') &&
        Array.isArray(props.objGetValue(props.cart, 'project.orders')) &&
        props.cart.project.orders.length > 0) {
      setProject(props.cart.project);
    }
    
    if(Array.isArray(itemSet) &&
        itemSet.length > 0 &&
        itemSet !== items) {
      setItems(itemSet); 
    }else {
      setItems(null);
      setCartItems(null);
    }
  }, [props.cart]);
  
  useEffect(() => {
    if(items && Array.isArray(items)) {
      let cItems = [];
      let digitalDelivery = true;
      items.map((item, index) => {
        let itemDigital = false;
        if(Array.isArray(props.objGetValue(item, `details.${languageCode}.fieldsets`))) {
          item.details[languageCode].fieldsets.map(fieldset => {
            if(Array.isArray(fieldset.fields)) {
              const itemDelivery = fieldset.fields.find(field => field.field_label.toLowerCase() === 'delivery');
              if(itemDelivery && props.objGetValue(item, `field_values.${itemDelivery.field_id}`) === 'digital') {
                itemDigital = true;
              }else{
                digitalDelivery = false;
              }
            }else{
              digitalDelivery = false;
            }
           
          })
        }else{
          digitalDelivery = false;
        }
        
        const itemActions = [
          {icon: 'trash', 
            action: removeItem, 
            label: 'Remove...', 
            subject: index,
            params: {itemLocation: item.itemLocation, product_name: item.details[languageCode].product_name}
          }
        ];
        cItems.push(
          <CartItem {...props}
                    index={index} 
                    key={`cartitem${index}`} 
                    item={item}
                    languageCode={languageCode}
                    orderIndex='cart' 
                    itemLocation={item.itemLocation} 
                    updateAction={props.cartSetValue} 
                    itemActions={itemActions}
                    hidePricing={hidePricing}
                    isDigital={itemDigital}
                    expandView={items.length === 1 ? true : false} />
        )
      });
      if(digitalDelivery !== isDigital){
        setIsDigital(digitalDelivery);  
      }
      
      if(cItems.length > 0 && 
          cItems !== cartItems) {
          setCartItems(cItems);
      }else{
        if(props.objExists(props.cart, 'project')) {
          props.cartDeleteVal('project');
          sessionStorage.removeItem('corePrjct');
        }
      }
    }
  }, [items, hidePricing]);
  
  useEffect(() => {
    if(props.objExists(props.cart, 'project') && isDigital &&
        props.objGetValue(props.cart, `project.project_delivery`) !== 'digital') {
          props.cartSetValue(`project.project_delivery`, 'digital');
    }else if(props.objGetValue(props.cart, `project.project_delivery`) === 'digital' &&
               !isDigital) {
      props.cartDeleteVal(`project.project_delivery`);
    }
  }, [isDigital, props.cart.project]);
  
  useEffect(() => {
    if(props.cart.project) {
      sessionStorage.setItem('corePrjct', JSON.stringify(props.cart.project));
    }else if (sessionStorage.getItem('corePrjct') && sessionStorage.getItem('corePrjct').length <= 0){
      sessionStorage.removeItem('corePrjct');
    }
  }, [props.cart.project]);
  
  // FUNCTIONS
  function removeItem(params, itemIndex) {
    if(props.objExists(props.cart, params.itemLocation)) {
      props.cartDeleteVal(params.itemLocation);
      if(props.objExists(props.cart, 'items') &&
        Array.isArray(props.cart.items) &&
        props.cart.items.length > 0 &&
        props.cart.items !== JSON.parse(sessionStorage.getItem('coreCrt'))) {
          props.cartSetValue('cartCount', props.cart.items.length);
          sessionStorage.setItem('coreCRT', JSON.stringify(props.cart.items));
      } else {
        emptyCart();
      }
    }
  }

  function emptyCart(e) {
    props.cartRemoveVal('project');
    props.cartRemoveVal('items');
    props.filesDeleteVal('uploads');
    props.filesDeleteVal('uploadComplete');
    sessionStorage.removeItem('coreCRT');
    sessionStorage.removeItem('corePrjct');
    setCartItems(null);
  }

  // NEED TO MAKE SURE THE CART ITEM KNOWS WHETHER IT'S FROM ITEMS OR PROJECT
  return (
    <div className="cart">
      {redirect && <Redirect to={redirect} />}
      <h2>Shopping Cart</h2>
      <div className="cart-header">
        <div>
          <p className={`cart-header-qty${(!props.cart.cartCount || props.cart.cartCount === 0) ? ' empty' : ''}`}>You have <strong>{props.cart.cartCount ? props.cart.cartCount : 'no'}</strong> item{`${!props.cart.cartCount || (props.cart.cartCount && props.cart.cartCount > 1) ? 's' : ''}`} in your cart.</p>
          {(props.cart.cartCount && props.cart.cartCount > 0) &&
            <Button type="button" btnClass="info-bg mini" label="Add an item?" icon="arrow" iconClass="rotate270"
              btnAction={(e)=>{setRedirect(props.objExists(props.site, 'preferences.category_home') ? props.site.preferences.category_home : '/categories')}} />
          }
          {(!props.cart.cartCount || props.cart.cartCount === 0) &&
            <Button type="button" btnClass="info-bg mini" label="Add an item?" icon="arrow" iconClass="rotate270"
            btnAction={(e)=>{setRedirect(props.objExists(props.site, 'preferences.category_home') ? props.site.preferences.category_home : '/categories')}} />
          }
        </div>

      </div>
      {Array.isArray(cartItems) && cartItems.length > 0 && cartItems}
      {!hidePricing && items && 
        utils.objExists(props.cart, 'cartCount') && 
        props.cart.cartCount > 0 && 
        <CartTotals {...props} {...utils} items={items} /> 
      }
      
      {props.objGetValue(props.cart, 'cartCount')  > 0 && cartItems &&
        <div className="cart-ctl">
          <Button type="button" btnClass="caution-bg" label="Empty Cart" icon="cart" btnAction={emptyCart} />
          <Button type="button" btnClass="success-bg" label="Checkout" icon="arrow" iconClass="rotate90" iconRight={true}
            disabled={(utils.objExists(props.cart, 'items') && props.cart.items.length > 0) ||
                      (utils.objExists(props.cart, 'project.orders') && props.cart.project.orders.length > 0) ? false : true}
            btnAction={(e)=>{setRedirect('/checkout')}} />
        </div>
      }
    </div>
  )
}
