import React, {useEffect, useState, Fragment} from 'react';

import FormDynamic from 'components/forms/FormDynamic';
import IconSVG from 'components/icons/IconSVG';
import Button from 'components/forms/elements/Button';
import FormShippingAddEdit from 'components/forms/common_forms/FormShippingAddEdit';
import ShippingBlock from 'components/profiles/shipping/ShippingBlock';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [task, setTask] = useState(null);
	const [taskName, setTaskName] = useState(null);
	const [fieldsets, setFieldsets] = useState(null);
	const [form, setForm] = useState(null);
	const [fileName, setFileName] = useState(null);
	const [fileVersion, setFileVersion] = useState(null);
	const [uploadName, setUploadName] = useState(null);
	const [fileTag, setFileTag] = useState(null);
	const [optionsCarriers, setOptionsCarriers] = useState(null);
	const [orderLocations, setOrderLocations] = useState(null);
	
	useEffect(() => {
		if(props.userID && props.userID != userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token != token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.task && props.task != task) {
			setTask(props.task);
		}
	}, [props.task]);
	
	useEffect(() => {
		if(props.languageCode && 
			props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}else if (!props.languageCode && 
					!languageCode &&
					props.objExists(props.site, 'site_language')) {
			setLanguageCode(props.site.site_language);
		}else if (!languageCode) {
			setLanguageCode('en');
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(Array.isArray(props.shippingCarriers) &&
			props.shippingCarriers.length > 0) {
			let optCarriers = [];
			props.shippingCarriers.map(carrier => {
				optCarriers.push({option_name: carrier.carrier_name, 
									option_value: carrier.carrier_id});
			})
			if(optCarriers.length > 0 &&
				optCarriers !== optionsCarriers) {
				setOptionsCarriers(optCarriers);	
			}
		}
	}, [props.shippingCarriers]);
	
	useEffect(() => {
		if(props.orderLocations &&
			props.orderLocations !== orderLocations) {
			setOrderLocations(props.orderLocations);
		}
	}, [props.orderLocations]);
	
	useEffect(() => {
		if(task) {
			// SET TASK NAME FOR MODAL
			let tName;
			if(props.objExists(task, 'item_name')) {
				setTaskName(`this ${props.capitalizeString(task.item_name, true)}`);
			}else if(props.objExists(task, `order_id`) &&
					props.objExists(task, `project_id`) &&
					props.objExists(task, `organization_code`)) {
				setTaskName(props.orderNumDisplay(task.project_id, task.order_id, task.organization_code));
			}else if(props.objExists(task, `project_id`) &&
					props.objExists(task, `organization_code`)) {
				setTaskName(props.projectNumDisplay(task.project_id, task.organization_code));
			}else{
				setTaskName(`Task ${task.task_id}`);
			}
		}
	}, [task]);
	
	// FUNCTIONS
	function handleCancel() {
		if(props.actionCancel) {
			props.actionCancel(true);
		}else{
			props.setModal(null);
		}
	}
	
	function handleComplete(params) {
		if(props.actionSubmit) {
			props.actionSubmit(params);
		}else{
			props.setModal(null);
		}	
	}
	
	return (
		<Fragment>
			<div className="modal-header text-center">
				<IconSVG icon="check" className="icon-header success" />
				<h3>{`Complete ${taskName ? taskName : 'Order'} Shipping?`}</h3>
				<p>Verify any shipping info needed has been added before clicking <b>'COMPLETE'</b>
					<br />
					<span className="subtext">
						(Shipping info can set for each shipping location shown)
					</span>
				</p>
			</div>
			{Array.isArray(orderLocations) &&
			orderLocations.map((loc, index) => {
				return (
					<div style={{paddingBottom: '1em', 
									borderBottom: '.0625em solid #333333', 
									marginBottom: '1em',
									borderRadius: '0em'
								}}>
						<p>Shipping to: <b>{`${loc.location_name ? loc.location_name : 
										`${loc.location_address_1}${
											loc.location_address_2 ? 
												`- ${loc.location_address_2}` :
													''}`}, ${loc.location_city}  ${
													loc.location_province}`}
						</b></p>
						<ShippingBlock {...props}
							location={loc} />
					</div>
				)
			})
			}
			<div className="form-row">
				<Button icon="close" 
					label="cancel" 
					btnAction={handleCancel} />
				<Button icon="check" 
					label="complete" 
					btnClass="success-bg"
					btnAction={handleComplete} />
			</div>
		</Fragment>
	)
}