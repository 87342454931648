import React, {useEffect, useState} from 'react';

import IconToken from 'components/icons/IconToken';

export default (props) => {
	const [contact, setContact] = useState(null);
	const [contactName, setContactName] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(props.contact && 
			props.contact !== contact) {
			setContact(props.contact);
		}
	}, [props.contact]);
	
	useEffect(() => {
		if(contact) {
			const cName = `${contact.contact_name_first} ${contact.contact_name_last}`;
			if(cName !== contactName) {
				setContactName(cName);
			}
		}
	}, [contact]);
	
	// FUNCTIONS
	function removeContact() {
		if(props.actionRemove) {
			props.actionRemove(contact);
		}
	}
	
	return (
		contactName &&
		<div className="message-contact-tag">
			<div>{contactName}</div>
			<div className="ctl">
				<IconToken icon="close" tooltip="remove" action={removeContact} />
			</div>
		</div>
	)
	
}