import React, {useEffect, useState, Fragment} from 'react';

import Button from 'components/forms/elements/Button';
import Input from 'components/forms/elements/Input';
import TaskUser from 'components/profiles/tasks/TaskUser';
import TaskGroup from 'components/profiles/tasks/TaskGroup';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [canEdit, setCanEdit] = useState(null);
	const [isSuper, setIsSuper] = useState(null);
	const [taskUsers, setTaskUsers] = useState(null);
	const [taskGroups, setTaskGroups] = useState(null);
	const [staff, setStaff] = useState(null);
	const [groups, setGroups] = useState(null);
	const [curSet, setCurSet] = useState(null);
	const [curList, setCurList] = useState(null);
	
	// HOOKS
	useEffect(() => {
		props.userID && 
		props.userID !== userID &&
		setUserID(props.userID);
	}, [props.userID]);
	
	useEffect(() => {
		props.token && 
		props.token !== token &&
		setToken(props.token);
	}, [props.token])
	
	useEffect(() => {
		props.organizationID && 
		props.organizationID !== organizationID &&
		setOrganizationID(props.organizationID);
	}, [props.organizationID]);
	
	useEffect(() => {
		props.languageCode && 
		props.languageCode !== languageCode &&
		setLanguageCode(props.languageCode);
	}, [props.languageCode]);
	
	useEffect(() => {
		props.canEdit &&
		props.canEdit !== canEdit &&
		setCanEdit(props.canEdit);
	}, [props.canEdit]);
	  
	  useEffect(() => {
		props.isSuper &&
		props.isSuper !== isSuper &&
		setIsSuper(props.isSuper);
	}, [props.isSuper]);
	
	useEffect(() => {
		if(props.objExists(props.task, 'users.linked') && 
			Array.isArray(props.task.users.linked) &&
			props.task.users.linked.length > 0) {
			setTaskUsers(props.task.users.linked);
		}
		if(props.objExists(props.task, 'users.groups') && 
			Array.isArray(props.task.users.groups) &&
			props.task.users.groups.length > 0) {
			setTaskGroups(props.task.users.groups);
		}
	}, [props.task]);
	
	useEffect(() => {
		if(props.users) {
			let staffList;
			if(taskUsers) {
				staffList = availableUsers(props.users, taskUsers);	
			}else{
				staffList = props.users;
			}
			if(staffList !== staff) {
				setStaff(staffList);
			}
		}
	}, [props.users, taskUsers]);
	
	useEffect(() => {
		if(props.groups) {
			let groupList;
			if(taskGroups) {
				groupList = availableGroups(props.groups, taskGroups);	
			}else{
				groupList = props.groups;
			}
			if(groupList !== groups) {
				setGroups(groupList);
			}
		}
	}, [props.groups, taskGroups]);
	
	useEffect(() => {
		if(!curSet) {
			setCurSet(staff ? 'staff' : groups ? 'groups' : null);
		}
	}, [staff, groups]);
	
	useEffect(() => {
		if(curSet) {
			switch(curSet) {
				case 'staff':
					staff &&
					staff !== curList &&
					setCurList(staff);
					break;
				case 'groups':
					groups && 
					groups !== curList &&
					setCurList(groups);
					break;
				default:
					break;
			}
		}
	}, [curSet, staff, groups]);
	
	useEffect(() => {
		
	}, [props.groups]);
	
	// FUNCTIONS
	function handleComplete() {
		if(props.actionComplete) {
			props.actionComplete();
		}
	}
	
	function unassignTask(taskID, coreUser = null, groupID = null) {
		let unassignParams = [
			{name: 'process', value: 'unassign_task'},
			{name: 'user_id', value: userID},
			{name: 'token', value: token},
			{name: 'task_id', value: taskID},
		];
		
		if(coreUser) {
			unassignParams.push({name: 'core_user', value: coreUser});
			setTaskUsers(taskUsers.filter(user => user.user_id !== coreUser));
		}else if(groupID) {
			unassignParams.push({name: 'group_id', value: groupID});
			setTaskGroups(taskGroups.filter(group => group.group_id !== groupID));
		}
		
		if(coreUser || groupID) {
			props[`${props.location ? props.location : 'admin'}LoadState`](unassignParams, 'tasks', `reload.${props.source ? props.source : 'tasks'}`);	
		}
	}
	
	function assignTask(taskID, coreUser = null, groupID = null) {
		let assignParams = [
			{name: 'process', value: 'assign_task'},
			{name: 'user_id', value: userID},
			{name: 'token', value: token},
			{name: 'task_id', value: taskID},
		];
		
		
		if(coreUser) {
			const userAssign = staff.find(user => user.user_id === coreUser);
			if(Array.isArray(taskUsers)) {
				setTaskUsers([...taskUsers, userAssign]);	
			}else{
				setTaskUsers([userAssign]);
			}
			assignParams.push({name: 'core_user', value: coreUser});
		}else if(groupID) {
			const groupAssign = groups.find(group => group.group_id === groupID);
			if(Array.isArray(taskGroups)) {
				setTaskGroups([...taskGroups, groupAssign]);	
			}else{
				setTaskGroups([groupAssign]);
			}
			assignParams.push({name: 'group_id', value: groupID});
		}
		console.log("ASSIGN PARAMS", assignParams);
		if(coreUser || groupID) {
			
			props[`${props.location ? props.location : 'admin'}LoadState`](assignParams, 'tasks', `reload.${props.source ? props.source : 'tasks'}`);
		}
	}
	
	function handleFilter(filter) {
		let userBase = availableUsers(props.users, taskUsers);
		 
		const filterNodes = filter.value && filter.value !== '' ? 
								filter.value.toLowerCase().split(' ') :
								[] ;
			
		if(userBase && filterNodes.length > 0) {
			setStaff(userBase.filter((user) => {
				let result = false;
				filterNodes.map(node => {
					if(node !== '' &&
						(user.contact_name_first.toLowerCase().indexOf(node) === 0 ||
						user.contact_name_last.toLowerCase().indexOf(node) === 0)) {
						result = true;
					}else if(node !== '') {
						result = false;
					}
				})
				return result;
			}));
		}else{
			setStaff(userBase);
		}
	}
	
	function availableUsers(usersAll, usersAssigned) {
		let filteredUsers;
		if(usersAssigned && 
			Array.isArray(usersAssigned) &&
			usersAssigned.length > 0) {
			const assignedIDs = usersAssigned.map(user => {
				if(user.user_id) {
					return user.user_id;
				}
			});
			if(assignedIDs && Array.isArray(assignedIDs)) {
				filteredUsers = usersAll.filter(user => !assignedIDs.includes(user.user_id));		
			}
		}
		if(filteredUsers) {
			return filteredUsers;
		}else{
			return usersAll;
		}
	}
	
	function availableGroups(groupsAll, groupsAssigned) {
		let filteredGroups;
		if(groupsAssigned && 
			Array.isArray(groupsAssigned) &&
			groupsAssigned.length > 0) {
			const assignedIDs = groupsAssigned.map(group => {
				if(group.group_id) {
					return group.group_id;
				}
			});
			if(assignedIDs && Array.isArray(assignedIDs)) {
				filteredGroups = groupsAll.filter(group => !assignedIDs.includes(group.group_id));		
			}
		}
		if(filteredGroups) {
			return filteredGroups;
		}else{
			return groupsAll;
		}
	}
	
	return (
		<div>
			<h3>{`${props.capitalizeString(props.task.task_parent_name ? `${props.task.task_parent_name} : ` : '', true)}${props.task.task_name ? props.capitalizeString(props.task.task_name, true) : props.objExists(props.task, `${languageCode}.task_name`) ? props.capitalizeString(props.task[languageCode].task_name, true) : `Task ${props.task.task_id}`}`}</h3>
			{taskUsers &&
				<Fragment>
					<h5>Current Task Users</h5>
					<div class="task-user-set">
						{taskUsers.map((user, index) => {
							return <TaskUser {...props}
											key={`taTU${index}`}
											taskID={props.task.task_id}
											user={user}
											actionRemove={unassignTask} />
						})}
					</div>
				</Fragment>
			}
			{taskGroups &&
				<Fragment>
					<h5>Current Task Groups</h5>
					<div class="task-user-set">
						{taskGroups.map((group, index) => {
							return <TaskGroup {...props}
											key={`taTU${index}`}
											taskID={props.task.task_id}
											group={group}
											languageCode={languageCode}
											actionRemove={unassignTask} />
						})}
					</div>
				</Fragment>
			}
			{staff && groups &&
				<div className="form-row">
					<span style={{alignSelf: 'baseline', marginRight: '1em'}} >Show </span>
					<div style={{display: 'inline-flex', width: '100%' }}>
					<Button icon="user" 
							label="Staff" 
							btnClass={`tiny${curSet === 'staff' ? ' active-element' : ''}`}
							btnAction={curSet !== 'staff' ? ()=>{setCurSet('staff')} : null} />
					<Button icon="group" 
							label="Groups" 
							btnClass={`tiny info-bg${curSet === 'groups' || !curSet ? ' active-element' : ''}`}
							btnAction={curSet !== 'users' ? ()=>{setCurSet('groups')} : null} />
					</div>
				</div>
			}
			<div>
				<div className="form-row">
					<p>Assign task to :</p>
					<Input {...props}
							name="user_filter"
							onChange={handleFilter}
							label={`${curSet === 'groups' ? 'Group' : "User's"} Name`} />
				</div>
				<div class="task-user-list">
				{curList && 
					Array.isArray(curList) && 
					curList.length > 0 &&
					curList.map((item, index) => {
						return (
							curSet === 'staff' ?
								<TaskUser {...props}
										key={`taTU${index}`}
										taskID={props.task.task_id}
										user={item}
										actionAssign={assignTask} />
							: 
								curSet === 'groups' &&
								<TaskGroup {...props}
									key={`taTU${index}`}
									taskID={props.task.task_id}
									group={item}
									actionAssign={assignTask}
									languageCode={languageCode} />
							
						)
					})
				}
				</div>
			</div>
			<div className="form-row">
				<Button icon="complete" btnClass="info-bg" label="Done" btnAction={handleComplete} />
			</div>
		</div>
	)
}