import React, {useEffect, useState} from 'react';

import {fileExists, setFilePath, localizeURL} from 'methods/site';

import IconSVG from 'components/icons/IconSVG';
import IconFile from 'components/icons/IconFile';

import * as utils from 'methods/site';

export default (props) => {
  props = {...props, ...utils}
  const [media, setMedia] = useState(null);
  const [mediaFileName, setMediaFileName] = useState(null);
  const [mediaPath, setMediaPath] = useState(null);
  const [mediaSource, setMediaSource] = useState(null);
  const [mediaFileTag, setMediaFileTag] = useState(null);
  const [tokenBG, setTokenBG] = useState(null);
  const [mediaPublished, setMediaPublished] = useState(null);
  const [preview, setPreview] = useState(null);
  
  useEffect(() => {
    if(props.media && props.media !== media) {
      setMedia(props.media);
    }
  }, [props.media]);
  
  useEffect(() => {
    if(media && media.media_file_name &&
      media.media_file_name !== mediaFileName) {
      setMediaFileName(media.media_file_name);
    }else if(!media && props.media_file_name &&
      props.media_file_name !== mediaFileName) {
      setMediaFileName(props.media_file_name);
    }
  }, [media, props.media_file_name]);
  
  useEffect(() => {
    if(media && media.media_file_path &&
      media.media_file_path !== mediaPath) {
      setMediaPath(media.media_file_path);
    }else if(!media && props.media_file_path &&
      props.media_file_path !== mediaPath) {
      setMediaPath(props.media_file_path);
    }
  }, [media, props.media_file_path]);
  
  useEffect(() => {
    if(media && media.media_file_tag) {
      media.media_file_tag !== mediaFileTag &&
      setMediaFileTag(media.media_file_tag);
    }else if(!media && props.media_file_tag) {
      props.media_file_tag !== mediaFileTag &&
      setMediaFileTag(media.media_file_tag);
    }else if(!media && !props.media_file_tag && mediaFileName) {
      const fileTag = mediaFileName.split('.')[mediaFileName.split('.').length-1];
      if(fileTag && fileTag !== mediaFileTag) {
        setMediaFileTag(fileTag);
      }
    }
  }, [media, mediaFileName, props.media_file_tag]);
  
  useEffect(() => {
    if(mediaFileName && ['jpg', 'jpeg', 'gif', 'png', 'svg'].includes(mediaFileTag)) {
      let mSource;
      if(mediaPath) {
        mSource = setFilePath(mediaFileName, utils.localizeURL(mediaPath));  
      }else{
        mSource = `https://aecore.app/${mediaFileName}`;
      }
      
      if(mSource !== mediaSource) {
        setMediaSource(mSource);
      }
    }else{
      setMediaSource(`https://aecore.app/assets/media/aeImageUnavailable.jpg`);
    }
  }, [mediaFileName, mediaPath, mediaFileTag]);
  
  useEffect(() => {
    if(media && media.media_published &&
      media.media_published !== mediaPublished) {
      setMediaPublished(media.media_published);
    }else if(!media && props.media_published &&
      props.media_published !== mediaPublished) {
      setMediaPublished(props.media_published);
    }
  }, [media, props.media_published]);

  useEffect(() => {
    mediaSource &&
    setTokenBG({ backgroundImage: `url('${mediaSource}')` });
  }, [mediaSource]);
  
  useEffect(() => {
    if(props.preview && mediaSource) {
      setPreview(
        <div className="modal-image-preview">
          <img src={mediaSource} />
        </div>
      )
    }
  }, [mediaSource, props.preview]);
  
  function handleClick(e) {
    if(props.action) {
      props.action(props.index);
    }else if(props.preview && 
              preview && 
              props.setModal){
      props.setModal(preview, true); 
    }
  }

  return (
    <div className={`media-token square${props.className ? ` ${props.className}` : ''}${!mediaPublished ? ' unpublished' : ''}${props.active ? ' active' : ''}`} onClick={handleClick}>
      {media && !['jpg', 'jpeg', 'png', 'gif', 'svg'].includes(media.media_file_tag) ?
        <IconFile icon={media.media_file_tag} className="object-fit-contain" />
      :
        mediaSource &&
        <img src={mediaSource} className="token-bg" />
      }
      {props.name}
      {!props.active &&
        <div className="media-token-screen"></div>  
      }
      {mediaFileName && !mediaPublished && <IconSVG className="overlay" icon="hide" />}
    </div>
  )
}
