import React, {useEffect, useState} from 'react';

import IconToken from 'components/icons/IconToken';
import FormHostAddEdit from 'components/forms/common_forms/FormHostAddEdit';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [isSuper, setIsSuper] = useState(null);
	const [userAccess, setUserAccess] = useState(null);
	const [host, setHost] = useState(null);
	const [organizations, setOrganizations] = useState(null);
	const [form, setForm] = useState(null);
	const [editActive, setEditActive] = useState(false);
	
	// HOOKS
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
		  setUserID(props.userID);
		}
	}, [props.userID]);
	  
	useEffect(() => {
		if(props.token && props.token !== token) {
		  setToken(props.token);
		}
	}, [props.token]);
	  
	useEffect(() => {
		if(props.isSuper && props.isSuper !== isSuper) {
	    	setIsSuper(props.isSuper);
		}
	}, [props.isSuper]);
	  
	useEffect(() => {
	  if(props.organizations && props.organizations !== organizations) {
		setOrganizations(props.organizations);
	  }
	}, [props.organizations]);
	
	useEffect(() => {
		if(props.userAccess && props.userAccess !== userAccess) {
			setUserAccess(props.userAccess);
		}
	}, [props.userAccess])
		
	useEffect(() => {
		if(props.host && props.host !== host) {
		  setHost(props.host);
		}
	}, [props.host]);
	
	useEffect(() => {
		if(userID && token && host) {
			setForm(
					<FormHostAddEdit {...props}
						userID={userID}
						token={token}
						host={host}
						organizations={organizations ? organizations : false}
						action={updateHost}
						actionCancel={()=>{setEditActive(false)}} />
			)
		}
	}, [userID, token, host, organizations, editActive])
	
	useEffect(() => {
		if(editActive && form) {
			props.setModal(form);
		}
	}, [editActive, form]);
	
	// FUNCTIONS
	function updateHost(params){
		props.setModal(null);
		setForm(null);
		setEditActive(false);
		props.adminLoadState(params, 'site', 'reload.hosts');
	}
	
	return (
		host &&
		<div className="list-item">
			<div className="list-item-body" onClick={!editActive ? ()=> setEditActive(true) : null}>
				<b>{host.host_name}</b> | points to: {host.organization_name}
			</div>
			<div className="list-item-ctl">
				<IconToken {...props}
							className="btn" 
							icon="edit" 
							tooltip="Edit Host" 
							showTooltip={true} 
							action={!editActive ? ()=>{setEditActive(true)} : null} />
			</div>
		</div>
	)
}