import React, {useEffect, useState, Fragment} from 'react';
import {Redirect} from 'react-router';

import IconToken from 'components/icons/IconToken';
import Link from 'components/navigation/Link';


export default (props) => {
  const [activityCleared, clearActivity] = useState(false);
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    if((props.activity && !props.activity.reqInfo) ||
        (props.activity && props.activity.reqInfo &&
          props.reqInfoComplete(props.activity.reqInfo, props.admin))) {
      clearActivity(true);
    }else{
      clearActivity(false);
    }
  }, [props.activity, props.admin]);
  
  function handleClick(module, isParent) {
    // setRedirect(`/${module[props.languageCode].module_link}`);
    if(!isParent && props.actionSelect && module.modules &&
        Array.isArray(module.modules) &&
        module.modules.length > 0) {
      props.actionSelect(module);
    }else if(isParent && props.actionSelect){
      props.actionSelect(module);
    }
  } 
  
  return (
    <div className={`admin-nav-option active${props.parent ? ' parent' : ''}${props.modulePath ? ' root' : ''}`} >
        {props.languageCode && props.module &&
          <Fragment>
            {props.module.module_parent > 0 ?
              <Link to={`${props.modulePath ? props.modulePath : ''}${!props.parent ? 
                      `/${props.module[props.languageCode].module_link}` : ''}`} 
                    action={()=>{handleClick(props.module, props.parent)}} >
          
              {props.parent &&
                <IconToken icon="arrow" className="rotate270" />
              }
              <div className="admin-nav-option-label">
                  {props.module[props.languageCode].module_name}
              </div>
              {!props.parent && props.module.modules &&
                <IconToken icon="arrow" className={props.parent ? 'rotate180' : 'rotate90'} />
              }
              </Link>
            :
              <div className="admin-nav-option-label">
                  {props.module[props.languageCode].module_name}
              </div>
            }
          </Fragment>
        }
    </div>
  )
}
