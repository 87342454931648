import React, {useEffect, useState} from 'react';

import FormDynamic from 'components/forms/FormDynamic';

export default (props) => {
  const [categories, setCategories] = useState(null);
  const [value, setValue] = useState(null);
  const [optionsCategories, setOptionsCategories] = useState(null);
  const [fieldsets, setFieldsets] = useState(null);
  const [form, setForm] = useState(null);
  
  useEffect(() => {
    if(Array.isArray(props.categories)) {
      setCategories(
        categoryList(props.categories).sort((a, b) => {
          return a.category_name > b.category_name ? 1 : -1;
        })
      );
    }
  }, [props.categories]);

  useEffect(() => {
    let catOptions = [];

    if(Array.isArray(categories)) {
      categories.map(cat => {
        if(!props.parentCategories || !Array.isArray(props.parentCategories) || !props.parentCategories.find(pCat => pCat.category_id === cat.category_id)) {
            catOptions.push(
              {option_name: props.capitalizeString(cat.category_name, true), option_value: cat.category_id}
            );
          }
        return true;
      })
    }
    
    if(Array.isArray(props.activeCategories)) {
      props.activeCategories.map(aCat => {
        catOptions = catOptions.filter(opt => opt.option_value !== aCat.category_id);
      })
    }

    let optionGroups = [
      {options: []}
    ];
    catOptions.map(opt => {
      optionGroups[0].options.push(opt);
    })
    
    setOptionsCategories(props.objClone(optionGroups));
  }, [categories]);

  useEffect(() => {
    if(optionsCategories) {
      setFieldsets([
        {fieldset_name: `${props.isChange ? 'Change' : 'Add'} Sub-Products`,
          fields: [
            {field_type: 'select',
              field_name: 'category_id',
              field_label: `Category`,
              field_value: null,
              field_placeholder: 'Select Category...',
              field_order: 1,
              option_groups: optionsCategories},
          ]
        }
      ]);
    }
  }, [optionsCategories]);

  useEffect(() => {
    fieldsets &&
    setForm(
      <FormDynamic
          content={fieldsets}
          name="add-sub-products"
          returnObject={true}
          fieldOnLoad={(e)=>{e.preventDefault()}}
          formActions={{formCancel: handleCancel, formSubmit: handleSubmit}} />
    )
  }, [fieldsets]);

  function categoryList(categories, parentName = '') {
    let catList = [];
    if(Array.isArray(categories)) {
      categories.map(cat => {
        cat = {...cat, category_name: `${parentName ? `${parentName} > ` : ''}${cat.category_name}`}
        if(!catList.includes(cat)) {
          catList.push(cat);
        }
        if(cat.sub_categories && 
            Array.isArray(cat.sub_categories)) {
            catList.push(...categoryList(cat.sub_categories, cat.category_name));
        }
      });
    }
    return catList;
  }

  function handleCancel() {
    props.setModal(null);
  }

  function handleSubmit(params) {
    if(props.action) {
      props.action(params);
    }
  }

  return (fieldsets && form)
}
