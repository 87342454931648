import React, {useEffect, useState} from 'react';

import Control from 'components/atoms/Control';
import Switch from 'components/forms/elements/Switch';
import FormFeatureAddEdit from 'components/forms/common_forms/FormFeatureAddEdit.js';
import IconToken from 'components/icons/IconToken';
import Feature from 'components/molecules/Feature';
import ModalConfirmation from 'components/molecules/ModalConfirmation';


export default (props) => {
	const [languageCode, setLanguageCode] = useState(null);
	const [slideModal, setSlideModal] = useState(null);
	
	
	// HOOKS
	useEffect(() => {
		if(!languageCode && props.language) {
			setLanguageCode(props.language);
		}else if(!languageCode){
			setLanguageCode('en');
		}
	}, [props.language]);
	
	// FUNCTIONS
	
	
	function previewFeature(feature) {
		props.setModal(
			<Feature {...props}
					content={feature}
					 />,
			true	
		);
	}
	
	function loadAddEditFeature(feature = null, lang) {
		props.setModal(
			<FormFeatureAddEdit 
				{...props}
				feature={feature ? feature : null}
				userID={props.userID}
				token={props.token}
				languageCode={languageCode}
				actionCancel={()=>{props.setModal(null)}}
				actionAddEdit={addEditFeature}
			/>
		)
	}
	
	function addEditFeature(params) {
		props.setModal(null);
		setSlideModal(null);
		console.log("Slide Params", params);
		props[`${props.location ? props.location : 'admin'}LoadState`](params, 'site', 'reload.persona');
	}
	
	function loadRemove(id, parent_id, link_type) {
		props.setModal(
			<ModalConfirmation {...props}
								message={`Are you sure you want to remove this feature?`}
								actionCancel={()=>{props.setModal(null)}}
								actionAccept={()=>{remove(id, parent_id, link_type)}} />
		);
	}
	
	function remove(id, parent_id, link_type) {
		props.setModal(null);
		const params = [
			{name: 'process', value: 'update_links'},
			{name: 'user_id', value: props.userID},
			{name: 'token', value: props.token},
			{name: 'link_type', value: link_type},
			{name: 'link_from', value: id},
			{name: 'link_to', value: parent_id},
			{name: 'link_field', value: 'link_removed'},
			{name: 'link_value', value: 'true'},
		]
		props[`${props.location}LoadState`](params, 'links', 'reload.persona');
	}
	
	function publish(value, args) {
		const params = [
			{name: 'process', value: 'update_links'},
			{name: 'user_id', value: props.userID},
			{name: 'token', value: props.token},
			{name: 'link_type', value: args.link_type},
			{name: 'link_from', value: args.link_from},
			{name: 'link_to', value: args.link_to},
			{name: 'link_field', value: 'link_published'},
			{name: 'link_value', value: value === true ? 'true' : 'false'},
		]
		props[`${props.location}LoadState`](params, 'links', 'reload.persona');
	}
	
	// console.log("PERSONA FEATURES", props.features);
	return (
		<div className="profile-component">
			<h5>Features</h5>
			<div className="profile-component-set">
				{props.features && 
					Array.isArray(props.features)  && 
					props.features.length > 0 &&
					props.features.map((feature, index) => {
						if(feature && props.objExists(feature, 'feature_id')) {
							return (
								<div className={`profile-component-feature${feature.feature_org_published ? ' published' : ' unpublished'}`}>
									<Switch label="Publish"
									value={feature.feature_org_published ? true : false}
									action={publish}
									params={{
										link_from: feature.feature_id,
										link_to: props.organizationID,
										link_type: 'featureToOrganization'
										}} />
									<Feature {...props} 
												feature={feature}
												languageCode={languageCode} />
									<div className="ctl">
										<IconToken icon="preview"
													action={()=>{previewFeature(feature)}}
													tooltip="Preview Feature" />
										<IconToken icon="edit"
													action={()=>{loadAddEditFeature(feature, languageCode)}}
													tooltip="Edit Feature" />
										<IconToken icon="trash"
													action={()=>{loadRemove(feature.feature_id, props.organizationID, 'featureToOrganization')}}
													   tooltip="Remove Feature" />
									</div>
									<IconToken icon={feature.feature_org_published ? 'eye' : 'hide'} className="icon-published" /> 
								</div>	
							)
						}
					})
				}
			</div>
			<Control icon="add" label="Add a feature" showLabel={true} action={loadAddEditFeature} />	
		</div>
	)
}