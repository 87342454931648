import React, {useEffect, useState, useRef, Fragment} from 'react';
import ReactDOM from 'react-dom';

import Loader from 'components/atoms/Loader';
import IconToken from 'components/icons/IconToken';
import IconSVG from 'components/icons/IconSVG';
import Button from 'components/forms/elements/Button';

export default (props) => {
  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);
  const [leader, setLeader] = useState(null);
  const [leapYear, setLeapYear] = useState(false);
  const [months, setMonths] = useState(null);
  const [dateCtl, setDateCtl] = useState(new Date());
  const [today, setToday] = useState(new Date());
  const [minDate, setMinDate] = useState(new Date());
  const [maxDate, setMaxDate] = useState(null);
  const [datePicker, setDatePicker] = useState(null);
  const [dpMonths, setDPMonths] = useState(null);
  const [showPrev, setShowPrev] = useState(false);
  const [showNext, setShowNext] = useState(false);
  const [inTrans, setInTrans] = useState(false);
  const [selectDate, setSelectDate] = useState(null);

  const refMths = useRef();

  const leapYearCtl = 2020;
  const weekdays = [
    {value: 0, name: 'Sunday', nameShort: 'Sun'},
    {value: 1, name: 'Monday', nameShort: 'Mon'},
    {value: 2, name: 'Tuesday', nameShort: 'Tue'},
    {value: 3, name: 'Wednesday', nameShort: 'Wed'},
    {value: 4, name: 'Thursday', nameShort: 'Thu'},
    {value: 5, name: 'Friday', nameShort: 'Fri'},
    {value: 6, name: 'Saturday', nameShort: 'Sat'},
  ]

  // HOOKS
  useEffect(() => {
    if(props.value && props.isDate(props.value)) {
      setDateCtl(props.setDate(props.value));
      if(inRange(props.setDate(props.value))) {
        setSelectDate(props.setDate(props.value));
      }else{
        setSelectDate(null);
      }
    }
  }, [props.value, minDate, maxDate]);


  useEffect(() => {
    if(props.minDate && props.isDate(props.minDate)) {
      setMinDate(props.setDate(props.minDate))
    }else{
      setMinDate(new Date());
    }
  }, [props.minDate]);
  
  useEffect(() => {
    if(minDate && minDate > dateCtl) {
        setDateCtl(minDate);
    } 
  }, [minDate]);

  useEffect(() => {
    if(props.maxDate) {
      // requires date to be passed in as YYYY-MM-DD
      if(props.maxDate.match(/^\d{4}[./-]\d{2}[./-]\d{2}$/)) {
        const dateNodes = props.getDateNodes(props.maxDate);
        setMaxDate(new Date(parseInt(dateNodes[0]),
                            parseInt(dateNodes[1])-1,
                            parseInt(dateNodes[2])));
        }
    }
  }, [props.maxDate]);

  useEffect(() => {
    if(dateCtl) {
      setLeapYear(
        dateCtl.getFullYear() === leapYearCtl || isLeapYear(dateCtl.getFullYear(), leapYearCtl) ?
          true : false
      )
    }
  }, [dateCtl]);

  useEffect(() => {
    setMonths([
      {value: 0, name: 'January', nameShort: 'Jan', days: 31},
      {value: 1, name: 'February', nameShort: 'Feb', days: leapYear ? 29 : 28},
      {value: 2, name: 'March', nameShort: 'Mar', days: 31},
      {value: 3, name: 'April', nameShort: 'Apr', days: 30},
      {value: 4, name: 'May', nameShort: 'May', days: 31},
      {value: 5, name: 'June', nameShort: 'Jun', days: 30},
      {value: 6, name: 'July', nameShort: 'Jul', days: 31},
      {value: 7, name: 'August', nameShort: 'Aug', days: 31},
      {value: 8, name: 'September', nameShort: 'Sep', days: 30},
      {value: 9, name: 'Oct', nameShort: 'Oct', days: 31},
      {value: 10, name: 'November', nameShort: 'Nov', days: 30},
      {value: 11, name: 'December', nameShort: 'Dec', days: 31},
    ]);
  }, [leapYear]);

  useEffect(() => {
    if(refMths.current && !refMths.current.style.height && document.getElementById('dpMthCur')) {
      refMths.current.style.height = `${document.getElementById('dpMthCur').offsetHeight}px`;
    }
  }, [datePicker])

  useEffect(() => {
    if(dpMonths) {
      const dpContainer = <div className="date-picker">
                            <div id="date-picker-months" ref={refMths} >
                              {dpMonths}
                            </div>
                            <div className="date-picker-base">
                              <div className="date-picker-display">
                                  <IconSVG icon="date" />
                                  {selectDate ? <span>{props.dateDisplay(selectDate, props.language_code)}</span> : 'Select a date'}
                              </div>
                              <div className="date-picker-ctl">
                                <Button icon="cancel" label="Reset" btnClass="mini" btnAction={handleCancel} />
                                <Button {...props} icon="check" id="dp-select-btn" label="Select" btnClass="mini success-bg" btnAction={handleSelect} disabled={selectDate ? false : true} />
                              </div>
                            </div>
                          </div>;
      setDatePicker(dpContainer);
    }
  }, [dpMonths]);

  useEffect(() => {
    if(months && dateCtl) {
      setDPMonths(buildMonth(dateCtl.getMonth(), dateCtl.getFullYear()));
    }
  }, [dateCtl, months, selectDate]);

  useEffect(() => {
    if(datePicker && showPrev) {
      setShowPrev(false);
      setDPMonths([buildMonth(dateCtl.getMonth() === 0 ? 11 : dateCtl.getMonth()-1, dateCtl.getMonth() === 0 ? dateCtl.getFullYear() -1 : dateCtl.getFullYear(), 'prev'),
                  buildMonth(dateCtl.getMonth(), dateCtl.getFullYear())]);
      setTimeout(() => {
        const curNode = refMths.current.querySelectorAll('#dpMthCur')[0];
        const prevNode = refMths.current.querySelectorAll('#dpMthPrev')[0];

        refMths.current.style.height = `${document.getElementById('dpMthPrev').offsetHeight}px`;

        curNode.classList.add('next');
        curNode.classList.add('in-trans');
        prevNode.classList.add('in-trans');

        setTimeout(() => {
          curNode.classList.remove('active');
          curNode.classList.remove('in-trans');
          prevNode.classList.remove('prev');
          prevNode.classList.remove('in-trans');
          prevNode.classList.add('active');
          prevNode.setAttribute('id', 'dpMthCur');
          setDateCtl(new Date(dateCtl.getMonth() === 0 ? dateCtl.getFullYear()-1 : dateCtl.getFullYear(), dateCtl.getMonth() === 0 ? 11 : dateCtl.getMonth()-1, 1));

        }, 500);
      }, 10);
    }
  }, [showPrev, dateCtl]);

  useEffect(() => {
    if(datePicker && showNext) {
      setDPMonths([buildMonth(dateCtl.getMonth(), dateCtl.getFullYear()),
                  buildMonth(dateCtl.getMonth() === 11 ? 0 : dateCtl.getMonth()+1, dateCtl.getMonth() === 11 ? dateCtl.getFullYear()+1 : dateCtl.getFullYear(), 'next')]);
    }
  }, [showNext]);

  useEffect(() => {
    if(dpMonths && showNext) {
      setShowNext(false);
      setTimeout(() => {
        const curNode = refMths.current.querySelectorAll('#dpMthCur')[0];
        const nextNode = refMths.current.querySelectorAll('#dpMthNext')[0];

        refMths.current.style.height = `${document.getElementById('dpMthNext').offsetHeight}px`;

        curNode.classList.add('prev');
        curNode.classList.add('in-trans');
        nextNode.classList.add('in-trans');

        setTimeout(() => {
          curNode.classList.remove('prev');
          setDateCtl(new Date(dateCtl.getMonth() === 11 ? dateCtl.getFullYear()+1 : dateCtl.getFullYear(), dateCtl.getMonth() === 11 ? 0 : dateCtl.getMonth()+1));

        }, 500);
      }, 10);
    }
  }, [dpMonths, showPrev, showNext]);



  // FUNCTIONS
  function isLeapYear(year, ctl) {
    const dif = parseInt(year) > parseInt(ctl) ?
                parseInt(year) - parseInt(ctl) :
                parseInt(ctl) - parseInt(year);
    if(dif < 4) {
      return false;
    }else{
      const difFactor = dif/4;
      if(difFactor - Math.floor(difFactor) > 0) {
        return false;
      }else{
        return true;
      }
    }
  }

  function monthPrev() {
    setShowPrev(true);
  }

  function monthNext() {
    setShowNext(true);
  }

  function handleDate(date) {
    if(props.isDate(date)) {
      setSelectDate(props.setDate(date));
    }
  }


  function buildMonth(mth, yr, pos = null) {
    const monthStart = new Date();
    monthStart.setDate(1);
    monthStart.setMonth(mth);
    monthStart.setFullYear(yr);

    return (
      <div key={`dpm${monthStart}`} className={`date-picker-month${pos ? ` ${pos}` : ' active'}`}
            id={pos === 'prev' ? 'dpMthPrev' : pos === 'next' ? 'dpMthNext' : 'dpMthCur'}>
        <div className="date-picker-header">
          <IconToken icon="arrow" className="rotate270" action={monthPrev} />
          <h4>{months[monthStart.getMonth()].name} {monthStart.getFullYear()}</h4>
          <IconToken icon="arrow" className="rotate90" action={monthNext} />
        </div>
        <div className="date-picker-week header">
          {weekdays && weekdays.map((weekday, index) => {
            return <div key={`dpwdk${index}`} className="date-picker-day">
                      {weekday.name[0]}
                  </div>
            })
          }
        </div>
        {buildWeeks(monthStart.getMonth(), yr)}
      </div>
    )
  }

  function buildWeeks(mth, yr) {
    const monthStart = new Date();
    monthStart.setDate(1);
    monthStart.setMonth(mth);
    monthStart.setFullYear(yr);
    const startDay = monthStart.getDay();
    const days = Math.ceil(months[monthStart.getMonth()].days);
    const wks = Math.ceil((months[monthStart.getMonth()].days+startDay)/7);


    let d = 1;
    let w = 1;
    let wd = 1;
    let ld = 1;
    let picker = [];
    let leaderDays = [];

    // BUILD LEADERS
    if(startDay && startDay > 0) {
      for(var i = 0; i < startDay; i++) {
        leaderDays.push(<div key={`dpldk${i}`} className="date-picker-day leader"></div>);
      }
    }

    let curDay = 1;
    for(w = 1; w <= wks; w++) {
      let weekdays = [];
      if(w === 1 && leaderDays.length > 0) {
        wd = leaderDays.length;
        weekdays = [...leaderDays];
      }else{
        wd = 0;
      }
      for(i = wd; i < 7; i++) {
        const curDate = new Date(yr, mth, curDay);
        const activeDate = inRange(curDate);
        if(curDay <= days) {
          weekdays.push(
            <div key={`dpwdk2${i}`} className={`date-picker-day${activeDate ? ` selector` : ''}${isToday({year: yr, month: mth, date: curDay}) ? ' today' : ''}${selectDate && selectDate.getTime() === curDate.getTime() ? ' selected' : ''}`}
                  onClick={activeDate ? ()=>{handleDate(curDate)} : null} >
              {curDay}
            </div>);
        }else{
          weekdays.push(
            <div key={`dpwdk2${i}`} className="date-picker-day leader">

            </div>);
        }
        curDay++;
      }

      picker.push(<div key={`dpp${w}`} className="date-picker-week">{weekdays}</div>);
    }

    return picker;
  }

  function isToday(date) {
    if(today.getFullYear() === date.year &&
        today.getMonth() === date.month &&
        today.getDate() === date.date) {
          return true;
        }else{
          return false;
        }
  }

  function inRange(date) {
    if(props.isDate(date)) {
      let rangeMin = false;
      let rangeMax = false;
      if(props.allowPast) {
        rangeMin = true;
      }else if(minDate) {
        if(date.getTime() >= minDate.getTime()) {
            rangeMin = true;
        }
      }
      if(!maxDate) {
        rangeMax = true;
      }else if(maxDate) {
        if(date.getTime() <= maxDate.getTime()) {
            rangeMax = true;
        }
      }

      if(rangeMin && rangeMax) {
        return true;
      }else{
        return false;
      }
    }else{
      return false;
    }
  }

  function handleSelect() {
    if(props.actionSelect && selectDate) {
      props.actionSelect(`${selectDate.getFullYear()}-${(parseInt(selectDate.getMonth())+1).toString().padStart(2,0)}-${selectDate.getDate().toString().padStart(2,0)}`);
    }
  }

  function handleCancel() {
    if(props.actionCancel) {
      props.actionCancel();
    }
  }
  
  return (
    <Fragment>
      {datePicker ?
        <Fragment>
          {datePicker}
        </Fragment>
      :
        <Loader />
      }
    </Fragment>
  )
}
