import React, { useEffect, useState, useRef } from 'react';

export default (props) => {
  const [switchValue, setSwitchValue] = useState(false);
  const [click, setClick] = useState(null);
  const switchTarget = useRef();

  useEffect(() => {
    setSwitchValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if(switchTarget && switchValue === true) {
      !switchTarget.current.classList.contains('active') && switchTarget.current.classList.add('active');
      switchTarget.current.classList.contains('inactive') && switchTarget.current.classList.remove('inactive');
    }else{
      switchTarget.current.classList.contains('active') && !switchTarget.current.classList.contains('inactive') &&
        switchTarget.current.classList.add('inactive');
      switchTarget.current.classList.contains('active') && switchTarget.current.classList.remove('active');
    }

    if(props.onChange && click) {
      setClick(false);
      props.onChange({name: props.name, value: switchValue}); 
    }
  }, [switchValue, switchTarget]);
  
  // FUNCTIONS
  function handleClick(e) {
    setClick(true);
    if(!switchValue) {
      setSwitchValue(true);
      if(props.action) {
        props.action(true, props.params ? props.params : null);
      }
    }else{
      setSwitchValue(false);
      if(props.action) {
        props.action(false, props.params ? props.params : null);
      }
    }
  }
  
  
  return (
      <div className={`form-switch-wrapper${props.className ? ` ${props.className}` : ''}`}>
        <div className="form-switch" onClick={handleClick} ref={switchTarget} >
          <div className="form-switch-lever"></div>
          <input type="hidden" name={props.name} value={switchValue} />
        </div>
        {props.label && <div className="form-switch-label">{props.label}</div>}
      </div>
  )
}
