import React, {useEffect, useState, Fragment} from 'react';
import {useParams, useLocation} from 'react-router';

import FormLogin from 'components/forms/common_forms/FormLogin';

import * as utils from 'methods/site';

export default (props) => {
	props = {...props.children, ...utils};
	const pgParams = useParams();
	const [organizationID, setOrganizationID] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [loginComponent, setLoginComponent] = useState(null);
	const [loginTask, setLoginTask] = useState(null);
	const [loginCode, setLoginCode] = useState(null);
	const [loginMsg, setLoginMsg] = useState(null);
	
	const pgLocation = useLocation();
	
	// HOOKS
	useEffect(() => {
		if(pgParams.task && loginTask !== pgParams.task) {
			setLoginTask(pgParams.task);
		}else if(!pgParams.task && loginTask) {
			setLoginTask('login');
		}
	}, [pgParams]);
	
	useEffect(() => {
		if(props.objExists(props.site, 'persona.organization_id') &&
			props.site.persona.organization_id !== organizationID) {
			setOrganizationID(props.site.persona.organization_id);
		}
		
		if(props.objExists(props.site, 'site_language') &&
			props.site.site_language !== languageCode) {
				setLanguageCode(props.site.site_language);
			}else if(!props.objExists(props.site, 'site_language') &&
					!languageCode) {
				setLanguageCode('en');
			}
	}, [props.site]);
	
	useEffect(() => {
		if(props.objExists(props.user, 'user_id')) {
			
		}
	}, [props.user]);
	
	useEffect(() => {
		if(pgLocation.search) {
			const searchParams = props.getURLSearchParams(pgLocation.search);
			
			if(searchParams.key && loginCode !== searchParams.key) {
				setLoginCode(searchParams.key);
			}else if(!searchParams.key && loginCode) {
				setLoginCode(null);
			}
			
		}
	}, [pgLocation])
	
	return (
		<Fragment>
			{loginMsg}
			{organizationID && 
			languageCode &&
			<FormLogin {...props}
						loginTask={loginTask} 
						loginCode={loginCode}
						actionMsg={setLoginMsg}
						organizationID={organizationID}
						languageCode={languageCode}
						fullService={true}
						isModal={false} />
			}
		</Fragment>
	)
}