import React, {useEffect, useState, Fragment} from 'react';

import OptionMap from 'components/profiles/common/OptionMap';
import FormModuleAddEdit from 'components/forms/common_forms/FormModuleAddEdit';
import Button from 'components/forms/elements/Button';

export default(props) => {
  const [modules, setModules] = useState(null);
  const [options, setOptions] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [optionIndex, setOptionIndex] = useState(0);

  // HOOKS
  useEffect(() => {
    loadSiteModules();
  }, []);
  

  useEffect(() => {
    if(props.objExists(props.admin, 'reload.modules') &&
      props.admin.reload.modules === true) {
      loadSiteModules();
      props.adminDeleteVal('reload.modules');
    }
  }, [props.admin.reload])

  useEffect(() => {
    if(props.languageCode) {
      setLanguageCode(props.languageCode);
    }else if(props.siteLanguage) {
      setLanguageCode(props.siteLanguage);
    }else{
      setLanguageCode('en');
    }
  }, [props.languageCode, props.siteLanguage]);

  useEffect(() => {
    console.log("props admins modules have changed", props.admin.modules);
    if(languageCode && props.objExists(props.admin, 'modules')) {
      setModules(loadModules(props.admin.modules));
      setOptions(loadOptions(props.admin.modules));
    }
  }, [props.admin.modules, props.admin.reload, languageCode]);

  // FUNCTIONS
  // LOAD SITE MODULES TO ADMIN STATE
  function loadSiteModules() {
    const params = [
      {name: 'process', value: 'get_site_modules'}
    ]
    props.adminLoadState(params, 'site', 'modules');
  }

  // LOAD MODULES INTO FLAT ARRAY FOR ACCESS
  function loadModules(modValue) {
    if(Array.isArray(modValue)) {
       let result = [];
       modValue.map(module => {
         result.push(module);
         if(module.modules && (Array.isArray(module.modules) || module.modules.module_id)) {
           result = [...result, ...loadModules(module.modules)];
         }
       });
       return result;
     }else if(modValue && Object.entries(modValue).length > 0 &&  modValue.module_component) {
      return [module, ...loadModules(modValue.modules)];
    }else{
      return null;
    }
  }

  // LOAD MODULES INTO OPTION MAP COMPATIBLE SET
  function loadOptions(modValue) {
    if(Array.isArray(modValue)) {
       let result = [];
       modValue.map(module => {
         // console.log("MODULE", languageCode, module);
         result.push({
           option_name: module[languageCode].module_name,
            option_id: module.module_id,
            option_order: module.module_order,
            option_parent: module.module_parent,
            options: loadOptions(module.modules),
         });
       });
       return result;
     }else if(modValue && Object.entries(modValue).length > 0 &&  modValue.module_component) {
      return {option_name: modValue[props.siteLanguage].module_name,
              option_id: modValue.module_id,
              option_order: modValue.module_order,
              option_parent: modValue.module_parent,
              options: loadOptions(modValue.modules),};
    }else{
      return null;
    }
  }

  function loadAddEditModule(module_id = null, module_parent = null) {
    let module = null;
    if(module_id && parseInt(module_id) > 0) {
      module = modules.find(module => module.module_id === module_id);

    }
    props.setModal(<FormModuleAddEdit {...props}
                      module={module ? module : null}
                      module_parent={module_parent}
                      modules={modules}
                      languageCode={languageCode}
                      action={addEditModule} />);

  }

  function addEditModule(params) {
    props.setModal(null);
    params = [
      ...params,
      {name: 'user_id', value: props.userID},
      {name: 'token', value: props.token},
      {name: 'return_bool', value: true}
    ]

    console.log("Params for Modules", params);

    props.adminLoadState(params, 'site', 'reload.modules');
  }

  function loadRemoveModule(module_id) {
    console.log("Gonna remove the module", module_id);
  }

  function moveModule(option_id, params) {
    const moveParams = [
      {name: 'module_id',  value: option_id},
      {name: 'module_parent', value: params.option_parent},
      {name: 'module_order', value: params.option_order},
      {name: 'process', value: 'update_site_module_order'},
      {name: 'user_id', value: props.userID},
      {name: 'token', value: props.token},
      {name: 'return_bool', value: true}
    ]

    console.log("Move Params", moveParams);
    props.adminLoadState(moveParams, 'site', 'reload.modules');
  }

  console.log('Modules Admin', props.admin);
  return (
    <Fragment>
      <h2>Modules</h2>
      {options && 
        Array.isArray(options) && 
        options.length > 1 &&
        <div className="form-row">
          {options.map((opt, index) => {
            return <Button {...props}
                      btnClass="mini" 
                      btnAction={()=>{setOptionIndex(index)}} 
                      label={opt.option_name} />
          })
        }
        </div>
      }
      {options && optionIndex >= 0 && 
        <OptionMap {...props}
                      options={options[optionIndex]}
                      option_type="Module"
                      actionEdit={loadAddEditModule}
                      actionRemove={loadRemoveModule}
                      actionAdd={loadAddEditModule}
                      actionMove={moveModule} />}

    </Fragment>
  )
}
