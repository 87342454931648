import React, {useEffect, useState, Fragment} from 'react';

import IconToken from 'components/icons/IconToken';
import IconToggle from 'components/icons/IconToggle';
import IconSVG from 'components/icons/IconSVG';
import Link from 'components/navigation/Link';
import GroupList from 'components/profiles/groups/GroupList';
import FormGroupAddEdit from 'components/forms/common_forms/FormGroupAddEdit';
import FormGroupAddUser from 'components/forms/common_forms/FormGroupAddUser';
import ModalConfirmation from 'components/molecules/ModalConfirmation';

export default (props) => {
	const [active, setActive] = useState(false);
	
	// HOOKS
	
	// FUNCTIONS 
	function loadEditGroup() {
		props.setModal(
			<FormGroupAddEdit {...props}
				userID={props.userID}
				token={props.token}
				coreOrg={props.coreOrg}
				languageCode={props.languageCode}
				group={props.group}
				actionSubmit={editGroup} />
		)
	}
	
	function editGroup(params) {
		props.setModal(null);
		props[`${props.stateLocation ? props.stateLocation : 'admin'}LoadState`](params, 'groups', 'reload.groups.user');
	}
	
	function loadRemoveGroup() {
		props.setModal(
			<ModalConfirmation {...props}
					message={`Are you shure you want to remove <b>${props.group[props.languageCode].group_name}</b>? This will permanently remove it and any subgroups from Groups.`}
					icon="group"
					actionAccept={removeGroup} />
		)
	}
	
	function removeGroup() {
		props.setModal(null);
		const removeParams = [
			{name: 'process', value: 'update_links'},
			{name: 'link_from', value: props.group.group_id},
			{name: 'link_to', value: props.coreOrg},
			{name: 'link_type', value: 'groupToOrganization'},
			{name: 'link_field', value: 'link_removed'},
			{name: 'link_value', value: true},
			{name: 'user_id', value: props.userID},
			{name: 'token', value: props.token}
		];
		props[`${props.stateLocation ? props.stateLocation : 'admin'}LoadState`](removeParams, 'links', 'reload.groups.user');
	}
	
	function loadAddUser() {
		props.setModal(
			<FormGroupAddUser {...props}
					userID={props.userID}
					token={props.token}
					coreOrg={props.coreOrg}
					languageCode={props.languageCode}
					staff={props[`${props.stateLocation ? props.stateLocation : 'admin'}`].staff.users}
					users={props.coreOrg > 1 && props[`${props.stateLocation ? props.stateLocation : 'admin'}`].users}
					group={props.group}
					stateLocation={props.stateLocation}
					source="groups.user"
					actionComplete={()=>{props.setModal(null)}} />
		)
	}
 	
	// console.log("GROUP ITEM", props.group, props.languageCode);
	return (
		<Fragment>
			<div className="flex-column valign-center" style={{gridColumn: 1}}>
				<Link to={`${props.location ? props.location : ''}/groups/group/${props.group.group_id}`} >
					<b>
						{props.objExists(props.group, `${props.languageCode}.group_name`) ?
							props.group[props.languageCode].group_name : 
							props.objExists(props.group, 'group_name') ?
								props.group.group_name : 'Group Name'
						}
					</b>
				</Link>
			</div>
			<div className="flex-column valign-center">
			{props.group.users && 
				props.group.users.length === 1 ? 
					'1 User' : `${props.group.users ? props.group.users.length : 'No'} Users`
				}
			</div>
			<div className="flex-column valign-center">
				{props.group.subgroups &&
				Array.isArray(props.group.subgroups) &&
				props.group.subgroups.length > 0 &&
					<Fragment>
						{props.group.subgroups.length === 1 ? 
							'1 Subgroup' : 
							`${props.group.subgroups.length} Subgroups`
						}
						<IconToken
							className="inline-ctl"
							icon={active ? 'collapse' : 'expand'}
							tooltip={active ? 'Hide subgroups' : 'Show subgroups'}
							action={()=>{setActive(active ? false : true)}} />
					</Fragment>
				}
			</div>
			<div className="ctl">
				{props.isAdmin &&
					<Fragment>
						<IconToken icon="user-add" 
								className="btn"
								tooltip="Add User" 
								showTooltip={true}
								action={loadAddUser} />
						<IconToken icon="edit" 
									className="btn"
									tooltip="Edit" 
									showTooltip={true}
									action={loadEditGroup} />
						<IconToken icon="trash"
									className="btn"
									tooltip="Remove" 
									showTooltip={true}
									action={loadRemoveGroup} />
					</Fragment>
				}
			</div>
			{props.group.subgroups &&
				Array.isArray(props.group.subgroups) &&
				props.group.subgroups.length > 0 &&
				<div className={`expandable${active ? ' active' : ''}`} style={{gridColumn: '1 / 5'}}>
					<h4>Subgroups</h4>
					<GroupList {...props}
								languageCode={props.languageCode}
								groupType="user"
								groups={props.group.subgroups}
								isSub={true}
								isAdmin={true} />
				</div>
			}
		</Fragment>
	)
}