import React, {useEffect, useState, Fragment} from 'react';

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import IconToken from 'components/icons/IconToken';
import MediaFileToken from 'components/profiles/media/MediaFileToken';
import Control from 'components/atoms/Control';
import FormSendMessage from 'components/forms/common_forms/FormSendMessage';
import MessageList from 'components/profiles/messages/MessageList';
import ModalConfirmation from 'components/molecules/ModalConfirmation';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [message, setMessage] = useState(null);
	const [messageSubject, setMessageSubject] = useState(null);
	const [messageBody, setMessageBody] = useState(null);
	const [messageDate, setMessageDate] = useState(null);
	const [author, setAuthor] = useState(null);
	const [initials, setInitials] = useState(null);
	const [replies, setReplies] = useState(null);
	const [task, setTask] = useState(null);
	const [taskName, setTaskName] = useState(null);
	const [projectID, setProjectID] = useState(null);
	const [orderID, setOrderID] = useState(null);
	const [itemID, setItemID] = useState(null);
	const [itemName, setItemName] = useState(null);
	const [organization, setOrganization] = useState(null);
	const [repliesActive, setRepliesActive] = useState(null);
	const [media, setMedia] = useState(null);
	const [messageRead, setMessageRead] = useState(null);
	const [messageArchived, setMessageArchived] = useState(null);
	const [messageMove, setMessageMove] = useState(null);
	
	useEffect(() => {
		setMessage(null);
		setMessageRead(null);
		setMessageMove(null);
		setMessageMove(null);
		setTask(null);
		setTaskName(null);
		setProjectID(null);
		setOrderID(null);
		setItemID(null);
		setMedia(null);
	}, []);
	
	useEffect(() => {
		if(props.userID &&
			props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token &&
			props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.languageCode &&
			props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.message &&
			props.message !== message) {
			setMessage(props.message);
		}
	}, [props.message]);
	
	useEffect(() => {
		if(message) {
			if(props.objExists(message, `message_subject`) &&
				message.message_subject !== messageSubject) {
				setMessageSubject(message.message_subject);
			}	
			
			if(props.objExists(message, `message_body`) &&
				message.message_body !== messageBody) {
				setMessageBody(message.message_body);
			}	
			
			if(props.objExists(message, `message_created`) &&
				message.message_created !== messageDate) {
					setMessageDate(message.message_created);
			}
	
			if(props.objExists(message, `message_author`) &&
				message.message_author !== author) { 
				setAuthor(message.message_author);	
			}else if(!props.objExists(message, `message_author`) &&
						props.objExists(message, `contact_name_first`) &&
						`${props.objExists(message, `contact_name_first`)} ${props.objExists(message, `contact_name_last`)}` !==
						author) {
				setAuthor(`${props.objExists(message, `contact_name_first`)} ${props.objExists(message, `contact_name_last`)}`)
			}
			if(message.message_read && !messageRead) {
				setMessageRead(true);
			}
			
			if(message.message_archived && !messageArchived) {
				setMessageArchived(true);
			}
			
			if(Array.isArray(message.media) && 
				message.media.length > 0 && 
				message.media !== media) {
			 	setMedia(message.media);	
			}
			
			if(message.task && message.task !== task) {
				setTask(message.task);
			}
		}
	}, [message, languageCode]);
	
	useEffect(() => {
		if(author) {
			const authorNodes = author.split(' ');
			let authorInitials = '';
			authorNodes.map(name => {
				authorInitials += name.substring(0, 1);
			});
			if(authorInitials !== '') {
				setInitials(authorInitials);
			}
		}
	}, [author]);
	
	useEffect(() => {
		if(message) {
			if(Array.isArray(message.replies) &&
				message.replies !== replies) {
				setReplies(message.replies);		
			}
		}
	}, [message]);
	
	useEffect(() => {
		if(task) {
			if(task.project_id &&
				task.project_id !== projectID) {
				setProjectID(task.project_id);
			}
			
			if(task.order_id &&
				task.order_id !== orderID) {
				setOrderID(task.order_id);
			}
			
			if(task.item_id &&
				task.item_id !== itemID) {
				setItemID(task.item_id);
			}
			
			if(task.item_name) {
				let iName = task.item_name;
				if(task.item_nickname) {
					iName += ` (${task.item_name})`;
				}
				if(iName !== itemName) {
					setItemName(iName);
				}
			}
			
			if(task.organization_id) {
				let tOrg = {
					organization_id: task.organization_id,
					organization_name: task.organization_name,
					organization_code: task.organization_code
				};
				if(tOrg !== organization) {
					setOrganization(tOrg)
				}
			}
			
			if(task.task_name && task.task_name !== taskName) {
				setTaskName(task.task_name);
			}
		}
	}, [task]);
	
	// FUNCTIONS 
	function loadReply() {
		props.setModal(
			<FormSendMessage {...props}
				messageParent={message.message_id}
				messageSubject={props.objExists(message, `${languageCode}.message_subject`) ?
								`RE: ${props.capitalizeString(message[languageCode].message_subject)}` :
									null}
				userID={userID}
				token={token}
				languageCode={languageCode}
				location={props.location}
				stateLocation={props.stateLocation}
				actionCancel={()=>{props.setModal(null)}}
				actionSubmit={reply} />
		);
	}
	
	function reply(params) {
		props.setModal(null);
		props[`${props.location ? props.location : 'dashboard'}LoadState`](params, 'messages', `reload.${props.stateLocation ? props.stateLocation : 'messages'}`);
	}
	
	function markMessageRead(value) {
		setMessageRead(value);
		setMessageMove(true);
		const readParams = [
			{name: 'process', value: 'set_message_receipt'},
			{name: 'user_id', value: userID},
			{name: 'token', value: token},
			{name: 'message_id', value: message.message_id},
			{name: 'message_read', value: value ? 1 : 0}
		];
		console.log("RECEIPT PARAMS", readParams);
		props.userLoadState(readParams, `messages`, `reload.messages`);
	}
	
	function confirmArchive() {
		props.setModal(
			<ModalConfirmation {...props}
				icon="archive"
				message={`<div>
							<p><b>Archive this message?</b></p>
							<p>The message will be hidden from your list</p>
						</div>`}
				actionAccept={archiveMessage}
				/>
		);
	}
 	
	function archiveMessage() {
		setMessageArchived(true);
		setMessageMove(true);
		props.setModal(null);
		const archiveParams = [
			{name: 'process', value: 'set_message_receipt'},
			{name: 'user_id', value: userID},
			{name: 'token', value: token},
			{name: 'message_id', value: message.message_id},
			{name: 'message_read', value: 1},
			{name: 'message_archived', value: 1}
		];
		console.log("RECEIPT PARAMS", archiveParams);
		props.userLoadState(archiveParams, `messages`, `reload.messages`);
	}
	
	function confirmRemove() {
		props.setModal(
			<ModalConfirmation {...props}
				icon="trash"
				message={`<p><b>Remove this message?</b></p>
						<p>The message will be permanently removed from the system</p>`}
				actionAccept={removeMessage}
				/>
		);
	}
	
	function removeMessage() {
		setMessageArchived(true);
		setMessageMove(true);
		props.setModal(null);
		const removeParams = [
			{name: 'process', value: 'remove_message'},
			{name: 'user_id', value: userID},
			{name: 'token', value: token},
			{name: 'message_id', value: message.message_id}
		];
		console.log("RECEIPT PARAMS", removeParams);
		props.userLoadState(removeParams, `messages`, `reload.messages`);
	}
	
	// message && message.message_id === 74 &&
	// console.log("MESSAGE TASK", message, task, media);
	
	return (
		message &&
		<div className={`widget-message${messageRead ? ` read` : ''}${messageArchived ? ` archived` : ''}${messageMove ? ` move` : ''}`}>
			<div className={`user-initials${userID === message.user_id ? ' owner' : '' } `}>
				{initials}
			</div>
			<div className="widget-message-contents message-bubble">
				<div className="message-bubble-body">
				{itemName && 
					<div className="subtext">
						{`RE: ${itemName}`}
					</div>	
				}
				
				{props.objExists(message, `message_subject`) &&
					<div className="subject">
						{message.message_urgent > 0 &&
							<IconToken icon="flame"
								className="message-flag error" />
						}
						{props.capitalizeString(message.message_subject, true)}
					</div>
				}
				<div className="message-body">
					{messageBody && ReactHtmlParser(props.encodeHTML(messageBody))}
				</div>
				{media &&
					<div className="message-media">
						<div>Files</div>
						{media.map((file, index) => {
							return(
								<div className="message-media-file">
							 		<MediaFileToken
								 		{...props} 
								 		key={`mdaTkn-${message.message_id}-${index}`}
										file={file} />
									<div className="truncate">{`${file.media_file_upload_name}`}</div>
								</div>
							)
						})}
					</div>
				}
				<div className="subtext">
					<b>{author ? author : 'Core User '}</b><br />
					{messageDate && `${props.dateDisplay(messageDate)} ${props.timeDisplay(messageDate, false)}`}
				</div>
				
				
				{!props.noReply &&
					<div className="ctl-row">
						<IconToken
							icon="message-reply"
							tooltip="Reply"
							action={loadReply} />
						<IconToken
							icon={message.message_read > 0 ? 'hide' : 'eye'}
							tooltip={message.message_read > 0 ? 'Mark as Unread' : 'Mark as Read'}
							action={()=>{markMessageRead(message.message_read > 0 ? 0 : 1)}} />
						<IconToken
							icon="archive"
							tooltip="Archive"
							action={confirmArchive} />
						{message.user_id === userID &&
							<IconToken
								icon="trash"
								tooltip="Remove"
								action={confirmRemove} />	
						}
					</div>
				}
				{replies && 
				<Fragment>
					<div className={`message-replies expandable${repliesActive ? '' : ' active'}`}>
						<Control 
							icon="messages-view" 
							iconClass="no-btn" 
							label={`Show ${replies.length} ${replies.length === 1 ? 'Reply' : 'Replies'}`}
							showLabel={true}
							action={()=>{setRepliesActive(repliesActive ? false : true)}} />
					</div>
					<div className={`message-replies expandable${repliesActive ? ' active' : ''}`}>
						<MessageList {...props}
							userID={userID}
							token={token}
							languageCode={languageCode}
							location={props.location}
							stateLocation={props.stateLocation}
							messages={replies}
							title={`${replies.length} ${replies.length === 1 ? 'Reply' : 'Replies'}`} />
						<Control 
							icon="messages-hide" 
							iconClass="no-btn" 
							label={`Hide ${replies.length === 1 ? 'Reply' : 'Replies'}`}
							showLabel={true}
							action={()=>{setRepliesActive(repliesActive ? false : true)}} />
					</div>
				</Fragment>
				}
				</div>
				<div className="message-bubble-arrow">
					<div className="outer"></div>
				</div>
			</div>
		</div>
	)
}