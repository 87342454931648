import React from 'react';

import IconToken from 'components/icons/IconToken';

export default (props) => {
  function handleClick(e) {
    if(props.action) {
      props.action(e, props.subject);
    }

    if(props.actionAfter) {
      props.actionAfter();
    }
  }

  return (
    <div className="actions-menu-item">
      <IconToken icon={props.icon} className="actions-menu-item-icon" />
      <div className="actions-menu-item-action" onClick={handleClick}>{props.label}</div>
    </div>
  )
}
