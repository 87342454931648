import React, {useEffect, useState} from 'react';

import FormDynamic from 'components/forms/FormDynamic';

export default (props) => {
  const [unitPrice, setUnitPrice] = useState(null);
  const [minQty, setMinQty] = useState(null);
  const [minPrice, setMinPrice] = useState(null);
  const [rounding, setRounding] = useState(null);
  const [fieldsPricing, setFieldsPricing] = useState(null);
  const [formPricing, setFormPricing] = useState(null);
  const [updating, setUpdating] =useState(false);

// HOOKS
useEffect(() => {
  if(props.pricing) {
    props.pricing.pricing_unit_price &&
    setUnitPrice(parseFloat(props.pricing.pricing_unit_price).toFixed(2));

    props.pricing.pricing_min_quantity &&
    setMinQty(parseInt(props.pricing.pricing_min_quantity));

    props.pricing.pricing_base_price &&
    setMinPrice(parseFloat(props.pricing.pricing_base_price).toFixed(2));

    props.pricing.pricing_round &&
    setRounding(props.pricing.pricing_round);
  }
}, [props.pricing]);

useEffect(() => {
    setFieldsPricing([
      {fieldset_name: `${props.objExists(props, 'pricing.pricing_id') ? 'Edit' : 'Add'} an option`,
      fields: [
        {field_type: 'text',
        field_name: 'option_name',
        field_label: 'Pricing Name',
        field_placeholder: 'Name of Pricing Option',
        field_value: props.objExists(props, 'pricing.pricing_option') ?
                        props.pricing.pricing_option : null,
        field_order: 1,
        field_required: true,
        field_validate: true},
        {field_type: 'float',
        field_label: 'Unit Price',
        field_name: 'pricing_unit_price',
        field_placeholder: 'Single item price',
        field_value: unitPrice,
        field_order: 2,
        field_required: true,
        field_validate: true,
        field_onChange: handleChange},
        {field_type: 'number',
        field_label: 'Minimum Quantity',
        field_name: 'pricing_min_quantity',
        field_placeholder: 'Minimum quantity required',
        field_value: minQty,
        field_order: 3,
        field_required: true,
        field_validate: true,
        field_onChange: handleChange},
        {field_type: 'float',
        field_label: 'Base Price',
        field_name: 'pricing_base_price',
        field_placeholder: 'Minimum price required',
        field_value: minPrice,
        field_order: 4,
        field_required: true,
        field_validate: true,
        field_onChange: handleChange},
        {field_type: 'select',
        field_name: 'pricing_round',
        field_label: 'Pricing Rounding',
        field_placeholder: "Select method...",
        field_value: rounding,
        field_required: false,
        field_validate: false,
        field_onChange: handleChange,
        field_onLoad: null,
        field_returnObject: true,
        field_order: 5,
        option_groups: [{
          options: [{option_name: 'None', option_value: 'none'},
                    {option_name: 'Round (Standard)', option_value: 'round'},
                    {option_name: 'Ceiling (Max)', option_value: 'ceiling'},
                    {option_name: 'Floor (Min)', option_value: 'floor'}]
        }]},
        {field_type: 'switch',
        field_name: 'pricing_ctl_qty',
        field_label: 'Controlled Product',
        field_value: props.objGetValue(props, 'pricing.pricing_ctl_qty') === 0 ||
                      props.objGetValue(props, 'pricing.pricing_ctl_qty') === false ||
                      props.objGetValue(props, 'pricing.pricing_ctl_qty') === 'false' ?
                        false : true,
        field_required: false,
        field_validate: false,
        field_order: 6},
        {field_type: 'switch',
        field_name: 'pricing_published',
        field_label: 'Publish',
        field_value: props.objGetValue(props, 'pricing.pricing_published') === 0 ||
                      props.objGetValue(props, 'pricing.pricing_published') === false ||
                      props.objGetValue(props, 'pricing.pricing_published') === 'false' ?
                        false : true,
        field_required: false,
        field_validate: false,
        field_order: 7},
        {field_type: 'hidden',
        field_name: 'process',
        field_value: props.objExists(props, 'pricing.pricing_id') ? 'update_product_pricing' : 'create_pricing',
        field_required: false,
        field_validate: false
        },
        props.objExists(props, 'pricing.pricing_id') &&
        {field_type: 'hidden',
        field_name: 'pricing_id',
        field_value: props.pricing.pricing_id,
        field_required: false,
        field_validate: false
        },
        {field_type: 'hidden',
        field_name: 'page_pricing',
        field_value: props.objGetValue(props, 'page_pricing') === 0 ||
                      props.objGetValue(props, 'page_pricing') === false ||
                      props.objGetValue(props, 'page_pricing') === 'false' ?
                        'false' : true,
        field_required: false,
        field_required: false,
        field_validate: false
        },
        props.objExists(props, 'product_id') &&
        {field_type: 'hidden',
        field_name: 'product_id',
        field_value: props.product_id,
        field_required: false,
        field_validate: false
        },
      ]},

    ])
}, [unitPrice, minQty, minPrice, rounding]);

useEffect(() => {
  if(fieldsPricing) {
    setFormPricing(<FormDynamic {...props}
                          content={fieldsPricing}
                          name="add-field-option"
                          returnObject={true}
                          fieldOnChange={handleChange}
                          cancelable={props.actionCancel ? true : false}
                          formActions={{formCancel: props.actionCancel ? handleCancel : null, formSubmit: handleAddEdit}} />);
  }
}, [fieldsPricing]);

useEffect(() => {
  if(updating === 'unitPrice' && unitPrice && minQty) {
    setUpdating(false);
    const pricing_base_price = calcMinPrice(unitPrice, minQty, rounding);
    if(pricing_base_price !== minPrice) {
      setMinPrice(pricing_base_price);
    }
  }
}, [unitPrice]);

useEffect(() => {
  if(updating === 'minQty' && minQty && unitPrice) {
    setUpdating(false);
    const pricing_base_price = calcMinPrice(unitPrice, minQty, rounding);
    if(pricing_base_price !== minPrice) {
      setMinPrice(pricing_base_price);
    }
  }
}, [minQty]);

useEffect(() => {
  if(updating === 'minPrice' && unitPrice && minPrice) {
    setUpdating(false);
    const pricing_min_quantity = calcMinQty(unitPrice, minPrice);
    if(pricing_min_quantity !== minQty) {
      setMinQty(pricing_min_quantity);
    }
  }
}, [minPrice]);

useEffect(() => {
  if(updating === 'rounding' && unitPrice && minQty) {
    setUpdating(false);
    const pricing_base_price = calcMinPrice(unitPrice, minQty, rounding);
    if(pricing_base_price !== minPrice) {
      setMinPrice(pricing_base_price);
    }
  }
}, [rounding]);

// FUNCTIONS
  function handleCancel(e) {
    if(props.actionCancel) {
      props.actionCancel(e);
    }
  }

  function handleChange(e) {
    if(Object.entries(e).length > 0 && !e.tagName) {
      console.log("TEST", e.name, e.value);
      switch(e.name) {
        case 'pricing_unit_price':
          if(e.value && e.value !== unitPrice) {
            setUpdating('unitPrice');
            setUnitPrice(e.value);
          }
          break;
        case 'pricing_min_quantity':
          if(e.value && e.value !== minQty) {
            setUpdating('minQty');
            setMinQty(e.value);
          }
          break;
        case 'pricing_base_price':
          if(e.value && e.value !== minPrice) {
            setUpdating('minPrice');
            setMinPrice(e.value);
          }
          break;
        case 'pricing_round':
          if(!updating && e.value !== rounding) {
            console.log("don't know why", e.name, e.value, e);
            setUpdating('rounding');
            setRounding(e.value);
          }
          break;
        default:
          break;
      }
    }
  }

  function priceRound(price, pricing_round) {
    switch(pricing_round) {
      case 'ceiling':
        return Math.ceil(parseFloat(price));
      case 'floor':
        return Math.floor(parseFloat(price));
      case 'round':
        return Math.round(parseFloat(price));
      default:
        return parseFloat(price);
    }
  }

  function calcMinPrice(pricing_unit_price = null, pricing_min_quantity = null, pricing_round = null) {
    if((pricing_unit_price || unitPrice) && (pricing_min_quantity || minQty)) {
      let rawPrice = parseFloat(pricing_unit_price ? pricing_unit_price : unitPrice) *
                      parseInt(pricing_min_quantity ? pricing_min_quantity : minQty);
      return priceRound(rawPrice, pricing_round).toFixed(2);
    }else{
      return null;
    }
  }

  function calcUnitPrice(pricing_base_price = null, pricing_min_quantity = null) {
    if((pricing_base_price || minPrice) && (pricing_min_quantity || minQty)) {
      let rawPrice = parseFloat(pricing_base_price ? pricing_base_price : minPrice) /
                      parseInt(pricing_min_quantity ? pricing_min_quantity : minQty);
      return rawPrice;
    }else{
      return null;
    }
  }

  function calcMinQty(pricing_unit_price = null, pricing_base_price = null) {
    if((pricing_unit_price || unitPrice) && (pricing_base_price || minPrice)) {
      let rawPrice = parseFloat(pricing_base_price ? pricing_base_price : minPrice) /
                      parseInt(pricing_unit_price ? pricing_unit_price : unitPrice);
      return Math.ceil(rawPrice);
    }else{
      return null;
    }
  }

  function handleAddEdit(params) {
    props.setModal(null);
    if(props.actionAddEdit) {
      props.actionAddEdit(params);
    }
  }

  return (formPricing)
}
