import React, { useEffect, useState, useRef} from 'react';

import IconSVG from 'components/icons/IconSVG';

export default (props) => {
  const btnRef = useRef();

  

  return (
    <div className={`nav-btn-menu ${props.className && props.className}${props.active ? ' active' : 
                        btnRef.current && btnRef.current.classList.contains('active') ? ' inactive' : ''}`} 
        onClick={props.action}
        id="menu-nav-btn"
        ref={btnRef} >
      <IconSVG icon="menu" className={props.className} />
    </div>
  )
}
