import React, {useEffect, useState} from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import MediaHero from 'components/profiles/media/MediaHero';

export default (props) => {
  const [style, setStyle] = useState();
  const articleStyles = ['picture-top', 'picture-centre', 'picture-bottom'];


  // useEffect(() => {
  //   // console.log("ARTICLE", props.article);
  //   if(props.article && props.objExists(props.article, 'article_style') &&
  //       props.article.article_style.includes('overlay') &&
  //       props.objExists(props.article, `details.${props.languageCode}.media.media_file_name`) &&
  //       props.objExists(props.article, `details.${props.languageCode}.media.media_file_path`)) {
  //     setStyle({
  //       ...style,
  //       backgroundImage: `url(${props.setFilePath(props.article.details[props.languageCode].media.media_file_name, props.localizeURL(props.article.details[props.languageCode].media.media_file_path))})`,
  //     })
  //   }  
  // }, [props.article]);
  // 
  // useEffect(() => {
  //   style &&
  //   console.log("****STYLE DID UPDATE", style);
  // }, [style]);
  
  
  return (
    <article className={`feature-article${props.objExists(props, 'article.article_style') ? ` ${props.article.article_style}` : ''}`}
              style={props.objExists(props, 'article.article_style') && 
                      props.objGetValue(props, 'article.article_style').includes('overlay') &&
                      props.objExists(props.article, `details.${props.languageCode}.media.media_file_name`) &&
                      props.objExists(props.article, `details.${props.languageCode}.media.media_file_path`) ?
                      {backgroundImage: `url(${props.setFilePath(props.article.details[props.languageCode].media.media_file_name, props.localizeURL(props.article.details[props.languageCode].media.media_file_path))})`} : null }>
      {props.objExists(props.article, 'article_style') &&
        articleStyles.includes(props.article.article_style) &&
        props.article.article_style === 'picture-top' &&
        props.objExists(props.article, `details.${props.languageCode}.media.media_file_name`) &&
        props.objExists(props.article, `details.${props.languageCode}.media.media_file_path`) &&
          <div className="article-visual">
            <img src={props.setFilePath(props.article.details[props.languageCode].media.media_file_name, props.localizeURL(props.article.details[props.languageCode].media.media_file_path))} />
          </div>
      }
        
      {props.objExists(props.article, `details.${props.languageCode}.article_title`) &&
        <h3>{props.article.details[props.languageCode].article_title}</h3>
      }
        
      {props.objExists(props.article, 'article_style') &&
        articleStyles.includes(props.article.article_style) &&
        props.article.article_style === 'picture-centre' &&
        props.objExists(props.article, `details.${props.languageCode}.media.media_file_name`) &&
        props.objExists(props.article, `details.${props.languageCode}.media.media_file_path`) &&
          <div className="article-visual">
            <img src={props.setFilePath(props.article.details[props.languageCode].media.media_file_name, props.localizeURL(props.article.details[props.languageCode].media.media_file_path))} />
          </div>
      }
        
      {props.objExists(props.article, `details.${props.languageCode}.article_content`) &&
        props.article.details[props.languageCode].article_content &&
          <div className="article-html">
            {ReactHtmlParser(props.article.details[props.languageCode].article_content)}
          </div>
      }
        
      {props.objExists(props.article, 'article_style') &&
        articleStyles.includes(props.article.article_style) &&
        props.article.article_style === 'picture-bottom' &&
          <div className="article-visual">
            { Array.isArray(props.objGetValue(props.article,  `details.${props.languageCode}.media`)) &&
              props.article.details[props.languageCode].media.lenght > 0 &&
                <img src={props.setFilePath(props.article.details[props.languageCode].media[0].media_file_name, props.localizeURL(props.article.details[props.languageCode].media[0].media_file_path))} />
            }
          </div>
      }
  </article>  
  )
}
