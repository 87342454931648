import React, {useEffect, useState} from 'react';

import IconSVG from 'components/icons/IconSVG';
import PDFViewer from 'components/pdf/PDFViewer';
import ProductApproval from 'components/products/ProductApproval';

export default (props) => {
	const [approval, setApproval] = useState(null);
	const [clearApproval, setClearApproval] = useState(null);
	const [approvalStatus, setApprovalStatus] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(props.approval && props.approval !== approval) {
			setApproval(props.approval);
			
			if(approval.approval_status && 
				approval.approval_status !== approvalStatus) {
				setApprovalStatus(approval.approval_status);
			}
		} 
	}, [props.approval]);
	 
	// FUNCTIONS
	function handleApproval(approval) {
		if(approval.approval_status && 
			approval.approval_status !== approvalStatus) {
			setApprovalStatus(approval.approval_status);
			
			if(props.actionApproval) {
				props.actionApproval(approval);
			}
		}
	}
	
	function handleClearApproval() {
		setApprovalStatus(null);
		
		if(props.actionClearApproval) {
			props.actionClearApproval();
		}
		
	}
	
	return (
		<div className="block-border">
			<div className="info-bg"
				style={{
					padding: '.5em',
					fontSize: '1.5em',
					color: '#f9f9f9',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				}}>
					<IconSVG icon="search" style={{fill: '#f9f9f9', marginRight: '.5em'}} />
					LIVE PREVIEW
				</div>
			<PDFViewer {...props}
				file={props.previewURL}
				stealthCtl={false}
				approvalStatus={approvalStatus ? approvalStatus : 'clear'} />
			<ProductApproval 
				{...props}
				approval={approval}
				actionApprove={handleApproval}
				actionClear={handleClearApproval}
				clearApproval={clearApproval} />
		</div>
	)
}
