import React, {useEffect, useState, Fragment} from 'react';

export default (props) => {
	const [selectedProducts, setSelectedProducts] = useState();
	
	useEffect(() => {
		if(props.selectedProducts && 
			Array.isArray(props.selectedProducts) &&
			props.selectedProducts.length > 0) {
			let prodSet = [];
			props.selectedProducts.map((prod, index, array) => {
				prodSet.push(
					<li>
						{props.capitalizeString(prod.product_name, true)}
						{prod.product_quantity && ", Quantity: " + prod.product_quantity}
					</li>
				)
			})
			setSelectedProducts(prodSet);	
		}
	}, [props.selectedProducts]);
	
	
	
	return (
		<Fragment>
			<h4>Included Products</h4>
			<ul className="cart-info">
				{selectedProducts}
			</ul>
		</Fragment>
	)
}