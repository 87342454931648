import * as methods from 'methods/reducers';

export default function files(state = {}, action) {
  switch(action.type) {
    case 'FILES_UPLOAD':
      if(Array.isArray(action.payload)) {
        if(action.stateLocation) {
          let uploads = {};
          // ENSURE state.uplodes IS AN OBJECT TO MERGE
          if(state.uploads) {
            if(Array.isArray(state.uploads)) {
              uploads = {general: state.uploads};
            }else{
              uploads = state.uploads;
            }  
          }
          return {...state, 
                  uploadComplete: true,
                  uploads: {...state.uploads,
                            [action.stateLocation]: action.payload}};
        }else{
          return {...state, 
            uploadComplete: true,
            uploads: [...state.uploads, ...action.payload]};  
        }
      }else if(!Array.isArray(action.payload)){
        let uploads = {};
        // ENSURE state.uplodes IS AN OBJECT TO MERGE
        if(state.uploads) {
          if(Array.isArray(state.uploads)) {
            uploads = {general: state.uploads};
          }else{
            uploads = state.uploads;
          }  
        }
        if(action.stateLocation) {
          return {...state, 
                  uploadComplete: true,
                  uploads: {...state.uploads,
                            [action.stateLocation]: action.payload}};
        }else{
          if(state.uploads) {
            return {...state, 
              uploadComplete: true,
              uploads: [...state.uploads, action.payload]};  
          }else{
            return {...state, 
              uploadComplete: true,
              uploads: [action.payload]};  
          } 
        }
      }
    case 'FILE_DELETE_VAL':
      const removeValState = methods.removeStateValue(state, action.payload);
      if(removeValState) {
        return {...removeValState};
      }else{
        console.log("REDUCER: Remove Failed");
        return {...state};
      }
    case 'FILES_SET_VAL':
      const setValState = methods.setStateValue(state, action.key, action.value);
      if(setValState) {
        return {...setValState};
      } else {
        console.log("REDUCER: Set Failed");
        return {...state};
      }
    case 'FILES_CLEAR_UPLOADS':
      return {uploads: null};
    default:
      return state;
  }


}
