import React, {useEffect, useState} from 'react';

import {mediaInfo} from 'methods/forms';

import FormDynamic from 'components/forms/FormDynamic';

export default (props) => {
  const [fieldsMedia, setFieldsMedia] = useState(null);
  const [formMedia, setFormMedia] = useState(null);

  useEffect(() => {
    setFieldsMedia(mediaInfo(null, null, null, props.mediaInfo ? props.mediaInfo : null));
  }, [props.mediaInfo]);

  useEffect(() => {
    if(fieldsMedia) {
      setFormMedia(
        <FormDynamic {...props}
                      content={[{fieldset_name: `Edit media info`, fields: fieldsMedia}]}
                      returnObject={true}
                      fieldOnChange={handleChange}
                      formActions={{formCancel: handleCancel, formSubmit: handleEdit}} />
      )
    }
  }, [fieldsMedia]);


// FUNCTIONS
  function handleCancel(e) {
    if(props.actionCancel) {
      props.actionCancel(e);
    }
  }

  function handleChange(e) {
    console.log("We're handling change!!");
  }

  function handleEdit(params) {
    props.mediaInfo &&
    params.push({name: 'media_id', value: props.mediaInfo.media_id});
    props.objExists(props.mediaInfo, 'text_id') &&
    params.push({name: 'text_id', value: props.mediaInfo.text_id});
    props.objExists(props.mediaInfo, 'media_type') &&
    params.push({name: 'media_type', value: props.mediaInfo.media_type});
    params.push({name: 'process', value: props.objExists(props.mediaInfo, 'text_id') ? 'update_media' : 'add_media_text'});

    if(props.actionEdit) {
      props.actionEdit(params);
    }
  }

  return (formMedia);
}
