import React, {useEffect, useState} from 'react';

import Loader from 'components/atoms/Loader';
import FormDynamic from 'components/forms/FormDynamic';

export default (props) => {
	const [form, setForm] = useState(null);
	const [fieldsets, setFieldsets] = useState(null);
	
	useEffect(() => {
		let foptions = [];
		if(props.fields && Array.isArray(props.fields) && props.fields.length > 0) {
			props.fields.map(field => {
			  foptions.push({option_name: props.capitalizeString(field.field_label, true), option_value: field.field_id});
			})
		}
		setFieldsets([{fieldset_name: `${props.objExists(props, 'charge.charge_id') ? 'Edit ' : 'Add '}` + `${props.objExists(props, 'type') ? props.type : 'Product'}` +  ' Charge',
			fields: [
				{field_type: 'hidden',
				field_name: 'process',
				field_value: props.objExists(props, 'charge.charge_value') ? 'update_charge' : 'add_charge'
				},
				{field_type: 'hidden',
				field_name: 'product_id',
				field_value: props.product_id
				},
				props.objExists(props, 'charge.charge_id') &&
				{field_type: 'hidden',
				field_name: 'charge_id',
				field_value: props.charge.charge_id
				},
				{field_type: 'hidden',
				field_name: 'charge_type',
				field_value: props.objExists(props, 'type') ? props.type : 'product'
				},
				props.objExists(props, 'charge.field_id') &&
				{field_type: 'hidden',
				field_name: 'field_id_org',
				field_value: props.charge.field_id
				},
				{field_type: 'text',
				field_name: 'charge_name',
				field_label: 'Name',
				field_value: props.objExists(props, 'charge.charge_name') ? props.charge.charge_name : null,
				field_required: true,
				field_validate: false,
				field_order: 1,
				},
				{field_type: 'text',
				field_name: 'charge_display',
				field_label: 'Display',
				field_value: props.objExists(props, 'charge.charge_display') ? props.charge.charge_display : null,
				field_required: false,
				field_validate: false,
				field_order: 2,
				},
				{field_type: 'float',
				field_name: 'charge_value',
				field_label: 'Value',
				field_value: props.objExists(props, 'charge.charge_value') ? props.charge.charge_value : null,
				field_required: true,
				field_validate: false,
				field_order: 3,
				},
				foptions.length > 0 && {field_type: 'select',
				field_name: 'field_id',
				field_label: 'Custom Fields',
				field_placeholder: "Select field...",
				field_value: props.objExists(props, 'charge.field_id') ? props.charge.field_id : null,
				field_required: false,
				field_validate: false,
				field_order: 4,
				option_groups: [{
				  options: foptions
				}]},
				{field_type: 'switch',
				field_name: 'charge_multiply',
				field_label: 'Multiply',
				field_value: props.objExists(props, 'charge.charge_multiply') ? true : false,
				field_required: false,
				field_validate: false,
				field_order: 5,
				},
			]
		}]);
	}, [props.charge]);
	
	useEffect(() => {
		if(fieldsets) {
			setForm(
				<FormDynamic
				  content={fieldsets}
				  name="add-edit-charge"
				  returnObject={true}
				  fieldOnLoad={null}
				  formActions={{formCancel: handleCancel, formSubmit: handleAddEdit}} />
			)
		}
	}, [fieldsets]);
	
	function handleCancel(e) {
		props.objExists(props, 'actionCancel') &&
		props.actionCancel();
	}
	
	function handleAddEdit(params) {
		props.objExists(props, 'actionAddEdit') &&
		props.actionAddEdit(params);
	}
	
	return (
		<div>
			{form ? form : <Loader />}
		</div>
	)	
}