import React, {useEffect, useState, Fragment} from 'react';
import {Redirect} from 'react-router';

import FormDynamic from 'components/forms/FormDynamic';
import IconToken from 'components/icons/IconToken';
import Badge from 'components/atoms/Badge';


export default (props) => {
  const [redirect, setRedirect] = useState(null);
  const [fieldsLogin, setFieldsLogin] = useState(null);
  const [formLogin, setFormLogin] = useState(null);
  const [user, setUser] = useState(null);
  const [messageCount, setMessageCount] = useState(null);
  const [taskCount, setTaskCount] = useState(null);

  useEffect(() => {
    if(props.siteUser && props.siteUser !== user) {
      setUser(props.siteUser);
    }else if(props.user && props.user !== user) {
      setUser(props.user);
    }else if(user && !props.siteUser && !props.user) {
      setUser(null);
    }
  }, [props.siteUser]);
  
  useEffect(() => {
    if(props.messageCount && 
        props.messageCount !== messageCount) {
        setMessageCount(props.messageCount);
    }
  }, [props.messageCount]);
  
  useEffect(() => {
    if(props.taskCount && 
        props.taskCount !== taskCount) {
        setTaskCount(props.taskCount);
    }
  }, [props.taskCount]);
  
  useEffect(() => {
    if(!props.objExists(user, 'user_id')) {
      setFieldsLogin(
        [
          {fieldset_name: 'User Login',
          fields: [
              {field_type: 'hidden',
              field_name: 'process',
              field_label: 'Login',
              field_value: 'user_login',},
              {field_type: 'email',
              field_name: 'contact_email',
              field_label: 'Email',
              field_placeholder: 'Your email address',
              field_required: true,
              field_verify: true,
              field_order: 1,
              field_focuse: true,
              },
              {field_type: 'password',
              field_name: 'user_key',
              field_label: 'Password',
              field_placeholder: 'Your password',
              field_required: true,
              field_verify: true,
              field_order: 2,
              field_focuse: true,
              },
              {field_type: 'switch',
              field_name: 'persist',
              field_label: 'Remember Me',
              field_default: false,
              field_order: 3}
            ]
          }
        ]
      )
    }
  }, [user]);

  useEffect(() => {
    if(fieldsLogin) {
      setFormLogin(
        <FormDynamic content={fieldsLogin}
                    name="User Login"
                    formActions={{formSubmit: login, formCancel: cancelLogin}}
                    formCompleteLabel="Login"
                    closable={true}
                    unmountOnClick={true}
                    cancelable={true}
                    includeControls={true}
                    multiStep={false}
                    returnObject={true}
                    alertMsg={props.objGetValue(user, 'alert_error')}
                    formClass=""
                    valActions={{isEmail: props.isEmail}}/>
      )
    }
  }, [fieldsLogin, user]);

  useEffect(() => {
    if(props.objExists(user, 'user_id') &&
      props.objGetValue(user, 'isLogin') === true) {
        props.setModal(null);
      }
  }, [user && user.user_id]);

  function login(params) {
    props.userLogin(params);
  }

  function cancelLogin() {
    props.setModal(null);
  }

  function activateUser() {
    if(props.objExists(user, 'user_id')) {
      setRedirect('/user');
    }else if(formLogin){
      setRedirect('/login');
    }

  }

  return (
    <div className={`token-user no-mobile${props.objExists(user, 'user_id') ? ' active' : ''}`}>
      {redirect && <Redirect to={redirect} />}
      <IconToken action={activateUser} className={props.objExists(user, 'user_id') ? ' active' : ''} icon="user" />
      <Badge
        type="tasks"
        className={`error tasks${taskCount > 0 ? ' active' : ''}`}
        content={taskCount} />
      <Badge 
        type="messages" 
        position='bottom-right'
        className={`info messages ${messageCount > 0 ? ` active` : ''}`}
        content={messageCount} />
    </div>
  )
}
